const template = require(`html-loader!./achievementBadge.html`).default;

class achievementBadgeCtrl {
  isCompletedBattle = false;

  constructor(
    stateService
  ) {
    'ngInject';

    this.stateService = stateService;
  }
}

achievementBadgeCtrl.$inject = [
  'stateService'
];

export default {
  template: template,
  controller: achievementBadgeCtrl,
  bindings: {
    achievement: '<',
    isWhite: '@',
    lazy: '@',
    showCount: '@',
  }
};
