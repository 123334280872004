import ImageModel from './image.model';

class PhotosResponseModel {
  constructor({ base_url, success, items = [] , message, error_type }) {
    this.success = success;
    this.base_url = base_url;
    this.items = items.map(image => new ImageModel(image));
    this.message = message;
    this.error_type = error_type;
  }
}

export default PhotosResponseModel;
