const template = require(`html-loader!./lpv2.html`).default;
import {lpConsts} from '../landingPages.data';

class lpv2Ctrl {

  constructor(
    pageData,
    Modals,
    $timeout,
    $scope,
    RouterService,
    gsScrollTopService,
    tagEventService,
    MemberService,
  ) {
    'ngInject';
    this.busy = true;
    this.CLICK_MODE={
      STORE: 'STORE',
      SIGN_UP: 'SIGN_UP',
    };
    this.comments = [
      {
        text:"GuruShots is the best photographic challenges in the world and I'm just loving this ** Cheers **",
        by:"Ian Popple, UK",
      },
      {
        text:"I am completely addicted to this site it keeps me amused for hours!",
        by:"Wendy Oserle, UK",
      },
      {
        text:"I think this app beats Instagram hands down. It's for people who really love photography.",
        by:"Garrett Green, US",
      },
      {
        text:"I love GuruShots. Ever since my friend introduced me to it, I check it 20 times a day.",
        by:"Law Ream, United States",
      }
    ];

    this.$scope = $scope;
    this.pageData = pageData;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.RouterService = RouterService;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    const numOfPrizes = lpConsts.numOfPrizes;
    const monthlyVotes = lpConsts.monthlyVotes;
    this.scopes = {
      '/lp5': {
        coverUrl: '/assets/images/pages/lpv2/cover5.jpg',
        showCoverUrl: '/assets/images/pages/lpv2/footer_bg.jpg',
        line1Text:'PHOTO GAME',
        line2Text:'Turn Your Photos Into a Fun<br>& Motivating game',
        buttonText:'JOIN NOW - IT’S FREE',
        numOfPrizes,
        monthlyVotes,
        clickMode: this.CLICK_MODE.STORE
      },
      '/lp6': {
        coverUrl: '/assets/images/pages/lpv2/cover5.jpg',
        showCoverUrl: '/assets/images/pages/lpv2/footer_bg.jpg',
        line1Text:'PHOTO GAME',
        line2Text:'Turn Your Photos Into a Fun<br>& Motivating game',
        buttonText:'JOIN NOW - IT’S FREE',
        numOfPrizes,
        monthlyVotes,
        clickMode: this.CLICK_MODE.SIGN_UP
      }
    };

    this.scope = _.clone(this.scopes[this.RouterService.currentUrl]);
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.tagEventService.sendPageViewEvent({title: 'Landing page - lp2'});
    this.busy = false;
    if (this.member.logged_in) {
      this.RouterService.go('challenges/my-challenges/current');
      return;
    }
    this.gsScrollTopService.scrollTop();
  }

  login(event) {
    if(this.scope.clickMode === this.CLICK_MODE.STORE){
      window.open('https://130921animal.onelink.me/6a4E/6c6c1d63');
    } else if (this.scope.clickMode === this.CLICK_MODE.SIGN_UP){
      this.Modals.open('login', event, {signUp: true});
    }

    //put to window.open second param '_blank' <- This is what makes it open in a new window.

  }

}
lpv2Ctrl.$inject = [
  'pageData',
  'Modals',
  '$timeout',
  '$scope',
  'RouterService',
  'gsScrollTopService',
  'tagEventService',
  'MemberService',
];
export default {
  template: template,
  controller: lpv2Ctrl
};
