const template = require(`html-loader!./challengesRankingLevels.html`).default;

import {isNotANumber} from "../../../../../helpers/number.helper";

class ChallengesPlannedTimings {

  constructor(
    $scope,
    ChallengesManager,
    RestManager,
    Toasts
  ) {
    'ngInject';

    this.$scope = $scope;
    this.challengesManager = ChallengesManager;
    this.restManager = RestManager;
    this.toasts = Toasts;

    this.busy = true;

    this.stringRankingLevelsModelSeparator = ',';
    this.searchText = '';
    this.searchHelpText = null;
    this.searchTextSplit = [];
    this.searchTextSplitLength = this.searchTextSplit.length;
    this.selectedItemCopy = this._selectedItemModel();
  }

  _selectedItemModel() {
    return {
      id: null,
      levels: []
    };
  }

  $onInit() {
    this.getRankingLevels();
  }

  async getRankingLevels() {
    const res = await this.challengesManager.getRankingLevels();

    if (!res.success) {
      return;
    }

    this.rankLevels = res.ranking_levels.reverse();

    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async saveRangingLevels(param) {
    let submittedData;

    switch (param) {
      case 'edit': {
        submittedData = {
          id: this.selectedItemCopy.id,
          levels: this.searchTextSplit
        };
        break;
      }
      case 'new': {
        submittedData = {
          levels: this.searchTextSplit
        };
        break;
      }
      default:
        break;
    }

    this.busy = true;

    const response = await this.restManager.setRankingLevels(submittedData);

    if (response.success) {
      await this.getRankingLevels();
    }

    this.busy = false;
    this.messageRankingLevels(response);
  }

  clean() {
    this.selectedItemCopy = this._selectedItemModel();
    this.searchText = '';
  }

  messageRankingLevels(response) {
    this.toasts.message(response.message ? response.message : response.success ? `ID ${this.rankLevels[0].id} was saved successfully` : `Error`, document.querySelector('.gs-white-wrapper'), !response.success, 'top right');
    this.clean();
  }

  querySearch() {
    this._validate();

    this.searchText = this.searchTextSplit.toString();

    return this.searchText ? this.rankLevels.filter((rankLevelItem) => {
      return ((rankLevelItem.id === +this.searchText) || rankLevelItem.levels.toString().includes(this.searchText));
    }) : this.rankLevels;
  }

  _validate() {
    this.searchTextSplit = (typeof this.searchText === 'string') ? this.searchText.split(this.stringRankingLevelsModelSeparator).slice(0, 6) : this.searchText;
    this.searchTextSplitLength = this.searchTextSplit.length;
    this.searchHelpText = this.searchTextSplitLength < 6 ? `You put ${this.searchTextSplitLength} values from required 6 to ${this.selectedItemCopy.id === null ? 'Create a new one' : 'Save changes'}.` : null;

    if (this.searchHelpText === null) {
      for (let i = 0; i < this.searchTextSplitLength; i++) {
        const isCurrentValueIsEmptyString = (this.searchTextSplit[i] === '')
          , isCurrentValueIsNotANumber = isCurrentValueIsEmptyString ? true : isNotANumber(this.searchTextSplit[i]) /* isNaN(';') -> true; isNaN('') -> false */
          , currentValue = !isCurrentValueIsNotANumber && +this.searchTextSplit[i]
          , prevValue = +this.searchTextSplit[i - 1]
          , currentValueText = isCurrentValueIsNotANumber ? (isCurrentValueIsEmptyString ? '<empty>': this.searchTextSplit[i]) : +this.searchTextSplit[i] /* values: <empty>, any symbol, any number  */
          , isFirstValueNotZero = (this.searchTextSplitLength > 1) && (+this.searchTextSplit[0] !== 0) /* autocomplete by two fields: id and levels. Id can start from any number and only when we get a next value after coma, then first should be a zero */
          , isNextValueNotBiggerThanPrevious = (i !== 0) && (currentValue <= prevValue)
        ;

        if (isCurrentValueIsNotANumber) {
          this.searchHelpText = `Value ${currentValueText} at position ${i + 1} should be a number`;
          break;
        }

        if (isFirstValueNotZero) {
          this.searchHelpText = `The first number should be a 0 (zero). You type ${currentValue}`;
          break;
        }

        if (isNextValueNotBiggerThanPrevious) {
          this.searchHelpText = `Value ${currentValueText} at position ${i + 1} should be bigger than ${prevValue} at position ${i}`;
          break;
        }
      }
    }
  }

  searchTextChange() {
    if (this.searchText === '') {
      this.selectedItemCopy = this._selectedItemModel();
    }
  }

  selectedItemChange() {
    Object.assign(this.selectedItemCopy, this.selectedItem);
  }
}
ChallengesPlannedTimings.$inject = [
  '$scope',
  'ChallengesManager',
  'RestManager',
  'Toasts'
];
export default {
  template: template,
  controller: ChallengesPlannedTimings
};
