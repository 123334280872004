import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-rewarded-video-wrapper',
  templateUrl: './admin-content-rewarded-video-wrapper.component.html',
  styleUrl: './admin-content-rewarded-video-wrapper.component.scss'
})
export class AdminContentRewardedVideoWrapperComponent {

}
