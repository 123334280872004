const template = require(`html-loader!./adminChallenges.html`).default;

class adminChallengesCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Create', route: 'challenges/my-challenges/editor', newTab: true, iconClass: "icon-link-external"},
      {name: 'Schedule', route: 'admin/contentManager/challenges/challengesSchedule/upcoming'},
      {name: 'Challenge Templates', route: 'admin/contentManager/challenges/challengeTemplates'},
      {name: 'Bulk Upload', route: 'admin/contentManager/challenges/bulkUpload'},
      {name: 'Winners', route: 'admin/contentManager/challenges/challengesWinners'},
      {name: 'Calendar', route: 'admin/contentManager/challenges/challengesCalendar'},
      {name: 'Auto Submit', route: 'admin/contentManager/challenges/challengesAutoSubmit'},
      {name: 'Notification Times', route: 'admin/contentManager/challenges/notificationTimes'},
      {name: 'Challenges Planned Timings', route: 'admin/contentManager/challenges/challengesPlannedTimings'},
      {name: 'Ranking Levels', route: 'admin/contentManager/challenges/challengesRankingLevels'},
      {name: 'Sponsors', route: 'admin/contentManager/challenges/challengesSponsors'},
    ];
  }
}

export default {
  template: template,
  controller: adminChallengesCtrl,
  bindings: {}
};
