import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ordinalSuffix"
})
export class OrdinalSuffixPipe implements PipeTransform {
  transform(value: number): string {
    let suffix = '';
    let postfixFirst = 'st';
    let postfixSecond = 'nd';
    let postfixThird = 'rd';
    let postfixRest = 'th';

    switch(value) {
      case 1:
        suffix = `${postfixFirst} place: Amazing!`;
        break;
      case 2:
        suffix = `${postfixSecond} place: Awesome!`;
        break;
      case 3:
        suffix = `${postfixThird} place: Awesome!`;
        break;
      case 4:
      case 5:
        suffix = `${postfixRest} place - Cool!`;
        break;
      default:
        suffix = `${postfixRest} place`;
        break;

    }

    return value + suffix;
  }
}
