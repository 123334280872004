const template = require(`html-loader!./adminLeagues.html`).default;

class adminLeaguesCtrl {
  constructor() {
    this.menuItems = [
      {
        name: 'Active league',
        route: 'admin/contentManager/teams/leagues/active',
        status: 'active'
      },
      {
        name: 'Next league',
        route: 'admin/contentManager/teams/leagues/next',
        status: 'next'
      }
    ];
  }
}

export default {
  template: template,
  controller: adminLeaguesCtrl,
  bindings: {}
};
