const template = require(`html-loader!./challengesItemCompleted.html`).default;
import gsChallengeEnum from "../../../api/enums/gsChallenge.enum";
import gsIconEnum from "../../../api/enums/gsIcon.enum";
import challengeEnum from "../../../api/enums/challenge.enum";
import badgeV2Mode from "../../../api/enums/badgeV2.enum";
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";

class challengesItemCompletedCtrl {

  constructor(
    $scope,
    $rootScope,
    $location,
    stateService,
    mixpanelService,
    MemberService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$location = $location;
    this.badgeV2Mode = badgeV2Mode;
    this.gsChallengeEnum = gsChallengeEnum;
    this.challengeEnum = challengeEnum;
    this.gsIconEnum = gsIconEnum;
    this.stateService = stateService;
    this.mixpanelService = mixpanelService;
    this.mixpanelEventsEnum = mixpanelEventsEnum;
    this.isWinner = false;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    let isAchievementTypeIcon = this.challenge.member.achievements.find(item => item.type === 'icon');
    let param = isAchievementTypeIcon ? isAchievementTypeIcon.param : false;

    if (
      param === 'icon-top-photo' ||
      param === 'icon-gurus-top-pick' ||
      param === 'icon-top-photographer'
    ) {
      this.isWinner = true;
    }
    // this.challenge.member.rewards_by_section = {
    //   "claim_state": "DISABLED",
    //   "sections": [
    //     {
    //       "type": "PARTICIPATION_BONUS",
    //       "name": "Participation Bonus",
    //       "resources": [
    //         {
    //           "type": "COINS",
    //           "title": "Coins",
    //           "value": 10
    //         }
    //       ],
    //       "points": 0
    //     },
    //     {
    //       "type": "TOTAL",
    //       "name": "Total",
    //       "resources": [
    //         {
    //           "type": "COINS",
    //           "title": "Coins",
    //           "value": 10
    //         }
    //       ],
    //       "points": 100
    //     }
    //   ]
    // }
  }

  claim(challengeId) {
    this.$scope.$emit('claim_reward', challengeId);
  }

  eventMixPanelAction(uiName) {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.COMPLETED_CHALLENGE,
          ui_name: uiName.toLowerCase(),
          challenge_id: this.challenge.id
        }
      }
    );
  }
}
challengesItemCompletedCtrl.$inject = [
  '$scope',
  '$rootScope',
  '$location',
  'stateService',
  'mixpanelService',
  'MemberService'
];
export default {
  template: template,
  controller: challengesItemCompletedCtrl,
  bindings: {
    challenge: '<',
  }
};
