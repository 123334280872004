import ImageModel from '../../../api/models/image/image.model';
const template = require(`html-loader!./suspendedImagesCard.html`).default;

class suspendedImagesCardCtrl {
  constructor($element, Modals) {
    'ngInject';

    this.$element = $element;
    this.Modals = Modals;

    this.reasons = [];
  }

  $onChanges() {
    //Prepare data & reasons array
    this.gspopup.data.images = this.gspopup.data.images.map((image) => {
      //Prepare reasons array
      if (this.reasons.indexOf(image.reason_text) === -1) {
        this.reasons.push(image.reason_text);
      }
      //return Image object
      return new ImageModel(image);
    });
  }

  preSubmit(event) {
    this.Modals.open('preSubmit', event, {challenge: this.challenge});
    this.close();
  }
}
suspendedImagesCardCtrl.$inject = [
  '$element', 'Modals'
];

export default {
  template: template,
  controller: suspendedImagesCardCtrl,
  bindings: {
    challenge: '<',
    gspopup: '<',
    close: '&'
  }
};
