const template = require(`html-loader!./challengeAchievements.html`).default;

import ImageModel from '../../../api/models/image/image.model';
import {AchievementModel} from '../../../api/models/achievement/achievement.model';
import { getNumberSuffix } from '../../../helpers/number.helper';

class challengeAchievementsCtrl {

  constructor(pageData,
              $scope,
              MemberService,
              PagesManager,
              $location) {
    'ngInject';
    this.busy = true;

    this.pageData = pageData;
    this.$scope = $scope;
    this.MemberService = MemberService;
    this.PagesManager = PagesManager;
    this.$location = $location;

    this.getNumberSuffix = getNumberSuffix;
  }

  $onInit() {
    this.init();
  }

  /** @return void **/
  async init() {
    this.member = this.MemberService.getCurrentMember();
    //TODO ERAN - need new api
    const initData = await this.PagesManager.getPageData(this.$location.absUrl());
    if (initData.success) {
      this.data = initData.items.page;
      this.data.images = this.data.images.map(image => new ImageModel(image));
      this.data.achievements = this.data.achievements.map(achievement => new AchievementModel(achievement));
      this.busy = false;
      setTimeout(() => {
        $('.challenge-achievements__slider').slick({
          slidesToShow: 1,
          slidesToScroll: 1,
          appendDots: '.challenge-achievements__dots',
          dots: true,
          arrows: false,
          autoplay: true,
          responsive: [
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: true,
              },
              lazyLoad: 'ondemand',
              variableWidth: true,
            },
          ],
        });
      });
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }
}
challengeAchievementsCtrl.$inject = [
  'pageData',
  '$scope',
  'MemberService',
  'PagesManager',
  '$location',
];
export default {
  template: template,
  controller: challengeAchievementsCtrl,
  bindings: {
    cUrl: '<',
    userName: '<'
  }
};
