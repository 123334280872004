const template = require(`html-loader!./teamDetails.html`).default;
import teamEnum from '../../../api/enums/team.enum';

class teamDetailsCtrl {

  constructor(
    $scope,
    $rootScope,
    notificationAlert,
    $filter,
    Modals,
    $timeout
  ) {
    'ngInject';
    this.teamChest;

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.notificationAlert = notificationAlert;
    this.$filter = $filter;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.teamEnum = teamEnum;
    this.Modals = Modals;

    this.isClaimed = false;
  }

  $onInit() {
    this.setDetailItems();
  }

  setDetailItems() {
    const checkIfTypeExistList = [
      teamEnum.DETAILS.TYPES.LANGUAGE,
      teamEnum.DETAILS.TYPES.COUNTRY
    ];

    for(let type of checkIfTypeExistList){
      let typeIndex = this.teamDetailTypes.indexOf(type);

      if (typeIndex !== -1 && !this.team[type]) {
        this.teamDetailTypes.splice(typeIndex, 1);
      }
    }

    this.details = {
      score: {
        type: teamEnum.DETAILS.TYPES.SCORE,
        title: 'Team Score',
        value: this.$filter('number')(this.team.score)
      },
      scoreWhite: {
        type: teamEnum.DETAILS.TYPES.SCORE_WHITE,
        title: 'Team Score',
        value: '+' + this.$filter('number')(this.team.score)
      },
      win: {
        type: teamEnum.DETAILS.TYPES.WIN,
        title: 'Team Wins',
        value: this.$filter('abbreviateNumber')(this.team.wins) || 0
      },
      members: {
        type: teamEnum.DETAILS.TYPES.MEMBERS,
        title: 'Team Members',
        value: this.team.member_count + '/' + this.team.team_capacity
      },
      status: {
        type: teamEnum.DETAILS.TYPES.STATUS,
        title: 'Min. Requirement',
        value: this.team.required_status_name
      },
      losts: {
        type: teamEnum.DETAILS.TYPES.LOSTS,
        title:'Team Losses',
        value: this.team.totalMatches ? (this.team.totalMatches - this.team.wins) : 0
      },
      totalMatches: {
        type: teamEnum.DETAILS.TYPES.TOTAL_MATCHES,
        title:'Total Matches',
        value: this.team.totalMatches ? this.team.totalMatches : 0
      },
      vote: {
        type: teamEnum.DETAILS.TYPES.VOTE,
        title:this.mode === 'matchHistory' ? 'Team Votes' : 'Total Votes',
        value: this.team.total_votes
      },
      language: {
        type: teamEnum.DETAILS.TYPES.LANGUAGE,
        title:'Language',
        value: this.team.language ? this.team.language.name : null
      },
      country: {
        type: teamEnum.DETAILS.TYPES.COUNTRY,
        title:'Country',
      }
    };
  }

  scoreInfo() {
    this.notificationAlert.info({
      message: this.configData.my_team_help_info,
      buttonText: 'Got it',
      title: 'Team Score'
    });
  }

  claim() {
    if (!this.isClaimed) {
      this.Modals.open('claim', null, {
        chest: this.teamChest.member_chest,
        chest_attributes: this.teamChest.chest_attributes
      });
      this.$timeout(() => {
        this.$rootScope.$emit('teamMemberChestReload');
      }, 1000);

      this.isClaimed = true;
    }
  }

  canClaim() {
    return this.teamChest.member_chest.can_claim;
  }
}
teamDetailsCtrl.$inject = [
  '$scope',
  '$rootScope',
  'notificationAlert',
  '$filter',
  'Modals',
  '$timeout'
];

export default {
  template: template,
  controller: teamDetailsCtrl,
  bindings: {
    team: '<',
    teamChest: '<',
    configData: '<',
    teamDetailTypes: '<',
    mode: '@',
    showLeague: '@'
  }
};
