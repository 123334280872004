import discover from './discover/discover';
import discoverPhotos from './discoverPhotos/discoverPhotos';
import discoverSearch from './discoverSearch/discoverSearch';

let moduleDiscover = angular.module('gsApp.discover', [])
    .component('discover', discover)
    .component('discoverPhotos', discoverPhotos)
    .component('discoverSearch', discoverSearch)

  ;

export default moduleDiscover.name;
