import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-events-leader-board-wrapper',
  templateUrl: './admin-content-manager-events-leader-board-wrapper.component.html',
  styleUrl: './admin-content-manager-events-leader-board-wrapper.component.scss'
})
export class AdminContentManagerEventsLeaderBoardWrapperComponent {

}
