export const challengeActionLockedConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-challenge-action-locked data="modal.data"></modal-challenge-action-locked>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
