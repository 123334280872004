<div
  [ngClass]="{'no-bg': !showBackground}"
  class="users-limit-wrapper">
  <div class="users-icon"></div>

  <div
    [ngStyle]="{'color': textColor, 'font-size': fontSize}"
    class="users-count"
  >{{players + '/' + maxPlayers}}</div>
</div>
