const template = require(`html-loader!./editChallengeTemplate.html`).default;
import {delay, map, Subject, takeUntil} from "rxjs";
import {ChallengeTurboUnlockType} from "../../../../../../../../modules/challenges/enums/challenges.enum";
import challengeEnum from "../../../../../../api/enums/challenge.enum";
import {
  challengeTypes,
  exhibitionPackages,
  joinMemberStatuses,
  joinMemberStatusTypes,
  maxPhotoSubmitsOptions
} from "../../../../../challenges/myChallengesEditor/myChallengesEditor.data";
import {ChallengeTemplate} from "../../../../../../api/models/ChallengeTemplate";
import {objectDifference} from "../../../../../../helpers/object.helper";

class editChallengeTemplateCtrl {
  isEditMode = false;
  ChallengeTurboUnlockType = ChallengeTurboUnlockType;
  subject = new Subject();
  constructor(
    RestManager,
    RouterService,
    stateService,
    Toasts,
    $element,
    notificationAlert,
    challengeService,
    $scope
  ) {
    'ngInject';
    this.RestManager = RestManager;
    this.stateService = stateService;
    this.RouterService = RouterService;
    this.Toasts = Toasts;
    this.$element = $element;
    this.notificationAlert = notificationAlert;
    this.challengeService = challengeService;
    this.challengeEnum = challengeEnum;
    this.challengeTypes = challengeTypes;
    this.maxPhotoSubmitsOptions = maxPhotoSubmitsOptions;
    this.exhibitionPackages = exhibitionPackages;
    this.joinMemberStatuses = joinMemberStatuses;
    this.joinMemberStatusTypes = joinMemberStatusTypes;
    this.tagsSearchText = "";
    this.$scope = $scope;
    this.badges = [];

    this.RouterService.activatedRoute
      .queryParams.pipe(
      delay(10),
      map(() => window.history.state),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      if (!params.template && this.RouterService.currentUrl.includes('/edit-template')) {
        this.goToChallengeTemplates();
        return;
      }
      this.challengeTemplate = new ChallengeTemplate(params.template) || new ChallengeTemplate();
      if (this.challengeTemplate.prizes) {
        delete this.challengeTemplate.prizes;
      }
      this.isEditMode = !!params.template;
      this.orgChallengeTemplate = angular.copy(this.challengeTemplate);

      this.initRecommendedHours();
      this.initRecommendedStartTime();
      this.initRecommendedEndTime();
      if (this.isEditMode) {
        this.badgeToggle(false);
      }
    });
  }

  //region Recommended hours
  initRecommendedHours() {
    if (this.challengeTemplate.params.recommended_hours.length === 0) {
      this.addRecommendedHours();
    }
  }

  addRecommendedHours() {
    this.challengeTemplate.params.recommended_hours.push(null);
  }

  clearRecommendedHours() {
    let firstItemIndex = 0;
    this.challengeTemplate.params.recommended_hours[firstItemIndex] = null;
  }

  deleteRecommendedHours(index) {
    this.challengeTemplate.params.recommended_hours.splice(index, 1);
  }
  //endregion

  //region Recommended start time
  /**
   * 100% duplication of recommended_end_time
   * */
  initRecommendedStartTime() {
    if (this.challengeTemplate.params.recommended_start_time.length === 0) {
      this.addRecommendedStartTime();
    } else {
      this.preformatRecommendedStartTime();
    }
  }

  addRecommendedStartTime() {
    this.challengeTemplate.params.recommended_start_time.push(null);
  }

  clearRecommendedStartTime() {
    let firstItemIndex = 0;
    this.challengeTemplate.params.recommended_start_time[firstItemIndex] = null;
  }

  deleteRecommendedStartTime(index) {
    this.challengeTemplate.params.recommended_start_time.splice(index, 1);
  }

  preformatRecommendedStartTime() {
    this.challengeTemplate.params.recommended_start_time = this.challengeTemplate.params.recommended_start_time.map((item) => item === null ? null : new Date(item));
  }
  //endregion

  //region Recommended end time
  /**
   * 100% duplication of recommended_start_time
   * */
  initRecommendedEndTime() {
    if (this.challengeTemplate.params.recommended_end_time.length === 0) {
      this.addRecommendedEndTime();
    } else {
      this.preformatRecommendedEndTime();
    }
  }

  addRecommendedEndTime() {
    this.challengeTemplate.params.recommended_end_time.push(null);
  }

  clearRecommendedEndTime() {
    let firstItemIndex = 0;
    this.challengeTemplate.params.recommended_end_time[firstItemIndex] = null;
  }

  deleteRecommendedEndTime(index) {
    this.challengeTemplate.params.recommended_end_time.splice(index, 1);
  }

  preformatRecommendedEndTime() {
    this.challengeTemplate.params.recommended_end_time = this.challengeTemplate.params.recommended_end_time.map((item) => item === null ? null : new Date(item));
  }
  //endregion

  goToChallengeTemplates() {
    this.stateService.goToState(
      'admin/contentManager/challenges/challengeTemplates',
      {},
      true
    );
  }

  isValid() {
    const diff = objectDifference(this.orgChallengeTemplate, this.challengeTemplate);

    if (!diff.length) {
      this.notificationAlert.infoSmall({message: "no field was changed"});
      return;
    }

    let fieldsToValidate = this.challengeService.getChallengeFieldsToValidate('params.');
    fieldsToValidate.unshift({field: 'name', title: 'Template name'})
    if(!this.challengeService.isFieldsValid(this.challengeTemplate, fieldsToValidate)){
      return false;
    }
    if(this.challengeTemplate.params.achievements_and_prizes && this.challengeTemplate.params.achievements_and_prizes.length){
      for(let achievementPrize of this.challengeTemplate.params.achievements_and_prizes){
        if(!this.challengeService.isAchievementPrizeValid(achievementPrize)){
          return false;
        }
      }
    }
    if(this.challengeTemplate.params.isOnePhoto()){
      if(this.challengeTemplate.params.boost_enable && this.challengeTemplate.params.turbo_enable){
        this.notificationAlert.open('Cant enable boost and turbo in one photo challenge!');
        return false;
      }
    }

    return true;
  }

  joinMemberStatusChanged() {
    this.challengeService.onJoinMemberStatusChanged({
      challenge: this.challengeTemplate.params,
      scope: this
    });
  }

  onTagUpdate(chip) {
    return this.challengeService.onChallengeTagUpdate(chip);
  }

  onTypeChanged() {
    this.challengeTemplate.params.boost_enable = !this.challengeTemplate.params.isOnePhoto();

    if([challengeEnum.TYPE.SPEED].includes(this.challengeTemplate.params.type)){
      this.challengeTemplate.params.max_photo_submits = 1;
    } else if ([challengeEnum.TYPE.EXHIBITION].includes(this.challengeTemplate.params.type)){
      this.challengeTemplate.params.max_photo_submits = 4;
    }
    this.badgeToggle();
  }

  badgeToggle(saveDefault = true) {
    this.badges = this.challengeService.getBadgesByType(
      this.challengeTemplate.params,
      saveDefault
    );
    this.badgeChanged(saveDefault);
  }

  parseTemplateResponse() {
    if (this.challengeTemplate.params.type !== challengeEnum.TYPE.EXHIBITION) {
      this.challengeTemplate.params.apply_package_id = undefined;
    }

    /* TODO: Refactor recommended_start_time && recommended_hours
        var numbers = [45, 4, 9, 16, 25];
        var allOver18 = numbers.some(myFunction);

        function myFunction(value) {
          return value === null || value === undefined;
        }
    */

    //region Recommended start time
    if (this.challengeTemplate.params.recommended_start_time.includes(null)) {
      this.challengeTemplate.params.recommended_start_time = this.challengeTemplate.params.recommended_start_time.filter((item) => {
        return !(item === null);
      });
    }

    if (this.challengeTemplate.params.recommended_start_time.includes(undefined)) {
      this.challengeTemplate.params.recommended_start_time = this.challengeTemplate.params.recommended_start_time.filter((item) => {
        return !(item === undefined);
      });
    }

    /**
     * Save only unique recommended start time
     * */
    let recommendedStartTimeValidation = this.challengeTemplate.params.recommended_start_time.map((item) => +item);
    recommendedStartTimeValidation = recommendedStartTimeValidation.filter((item, index) => {
      return recommendedStartTimeValidation.indexOf(item) === index;
    });
    this.challengeTemplate.params.recommended_start_time = recommendedStartTimeValidation.map((item) => new Date(item));
    //endregion

    //region Recommended end time
    if (this.challengeTemplate.params.recommended_end_time.includes(null)) {
      this.challengeTemplate.params.recommended_end_time = this.challengeTemplate.params.recommended_end_time.filter((item) => {
        return !(item === null);
      });
    }

    if (this.challengeTemplate.params.recommended_end_time.includes(undefined)) {
      this.challengeTemplate.params.recommended_end_time = this.challengeTemplate.params.recommended_end_time.filter((item) => {
        return !(item === undefined);
      });
    }

    /**
     * Save only unique recommended end time
     * */
    let recommendedEndTimeValidation = this.challengeTemplate.params.recommended_end_time.map((item) => +item);
    recommendedEndTimeValidation = recommendedEndTimeValidation.filter((item, index) => {
      return recommendedEndTimeValidation.indexOf(item) === index;
    });
    this.challengeTemplate.params.recommended_end_time = recommendedEndTimeValidation.map((item) => new Date(item));
    //endregion

    //region Recommended hours
    if (this.challengeTemplate.params.recommended_hours.includes(null)) {
      this.challengeTemplate.params.recommended_hours = this.challengeTemplate.params.recommended_hours.filter((item) => {
        return !(item === null);
      });
    }

    if (this.challengeTemplate.params.recommended_hours.includes(0)) {
      this.challengeTemplate.params.recommended_hours = this.challengeTemplate.params.recommended_hours.filter((item) => {
        return !(item === 0);
      });
    }

    if (this.challengeTemplate.params.recommended_hours.includes('e')) {
      this.challengeTemplate.params.recommended_hours = this.challengeTemplate.params.recommended_hours.filter((item) => {
        return !(item === 'e');
      });
    }

    if (this.challengeTemplate.params.recommended_hours.includes('-')) {
      this.challengeTemplate.params.recommended_hours = this.challengeTemplate.params.recommended_hours.filter((item) => {
        return !(item === '-');
      });
    }

    /**
     * Save only unique recommended hours
     * */
    this.challengeTemplate.params.recommended_hours = this.challengeTemplate.params.recommended_hours.filter((item, index) => this.challengeTemplate.params.recommended_hours.indexOf(item) === index);
    //endregion
  }

  async save() {
    this.parseTemplateResponse();
    const isValid = this.isValid();
    if (isValid) {
      const res = await this.RestManager.setChallengeTemplate(this.challengeTemplate.getParsed());
      if (!res.success) {
        return;
      }
      this.goToChallengeTemplates();
    }
  }

  badgeChanged(saveDefault = true) {
    this.challengeService.badgeChanged(this.challengeTemplate.params, saveDefault);
  }

  searchTags(text) {
    return this.challengeService.searchTags(text);
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
editChallengeTemplateCtrl.$inject = [
  'RestManager',
  'RouterService',
  'stateService',
  'Toasts',
  '$element',
  'notificationAlert',
  'challengeService',
  '$scope'
];
export default {
  template: template,
  controller: editChallengeTemplateCtrl,
  bindings: {},
};
