export const  memberLeaveModes = {
  DEACTIVATE:'DEACTIVATE',
  DELETE:'DELETE',
  REACTIVATE:'REACTIVATE',
};
export const  memberLeaveSteps = {
  DEACTIVATE_OR_DELETE:'DEACTIVATE_OR_DELETE',
  REASONS:'REASONS',
  END:'END',
  LOSE_PROGRESS:'LOSE_PROGRESS',
  ADMIN:'ADMIN',
};
export const  reasonsType = {
  SUSPEND:'SUSPEND',
  DELETE:'DELETE',
  REACTIVATE:'REACTIVATE',
};
export const  deactivateAction = {
  DELETE:'DELETE',
  ACTIVATE:'ACTIVATE',
  NO_ACTION:'NO_ACTION',
};
export const  deactivateActions = [
  {name:'Reactive', id:deactivateAction.ACTIVATE},
  {name:'Keep Deactivated', id:deactivateAction.NO_ACTION},
  {name:'Automatically delete account', id:deactivateAction.DELETE},
];
