const template = require(`html-loader!./readMore.html`).default;

class readMoreComponent {

  constructor($sce, $mdDialog) {
    "ngInject";

    this.$mdDialog = $mdDialog;
  }

  close() {
    this.$mdDialog.hide();
  }
}
readMoreComponent.$inject = [
  '$sce', '$mdDialog'
];
export default {
  template: template,
  controller: readMoreComponent,
  bindings: {
    data: '='
  }
};
