import toastsSettings from './toasts.conf';
import toastsService from './toasts.service';
import suspendedImages from './suspendedImages/suspendedImages';
import successfulSwap from './successfulSwapToast/successfulSwapToast';
import trySwap from './trySwap/trySwap';
import simpleMessage from './simpleMessage/simpleMessage';
import expoSelection from './expoSelectionToast/expoSelection';
import expoSale from './expoSaleToast/expoSale';

let moduleServiceToasts = angular.module('gsApp.service.toasts', [])
    .factory('toastsSettings', toastsSettings)
    .service('Toasts', toastsService)
    .component('suspendedImages', suspendedImages)
    .component('toastSuccessfulSwap', successfulSwap)
    .component('trySwapToast', trySwap)
    .component('simpleMessage', simpleMessage)
    .component('expoSelectionToast', expoSelection)
    .component('expoSaleToast', expoSale)
  ;

export default moduleServiceToasts.name;
