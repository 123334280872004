/**
 * ClickControl directive for 'click once' actions.
 * You can use disabled parameter, also u can set event name for unlock
 */
class clickControl {
  constructor() {

    this.restrict = 'A';
    this.priority = -1;
    this.scope = {
      'ngClick': '&',
      'clickDisabled': '<',
      'allowNgClick': '<',
      'eventAllow': '@'
    };
  }

  link(scope, element, attrs) {
    element.bind('click', (evt) => {
      if (scope.clickDisabled) {
        evt.preventDefault();
        evt.stopImmediatePropagation();

        if(scope.allowNgClick){
          scope.ngClick();
        }
      }
    });

    scope.$on(scope.eventAllow, function () {
      scope.clickDisabled = false;
    });
  }
}

clickControl.$inject = [

];

export default () => new clickControl;
