class MarkAsAdultService {
  constructor(
    PhotosManager
  ) {
    'ngInject';

    this.PhotosManager = PhotosManager;
  }

  setPhotoData(data) {
    this.PhotosManager.setPhotoData(data);
  }
}
MarkAsAdultService.$inject = ['PhotosManager'];
export default MarkAsAdultService;
