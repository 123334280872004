import TeamModel from './team.model';
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

class MatchModel {
  constructor(props) {
    this.can_join = props.can_join;
    this.match_id = props.match_id;
    this.challenge_id = props.challenge_id;
    this.challenges = props.challenges ? props.challenges.map(challenge => new NgChallengeModel(challenge)) : props.challenges;
    this.teams = props.teams ? props.teams.map(team => new TeamModel(team)) : props.teams;
  }
}

export default MatchModel;
