const template = require(`html-loader!./share.html`).default;

class shareCtrl {
  /**
   * @param {Toasts} Toasts
   * */

  constructor($mdDialog, $element, Toasts) {
    "ngInject";
    this.$mdDialog = $mdDialog;
    this.Toasts = Toasts;
    this.$element = $element;
  }

  $onChanges() {
    if (!this.data || !this.data.link) {
      this.close();
      return false;
    }
  }

  close() {
    this.$mdDialog.hide();
  }

  copyDone(event) {
    // show toast
    this.Toasts.open('copy', {}, $(this.$element[0]).find('.c-modal-share__copy__btn')[0]);
  }
}
shareCtrl.$inject = [
  '$mdDialog', '$element', 'Toasts'
];
export default {
  template: template,
  controller: shareCtrl,
  bindings: {
    data: '=data'
  }
};
