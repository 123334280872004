const template = require(`html-loader!./trySwapCard.html`).default;

class trySwapCardCtrl {
  constructor($element, Modals, challengeService) {
    'ngInject';
    this.$element = $element;
    this.Modals = Modals;
    this.challengeService = challengeService;
  }

  swap(event) {
    this.challengeService.swapAction({
      challenge: this.challenge
    });
    this.close();
  }
}
trySwapCardCtrl.$inject = [
  '$element', 'Modals', 'challengeService'
];
export default {
  template: template,
  controller: trySwapCardCtrl,
  bindings: {
    challenge: '<',
    gspopup: '<',
    close: '&'
  }
};
