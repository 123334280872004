/* globals angular */
const template = require(`html-loader!./successfulSwapToast.html`).default;

class successfulSwapToastCtrl {

  /**
   * @param {ModalsService} Modals
   * @param {RestManager} RestManager
   * */
  constructor($mdToast, Modals, RestManager) {
    'ngInject';

    this.$mdToast = $mdToast;
    this.Modals = Modals;
    this.RestManager = RestManager;
  }

  open(event) {
    this.Modals.open('successfulSwap', event, {
      challenge: this.challenge,
    });

    this.RestManager.deleteLast();
    this.$mdToast.hide();
  }

  close() {
    this.RestManager.deleteLast();
    this.$mdToast.hide();
  }
}
successfulSwapToastCtrl.$inject = [
  '$mdToast', 'Modals', 'RestManager'
];
export default {
  template: template,
  controller: successfulSwapToastCtrl,
  bindings: {
    challenge: '<challenge',
  }
};
