const template = require(`html-loader!./myTeamChatReplyMessage.html`).default;

class myTeamChatReplyMessageCtrl {
  constructor(
    $rootScope,
    $sce,
    MemberService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$sce = $sce;
    this.member = MemberService.getCurrentMember();
  }

  $onChanges(){
    this.replyMessage.message.text = this.replyMessage.message.text.replace("<br />", " ").replace("<br>", " ").replace("<br >", " ");
  }

  trustedHtml(plainText) {
    return this.$sce.trustAsHtml(plainText);
  }
}

myTeamChatReplyMessageCtrl.$inject = [
  '$rootScope',
  '$sce',
  'MemberService',
];

export default {
  template: template,
  controller: myTeamChatReplyMessageCtrl,
  bindings: {
    replyMessage: '<',
    messageMode: '@'
  }
};
