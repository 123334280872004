import ArticleModel from './article.model';

class ArticlesListResponseModel {
  constructor({success, article, articles = []}) {
    this.success = success;
    this.articles = articles.map(article => new ArticleModel(article));
    this.article = article ? new ArticleModel(article) : undefined;
  }
}

export default ArticlesListResponseModel;
