const template = require(`html-loader!./gsFooter.html`).default;

class gsFooter {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.year = new Date().getFullYear();
  }
}

gsFooter.$inject = [

];

export default {
  template: template,
  controller: gsFooter,
};

