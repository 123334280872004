import mixpanelEventsEnum from "./mixpanel.enum";

class MixedPanelEventData {
  constructor({
    browser_type,
    browser_version,
    member_id,
    current_page,
    ui_category,
    ui_name,
    ui_action,
    url_params,
    ui_notification_counter,
    menu_selection,
    searched_member_id,
    ui_notification,
    resource_balance,
    number_of_challenges_to_fill,
    fills_amount,
    challenge_id,
    meter_status,
    number_of_photos_submitted,
    challenge_level,
    boost_time_left,
    member_status,
    order_type,
    spotlight_type,
    filter_type,
    league_id,
    leagues_state,
    balance_fills,
    balance_keys,
    balance_swaps,
    package_id,
    achievement_name,
    viewed_member_id,
    theme_id,
    theme_name,
    participants_joined,
    total_participants,
    purchase_success,
    battle_id,
    total_votes,
    success_in_voting_session,
    fail_in_voting_session,
    error_in_voting_session,
    exposure_meter_status,
    current_balance,
    package_type,
    galleryId,
    offer_coins,
    price,
    origin,
    gallery_id,
    ui_state,
  }) {
    /* Send for all events */
    this.screen_resolution = `${window.screen.availWidth}x${window.screen.availHeight}`;

    Object.assign(this,
      (browser_type !== undefined) && {browser_type},
      (browser_version !== undefined) && {browser_version},
      (member_id !== undefined) && {member_id},
      (current_page !== undefined) && {current_page},
      (ui_category !== undefined) && {ui_category},
      (ui_name !== undefined) && {ui_name},
      (ui_action !== undefined) && {ui_action},
      (url_params !== undefined) && {url_params},
      (ui_notification_counter !== undefined) && {ui_notification_counter},
      (menu_selection !== undefined) && {menu_selection},
      (searched_member_id !== undefined) && {searched_member_id},
      (ui_notification !== undefined) && {ui_notification},
      (resource_balance !== undefined) && {resource_balance},
      (number_of_challenges_to_fill !== undefined) && {number_of_challenges_to_fill},
      (fills_amount !== undefined) && {fills_amount},
      (challenge_id !== undefined) && {challenge_id},
      (meter_status !== undefined) && {meter_status},
      (number_of_photos_submitted !== undefined) && {number_of_photos_submitted},
      (challenge_level !== undefined) && {challenge_level},
      (boost_time_left !== undefined) && {boost_time_left},
      (member_status !== undefined) && {member_status},
      (order_type !== undefined) && {order_type},
      (spotlight_type !== undefined) && {spotlight_type},
      (filter_type !== undefined) && {filter_type},
      (league_id !== undefined) && {league_id},
      (leagues_state !== undefined) && {leagues_state},
      (balance_fills !== undefined) && {balance_fills},
      (balance_swaps !== undefined) && {balance_swaps},
      (balance_keys !== undefined) && {balance_keys},
      (package_id !== undefined) && {package_id},
      (achievement_name !== undefined) && {achievement_name},
      (viewed_member_id !== undefined) && {viewed_member_id},
      (theme_id !== undefined) && {theme_id},
      (theme_name !== undefined) && {theme_name},
      (participants_joined !== undefined) && {participants_joined},
      (total_participants !== undefined) && {total_participants},
      (purchase_success !== undefined) && {purchase_success},
      (battle_id !== undefined) && {battle_id},
      (total_votes !== undefined) && {total_votes},
      (success_in_voting_session !== undefined) && {success_in_voting_session},
      (fail_in_voting_session !== undefined) && {fail_in_voting_session},
      (error_in_voting_session !== undefined) && {error_in_voting_session},
      (exposure_meter_status !== undefined) && {exposure_meter_status},
      (current_balance !== undefined) && {current_balance},
      (package_type !== undefined) && {package_type},
      (galleryId !== undefined) && {galleryId},
      (offer_coins !== undefined) && {offer_coins},
      (price !== undefined) && {price},
      (origin !== undefined) && {origin},
      (gallery_id !== undefined) && {gallery_id},
      (ui_state !== undefined) && {ui_state},
    );
  }
}

export default MixedPanelEventData;
