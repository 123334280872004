<div
  class="mini-game-wrapper"
  [ngClass]="{'zero-opacity': isLoading}"
>
  <div class="close-button" (click)="closeModal()">✕</div>

  <app-mini-game-votes-meter
    [game]="game"
  ></app-mini-game-votes-meter>

  <app-mini-game-voting
    [images]="images"
    [currentIndex]="currentIndex"
    [isVoteSubmitGoing]="isVoteSubmitGoing"
    [isLoading]="isLoading"
    (voteSubmit)="submitVote($event)"
  ></app-mini-game-voting>

  <ng-template #gameLose>
    <div class="lose-wrapper">
      <div class="lose-text" *ngIf="miniGameErrorMessage">{{miniGameErrorMessage}}</div>
      <div class="lose-text" *ngIf="!miniGameErrorMessage">
        You didn't win this time...
        <div *ngIf="!isMiniGameErrored">Try again using {{unlockType === resourceType.KEYS ? 'a key' : 'coins'}}</div>
      </div>
      <div class="gs-p-r">
        <button
          class="start-mini-game-button gs-theme-text-caps-bold"
          (click)="isMiniGameErrored ? closeModal() : keyUnlock()"
        >
          {{isMiniGameErrored ? 'Close' : 'Try Again'}}
        </button>
        <app-resource-indicator
          *ngIf="!isMiniGameErrored"
          [resource]="unlockType!"
          [amount]="challenge.member.turbo?.turbo_unlock_amount!"
        ></app-resource-indicator>
      </div>
      <div
        *ngIf="!isMiniGameErrored"
        class="lose-close-link gs-theme-text-regular"
        (click)="closeModal()"
      >
        Close
      </div>
    </div>
  </ng-template>
</div>

<div *ngIf="afterGameOptions.showAfterGameMessage" class="after-game-message">
  <ng-container *ngIf="afterGameOptions.isGameWon; else gameLose">
    <div
      class="won-wrapper"
      (click)="afterWon()"
    >
      <div class="won-image"></div>
      <span class="won-text">Turbo won!</span>
      <div class="won-action-link gs-theme-text-regular">Click to continue</div>
    </div>
  </ng-container>
</div>

<div *ngIf="isLoading" class="loading-overlay">
  <app-gs-loader
    [type]="loaderTypeBattle"
  ></app-gs-loader>
</div>
