import MemberModel from "../../../api/models/member.model";
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

const emptyMember = new MemberModel({});
const emptyChallenge = new NgChallengeModel({});
const uploadMessage = 'I\'m the sole owner of the photos I\'m uploading';

export const scopes = {
  getImages: {
    scope: 500,
    member: _.clone(emptyMember),
    getTitle() {
      return `<i class="icon-butterfly"></i>
            <span>Select or upload image</span>`;
    },
    myPhotos: true,
    message: false,
    submit: 'CHANGE',
    getScopeId() {
      return this.member.id;
    }
  },
  suggestedChallenge: {
    scope: 500,
    member: _.clone(emptyMember),
    getTitle() {
      return `<i class="icon-butterfly"></i>
            <span>Select or upload image</span>`;
    },
    myPhotos: true,
    message: false,
    submit: 'SELECT',
    getScopeId() {
      return this.member.id;
    }
  },
  profile: {
    uploadMessage: uploadMessage,
    scope: 500,
    member: _.clone(emptyMember),
    getTitle() {
      return `<i class="icon-upload"></i>
            <span>Upload to profile</span>`;
    },
    tip: 'You can upload up to 21 photos at once',
    myPhotos: false,
    message: false,
    submit: 'UPLOAD',
    getScopeId() {
      return this.member.id;
    }
  },
  avatar: {
    uploadMessage: uploadMessage,
    scope: 300,
    member: _.clone(emptyMember),
    getTitle() {
      return `<span>Change Profile Photo</span>`;
    },
    myPhotos: true,
    message: false,
    submit: 'UPLOAD',
    getScopeId() {
      return this.member.id;
    }
  },
  cover: {
    uploadMessage: uploadMessage,
    scope: 400,
    member: _.clone(emptyMember),
    getTitle() {
      return `<i class="icon-menu-photos"></i>
            <span>Change Cover Photo</span>`;
    },
    myPhotos: true,
    message: false,
    submit: 'UPLOAD',
    getScopeId() {
      return this.member.id;
    }
  },
  challenge: {
    uploadMessage: uploadMessage,
    scope: 100,
    challenge: _.clone(emptyChallenge),
    getTitle() {
      return `<span>UPLOAD PHOTOS TO
              <span class="blue">${this.challenge.title}</span>
              challenge</span>`;
    },
    myPhotos: true,
    message: true,
    submit: 'SUBMIT',
    getScopeId() {
      return this.challenge.id;
    }
  },
  theme: {
    uploadMessage: uploadMessage,
    scope: 1000,
    challenge: _.clone(emptyChallenge),
    getTitle() {
      return `<span>UPLOAD PHOTOS TO
                <span class="blue">${this.challenge.title}</span>
              </span>`;
    },
    myPhotos: true,
    message: true,
    submit: 'SUBMIT',
    getScopeId() {
      return this.challenge.id;
    }
  },
  challengeCover: {
    uploadMessage: uploadMessage,
    scope: 200,
    challenge: _.clone(emptyChallenge),
    getTitle() {
      return `<span>UPLOAD CHALLENGE COVER</span>`;
    },
    myPhotos: true,
    message: false,
    submit: 'UPLOAD',
    getScopeId() {
      return this.challenge.id;
    }
  },
  onBoarding: {
    uploadMessage: uploadMessage,
    scope: 102,
    challenge: _.clone(emptyChallenge),
    getTitle() {
      return `<span>UPLOAD YOUR PHOTOS</span>`;
    },
    myPhotos: false,
    message: true,
    submit: 'SUBMIT',
    getScopeId() {
      return this.challenge.id;
    }
  },
  swap: {
    scope: 101,
    challenge: _.clone(emptyChallenge),
    getTitle() {
      return `<i class="icon-swap"></i>
            <span>Select the photo you want to swap out</span>`;
    },
    myPhotos: true,
    tip: `Fact: Every one of your images receives equal exposure no matter when
          it was swapped-in or submitted`,
    message: true,
    submit: 'SWAP',
    getScopeId() {
      return this.challenge.id;
    }
  },
  chat: {
    scope: 500,
    member: _.clone(emptyMember),
    getTitle() {
      return `<span>Share photo to Team Chat</span>`;
    },
    myPhotos: true,
    message: false,
    submit: 'SHARE',
    getScopeId() {
      return this.member.id;
    }
  },
};
