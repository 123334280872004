const template = require(`html-loader!./spotlights.html`).default;
import SpotlightModel from '../../api/models/spotlight.model';
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";

class spotlightsCtrl {
  /** @param {RestManager} RestManager */
  constructor(
    RestManager,
    $element,
    $timeout,
    mixpanelService
  ) {
    'ngInject';
    this.items = [];

    this.RestManager = RestManager;
    this.$element = $element;
    this.$timeout = $timeout;
    this.mixpanelService = mixpanelService;
  }

  $onInit() {
    this.get_all();
  }

  get_all() {
    this.RestManager.getSpotlight(6).then((res) => {
      if(!res.items.length) {
        this.$element.remove();
        return true;
      }
      this.items = res.items.map(item => new SpotlightModel(item));
    });
  }

  eventMixPanelAction(spotlightType, uiCategory = -1) {
    this.mixpanelService.track(
      {
        data: {
          ui_category: uiCategory,
          ui_name: mixpanelEventsEnum.UI_NAME.SPOTLIGHT_BUTTON,
          spotlight_type: spotlightType.label.toLocaleLowerCase()
        }
      }
    );
  }
}

spotlightsCtrl.$inject = [
  'RestManager',
  '$element',
  '$timeout',
  'mixpanelService'
];

export default {
  template: template,
  controller: spotlightsCtrl
};
