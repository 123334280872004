class VoteManager {
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  getVoteData({c_id, limit, mode, url}) {
    return this.api['rest']['getVoteData']({c_id, limit, mode, url});
  }

  getVotePhotos(c_id) {
    return this.api['rest']['getVotePhotos']({c_id});
  }

  submitVotes({c_id, tokens, c_token, viewed_tokens}) {
    return this.api['rest']['submitVotes']({c_id, tokens, c_token, viewed_tokens});
  }

  submitVotes2({tokens, viewed_tokens, skipped_tokens, c_token}) {
    return this.api['rest']['submitBattleVotes']({tokens, viewed_tokens, skipped_tokens, c_token});
  }

  getBattleVotes({c_token, tokens, viewed_tokens}) {
    return this.api['rest']['getBattleVotes']({c_token, tokens, viewed_tokens});
  }

  submitGuruPicks({c_id, tokens, c_token, viewed_tokens}) {
    return this.api['rest']['submitGuruPicks']({c_id, tokens, c_token, viewed_tokens});
  }

  submitGuruTopPicks({c_id, tokens, c_token, viewed_tokens}) {
    return this.api['rest']['submitGuruTopPicks']({c_id, tokens, c_token, viewed_tokens});
  }
}

VoteManager.$inject = [
  'api'
];

export default VoteManager;
