export enum NgLocalStorageKeysEnum {
  LAST_PAYED_WITH_PAYPAL = 'LAST_PAYED_WITH_PAYPAL',
  TUTORIALS_FIRST_LOGIN = 'TUTORIALS_FIRST_LOGIN',
  SHOW_OOC = 'SHOW_OOC',
  LATEST_MISSIONS = 'LATEST_MISSIONS',
  CHALLENGES_SORT_ORDER_FILTER_SELECTIONS = 'CHALLENGES_SORT_ORDER_FILTER_SELECTIONS',
}
export enum ResourceType {
  COINS = 'COINS',
  FILLS = 'FILLS',
  KEYS = 'KEYS',
  SWAPS = 'SWAPS',
  BUNDLES = 'BUNDLES',
}

export enum TurboType {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
}

export enum MemberPermission {
  CREATE_CHALLENGE= 'CREATE_CHALLENGE',
  CHALLENGE_BID= 'CHALLENGE_BID',
}

export enum PrizeType {
  PHOTO_SWAP = 'PHOTO_SWAP',
  KEY = 'KEY',
  EXPOSURE_AUTOFILL = 'EXPOSURE_AUTOFILL',
  COINS = 'COINS',
}
