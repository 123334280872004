import { Component } from '@angular/core';

@Component({
  selector: 'app-challenges-completed-wrapper',
  templateUrl: './challenges-completed-wrapper.component.html',
  styleUrl: './challenges-completed-wrapper.component.scss'
})
export class ChallengesCompletedWrapperComponent {

}
