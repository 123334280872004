<div [id]="sectionType"
     class="section">
  <div class="section__title gs-center-v-flex gs-theme-text-bold-small gs-theme-color-white_100">
    <img src="/assets/images/pages/store/store-section-title-challenges.png"
         alt="shop">
  </div>
  <div class="section__categories__wrapper gs-m-12-a-0">
    <div class="section__categories gs-m-t-25"
         *ngFor="let category of storeSection.categories">
      <div [id]="category.name"
           class="section__category gs-center-v-flex">
        <div class="{{category.name}} section__category__title gs-center-v-flex gs-theme-text-headline-big gs-theme-color-white_100 gs-p-9-0">
          <i class="icon icon-match-stars"></i>
          <span>{{category.title}}</span>
          <i class="icon icon-match-stars gs-icon-flip"></i>
        </div>
        <div class="section__category__subtitle gs-t-a-c">{{category.subtitle}}</div>
      </div>
      <div class="section__category__item m-wrapper gs-d-f gs-j-c-c gs-f-w">
        <div *ngFor="let storeItem of category.items; let i = index"
             [ngClass]="{'m-min-size': category.items.length > 6}"
             class="section__category__item">
          <app-store-item (storeItemClicked)="storeService.storeItemSelected(storeItem, sectionType)"
                          [storeItem]="storeItem"
                          [sectionType]="sectionType"
          ></app-store-item>
        </div>
      </div>
    </div>
  </div>
</div>
