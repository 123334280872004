import badgeV2Mode from "../../api/enums/badgeV2.enum";
import challengeEnum from '../../api/enums/challenge.enum';
import memberEnum from '../../api/enums/member.enum';
import {ResourceType} from "../../../../core/models/enums";
import {extractContentFromHtml} from "../../helpers/string.helper";
import {Subject, takeUntil, delay, of} from "rxjs";
const template = require(`html-loader!./challenge.page.html`).default;

class challengePageCtrl {

  ResourceType = ResourceType;
  subject = new Subject();
  constructor(
    rankFilters,
    $scope,
    pageData,
    Modals,
    Facebook,
    $window,
    gsModals,
    $rootScope,
    ChallengesManager,
    deepLinkService,
    brazeService,
    challengePageService,
    NgChallengesService,
    MemberService,
    challengeService,
    NgMetaDataService,
    RouterService
  ) {
    'ngInject';
    this.isSubmitButtonBlocked = false;
    this.rankFilters = rankFilters;
    this.$scope = $scope;
    this.pageData = pageData;
    this.Modals = Modals;
    this.Facebook = Facebook;
    this.$window = $window;
    this.gsModals = gsModals;
    this.$rootScope = $rootScope;
    this.ChallengesManager = ChallengesManager;
    this.deepLinkService = deepLinkService;
    this.challengeEnum = challengeEnum;
    this.memberEnum = memberEnum;
    this.brazeService = brazeService;
    this.badgeV2Mode = badgeV2Mode;
    this.challengePageService = challengePageService;
    this.NgChallengesService = NgChallengesService;
    this.MemberService = MemberService;
    this.challengeService = challengeService;
    this.NgMetaDataService = NgMetaDataService;
    this.RouterService = RouterService;

    this.onFilterChanged = this.onFilterChanged.bind(this);
    this.onChangedRankPage = this.onChangedRankPage.bind(this);

    of({}).pipe(
      delay(10),
      takeUntil(this.subject)
    ).subscribe(async () => {
      this.member = this.MemberService.getCurrentMember();
      const challengeRes =  await this.NgChallengesService.getChallengeByUrl(this.url);
      if (!challengeRes.success) {
        this.RouterService.go('challenges/my-challenges/current');
        return;
      }
      // TODO refactor so challenge reference will be in only one service
      this.challengePageService.challenge = this.challenge = challengeRes.challenge;
      if (this.challenge.welcome_message) {
        this.challenge.welcome_message = this.challenge.welcome_message.replace(/\n|\r/g, "");
      }

      const ChallengeExtraText = ``;
      this.challengeTitle = `${this.challenge.title}${ChallengeExtraText}`
      this.NgMetaDataService.updateMetaTags({
        title: this.challengeTitle,
        description: extractContentFromHtml(this.challenge.welcome_message),
        keywords:[ 'photography challenge']
      });

      this.rankTabs = this.challengePageService.getRankTabs(this.url, this.challenge);

      // filters
      this.filters = this.rankFilters.getList(this.url);
      this.filter = this.rankFilters.getFilter();

      if (
        this.challenge.type === challengeEnum.TYPE.EXHIBITION &&
        this.challenge.status === challengeEnum.STATUSES.CLOSED.VALUE
      ) {
        const res = await this.ChallengesManager.getChallengeAutoSelectAttributes(this.challenge.id);
        if (res.success) {
          this.challengeLock = res.challenge_lock;
        }
      }

      this.done = true;
      this.deepLinkService.check({challenge:this.challenge});

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    });

    $scope.$on('$destroy', () => {
      this.offFilterChanged();
      this.offChangedRankPage();
      this.rankFilters.setFilter('default');
    });
  }

  $onInit() {
    this.$window.scrollTo(0, 0);

    // temporary patch for component structure
    this.tools = {
      linkFacebook: this.linkFacebook.bind(this),
    };

    this.offFilterChanged = this.$rootScope.$on('onFilterChanged', this.onFilterChanged);
    this.offChangedRankPage = this.$rootScope.$on('onChangedRankPage', this.onChangedRankPage);

    this.pageData.get('challenge');
  }

  async goToTab(event, state) {
    event.stopPropagation();
    await this.RouterService.reloadRoute(state, {});
    setTimeout(() => {window.scroll({ top: 620, behavior: 'smooth' })},1000);
  }

  setFilter(filter, event) {
    this.rankFilters.setFilter(filter, event);
  }

  linkFacebook() {
    this.Facebook.login();
  }

  isRankTabActive() {
    return this.RouterService.currentUrl.includes('/rank/');
  }

  async preSubmit() {
    this.isSubmitButtonBlocked = true;
    await this.challengeService.openPreSubmit(this.challenge);
    this.isSubmitButtonBlocked = false;
  }

  login(event) {
    this.Modals.open('login', event, { signUp: true });
  }

  vote() {
    if (this.member?.logged_in && this.challenge.status === challengeEnum.STATUSES.ACTIVE.VALUE) {
      this.gsModals.open('vote', { challenge: this.challenge });
    }
  }

  onFilterChanged(event, data) {
    this.filter = data.filter;
  }

  onChangedRankPage(event, data) {
    this.filter = this.rankFilters.filter;
    this.filters = this.rankFilters.getList(this.url);
  }

  duplicate(){
    this.Modals.open('createChallenge', null, {cUrl: this.challenge.url});
  }

  chest(){
    this.brazeService.sendChestEvent(this.challenge.id);
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    this.NgChallengesService.currentChallenge$.next(undefined)
  }
}
challengePageCtrl.$inject = [
  'rankFilters',
  '$scope',
  'pageData',
  'Modals',
  'Facebook',
  '$window',
  'gsModals',
  '$rootScope',
  'ChallengesManager',
  'deepLinkService',
  'brazeService',
  'challengePageService',
  'NgChallengesService',
  'MemberService',
  'challengeService',
  'NgMetaDataService',
  'RouterService'
];
export default {
  template: template,
  controller: challengePageCtrl,
  bindings: {
    url: '<'
  }
};
