import {Inject, Injectable} from '@angular/core';
import requestEnum from "./request/request.enum";
import {environment} from "../../../environments/environment";
import {DOCUMENT} from "@angular/common";

@Injectable()
export class UrlService {

  constructor(
    @Inject(DOCUMENT) private document: Document
  ) { }

  getEnvBaseUrl():string {
    return `${requestEnum.PROTOCOL}://${this.document.defaultView?.window.location.hostname}`;
  }

  getApiBaseUrl(controller:string):string {
    return `${requestEnum.PROTOCOL}://${environment.domains.api}/${controller}/`;
  }

  getEnvPageDataUrl(pagName:string):string {
    return `${this.getEnvBaseUrl()}/${pagName}`;
  }

  gePhotoDomain():string {
    return `https://${environment.domains.photos}`;
  }

  getImageUrl(memberId:string, imageId:string, width = 0, height = 0):string {
    return `${this.gePhotoDomain()}/unsafe/${this.getSize(width)}x${height}/${memberId}/3_${imageId}.jpg`;
  }

  getImageTokenUrl(token:string, width = 0, height = 0):string {
    return `${this.gePhotoDomain()}/unsafe/${this.getSize(width)}x${height}/${token}`;
  }

  getImageUrlForGalleryWall(memberId:string, imageId:string, width = 0, height = 0): string {
      return `${this.gePhotoDomain()}/unsafe/${this.getSize(width)}x${height}/center/top/${memberId}/3_${imageId}.jpg`;
  }

  public getSize(size:number){
    const sizeSteps =
      [200,300, 600,1200,1600,2000]
    for(const step of sizeSteps){
      if(size <= step){
        return step;
      }
    }
    return 0; //original
  }
}
