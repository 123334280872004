class PagesManager {
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  getPageData(url) {
    return this.api['restPages']['getPageData']({url});
  }
}

PagesManager.$inject = [
  'api'
];

export default PagesManager;
