import MemberModel from '../member.model';

class FollowerModel {
  constructor({member, photo}) {
    this.member = new MemberModel(member);
    this.photo = photo;
  }
}

export default FollowerModel;
