import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-exhibitions-page-wrapper',
  templateUrl: './admin-content-manager-exhibitions-page-wrapper.component.html',
  styleUrl: './admin-content-manager-exhibitions-page-wrapper.component.scss'
})
export class AdminContentManagerExhibitionsPageWrapperComponent {

}
