import {gsUploaderImage} from './gsUploaderImage';

const template = require(`html-loader!./gsUploaderFiles.html`).default;

class gsUploaderFilesComponent {

  constructor($element, Upload, $mdToast, $rootScope, $timeout, MemberService) {
    'ngInject';
    this.busy = false;
    this.gsUploaderImage = gsUploaderImage;
    this.files = [];
    this.inProgress = [];
    this.isMultiple = true;
    // uploader scopes
    this.uFiles = {}; // Key fileName, value file object
    this.uploading = []; // File waiting for upload to server
    this._invalidFiles = []; // Not valid files

    this.$element = $element;
    this.Upload = Upload;
    this.$mdToast = $mdToast;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.member = MemberService.getCurrentMember();


    this.onSuccess = this.onSuccess.bind(this);
    this.onError = this.onError.bind(this);
    this.onProgress = this.onProgress.bind(this);
  }

  $onInit() {
    this.isMultiple = this.settings.items_limit > 1;
    if (screen.availWidth <= 768) {
      this.$timeout(() => {
        $('.gs-uploader-files__drop-box').click();
      });
    }
    this.isAdmin = this.member.is_admin;
    this.validateRules = {
      minHeight: !this.isAdmin ? 700 : 0,
      minWidth: !this.isAdmin ? 700 : 0,
      pattern: !this.isAdmin ? '.jpg,.jpeg,.png' : '*'
    };
  }

  removeInvalidFile(file) {
    const index = this._invalidFiles.indexOf(file);
    if (index !== -1) {
      this._invalidFiles.splice(index, 1);
    }
  }

  // for multiple files:
  uploadFiles(files) {
    if (!files || !files.length) {
      return true;
    }
    this.files = files;

    let filesToThumb = [];
    for (const file of files) {
      // Skip if exist
      if (this.uFiles[file.name]) {
        continue;
      }

      // To thumb
      filesToThumb.unshift(file);

      // Set image
      this.uFiles[file.name] = {
        progress: 100,
        file: file
      };

      // File in progress
      this.inProgress.push(file);
    }

    // Build thumbs
    this.generateThumbs(filesToThumb);

    // Run in three flows
    this.upload(3);
  }

  generateThumbs(files) {
    let arr = [];
    for (let i = files.length - 1; i >= 0; i--) {
      this.gsUploaderImage.getThumbnailUrl(files[i]).then(url => {
        this.uFiles[files[i].name].url = url;
        arr.push(url);
        if(i === 0){
          this.settings.selectedImagesUrl = [...arr];
        }
      });
    }
  }

  upload(chunks = 1) {
    // no upload token
    if (!this.settings.token) {
      this.tools.close();
      return;
    }

    for (let i = 1; i <= chunks; i++) {
      if (!this.inProgress.length) return;

      // Get file for upload
      let file = this.inProgress.splice(0, 1)[0];
      this.uploading.push(file.name);
      // uploader params
      const params = {
        url: this.settings.upload_domain + '/rest/upload_image/' + this.settings.token,
        withCredentials: false,
        headers: {
          'Content-Type': 'multipart/form-data',
          'X-Requested-With': 'XMLHttpRequest'
        },
        data: {
          qqfile: file,
          qqfilename: file.name,
          qqtotalfilesize: file.size,
          qquuid: this.settings.token
        }
      };
      // upload to GuruShots
      this.Upload.upload(params).then(this.onSuccess, this.onError, this.onProgress);
    }
  }

  onProgress(evt) {
    const percent = 100 - parseInt(100.0 * evt.loaded / evt.total);
    this.uFiles[evt.config.data.qqfile.name].progress = percent >= 20 ? percent : 20;
  }

  onSuccess(resp) {
    // Update progress
    this.uFiles[resp.config.data.qqfile.name].progress = 0;

    // Update config
    if (resp.data.success) {
      this.uFiles[resp.config.data.qqfile.name].id = resp.data.data.id;
      this.uFiles[resp.config.data.qqfile.name].status = 'success';
    } else {
      this.uFiles[resp.config.data.qqfile.name].status = 'error';
    }

    // Remove from active tasks
    this.uploading.splice(this.uploading.indexOf(resp.config.data.qqfile.name), 1);

    // patch for boarding
    if (!this.inProgress.length && this.settings.autoSubmit) this.submit();

    this.upload(1);
  }

  onError(resp) {
    // console.log('Error status: ' + resp.status);

    // Update status
    this.uFiles[resp.config.data.qqfile.name].status = 'error';
    this.uFiles[resp.config.data.qqfile.name].progress = 0;

    // Remove from active tasks
    this.uploading.splice(this.uploading.indexOf(resp.config.data.qqfile.name), 1);
  }

  removeImage(image) {
    this.settings.selectedImagesUrl = [];
    for (const name in this.uFiles) {
      if (name === image.file.name) {
        delete this.uFiles[name];
        break;
      }
    }
    this.files = this.files.filter((file)=>{
      return file.name !== image.file.name;
    })
  }

  back() {
    this.tools.back();
  }

  submit() {
    if (this.uploading.length || !this.uFilesLength) return true;

    // Prepare images
    let images = [];
    for (const name in this.uFiles) {
      images.push({id: this.uFiles[name].id});
    }

    // submit callback
    this.tools.submit(images);
  }

  get uFilesLength() {
    return Object.keys(this.uFiles).length;
  }

  // Error handler
  set invalidFiles(files) {
    files = files.map(file => {
      console.log(file.$error);
      switch (file.$error) {
        case 'pattern':
          file.errorMessage = {
            title: 'At GuruShots we care about quality',
            message: `You can upload only *.jpg, *.jpeg or *.png
                      files`
          };
          break;
        case 'minWidth':
        case 'minHeight':
          file.errorMessage = {
            title: 'At GuruShots we care about quality',
            message: `Image dimensions are too small: <b>${file.$ngfWidth}×${file.$ngfHeight}</b>
                      <div>(minimum width & height must be 700px)</div>`
          };
          break;
        case 'maxFiles':
          file.errorMessage = {
            title: 'At GuruShots we care about quality',
            message: `Max upload files limit: <b>${this.settings.items_limit}</b>`
          };
          this.removeInvalidFile(file);
          break;
      }
      return file;
    });
    this._invalidFiles = files;
  }

  get invalidFiles() {
    return this._invalidFiles;
  }
}
gsUploaderFilesComponent.$inject = [
  '$element', 'Upload', '$mdToast', '$rootScope', '$timeout', 'MemberService'
];
export default {
  template: template,
  controller: gsUploaderFilesComponent,
  bindings: {
    settings: '=',
    tools: '='
  }
};
