const template = require(`html-loader!./mobileNotifications.html`).default;

const TYPE_ALL = 'all',
  TYPE_VOTE = 'vote',
  TYPE_ACTIVITY = 'activity';

class mobileNotificationsComponent {

  constructor(pageData, $timeout, $scope, $rootScope, RestManager, NotificationsManager, MemberService) {
    'ngInject';
    this.items = [];
    this.tabSelected = 0;
    this.open = false;
    this.inited = false;
    this.loading = false;
    this.lists = {
      vote: { type: TYPE_VOTE, start: 0, limit: 50, items: [], loading: false },
      activity: { type: TYPE_ACTIVITY, start: 0, limit: 50, items: [], loading: false }
    };
    this.tools = {
      close: () => {}
    };

    this.pageData = pageData;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.NotificationsManager = NotificationsManager;
    this.MemberService = MemberService;

    this.onScroll = _.debounce(this.onScroll.bind(this), 250);
  }

  $onInit() {
    // init
    this.initPage();
    // get data
    this.loadMore(TYPE_ALL);
    // iOs fix
    /*$('body, .gs-notifications__block').css({
      maxHeight: window.innerHeight + 'px'
    });*/
    // to top
    this.$timeout(() => {
      $('html, body').scrollTop(0);
      // events
      $('.gs-notifications__list').on('scroll', this.onScroll);
    });
  }

  $onDestroy() {
    $('.gs-notifications__list').off('scroll', this.onScroll);
  }

  /** @return void **/
  initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('mobileNotifications');

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  /** @return void **/
  async loadMore(type, force) {
    if (this.loading) {
      return;
    }
    this.loading = true;

    // clear data
    if (force) {
      this.lists.activity.start = 0;
      this.lists.vote.start = 0;
      this.lists.activity.loading = false;
      this.lists.vote.loading = false;
    }

    // for all request start && limit from vote
    let requestType = type === 'all' ? TYPE_ACTIVITY : type;
    let start = this.lists[requestType].start;
    let limit = this.lists[requestType].limit;

    if (this.lists[requestType].loading) {
      return;
    }
    this.lists[requestType].loading = true;

    const res = await this.NotificationsManager.get(start, limit, type);
    if (!res.success) {
      return;
    }

    // update lists
    if (force) {
      this.lists.activity.items = [];
      this.lists.vote.items = [];
      $(this.$element[0])
        .find('.gs-notifications__list')
        .scrollTop(0);
    }

    // update data
    this.new = res.new;

    if (type !== TYPE_ALL) {
      // increase start
      this.lists[type].start += this.lists[type].limit;
      this.lists[type].items.push(...res.items[type]);
    } else {
      Object.keys(this.lists).map((key, index) => {
        this.lists[key].start += this.lists[key].limit;
        this.lists[key].items.push(...res.items[this.lists[key].type]);
      });
    }
    this.inited = true;

    this.$timeout(() => {
      this.lists[requestType].loading = false;
      this.loading = false;
    });

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onScroll(event) {
    let element = event.currentTarget;
    console.log(element, 'onScroll');
    if (element.scrollTop === element.scrollHeight - element.offsetHeight) {
      this.loadMore($(element).data('type'));
    }
  }

  onTabClick() {
    console.log('onTabClick');
    this.$timeout(() => {
      this.$rootScope.$emit('lazyImg:refresh');
    }, 1000);
  }
}
mobileNotificationsComponent.$inject = [
  'pageData', '$timeout', '$scope', '$rootScope', 'RestManager', 'NotificationsManager', 'MemberService'
];
export default {
  template: template,
  controller: mobileNotificationsComponent
};
