<div class="ai-filter-wrapper">
  <div class="ai-filter-icon">
    <app-ng-image
      image="assets/svg/icon/ai-icon.svg"
      [fill]="true"
      [priority]="true"
    >
    </app-ng-image>
  </div>
</div>
