import { Component } from '@angular/core';

@Component({
  selector: 'app-moderation-offtopic-wrapper',
  templateUrl: './moderation-offtopic-wrapper.component.html',
  styleUrl: './moderation-offtopic-wrapper.component.scss'
})
export class ModerationOfftopicWrapperComponent {

}
