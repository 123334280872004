import {Inject, Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {BehaviorSubject, mergeMap, Observable} from "rxjs";
import {filter, map, tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  public lastRouteData$ = new BehaviorSubject<any>(null);

  constructor(
    private r: Router,
    public activatedRoute: ActivatedRoute,
    @Inject('photoSwipe')  private photoSwipe: any,
  ) { }

  get router(): Router {
    return this.r;
  }

  get currentUrl(): string {
    return this.router.url;
  }

  get routerEvents(): Observable<any> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        this.onRoutChange();
        return this.activatedRoute
      }),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
      tap(data => this.lastRouteData$.next(data))
    );
  }

  private onRoutChange(){
    this.photoSwipe.close();
  }

  go(route: string, queryParams = {}, reload = false): void {
    void this.router.navigate([route], { queryParams })
      .then(() => {
        if (reload) {
          this.reloadCurrentRoute();
        }
      });
  }

  reloadCurrentRoute(): void {
    this.reloadRoute(this.router.url);
  }

  async reloadRoute(url:string, queryParams = {}) {
    await this.router.navigateByUrl('/', { skipLocationChange: true });
    return await this.router.navigate([url], queryParams);
  }

  removeQueryParams() {
    // Navigate to the same path without any query parameters
    const url = this.router.url.split('?')[0];
    return this.router.navigate([url], {
      queryParams: {}, // Clear all query params
      replaceUrl: true // Replace the current history entry so the user doesn't go back to the URL with query params
    });
  }
}
