export const topTabMenuData = {
  TYPE: {
    CHALLENGES: {
      NAME:'CHALLENGES',
      TABS: [
        {
          TITLE: 'Active',
          state: 'challenges/my-challenges/current'
        },
        {
          TITLE: 'Manage',
          state: 'challenges/manage',
          permissions: ['CREATE_CHALLENGE', 'CHALLENGE_BID']
        },
        {
          TITLE: 'Completed',
          state: 'challenges/my-challenges/completed'
        },
        {
          TITLE: 'Discover',
          state: 'challenges/open'
        },
        {
          TITLE: 'Upcoming',
          state: 'challenges/upcoming'
        },
        {
          TITLE: 'Closed',
          state: 'challenges/closed'
        },
      ]
    },
    CHALLENGES_MOBILE_MY_CHALLENGES: {
      NAME:'CHALLENGES_MOBILE_MY_CHALLENGES',
      TABS: [
        {
          TITLE: 'ACTIVE',
          state: 'challenges/my-challenges/current'
        },
        {
          TITLE: 'COMPLETED',
          state: 'challenges/my-challenges/completed'
        }
      ]
    },
    CHALLENGES_MOBILE_DISCOVER: {
      NAME:'CHALLENGES_MOBILE_DISCOVER',
      TABS: [
        {
          TITLE: 'OPEN',
          state: 'challenges/open'
        },
        {
          TITLE: 'UPCOMING',
          state: 'challenges/upcoming'
        },
      ]
    },
    INDIVIDUAL_LEADERBOARD_INFO: {
      NAME:'INDIVIDUAL_LEADERBOARD_INFO',
      TABS: [
        {
          TITLE: 'INFO',
          SELECTED: true
        },
        {
          TITLE: 'RULES',
        },
      ]
    },
    ACHIEVEMENTS: {
      NAME:'ACHIEVEMENTS',
      TABS: [
        {
          IMAGE: 'achievements.svg',
          TITLE: 'ACHIEVEMENTS',
          state: '${profileName}/achievements/achievements'
        },
        {
          IMAGE: 'challenges.svg',
          TITLE: 'CHALLENGES',
          state: '${profileName}/achievements/challenges'
        }
      ]
    },
  }
};
