const template = require(`html-loader!./adminSubMenu.html`).default;

class adminSubMenuCtrl {
  constructor(RouterService) {
    'ngInject';
    this.RouterService = RouterService;
  }

  isActiveClassAdded(route, name) {
    if (
      this.RouterService.currentUrl.includes('challengesSchedule') &&
      route.includes('challengesSchedule')
    ) {
      return true
    }

    if (
      this.RouterService.currentUrl.includes('exhibitions') &&
      route.includes('exhibitions') &&
      name === 'Exhibitions') {
      return true
    }
    if (
      this.RouterService.currentUrl.includes('contentManager/challenges') &&
      route.includes('contentManager/challenges') &&
      name === 'Challenges'
    ) {
      return true
    }

    if (
      this.RouterService.currentUrl.includes('contentManager/teams') &&
      route.includes('contentManager/teams') &&
      name === 'Teams'
    ) {
      return true
    }

    if (
      this.RouterService.currentUrl.includes('contentManager/teams/leagues') &&
      route.includes('contentManager/teams/leagues') &&
      name === 'Leagues'
    ) {
      return true
    }
    return false;
  }

  goToChallengeEditor() {
    window.open(`challenges/my-challenges/editor/null`);
  }
}

export default {
  template: template,
  controller: adminSubMenuCtrl,
  bindings: {
    items: '<'
  }
};
