class GsModalsService {

  constructor(rx) {
    'ngInject';
    this.rx = rx;
    this.onOpen = new rx.Subject();
  }

  open(name, data) {
    this.onOpen.onNext({name, data});
  }
}

GsModalsService.$inject = [
  'rx'
];
export default GsModalsService;
