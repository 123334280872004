import { Component } from '@angular/core';

@Component({
  selector: 'app-partners-wrapper',
  templateUrl: './partners-wrapper.component.html',
  styleUrl: './partners-wrapper.component.scss'
})
export class PartnersWrapperComponent {

}
