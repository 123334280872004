import CardCustomDataModel from './cardCustomData.model';

class CardModel {
  /* TODO: refactor.
      #1 One switch statement.
      #2 AdminCard extends Card... Can we simplify/avoid Card?
      */
  constructor({id, priority, type, data = {}}) {
    switch (type) {
      case 'custom':
        data = new CardCustomDataModel(data);
    }
    Object.assign(this, {id, priority, type, data});
  }
}

export default CardModel;
