import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {Mission} from "../../missions.interface";
import {MissionClaimState} from "../../missions.enum";
import {GsTimerEnum} from "../../../../shared/pipes/gs-timer/gs-timer.enum";
import challengeEnum from "../../../../gsApp/app/api/enums/challenge.enum";
import GsTimerService from "../../../../core/services/gs-timer.service";
import {NgDateHelper} from "../../../../core/helpers/ngDateHelper";
import {MissionsService} from "../../services/missions.service";
import {RouterService} from "../../../../core/services/router.service";

@Component({
  selector: 'app-mission-card',
  templateUrl: './mission-card.component.html',
  styleUrl: './mission-card.component.scss',
  providers: [GsTimerService],
})
export class MissionCardComponent implements OnDestroy {
  @Input() set mission(value: Mission) {
    if (value) {
      if (value.initialOffset && this.mission?.progress?.current < value.progress.current) {
        const previousProgress = this.mission?.progress?.current || 0;
        this._mission = {
          ...value,
          progress: {
            current: previousProgress,
            required: this.mission.progress.required
          }
        };
      } else {
        this._mission = value;
      }

      if (value.expiration_timestamp && value.claim_state !== MissionClaimState.CLAIM) {
        this.startTimer(value.expiration_timestamp * 1000);
      }
    }
  };
  @Input() isAnimationDisabled = true;
  @Output() onTimerExpired = new EventEmitter<Mission>;
  @Output() onClaimed = new EventEmitter<Mission>;

  get mission() {
    return this._mission;
  }

  private _mission!: Mission;

  protected readonly challengeEnum = challengeEnum;
  protected readonly NgDateHelper = NgDateHelper;

  public missionClaimState = MissionClaimState;
  public gsTimerEnum = GsTimerEnum;
  public countDownTime = 0;

  getProgressWidth(): string {
    return `calc(${(this.mission.progress.current / this.mission.progress.required) * 100}%`;
  }

  constructor(
    private missionsService: MissionsService,
    private gsTimerService: GsTimerService,
    private routerService: RouterService,
  ) {
  }

  startTimer(time: number) {
    this.gsTimerService.start({
      expireTime: time,
      timesUpHandler: this.timesUpHandler.bind(this),
      timeTickingHandler: this.timeTickingHandler.bind(this)
    });
  }

  timesUpHandler(): void {
    this.onTimerExpired.emit(this.mission);
  }

  timeTickingHandler(countDownTimestamp: number): void {
    this.countDownTime = countDownTimestamp;
  }

  actionButtonClick(): void {
    if (this.mission.claim_state === MissionClaimState.DISABLED) {
      if(this.mission.web_link){
        const url = new URL(this.mission.web_link);
        let queryParams: { [key: string]: string } = {};
        if(url.searchParams.size){
          url.searchParams.forEach((value, key) => {
            queryParams[key] = value;
          });
        }
        this.routerService.go(url.pathname, queryParams);
      }
    } else if (this.mission.claim_state === MissionClaimState.CLAIM) {
      this.onClaimed.emit(this._mission);
    }
  }

  isMissionDirty(id: number): boolean {
    return !!this.missionsService.missionsDirtyMap.get(id);
  }

  ngOnDestroy(): void {
    this.gsTimerService.stop();
  }
}
