import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-ai-terms',
  templateUrl: './ai-terms.component.html',
  styleUrls: ['./ai-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AiTermsComponent {

}
