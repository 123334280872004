/*
 * v.1.0.0
 * Trigger gsUploader
 *
 * <tag attr>: gs-uploader="target:string"
 *             challenge="challenge:object"
 *             member="member:object"
 * */
function gsUploader(
  Modals
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      gsUploader: '@',
      challenge: '<',
      member: '<'
    },
    link: function (scope, element, attrs) {

      element[0].addEventListener("click", (event) => {
        event.preventDefault();

        Modals.open('gsUploader', event, {
          target: scope.gsUploader,
          member: scope.member,
          challenge: scope.challenge
        });

        return false;
      });
    }
  };
}

gsUploader.$inject = [
  'Modals'
];

export default gsUploader;
