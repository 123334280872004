const template = require(`html-loader!./challengesCalendar.html`).default;
import vis from 'vis/dist/vis';
import moment from 'moment/moment';
import challengeEnum from '../../../../../api/enums/challenge.enum';

class challengesCalendarCtrl {

  constructor(ChallengesManager, $scope) {
    'ngInject';
    this.timeLine;
    this.challengesCount = [];
    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
  }

  $onInit() {
    window.vis = vis;
    this.getData();
  }

  async getData() {
    const active = await this.ChallengesManager.listChallenges({
      status: challengeEnum.STATUSES.ACTIVE.VALUE,
      start: 0,
      limit: 1000,
      order: 'DESC',
    });
    const upcoming = await this.ChallengesManager.listChallenges({
      status: challengeEnum.STATUSES.UPCOMING.VALUE,
      start: 0,
      limit: 1000,
      order: 'DESC',
    });
    if (!active.success || !upcoming.success) return;
    // merge challenges
    let challenges = [];
    challenges.push(...active.challenges);
    challenges.push(...upcoming.challenges);
    // items
    let items = [];
    for (let challenge of challenges) {
      this.setChallengesCount(challenge);
      const brand = challenge.sponsors && challenge.sponsors[0] ? ' [' + challenge.sponsors[0].brand.trim() + ']' : '';
      items.push({
        id: challenge.id,
        start: new Date(challenge.start_time),
        end: new Date(challenge.close_time),
        content: `${challenge.title} (${challenge.id}) ${brand}`,
        title: `${challenge.title} (${challenge.id}) ${brand}`,
        className: `${challenge.type} ${challenge.status}`,
      });
    }
    // init dataSet
    items = new vis.DataSet(items);
    const container = document.getElementById('challenges-calendar__visualization');
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    const options = {
      start: new Date(y, m, 1),
      end: new Date(y, m + 1, 0),
      min: new Date(y - 1, 0, 1),
      max: new Date(y + 2, 0, 0),
      orientation: 'top',
      clickToUse: true,
      zoomMin: 864000000,
      zoomMax: 31536000000,
    };
    this.timeLine = new vis.Timeline(container, items, options);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  currentMonth() {
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth();
    this.timeLine.setOptions({
      start: new Date(y, m, 1),
      end: new Date(y, m + 1, 0),
    });
  }

  setChallengesCount(challenge) {
    let startDate = moment(challenge.start_time);
    let endDate = moment(challenge.close_time);
    while (startDate <= endDate) {
      let y = moment(startDate).format('YYYY'); /* TODO: Date format ENUM? */
      let mmDD = moment(startDate).format('DD.MM'); /* TODO: Date format ENUM? */
      // find year index
      let yearIndex = _.findIndex(this.challengesCount, { year: y });
      if (yearIndex === -1) {
        this.challengesCount.push({
          year: y,
          dates: [],
        });
        yearIndex = _.findIndex(this.challengesCount, { year: y });
      }
      // find month index
      let mmDDIndex = _.findIndex(this.challengesCount[yearIndex].dates, { mmDD: mmDD });
      if (mmDDIndex === -1) {
        this.challengesCount[yearIndex].dates.push({
          mmDD: mmDD,
          total: 0,
          opening: 0,
          closing: 0,
        });
        mmDDIndex = _.findIndex(this.challengesCount[yearIndex].dates, { mmDD: mmDD });
      }
      // total challenges
      this.challengesCount[yearIndex].dates[mmDDIndex].total += 1;
      // opening
      if (moment(challenge.start_time).format('DD.MM') === moment(startDate).format('DD.MM')) {
        this.challengesCount[yearIndex].dates[mmDDIndex].opening += 1;
      }
      // closing
      if (moment(challenge.close_time).format('DD.MM') === moment(startDate).format('DD.MM')) {
        this.challengesCount[yearIndex].dates[mmDDIndex].closing += 1;
      }
      // next
      startDate = moment(startDate).add(1, 'days');
      // current day
      this.currentYear = moment(new Date()).format('YYYY');
      this.currentDay = moment(new Date()).format('DD.MM');
    }
  }
}
challengesCalendarCtrl.$inject = [
  'ChallengesManager', '$scope'
];
export default {
  template: template,
  controller: challengesCalendarCtrl,
  bindings: {},
};
