const template = require(`html-loader!./trySwap.html`).default;

class trySwapCtrl {

  constructor($mdToast, Modals, challengeService) {
    'ngInject';
    this.$mdToast = $mdToast;
    this.Modals = Modals;
    this.challengeService = challengeService;
  }

  swap(event, image) {
    this.close();

    if (this.challengeService.isSwapsEmpty()) {
      this.challengeService.openStoreSwapsSection();
      return true;
    }

    // Swap
    this.Modals.open('gsUploader', event, {
      target: 'swap',
      challenge: this.challenge
    });
  }

  close() {
    this.$mdToast.hide();
  }
}
trySwapCtrl.$inject = [
  '$mdToast', 'Modals', 'challengeService'
];
export default {
  template: template,
  controller: trySwapCtrl,
  bindings: {
    challenge: '<',
  }
};
