const template = require(`html-loader!./teamName.html`).default;

class teamNameCtrl {
  constructor(
    $rootScope,
    $element,
    stateService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$element = $element;
    this.stateService = stateService;
  }

  onClick(){
    if (!this.team.id || !this.clickable) {
      return;
    }

    if(this.goToAdminTeamManager === 'true'){
      this.stateService.goToState(`admin/teamsManager/${this.team.id}`, {}, true);
    } else {
      this.stateService.goToState(`teams/info/${this.team.id}`);
    }
  }
}

teamNameCtrl.$inject = [
  '$rootScope',
  '$element',
  'stateService'
];

export default {
  template: template,
  controller: teamNameCtrl,
  bindings: {
    team: '<',
    clickable: '@',
    hideIcons: '@',
    goToAdminTeamManager: '@',
    newTab: '@',
  }
};
