import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-achievements-achievements-wrapper',
  templateUrl: './profile-achievements-achievements-wrapper.component.html',
  styleUrl: './profile-achievements-achievements-wrapper.component.scss'
})
export class ProfileAchievementsAchievementsWrapperComponent {

}
