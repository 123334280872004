import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IStoreItem} from "../../services/store.service.types";
import {SectionType} from "../../models/enums";
import {NgModalsService} from "../../../angular-material/services/ng-modals.service";

@Component({
  selector: 'app-store-item',
  templateUrl: './store-item.component.html',
  styleUrls: ['./store-item.component.scss']
})
export class StoreItemComponent  implements OnInit{
  @Input() storeItem!: IStoreItem;
  @Input() sectionType!: SectionType;
  // @Input() categoryType!: ResourceType | StoreResourceType;
  @Output() storeItemClicked = new EventEmitter();
  constructor(
    private ngModalService:NgModalsService
  ) {}

  openInfoModal(){
    this.ngModalService.openStoreItemInfoModal(this.storeItem.received);
  }

  ngOnInit(): void {
    // this.storeItem.received = [...this.storeItem.received, this.storeItem.received[0]]
  }
}
