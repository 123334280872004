import {
  AfterViewInit,
  Component,
  ContentChildren,
  Directive,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import {animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style} from "@angular/animations";
import {CarouselItemDirective} from "../../directives/carousel-item.directive";

@Directive({
  selector: '.carousel-item'
})
export class CarouselItemElement {
}

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class CarouselComponent implements AfterViewInit {
  @ContentChildren(CarouselItemDirective) items!: QueryList<CarouselItemDirective>;
  @ViewChildren(CarouselItemElement, { read: ElementRef }) private itemsElements!: QueryList<ElementRef>;
  @ViewChild('carousel') private carousel!: ElementRef;
  @Input() timing = '1s ease-in';
  @Input() currentSlide = 0;
  @Input() borderWidth = 16;
  @Input() carouselWrapperStyle = {width: ''};
  @Input() fadeOptions: {isFadeOverlayVisible: boolean}
    = {isFadeOverlayVisible: false};
  private player!: AnimationPlayer;
  private itemWidth!: number;

  constructor(private builder: AnimationBuilder) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.itemWidth = this.itemsElements.first.nativeElement.getBoundingClientRect().width;
      const itemWidthWithBorders  = this.itemWidth + this.borderWidth;
      this.carouselWrapperStyle = {
        ...this.carouselWrapperStyle,
        width: `${itemWidthWithBorders}px`
      };
      if (this.currentSlide > 0) {
        const offset = this.currentSlide * this.itemWidth;
        const myAnimation = this.buildAnimation(offset, '0s');
        this.player = myAnimation.create(this.carousel.nativeElement);
        this.player.play();
      }
    }, 250);
  }

  next(): void {
    if (this.currentSlide + 1 === this.items.length ) return;

    this.currentSlide = (this.currentSlide + 1) % this.items.length;
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }

  prev(): void {
    if (this.currentSlide === 0) return;

    this.currentSlide = ((this.currentSlide - 1) + this.items.length) % this.items.length;
    const offset = this.currentSlide * this.itemWidth;

    const myAnimation = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }

  goToSlideByIndex(index: number): void {
    this.currentSlide = index;
    const offset = index * this.itemWidth;
    const myAnimation = this.buildAnimation(offset);
    this.player = myAnimation.create(this.carousel.nativeElement);
    this.player.play();
  }

  private buildAnimation(offset: number, timing?: string): AnimationFactory {
    return this.builder.build([
      animate(timing ?? this.timing, style({ transform: `translateX(-${offset}px)` }))
    ]);
  }
}
