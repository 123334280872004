import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MiniGameImageInterface} from "../../interfaces/mini-game.interface";
import {delay, Subject, takeUntil} from "rxjs";
import {tap} from "rxjs/operators";
import {imageWidth, voteAnimationTime} from "../../challenges.constants";
import {VoteResultInterface} from "../../interfaces/vote-result.interface";

@Component({
  selector: 'app-mini-game-voting',
  templateUrl: './mini-game-voting.component.html',
  styleUrls: ['./mini-game-voting.component.scss']
})
export class MiniGameVotingComponent implements OnDestroy {
  @Input() images: MiniGameImageInterface[] = [];
  @Input() currentIndex = 0;
  @Input() isVoteSubmitGoing = true;
  @Input() isLoading = true;
  @Output() voteSubmit = new EventEmitter<string>();

  protected readonly imageWidth = imageWidth;
  public isFirstImageActive = true;
  public voteState: boolean | null = null;
  public firstImageScore = 0;
  public secondImageScore = 0;
  private setVote$ = new Subject<VoteResultInterface>();
  private destroy$ = new Subject<void>();

  constructor() {
    this.setVote$.pipe(
      tap(voteResult => {
        this.voteState = voteResult.is_successful_selection;
        this.firstImageScore = voteResult.scores.first_image;
        this.secondImageScore = voteResult.scores.second_image;
      }),
      delay(voteAnimationTime),
      takeUntil(this.destroy$)
    ).subscribe(() => this.voteState = null);
  }

  submitImage(imageId: string, isFirstImageActive: boolean): void {
    this.isFirstImageActive = isFirstImageActive;
    this.voteSubmit.emit(imageId);
  }

  setVote(voteResult: VoteResultInterface): void {
    this.setVote$.next(voteResult);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
