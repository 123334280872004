import {ChallengeTurboState, ChallengeUnlockType} from "../../../../../modules/challenges/enums/challenges.enum";

const template = require(`html-loader!./challengeActionActivate.html`).default;
import challengeEnum from "../../../api/enums/challenge.enum";
import {NgChallengesService} from "../../../../../modules/challenges/services/ng-challenges.service";

class challengeActionActivateModalCtrl {
  /**
   * @param {RestManager} RestManager
   * @param {challengeService} challengeService
   * */

  constructor(
    $mdDialog,
    $timeout,
    challengeService,
    $scope,
    NgChallengesService,
    MediatorService
  ){
    "ngInject";
    this.$mdDialog = $mdDialog;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.challengeService = challengeService;
    this.NgChallengesService = NgChallengesService;
    this.mediatorService = MediatorService;
    challengeEnum

    this.inited = false;
    this.photoSelected = false;
  }

  close() {
    this.$mdDialog.hide();
  }

  $onChanges() {
    if (!this.data || !this.data.challenge) {
      this.close();
      return;
    }
    this.images = this.data.challenge.member.ranking.entries.filter((image)=>{
      return !image.boosted && !image.turbo;
    })
    this.image = this.isTurbo() ? '/assets/images/modals/turbo/turbo-rules.png' : '/assets/images/gsApp/modals/modal-boost-cover.png';
    this.activatingText = this.isTurbo() ? 'ACTIVATING...' : 'BOOSTING...';
    this.title = this.isTurbo() ? 'CHOOSE YOUR PHOTO' : 'SELECT WHICH PHOTOS TO BOOST';
    this.text = this.isTurbo() ? 'Select which of your Photos will get the amazing Turbo!' :
      'The photo you select will get an amazing exposure boost!';
    this.$timeout(() => this.inited = true, 100);
  }

  isTurbo(){
    return this.data.type === ChallengeUnlockType.TURBO;
  }

  async onPhotoSelect(image) {
    if (!this.photoSelected) {
      this.photoSelected = true;
      let res;
      if(this.isTurbo() ){
        res = await this.NgChallengesService.setChallengeTurbo(this.data.challenge.id, image.id);
        this.data.challenge.setTurboState(ChallengeTurboState.USED);
        image.turbo = true;
      } else {
        res = await this.challengeService.boostPhoto(image, this.data.challenge);
        this.data.challenge.member.boost.state = challengeEnum.BOOST.USED;
      }
      // this.mediatorService.broadcast(MediatorMessageKey.RELOAD_ACTIVE_CHALLENGES);
      if (!res.success) {
        this.close();
      } else {
        this.$timeout(() => {
          this.close();
        }, 1000);
      }
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
  }
}
challengeActionActivateModalCtrl.$inject = [
  '$mdDialog',
  '$timeout',
  'challengeService',
  '$scope',
  'NgChallengesService',
  'MediatorService'
];
export default {
  template: template,
  controller: challengeActionActivateModalCtrl,
  bindings: {
    data: '=data'
  }
};
