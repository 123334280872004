<div class="container">
  <div class="title gs-theme-text-headline gs-theme-color-black_100">Confirm</div>
  <div class="gs-theme-text-regular gs-theme-color-black_100">{{data.message}}</div>
  <div class="actions">
    <div [mat-dialog-close]="false"
         class="cancel gs-theme-text-headline-sub-bold gs-theme-color-blue_100 gs-c-p">Cancel</div>
    <div class="gs-p-r">
      <button [mat-dialog-close]="true"
              class="ok gs-theme-bg-color-blue_100 gs-theme-text-headline-sub-bold gs-theme-color-white_100">Ok</button>
      <app-resource-indicator *ngIf="data.resourceType && data.resourceTypeAmount"
                              [resource]="data.resourceType" [amount]="data.resourceTypeAmount"></app-resource-indicator>
    </div>
  </div>
</div>

