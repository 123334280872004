import {InfoService} from "../../../../../../../core/services/info.service";
import {environment} from "../../../../../../../../environments/environment";
import {autoUpdateTypeEnum} from "../../../../../services/autoUpdate.service.data";
import {NgDateHelper} from "../../../../../../../core/helpers/ngDateHelper";

const template = require(`html-loader!./bulkUpload.html`).default;

class bulkUploadCtrl {

  busy = false;

  constructor(Upload, InfoService, RestManager, autoUpdateService) {
    'ngInject';
    this.Upload = Upload;
    this.infoService = InfoService;
    this.restManager = RestManager;
    this.autoUpdateService = autoUpdateService;
    this.challengesAmountAwaitingExport = 1;
  }

  $onInit() {
    this.initAutoUpdate();
  }

  initAutoUpdate() {
    let autoUpdateConfig = {
      type: autoUpdateTypeEnum.CHALLENGES_BULK_UPLOAD,
      callback: this.updateTotalChallengesAwaitingImport.bind(this),
      initCallbackOnStart: true,
      delay: 5 * NgDateHelper.millisecondsInSecond
    };
    this.autoUpdateService.startAutoUpdateByType(autoUpdateConfig);
  }

  stopAutoUpdate(){
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.CHALLENGES_BULK_UPLOAD);
  }

  async updateTotalChallengesAwaitingImport(){
    const result = await this.restManager.getTotalChallengesAwaitingImport();
    if (result.success) {
      this.challengesAmountAwaitingExport = result.total;
    } else {
      this.challengesAmountAwaitingExport = 1;
    }
  }

  async upload(files) {
    if (this.busy || !files || !files.length) {
      return true;
    }

    const file = files[0];

    const params = {
      url: `https://${environment.domains.api}/rest/challenge_upload_csv`,
      withCredentials: false,
      headers: {
        'Content-Type': 'multipart/form-data',
        'X-Requested-With': 'XMLHttpRequest'
      },
      data: {challenges_csv: file}
    };
    this.busy = true;
    const messageSeconds = 10
    this.Upload.upload(params).then(response => {
      const data = response.data;
      if (data && data.success) {
        this.infoService.showErrorMessage(
          {
            message:'Challenges Successfully Uploaded',
            seconds:messageSeconds
          });
      } else {
        this.infoService.showErrorMessage(
          {
            message:data.message,
            seconds:messageSeconds
          });
      }
      this.busy = false;
    }).catch(()=>{
      this.infoService.showErrorMessage(
        {
          message:'Global Error',
          seconds:messageSeconds
        });
      this.busy = false;
    });
  }

  $onDestroy() {
    this.stopAutoUpdate();
  }
}
bulkUploadCtrl.$inject = [
  'Upload', 'InfoService', 'RestManager', 'autoUpdateService'
];
export default {
  template: template,
  controller: bulkUploadCtrl,
  bindings: {},
};
