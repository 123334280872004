class ExhibitionsManager {
  /**
   * @param {api} api
   * */
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  getExhibitionPageItems(id) {
    return this.api['exhibitions']['getExhibitionPageItems']({id});
  }

  setExhibitionPageItem({id, published, c_id, country, city, country_code, date, name, summary_votes, summary_photos,
                          summary_countries, summary_members, media_type, media_id, comment_text,
                          comment_member_id, comment_image_id, action_text, action_url, sponsor_url, sponsor_image_id}) {
    return this.api['rest']['setExhibitionPageItem']({id, published, c_id, country, city, country_code, date, name,
      summary_votes, summary_photos, summary_countries, summary_members, media_type, media_id, comment_text,
      comment_member_id, comment_image_id, action_text, action_url, sponsor_url, sponsor_image_id});
  }

  delExhibitionPageItem(id) {
    return this.api['rest']['delExhibitionPageItem']({id});
  }

  getExhibitionWinners(data) {
    return this.api['rest']['getExhibitionWinners'](data);
  }

  setExhibitionWinners(data) {
    return this.api['rest']['setExhibitionWinners'](data);
  }
}

ExhibitionsManager.$inject = [
  'api'
];

export default ExhibitionsManager;
