export const partners = [
  {
    name: 'Adobe',
    text: `
    <b>Adobe Stock</b> is a service that provides designers and businesses with access to 45 million high-quality,
    curated, royalty-free photos, videos, illustrations, and vector graphics for all their creative projects.
    `,
  },
  {
    name: 'Wix',
    text: `
    <b>Wix</b>’s powerful technology makes it easy for everyone to get online with a stunning, professional and functional 
    web presence. No creative limits, no coding – just complete freedom to express yourself and manage your entire 
    business online.
    `,
  },
  {
    name: 'Kodak',
    text: `
    With their world-class R&D capabilities, innovative solutions portfolio, and highly trusted brand, <b>Kodak</b> is 
    helping customers around the globe to sustainably grow their businesses and enjoy their lives.
    `,
  },
  {
    name: 'B&H',
    text: `
    <b>B&H</b> has been the USA’s premier photography store for over 40 years. As an authorized seller of Canon, Fuji, 
    Nikon, Olympus, Panasonic, Sony and more, we are committed to providing the best camera gear along with superior 
    knowledge, prices, and shipping.
    `,
  },
  {
    name: 'Adorama',
    text: `
    <b>Adorama</b> is listed as one of the top five electronics retailers by Consumer Reports, “Best of the Web” by 
    Forbes.com, Internet Retailers Top 100, and is the official Electronics Retailer of the NY Giants.
    `,
  },
  {
    name: 'Skylum',
    text: `
    <b>Skylum</b> is a global imaging technology company with offices in Bellevue (USA), Kiev (Ukraine) and Tokyo (Japan).
     Skylum allows millions of photographers to make incredible images faster. They automate photo editing with the 
     power of Artificial Intelligence, yet keep all the creative control in the hands of the artist. The Skylum Team 
     has won the Red Dot Award, Apple’s Best of the Year, and other top industry awards. Makers of Luminar, Photolemur, and Aurora HDR
    `,
  },
  {
    name: 'Polaroid',
    text: `
    <b>Polaroid</b> heritage brands symbolize, embody and provide tremendous familiarity with quality products ranging from
    instant and digital still cameras, high-definition and mountable sports action video cameras, tablets, 
    high-definition TVs, mobile apps and apparel
    `,
  },
  {
    name: 'Tamron',
    text: `
    With its firm commitment to developing high-quality, innovative and technologically advanced products that satisfy
    customer needs, <b>Tamron</b> secures its leading position in the global optical industry
    `,
  },
  {
    name: 'Manfrotto',
    text: `
    Based in Northern Italy at Cassola, <b>Manfrotto</b> designs, manufactures and markets a wide range of camera and 
    lighting support equipment for the professional photographic, film, theater, live entertainment and video markets.
    The product line includes an extensive range of camera tripods and heads, lighting stands and accessories.
    `,
  },
  {
    name: 'Zazzle',
    text: `
    <b>Zazzle</b> is an on-demand marketplace where we connect customers with artists’ beautiful designs on the world’s best
    products so anything imaginable can be created.
    `,
  },
  {
    name: 'Blackrapid',
    text: `
    Trusted by photographers worldwide, we create our products with high quality materials and innovative designs. 
    We know you’ll feel the <b>BLACKRAPID</b> difference from the moment you first put on one of our straps.
    `,
  },
  {
    name: 'Kelbyone',
    text: `
    <b>Kelbyone</b> is an educational membership community designed to fuel your creativity.We are driven by a passion 
    to provide incredible training for Photoshop, Lightroom, and Photography made affordable for everyone.
    `,
  },
  {
    name: 'Skillshare',
    text: `
    <b>Skillshare</b> is an online learning community with thousands of classes in design, business, tech, and more. 
    Anyone can join the millions of members in our community to learn cutting-edge skills, network with peers and 
    discover new opportunities.
    `,
  },
  {
    name: 'Mpbcom',
    text: `
    <b>mpb.com</b> is the platform that is changing the way photographers and filmmakers trade equipment online, forever.
    We offer a hassle-free and secure process for sellers and a retail experience that makes buying used equipment as
    simple and enjoyable as buying new.
    `,
  },
  {
    name: 'SmugMug',
    text: `
    With a <b>SmugMug</b> portfolio, you’re going to look like a pro even if you’ve never sold a photo before. You’ll have 
    the power to make your work look and feel totally unique, and we make sure your photos look their best across 
    computers and mobile devices
    `,
  },
  {
    name: 'Lowepro',
    text: `
    <b>Lowepro</b> specialized backpacks, cases and camera bags have been an essential part of countless adventures 
    through every climate and condition over the last 50 years. Our evolutionary designs elevate the way you carry, 
    protect and organize your gear.
    `,
  },
  {
    name: 'Zenfolio',
    text: `
    <b>Zenfolio</b> provides professional photo and video hosting for photographers. Selling and ordering, unlimited storage,
    secure client access, proofing and more.
    `,
  },
  {
    name: 'ThinkTank',
    text: `
    <b>Think Tank</b> Photo designs camera carrying solutions for working professionals. Our products are born from 
    the collaborative efforts of professional photographers and product designers, working together with a common 
    goal — to create the most inventive and effective solutions for all photographic situations.
    `,
  },
  {
    name: 'Denny',
    text: `
    For over 44 years, <b>Denny</b> has been the leader and innovator in the photographic industry, providing 
    a personalized service experience and serving as a one stop outlet for all of your photography backdrops, 
    photography equipment and photo props needs.
    `,
  },
  {
    name: 'BayPhotoLab',
    text: `
    <b>Bay Photo Lab</b> has a long history of innovative photographic printing and photo finishing services. They have been 
    providing Professional Photographers with the highest quality printing and customer service for over 40 years
    `,
  },
];
