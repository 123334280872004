<div class="store__wrapper gs-d-f"
     *ngIf="ready; else loading">
  <img [src]="'/assets/images/pages/store/store-title.png'"
       class="gs-p-a store__title"
       alt="Store title"
       title="Store title">

  <div class="store"
       *ngIf="storeStateData?.state === StoreState.STORE">
<!--    <div>-->
<!--      <nav *ngIf="sections.length > 1"-->
<!--           [tabPanel]="tabPanel"-->
<!--           mat-tab-nav-bar>-->
<!--        <a mat-tab-link *ngFor="let section of sections"-->
<!--           (click)="onTabClick(section.type)"-->
<!--           [active]="activeLink == section.type"> {{section.name}} </a>-->
<!--      </nav>-->
<!--      <mat-tab-nav-panel #tabPanel></mat-tab-nav-panel>-->
<!--    </div>-->
    <div class="store__bankroll gs-center-flex gs-theme-color-cool_05">
      <app-bankroll-store [sectionType]="SectionType.CHALLENGES"></app-bankroll-store>
<!--      <app-bankroll-store *ngIf="activeLink === SectionType.CHALLENGES"-->
<!--                          [sectionType]="SectionType.CHALLENGES"></app-bankroll-store>-->
<!--      <app-bankroll-store *ngIf="activeLink === SectionType.BATTLES"-->
<!--                          [sectionType]="SectionType.BATTLES"></app-bankroll-store>-->
    </div>

    <div #container
         (scroll)="onScroll()"
         [ngClass]="{'m-tabs': sections.length > 1}"
         class="store__viewport">
      <app-store-section *ngIf="store.challenges.categories.length"
                         [storeSection]="store.challenges"
                         [sectionType]="SectionType.CHALLENGES"></app-store-section>
      <div #battles>
        <app-store-section  *ngIf="store.battles?.categories?.length"
                            [storeSection]="store.battles!"
                            [sectionType]="SectionType.BATTLES"></app-store-section>
      </div>
      <div *ngIf="!store.challenges.categories.length && !store.battles?.categories?.length">No items in store</div>
    </div>
  </div>
  <app-payment *ngIf="storeStateData?.state === StoreState.PAYMENT"
               [storeItem]="storeStateData?.item"></app-payment>
</div>

<ng-template #loading>
  <div class="loader-wrapper gs-center-v-flex">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
