const template = require(`html-loader!./teamSuggestedMemberItem.html`).default;

class teamInvitesItemCtrl {

  constructor($scope, $rootScope, RestManager, teamsService) {
    'ngInject';
    this.suggestedMember;

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.teamsService = teamsService;
  }

  $onInit() {
  }

  /** @return void **/
  async removeSuggestedMember(memberId){
    if(!this.removeSuggestedMemberBusy){
      this.removeSuggestedMemberBusy = true;
      const res = await this.RestManager.hideSuggestedMember(memberId);
      if(!res.success){
        this.removeSuggestedMemberBusy = false;
        return;
      }
      this.onRemoveSuggestedMember({memberId:memberId});
    }
  }

  /** @return void **/
  async inviteMember(memberId){
    if(!this.removeSuggestedMemberBusy){
      this.removeSuggestedMemberBusy = true;
      const res = await this.RestManager.inviteMember(memberId);
      if(!res.success){
        this.removeSuggestedMemberBusy = false;
        return;
      }
      this.onRemoveSuggestedMember({memberId:memberId});
    }
  }
}

teamInvitesItemCtrl.$inject = [
  '$scope', '$rootScope', 'RestManager', 'teamsService'
];
export default {
  template: template,
  controller: teamInvitesItemCtrl,
  bindings: {
    onRemoveSuggestedMember: '&',
    suggestedMember: '<'
  }
};
