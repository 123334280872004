import RestManager from "../api/managers/RestManager";
import environment from '../gsApp.environment';

/**
 * Share button manager (NEW version)
 *
 * <tag attr>: social-share='[URL]' social-share-conf='{JSON}'
 *
 * code: null, // default without code
 * tc: null, // tracking code
 * service: 'facebook', // facebook, twitter
 * action: 'share' // share, message
 * cache: true // to fix Facebook cache for dynamic links change to false
 * */
class socialShareCtrl {
  /**
   * @param {popupService} popup
   * @param {RestManager} RestManager
   * */
  constructor(
    $httpParamSerializerJQLike,
    $rootScope,
    $scope,
    popup,
    RestManager
  ) {
    "ngInject";

    this.$httpParamSerializerJQLike = $httpParamSerializerJQLike;
    this.appId = environment.facebookAppId;
    this.$scope = $scope;
    this.popup = popup;
    this.RestManager = RestManager;
    this.$rootScope = $rootScope;

    //Random string to disable Facebook cache
    this.uString = Math.floor(Math.random() * 10000000000000001);
  }

  onClick(event, settings) {
    event.preventDefault();

    console.log(settings, 'settings');

    let link; // Share link

    // open empty window
    let newWindow = this.popup.open();

    // tracking code from settings || without code
    if (settings.tc !== null || settings.code === null) {
      link = this.getLink(settings);
      //Replace or close modal
      (link) ? newWindow.location.replace(link) : newWindow.close();
      return false;
    }

    // get tracking codes
    this.RestManager.getTc().then(res => {
      //Check if code exist
      if (!(settings.tc = res.items[settings.code])) {
        newWindow.close();
        return false;
      }
      // get link
      link = this.getLink(settings);
      //Replace or close modal
      (link) ? newWindow.location.replace(link) : newWindow.close();
    });

    return false;
  }

  getLink(settings) {
    let conf;
    let link;

    // fix cache issue
    if (settings.cache) {
      link = `${settings.url}?tc=${settings.tc}`;
    }
    else {
      link = `${settings.url}/${this.uString}?tc=${settings.tc}`;
    }

    // generate link
    switch (settings.service) {
      case 'facebook':
        //Generate params
        switch (settings.action) {
          case 'share':
            conf = {
              main: 'https://www.facebook.com/dialog/share?',
              params: {
                app_id: this.appId,
                display: 'popup',
                href: link,
                redirect_uri: `https://${window.location.host}/viral/redirect/${settings.tc}`
              }
            };
            break;
          case 'message':
            conf = {
              main: 'https://www.facebook.com/dialog/send?',
              params: {
                app_id: this.appId,
                display: 'popup',
                link: link,
                redirect_uri: `https://${window.location.host}/viral/redirect/${settings.tc}`
              }
            };
            break;
        }
        break;
      case 'twitter':
        conf = {
          main: 'https://twitter.com/intent/tweet?',
          params: {
            hashtags: 'gurushots',
            text: link,
            redirect_uri: `https://${window.location.host}/viral/redirect/${settings.tc}`
          }
        };
        break;
      case 'pinterest':
        const dataService = {};
        if (this.$scope.shareInfo) {
          dataService.image = this.$scope.shareInfo.image;
          dataService.description = this.$scope.shareInfo.title + ' by GuruShots';
        }
        else {
          dataService.image = this.$scope.image;
          dataService.description = (this.$scope.image.title ? this.$scope.image.title + ' ' : '') + 'by ' + this.$scope.image.member.name;
        }
        conf = {
          main: 'https://www.pinterest.com/pin/create/button?',
          params: {
            url: link,
            media_id: dataService.image.id,
            media: `${this.$rootScope.photoDomain}/unsafe/0x0/${dataService.image.member_id}/3_${dataService.image.id}.jpg`,
            description: dataService.description
          }
        };
        break;
      case 'tumblr':
        conf = {
          main: 'http://tumblr.com/widgets/share/tool?',
          params: {
            canonicalUrl: link
          }
        };
        break;
      default:
        return false;
    }

    // serialize url
    link = conf.main + this.$httpParamSerializerJQLike(conf.params);
    return link;
  }
}

socialShareCtrl.$inject = [
  '$httpParamSerializerJQLike',
  '$rootScope',
  '$scope',
  'popup',
  'RestManager'
];

// directive
export default () => {
  return {
    restrict: "A",
    controller: socialShareCtrl,
    scope: {
      url: '@socialShare',
      conf: '@socialShareConf',
      shareInfo: '<',
      image: '<'
    },
    link: function (scope, element, attrs, ctrl) {
      let defConf = {
        code: null, // default without code
        tc: null, // tracking code
        service: 'facebook', // facebook, twitter
        action: 'share', // share, message
        cache: true  // fix Facebook cache for dynamic links
      };

      // parse and merge settings
      function parseData() {
        let config = {};
        try {
          if(JSON.parse(scope.conf)) {
            config = JSON.parse(scope.conf);
          }
        }
        catch(err) {
          console.log('JSON.parse error', scope.conf, err);
        }
        let settings = _.defaults(config, defConf);
        settings.url = scope.url;
        settings.image = scope.image;
        element.off('click').on('click', event => ctrl.onClick(event, settings));
      }
      parseData();
      // watch changes
      let unbind = scope.$watch('image', parseData.bind(this));
      scope.$destroy(() => {
        unbind();
      });
    }
  };
};
