import { parseFromSecToMilisec } from '../../../helpers/date.helper';

class SeasonModel {
  constructor(props) {
    this.id = props.id;
    this.type = props.type;
    this.name = props.name;
    this.start_date = parseFromSecToMilisec(props.start_date);
    this.close_date = parseFromSecToMilisec(props.close_date);
    this.status = props.status;
    this.next_season_id = props.next_season_id;
  }
}

export default SeasonModel;
