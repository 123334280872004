const template = require(`html-loader!./selectList.html`).default;

class selectListModalCtrl {
  /** @param {ChallengesManager} ChallengesManager **/

  constructor($mdDialog, $scope, ChallengesManager) {
    'ngInject';
    this.data;
    this.options = [];

    this.$mdDialog = $mdDialog;
    this.$scope = $scope;
    this.ChallengesManager = ChallengesManager;
  }

  select(option) {
    this.$mdDialog.hide(option);
  }

  close() {
    this.$mdDialog.hide(false);
  }
}
selectListModalCtrl.$inject = [
  '$mdDialog', '$scope', 'ChallengesManager'
];
export default {
  template: template,
  controller: selectListModalCtrl,
  bindings: {
    data: '=data'
  }
};
