import {gsIsMobile} from "../../helpers/gen.helper";
import {topTabMenuData} from "../../components/topTabsMenu/topTabMenu.data";
import resolutionEnum from "../../api/enums/resolution.enum";
import {Subject, takeUntil, filter} from "rxjs";
const template = require(`html-loader!./challenges.page.html`).default;

class challengesPageCtrl {
  destroy$ = new Subject();
  constructor(
    $document,
    $scope,
    pageData,
    onBoarding,
    $cookies,
    $timeout,
    Modals,
    $rootScope,
    RestManager,
    MemberService,
    SessionConfigService,
    NgMetaDataService,
    RouterService
  ) {
    'ngInject';
    this.buttonPressed = false;
    this.$document = $document;
    this.$scope = $scope;
    this.pageData = pageData;
    this.onBoarding = onBoarding;
    this.$cookies = $cookies;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.MemberService = MemberService;
    this.SessionConfigService = SessionConfigService;
    this.topTabMenuData = topTabMenuData;
    this.NgMetaDataService = NgMetaDataService;
    this.routerService = RouterService;

    this.skipIntro = this.skipIntro.bind(this);
    this.openLb = this.openLb.bind(this);

    // events
    $(document).on('skipIntro', this.skipIntro);
    this.offopenLb = this.$rootScope.$on('openLb', this.openLb);

    // destroy
    this.$scope.$on('$destroy', () => {
      if (this.member && this.member.intro) this.intro.destroy();
      $(document).off('skipIntro', this.skipIntro);
      this.offopenLb();
      window.removeEventListener('scroll', this.onScroll);
      console.log('challengesPageCtrl destroy');
    });

    this.init();

    // onBoarding
    this.SessionConfigService.sessionConfig$.pipe(
      filter(config => !!config && config.on_boarding && this.member.logged_in),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.onBoarding.load(this.SessionConfigService.onBoarding);
    });
  }

  async init() {
    this.NgMetaDataService.updateMetaTags({
      title:`Photography Challenges`,
      description:`Dive into the world of photography challenges on GuruShots. Participate in ongoing competitions, vote for your favorites, and showcase your photography skills.`,
      keywords:[ 'photography challenges', 'photo contests', 'GuruShots challenges', 'compete in photography', 'vote for photos']
    });
    this.member = this.MemberService.getCurrentMember();
    if (this.member?.logged_in === false && this.routerService.currentUrl !== '/challenges/closed') {
      this.routerService.go('');
      return;
    }
    this.pageData.get('challenges');
    this.done = true;

    this.onResize = this.onResize.bind(this);
    $(window).on('resize', this.onResize);
    this.onResize();

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  $onDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    $(window).off('resize', this.onResize);
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= resolutionEnum.WIDTH.MOBILE_HEADER;
    this.tabsType = this.isMobile ? topTabMenuData.TYPE.CHALLENGES_MOBILE_MY_CHALLENGES.NAME: topTabMenuData.TYPE.CHALLENGES.NAME;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  setTabsType(type) {
    this.tabsType = type;
  }

  skipIntro() {
    (this.settings ??= {}).show_on_boarding = 0;
    this.routerService.reloadCurrentRoute();
  }

  openLb() {
    if (!gsIsMobile()) {
      this.buttonPressed = true;
    }
  }
}
challengesPageCtrl.$inject = [
  '$document',
  '$scope',
  'pageData',
  'onBoarding',
  '$cookies',
  '$timeout',
  'Modals',
  '$rootScope',
  'RestManager',
  'MemberService',
  'SessionConfigService',
  'NgMetaDataService',
  'RouterService',
];
export default {
  template: template,
  controller: challengesPageCtrl
};
