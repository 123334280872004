const template = require(`html-loader!./teamsSuggestions.html`).default;
import teamEnum  from '../../../api/enums/team.enum';

class TeamsSuggestions {
  constructor(
    RestManager,
    stateService,
    $scope
  ) {
    'ngInject';

    this.teamEnum = teamEnum;

    this.RestManager = RestManager;
    this.stateService = stateService;
    this.$scope = $scope
    ;
  }

  async hideTeam(teamId, index) {
    let res = await this.RestManager.hideTeamSuggest({team_id:teamId});

    this.busy = false;

    if (res.success) {
      console.log(`this.suggestedTeams.length is ${this.suggestedTeams.length}`);
      this.suggestedTeams = this.suggestedTeams.slice(0, index).concat(this.suggestedTeams.slice(index + 1, this.suggestedTeams.length));
      console.log(`hide ${teamId} with the index ${index}.`);
      console.log(`this.suggestedTeams.length is ${this.suggestedTeams.length}`);
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
  }
}

TeamsSuggestions.$inject = [
  'RestManager',
  'stateService',
  '$scope'
];

export default {
  template: template,
  controller: TeamsSuggestions,
  bindings: {
    suggestedTeams:'<'
  }
};
