const gsIconEnum = Object.freeze({
  TYPE: {
    EXPOSURE_AUTOFILL: {
      NAME: 'EXPOSURE_AUTOFILL',
      CLASS: 'icon-flash',
      TITLE: 'FILL',
    },
    KEY: {
      NAME: 'KEY',
      CLASS: 'icon-key-2',
      TITLE: 'KEY',
    },
    PHOTO_SWAP: {
      NAME: 'PHOTO_SWAP',
      CLASS: 'icon-swap-invert',
      TITLE: 'SWAP',
    },
    POINT: {
      NAME: 'POINT',
      SRC: '/assets/svg/rewards/points-color.svg',
      TITLE: 'GS POINTS'
    },
    VOTE: {
      NAME: 'VOTE',
      CLASS: 'icon-vote-full',
      TITLE: 'TOTAL VOTES'
    },
  }
});

export default gsIconEnum;
