import {Component, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MiniGameService} from "../../services/mini-game.service";
import {MiniGameImageInterface, MiniGameInterface} from "../../interfaces/mini-game.interface";
import {VoteResultInterface} from "../../interfaces/vote-result.interface";
import {VoteStateEnum} from "../../enums/vote.enum";
import {delay, Subject, takeUntil} from "rxjs";
import {filter, tap} from "rxjs/operators";
import {MiniGameVotingComponent} from "../mini-game-voting/mini-game-voting.component";
import {imageWidth, voteAnimationTime} from "../../challenges.constants";
import {MediatorMessageKey, MediatorService} from "../../../../core/services/mediator.service";
import {UrlService} from "../../../../core/services/url.service";
import {ImageHelper} from "../../../../core/helpers/ImageHelper";
import {GsLouderType} from "../../../../shared/components/gs-loader/gs-louder-type.enum";
import {NgChallengeModel} from "../../models/ng-challenge.model";
import {ChallengeTurboState, ChallengeTurboUnlockType, ChallengeUnlockType} from "../../enums/challenges.enum";
import {ResourceType} from "../../../../core/models/enums";

@Component({
  selector: 'app-mini-game-modal',
  templateUrl: './mini-game-modal.component.html',
  styleUrls: ['./mini-game-modal.component.scss']
})
export class MiniGameModalComponent implements OnInit, OnDestroy {
  @ViewChild(MiniGameVotingComponent) miniGameVotingComponent!: MiniGameVotingComponent;

  @Input() challenge!: NgChallengeModel;
  public game: MiniGameInterface = {
    images: [],
    required_selections: 0,
    max_selections: 0
  };
  public images: MiniGameImageInterface[] = [];
  public currentIndex = 0;
  public imagesVoted = 0;
  public isVoteSubmitGoing = false;
  public isLoading = false;
  public isMiniGameErrored = false;
  public miniGameErrorMessage: string | null = null;
  public afterGameOptions = {
    showAfterGameMessage: false,
    isGameWon: false
  }
  public loaderTypeBattle:GsLouderType =  GsLouderType.MAIN;

  private switchImages$ = new Subject<VoteResultInterface>();
  private showAfterGameMessage$ = new Subject<typeof this.afterGameOptions>();
  private destroy$ = new Subject<void>();
  private isGameFinished = false;
  public unlockType?:ResourceType;
  public resourceType = ResourceType;
  public unlockAmount:number = 0;

  constructor(
    private miniGameService: MiniGameService,
    private mediatorService: MediatorService,
    private urlService: UrlService,
    @Inject("Modals") private Modals: any
  ) {
    this.switchImages$.pipe(
      tap((VoteResultInterface) => this.miniGameVotingComponent.setVote(VoteResultInterface)),
      delay(voteAnimationTime - 400),
      filter(() => this.currentIndex < this.images.length - 1 && !this.isGameFinished),
      tap(() => this.currentIndex++),
      delay(400),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.isVoteSubmitGoing = false;
    });

    this.showAfterGameMessage$.pipe(
      delay(voteAnimationTime),
      takeUntil(this.destroy$)
    ).subscribe(afterGameOptions => this.afterGameOptions = afterGameOptions);

    this.mediatorService.on(MediatorMessageKey.LOCKED_RESULT).pipe(
      delay(voteAnimationTime),
      takeUntil(this.destroy$)
    ).subscribe(async (unlockSuccess) => {
      if (unlockSuccess.data){
        await this.startGame()
      } else {
        this.closeModal();
      }
    });
  }

  async ngOnInit(): Promise<void> {
    // if(this.challenge.member.turbo){
    //   this.challenge.member.turbo.turbo_unlock_type = ChallengeTurboUnlockType.COINS;
    //   this.challenge.member.turbo.unlock_amount = 4;
    // }

    if (this.challenge.member.turbo?.turbo_unlock_type === ChallengeTurboUnlockType.COINS) {
      this.unlockType = ResourceType.COINS;
    } else if (this.challenge.member.turbo?.turbo_unlock_type === ChallengeTurboUnlockType.KEY) {
      this.unlockType = ResourceType.KEYS;
    }
    await this.startGame();
    // this.isGameFinished = true;
    // this.showAfterGameMessage$.next({
    //   showAfterGameMessage: true,
    //   isGameWon: false
    // });
  }

  async submitVote(image_id: string) {
    if (!image_id || this.imagesVoted >= this.images.length || this.isVoteSubmitGoing) {
      return;
    }
    this.isVoteSubmitGoing = true;
    const voteResult = await this.miniGameService.submitTurboChallengeSelection(
      this.challenge.id!,
      image_id
    );

    if (this.isChallengeFinished) {
      this.showAfterGameMessage$.next({
        showAfterGameMessage: true,
        isGameWon: false
      });
      this.isMiniGameErrored = true;
      return;
    }

    if (voteResult.success) {
      this.imagesVoted++;
      this.handleVoteResult(voteResult);
      setTimeout(() => this.updateVote(voteResult.is_successful_selection, image_id), 360);
    } else {
      this.isMiniGameErrored = true;
      this.miniGameErrorMessage = voteResult.message as string;
      this.showAfterGameMessage$.next({
        showAfterGameMessage: true,
        isGameWon: false
      });
    }
  }

  updateVote(isSuccessSelection: boolean, imageId: string) {
    const image = this.game.images.find(image => {
      return image.first_image.id === imageId || image.second_image.id === imageId
    });
    if (image) {
      image.is_success = isSuccessSelection;
    }
  }

  async getGame(): Promise<any> {
    this.game = await this.miniGameService.getChallengeTurbo(this.challenge.id!);
    if (!this.game.success) {
      this.isMiniGameErrored = true;
      this.miniGameErrorMessage = this.game.message as string;
      this.showAfterGameMessage$.next({
        showAfterGameMessage: true,
        isGameWon: false
      });
      this.isLoading = false;
    } else {
      this.challenge.setTurboState(ChallengeTurboState.IN_PROGRESS);
    }
  }

  handleVoteResult(voteResult: VoteResultInterface): void {
    const states: {[key: string]: () => void} = {
      [VoteStateEnum.IN_PROGRESS]: () => {
        this.switchImages$.next(voteResult);
      },
      [VoteStateEnum.WON]: () => {
        this.isGameFinished = true;
        this.switchImages$.next(voteResult);
        this.showAfterGameMessage$.next({
          showAfterGameMessage: true,
          isGameWon: true
        });
        this.challenge.setTurboState(ChallengeTurboState.WON);
      },
      [VoteStateEnum.LOSE]: () => {
        this.isGameFinished = true;
        this.switchImages$.next(voteResult);
        this.showAfterGameMessage$.next({
          showAfterGameMessage: true,
          isGameWon: false
        });
        this.challenge.setTurboState(ChallengeTurboState.LOCKED);
      }
    }

    if (states[voteResult.state]) {
      states[voteResult.state]();
    }
  }

  afterWon(){
    if(this.isChallengeFinished){
      this.closeModal();
    } else{
      this.mediatorService.broadcast(MediatorMessageKey.CLOSE_MINI_GAME);
      this.Modals.open('challengeActionActivate', null, {
        type:ChallengeUnlockType.TURBO,
        challenge:this.challenge
      });
      // this.mediatorService.broadcast(MediatorMessageKey.RELOAD_ACTIVE_CHALLENGES,
      //   {openTurboActivation:true, challenge:this.challenge});
    }
  }

  async startGame(): Promise<void> {
    this.isLoading = true;
    this.isGameFinished = false;
    this.afterGameOptions.showAfterGameMessage = false;
    this.isMiniGameErrored = false;
    this.miniGameErrorMessage = null;
    this.isVoteSubmitGoing = false;
    this.currentIndex = 0;
    this.imagesVoted = 0;
    await this.getGame();
    this.images = (this.game.images ?? []).filter(image => image.is_success === null);
    if (this.images.length > 0) {
      await this.preloadImages();
    }
    this.isLoading = false;
  }

  keyUnlock(): void {
    this.mediatorService.broadcast(MediatorMessageKey.LOCKED, this.challenge);
  }

  closeModal(): void {
    this.mediatorService.broadcast(MediatorMessageKey.CLOSE_MINI_GAME);
  }

  async preloadImages(): Promise<void> {
    const firstImages = this.images[0];
    const preloadImagesLinks = [
      this.urlService.getImageUrl(
        firstImages.first_image.member_id,
        firstImages.first_image.id,
        imageWidth
      ),
      this.urlService.getImageUrl(
        firstImages.second_image.member_id,
        firstImages.second_image.id,
        imageWidth
      ),
    ];
    await ImageHelper.preloadImages(preloadImagesLinks);
  }

  get isChallengeFinished(): boolean {
    return this.challenge.timeLeft <= 0;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  protected readonly ResourceType = ResourceType;
}
