import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-vote-page-wrapper',
  templateUrl: './vote-page-wrapper.component.html',
  styleUrl: './vote-page-wrapper.component.scss'
})
export class VotePageWrapperComponent {
  challengeUrl = null;

  constructor(
    private route: ActivatedRoute
  ) {
    this.route
      .params.pipe(
    ).subscribe(async (params: any) => {
      this.challengeUrl = params.challengeUrl;
    });
  }
}
