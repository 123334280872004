import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-notifications-wrapper',
  templateUrl: './mobile-notifications-wrapper.component.html',
  styleUrl: './mobile-notifications-wrapper.component.scss'
})
export class MobileNotificationsWrapperComponent {

}
