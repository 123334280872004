class NotificationsManager {
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  get(start, limit, type) {
    return this.api['rest']['getNotifications']({start, limit, type});
  }

  watchNotifications() {
    return this.api['rest']['watchNotifications']();
  }

  readNotifications(date, type) {
    return this.api['rest']['readNotifications']({date, type});
  }
}

NotificationsManager.$inject = [
  'api'
];

export default NotificationsManager;
