import { Injectable } from '@angular/core';
import RequestServiceModel from "../../../core/services/request/requestService.model";
import NgRequestEnum from "../../../core/services/request/request.enum";
import { firstValueFrom } from "rxjs";
import RequestService from "../../../core/services/request/requestService";
import {VoteResultInterface} from "../interfaces/vote-result.interface";
import {MiniGameInterface} from "../interfaces/mini-game.interface";

@Injectable({
  providedIn: 'root'
})
export class MiniGameService {

  constructor(
    private requestService: RequestService
  ) { }

  getChallengeTurbo(challenge_id: number): Promise<MiniGameInterface> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_CHALLENGE_TURBO.NAME,
      // getMockData: true,
      // mockDataState: 1,
      body: {
        challenge_id
      }
    });
    return firstValueFrom(this.requestService.request<MiniGameInterface>(conf));
  }

  submitTurboChallengeSelection(challenge_id: number, image_id: string): Promise<VoteResultInterface> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.SUBMIT_CHALLENGE_TURBO_SELECTION.NAME,
      // getMockData: true,
      // mockDataState: 4,
      body: {
        challenge_id,
        image_id
      }
    });
    return firstValueFrom(this.requestService.request<VoteResultInterface>(conf));
  }
}
