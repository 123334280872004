<div class="action-button
            {{type === ActiveChallengeActionButtonType.TURBO ? challenge.member?.turbo?.state : ''}}
            {{type === ActiveChallengeActionButtonType.BOOST ? challenge.member?.boost?.state : ''}}
            "
     [ngClass]="{
        disable:!isEnable,
        blink: isBoostAvailable(),
       'boost-timer-available':isBoostAvailable()
     }"
     (click)="onButtonClick()"
>
  <i [ngClass]=iconByType[type!] class="gs-f-s-12"></i>
  <div>{{type}}</div>
  <i *ngIf="!isEnable" class="icon-lock gs-f-s-12"></i>
  <div *ngIf="type === ActiveChallengeActionButtonType.TURBO"
       [ngSwitch]="challenge.member?.turbo?.state"
       class="action-button__status">
    <div class="action-button__status__message"
         *ngSwitchCase="ChallengeTurboState.FREE">Free</div>
    <div class="action-button__status__message"
         *ngSwitchCase="ChallengeTurboState.WON">Use it!</div>
    <div class="action-button__status__message"
         *ngSwitchCase="ChallengeTurboState.IN_PROGRESS">Resume</div>
    <div class="action-button__status__icon-message" *ngSwitchCase="ChallengeTurboState.TIMER">
      <div class="action-button__status__icon-message__text">
        {{countDownTime | gsTimer: GsTimerEnum.CHALLENGE_TURBO_BUTTON}}
      </div>
      <div class="action-button__status__icon-message__icon">
        <i class="icon-lock"></i>
      </div>
    </div>
    <app-resource-indicator *ngSwitchCase="ChallengeTurboState.LOCKED"
      [resource]="unlockTurboType" [amount]="challenge.member?.turbo?.turbo_unlock_amount!">
    </app-resource-indicator>
  </div>
  <div
    *ngIf="type === ActiveChallengeActionButtonType.BOOST"
    [ngSwitch]="challenge.member?.boost?.state"
    class="action-button__status"
  >
    <app-resource-indicator
      *ngSwitchCase="ChallengeBoostState.LOCKED"
      [resource]="ResourceType.KEYS" [amount]="1">
    </app-resource-indicator>

    <div class="action-button__status__icon-message" *ngIf="isBoostTimerEnabled()">
      <div class="action-button__status__icon-message__text">
        {{countDownTime | gsTimer: GsTimerEnum.CHALLENGE_TURBO_BUTTON}}
      </div>
      <div class="action-button__status__icon-message__icon">
        <i class="icon-lock-open"></i>
      </div>
    </div>
    <div class="action-button__status__icon-message__icon" *ngSwitchCase="ChallengeBoostState.MISSED">
      <i class="icon-exclamation-point"></i>
    </div>
  </div>
</div>
