const template = require(`html-loader!./elementProtection.html`).default;

class ElementProtectionController {
  constructor(
    $timeout
  ) {

    'ngInject';

    this.timeout = $timeout;

    const from = 1,
      to = 10
    ;

    this.protectionValue1 = Math.floor(Math.random() * to) + from;
    this.protectionValue2 = Math.floor(Math.random() * to) + from;
  }

  $onInit() {
    console.log('ElementProtectionController $onInit');

    const DOMElement = angular.element(document.getElementById('protection--s--'));
    /*DOMElement.find('div').replaceWith(this.transclude());*/

    this.timeout(() => {
      DOMElement.prepend(this.tagGenerator(this.protectionValue1));
    }, 0);
    DOMElement.append(this.tagGenerator(this.protectionValue2));
  }

  tagGenerator(amountOfElements) {
    const fragment = document.createDocumentFragment();

    for(let i = 0; i < amountOfElements; i++) {
      const a = document.createElement('div');

      a.textContent = `Text ${i}`;
      fragment.appendChild(a);
    }

    return fragment;
  }
}

ElementProtectionController.$inject = [
  '$timeout'
];

export default {
  transclude: true,
  replace: true,
  template: template,
  controller: ElementProtectionController,
  bindings: {}
};
