function disableRightClick() {
  return {
    restrict: 'A',
    link: function (scope, element, attr) {
      element.bind('contextmenu', e => e.preventDefault());
    }
  };
}

disableRightClick.$inject = [

];

export default disableRightClick;
