const template = require(`html-loader!./rankingLevelBadge.html`).default;

class rankingLevelBadgeCtrl {
  constructor(
    $scope,
    $rootScope
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;

    this.rankingLevelBgColorsByLevel = {
      0: {
        color1:'9A9A9A',
        color2:'9A9A9A'
      },
      1: {
        color1:'2C7FA6',
        color2:'0A92D2'
      },
      2: {
        color1:'048175',
        color2:'00C9CC'
      },
      3: {
        color1:'A62C46',
        color2:'DD2F54'
      },
      4: {
        color1:'234F64',
        color2:'0E859D'
      },
      5: {
        color1:'0D0F17',
        color2:'2A2E3F'
      },
    };
  }

  $onInit() {
    this.color = this.rankingLevelBgColorsByLevel[this.rankingLevel.level];
  }
}

rankingLevelBadgeCtrl.$inject = [
  '$scope',
  '$rootScope'
];

export default {
  template: template,
  controller: rankingLevelBadgeCtrl,
  bindings: {
    rankingLevel: '<'
  }
};
