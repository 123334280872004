export const modalBlockedUserConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-blocked-user data="modal.data"></modal-blocked-user>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
