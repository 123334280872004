<div
  *ngIf="!isLoading else loading"
  class="photographer-ranking-wrapper">

  <div class="photographer-ranking-top-bar">
    <div class="photographer-ranking-top-bar-inner">
      <ng-container *ngIf="challenge.status === challengeEnum.STATUSES.ACTIVE.VALUE">
        <div class="clock" [ngClass]="{'clock-hidden': isChallengeFinished}">
          <i class="icon-clock"></i>
          {{countDownTime | gsTimer : gsTimerEnum.RANKING}}
        </div>
      </ng-container>

      <ng-container *ngIf="challenge.status === challengeEnum.STATUSES.CLOSING.VALUE">
        <div class="clock ranking-finalizing-result">
          <i class="icon-clock"></i>
          <span class="result-text">Finalizing Results</span>
        </div>
      </ng-container>

      <ng-container *ngIf="challenge.status === challengeEnum.STATUSES.CLOSED.VALUE">
        <div class="clock ranking-finalizing-result closed">
          <i class="icon-clock"></i>
          <span class="result-text">Ended</span>
        </div>
      </ng-container>

      <div class="prizes" (click)="onHeaderPrizesClick()">
        <app-ng-rewards
          [styleClass]="'rank-header'"
          [prizes]="firstPrizes"
        ></app-ng-rewards>
      </div>
    </div>
  </div>

  <div
    class="ranking-items-wrapper"
    [ngClass]="{'not-joined-height': !isJoinedToChallenge || challenge.status === challengeEnum.STATUSES.CLOSED.VALUE}"
  >

    <cdk-virtual-scroll-viewport
      [itemSize]="itemHeight"
      class="ranking-items-scrolling-viewport"
    >
      <app-photographer-ranking-item
        *cdkVirtualFor="let rankingItem of rankingItems"
        [rankingItem]="rankingItem"
        [exposure]="exposure"
        [isUser]="rankingItem.member.id === memberId"
      >
      </app-photographer-ranking-item>
    </cdk-virtual-scroll-viewport>

    <div
      *ngIf="!isLockedMode && isJoinedToChallenge"
      class="ranking-target-button">
      <button
        (click)="goTarget()"
      ></button>
    </div>

  </div>

  <div
    *ngIf="isJoinedToChallenge && challenge.status !== challengeEnum.STATUSES.CLOSED.VALUE"
    class="ranking-footer"
    [ngClass]="{'ranking-footer-disabled': challenge.status === challengeEnum.STATUSES.CLOSING.VALUE}"
  >

    <button
      mat-flat-button
      class="ranking-footer-fill"
      [disabled]="!isFillButtonEnabled"
      (click)="fill()"
    >Fill</button>

    <div class="exposure-meter-wrapper">
      <app-ng-exposure-meter
        [exposure]="exposure"
        [showMeterBackground]="true"
      ></app-ng-exposure-meter>
      <div class="exposure-bonus-label">Exposure Bonus</div>
    </div>

    <button
      mat-raised-button
      class="ranking-footer-vote"
      (click)="goVote()"
    >Vote</button>
  </div>

  <div
    @fadeInFadeOut
    *ngIf="isNotificationShown && isJoinedToChallenge"
    class="ranking-notification-message"
  >
    {{isAutoUpdateOn ? 'Live Ranking On' : 'Live Ranking Off'}}
  </div>

</div>

<ng-template #loading>
  <div class="loader-wrapper gs-center-v-flex">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
