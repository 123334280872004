import leaderBoardEnum from "../api/enums/leaderBoard.enum";
import leagueEnum from "../api/enums/league.enum";
import featureMessageEnum from "../api/enums/featureMessage.enum";

class leaderboardService {

  constructor($timeout, Modals, RestManager) {
    'ngInject';
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.RestManager = RestManager;
  }

  isTypeGlobal(type) {
    return type === leaderBoardEnum.TYPE.TEAM_GLOBAL;
  }

  isTypeLeague(type) {
    return type === leaderBoardEnum.TYPE.TEAM_LEAGUES;
  }

  isTypeIndividual(type) {
    return type === leaderBoardEnum.TYPE.INDIVIDUAL;
  }

  isLeagueStatusActive(leagueData){
    return leagueData && leagueEnum.STATE.ACTIVE === leagueData.state.status;
  }

  isLeagueStatusInactiveNotInLeague(leagueData){
    return leagueData && leagueEnum.STATE.INACTIVE_NOT_IN_LEAGUE === leagueData.state.status;
  }

  isLeagueStatusInactiveInLeague(leagueData){
    return leagueData && leagueEnum.STATE.INACTIVE_IN_LEAGUE === leagueData.state.status;
  }

  isSeasonStatusPending(leagueData){
    return leagueData && this.isLeagueStatusInactiveInLeague(leagueData)
      && leagueData.season &&  leagueEnum.SEASON.STATE.PENDING === leagueData.season.status;
  }

  isLeagueStatusComingSoon(leagueData){
    return leagueData && leagueEnum.STATE.COMING_SOON === leagueData.state.status;
  }

  async onFeatureMessageAction(featureMessage) {
    if (featureMessage.data.button.type === featureMessageEnum.BUTTON.CLOSE) {
      await this.deleteFeatureMessage(featureMessage);
    } else if (featureMessage.data.button.type === featureMessageEnum.BUTTON.CLAIM) {
      this.Modals.open('claim', null, {
        chest: featureMessage.chest,
        chest_attributes: featureMessage.chest_attributes
      });
      await this.$timeout(1000);
    }
  }

  async deleteFeatureMessage(featureMessage) {
    if(!featureMessage) {
      return false;
    }
    await this.RestManager.deleteFeatureMessage(featureMessage.id);
  }
}

leaderboardService.$inject = [
  '$timeout',
  'Modals',
  'RestManager',
];
export default leaderboardService;
