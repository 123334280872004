/* globals angular */
const template = require(`html-loader!./suspendedImages.html`).default;
import ImageModel from '../../../api/models/image/image.model';

class suspendedImagesCtrl {

  /**
   * @param {RestManager} RestManager
   * */

  constructor($mdToast, Modals, RestManager) {
    'ngInject';

    this.$mdToast = $mdToast;
    this.Modals = Modals;
    this.RestManager = RestManager;

    this.reasons = [];
  }

  $onChanges() {
    if(!this.challenge) {
      return;
    }

    this.challenge.popup.data = this.challenge.popup.data.map((image) => {
      //Prepare reasons array
      if(this.reasons.indexOf(image.reason_text) === -1) {
        this.reasons.push(image.reason_text);
      }
      //return Image object
      return new ImageModel(image);
    });
  }

  close() {
    this.RestManager.deleteLast();
    this.$mdToast.hide();
  }

  preSubmit(event) {
    this.Modals.open('preSubmit', event, {challenge: this.challenge});
    this.close();
  }
}
suspendedImagesCtrl.$inject = [
  '$mdToast', 'Modals', 'RestManager'
];
export default {
  template: template,
  controller: suspendedImagesCtrl,
  bindings: {
    challenge: '<data'
  }
};
