const template = require(`html-loader!./mobileMembersSearch.html`).default;

class mobileMembersSearchComponent {

  constructor(
    pageData,
    $timeout,
    $scope,
    $rootScope,
    RestManager,
    gsScrollTopService,
    MemberService,
  ) {
    'ngInject';
    this.items = [];

    this.pageData = pageData;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.gsScrollTopService = gsScrollTopService;
    this.MemberService = MemberService;

    this.search = _.debounce(this.search.bind(this), 250);
  }

  $onInit() {
    this.gsScrollTopService.scrollTop();
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('mobileMembersSearch');
  }

  /** @return void **/
  async search() {
    this.items = [];
    if (this.busy || this.searchText.length < 3) {
      return;
    }
    this.busy = true;
    this.lastSearchText = _.clone(this.searchText);

    const res = await this.RestManager.search(this.searchText);

    this.items = res.items;

    this.$timeout(() => {
      this.busy = false;
    });

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
mobileMembersSearchComponent.$inject = [
  'pageData',
  '$timeout',
  '$scope',
  '$rootScope',
  'RestManager',
  'gsScrollTopService',
  'MemberService',
];
export default {
  template: template,
  controller: mobileMembersSearchComponent
};
