import { Injectable } from '@angular/core';

@Injectable()
export class ScrollService {
  isScroll: any;

  constructor() { }

  scrollTo(yPos:number, duration = 600) {
    return new Promise((resolve) => {
      const startY = window.scrollY;
      const difference = yPos - startY;
      const startTime = performance.now();

      const step = () => {
        const progress = (performance.now() - startTime) / duration;
        const amount = this.easeOutCubic(progress);
        window.scrollTo({top: startY + amount * difference});
        if (progress < 0.99) {
          window.requestAnimationFrame(step);
        } else {
          resolve(true);
        }
      };
      step();
    });
  }

  scrollIntoView(elementToScroll: HTMLElement | null, block: ScrollLogicalPosition = 'center', behavior: ScrollBehavior = 'smooth'){
    if (elementToScroll) {
      elementToScroll.scrollIntoView({behavior, block});
    } else {
      console.warn('elementToScroll is null');
    }
  }

  windowScrollToTheTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private easeOutCubic (t:any) { return --t * t * t + 1};
}
