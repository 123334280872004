import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-terms-wrapper',
  templateUrl: './ai-terms-wrapper.component.html',
  styleUrl: './ai-terms-wrapper.component.scss'
})
export class AiTermsWrapperComponent {

}
