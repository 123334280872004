import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-users-limit',
  templateUrl: './users-limit.component.html',
  styleUrls: ['./users-limit.component.scss']
})
export class UsersLimitComponent {
  @Input() players = 0;
  @Input() maxPlayers = 0;
  @Input() showBackground = true;
  @Input() textColor = '#fff';
  @Input() fontSize = '18px';
}
