class stateService {

  constructor(
    $window,
    SessionConfigService,
    RouterService
  ) {
    'ngInject';
    this.previousState = null;
    this.$window = $window;
    this.SessionConfigService = SessionConfigService;
    this.r = RouterService;
  }

  redirectTo(route, queryParams= {}) {
    this.r.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.r.router.navigate([route], { queryParams })
    });
  }

  goToState(route, queryParams= {}, reload){
    if(reload){
      this.redirectTo(route , queryParams);
    } else {
      this.r.router.navigate([route], { queryParams });
    }
  }

  goToStateNewTab(route, queryParams={}, reload=false){
    // const url = this.$state.href(state, params, {reload:reload});
    // this.$window.open(url,'_blank');
    const url = this.r.router.serializeUrl(
      this.r.router.createUrlTree([route], {queryParams})
    );

    window.open(url, '_blank');
  }

  goToDiscoverState(params={}, reload=false){
    this.goToState(
      'challenges/open',
      params,
      reload
    );
  }
  goToDefaultState(params={}, reload=false){
    this.goToState(
      'challenges/my-challenges/current',
      params,
      reload
    );
  }

  goToHomePage(params={}, reload=false){
    this.goToState(
      '',
      params,
      reload
    );
  }

  reload() {
    window.location.reload();
  }

  getCurrentState(){
    return this.r.currentUrl;
  }

  get currentUrl() {
    this.r.currentUrl;
  }

  getProfileDefaultState(isMyProfile = true){
    const tab = this.SessionConfigService.sessionConfig &&
    this.SessionConfigService.sessionConfig.profile_page_achievements_default_tab && isMyProfile
      ? 'achievements' : 'photos';
    return `${tab}`;
  }

}
stateService.$inject = [
  '$window',
  'SessionConfigService',
  'RouterService'
];
export default stateService;
