import {isTrue} from "../../helpers/string.helper";

const template = require(`html-loader!./challengeImages.html`).default;
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";
import {MediatorMessageKey} from "../../../../core/services/mediator.service";

class challengeImagesCtrl {
  challenge;

  constructor(
    $scope,
    $rootScope,
    Modals,
    challengeService,
    mixpanelService,
    MediatorService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.challengeService = challengeService;
    this.mixpanelService = mixpanelService;
    this.mediatorService = MediatorService;
  }

  $onInit() {}

  $onChanges(changes) {
    if(changes.enableSubmitPhoto){
      this.enableSubmitPhoto = isTrue(changes.enableSubmitPhoto.currentValue);
    }
    if(changes.enableResizeMode){
      this.enableResizeMode = isTrue(changes.enableResizeMode.currentValue);
    }
    if(changes.deleteEnable){
      this.deleteEnable = isTrue(changes.deleteEnable.currentValue);
    }
  }

  preSubmit(event) {
    this.eventMixPanelAction();
    this.Modals.open('gsUploader', event, {
      target: 'challenge',
      challenge: this.challenge,
    });
  }

  async remove(imageToRemove) {
    if(await this.challengeService.removeImageFromChallenge(
      {
        imageToRemove:imageToRemove,
        challenge: this.challenge,
      }
    )){
      this.challenge.member.ranking.entries = this.challenge.member.ranking.entries.filter(image => image.id !== imageToRemove.id);
      if(this.challenge.member.ranking.entries.length > 0){
        // this.mediatorService.broadcast(MediatorMessageKey.UPDATE_ACTIVE_CHALLENGES);
      } else{
        this.mediatorService.broadcast(MediatorMessageKey.REMOVE_ACTIVE_CHALLENGE,{
          challengeId: this.challenge.id
        });
      }
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
  }

  eventMixPanelAction() {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.ACTIVE_CHALLENGE,
          ui_name: mixpanelEventsEnum.UI_NAME.SUBMIT_PHOTO,
          challenge_id: this.challenge.id,
          number_of_photos_submitted: this.challenge.member.ranking.entries.length
        }
      }
    );
  }
}

challengeImagesCtrl.$inject = [
  '$scope',
  '$rootScope',
  'Modals',
  'challengeService',
  'mixpanelService',
  'MediatorService'
];

export default {
  template: template,
  controller: challengeImagesCtrl,
  bindings: {
    challenge: '<',
    enableSubmitPhoto: '@',
    enableResizeMode: '@',
    deleteEnable: '@'
  }
};
