export const TEAM_MANAGER_SEARCH_BY_NAME_OPTION = {
  name: 'Name',
  value: 'name'
};
export const TEAM_MANAGER_SEARCH_BY_ID_OPTION = {
  name: 'Id',
  value: 'id'
};
export const teamsManagerConf = {
  searchByOptions : [
    TEAM_MANAGER_SEARCH_BY_NAME_OPTION,
    TEAM_MANAGER_SEARCH_BY_ID_OPTION
  ],
  teamFields: [
    {title: 'Id', value: 'id'},
    {title: 'Members in team', value: 'member_count'},
    {title: 'League name', value: 'league.name'},
    {title: 'League sub name', value: 'league.sub_name'},
    {title: 'League bucket id', value: 'league.bucket_id'},
  ]
};
export const teamsManagerActionFieldType = {
  NUMBER: 'NUMBER',
  TEXT: 'TEXT',
  LIST: 'LIST'
};
