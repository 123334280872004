import {filter, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./teamsJoin.html`).default;
import TeamModel from "../../../api/models/team/team.model";
import TeamInviteModel from '../../../api/models/team/teamInvite.model';
import teamEnum from '../../../api/enums/team.enum';
import {teamItemMode} from "../../../components/teamItem/teamItem.data";

class teamsJoinCtrl {

  constructor(
    $scope,
    RestManager,
    $timeout,
    stateService,
    teamsService,
    gsScrollTopService,
    tagEventService,
  ) {
    'ngInject';
    this.loadMore = true;
    this.start = 0;
    this.isFirstLoaded = false;
    this.subject = new Subject();
    this.$scope = $scope;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.stateService = stateService;
    this.teamsService = teamsService;
    this.teamItemMode = teamItemMode;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;

    this.teamsService.getTeamsConfig$.pipe(
      filter(configData => !!configData),
      takeUntil(this.subject)
    ).subscribe(async (configData) => {
      this.configData = configData;

      await this.teamsService.openTeamIntro(teamEnum.INTRO_STATES.JOIN);
      this.limit = this.configData?.teams_page_size;

      await Promise.all([
        this.loadTeamInvites(),
        this.getTeams(),
        this.getSuggestedTeams()
      ]);
      this.isFirstLoaded = true;

      this.tagEventService.sendPageViewEvent({title: 'Teams - join'});
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    });
  }

  $onInit() {
    this.onScroll = this.onScroll.bind(this);
    $(window).on('scroll', this.onScroll);
    this.removeInviteOff = this.$scope.$on('removeInvite', this.removeInviteByTeamId.bind(this));
    this.gsScrollTopService.scrollTop();
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    $(window).off('scroll', this.onScroll);
    this.removeInviteOff();
  }

  search() {
    if(this.searchPromise) {
      this.$timeout.cancel(this.searchPromise);
    }

    this.searchPromise = this.$timeout(() => {
      this.start = 0;
      this.loadMore = true;
      this.getTeams();
    },500);
  }

  async getTeams() {
    if (!this.busy) {
      this.busy = true;
      let reqData = {
        start: this.start,
        limit: this.limit,
      };

      if (this.searchText) {
        reqData.name = this.searchText;
      }

      const res = await this.teamsService.getTeams(reqData);

      if (!res.success) {
        this.busy = false;

        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }

        return;
      }

      let data = res.data.teams.map((team) => {
        return new TeamModel(team);
      });

      if (this.start === 0) {
        this.teams = data;
      } else {
        this.teams = [...this.teams, ...data];
      }

      this.loadMore = data.length === this.limit;
      this.start += this.limit;
      this.busy = false;

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  onScroll() {
    let dHight = Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );

    if (
      $(window).scrollTop() + $(window).height() > (dHight-300)
      && this.teams
      && this.loadMore && !this.busy
    ) {
      this.getTeams();
    }
  }

  teamClicked(team) {
    this.teamsService.goToTeamInfo(team.id);
  }

  async loadTeamInvites() {
    const res = await this.RestManager.getTeamInvites();

    if (!res.success) {
      return;
    }

    this.teamInvites = res.invites.map(teamInvite => new TeamInviteModel(teamInvite));
  }

  async getSuggestedTeams() {
    this.res = await this.RestManager.getTeamSuggest();

    if (this.res.success) {
      this.suggestedTeams = this.res.teams.map((teamItem) => new TeamModel(teamItem));
    }
  }

  removeInviteByTeamId(evt, data){
    const index = this.teamInvites.findIndex(teamInvite => teamInvite.team.id === data.teamId);
    this.teamInvites.splice(index, 1);
    if (!this.$scope.$$phase){
      this.$scope.$digest();
    }
  }
}
teamsJoinCtrl.$inject = [
  '$scope',
  'RestManager',
  '$timeout',
  'stateService',
  'teamsService',
  'gsScrollTopService',
  'tagEventService',
];
export default {
  template: template,
  controller: teamsJoinCtrl
};
