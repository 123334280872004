const template = require(`html-loader!./downloadApps.html`).default;

class downloadAppsCtrl {
  constructor(
    $element,
    $scope,
    $timeout,
    $cookies
  ) {
    'ngInject';

    this.$ctrl = this;
    this.device = window.device;

    this.$element = $element;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$cookies = $cookies;
  }

  $onInit() {
    this.$timeout(() => {
      const height = this.$element[0].offsetHeight + 'px';
      $('body').addClass('downloadApps');
    });
  }

  $onDestroy() {
    console.log('$onDestroy');
    this.destroy();
  }

  destroy() {
    $('body').removeClass('downloadApps');
  }

  remove() {
    let expired = new Date();
    expired.setDate(expired.getDate() + 31);
    this.$cookies.put('hideDownloadApps', true, { expires: expired });
    this.$element.remove();
    this.$scope.$destroy();
    this.destroy();
  }
}

downloadAppsCtrl.$inject = [
  '$element',
  '$scope',
  '$timeout',
  '$cookies'
];

export default {
  template: template,
  controller: downloadAppsCtrl,
};
