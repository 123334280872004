const template = require(`html-loader!./searchBox.html`).default;
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";

class searchBoxCtrl {
  /**
   * @param {RestManager} RestManager
   * */
  constructor(
    $timeout,
    $element,
    RestManager,
    $scope,
    mixpanelService
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$element = $element;
    this.RestManager = RestManager;
    this.$scope = $scope;
    this.mixpanelService = mixpanelService;

    this.onDocument = this.onDocument.bind(this);
    this.search = _.throttle(this.search.bind(this), 500);
  }

  $onInit() {
    this.searchText = '';
    this.promise = null;
  }

  search() {
    if (this.searchText.length < 3) {
      return;
    }

    this.eventMixPanelAction();

    //Close event
    $(document)
      .off('click', this.onDocument)
      .on('click', this.onDocument)
    ;

    this.items = [];
    this.busy = true;

    let promise;
    this.promise = promise = this.RestManager.search(this.searchText)
      .then(res => {
        if (this.promise !== promise) {
          return false;
        }

        this.items = res.items;

        this.$timeout(()=>{
          this.busy = false;
        });
      })
    ;
  }

  toggleSearch() {
    //Hide
    if(this.active) {
      this.hide();
    }
    //Show
    else {
      this.active = true;
    }
  }

  hide(){
    this.searchText = '';
    this.busy = false;
    this.active = false;
  }

  // close on document click
  onDocument(event) {
    if ($(this.$element[0]).find(event.target).length > 0) {
      return true;
    }
    $(document).off('click', this.onDocument);
    this.searchText = '';
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
    return true;
  }

  eventMixPanelAction(searched_member_id = '') {
    let data = {
      ui_category: mixpanelEventsEnum.UI_CATEGORY.TOP_NAV,
    };

    if (searched_member_id !== '') {
      data.searched_member_id = searched_member_id;
      data.ui_name = mixpanelEventsEnum.UI_NAME.SEARCH;
    } else {
      data.ui_name = mixpanelEventsEnum.UI_NAME.SEARCH_TYPING;
      data.ui_action = mixpanelEventsEnum.UI_ACTION.TYPE;
    }

    this.mixpanelService.track({data});
  }
}

searchBoxCtrl.$inject = [
  '$timeout',
  '$element',
  'RestManager',
  '$scope',
  'mixpanelService'
];

export default {
  template: template,
  controller: searchBoxCtrl
};
