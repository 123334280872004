import {Component, Input, TemplateRef} from '@angular/core';

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrl: './custom-tooltip.component.scss'
})
export class CustomTooltipComponent {
  @Input() contentTemplate!: TemplateRef<any>;

}
