const template = require(`html-loader!./teamsConfirm.html`).default;
import {teamConfirmTypesData} from "./teamsConfirm.data";

class teamsConfirmCtrl {

  constructor($mdDialog, $rootScope) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
  }

  $onInit() {
    this.confirmData = teamConfirmTypesData[this.data.teamConfirmType];
  }

  close(isConfirmed = false) {
    this.$mdDialog.hide(isConfirmed);
  }

  confirm(){
    this.close(true);
  }
}
teamsConfirmCtrl.$inject = [
  '$mdDialog', '$rootScope'
];
export default {
  template: template,
  controller: teamsConfirmCtrl,
  bindings: {
    data: '=data',
  },
};
