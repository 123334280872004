export const filters = {
  Votes: { order: 'votes', sort: 'desc' },
  Achievements: { order: 'achievements', sort: 'desc' },
  Newest: { order: 'date', sort: 'desc' },
  Oldest: { order: 'date', sort: 'asc' },
};

export const justifiedGalleryConf = {
  rowHeight: 150,
  maxRowHeight: 150,
  lastRow: 'nojustify',
  captions: false,
  margins: 5,
  waitThumbnailsLoad: false,
  selector: 'div',
  cssAnimation: false,
  imagesAnimationDuration: 0,
};