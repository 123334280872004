const template = require(`html-loader!./matchMemberItem.html`).default;

class matchMemberItemCtrl {

  constructor($scope, $rootScope) {
    'ngInject';
    this.$scope = $scope;
    this.$rootScope = $rootScope;
  }

  $onInit() {}
}

matchMemberItemCtrl.$inject = [
  '$scope', '$rootScope'
];
export default {
  template: template,
  controller: matchMemberItemCtrl,
  bindings: {
    member: '<',
    index: '<'
  }
};
