<span class="resources-title gs-theme-color-gray_70">{{title}}</span>
<ng-container *ngIf="!sections || sections?.length === 0; else Sections">
  <span class="gs-theme-text-bold gs-theme-color-gray_80">No available prizes</span>
</ng-container>

<ng-template #Sections>
  <div
    class="resource-section-wrapper"
    *ngFor="let section of sections"
  >
    <div
      class="resource-section-item"
      *ngFor="let resource of section.resources"
    >
      <div class="icon icon-{{resource.type.toLowerCase()}}"></div>

      <div class="value gs-theme-text-bold">{{resource.value | number}}</div>
    </div>
  </div>
</ng-template>

<div
  *ngIf="isInfoButtonVisible"
  class="icon-tooltip-prizes-info"
  customToolTip [contentTemplate]="tooltipTemplate"
  (onShow)="onTooltipShow()"
></div>

<ng-template #tooltipTemplate>
  <div class="tooltip-header gs-theme-text-caption-bold-caps gs-theme-color-gray_70">Prizes</div>
  <div
    class="resource-section-wrapper flex-column bottom-border"
    *ngFor="let section of allSections"
    [ngClass]="{'total-bg': section.name === ResourceNameEnum.TOTAL}"
  >
    <span class="resources-title gs-theme-text-caption-regular-caps gs-theme-color-gray_70">{{section.name}}</span>

    <div class="resource-section">
      <div
        class="resource-section-item"
        *ngFor="let resource of section.resources"
      >
        <div class="icon icon-{{resource.type.toLowerCase()}}"></div>

        <div class="value gs-theme-text-bold gs-theme-color-gray_80">{{resource.value | number}}</div>
      </div>
    </div>
  </div>
</ng-template>
