import RankingModel from './ranking.model';
import ImageModel from './image/image.model';
import memberEnum from "../enums/member.enum";
import LeagueModel from "./team/league.model";

/**
 * @property {SubmitStates} submit_state
 * **/
class MemberModel {
  constructor(props) {
    this.about = props.about;
    this.adult = props.adult;
    this.avatar = props.avatar;
    this.country = props.country;
    this.city = props.city;
    this.disable_funds = props.disable_funds;
    this.email = props.email;
    this.exhibition = props.exhibition || null;
    this.exposure = props.exposure;
    this.facebook = props.facebook;
    this.first_name = props.first_name;
    this.follow = props.follow;
    this.following = props.following;
    this.friends = props.friends;
    this.google = props.google;
    this.id = props.id;
    this.instagram = props.instagram;
    this.intro = props.intro;
    this.is_admin = props.is_admin;
    this.is_following = props.is_following;
    this.is_guru = props.is_guru;
    this.ip_address = props.ip_address;
    this.is_joined = props.is_joined || false;
    this.last_name = props.last_name;
    this.logged_in = props.logged_in || false;
    this.member_group_id = props.member_group_id;
    this.member_status = props.member_status;
    this.member_status_name = props.member_status_name;
    this.name = props.name;
    this.permissions = props.permissions || {};
    this.points = props.points;
    this.profile_cover = props.profile_cover;
    this.quote = props.quote;
    this.ranking = new RankingModel(props.ranking || {});
    this.reg_date = props.reg_date;
    this.submit_state = props.submit_state;
    this.suggested_images = (props.suggested_images || []).map(image => new ImageModel(image));
    this.time_joined = props.time_joined * 1000;
    this.quote = props.quote;
    this.reg_date = props.reg_date;
    this.reg_time = props.reg_time ? new Date(props.reg_time * 1000) : null;
    this.twitter = props.twitter;
    this.user_name = props.user_name;
    this.voting_power = props.voting_power;
    this.website = props.website;
    this.youtube = props.youtube;
    this.facebook_connected = props.facebook_connected;
    this.moderator = props.moderator;
    this.moderator_badge = props.moderator_badge;
    this.google = props.google;
    this.member_league_id = props.member_league_id || 0;
    // this.member_league_id = 5;
    if(props.league) {
      this.league = new LeagueModel(props.league);
    }
  }

  canShowLeagueBadge(){
    return this.member_league_id /*&& this.member_league_id >= 7*/;
  }

  isOwner(id) {
    return this.id === id;
  }

  inTestGroup() {
    return this.member_group_id === 1001;
  }

  isRestricted(){
    return this.isDeactivatedByMember() || this.isDeactivatedByAdmin() || this.isDeletedByMember() || this.isDeletedByAdmin();
  }

  isDeactivatedByMember(){
    return this.member_group_id === memberEnum.GROUP.DEACTIVATE_BY_MEMBER;
  }

  isDeactivatedByAdmin(){
    return this.member_group_id === memberEnum.GROUP.DEACTIVATE_BY_ADMIN;
  }

  isDeletedByMember(){
    return this.member_group_id === memberEnum.GROUP.DELETED_BY_MEMBER;
  }

  isDeletedByAdmin(){
    return this.member_group_id === memberEnum.GROUP.DELETED_BY_ADMIN;
  }

  get isAdmin() {
    return !!this.is_admin;
  }

  get isGuru() {
    return this.member_group_id === 2000;
  }

  get gsData() {
    let gsData = {
      id: this.id,
      member_group_id: this.member_group_id,
      user_name: this.user_name,
      avatar: this.avatar,
      logged_in: this.logged_in,
      adult: this.adult,
    };
    if (this.is_admin) {
      gsData.is_admin = this.is_admin;
    }
    return gsData;
  }
}

export default MemberModel;
