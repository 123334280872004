/** Challenge rank endpoints */
class RankingManager {
  constructor(api) {
    "ngInject";
    this.api = api;
  }

  getTopPhotographer({c_id, limit_above, limit_below, filter, init, level,
                       index, user_name, member_id}) {
    return this.api["rest"]["getTopPhotographer"]({
      c_id, limit_above, limit_below, filter, init, level,
      index, user_name, member_id
    });
  }

  getTopPhoto({c_id, filter, reason_id, limit, start}) {
    return this.api["ranking"]["getTopPhotos"]({c_id, filter, limit, start});
  }

  getGuruPicks({start, limit, filter, c_id}) {
    return this.api["ranking"]["getGuruPicks"]({start, limit, filter, c_id});
  }
}

RankingManager.$inject = [
  'api'
];

export default RankingManager;
