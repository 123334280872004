const template = require(`html-loader!./enterPassword.html`).default;

class enterPasswordCtrl {
  /**
   * @param {AuthenticationManager} AuthenticationManager
   * @param {ModalsService} Modals
   * */

  constructor($mdDialog, AuthenticationManager, $rootScope, Modals, MemberService) {
    'ngInject';
    this.error = false;

    this.$mdDialog = $mdDialog;
    this.AuthenticationManager = AuthenticationManager;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.title = this.data.title ? this.data.title : 'Enter password';
    this.confirmTitle = this.data.confirmTitle ? this.data.confirmTitle : 'CONFIRM';
    this.cancelTitle = this.data.cancelTitle ? this.data.cancelTitle : 'CANCEL';
    this.callback = this.data.callback ? this.data.callback : () => {};
  }

  forgotPassword() {
    this.Modals.open('login', null, {state: 'forgot', hideSignIn: true});
  }

  /** @return void */
  confirm() {
    if (this.busy) {
      return;
    }
    if (!this.password || this.password.length < 3) {
      this.error = true;
      return;
    }
    this.busy = true;
    this.error = false;
    this.AuthenticationManager.signIn(this.member.user_name, this.password).then(res => {
      this.busy = false;
      if (!res.success) {
        this.error = true;
        return;
      }
      this.close();
      this.callback();
    });
  }

  close() {
    this.$mdDialog.hide();
  }
}
enterPasswordCtrl.$inject = [
  '$mdDialog', 'AuthenticationManager', '$rootScope', 'Modals', 'MemberService'
];
export default {
  template: template,
  controller: enterPasswordCtrl,
  bindings: {
    data: '=data'
  }
};
