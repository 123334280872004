<button
  *ngIf="status == claimStatusEnum.CLAIM"
  class="claim-button gs-theme-text-caps-bold"
  [disabled]="isDisabled"
  (click)="onClaim()"
>
  claim
</button>

<span
  *ngIf="status == claimStatusEnum.CLAIMED"
  class="claim-text"
>
  claimed
</span>
