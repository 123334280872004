const template = require(`html-loader!./avatarFull.html`).default;

class avatarFullCtrl {
  constructor(
    $rootScope
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
  }
}

avatarFullCtrl.$inject = [
  '$rootScope'
];

export default {
  template: template,
  controller: avatarFullCtrl,
  bindings: {
    member: '<'
  }
};
