const template = require(`html-loader!./teamBadges.html`).default;

class teamBadgesCtrl {

  constructor($mdDialog, $rootScope, RestManager, $scope) {
    'ngInject';
    this.busy = false;
    this.loadMore = true;
    this.badges = [];
    this.limit = 100;
    this.start = 0;
    this.containerClass = '.modal-team-badges__content';

    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.$scope = $scope;
  }

  $onInit() {
    this.init();
  }

  async init(){
    if(this.data.team.badge){
      this.select(this.data.team.badge);
    }
    await this.getTeamBadges();
    $(this.containerClass).on('scroll', this.onBadgesScroll.bind(this));
  }

  $onDestroy() {
    $(this.containerClass).off('scroll');
  }

  async getTeamBadges(){
    if (!this.busy && this.loadMore) {
      this.busy = true;
      const res = await this.RestManager.getTeamBadges({start: this.start, limit: this.limit});
      this.busy = false;
      if (!res.success) {
        return;
      }
      const data = res.badges;
      this.badges = [ ...this.badges, ...data];
      if (data.length !== this.limit) {
        this.loadMore = false;
        console.log('do not loadMore');
      } else {
        this.start += this.limit;
      }
      if (!this.$scope.$$phase) {this.$scope.$digest()}
    }
  }

  select(badge){
    this.selectedBadge = badge;
  }

  setBadge(){
    this.data.team.badge = this.selectedBadge;
    this.close();
  }

  close() {
    this.$mdDialog.hide();
  }

  onBadgesScroll(e) {
    if (!this.busy && this.loadMore) {
      const selector = $(this.containerClass);
      if (selector.scrollTop() + selector.innerHeight() >= selector[0].scrollHeight-100) {
        console.log('getTeamBadges');
        this.getTeamBadges();
      }
    }
  }
}
teamBadgesCtrl.$inject = [
  '$mdDialog', '$rootScope', 'RestManager', '$scope'
];
export default {
  template: template,
  controller: teamBadgesCtrl,
  bindings: {
    data: '=data',
  },
};
