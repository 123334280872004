import { NgModule } from '@angular/core';
import { PaymentComponent } from './containers/payment/payment.component';
import {SharedModule} from "../../shared/shared.module";
import {ReactiveFormsModule} from "@angular/forms";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {PaypalButtonComponent} from "./components/paypal-button/paypal-button.component";
import { SavedCardComponent } from './components/saved-card/saved-card.component';
import {AngularMaterialModule} from "../angular-material/angular-material.module";


@NgModule({
  declarations: [
      PaymentComponent,
      PaypalButtonComponent,
      SavedCardComponent
  ],
  exports: [
      PaymentComponent
  ],
  imports: [
    SharedModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AngularMaterialModule
  ]
})
export class PaymentModule { }
