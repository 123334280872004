/* globals angular */
const template = require(`html-loader!./ruleBlock.html`).default;

class ruleBlockCtrl {
  constructor() {

    this.open = false;
  }
}

export default {
  template: template,
  controller: ruleBlockCtrl,
  bindings: {
    data: '<data'
  }
};
