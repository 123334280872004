import GsResponse from "./GsResponse";
import ImageModel from "../image/image.model";
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

class GetChallengesByAchievement extends GsResponse{
  constructor({items, success}) {
    super(success);
    Object.assign(this,
      (![undefined,null].includes(items)) && {items: items.map(
        (item) => {
          item.challenge = new NgChallengeModel(item.challenge);
          item.top_image = new ImageModel(item.top_image);
          return item;
        })
      },
    );
  }
}


export default GetChallengesByAchievement;
