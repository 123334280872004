<div [ngClass]="'m-' + sectionType.toLowerCase()"
     (click)="storeItemClicked.emit()"
     class="store-item gs-t-a-c">
  <div class="gs-w-80 gs-h-40 gs-m-0-a gs-theme-color-gray_80 gs-theme-text-bold gs-center-flex">{{storeItem.name}}</div>
  <app-store-item-icon-amount [typeClass]="sectionType"
                              [item]="storeItem"
                              [itemSize]="80"
  ></app-store-item-icon-amount>
  <div *ngIf="storeItem.label"
       class="store-item_label gs-theme-text-caption-bold-caps gs-theme-color-white_100">
      {{storeItem.label.title}}
    <div class="store-item_label_triangle"></div>
  </div>
  <div [ngSwitch]="storeItem.cost_currency"
       [ngClass]="'m-' + sectionType.toLowerCase()"
       class="button gs-theme-text-bold gs-theme-color-white_100">
    <app-store-item-price [item]="storeItem"></app-store-item-price>
  </div>
</div>
<div (click)="openInfoModal()"
     *ngIf="storeItem.received.length > 1"
     class="store-item__info gs-center-flex gs-c-p">
  <i class="icon-info-sign"></i>
</div>
