import { Component } from '@angular/core';

@Component({
  selector: 'app-challenges-upcoming-wrapper',
  templateUrl: './challenges-upcoming-wrapper.component.html',
  styleUrl: './challenges-upcoming-wrapper.component.scss'
})
export class ChallengesUpcomingWrapperComponent {

}
