import ImageModel from "./image/image.model";

class MediaModel {
  constructor({type, id, image = {}}) {
    image = new ImageModel(image);
    Object.assign(this, {type, id, image});
  }
}

export default MediaModel;
