import { Component } from '@angular/core';

@Component({
  selector: 'app-ads-view1-wrapper',
  templateUrl: './ads-view1-wrapper.component.html',
  styleUrl: './ads-view1-wrapper.component.scss'
})
export class AdsView1WrapperComponent {

}
