import CountryModel from './country.model';

class InviteMemberModel {
  constructor(props) {
    this.id = props.id;
    this.avatar = props.avatar;
    this.member_id = props.member_id;
    this.member_status = props.member_status;
    this.member_status_name = props.member_status_name;
    this.name = props.name;
    this.user_name = props.user_name;
    this.country = props.country ? new CountryModel(props.country) : props.country;
  }
}

export default InviteMemberModel;
