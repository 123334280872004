import {delay, map, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./votePage.html`).default;

class votePageComponent {
  subject = new Subject();
  constructor(pageData, $window, $scope, RouterService) {
    'ngInject';
    this.busy = true;
    this.data = {};

    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.RouterService = RouterService;

    this.onResize = this.onResize.bind(this);

    this.RouterService.activatedRoute
      .paramMap.pipe(
      map(() => window.history.state),
      takeUntil(this.subject)
    ).subscribe(async params => {
      this.data.mission = params.mission;
    });

    this.RouterService.activatedRoute
      .params.pipe(
      delay(50),
      takeUntil(this.subject)
    ).subscribe(async () => {
      if(!this.url) {
        this.RouterService.go('challenges/my-challenges/current');
        return;
      }
      let challenge = {};
      challenge.url = this.url;
      this.data.challenge = challenge;
      this.init();
    });
  }

  $onDestroy() {
    $(window).off('resize', this.onResize);
    this.subject.next();
    this.subject.complete();
  }

  init() {
    this.pageData.get('vote');
    // fix for iOs bottom bar
    this.availHeight = window.innerHeight;
    $(window).on('resize', this.onResize);
  }

  onResize() {
    this.availHeight = window.innerHeight;
  }
}
votePageComponent.$inject = [
  'pageData', '$window', '$scope', 'RouterService'
];
export default {
  template: template,
  controller: votePageComponent,
  bindings: {
    url: '<'
  }
};
