class InfinityScroll {
  constructor(
    $rootScope,
    $window
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$window = $window;
  }
}

InfinityScroll.$inject = [
  '$rootScope',
  '$window'
];

export default function infinityScroll() {
  'ngInject';

  return {
    restrict: 'A',
    transclude: true,
    controller: InfinityScroll,
    link: (scope, element, attr, ctrl) => {
      let scrollEventFn = () => {
        const scrollingHeight = event.target.scrollHeight - event.target.offsetHeight;
        const fireEventAfterScrollPercentage = 70;

        let loadMoreScrollValue = scrollingHeight * fireEventAfterScrollPercentage / 100;

        if (event.target.scrollTop >= loadMoreScrollValue) {
          ctrl.$rootScope.$emit('infinityScrollLoadMore');
        }
      };

      element
        .addClass('gs-o-s gs-scroll')
        .on('scroll', scrollEventFn.bind(this))
      ;
    },
    template: require(`html-loader!./infinityScroll.html`).default
  };
}
