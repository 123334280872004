import TeamModel from './team.model';
import InviteMemberModel from './inviteMember.model';

class TeamInviteModel {
  constructor(props) {
    this.invitation_date = props.invitation_date;
    this.team = new TeamModel(props.team);
    this.team.can_join = true;
    this.inviting_member = new InviteMemberModel(props.inviting_member);
  }
}

export default TeamInviteModel;
