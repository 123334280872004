class GsScrollTopService {

  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  scrollTop(duration = 0) {

    this.$timeout(() => {
      // window.scrollTo({top: 0});
      $('html, body').animate({ scrollTop: 0 }, duration);
    });
  }
}
GsScrollTopService.$inject = [
  '$timeout'
];
export default GsScrollTopService;
