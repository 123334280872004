<div class="gs-p-r store-item-icon-amount gs-center-v-flex"
[ngStyle]="{margin: item.received.length === 1? '18px 3px 0 3px' : '10px 3px 0 3px'}">
  <div *ngIf="item.received.length === 1"
       class="amount gs-p-a gs-theme-text-headline gs-theme-color-white_100 gs-w-30 gs-t-a-c">
    {{item!.received[0].amount}}
  </div>
  <img [width]="itemSize"
       [height]="itemSize"
       [ngSrc]="item!.icon.png_url"
       [alt]="item!.name"
       [title]="item!.name"
       class="gs-d-b" />
</div>
