import mixpanelEventsEnum from "../../../mixpanel/mixpanel.enum";

const template = require(`html-loader!./gsUploaderButtons.html`).default;

class gsUploaderButtonsComponent {

  constructor(
    mixpanelService,
    MemberService
  ) {
    'ngInject';
    this.mixpanelService = mixpanelService;
    this.member = MemberService.getCurrentMember();
  }
  selectSource(source, uiAction) {
    this.settings.source = source;
    switch (source) {
      case 'device':
        this.settings.state = 'device';
        // patch for boarding
        if (this.settings.autoSubmit && !device.mobile()) {
          setTimeout(() => $('.gs-uploader-files__drop-box').click(), 500);
        }
        break;
      default:
        this.settings.state = 'photos';
    }
    if (['theme', 'challenge'].includes(this.settings.target)){
      this.sendSelectPhotoSourceClientEvent(uiAction);
      // this.battleEventsService.sendSelectPhotoSourceClientEvent(
      //   uiAction,
      //   this.settings.target === 'challenge'
      // );
    }
  }
  sendSelectPhotoSourceClientEvent(uiAction) {
    this.mixpanelService.track({
      // event: mixpanelEventsEnum.EVENT_NAME.JOIN,
      data: {
        ui_name: mixpanelEventsEnum.UI_NAME.CHOOSE_PHOTO,
        ui_action: uiAction,
        type: 'challenge',
        member_id: this.member.id,
      }
    });
  }

  swapBack() {
    this.settings.state = 'swap';
  }
}

gsUploaderButtonsComponent.$inject = [
  'mixpanelService',
  'MemberService',
];

export default {
  template: template,
  controller: gsUploaderButtonsComponent,
  bindings: {
    settings: '=',
    tools: '=',
  },
};
