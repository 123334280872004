export class EnvironmentStaticBrazeMobileModel {
  brazeMobile: { [key: string]: string } = {
    preprod: 'e4ec0cc9-83e1-4b4b-9e3f-527cca248ab6',
    prod: 'e4ec0cc9-83e1-4b4b-9e3f-527cca248ab6',
    beta: 'e4ec0cc9-83e1-4b4b-9e3f-527cca248ab6',
    stage: '887d0c60-e072-4386-9b56-de535e08cf21'
  }

  constructor() {
  }

  get(env: string): string {
    return this.brazeMobile[env];
  }
}
