// const template = require(`html-loader!./avatar.html`).default;
// import leagueEnum from "../../api/enums/league.enum";
// import memberEnum from "../../api/enums/member.enum";
// import TeamMemberModel from "../../api/models/team/teamMember.model";
//
// class avatarCtrl {
//   constructor(
//     $rootScope,
//     stateService,
//     Modals
//   ) {
//     'ngInject';
//
//     this.$rootScope = $rootScope;
//     this.stateService = stateService;
//     this.Modals = Modals;
//     this.noAvatarUrl = memberEnum.AVATAR.PLACEHOLDER;
//     this.avatar = null;
//   }
//
//   $onInit(){}
//
//   $onChanges(changes) {
//     if (changes.member && changes.member.currentValue) {
//       const newSize = this.size || '50x50';
//       this.avatar = {url:this.member.avatar ? this.member.avatar.replace('500x500', newSize) : memberEnum.AVATAR.PLACEHOLDER };
//       this.showBadge = this.showLeagueBadge && this.member.canShowLeagueBadge();
//       if(this.showBadge){
//         this.leagueConf = leagueEnum.ID_LEAGUE[this.member.member_league_id]();
//       }
//     }
//   }
//
//   onImageLoaded(){
//     this.loaded = true;
//   }
//
//   onImageError(){
//     this.loaded = true;
//   }
//
//   onClick(){
//     if(this.clickable === 'false'){return}
//     const memberUserName = this.member.user_name || this.member.memberUserName;
//     const state = this.goToAdminMemberManager === 'true' ? 'gs.gsAdmin.memberManager' : 'gs.profile';
//     const stateMethod = this.newTab === 'true' ? 'goToStateNewTab' : 'goToState';
//     if(memberUserName){
//       this.stateService[stateMethod](state, {cUserName:memberUserName});
//       this.Modals.close();
//     }
//   }
//
//   isTeamAdmin(){
//     if (this.member && TeamMemberModel.prototype.isPrototypeOf(this.member)) {
//       return this.member.isAdmin();
//     }
//     return false;
//   }
// }
//
// avatarCtrl.$inject = [
//   '$rootScope',
//   'stateService',
//   'Modals'
// ];
//
// export default {
//   template: template,
//   controller: avatarCtrl,
//   bindings: {
//     member: '<',
//     size:'@',
//     lazy:'@',
//     watch:'@',
//     newTab:'@',
//     goToAdminMemberManager:'@',
//     clickable:'@',
//     showLeagueBadge:'@'
//   }
// };
