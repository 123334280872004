function gsShowOnScroll(
  $timeout,
  $document,
  $window
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      top: '@gsShowOnScroll'
    },
    link: function (scope, element) {
      function onScroll() {
        if ($window.scrollY > scope.top) {
          element.addClass('active');
        }
        else {
          element.removeClass('active');
        }
      }

      $timeout(() => {
        $document.bind('scroll', _.debounce(onScroll, 250));
      });
    }
  };
}

gsShowOnScroll.$inject = [
  '$timeout',
  '$document',
  '$window'
];

export default gsShowOnScroll;
