const template = require(`html-loader!./topTabMenu.html`).default;
import {topTabMenuData} from "./topTabMenu.data";
import {autoUpdateTypeEnum} from "../../services/autoUpdate.service.data";
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";

class topTabMenuCtrl {
  constructor(
    $scope,
    $rootScope,
    autoUpdateService,
    mixpanelService,
    JoinedChallengesPageService,
    MemberService,
    SessionConfigService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.autoUpdateService = autoUpdateService;
    this.mixpanelService = mixpanelService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.SessionConfigService = SessionConfigService;
    this.MemberService = MemberService;
    this.member = this.MemberService.getCurrentMember();
    this.updateIndicatorObj = {};
  }

  $onDestroy() {
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.COMPLETED_CHALLENGES);
  }

  $onChanges(){
    if (this.tabsType) {
      this.tabs = topTabMenuData.TYPE[this.tabsType].TABS;
      for (const tab of this.tabs) {
        if(this.tabsType === 'ACHIEVEMENTS'){
          if(this.MemberService.profilePageMember){
            tab.state = tab.state.replace('${profileName}', this.MemberService.profilePageMember.user_name);
          }
        }
      }
      this.updateIndicators();
    }
  }

  isTabPermitted(tabPermissions){
    if (!this.SessionConfigService.permissions) { return false; }
    for (const permissionName of tabPermissions) {
      if (this.SessionConfigService.permissions[permissionName]) {
        return true;
      }
    }
    return false;
  }

  tabClicked(tab){
    if (tab.PARENT) {
      if (tab.TITLE === 'MY CHALLENGES') {
        const el = document.getElementsByClassName('top-tab-menu-joined-tab');
        if (el && el[0]) {
          el[0].className += ' active';
        }
      }
      if (tab.TITLE === 'DISCOVER') {
        const el = document.getElementsByClassName('top-tab-menu-open-tab');
        if (el && el[0]) {
          el[0].className += ' active';
        }
      }
    }
    if (this.noStateMode && !tab.SELECTED) {
      this.tabs.map((c_tab)=> {
        c_tab.SELECTED = c_tab.TITLE === tab.TITLE;
      });
    }

    switch (this.tabsType){
      case topTabMenuData.TYPE.CHALLENGES.NAME:
        if (tab.state === 'challenges/my-challenges/completed') {
          //TODO move indicators to a service
          this.updateIndicatorObj[this.tabsType] = false;
          this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.COMPLETED_CHALLENGES);
        } else {
          this.updateIndicators();
        }
        break;
    }

    this.eventMixPanelAction(tab);
  }

  updateIndicators(){
    if(this.tabsType === topTabMenuData.TYPE.CHALLENGES.NAME){
      // console.log('updateIndicators');
      this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.COMPLETED_CHALLENGES);
      this.autoUpdateService.startAutoUpdateByType({
        type : autoUpdateTypeEnum.COMPLETED_CHALLENGES,
        callback : this.isNewCompletedChallenges.bind(this),
        initCallbackOnStart: true
      });
    }
  }

  async isNewCompletedChallenges(){
    const res = await this.JoinedChallengesPageService.isNewCompletedChallenges();
    if (res.success) {
      this.updateIndicatorObj[this.tabsType] = res.is_new;
    }
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  eventMixPanelAction(tab) {
    tab = tab.TITLE.toLowerCase();
    let data = {
      ui_category: mixpanelEventsEnum.UI_CATEGORY.TOP_SECONDARY_NAV,
      ui_name: tab
    };

    if (tab === 'completed') {console.log('when completed tab this.updateIndicatorObj[this.tabsType] ', this.updateIndicatorObj[this.tabsType]);
      data.ui_notification = this.updateIndicatorObj[this.tabsType] ? 'active': null;
    }

    this.mixpanelService.track({data});
  }
}

topTabMenuCtrl.$inject = [
  '$scope',
  '$rootScope',
  'autoUpdateService',
  'mixpanelService',
  'JoinedChallengesPageService',
  'MemberService',
  'SessionConfigService',
];

export default {
  template: template,
  controller: topTabMenuCtrl,
  bindings: {
    tabsType: '<',
    permissions: '<',
    noStateMode: '@'
  }
};
