import { NgModule } from '@angular/core';
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import GsHttpInterceptor from "./interceptors/httpInterceptor";
import ObjectHelper from "./helpers/object.helper";
import NgTokenService from "./services/ng-token.service";
import {CookieModule} from "ngx-cookie";
import NgCookieService from "./services/cookie.service";
import {UrlService} from "./services/url.service";
import {StateService} from "./services/state.service";
import {NgLocalStorageService} from "./services/ng-local-storage.service";
import {MemberService} from "./services/member.service";
import {ScrollService} from "./services/scroll.service";
import {InfoService} from "./services/info.service";
import {ScriptService} from "./services/script.service";
import {BattleFtueService} from "./services/battle-ftue.service";
import {SessionService} from "./services/session.service";
import {SessionConfigService} from "./services/session-config.service";
import {NgMetaDataService} from "./services/ng-meta-data.service";
// import {AdsService} from "./services/ads.service";

@NgModule({
  imports: [
    CookieModule.withOptions(),
  ],
  providers:[
    ObjectHelper,
    NgCookieService,
    BattleFtueService,
    NgTokenService,
    UrlService,
    StateService,
    NgLocalStorageService,
    MemberService,
    ScrollService,
    InfoService,
    ScriptService,
    SessionService,
    SessionConfigService,
    NgMetaDataService,

    // AdsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GsHttpInterceptor,
      multi: true
    },
  ]
})
export class CoreModule { }
