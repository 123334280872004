const template = require(`html-loader!./myChallengesManage.html`).default;
import challengeEnum from '../../../api/enums/challenge.enum';

const IS_MOBILE = 1350;

class myChallengesManageCtrl {
  constructor(
    ChallengesManager,
    $scope,
    RouterService,
    $filter,
    MemberService,
    SessionConfigService
  ) {

    'ngInject';
    this.challengeEnumStatuses = challengeEnum.STATUSES;
    this.busy = true;
    this.challenges = {};
    this.isEmpty = true;
    this.closedLimit = 20;
    this.isOpen = {
      upcoming: true,
      active: true,
      pending: true,
      draft: true,
      closed: false,
      suggested: true
    };

    this.ChallengesManager = ChallengesManager;
    this.MemberService = MemberService;
    this.SessionConfigService = SessionConfigService;
    this.$scope = $scope;
    this.RouterService = RouterService;
    this.$filter = $filter;
    this.member = this.MemberService.getCurrentMember();

    this.onResize = _.debounce(this.onResize.bind(this), 250);
  }

  $onInit() {
    // mobile view
    this.isMobile = window.innerWidth <= IS_MOBILE;
    $(window).on('resize', this.onResize);
    // no permissions
    if (!this.SessionConfigService.permissions.CREATE_CHALLENGE && !this.SessionConfigService.permissions.CHALLENGE_BID) {
      this.RouterService.go('challenges/my-challenges/current');
      return;
    }
    this.getData();
  }

  /** @return void */
  async getData() {
    const res = await this.ChallengesManager.getGuruChallenges();
    if (!res.success) {
      return;
    }
    // total
    this.totals = res.totals;
    this.settings = res.settings;
    this.isCooldown = res.cooldown;
    // challenges model
    // this.challenges = res.challenges;
    this.isBIdEmpty = res.challenges[this.challengeEnumStatuses.IN_BID.VALUE].length === 0;
    this.challengesGroups = [
      {status:'suggested', challenges: res.challenges[this.challengeEnumStatuses.IN_BID.VALUE]},
      {status:this.challengeEnumStatuses.ACTIVE.VALUE, challenges: res.challenges[this.challengeEnumStatuses.ACTIVE.VALUE]},
      {status:this.challengeEnumStatuses.UPCOMING.VALUE, challenges: res.challenges[this.challengeEnumStatuses.UPCOMING.VALUE]},
      {status:this.challengeEnumStatuses.PENDING.VALUE, challenges: [...res.challenges[this.challengeEnumStatuses.ASSIGNED.VALUE], ...res.challenges[this.challengeEnumStatuses.PENDING.VALUE]]},
      {status:this.challengeEnumStatuses.DRAFT.VALUE, challenges: res.challenges[this.challengeEnumStatuses.DRAFT.VALUE]},
      {status:this.challengeEnumStatuses.CLOSED.VALUE, challenges: res.challenges[this.challengeEnumStatuses.CLOSED.VALUE]},
    ];
    // if all groups empty
    for (const group of this.challengesGroups) {
      if (group.challenges.length) {
        this.isEmpty = false;
      }
    }

    this.setBidText();

    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  setBidText(){
    if(this.isCooldown){
      this.bidText = {};
      const date = this.$filter('date')(new Date(this.isCooldown * 1000), 'dd.MM.yyyy HH:mm');
      if(this.SessionConfigService.permissions.CREATE_CHALLENGE){
        this.bidText.desc_1 = ` In order to give as many Gurus the opportunity to run a challenge, there is a period of
          time when you will not be able to Bid on challenges after your challenge has ended..<br>In addition,
          challenges that you create and submit will not be seen in the que until this period of time has ended.
          You will be able to bid and submit challenges again on ${date}`;
        // this.bidText.desc_2 = 'In addition, challenges that you create and submit will not be seen in the que until this period of time has ended. You will be able to bid and submit challenges again on <INSERT DATE>';
        this.bidText.title = 'Thank you for running a challenge!';
        return;
      }
      if(this.SessionConfigService.permissions.CHALLENGE_BID){
        this.bidText.desc_1 = `In order to give as many Gurus the opportunity to run a challenge,
          there is a period of time when you will not be able to Bid on challenges after your challenge has ended.
          <br>You will be able to run challenges again on ${date}`;
        // this.bidText.desc_2 = 'You will be able to run challenges again on <INSERT DATE>';
        this.bidText.title = 'Thank you for running a challenge!';
        return;
      }
    }

    if(!this.SessionConfigService.permissions.CREATE_CHALLENGE && this.SessionConfigService.permissions.CHALLENGE_BID){
      this.bidText = {};
      this.bidText.desc_1 = 'To bid on a challenge, simply upload a cover banner to the challenge you want to run. You will receive confirmation on your challenge choice shortly after submitting..';
      this.bidText.desc_2 = 'To increase your chances of being selected, ensure your selected image is as relevant to the challenge theme as possible and of high quality.';
      if(this.isBIdEmpty){
        this.bidText.title = 'Oops! Looks like today’s challenges have been claimed. New challenges can be added at any time, so check in regularly.';
      }else{
        this.bidText.title = 'You have suggested challenges available!';
      }
    }
  }

  onResize() {
    this.isMobile = window.innerWidth <= IS_MOBILE;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  moreClosed() {
    this.closedLimit += 10;
  }
}
myChallengesManageCtrl.$inject = [
  'ChallengesManager',
  '$scope',
  'RouterService',
  '$filter',
  'MemberService',
  'SessionConfigService',
];
export default {
  template: template,
  controller: myChallengesManageCtrl
};
