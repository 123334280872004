const template = require(`html-loader!./individualLeaderboardInfo.html`).default;
import {topTabMenuData} from "../../../components/topTabsMenu/topTabMenu.data";
import leagueEnum from "../../../api/enums/league.enum";

class individualLeaderboardInfo {

  constructor($scope, $mdDialog, RestManager) {
    'ngInject';
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.RestManager = RestManager;
    this.topTabMenuData = topTabMenuData;
    this.leagueEnum = leagueEnum;

    this.busy = true;
  }

  $onInit() {
    this.getData();
  }

  async getData(){
    const res = await this.RestManager.getLeagueConfigWithRules();
    if(!res.success){
      this.close();
      return;
    }
    this.rules = res.rules;
    this.leagueGroups = {};
    res.leagues.leagues.forEach((league)=>{
      if(!this.leagueGroups[league.name.toUpperCase()]){
        this.leagueGroups[league.name.toUpperCase()] = [];
      }
      this.leagueGroups[league.name.toUpperCase()].push(league);
    });
    this.leagueGroups = [
      this.leagueGroups[this.leagueEnum.TYPE.LEGENDS.NAME],
      this.leagueGroups[this.leagueEnum.TYPE.EMERALD.NAME],
      this.leagueGroups[this.leagueEnum.TYPE.GOLD.NAME],
      this.leagueGroups[this.leagueEnum.TYPE.SILVER.NAME],
      this.leagueGroups[this.leagueEnum.TYPE.BRONZE.NAME],
    ];
    this.busy = false;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  close() {
    this.$mdDialog.hide();
  }
}
individualLeaderboardInfo.$inject = [
  '$scope', '$mdDialog', 'RestManager'
];
export default {
  template: template,
  controller: individualLeaderboardInfo,
  bindings: {
    data: '=data',
  },
};
