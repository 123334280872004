import mixpanelEventsEnum from "../mixpanel/mixpanel.enum";
import pushService from "../push.service";
import environment from "../../gsApp.environment";

export const PAY_PAL = 'payPal';
export const PAY_ME = 'payMe';
export const BLUE_SNAP = 'blueSnap';

class GSPay {
  /**
   * @param PayPalScript
   * @param $timeout
   * @param {ShopManager} ShopManager
   * @param {tagEventService} tagEventService
   * @param {brazeService} brazeService
   * @param $rootScope
   */

  constructor(
    PayPalScript,
    $timeout,
    ShopManager,
    $rootScope,
    tagEventService,
    brazeService,
    mixpanelService,
    challengeService
  ) {
    'ngInject';
    this.busy = { payPal: false, blueSnap: false };
    this.bluesnap2;
    this.paymentType = {
      NEW_CARD: 'NEW_CARD',
      EXISTING_CARD: 'EXISTING_CARD',
    };

    this.PayPalScript = PayPalScript;
    this.$timeout = $timeout;
    this.ShopManager = ShopManager;
    this.$rootScope = $rootScope;
    this.tagEventService = tagEventService;
    this.brazeService = brazeService;
    this.mixpanelService = mixpanelService;
    this.challengeService = challengeService;
  }

  // ********
  // Public
  // ********

  /**
   * Init pay provider
   * @param {string} provider - Payment service provider name
   * @param {object} data - payments info
   * @param {object} callbacks - Controller callbacks
   */
  async init(provider, data = {}, callbacks = {}) {
    if (this['init_' + provider]) {
      await this['init_' + provider](data, callbacks);
      return true;
    }
    return false;
  }

  /**
   * Call provider pay method
   * @param {string} provider - Payment service provider name
   * @param {object} data - payments info
   * @param {object} callbacks - Controller callbacks
   */
  pay(provider, data = {}, callbacks = {}) {
    if (this['pay_' + provider]) {
      this['pay_' + provider](data, callbacks);
      return true;
    }
    return false;
  }

  /**
   * Process payment
   * @param {string} provider - Payment service provider name
   * @param {object} data - payments info
   * @param {object} callbacks - Controller callbacks
   */
  process(provider, data = {}, callbacks = {}) {
    if (this['process_' + provider]) {
      this['process_' + provider](data, callbacks);
      return true;
    }
    return false;
  }

  // ********
  // payMe
  // ********

  /**
   * data = {public_key, test_mode}
   * callbacks = {payment, onError}
   * @private
   */
  init_payMe(data, callbacks) {
    if (this.payMe_inited) return true;
    this.payMe_inited = true;
    //Init payMe form
    this.payme.initialize({
      seller_payme_id: data.public_key,
      is_integration: data.test_mode, //test or production
      log_severity: false,
      buyer_is_permanent: true,
      nosecure: false,
      validate_name: false,
      validate_social_id: false,
      validate_email_address: false,
      validate_phone_number: false,
      validate_card_number: false,
      validate_card_expiration: false,
      validate_cvv: false,
      callback: buyer_key => this.process_payMe(data, callbacks, buyer_key),
      error_callback: res => {
        callbacks.showMessage(`Something went wrong. Make sure you are using a Visa or Master
                            credit card (Not debit) or use PayPal.`);
        callbacks.changeBusy(false);
      },
    });
  }

  async initBluesnap(callbacks, data, paymentType){
    if(!this.bluesnapInitialized){
      console.log('bluesnapInitialized');
      this.bluesnapInitialized = true;
      let domainPre = data.test_mode ? 'sandbox' : 'gateway';
      console.log('bluesnap domainPre', domainPre);
      await $.getScript(`https://${domainPre}.bluesnap.com/js/cse/v1.0.4/bluesnap.js`);
      await $.getScript(`https://${domainPre}.bluesnap.com/web-sdk/4.3.0/bluesnap.js`);
      this.bluesnap2 = new BlueSnap(data.public_key, data.test_mode);

      bluesnap.threeDsPaymentsSetup(data.bluesnap_3ds_token, function (sdkResponse) {
        console.log('on threeDsPaymentsSetup - ' + paymentType + ' - pay sdkResponse',sdkResponse);
        const code = sdkResponse.code;
        if (code === '1') {  // on Success
          const threeDSecure = sdkResponse.threeDSecure;
          let request;
          if(paymentType === this.paymentType.NEW_CARD) {
            //bluesnap encrypting process on new card
            try {
              this.bluesnap2.encrypt('checkout-form');
            } catch (e) {
              callbacks.changeBusy(false);
              callbacks.changeState('error');
              callbacks.showMessage('Error');
              if (!this.$rootScope.$$phase) this.$rootScope.$digest();
              return;
            }

            let formData = $('#checkout-form')
            .serializeArray()
            .reduce((obj, item) => {
                obj[item.name] = item.value;
                return obj;
              }, {});

            let expire = formData.expiration.split('/');

            request = {
              first_name: formData.first_name,
              last_name: formData.last_name,
              email: formData.email,
              encrypted_credit_card: formData.encryptedCreditCard,
              encrypted_cvv: formData.encryptedCvv,
              exp_month: expire[0],
              exp_year: expire[1],
              package_id: data.packId,
              param: data.param,
              bs_3d_secure_reference_id: threeDSecure.threeDSecureReferenceId,
            };

            this.ShopManager.payCreditCard(request)
              .then(res => {
                this.onServerPaymentResponse(callbacks, res, data);
              });
          } else {
            request = {
              package_id: data.pack.id,
              param: data.param || '',
              bs_3d_secure_reference_id: threeDSecure.threeDSecureReferenceId,
            };

            this.ShopManager.pay(request)
              .then(res => {
                this.onServerPaymentResponse(callbacks, res, data);
              });
          }
        } else {
          // on 3ds errors
          this.handleBluesnap3dsErrors(sdkResponse, callbacks);
          if (!this.$rootScope.$$phase) this.$rootScope.$digest();
        }
      }.bind(this));
    }
  }


  /** @private */
  async pay_blueSnap(data, callbacks) {
    await this.initBluesnap(callbacks, data, this.paymentType.NEW_CARD);
    callbacks.changeBusy(true);
    const newCard = {
      ccNumber: data.card.number,
      cvv: data.card.cvv,
      expDate: data.card.expiration,
      amount: data.pack.price,
      currency: 'USD'
    };
    console.log("newCard", newCard);
    bluesnap.threeDsPaymentsSubmitData(newCard);
  }

  /**
   * After get buyer_key -> Finish payment
   * */
  async process_blueSnap(data, callbacks) {
    await this.initBluesnap(callbacks, data, this.paymentType.EXISTING_CARD);
    callbacks.changeBusy(true);
    const previouslyUsedCard = {
      last4Digits: data.shopData.payment_card.last_4,
      ccType: data.shopData.payment_card.type,
      amount: data.pack.price,
      currency: 'USD'
    };
    console.log("previouslyUsedCard", previouslyUsedCard);
    bluesnap.threeDsPaymentsSubmitData(previouslyUsedCard);
  }

  onServerPaymentResponse(callbacks, res, data){
    // shop busy
    callbacks.changeBusy(false);
    // Errors
    if (!res.success) {
      switch (res.error_code) {
        case 1105: {
          data.shopData.payment_card = 0;
          callbacks.setShopData(data.shopData);
          callbacks.changeState('payment');
          callbacks.showMessage(res.message);
          break;
        }
        //On challenge error
        case 1402: {
          callbacks.changeState('error');
          callbacks.showMessage(res.message);
          break;
        }
        // Unrecoverable: show "OOPS"
        default: {
          callbacks.changeState('error');
          if (res.message) {
            callbacks.showMessage(res.message);
          }
        }
      }
      if (!this.$rootScope.$$phase) this.$rootScope.$digest();
      return;
    }

    // Success

    this.onPurchaseComplete(data.pack, 'credit_card', data.param, res);
    callbacks.changeState('thankYou');
    if (!this.$rootScope.$$phase) this.$rootScope.$digest();
  }

  handleBluesnap3dsErrors(sdkResponse, callbacks){
    // in case some errors occurred
    let message;
    if(sdkResponse.info){
      const errorsArray = sdkResponse.info.errors;
      console.log(errorsArray);
      // in case some warnings occurred
      const warningsArray = sdkResponse.info.warnings;
      console.log(warningsArray);

      if(sdkResponse.info.errors && sdkResponse.info.errors[0]){
        message = sdkResponse.info.errors[0];
      }else if(sdkResponse.info.warnings && sdkResponse.info.warnings[0]){
        message = sdkResponse.info.warnings[0];
      }
    }

    switch(sdkResponse.code){
      case '15':{
        return;
      }
      case '14101':{
        message = 'Authentication failed, please try a different payment method';
        break;
      }
      default: {
        // message = JSON.stringify(sdkResponse);
      }
    }

    callbacks.changeBusy(false);
    callbacks.changeState('error');
    if(message){
      callbacks.showMessage(message);
    }
  }

  /**
   * PayPal
   * data: {packId}
   * callbacks: {changeState, changeBusy}
   * @private
   */
  async init_payPal(data, callbacks) {
    if (this.busy.payPal) return false;
    this.busy.payPal = true;
    //load script
    await $.getScript(this.PayPalScript);
    await this.$timeout();
    paypal.Button.render(
      {
        env: ['beta','production'].includes(environment.env)  ? 'production' : 'sandbox',
        style: {
          label: 'checkout', // checkout || credit
          size: 'tiny', // tiny | small | medium
          shape: 'rect', // pill | rect
          color: 'silver', // gold | blue | silver
        },
        payment: (resolve, reject) => {
          this.eventMixPanelAction({
            uiName: mixpanelEventsEnum.UI_NAME.PAYPAL_CHECKOUT,
            packageId: data.packId,
          });
          this.ShopManager.paypalCreate(data.packId, data.c_id).then(res => {
            if (!res.success) {
              callbacks.changeState('error');
              reject(res.message);
            } else {
              resolve(res.items.payment_id);
            }
          });
        },
        onAuthorize: (payData, actions) => {
          // shop busy
          callbacks.changeBusy(true);
          //get data
          this.ShopManager.paypalExecute(data.packId, payData.paymentID, payData.payerID, data.c_id).then(res => {
            // shop busy
            callbacks.changeBusy(false);
            // errors
            if (!res.success) {
              callbacks.changeState('error');
              if (res.message) callbacks.showMessage(res.message);
              return true;
            }
            // payment success
            this.onPurchaseComplete(data.pack, 'paypal', data.c_id, res);
            callbacks.changeState('thankYou');
          });
        },
        onCancel: (data, actions) => {
          callbacks.changeBusy(false);
          console.log('onCancel', data, actions);
        },
        onError: function(err) {
          callbacks.changeBusy(false);
          console.log('onError', err);
        },
      },
      '#paypal-button'
    );
    // service busy
    this.busy.payPal = false;
  }

  onPurchaseComplete(pack, method, challenge_id, server_response){
    this.tagEventService.storeComplete(pack, server_response.first_time);
    this.brazeService.sendPurchaseEvent({
      price: pack.price,
      product_id: pack.id,
      method: method,
      challenge_id: challenge_id
    });
  }

  async eventMixPanelAction({uiName, packageId}) {
    let data = {};

    data.current_page = mixpanelEventsEnum.PAGE_NO_ROUTE.STORE;
    data.ui_name = uiName;
    data.package_id = packageId;

    data.balance_swaps = this.challengeService.getSwapsAmount();
    data.balance_keys = this.challengeService.getKeysAmount();
    data.balance_fills = this.challengeService.getFillAmount();

    this.mixpanelService.track({data});
  }
}
GSPay.$inject =
  ['PayPalScript',
    '$timeout',
    'ShopManager',
    '$rootScope',
    'tagEventService',
    'brazeService',
    'mixpanelService',
    'challengeService'
  ];

export default GSPay;
