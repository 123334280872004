const template = require(`html-loader!./teamsCountry.html`).default;

class TeamsCountry {
  constructor(

  ) {
    'ngInject';

    this.isWrapped = false;
  }
}

TeamsCountry.$inject = [

];

export default {
  template: template,
  controller: TeamsCountry,
  bindings: {
    isWrapped: '<',
    imageUrl: '<'
  }
};
