const template = require(`html-loader!./teamLeaderboard.html`).default;
import TeamModel from "../../../api/models/team/team.model";
import TeamStateModel from '../../../api/models/team/teamState.model';
import {teamItemMode} from "../../../components/teamItem/teamItem.data";
import teamEnum from '../../../api/enums/team.enum';
import leagueEnum from "../../../api/enums/league.enum";
import leaderBoardEnum from "../../../api/enums/leaderBoard.enum";
import {LeagueTeamData} from "../../../api/models/team/leagueData.model";

class teamLeaderboardCtrl {
  constructor(
    $scope,
    notificationAlert,
    RestManager,
    teamsService,
    $timeout,
    Modals,
    $rootScope,
    tagEventService,
    leaderboardService,
    RouterService
  ) {
    'ngInject';
    this.loadMore = true;
    this.busy = false;
    this.start = 0;
    this.limit = 20;
    this.teams = [];
    this.showMe = true;
    this.scrollClass = '.team-leaderboard__main';

    this.$scope = $scope;
    this.notificationAlert = notificationAlert;
    this.RestManager = RestManager;
    this.teamsService = teamsService;
    this.RouterService = RouterService;
    this.$timeout = $timeout;
    this.teamEnum = teamEnum;
    this.leagueEnum = leagueEnum;
    this.Modals = Modals;
    this.teamItemMode = teamItemMode;
    this.leaderBoardEnum = leaderBoardEnum;
    this.$rootScope = $rootScope;
    this.tagEventService = tagEventService;
    this.leaderboardService = leaderboardService;

    this.loadMore = true;
    this.busy = false;
    this.start = 0;
    this.limit = 20;
    this.teams = [];
    this.scrollClass = '.team-leaderboard__main';
    this.featureMessage = null;
  }

  async $onChanges(){
    this.type = this.RouterService.currentUrl.split("/").pop();
    if (this.isLeague()) {
      this.teams = [];
      this.tagEventService.sendPageViewEvent({title: 'Teams - my team - leader board'});
      await this.getTeamLeagueData();
    } else {
      this.start = 0;
      this.tagEventService.sendPageViewEvent({title: 'Teams - my team - global leader board'});
      await this.getGlobalData();
    }
    await this.teamsService.openTeamIntro(this.isLeague() ? teamEnum.INTRO_STATES.LEAGUES : teamEnum.INTRO_STATES.LEADERBOARD);
    this.offOnLeagueChanged = this.$rootScope.$on('onLeagueChanged', this.onLeagueChanged.bind(this));
    $(this.scrollClass).on('scroll', this.onScroll.bind(this));
  }

  registerFloatingItemHandler(handler){
    this.checkfloatingItemHandler = handler;
  }

  $onDestroy(){
    $(this.scrollClass).off('scroll', this.onScroll);
    this.offOnLeagueChanged();
  }

  onLeagueChanged() {
    if (this.isLeague()) {
      this.getTeamLeagueData();
    }
  }

  isLeague() {
    return this.leaderboardService.isTypeLeague(this.type);
  }

  isLeagueStatus(status) {
    return this.leagueData && status.includes(this.leagueData.state.status);
  }

  onScroll(e) {
    this.checkfloatingItemHandler();

    //league dont have loading
    if (this.isLeague()) {
      return;
    }

    if($(e.target).scrollTop() + $(e.target).innerHeight() >= $(e.target)[0].scrollHeight - 800) {
      if (this.teams && this.loadMore) {
        this.getGlobalData();
      }
    }
  }

  async getTeamLeagueData() {
    if (!this.busy) {
      this.busy = true;
      const res = await this.RestManager.getTeamLeagueData();

      if (!res.success) {
        return;
      }

      this.leagueData = new LeagueTeamData(res);
      this.featureMessage = this.leagueData.featureMessage;
      if(this.leagueData.league && this.leagueData.league.name){
        this.leagueConf = leagueEnum.TYPE[this.leagueData.league.name.toUpperCase()];
      }
      this.myTeam = this.leagueData.myItem;
      this.busy = false;

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  async onFeatureMessageAction() {
    await this.leaderboardService.onFeatureMessageAction(this.featureMessage);
    this.featureMessage = null;
    this.getTeamLeagueData();
  }

  async getGlobalData() {
    if(!this.busy) {
      this.busy = true;
      const res = await this.RestManager.getLeaderboard({
        start: this.start,
        limit: this.limit
      });

      if (!res.success) {
        return;
      }

      this.state = new TeamStateModel(res.state);
      this.myTeam = new TeamModel(res.data.my_team);
      this.data = res.data.teams.map((team) => {
        return new TeamModel(team);
      });

      if (this.start === 0) {
        this.teams = this.data;
      } else {
        this.teams = [...this.teams, ...this.data];
      }

      this.loadMore = this.data.length === this.limit;
      this.start += this.limit;
      this.busy = false;

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  leaderboardHelp() {
    this.notificationAlert.info({
      message: (this.isLeague() ? this.configData.leagues_help_info : this.configData.leaderboard),
      buttonText: 'Got it',
      title: this.isLeague() ? 'League' : 'Leaderboard'
    });
  }
}
teamLeaderboardCtrl.$inject = [
  '$scope',
  'notificationAlert',
  'RestManager',
  'teamsService',
  '$timeout',
  'Modals',
  '$rootScope',
  'tagEventService',
  'leaderboardService',
  'RouterService'
];
export default {
  template: template,
  controller: teamLeaderboardCtrl,
  bindings: {
    team: '<',
    configData: '<',
    type: '<',
  }
};
