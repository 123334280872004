const template = require(`html-loader!./gsUploaderSwap.html`).default;

class gsUploaderSwapComponent {

  constructor($mdDialog) {
    "ngInject";

    this.$mdDialog = $mdDialog;
  }

  select(image) {
    this.settings.currentImage = image;
    this.settings.state = 'buttons';
  }
}
gsUploaderSwapComponent.$inject = [
  '$mdDialog',
];
export default {
  template: template,
  controller: gsUploaderSwapComponent,
  bindings: {
    settings: '=',
    tools: '='
  }
};
