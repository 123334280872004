const achievementEnum = Object.freeze({
  TYPE: {
    ICON : 'icon',
    BADGE: 'badge'
  },
  MODAl : {
    TYPE: {
      INFO: 'INFO',
      ACHIEVEMENT_INFO: 'ACHIEVEMENT_INFO',
    }
  },
  CHALLENGES_TYPE: {
    IMAGE:'image',
    MEMBER:'member',
  }
});

export default achievementEnum;
