export const lbEventConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-lb-event data="modal.data"></modal-lb-event>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: false,
  fullscreen: false
};
