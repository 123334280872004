import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-challenges-challenges-calendar-wrapper',
  templateUrl: './admin-content-manager-challenges-challenges-calendar-wrapper.component.html',
  styleUrl: './admin-content-manager-challenges-challenges-calendar-wrapper.component.scss'
})
export class AdminContentManagerChallengesChallengesCalendarWrapperComponent {

}
