const template = require(`html-loader!./gsAdmin.page.html`).default;

class gsAdminCtrl {

  constructor(
              $scope,
              RouterService,
              $cookies,
              MemberService,
              NgMetaDataService,
              pageData
  ) {
    'ngInject';
    this.done = false;
    this.RouterService = RouterService;
    this.$scope = $scope;
    this.pageData = pageData;
    this.$cookies = $cookies;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
    this.init();
  }

  init() {
    this.pageData.get('gs-admin');
    this.NgMetaDataService.setTitle('Admin');
    this.member = this.MemberService.getCurrentMember();
    if (!this.$cookies.get('gs_at') || !this.member?.is_admin) {
      this.RouterService.go('challenges/my-challenges/current');
      return;
    }
    this.done = true;
  }
}
gsAdminCtrl.$inject = [
  '$scope',
  'RouterService',
  '$cookies',
  'MemberService',
  'NgMetaDataService',
  'pageData'
];

export default {
  template: template,
  controller: gsAdminCtrl
};
