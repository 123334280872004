const template = require(`html-loader!./challengesItemSuggestedMobile.html`).default;
import { challengesItemSuggestedController } from '../challengesItemSuggested';

export default {
  template: template,
  controller: challengesItemSuggestedController,
  bindings: {
    challenge: '<',
  },
};
