import {filter, Subject, takeUntil} from 'rxjs';
import {MissionsService} from "../../../modules/missions/services/missions.service";
class authService {
  firstInit = true;
  destroy$ = new Subject();
  isLoginFromHomePage = false;
  brazeInitialized = false;
  isSignUp = false;
  constructor(
    $rootScope,
    AuthenticationManager,
    $cookies,
    deepLinkService,
    tagEventService,
    socketService,
    NgTokenService,
    JoinedChallengesPageService,
    brazeService,
    MemberService,
    teamsService,
    mixpanelService,
    SessionConfigService,
    RouterService,
    MissionsService,
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.AuthenticationManager = AuthenticationManager;
    this.$cookies = $cookies;
    this.deepLinkService = deepLinkService;
    this.tagEventService = tagEventService;
    this.socketService = socketService;
    this.NgTokenService = NgTokenService;
    this.brazeService = brazeService;
    this.teamsService = teamsService;
    this.mixpanelService = mixpanelService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.SessionConfigService = SessionConfigService;
    this.MemberService = MemberService;
    this.RouterService = RouterService;
    this.MissionsService = MissionsService;
    this.MemberService.member$.pipe(
      filter(member => member !== undefined),
      takeUntil(this.destroy$)
    ).subscribe(member => {
      this.member = member;
      if (this.member.logged_in) {
        if (!this.isLoginFromHomePage) {
          this.afterSessionStarted();
        } else {
          if (this.isSignUp) {
            this.brazeService.showMessagesLater = true;
          }
          if (!this.brazeInitialized) {
            this.brazeService.init();
            this.brazeInitialized = true;
          }
        }
      }
    });
  }

  afterSessionStarted(isSignUp = false){
    if (!this.brazeInitialized) {
      if (isSignUp) {
        this.brazeService.showMessagesLater = true;
      }
      this.brazeService.init();
      this.brazeInitialized = true;
    }


    if (this.MemberService.isLoggedIn()) {
      this.brazeService.changeUser(this.member?.id);
      this.teamsService.initAutoUpdate();
      this.MissionsService.init();
    }
    if (this.firstInit) {
      this.firstInit = false;
      this.mixpanelService.init();
    }
  }

  async login() {
    this.isLoginFromHomePage = true;
    await this.afterLogin();
    const popupCompletedChallengesFlag = this.SessionConfigService.sessionConfig.popup_completed_challenges;
    const isNewCompletedChallengesRes = await this.JoinedChallengesPageService.isNewCompletedChallenges();
    let stateToRedirect = 'challenges/my-challenges/current';
    let params = {};
    if (popupCompletedChallengesFlag && isNewCompletedChallengesRes.is_new) {
      stateToRedirect =  'challenges/my-challenges/completed';
      params = {scrollToLastNewChallenge:true};
      this.brazeService.showMessagesLater = true;
    }
    this.go(stateToRedirect, params);
    this.isLoginFromHomePage = false;
  }

  async afterLogin(isSignUp = false) {
    await this.MemberService.getCurrentMemberProfile();
    await this.SessionConfigService.getConfigData();
    this.afterSessionStarted(isSignUp);
  }

  async signUp(res = {}, conversionData) {
    this.isLoginFromHomePage = true;
    this.isSignUp = true;
    // google and facebook
    this.tagEventService.signup(conversionData, res.member_id);
    await this.afterLogin(true);
    // redirect
    this.go('challenges/open');
    this.isLoginFromHomePage = false;
    this.isSignUp = false;
  }

  go(state, params = {}){
    let dl = this.deepLinkService.getDl();
    if (dl && dl.dlState) {
      this.RouterService.go(dl.dlState, dl.params, true);
    } else {
      this.RouterService.go(state, params, true);
    }
  }

  signOut() {
    this.NgTokenService.resetAllTokens();
    // this.teamsService.cancelInviteNotificationInterval();
    // this.socketService.closeSocket();
    // this.MemberService.resetMember();
    //TODO rout to home page without reload, need to check...
    window.location.href = '/';
    // this.$state.go('gs.homePage');
  }

  $onDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

authService.$inject = [
  '$rootScope',
  'AuthenticationManager',
  '$cookies',
  'deepLinkService',
  'tagEventService',
  'socketService',
  'NgTokenService',
  'JoinedChallengesPageService',
  'brazeService',
  'MemberService',
  'teamsService',
  'mixpanelService',
  'SessionConfigService',
  'RouterService',
  'MissionsService',
];

export default authService;
