const template = require(`html-loader!./gsNotifications.html`).default;

import {autoUpdateTypeEnum} from "../../services/autoUpdate.service.data";
import {gsNotificationEnum} from "./gsNotifications.data";
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";

class gsNotificationsCtrl {
  /**
   * @param {NotificationsManager} NotificationsManager
   * @param {ModalsService} Modals
   * @param {gsNotificationsService} gsNotificationsService
   * @param {autoUpdateService} autoUpdateService
   * */
  constructor(
    NotificationsManager,
    $element,
    $scope,
    $timeout,
    Modals,
    gsNotificationsService,
    autoUpdateService,
    mixpanelService
  ) {
    'ngInject';

    this.tabSelected = 0;
    this.open = false;
    this.loading = false;
    this.gsNotificationEnum = gsNotificationEnum;

    this.NotificationsManager = NotificationsManager;
    this.$element = $element;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.gsNotificationsService = gsNotificationsService;
    this.autoUpdateService = autoUpdateService;
    this.mixpanelService = mixpanelService;

    this.onDocument = this.onDocument.bind(this);
    this.onScroll = _.debounce(this.onScroll.bind(this), 250);

    this.tools = {
      close: this.close.bind(this)
    };
  }

  $onInit() {
    this.notificationData = this.gsNotificationsService.notificationData;
    let autoUpdateObject = {
      type : autoUpdateTypeEnum.NOTIFICATION,
      callback : this.loadMore.bind(this),
      callbackParams : {type:gsNotificationEnum.TYPE_ALL, force:true},
    };
    if(!this.notificationData.inited){
      autoUpdateObject.initCallbackOnStart = true;
      autoUpdateObject.initCallbackOnStartParams = {type:gsNotificationEnum.TYPE_ALL};
    }
    this.autoUpdateService.startAutoUpdateByType(autoUpdateObject);
  }

  $onDestroy() {
    $(document).off('click', this.onDocument);
    if (this.notificationsList) {
      this.notificationsList.off('mousewheel', this.onWheel);
      this.notificationsList.off('scroll', this.onScroll);
    }
  }

  /** @return void **/
  async loadMore({type, force}) {
    if (this.loading) {
      return;
    }
    this.loading = true;

    // clear data
    if (force) {
      this.gsNotificationsService.resetNotificationData();
      this.notificationData = this.gsNotificationsService.notificationData;
    }

    // for all request start && limit from vote
    let requestType = type === gsNotificationEnum.TYPE_ALL ? gsNotificationEnum.TYPE_ACTIVITY : type;
    let typeData = this.notificationData.lists[requestType];
    let start = typeData.start;
    let limit = typeData.limit;

    if (typeData.loading || !typeData.loadMore) {
      return;
    }
    typeData.loading = true;

    const res = await this.NotificationsManager.get(start, limit, type);
    if (!res.success) {
      return;
    }

    // scroll top
    if (force) {
      $(this.$element[0])
        .find('.gs-notifications__list')
        .scrollTop(0);
    }

    // update data
    this.notificationData.new = res.new;

    if (type !== gsNotificationEnum.TYPE_ALL) {
      // increase start
      this.notificationData.lists[type].start += this.notificationData.lists[type].limit;
      this.notificationData.lists[type].items.push(...res.items[type]);
      this.notificationData.lists[type].loadMore = res.items[type].length === this.notificationData.lists[type].limit;
    } else {
      Object.keys(this.notificationData.lists).map((key, index) => {
        this.notificationData.lists[key].start += this.notificationData.lists[key].limit;
        this.notificationData.lists[key].items.push(...res.items[this.notificationData.lists[key].type]);
        this.notificationData.lists[key].loadMore = res.items[key].length === this.notificationData.lists[key].limit;
      });
    }
    this.notificationData.inited = true;

    // trigger BROADCAST event
    if (res.broadcast !== null) {
      let data = res.items[gsNotificationEnum.TYPE_ACTIVITY][res.broadcast];
      this.gsNotificationsService.openModal(data);
    }

    this.$timeout(() => {
      this.notificationData.lists[requestType].loading = false;
      this.gsNotificationsService.notificationData = this.notificationData;
      this.loading = false;
    });

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  triggerMenu() {
    this.eventMixPanelAction();

    if (!this.notificationData.inited) {
      return false;
    }

    // send watch new
    if (this.notificationData.new.total > 0 && !this.open) {
      // update views
      this.notificationData.new.total = 0;
      this.NotificationsManager.watchNotifications();

      // if no Activity but new Votes
      if (this.notificationData.new.activity < 1 && this.notificationData.new.votes > 0) {
        this.tabSelected = 1;
      }
    }

    this.open = !this.open;

    // events
    if (this.open) {
      this.$timeout(() => {
        $(document).on('click', this.onDocument);
        this.notificationsList = $(this.$element[0]).find('.gs-notifications__list');
        this.notificationsList.on('mousewheel', this.onWheel);
        this.notificationsList.on('scroll', this.onScroll);
      });
    } else {
      $(document).off('click', this.onDocument);
      this.notificationsList.off('mousewheel', this.onWheel);
      this.notificationsList.off('scroll', this.onScroll);
    }
  }

  // close on document click
  onDocument(event) {
    if (!this.open || $(this.$element[0]).find(event.target).length > 0) {
      return true;
    }

    this.open = false;
    $(document).off('click', this.onDocument);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
    return true;
  }

  /**
   * Prevent document scroll
   * */
  onWheel(event, delta) {
    let element = event.currentTarget;
    if (
      (element.scrollTop >= element.scrollHeight - element.offsetHeight && delta < 0) ||
      (element.scrollTop === 0 && delta > 0)
    ) {
      event.preventDefault();
      return false;
    }
  }

  onScroll(event) {
    let element = event.currentTarget;
    if (Math.floor( element.scrollTop ) === Math.floor(element.scrollHeight - element.offsetHeight)) {
      this.loadMore({type:$(element).data('type')});
    }
  }

  close() {
    this.open = false;
  }

  eventMixPanelAction() {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.TOP_NAV,
          ui_name: mixpanelEventsEnum.UI_NAME.NOTIFICATIONS_BELL,
          ui_notification_counter: this.notificationData.new.total
        }
      }
    );
  }
}

gsNotificationsCtrl.$inject = [
  'NotificationsManager',
  '$element',
  '$scope',
  '$timeout',
  'Modals',
  'gsNotificationsService',
  'autoUpdateService',
  'mixpanelService'
];

export default {
  template: template,
  controller: gsNotificationsCtrl
};
