const template = require(`html-loader!./myTeam.html`).default;
import teamEnum from '../../../api/enums/team.enum';
import TeamChestModel from '../../../api/models/team/teamChest.model';

class myTeamCtrl {

  constructor(
    $scope,
    $rootScope,
    notificationAlert,
    $location,
    Toasts,
    $element,
    $filter,
    stateService,
    Modals,
    RestManager,
    socketService,
    teamsService,
    deepLinkService,
    tagEventService,
    RouterService,
  ) {
    'ngInject';
    this.settingsOpen = false;
    this.busy = true;

    this.$scope = $scope;
    this.notificationAlert = notificationAlert;
    this.$location = $location;
    this.Toasts = Toasts;
    this.$element = $element;
    this.stateService = stateService;
    this.Modals = Modals;
    this.RestManager = RestManager;
    this.socketService = socketService;
    this.teamsService = teamsService;
    this.$rootScope = $rootScope;
    this.deepLinkService = deepLinkService;
    this.tagEventService = tagEventService;
    this.RouterService = RouterService;
  }

  $onInit() {
    this.init();
  }

  /** @return void **/
  async init(){
    this.setShareLink();
    this.setDetailItems();
    this.offTeamMemberChestReload = this.$rootScope.$on('teamMemberChestReload', this.teamMemberChestReload.bind(this));
    await this.getTeamMemberChest();
    this.busy = false;
    this.deepLinkService.check({selectorParent:'.my-team__content', selector:'team-suggested-members'});
    this.tagEventService.sendPageViewEvent({title: 'Teams - my team - home'});
  }

  teamMemberChestReload(){
    this.getTeamMemberChest();
  }

  $onDestroy(){
    this.offTeamMemberChestReload();
  }

  async getTeamMemberChest(){
    const res = await this.RestManager.getMemberTeamChest();
    if(!res.success){
      return;
    }
    this.teamChest = new TeamChestModel(res);
    if (!this.$scope.$$phase) {this.$scope.$digest()}
  }

  setShareLink(){
    const port = this.$location.$$host === 'localhost' ? `:${this.$location.$$port}` : '';
    this.shareLink = `https://${this.$location.$$host}${port}/teams/info/${this.team.id}`;
  }

  myTeamHelp(){
    this.notificationAlert.info({
      message:  this.configData.my_team,
      buttonText: 'Got it',
      title: 'My Team'
    });
  }

  copyDone() {
    this.Toasts.open('copy', {}, $(this.$element[0]).find('.my-team__header__invite-btn')[0]);
  }

  editTeam(){
    this.stateService.goToState('teams/edit', {teamId: this.team.id});
  }

  async leaveTeam() {
    if (await this.teamsService.leaveTeam(this.team) ) {
      this.RouterService.reloadRoute('teams');
    }
  }

  setDetailItems() {
    this.teamDetailTypes = [
      teamEnum.DETAILS.TYPES.SCORE,
      teamEnum.DETAILS.TYPES.WIN,
      teamEnum.DETAILS.TYPES.MEMBERS,
      teamEnum.DETAILS.TYPES.STATUS,
      teamEnum.DETAILS.TYPES.LANGUAGE,
      teamEnum.DETAILS.TYPES.COUNTRY
    ];
  }

  isTeamSuggestedInviteEnabled(){
    return this.teamsService.isTeamSuggestedInviteEnabled(this.team);
  }

}
myTeamCtrl.$inject = [
  '$scope',
  '$rootScope',
  'notificationAlert',
  '$location',
  'Toasts',
  '$element',
  '$filter',
  'stateService',
  'Modals',
  'RestManager',
  'socketService',
  'teamsService',
  'deepLinkService',
  'tagEventService',
  'RouterService',
];
export default {
  template: template,
  controller: myTeamCtrl,
  bindings: {
    team: '<',
    configData: '<'
  }
};
