import { Component } from '@angular/core';

@Component({
  selector: 'app-lp-mobile-page-wrapper',
  templateUrl: './lp-mobile-page-wrapper.component.html',
  styleUrl: './lp-mobile-page-wrapper.component.scss'
})
export class LpMobilePageWrapperComponent {

}
