import { Pipe, PipeTransform } from '@angular/core';
import {NgDateHelper} from "../../../core/helpers/ngDateHelper";
import {GsTimerEnum} from "./gs-timer.enum";

@Pipe({name: 'gsTimer'})
export class GsTimerPipe implements PipeTransform {
  transform(deltaTimestamp: number | null | undefined, type: string): string {
    let result;

    if(!deltaTimestamp){
      return '';
    }

    if (deltaTimestamp > 0) {
      const timeComponents = this.convertDeltaTime(deltaTimestamp);

      switch(type) {
        case GsTimerEnum.VOTE_INFO:
          result = `${timeComponents.seconds}S`;
          break;
        case GsTimerEnum.RANKING:
          result = `${NgDateHelper.addLeadZero(timeComponents.hours)}:${NgDateHelper.addLeadZero(timeComponents.minutes)}:
          ${NgDateHelper.addLeadZero(timeComponents.seconds)}`;
          break;
        case GsTimerEnum.CHALLENGE_TURBO_BUTTON:
          result = this.getFormattedUnitsByOrder(timeComponents, 1)
          break;
        case GsTimerEnum.MISSION_CARD_TIMER:
          result = this.getFormattedUnitsByOrder(timeComponents, 2);
          break;
        default:
          result = `The default timer type in not defined`;
          break;
      }
    } else {
      result = `Ended`;
    }

    return result;
  }

  getFormattedUnitsByOrder(timeComponents:any, numOfComponentsToShow:number){
    const timeUnits = [
      { value: timeComponents.weeks, label: 'W' },
      { value: timeComponents.days, label: 'D' },
      { value: timeComponents.hours, label: 'H' },
      { value: timeComponents.minutes, label: 'M' },
      { value: timeComponents.seconds, label: 'S' }
    ];

    // Filter out the units with value 0
    const nonZeroUnits = timeUnits.filter(unit => unit.value > 0);

    // Take the first two non-zero units
    const formattedUnits = nonZeroUnits.slice(0, numOfComponentsToShow).map(unit => {
      // Format the value to always have two digits (e.g., 01, 05, etc.)
      return `${unit.value.toString().padStart(2, '0')}${unit.label}`;
    });

    // Join the two formatted units with ":"
    return   formattedUnits.join(':');
  }

  convertDeltaTime(ms: number) {
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    const days = Math.floor((ms / (1000 * 60 * 60 * 24)) % 7);
    const weeks = Math.floor(ms / (1000 * 60 * 60 * 24 * 7));

    return {
      weeks,
      days,
      hours,
      minutes,
      seconds
    };
  }
}
