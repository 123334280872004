import ImageModel from './image.model';

class RankingResponseModel {
  constructor({ success = false, error_code, items = [] }) {
    this.success = success;
    if (error_code) {
      this.error_code = error_code;
    }
    this.items = items.map(image => new ImageModel(image));
  }
}

export default RankingResponseModel;
