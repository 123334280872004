
class myChallengesEditorBase {

  constructor({$scope, $element, Toasts}) {
    this.$scope = $scope;
    this.Toasts = Toasts;
    this.$element = $element;
  }

  afterSave(res) {
    if (!res.success){
      this.Toasts.message('Error!', this.$element);
    }else{
      this.Toasts.message('Saved!', this.$element, false);
    }

    if (!this.$scope.$$phase) this.$scope.$digest();
  }
}
myChallengesEditorBase.$inject = [
  '$scope', '$element', 'Toasts'
];
export default myChallengesEditorBase
