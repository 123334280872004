class toastCtrl {
  /** @param {RestManager} RestManager **/

  constructor($mdToast, $scope, popup, RestManager) {
    "ngInject";
    this.$mdToast = $mdToast;
    this.RestManager = RestManager;
    this.$scope = $scope;
    this.popup = popup;
  }

  friendsInvite() {
    this.$mdToast.hide();
    let facebook_message = {
      main: '//www.facebook.com/dialog/send?',
      params: {
        app_id: '668625103154083',
        display: 'popup',
        link: `${window.location.href}`,
        redirect_uri: `${window.location.origin}/viral/redirect`
      }
    };
    let link = facebook_message.main + $.param(facebook_message.params);
    this.popup.open(link);
    this.disablePopup();
  }

  friendsClose() {
    this.$mdToast.hide();
    this.disablePopup();
  }

  disablePopup() {
    this.RestManager.setMemberSettings('show_ranking_friends_toast', 0);
    this.callback();
  }

  close() {
    this.$mdToast.hide();
  }
}
toastCtrl.$inject = [
  '$mdToast', '$scope', 'popup', 'RestManager'
];
export default toastCtrl;
