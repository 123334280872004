import GsResponse from "./GsResponse";
import {AchievementModel} from "../achievement/achievement.model";
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

class GetMemberAchievementsChallengesHistoryResponse extends GsResponse{
  constructor({items, success}) {
    super(success);
    this.items = items.map((item)=>{
      item.challenge = new NgChallengeModel(item.challenge);
      if(item.achievement){
        item.achievement = new AchievementModel(item.achievement);
      }
      return item;
    });
  }
}


export default GetMemberAchievementsChallengesHistoryResponse;
