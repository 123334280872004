const template = require(`html-loader!./brazeContentCardsBanner.html`).default;


class BrazeContentCardsBanner {
  constructor(
    brazeService
  ) {
    'ngInject';

    this.brazeService = brazeService;
    this.brazeData = null;
  }

  $onInit() {
    this.contentCardsResponse = this.brazeService.filteredContentCardData ? this.brazeService.filteredContentCardData : this.brazeService.getFilteredContentCard({filterConfig: this.brazeConfig});
  }
}

BrazeContentCardsBanner.$inject = [
  'brazeService'
];

export default {
  template: template,
  controller: BrazeContentCardsBanner,
  bindings: {
    brazeConfig: '<'
  }
};
