import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ExhibitionsWrapperComponent} from "./components/exhibitions-wrapper/exhibitions-wrapper.component";
import {HomePageWrapperComponent} from "./components/home-page-wrapper/home-page-wrapper.component";
import {TermsPageWrapperComponent} from "./components/terms-page-wrapper/terms-page-wrapper.component";
import {PartnersWrapperComponent} from "./components/partners-wrapper/partners-wrapper.component";
import {
  ChallengesClosedWrapperComponent
} from "./components/challenges-closed-wrapper/challenges-closed-wrapper.component";
import {ChallengesPageWrapperComponent} from "./components/challenges-page-wrapper/challenges-page-wrapper.component";
import {DiscoverPhotosWrapperComponent} from "./components/discover-photos-wrapper/discover-photos-wrapper.component";
import {DiscoverWrapperComponent} from "./components/discover-wrapper/discover-wrapper.component";
import {DiscoverSearchComponent} from "./components/discover-search/discover-search.component";
import {ArticlesWrapperComponent} from "./components/articles-wrapper/articles-wrapper.component";
import {ArticleWrapperComponent} from "./components/article-wrapper/article-wrapper.component";
import {ArticleEditWrapperComponent} from "./components/article-edit-wrapper/article-edit-wrapper.component";
import {AboutPageWrapperComponent} from "./components/about-page-wrapper/about-page-wrapper.component";
import {ContactWrapperComponent} from "./components/contact-wrapper/contact-wrapper.component";
import {DmcaWrapperComponent} from "./components/dmca-wrapper/dmca-wrapper.component";
import {PrivacyWrapperComponent} from "./components/privacy-wrapper/privacy-wrapper.component";
import {
  ChallengesJoinedWrapperComponent
} from "./components/challenges-joined-wrapper/challenges-joined-wrapper.component";
import {ChallengesOpenWrapperComponent} from "./components/challenges-open-wrapper/challenges-open-wrapper.component";
import {
  ChallengesCompletedWrapperComponent
} from "./components/challenges-completed-wrapper/challenges-completed-wrapper.component";
import {
  ChallengesUpcomingWrapperComponent
} from "./components/challenges-upcoming-wrapper/challenges-upcoming-wrapper.component";
import {
  ChallengesEditorWrapperComponent
} from "./components/challenges-editor-wrapper/challenges-editor-wrapper.component";
import {
  ChallengesManageWrapperComponent
} from "./components/challenges-manage-wrapper/challenges-manage-wrapper.component";
import {ChallengeRankWrapperComponent} from "./components/challenge-rank-wrapper/challenge-rank-wrapper.component";
import {
  ChallengeRankTopPhotographerWrapperComponent
} from "./components/challenge-rank-top-photographer-wrapper/challenge-rank-top-photographer-wrapper.component";
import {
  ChallengeRankTopPhotoWrapperComponent
} from "./components/challenge-rank-top-photo-wrapper/challenge-rank-top-photo-wrapper.component";
import {
  ChallengeRankGuruTopPickWrapperComponent
} from "./components/challenge-rank-guru-top-pick-wrapper/challenge-rank-guru-top-pick-wrapper.component";
import {ChallengeRulesWrapperComponent} from "./components/challenge-rules-wrapper/challenge-rules-wrapper.component";
import {
  ChallengeDetailsWrapperComponent
} from "./components/challenge-details-wrapper/challenge-details-wrapper.component";
import {
  ChallengePrizesWrapperComponent
} from "./components/challenge-prizes-wrapper/challenge-prizes-wrapper.component";
import {
  ChallengeWinnersWrapperComponent
} from "./components/challenge-winners-wrapper/challenge-winners-wrapper.component";
import {ChallengeWrapperComponent} from "./components/challenge-wrapper/challenge-wrapper.component";
import {
  ChallengeWinnersAllWrapperComponent
} from "./components/challenge-winners-all-wrapper/challenge-winners-all-wrapper.component";
import {
  ChallengeWinnersExhibitPrintWrapperComponent
} from "./components/challenge-winners-exhibit-print-wrapper/challenge-winners-exhibit-print-wrapper.component";
import {
  ChallengeWinnersExhibitDigitalWrapperComponent
} from "./components/challenge-winners-exhibit-digital-wrapper/challenge-winners-exhibit-digital-wrapper.component";
import {ProfileWrapperComponent} from "./components/profile-wrapper/profile-wrapper.component";
import {ProfilePhotosWrapperComponent} from "./components/profile-photos-wrapper/profile-photos-wrapper.component";
import {ProfileLikesWrapperComponent} from "./components/profile-likes-wrapper/profile-likes-wrapper.component";
import {
  ProfileAchievementsWrapperComponent
} from "./components/profile-achievements-wrapper/profile-achievements-wrapper.component";
import {
  ProfileAchievementsAchievementsWrapperComponent
} from "./components/profile-achievements-achievements-wrapper/profile-achievements-achievements-wrapper.component";
import {
  ProfileAchievementsChallengesWrapperComponent
} from "./components/profile-achievements-challenges-wrapper/profile-achievements-challenges-wrapper.component";
import {
  ProfileFollowersWrapperComponent
} from "./components/profile-followers-wrapper/profile-followers-wrapper.component";
import {
  ProfileFollowingWrapperComponent
} from "./components/profile-following-wrapper/profile-following-wrapper.component";
import {TeamsPageWrapperComponent} from "./components/teams-page-wrapper/teams-page-wrapper.component";
import {TeamsLockWrapperComponent} from "./components/teams-lock-wrapper/teams-lock-wrapper.component";
import {TeamsJoinWrapperComponent} from "./components/teams-join-wrapper/teams-join-wrapper.component";
import {TeamsHomeWrapperComponent} from "./components/teams-home-wrapper/teams-home-wrapper.component";
import {
  TeamsHomeMyTeamWrapperComponent
} from "./components/teams-home-myteam-wrapper/teams-home-myteam-wrapper.component";
import {
  TeamsHomeLeaderboardWrapperComponent
} from "./components/teams-home-leaderboard-wrapper/teams-home-leaderboard-wrapper.component";
import {TeamsHomeMatchWrapperComponent} from "./components/teams-home-match-wrapper/teams-home-match-wrapper.component";
import {TeamsCreateWrapperComponent} from "./components/teams-create-wrapper/teams-create-wrapper.component";
import {TeamsEditWrapperComponent} from "./components/teams-edit-wrapper/teams-edit-wrapper.component";
import {TeamsInfoWrapperComponent} from "./components/teams-info-wrapper/teams-info-wrapper.component";
import {LeaguesWrapperComponent} from "./components/leagues-wrapper/leagues-wrapper.component";
import {AdminWrapperComponent} from "./components/admin-wrapper/admin-wrapper.component";
import {
  AdminMemberManagerWrapperComponent
} from "./components/admin-member-manager-wrapper/admin-member-manager-wrapper.component";
import {
  AdminTeamsManagerWrapperComponent
} from "./components/admin-teams-manager-wrapper/admin-teams-manager-wrapper.component";
import {
  AdminContentManagerChallengesWrapperComponent
} from "./components/admin-content-manager-challenges-wrapper/admin-content-manager-challenges-wrapper.component";
import {
  AdminContentManagerWrapperComponent
} from "./components/admin-content-manager-wrapper/admin-content-manager-wrapper.component";
import {
  AdminContentManagerChallengesScheduleWrapperComponent
} from "./components/admin-content-manager-challenges-schedule-wrapper/admin-content-manager-challenges-schedule-wrapper.component";
import {
  AdminContentManagerChallengesTemplatesWrapperComponent
} from "./components/admin-content-manager-challenges-templates-wrapper/admin-content-manager-challenges-templates-wrapper.component";
import {
  AdminContentManagerChallengesBulkUploadWrapperComponent
} from "./components/admin-content-manager-challenges-bulk-upload-wrapper/admin-content-manager-challenges-bulk-upload-wrapper.component";
import {
  AdminContentManagerChallengesEditChallengeTemplateWrapperComponent
} from "./components/admin-content-manager-challenges-edit-challenge-tempalate-wrapper/admin-content-manager-challenges-edit-challenge-template-wrapper.component";
import {
  AdminContentManagerChallengesNewChallengeTemplateWrapperComponent
} from "./components/admin-content-manager-challenges-new-challenge-tempalate-wrapper/admin-content-manager-challenges-new-challenge-template-wrapper.component";
import {
  AdminContentManagerChallengesChallengesWinnersWrapperComponent
} from "./components/admin-content-manager-challenges-challenges-winners-wrapper/admin-content-manager-challenges-challenges-winners-wrapper.component";
import {
  AdminContentManagerChallengesChallengesCalendarWrapperComponent
} from "./components/admin-content-manager-challenges-challenges-calendar-wrapper/admin-content-manager-challenges-challenges-calendar-wrapper.component";
import {
  AdminContentManagerChallengesChallengesAutoSubmitWrapperComponent
} from "./components/admin-content-manager-challenges-challenges-auto-submit-wrapper/admin-content-manager-challenges-challenges-auto-submit-wrapper.component";
import {
  AdminContentManagerChallengesNotificationTimesWrapperComponent
} from "./components/admin-content-manager-challenges-notification-times-wrapper/admin-content-manager-challenges-notification-times-wrapper.component";
import {
  AdminContentManagerChallengesChallengesRankingLevelsWrapperComponent
} from "./components/admin-content-manager-challenges-challenges-ranking-levels-wrapper/admin-content-manager-challenges-challenges-ranking-levels-wrapper.component";
import {
  AdminContentManagerChallengesChallengesSponsorsWrapperComponent
} from "./components/admin-content-manager-challenges-challenges-sponsors-wrapper/admin-content-manager-challenges-challenges-sponsors-wrapper.component";
import {
  AdminContentManagerChallengesChallengesSponsorsNewWrapperComponent
} from "./components/admin-content-manager-challenges-challenges-sponsors-new-wrapper/admin-content-manager-challenges-challenges-sponsors-new-wrapper.component";
import {
  AdminContentManagerChallengesChallengesSponsorsEditWrapperComponent
} from "./components/admin-content-manager-challenges-challenges-sponsors-edit-wrapper/admin-content-manager-challenges-challenges-sponsors-edit-wrapper.component";
import {
  AdminContentManagerChallengesChallengesPlannedTimingsWrapperComponent
} from "./components/admin-content-manager-challenges-challenges-planned-timings-wrapper/admin-content-manager-challenges-challenges-planned-timings-wrapper.component";
import {
  AdminContentManagerExhibitionWrapperComponent
} from "./components/admin-content-manager-exhibition-wrapper/admin-content-manager-exhibition-wrapper.component";
import {
  AdminContentManagerExhibitionsManagerWrapperComponent
} from "./components/admin-content-manager-exhibitions-manager-wrapper/admin-content-manager-exhibitions-manager-wrapper.component";
import {
  AdminContentManagerExhibitionsSelectedWrapperComponent
} from "./components/admin-content-manager-exhibitions-selected-wrapper/admin-content-manager-exhibitions-selected-wrapper.component";
import {
  AdminContentManagerExhibitionsPageWrapperComponent
} from "./components/admin-content-manager-exhibitions-page-wrapper/admin-content-manager-exhibitions-page-wrapper.component";
import {
  AdminContentManagerExhibitionsVirtualWrapperComponent
} from "./components/admin-content-manager-exhibitions-virtual-wrapper/admin-content-manager-exhibitions-virtual-wrapper.component";
import {
  AdminContentManagerExhibitionsVirtualEditWrapperComponent
} from "./components/admin-content-manager-exhibitions-virtual-edit-wrapper/admin-content-manager-exhibitions-virtual-edit-wrapper.component";
import {
  AdminContentManagerExhibitionsVirtualNewWrapperComponent
} from "./components/admin-content-manager-exhibitions-virtual-new-wrapper/admin-content-manager-exhibitions-virtual-new-wrapper.component";
import {
  AdminContentManagerExhibitionsEditWrapperComponent
} from "./components/admin-content-manager-exhibitions-edit-wrapper/admin-content-manager-exhibitions-edit-wrapper.component";
import {
  AdminContentManagerSpotlightWrapperComponent
} from "./components/admin-content-manager-spotlight-wrapper/admin-content-manager-spotlight-wrapper.component";
import {
  AdminContentManagerSpotlightEditWrapperComponent
} from "./components/admin-content-manager-spotlight-edit-wrapper/admin-content-manager-spotlight-edit-wrapper.component";
import {
  AdminContentManagerPopupsWrapperComponent
} from "./components/admin-content-manager-popups-wrapper/admin-content-manager-popups-wrapper.component";
import {
  AdminContentManagerExhibitionsWrapperComponent
} from "./components/admin-content-manager-exhibitions-wrapper/admin-content-manager-exhibitions-wrapper.component";
import {
  AdminContentManagerWidgetWrapperComponent
} from "./components/admin-content-manager-widjet-wrapper/admin-content-manager-widget-wrapper.component";
import {
  AdminContentManagerDailyRewardWrapperComponent
} from "./components/admin-content-manager-daily-reward-wrapper/admin-content-manager-daily-reward-wrapper.component";
import {
  ng1AdminContentManagerDailyRewardsComponent
} from "./upgradedComponents/adminContentManagerDailyRewards.directive";
import {
  AdminContentManagerDailyRewardsMissionsWrapperComponent
} from "./components/admin-content-manager-daily-rewards-missions-wrapper/admin-content-manager-daily-rewards-missions-wrapper.component";
import {
  AdminContentManagerDailyRewardsEditDailyMissionWrapperComponent
} from "./components/admin-content-manager-daily-rewards-edit-daily-mission-wrapper/admin-content-manager-daily-rewards-edit-daily-mission-wrapper.component";
import {
  AdminContentManagerDailyRewardsPrizesWrapperComponent
} from "./components/admin-content-manager-daily-rewards-prizes-wrapper/admin-content-manager-daily-rewards-prizes-wrapper.component";
import {
  AdminContentManagerDailyRewardsEditDailyPrizeWrapperComponent
} from "./components/admin-content-manager-daily-rewards-edit-daily-prize-wrapper/admin-content-manager-daily-rewards-edit-daily-prize-wrapper.component";
import {
  AdminContentRewardedVideoWrapperComponent
} from "./components/admin-content-rewarded-video-wrapper/admin-content-rewarded-video-wrapper.component";
import {
  AdminContentLiveopsWrapperComponent
} from "./components/admin-content-liveops-wrapper/admin-content-liveops-wrapper.component";
import {
  AdminContentManagerLiveopsSuggestWrapperComponent
} from "./components/admin-content-manager-liveops-suggest-wrapper/admin-content-manager-liveops-suggest-wrapper.component";
import {
  AdminContentManagerEventsEditLbEventWrapperComponent
} from "./components/admin-content-manager-events-edit-lb-event-wrapper/admin-content-manager-events-edit-lb-event-wrapper.component";
import {
  AdminContentManagerEventsLeaderBoardWrapperComponent
} from "./components/admin-content-manager-events-leader-board-wrapper/admin-content-manager-events-leader-board-wrapper.component";
import {
  AdminContentManagerEventsNewLbEventWrapperComponent
} from "./components/admin-content-manager-events-new-lb-event-wrapper/admin-content-manager-events-new-lb-event-wrapper.component";
import {
  AdminContentManagerEventsWrapperComponent
} from "./components/admin-content-manager-events-wrapper/admin-content-manager-events-wrapper.component";
import {
  AdminContentManagerChestsWrapperComponent
} from "./components/admin-content-manager-chests-wrapper/admin-content-manager-chests-wrapper.component";
import {
  AdminContentManagerTeamsWrapperComponent
} from "./components/admin-content-manager-teams-wrapper/admin-content-manager-teams-wrapper.component";
import {
  AdminContentManagerTeamsLeaguesWrapperComponent
} from "./components/admin-content-manager-teams-leagues-wrapper/admin-content-manager-teams-leagues-wrapper.component";
import {
  AdminContentManagerTeamsSeasonsWrapperComponent
} from "./components/admin-content-manager-teams-seasons-wrapper/admin-content-manager-teams-seasons-wrapper.component";
import {
  AdminContentManagerTeamsLeaguesActiveWrapperComponent
} from "./components/admin-content-manager-teams-leagues-active-wrapper/admin-content-manager-teams-leagues-active-wrapper.component";
import {
  AdminContentManagerTeamsLeaguesNextWrapperComponent
} from "./components/admin-content-manager-teams-leagues-next-wrapper/admin-content-manager-teams-leagues-next-wrapper.component";
import {DlPageWrapperComponent} from "./components/dl-page-wrapper/dl-page-wrapper.component";
import {DlVoteWrapperComponent} from "./components/dl-vote-wrapper/dl-vote-wrapper.component";
import {
  DlProfileUploadWrapperComponent
} from "./components/dl-profile-upload-wrapper/dl-profile-upload-wrapper.component";
import {DlExhibitionWrapperComponent} from "./components/dl-exhibition-wrapper/dl-exhibition-wrapper.component";
import {DlFillWrapperComponent} from "./components/dl-fill-wrapper/dl-fill-wrapper.component";
import {DlSwapWrapperComponent} from "./components/dl-swap-wrapper/dl-swap-wrapper.component";
import {DlBoostWrapperComponent} from "./components/dl-boost-wrapper/dl-boost-wrapper.component";
import {DlLbWrapperComponent} from "./components/dl-lb-wrapper/dl-lb-wrapper.component";
import {
  DlSuggestedMembersWrapperComponent
} from "./components/dl-suggedted-members-wrapper/dl-suggested-members-wrapper.component";
import {EmptyWrapperComponent} from "./components/empty-wrapper/empty-wrapper.component";
import {PhotoPageWrapperComponent} from "./components/photo-page-wrapper/photo-page-wrapper.component";
import {BoardingPageWrapperComponent} from "./components/boarding-page-wrapper/boarding-page-wrapper.component";
import {VotePageWrapperComponent} from "./components/vote-page-wrapper/vote-page-wrapper.component";
import {SponsorsPageWrapperComponent} from "./components/sponsors-page-wrapper/sponsors-page-wrapper.component";
import {
  SponsorsPaymentsWrapperComponent
} from "./components/sponsors-payments-wrapper/sponsors-payments-wrapper.component";
import {
  SponsorsMaterialsWrapperComponent
} from "./components/sponsors-materials-wrapper/sponsors-materials-wrapper.component";
import {ModerationPageWrapperComponent} from "./components/moderation-page-wrapper/moderation-page-wrapper.component";
import {
  ModerationCopyrightWrapperComponent
} from "./components/moderation-copyright-wrapper/moderation-copyright-wrapper.component";
import {
  ModerationOfftopicWrapperComponent
} from "./components/moderation-offtopic-wrapper/moderation-offtopic-wrapper.component";
import {
  ModerationAdultWrapperComponent
} from "./components/moderation-adult-wrapper/moderation-adult-wrapper.component";
import {
  ModerationOfftopicImagesWrapperComponent
} from "./components/moderation-offtopic-images-wrapper/moderation-offtopic-images-wrapper.component";
import {MobileMenuWrapperComponent} from "./components/mobile-menu-wrapper/mobile-menu-wrapper.component";
import {
  VirtualExhibitionsWrapperComponent
} from "./components/virtual-exhibitions-wrapper/virtual-exhibitions-wrapper.component";
import {
  ProfileSettingsWrapperComponent
} from "./components/profile-settings-wrapper/profile-settings-wrapper.component";
import {
  ChallengeAchievementsWrapperComponent
} from "./components/challenge-achievements-wrapper/challenge-achievements-wrapper.component";
import {ActivityWrapperComponent} from "./components/activity-wrapper/activity-wrapper.component";
import {DeactivatedWrapperComponent} from "./components/deactivated-wrapper/deactivated-wrapper.component";
import {
  MobileMemberSearchWrapperComponent
} from "./components/mobile-member-search-wrapper/mobile-member-search-wrapper.component";
import {
  MobileNotificationsWrapperComponent
} from "./components/mobile-notifications-wrapper/mobile-notifications-wrapper.component";
import {ErrorPageWrapperComponent} from "./components/error-page-wrapper/error-page-wrapper.component";
import {MaintenanceComponent} from "./components/maintenance/maintenance.component";
import {ModerationSpamWrapperComponent} from "./components/moderation-spam-wrapper/moderation-spam-wrapper.component";
import {MissionsWrapperComponent} from "./modules/missions/components/missions-wrapper/missions-wrapper.component";

import {
  ExhibitionWidgetWrapperComponent
} from "./components/exhibition-widget-wrapper/exhibition-widget-wrapper.component";

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HomePageWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
      },
      {
        path: 'exhibitions',
        component: ExhibitionsWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'virtual_exhibitions',
        component: VirtualExhibitionsWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'partners',
        component: PartnersWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'terms',
        component: TermsPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'challenges',
        component: ChallengesPageWrapperComponent,
        children: [
          {
            path: 'my-challenges',
            children: [
              {
                path: 'current',
                data: { displayHeader: true, displayFooter: true },
                component: ChallengesJoinedWrapperComponent,
                children: [
                  {
                    path: 'ads',
                    data: {displayHeader: true, displayFooter: true},
                    component: EmptyWrapperComponent
                  }
                ]
              },
              {
                path: 'completed',
                data: { displayHeader: true, displayFooter: true },
                component: ChallengesCompletedWrapperComponent,
              },
              {
                path: 'editor/:cUrl',
                data: { displayHeader: true, displayFooter: true },
                component: ChallengesEditorWrapperComponent,
              },
            ]
          },
          {
            path: 'open',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengesOpenWrapperComponent,
          },
          {
            path: 'upcoming',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengesUpcomingWrapperComponent,
          },
          {
            path: 'manage',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengesManageWrapperComponent,
          },
          {
            path: 'closed',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengesClosedWrapperComponent,
          },
        ]
      },
      {
        path: 'challenge/:cUrl',
        component: ChallengeWrapperComponent,
        children: [
          {
            path: 'winners',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengeWinnersWrapperComponent,
            children: [
              {
                path: 'all',
                data: { displayHeader: true, displayFooter: true },
                component: ChallengeWinnersAllWrapperComponent
              },
              {
                path: 'exhibitPrint',
                data: { displayHeader: true, displayFooter: true },
                component: ChallengeWinnersExhibitPrintWrapperComponent
              },
              {
                path: 'exhibitDigital',
                data: { displayHeader: true, displayFooter: true },
                component: ChallengeWinnersExhibitDigitalWrapperComponent
              }
            ]
          },
          {
            path: 'details',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengeDetailsWrapperComponent,
          },
          {
            path: 'prizes',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengePrizesWrapperComponent,
          },
          {
            path: 'rules',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengeRulesWrapperComponent,
          },
          {
            path: 'rank',
            data: { displayHeader: true, displayFooter: true },
            component: ChallengeRankWrapperComponent,
            children: [
              {
                path: 'top-photographer',
                data: { displayHeader: true, displayFooter: false },
                component: ChallengeRankTopPhotographerWrapperComponent,
              },
              {
                path: 'top-photo',
                data: { displayHeader: true, displayFooter: false },
                component: ChallengeRankTopPhotoWrapperComponent,
              },
              {
                path: 'gurus-top-pick',
                data: { displayHeader: true, displayFooter: true },
                component: ChallengeRankGuruTopPickWrapperComponent,
              }
            ]
          }
        ]
      },
      {
        path: 'missions',
        component: MissionsWrapperComponent,
        data: { displayHeader: true, displayFooter: false }
      },
      {
        path: 'discover',
        component: DiscoverWrapperComponent,
        children: [
          {
            path: 'winners',
            data: { displayHeader: true, displayFooter: true, type: 'winners' },
            component: DiscoverPhotosWrapperComponent,
          },
          {
            path: 'trending',
            data: { displayHeader: true, displayFooter: true, type: 'trending' },
            component: DiscoverPhotosWrapperComponent,
          },
          {
            path: 'guru-picks',
            data: { displayHeader: true, displayFooter: true, type: 'guru_picks' },
            component: DiscoverPhotosWrapperComponent,
          },
          {
            path: 'search',
            data: { displayHeader: true, displayFooter: true, search: null },
            component: DiscoverSearchComponent,
          },
        ]
      },
      {
        path: 'articles',
        component: ArticlesWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'article/:url',
        component: ArticleWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'article-edit',
        component: ArticleEditWrapperComponent,
        pathMatch: 'full',
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'about',
        component: AboutPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'contact',
        component: ContactWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'privacy',
        component: PrivacyWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'dmca',
        component: DmcaWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'teams',
        component: TeamsPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
        children: [
          {
            path: 'lock',
            component: TeamsLockWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'join',
            component: TeamsJoinWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'home',
            component: TeamsHomeWrapperComponent,
            data: { displayHeader: true, displayFooter: true },
            children: [
              {
                path: 'my-team',
                component: TeamsHomeMyTeamWrapperComponent,
                data: { displayHeader: true, displayFooter: true }
              },
              {
                path: 'leaderboard/:type',
                component: TeamsHomeLeaderboardWrapperComponent,
                data: { displayHeader: true, displayFooter: true }
              },
              {
                path: 'match',
                component: TeamsHomeMatchWrapperComponent,
                data: { displayHeader: true, displayFooter: true }
              }
            ]
          },
          {
            path: 'create',
            component: TeamsCreateWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'edit',
            component: TeamsEditWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'info/:teamId',
            component: TeamsInfoWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          }
        ]
      },
      {
        path: 'leagues',
        component: LeaguesWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
      },
      {
        path: 'admin',
        component: AdminWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
        children: [
          {
            path: 'memberManager',
            component: AdminMemberManagerWrapperComponent,
            data: { displayHeader: true, displayFooter: false }
          },
          {
            path: 'teamsManager/:teamId',
            component: AdminTeamsManagerWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'teamsManager',
            component: AdminTeamsManagerWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'contentManager',
            component: AdminContentManagerWrapperComponent,
            data: { displayHeader: true, displayFooter: true },
            children: [
              {
                path: 'challenges',
                component: AdminContentManagerChallengesWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
                children: [
                  {
                    path: 'challengesSchedule/:status',
                    component: AdminContentManagerChallengesScheduleWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'challengeTemplates',
                    component: AdminContentManagerChallengesTemplatesWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'bulkUpload',
                    component: AdminContentManagerChallengesBulkUploadWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'editChallengeTemplate',
                    component: AdminContentManagerChallengesEditChallengeTemplateWrapperComponent,
                    data: { displayHeader: true, displayFooter: false }
                  },
                  {
                    path: 'newChallengeTemplate',
                    component: AdminContentManagerChallengesNewChallengeTemplateWrapperComponent,
                    data: { displayHeader: true, displayFooter: false }
                  },
                  {
                    path: 'challengesWinners',
                    component: AdminContentManagerChallengesChallengesWinnersWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'challengesCalendar',
                    component: AdminContentManagerChallengesChallengesCalendarWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'challengesAutoSubmit',
                    component: AdminContentManagerChallengesChallengesAutoSubmitWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'notificationTimes',
                    component: AdminContentManagerChallengesNotificationTimesWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'challengesRankingLevels',
                    component: AdminContentManagerChallengesChallengesRankingLevelsWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'challengesSponsors',
                    component: AdminContentManagerChallengesChallengesSponsorsWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  },
                  {
                    path: 'challengesSponsors/new',
                    component: AdminContentManagerChallengesChallengesSponsorsNewWrapperComponent,
                    data: { displayHeader: true, displayFooter: false }
                  },
                  {
                    path: 'challengesSponsors/edit',
                    component: AdminContentManagerChallengesChallengesSponsorsEditWrapperComponent,
                    data: { displayHeader: true, displayFooter: false }
                  },
                  {
                    path: 'challengesPlannedTimings',
                    component: AdminContentManagerChallengesChallengesPlannedTimingsWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  }
                ]
              },
              {
                path: 'exhibitions',
                component: AdminContentManagerExhibitionWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
                children: [
                  {
                    path: 'manager',
                    component: AdminContentManagerExhibitionsManagerWrapperComponent,
                    data: { displayHeader: true, displayFooter: false }
                  },
                  {
                    path: 'selected',
                    component: AdminContentManagerExhibitionsSelectedWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'page',
                    component: AdminContentManagerExhibitionsPageWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'virtual',
                    component: AdminContentManagerExhibitionsVirtualWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'virtualEdit',
                    component: AdminContentManagerExhibitionsVirtualEditWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'virtualNew',
                    component: AdminContentManagerExhibitionsVirtualNewWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  },
                  {
                    path: 'edit',
                    component: AdminContentManagerExhibitionsEditWrapperComponent,
                    data: { displayHeader: true, displayFooter: true }
                  }
                ]
              },
              {
                path: 'spotlight',
                component: AdminContentManagerSpotlightWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
              },
              {
                path: 'spotlightEdit',
                component: AdminContentManagerSpotlightEditWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
              },
              {
                path: 'popups',
                component: AdminContentManagerPopupsWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
              },
              {
                path: 'exhibition',
                component: AdminContentManagerExhibitionsWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
              },
              {
                path: 'widget',
                component: AdminContentManagerWidgetWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
              },
              {
                path: 'dailyRewards',
                component: AdminContentManagerDailyRewardWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
                children: [
                  {
                    path: 'missions',
                    component: AdminContentManagerDailyRewardsMissionsWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  },
                  {
                    path: 'editDailyMission',
                    component: AdminContentManagerDailyRewardsEditDailyMissionWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  },
                  {
                    path: 'prizes',
                    component: AdminContentManagerDailyRewardsPrizesWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  },
                  {
                    path: 'editDailyPrize',
                    component: AdminContentManagerDailyRewardsEditDailyPrizeWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  }
                ]
              },
              {
                path: 'rewardedVideo',
                component: AdminContentRewardedVideoWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
              },
              {
                path: 'liveops',
                component: AdminContentLiveopsWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
                children: [
                  {
                    path: 'suggest',
                    component: AdminContentManagerLiveopsSuggestWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  }
                ]
              },
              {
                path: 'events',
                component: AdminContentManagerEventsWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
                children: [
                  {
                    path: 'leaderBoard',
                    component: AdminContentManagerEventsLeaderBoardWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  },
                  {
                    path: 'editLbEvent',
                    component: AdminContentManagerEventsEditLbEventWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  },
                  {
                    path: 'newLbEvent',
                    component: AdminContentManagerEventsNewLbEventWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  }
                ]
              },
              {
                path: 'chests',
                component: AdminContentManagerChestsWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
              },
              {
                path: 'teams',
                component: AdminContentManagerTeamsWrapperComponent,
                data: { displayHeader: true, displayFooter: true },
                children: [
                  {
                    path: 'leagues',
                    component: AdminContentManagerTeamsLeaguesWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                    children: [
                      {
                        path: 'active',
                        component: AdminContentManagerTeamsLeaguesActiveWrapperComponent,
                        data: { displayHeader: true, displayFooter: true }
                      },
                      {
                        path: 'next',
                        component: AdminContentManagerTeamsLeaguesNextWrapperComponent,
                        data: { displayHeader: true, displayFooter: true }
                      },
                    ]
                  },
                  {
                    path: 'seasons',
                    component: AdminContentManagerTeamsSeasonsWrapperComponent,
                    data: { displayHeader: true, displayFooter: true },
                  }
                ]
              },
            ]
          }
        ]
      },
      {
        path: 'dl',
        component: DlPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
        children: [
          {
            path: 'vote/:cUrl',
            component: DlVoteWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'profileUpload',
            component: DlProfileUploadWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'exhibition/:cId',
            component: DlExhibitionWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'fill/:cId',
            component: DlFillWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'swap/:cId',
            component: DlSwapWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'boost/:cId',
            component: DlBoostWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'lb',
            component: DlLbWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'suggested-members',
            component: DlSuggestedMembersWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          }
        ]
      },
      {
        path: 'photo/:id',
        component: PhotoPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'boarding/:slide/:id',
        component: BoardingPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'vote/:challengeUrl',
        component: VotePageWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'sponsors',
        component: SponsorsPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
        children: [
          {
            path: 'materials',
            component: SponsorsMaterialsWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'payments',
            component: SponsorsPaymentsWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
        ]
      },
      {
        path: 'moderation',
        component: ModerationPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
        children: [
          {
            path: 'copyright',
            component: ModerationCopyrightWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'offtopic',
            component: ModerationOfftopicWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'spam',
            component: ModerationSpamWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'adult',
            component: ModerationAdultWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'offtopicImages',
            component: ModerationOfftopicImagesWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          }
        ]
      },
      {
        path: 'error/page404',
        component: ErrorPageWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
      },
      {
        path: 'error/maintenance',
        component: MaintenanceComponent,
        data: { displayHeader: true, displayFooter: true },
      },
      // {
      //   path: 'lp1',
      //   component: DlPageWrapperComponent,
      //   data: { displayHeader: true, displayFooter: true }
      // },
      // {
      //   path: 'lp2',
      //   component: DlPageWrapperComponent,
      //   data: { displayHeader: true, displayFooter: true }
      // },
      // {
      //   path: 'lp3',
      //   component: DlPageWrapperComponent,
      //   data: { displayHeader: true, displayFooter: true }
      // },
      // {
      //   path: 'lp4',
      //   component: DlPageWrapperComponent,
      //   data: { displayHeader: true, displayFooter: true }
      // },
      // {
      //   path: 'lp5',
      //   component: DlPageWrapperComponent,
      //   data: { displayHeader: true, displayFooter: true }
      // },
      // {
      //   path: 'lp6',
      //   component: DlPageWrapperComponent,
      //   data: { displayHeader: true, displayFooter: true }
      // },
      // {
      //   path: 'lp_mobile',
      //   component: DlPageWrapperComponent,
      //   data: { displayHeader: true, displayFooter: true }
      // },
      // {
      //   path: 'photo_contest',
      //   component: DlPageWrapperComponent,
      //   data: { displayHeader: true, displayFooter: true }
      // },
      {
        path: 'mobile_menu',
        component: MobileMenuWrapperComponent,
        data: { displayHeader: false, displayFooter: false }
      },
      {
        path: 'mobile_notifications',
        component: MobileNotificationsWrapperComponent,
        data: { displayHeader: false, displayFooter: false }
      },
      {
        path: 'profile_settings',
        component: ProfileSettingsWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'activity',
        component: ActivityWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
      {
        path: 'deactivated',
        component: DeactivatedWrapperComponent,
        data: { displayHeader: false, displayFooter: false }
      },
      {
        path: 'mobile-members-search',
        component: MobileMemberSearchWrapperComponent,
        data: { displayHeader: false, displayFooter: false }
      },
      {
        path: 'achievements/:cUrl/:userName',
        component: ChallengeAchievementsWrapperComponent,
        data: { displayHeader: true, displayFooter: false }
      },
      {
        path: ':cUserName',
        component: ProfileWrapperComponent,
        data: { displayHeader: true, displayFooter: true },
        children: [
          {
            path: 'photos',
            component: ProfilePhotosWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'likes',
            component: ProfileLikesWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'achievements',
            component: ProfileAchievementsWrapperComponent,
            data: { displayHeader: true, displayFooter: true },
            children: [
              {
                path: 'achievements',
                component: ProfileAchievementsAchievementsWrapperComponent,
                data: { displayHeader: true, displayFooter: true }
              },
              {
                path: 'challenges',
                component: ProfileAchievementsChallengesWrapperComponent,
                data: { displayHeader: true, displayFooter: true }
              }
            ]
          },
          {
            path: 'followers',
            component: ProfileFollowersWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          },
          {
            path: 'following',
            component: ProfileFollowingWrapperComponent,
            data: { displayHeader: true, displayFooter: true }
          }
        ]
      },
      {
        path: 'exhibition_widget/:id',
        component: ExhibitionWidgetWrapperComponent,
        data: { displayHeader: true, displayFooter: true }
      },
    ]
  }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  declarations: [
    ng1AdminContentManagerDailyRewardsComponent
  ],
  exports: [RouterModule, ng1AdminContentManagerDailyRewardsComponent]
})
export class AppRoutingModule { }
