import {filter, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./articleEdit.html`).default;
import ArticleModel from '../../../api/models/articles/article.model';
import ArticleItemModel from '../../../api/models/articles/articleItem.model';

class articleEditCtrl {

  constructor(
    $mdSidenav,
    $rootScope,
    $scope,
    $timeout,
    $window,
    ArticlesManager,
    ChallengesManager,
    Modals,
    notificationAlert,
    PhotosManager,
    MemberService,
    RouterService
  ) {
    'ngInject';
    this.mode = 'edit'; // edit or new
    this.challengesList = [];
    this.imagesCounter = 1;
    this.challengeId = null;
    this.editMode = false;
    this.inited = false;
    this.subject$ = new Subject();

    this.$mdSidenav = $mdSidenav;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$window = $window;
    this.ArticlesManager = ArticlesManager;
    this.ChallengesManager = ChallengesManager;
    this.Modals = Modals;
    this.notificationAlert = notificationAlert;
    this.PhotosManager = PhotosManager;
    this.MemberService = MemberService;
    this.RouterService = RouterService;

    this.deleteItem = this.deleteItem.bind(this);
    this.RouterService.activatedRoute
      .queryParams.pipe(
      filter(params => params.url || params.article),
      takeUntil(this.subject$)
    ).subscribe(params => {
      this.load(params.url, params?.article ? JSON.parse(params.article) : null);
    });
  }

  $onInit() {
    this.member = this.MemberService.getCurrentMember();
    this.loggedIn = this.member?.logged_in;
    this.isAdmin = !!this.member?.is_admin;

    this.$window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    $(this.$window).on('pb_change', this.onPhotoBoxChange);
    if (!this.isAdmin) {
      this.RouterService.go('articles');
    }
  }

  load(url, article) {
    if (this.inited) {
      return;
    }

    // challenges list
    this.ChallengesManager.getChallengesList().then(res => {
      this.challengesList = res.success ? res.challenges : [];
    });

    // if new article
    if (url === 'new') {
      this.mode = 'create';
      this.article = new ArticleModel({});
      this.inited = true;
      // text editor
      this.activateTextEditor();
      return;
    }

    // get data from articles
    if (article !== null) {
      this.article = article;
      this.inited = true;
      // text editor
      this.activateTextEditor();
      return;
    }
    let params = {
      url: url
    };

    this.ArticlesManager.getArticles(params)
      .then(res => {
        if (!res.success) {
          this.RouterService.go('articles');
          return;
        }
        this.article = res.articles[0];
        // init page
        this.inited = true;
        // text editor
        this.activateTextEditor();
      })
      .catch(() => {
        this.RouterService.go('articles');
      });
  }

  getMemberPhotos() {
    this.Modals.open('gsUploader', event, {
      target: 'getImages',
      items_limit: 100,
      member: { id: this.memberId || this.member?.id },
      submit: 'ADD',
      callback: images => {
        images = images.map(
          image =>
            new ArticleItemModel({
              type: 'image',
              image: image,
              content: image.id,
            })
        );
        this.article.items.push(...images);
      },
    });
  }

  getChallengePhotos() {
    if (!this.challengeId || !this.imagesCounter) {
      return true;
    }

    this.ChallengesManager.getChallengePhotos(this.challengeId, this.imagesCounter).then(res => {
      if (!res.success) {
        return true;
      }
      const items = res.images.map(
        image =>
          new ArticleItemModel({
            type: 'image',
            image: image,
            content: image.id,
          })
      );
      this.article.items.push(...items);
    });
  }

  activateTextEditor() {
    this.$timeout(() => {
      new MediumEditor('.article-edit__editable', {
        elementsContainer: document.querySelector('article-edit'),
        spellcheck: false,
        targetBlank: true,
        imageDragging: false,
        toolbar: {
          buttons: ['bold', 'italic', 'underline', 'h2', 'h3', 'h4', 'anchor'],
          diffTop: -72,
        },
        paste: {
          cleanPastedHTML: true,
          cleanAttrs: ['style', 'dir'],
          cleanTags: ['label', 'meta'],
        },
      });
    });
  }

  openItemEditor(item) {
    // stop body scroll
    $('body').css({
      width: document.body.clientWidth,
      overflow: 'hidden',
    });
    if (item) {
      this.currentItem = item;
    } else {
      let newItem = new ArticleItemModel({});
      this.article.items.push(newItem);
      this.currentItem = newItem;
    }
    // Open editor after render
    this.$timeout(() => {
      this.$mdSidenav('newItem').open();
    });
  }

  delArticle() {
    this.ArticlesManager.delArticle(this.article.id).then(res => {
      if (!res.success) {
        return;
      }
      //Clear cache
      window.localStorage.clear();
      //Redirect to articles
      this.RouterService.go('articles');
    });
  }

  deleteItem(item) {
    this.article.items.splice(this.article.items.indexOf(item), 1);
  }

  swap(index, forward = true) {
    // Get article id
    const id = this.article.items[index].id;
    // Forward or back swap
    index = forward ? index : index - 1;
    // Swap items
    this.article.items.splice(index, 2, this.article.items[index + 1], this.article.items[index]);
    // Scroll to item
    if (id) {
      this.$timeout(() => {
        $(window).scrollTo('#' + id, 1000, -200);
      });
    }
  }

  onPhotoBoxChange(e, imageId) {
    if (imageId) {
      $(window).scrollTo('#' + imageId, 100);
    }
  }

  // Autocompete items function
  querySearch(query) {
    return query ? this.challengesList.filter(this.createFilterFor(query)) : this.challengesList;
  }

  // Filter for autocompete
  createFilterFor(query) {
    let lowercaseQuery = query.toLowerCase();
    return function filterFn(challenge) {
      return challenge.title.toLowerCase().indexOf(lowercaseQuery) !== -1;
    };
  }

  changeCover(event, image) {
    if (image) {
      this.article.cover_image = image;
      return;
    }

    this.Modals.open('gsUploader', event, {
      target: 'getImages',
      items_limit: 1,
      member: { id: this.member?.id },
      callback: images => (this.article.cover_image = images[0]),
    });
  }

  publish() {
    this.article.published = !this.article.published;
    this.save();
  }

  async save() {
    if (!this.article.cover_image || !this.article.cover_image.id) {
      this.notificationAlert.open('Please select cover image!');
      return false;
    }

    if (!this.article.title || this.article.title.length < 1) {
      this.notificationAlert.open('Title empty!');
      return false;
    }

    if (!this.article.text || this.article.text.length < 1) {
      this.notificationAlert.open('Text empty!');
      return false;
    }

    if (!this.article.items || this.article.items.length < 1) {
      this.notificationAlert.open('No items!');
      return false;
    }

    // Prepare for save
    const data = angular.copy(this.article);
    // Cover
    data.cover_image_id = data.cover_image.id;
    delete data.cover_image;
    delete data.date;
    delete data.views;
    // Items
    data.items.forEach((item, index) => {
      // Set order
      item.order = index;
      delete item.image;
      delete item.article;
    });

    // Save article
    const res = await this.ArticlesManager.saveArticle(data);
    if (!res.success) {
      this.notificationAlert.open('Error! Not saved!');
      if (!this.$scope.$$phase) this.$scope.$digest();
      return;
    }
    window.localStorage.clear();
    if (!this.article.url && res.url) {
      this.RouterService.go('article-edit', { url: res.url });
      return true;
    }

    this.notificationAlert.open('Saved!');
    this.RouterService.go('articles');
  }

  changeIndex(index, newIndex) {
    newIndex = +newIndex;
    if (isNaN(newIndex) || index === newIndex || newIndex < 0) {
      return;
    }
    if (newIndex > this.article.items.length) {
      newIndex = this.article.items.length;
    }
    this.article.items.splice(newIndex, 0, this.article.items.splice(index, 1)[0]);
  }

  sendEmail() {
    if (!this.article.id) {
      this.notificationAlert.open('Save article before!');
      return false;
    }
    this.ArticlesManager.notifyMembers(this.article.id).then(res => {
      if (!res.success) {
        this.notificationAlert.open('Emails not sent!');
        return false;
      }
      this.notificationAlert.open('Emails sent!');
    });
  }

  $onDestroy() {
    this.subject$.next();
    this.subject$.complete();
  }
}
articleEditCtrl.$inject = [
  '$mdSidenav',
  '$rootScope',
  '$scope',
  '$timeout',
  '$window',
  'ArticlesManager',
  'ChallengesManager',
  'Modals',
  'notificationAlert',
  'PhotosManager',
  'MemberService',
  'RouterService'
];
export default {
  template: template,
  controller: articleEditCtrl,
  bindings: {},
};
