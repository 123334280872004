import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-challenges-challenges-auto-submit-wrapper',
  templateUrl: './admin-content-manager-challenges-challenges-auto-submit-wrapper.component.html',
  styleUrl: './admin-content-manager-challenges-challenges-auto-submit-wrapper.component.scss'
})
export class AdminContentManagerChallengesChallengesAutoSubmitWrapperComponent {

}
