import {resolveObjectPath} from "./object.helper";

function isFieldValid(field) {
  return field || field === 0;
}

function isNumber(value) {
  return (value instanceof Number || typeof value == 'number');
}

function isNumber2(value) {
  return (value instanceof Number || typeof value == 'number');
}

function isFieldValidNew(obj , {field, title, isNumber, minNumber = 0, maxNumber, isRequired = true}) {
  let fieldValue =  resolveObjectPath(field, obj);
  if(!isRequired && (fieldValue === undefined || fieldValue === null)){
    return { valid : true };
  }
  if(!obj  || fieldValue === undefined || fieldValue === null || fieldValue === ''){
    return {
      message: `${title || field} is required!`,
      valid : false
    };
  }

  if(isNumber){
    if(!isNumber2(fieldValue)){
      return {
        message: `${title || field} is not a number`,
        valid : false
      };
    }

    minNumber = minNumber || 0;
    if( fieldValue < minNumber ){
      return {
        message: `${title || field} need to be minimum ${minNumber}`,
        valid : false
      };
    }

    if(maxNumber && fieldValue > maxNumber){
      return {
        message: `${title || field} need to be maximum ${maxNumber}`,
        valid : false
      };
    }
  }

  return { valid : true };
}

export { isFieldValid, isNumber , isFieldValidNew};
