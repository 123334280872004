const template = require(`html-loader!./followBtn.html`).default;

class followBtnCtrl {
  /** @param {PhotosManager} PhotosManager **/
  constructor(
    $element,
    $rootScope,
    Modals,
    PhotosManager,
    MemberService,
  ) {
    'ngInject';

    this.following = false;

    this.$element = $element;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.PhotosManager = PhotosManager;
    this.MemberService = MemberService;
    this.loggedInMember = this.MemberService.getCurrentMember();
  }

  $onChanges() {
    if (!this.member) return;

    if (this.MemberService.isMyMember(this.member.id)) {
      this.$element.remove();
      return;
    }

    this.$element.removeClass('following');
    this.following = this.member.following || this.member.is_following;
    if (this.following) this.$element.addClass('following');
    console.log('$onChanges',this.member.logged_in);
    // console.log('followBtn:following =', this.following);
  }

  async triggerFollow(event) {
    if (!this.member) return;
    if (!this.loggedInMember.logged_in) {
      this.Modals.open('login', event, { signUp: true });
      return;
    }

    let res;
    const params = ['challenges', true, [this.member.id]];
    if (this.following) {
      res = await this.PhotosManager.unfollow(...params);
    } else {
      res = await this.PhotosManager.follow(...params);
    }
    if (!res.success) {
      if (res.error_code === 1000) {
        this.Modals.open('login', event, { signUp: true });
      }
      return;
    }

    this.$element.toggleClass('following');
    this.following = !this.following;

    if (!this.$rootScope.$$phase) this.$rootScope.$digest();
  }
}

followBtnCtrl.$inject = [
  '$element',
  '$rootScope',
  'Modals',
  'PhotosManager',
  'MemberService',
];

export default {
  template: template,
  controller: followBtnCtrl,
  bindings: {
    member: '<',
  },
};
