const template = require(`html-loader!./teamsMembers.html`).default;

const defaulMemberAmount = 0;

class TeamsMembers {
  constructor(

  ) {
    'ngInject';
  }

  $onInit() {
    this.members = this.membersAmount ? this.membersAmount : defaulMemberAmount;
  }
}

TeamsMembers.$inject = [

];

export default {
  template: template,
  controller: TeamsMembers,
  bindings: {
    membersAmount: '<'
  }
};
