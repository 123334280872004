<ng-content></ng-content>

<div class="global-bankroll-item-wrapper gs-c-p" (click)="openStore(ResourceType.BUNDLES)">
  <div
    *ngFor="let item of items()"
    class="global-bankroll-item-combined"
  >
    <img [src]="item.icon.svg_url" [alt]="item.type">

    <div
      class="gs-theme-text-headline-sub-bold gs-theme-color-gray_90"
      [style.margin]="resourceStyleMapSignal().get(item.type)"
    >{{item.amount | number}}</div>
  </div>
  <div class="global-bankroll-item_plus gs-center-flex">
    <i class="icon-plus"></i>
  </div>
</div>

<div
  class="global-bankroll-item gs-c-p"
  (click)="openStore(ResourceType.COINS)"
>
  <img [src]="coinItem()?.icon?.svg_url" [alt]="coinItem()?.type">

  <div
    class="gs-theme-text-headline-sub-bold gs-theme-color-gray_90"
    [style.margin]="resourceStyleMapSignal().get(coinItem()?.type || ResourceType.COINS)"
  >{{coinItem()?.amount | number}}</div>

  <div class="global-bankroll-item_plus gs-center-flex">
    <i class="icon-plus"></i>
  </div>
</div>
