import { Component } from '@angular/core';

@Component({
  selector: 'app-error-page404-wrapper',
  templateUrl: './error-page404-wrapper.component.html',
  styleUrl: './error-page404-wrapper.component.scss'
})
export class ErrorPage404WrapperComponent {

}
