import memberEnum from "../../../api/enums/member.enum";

const template = require(`html-loader!./challengeUnlock.html`).default;

import {ResourceType} from "../../../../../core/models/enums";
import {ChallengeUnlockUsage} from "../../../../../modules/challenges/enums/challenges.enum";
import mixpanelEventsEnum from "../../mixpanel/mixpanel.enum";

class challengeUnlockCtrl {
  ResourceType = ResourceType;
  constructor($mdDialog,
              RestManager,
              $timeout,
              Modals,
              $scope,
              challengeService,
              NgChallengesService,
  ) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.$scope = $scope;
    this.challengeService = challengeService;
    this.NgChallengesService = NgChallengesService;
  }

  $onInit() {
    this.challenge = this.data.challenge;
    this.NgChallengesService.sendJoinChallengeMixpanelEvent({
        challenge : this.challenge,
        ui_name:mixpanelEventsEnum.UI_NAME.JOIN_CONFIRMATION,
        ui_action:mixpanelEventsEnum.UI_ACTION.APPEAR
      }
    );
  }

  async unlock(event) {
    if (this.unlocking) return false;
    this.NgChallengesService.sendJoinChallengeMixpanelEvent({
        challenge : this.challenge,
        ui_name:mixpanelEventsEnum.UI_NAME.JOIN_CONFIRMATION,
        ui_action:mixpanelEventsEnum.UI_ACTION.CLICK,
        ui_type : this.challengeService.isKeysEmpty() ? 'out_currency' : undefined
      }
    );
    this.unlocking = true;
    let btn = $(event.currentTarget);
    btn.addClass('run');

    const res = await this.challengeService.keyUnlock(this.challenge, ChallengeUnlockUsage.JOIN_CHALLENGE);
    if (!res.success) {
      this.close();
      return;
    }

    this.data.challenge.member.submit_state = memberEnum.SUBMIT_STATES.JOIN;
    // if (this.data.requestedName) {
    //   this.Modals.open(this.data.requestedName, event, this.data);
    //   return;
    // }

    this.close(true);
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  close(res = false) {
    this.$mdDialog.hide(res);
  }
}
challengeUnlockCtrl.$inject = [
  '$mdDialog',
  'RestManager',
  '$timeout',
  'Modals',
  '$scope',
  'challengeService',
  'NgChallengesService'
];
export default {
  template: template,
  controller: challengeUnlockCtrl,
  bindings: {
    data: '=data',
  },
};
