import mediaEnum from '../../api/enums/media.enum';
const template = require(`html-loader!./media.html`).default;

class mediaController {
  constructor(

  ) {
    'ngInject';

    this.isYoutube = true;
    this.isImage = false;
    this.isSelected = -1;
    this.selectedMediaItem = '';

    this.mediaEnum = mediaEnum;
  }

  $onInit() {
    this.isThumbnails = (this.media.length > 1);
    this.view(this.media[0], 0); /* by default init first media item */
  }

  view(mediaItem, index) {
    this.selectedMediaItem = mediaItem;
    this.isSelected = index;
    console.log('mediaItem === selectedMediaItem ', mediaItem === this.selectedMediaItem);
    this.isYoutube = this.selectedMediaItem['type'] === mediaEnum.TYPE.YOUTUBE;
    this.isImage = this.selectedMediaItem['type'] === mediaEnum.TYPE.IMAGE;
  }
}

mediaController.$inject = [

];

export default {
  template: template,
  controller: mediaController,
  bindings: {
    "media": '<',
  }
};
