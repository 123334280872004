const chatEnum = Object.freeze({
  CHAT_MESSAGE: {
    TYPE: {
      DEFAULT: 'default',
      SYSTEM: 'system',
      MATCH: 'match',
      LEAGUE: 'leagues',
    }
  },
  MESSAGE: {
    TYPE: {
      DEEP_LINK: 'deep_link',
      MATCH: 'match',
      CHALLENGE: 'challenge',
      TEAMS: 'teams',
    },
    SUB_TYPE: {
      USER_PROFILE: 'user_profile'
    }
  },
  SYSTEM_EVENT: {
    LEFT: 'left',
    JOINED: 'joined',
    REMOVED: 'removed',
    PROMOTE_ADMIN: 'promote_admin',
    MATCH_CLOSED: 'match_closed',
    MATCH_SELECTED: 'match_selected',
    MATCH_STARTED: 'match_started',
    MATCH_TIMEOUT: 'match_timeout',
    MATCH_CANCELLED: 'match_cancelled',
  }
});

export default chatEnum;
