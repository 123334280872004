/* globals angular */
import {ChallengeUnlockUsage} from "../../../../../modules/challenges/enums/challenges.enum";

const template = require(`html-loader!./boostUnlock.html`).default;

class boostUnlockCtrl {
  /**
   * @param {RestManager} RestManager
   * @param {challengeService} challengeService
   * */

  constructor($mdDialog, RestManager, $timeout, Modals, $scope, challengeService) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.$scope = $scope;
    this.challengeService = challengeService;
  }

  async unlock(event) {
    if (this.unlocking) return false;
    this.unlocking = true;

    let btn = $(event.currentTarget);
    btn.addClass('run');

    const res = await this.challengeService.keyUnlock(this.data.challenge, ChallengeUnlockUsage.EXPOSURE_BOOST);
    if (!res.success) {
      this.close();
      return;
    }

    //Start animation
    this.unlocked = true;
    this.$timeout(() => {
      this.Modals.open('challengeActionActivate', event, { challenge: this.data.challenge });
    }, 2500);

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  close() {
    this.$mdDialog.hide();
  }
}
boostUnlockCtrl.$inject = [
  '$mdDialog', 'RestManager', '$timeout', 'Modals', '$scope', 'challengeService'
];
export default {
  template: template,
  controller: boostUnlockCtrl,
  bindings: {
    data: '=data',
  },
};
