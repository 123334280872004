import ImageModel from '../image/image.model';

class SponsorModel {
  constructor({
    brand,
    challenge_id,
    description,
    id,
    image_id,
    member_id,
    name,
    order,
    url,
    video_embed
  }) {
    this.image = new ImageModel({
      id: image_id,
      member_id: member_id,
    });
    Object.assign(this, {
      brand,
      challenge_id,
      description,
      id,
      image_id,
      member_id,
      name,
      order,
      url,
      video_embed
    });
  }
}

export default SponsorModel;
