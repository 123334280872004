const template = require(`html-loader!./lbEvent.html`).default;
import ImageModel from '../../../api/models/image/image.model';

class lbEventCtrl {
  /**
   * @param {RestManager} RestManager
   * **/

  constructor($mdDialog, $rootScope, RestManager, NotificationsManager) {
    'ngInject';
    this.busy = true;

    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.NotificationsManager = NotificationsManager;
  }

  $onInit() {
    this.getData();
  }

  async getData(){
    if (!this.data.event) {
      const res = await this.RestManager.getLbEvent();
      if (res.success && res.event) {
        this.event = res.event;
      }
    } else {
      this.event = this.data.event;
    }
    // this.event.behavior.show_joined_dialog = true;
    if (!this.event || !this.event.event || (!this.event.behavior.show_closed_dialog && !this.event.behavior.show_joined_dialog)){
      await this.setRead();
      this.close();
      if (!this.$rootScope.$$phase) this.$rootScope.$digest();
      return;
    }
    this.busy = false;
    this.show_closed_dialog = this.event.behavior.show_closed_dialog;
    this.show_joined_dialog = this.event.behavior.show_joined_dialog;
    if (!this.$rootScope.$$phase) this.$rootScope.$digest();
  }

  close() {
    this.$mdDialog.hide();
  }

  openLb(){
    this.setRead();
    this.close();
    this.$rootScope.$emit('openLb');
  }

  async setRead(){
    if (this.data.date) {
      await this.NotificationsManager.readNotifications(this.data.date, 'activity');
    }
  }
}
lbEventCtrl.$inject = [
  '$mdDialog', '$rootScope', 'RestManager', 'NotificationsManager'
];
export default {
  template: template,
  controller: lbEventCtrl,
  bindings: {
    data: '=data',
  },
};
