import { Component } from '@angular/core';

@Component({
  selector: 'app-leagues-wrapper',
  templateUrl: './leagues-wrapper.component.html',
  styleUrl: './leagues-wrapper.component.scss'
})
export class LeaguesWrapperComponent {

}
