const template = require(`html-loader!./badgeV2DynamicChallenge.html`).default;

class badgeV2DynamicChallengeController {
  constructor(

  ) {
    'ngInject';
  }
}

badgeV2DynamicChallengeController.$inject = [

];

export default {
  template: template,
  controller: badgeV2DynamicChallengeController,
  bindings: {
    maxPhotos: '<'
  }
};
