class RestrictedMemberService {
  constructor(
    Modals,
    notificationAlert,
    RestManager,
    $rootScope
  ) {
    'ngInject';

    this.modals = Modals;
    this.notificationAlert = notificationAlert;
    this.RestManager = RestManager;
    this.$rootScope = $rootScope;

    this.modalText = {
      confirm: {
        title: 'Block User',
        text: 'By blocking this user they will not be able to follow you and like or comment on your photos',
        buttons: {
          ok: 'Block User',
          cancel: 'Cancel'
        },
      },
      notification: {
        userBlocked: `<i class="icon-check-alt"></i> You have blocked this user`
      }
    };
  }

  async blockUser(member_id) {
    const confirm = await this.modals.confirm(
      this.modalText.confirm.title,
      this.modalText.confirm.text,
      this.modalText.confirm.buttons.ok,
      this.modalText.confirm.buttons.cancel
    );

    if (confirm) {
      const blockMember = await this.RestManager.blockMember({member_id});

      if (blockMember.success) {
        await this.notificationAlert.open(this.modalText.notification.userBlocked, false);
        this.$rootScope.$emit('userBlockingSuccess');
      }
    }
  }
}
RestrictedMemberService.$inject = [
  'Modals',
  'notificationAlert',
  'RestManager',
  '$rootScope'
];
export default RestrictedMemberService;
