function config() {
  'ngInject';

  //Routes
  // $stateProvider
  //   .state('gs.dl', {
  //     abstract: true
  //   })
  //   .state('gs.dl.vote', {
  //     url: '/dl/vote/:cUrl',
  //     params: {
  //       cUrl: null
  //     },
  //     views: {
  //       'page@gs': {
  //         template: dlTemplate,
  //         controller: 'dlCtrl'
  //       },
  //     }
  //   })
  //   .state('gs.dl.profileUpload', {
  //     url: '/dl/profileUpload',
  //     views: {
  //       'page@gs': {
  //         template: dlTemplate,
  //         controller: 'dlCtrl'
  //       },
  //     }
  //   })
  //   .state('gs.dl.exhibition', {
  //     url: '/dl/exhibition/:cId',
  //     params: {
  //       cId: null
  //     },
  //     views: {
  //       'page@gs': {
  //         template: dlTemplate,
  //         controller: 'dlCtrl'
  //       },
  //     }
  //   })
  //   .state('gs.dl.fill', {
  //     url: '/dl/fill/:cId',
  //     params: {
  //       cId: null
  //     },
  //     views: {
  //       'page@gs': {
  //         template: dlTemplate,
  //         controller: 'dlCtrl'
  //       },
  //     }
  //   })
  //   .state('gs.dl.swap', {
  //     url: '/dl/swap/:cId',
  //     params: {
  //       cId: null
  //     },
  //     views: {
  //       'page@gs': {
  //         template: dlTemplate,
  //         controller: 'dlCtrl'
  //       },
  //     }
  //   })
  //   .state('gs.dl.boost', {
  //     url: '/dl/boost/:cId',
  //     params: {
  //       cId: null
  //     },
  //     views: {
  //       'page@gs': {
  //         template: dlTemplate,
  //         controller: 'dlCtrl'
  //       },
  //     }
  //   })
  //   .state('gs.dl.lb', {
  //     url: '/dl/lb',
  //     views: {
  //       'page@gs': {
  //         template: dlTemplate,
  //         controller: 'dlCtrl'
  //       },
  //     }
  //   })
  //   .state('gs.dl.suggestedMembers', {
  //     url: '/dl/suggested-members',
  //     views: {
  //       'page@gs': {
  //         template: dlTemplate,
  //         controller: 'dlCtrl'
  //       },
  //     }
  //   })
  // ;
}

export default config;
