import { Component } from '@angular/core';

@Component({
  selector: 'app-dl-suggested-members-wrapper',
  templateUrl: './dl-suggested-members-wrapper.component.html',
  styleUrl: './dl-suggested-members-wrapper.component.scss'
})
export class DlSuggestedMembersWrapperComponent {
  state = {
    dlState: 'dl/suggestedMembers',
    redirectState: 'teams/home/my-team',
    type: 'suggestedMembers'
  }
}
