import articles from './articles/articles';
import articlesItem from './articlesItem/articlesItem';
import article from './article/article';
import articleBlockImage from './articleBlockImage/articleBlockImage';
import articleBlockAction from './articleBlockAction/articleBlockAction';
import articleBlockHTML from './articleBlockHTML/articleBlockHTML';
import articleBlockYoutube from './articleBlockYoutube/articleBlockYoutube';
import articleBlockBanner from './articleBlockBanner/articleBlockBanner';
import articleEdit from './articleEdit/articleEdit';
import articleItemEditor from './articleItemEditor/articleItemEditor';

let moduleArticlesPage = angular.module('gsApp.articlesPage', [])
    .component('articles', articles)
    .component('articlesItem', articlesItem)
    .component('gsArticle', article)
    .component('articleBlockImage', articleBlockImage)
    .component('articleBlockAction', articleBlockAction)
    .component('articleBlockHtml', articleBlockHTML)
    .component('articleBlockYoutube', articleBlockYoutube)
    .component('articleBlockBanner', articleBlockBanner)
    .component('articleEdit', articleEdit)
    .component('articleItemEditor', articleItemEditor)
  ;

export default moduleArticlesPage.name;
