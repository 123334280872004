import NgTokenService from "../../../../../core/services/ng-token.service";

const template = require(`html-loader!./claim.html`).default;

class claimCtrl {
  constructor($mdDialog, $rootScope, stateService, RestManager, $location, $cookies, $timeout, NgTokenService) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
    this.stateService = stateService;
    this.RestManager = RestManager;
    this.$location = $location;
    this.$cookies = $cookies;
    this.$timeout = $timeout;
    this.NgTokenService = NgTokenService;
  }

  $onInit() {
    this.$timeout(()=>{
      $('.md-dialog-backdrop').css('display','none');
    })
    if(!this.data.chest){
      this.close();
      return;
    }
    const domain = this.data.chest.chest_domain || this.data.chest_attributes.domain;
    this.url = `https://${domain}/chest/claim/${this.NgTokenService.memberToken}/${this.data.chest.type}/${this.data.chest.entity_id}/web`;
    this.onClaimEndedEventRef = this.onClaimEndedEvent.bind(this);
    window.addEventListener('message', this.onClaimEndedEventRef);
  }

  onClaimEndedEvent(e){
    const data = e.data;
    console.log('claim data', e.data);
    this.close();
  }

  $onDestroy() {
    window.removeEventListener('message', this.onClaimEndedEvent);
  }

  close() {
    this.$mdDialog.hide();
  }
}
claimCtrl.$inject = [
  '$mdDialog', '$rootScope', 'stateService', 'RestManager', '$location', '$cookies', '$timeout', 'NgTokenService'
];
export default {
  template: template,
  controller: claimCtrl,
  bindings: {
    data: '=data',
  },
};
