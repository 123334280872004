import {INgChestModel} from "./models";

export class NgChestModel implements INgChestModel {
  target_points!: number;
  points!: number;
  private _pointsProgress!: number;

  get pointsProgress(): number {
    return this._pointsProgress;
  }

  set pointsProgress(value: number) {
    this._pointsProgress = value;
  }

  constructor(props: INgChestModel = {}) {
    Object.assign(this, props);

    this._pointsProgress = (this.target_points && this.points) ? Math.round(this.points / this.target_points * 100) : 0;
  }
}
