const template = require(`html-loader!./adminExhibitionsSelected.html`).default;

class adminExhibitionsSelectedCtrl {
  /**
   * @param {gsAdminManager} gsAdminManager
   * @param {ChallengesManager} ChallengesManager
   * @param {ModalsService} Modals
   * */

  constructor(gsAdminManager, $scope, Toasts, $element, Modals, ChallengesManager) {
    'ngInject';
    this.challengeId;
    this.$ctrl = this;
    this.type = 1;
    this.items = [];
    this.busy = false;
    this.lines = {
      1: '',
      2: '',
      3: '',
      4: '',
      5: '',
    };

    this.gsAdminManager = gsAdminManager;
    this.Toasts = Toasts;
    this.$element = $element;
    this.Modals = Modals;
    this.$scope = $scope;
    this.ChallengesManager = ChallengesManager;
  }

  getData() {
    if (!this.challengeId) {
      this.message('Challenge id required!', true);
      return;
    }

    if (this.busy) return;
    this.busy = true;

    this.items = [];
    this.gsAdminManager.getExhibitionList(this.type, this.challengeId).then(res => {
      if (!res.success) {
        const data = {
          message: 'Error!',
          class: 'error',
        };
        this.Toasts.open('simpleMessage', { data }, this.$element);
        this.busy = false;
        return;
      }
      this.message('Done!');
      this.items.push(...res.items);
      this.lines[5] = this.type === 1 ? 'Print Catalogue' : 'Digital Catalogue';
      this.busy = false;
    });
  }

  async setDigital() {
    const confirm = await this.Modals.confirm(
      'Auto select digital?',
      `Are you sure? You want to auto select digital winners?`,
      'Select',
      'Cancel'
    );
    if (!confirm) return;

    this.ChallengesManager.autoSelectDigitalExhibition(this.challengeId);

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async resetSelection() {
    if(!this.challengeId){return;}
    const confirm = await this.Modals.confirm(
      'Reset selection',
      `Are you sure you want to reset selection`,
      'Ok',
      'Cancel'
    );
    if (!confirm) return;

    const res = await this.ChallengesManager.resetAutoSelectExhibition(this.challengeId);
    if (!res.success) {
      this.message('Reset selection failed', true);
      return;
    }
    this.message('Reset selection success');

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  print() {
    window.print();
  }

  message(message, isError) {
    let options = {
      data: {
        message: message,
      },
    };
    if (isError) {
      options.data.class = 'error';
    }
    this.Toasts.open(
      'simpleMessage',
      options,
      this.$element,
      'bottom left'
    );
  }
}
adminExhibitionsSelectedCtrl.$inject = [
  'gsAdminManager', '$scope', 'Toasts', '$element', 'Modals', 'ChallengesManager'
];
export default {
  template: template,
  controller: adminExhibitionsSelectedCtrl,
  bindings: {},
};
