import Member from "../api/models/member.model";

class BlockedUserService {

  constructor(
    RestManager
  ) {
    'ngInject';

    this.RestManager = RestManager;

    this.paginationStartDefault = 0;
    this.paginationLimitDefault = 10;
    this.paginationStartStep = 10;
    this.pagination = {
      start: this.paginationStartDefault,
      limit: this.paginationLimitDefault
    };
  }

  async getBlockedUserList() {
    let members = [];
    const responseBlockedUserList = await this.RestManager.getBlockedMembers(this.pagination);
    console.log('this.pagination ', this.pagination);
    console.log('responseBlockedUserList ', responseBlockedUserList);

    if (responseBlockedUserList.success) {
      members = responseBlockedUserList.members.map(member => new Member(member));
    }

    return members;
/*    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.members.three);
      }, 3000);
    });*/
  }

  setPaginationStepForward() {
    this.pagination.start += this.paginationStartStep;
  }

  setPaginationToDefault() {
    this.pagination.start = this.paginationStartDefault;
  }

  async unblockMembers(memberIds) {
    return await this.RestManager.unblockMembers({member_ids: memberIds});

/*    return await new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(this.members.saved);
      }, 3000);
    });*/
  }
}
BlockedUserService.$inject = [
  'RestManager',
];
export default BlockedUserService;
