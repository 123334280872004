const template = require(`html-loader!./badgeV2.html`).default;
import challengeEnum from "../../api/enums/challenge.enum";
import badgeV2Mode from "../../api/enums/badgeV2.enum";

class badgeV2Controller {
  constructor(
    $rootScope,
    $scope
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;

    this.challengeEnum = challengeEnum;
    this.badgeV2Mode = badgeV2Mode;
  }

  $onChanges(changes) {
    // console.log('changes ', changes);
    // console.log('changes.badgeName ', changes.badgeName);
    // console.log('changes.badgeName.currentValue ', changes.badgeName.currentValue);
    this.badgeName = changes.badgeName.currentValue;

    if (!this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }
}

badgeV2Controller.$inject = [
  '$rootScope',
  '$scope'
];

export default {
  template: template,
  controller: badgeV2Controller,
  bindings: {
    badgeName: '<',
    mode: '<',
    mode1: '<',
    exhibitionName: '<',
    textLeft: '<',
    textRight: '<',
    previewSizeClass: '<',
    exhibitionMobileFlagsClass: '<',
    maxPhotos: '<'
  }
};
