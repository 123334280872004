const template = require(`html-loader!./brazeContentCardsCaptionedImage.html`).default;

class BrazeContentCardsCaptionedImage {
  constructor(
    brazeService
  ) {
    'ngInject';

    this.brazeService = brazeService;
    this.brazeData = null;
    this.descriptionBullets = [];
  }

  $onInit() {
    this.contentCardsResponse = this.brazeService.filteredContentCardData ? this.brazeService.filteredContentCardData : this.brazeService.getFilteredContentCard({filterConfig: this.brazeConfig});

    if (this.contentCardsResponse.description !== '') {
      const separator = "•";
      const descriptionSplit = this.contentCardsResponse.description.split(separator);
      if (descriptionSplit.length) {
        /* .splice(1) - is cut first item, cause after split, the first item would be an empty string */
        this.descriptionBullets = descriptionSplit.splice(1);
      }
    }
  }
}

BrazeContentCardsCaptionedImage.$inject = [
  'brazeService'
];

export default {
  template: template,
  controller: BrazeContentCardsCaptionedImage,
  bindings: {
    brazeConfig: '<',
    close: '&'
  }
};
