class FollowersManager {
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  getProfileFollowers(profileId, from, limit, method) {
    return this.api['followers'][method]({ id: profileId, start: from, limit: limit }).then(res => {
      return res.success ? res.items : [];
    });
  }

  getProfileSuggestFollow(member_id) {
    return this.api['suggestFollow']['getSuggestFollow']({ id: member_id, start: 0, limit: 6 }).then(res => res.items);
  }

  isFollowing(om_id) {
    return this.api['rest']['isFollowing']({ om_id });
  }
}

FollowersManager.$inject = [
  'api'
];

export default FollowersManager;
