import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-sponsors-materials-wrapper',
  templateUrl: './sponsors-materials-wrapper.component.html',
  styleUrl: './sponsors-materials-wrapper.component.scss'
})
export class SponsorsMaterialsWrapperComponent {
  formId = null;

  constructor(
    private route: ActivatedRoute
  ) {
    this.route
      .params.pipe(
    ).subscribe(async (params: any) => {
      this.formId = params.formId;
    });
  }
}
