import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {IStoreStateData, StoreService, StoreState} from "../../services/store.service";
import { Observable} from "rxjs";
import {IGetStore, IStoreItem} from "../../services/store.service.types";
import {SectionType,} from "../../models/enums";
import {BankrollService} from "../../../bankroll/bankroll.service";
import {ResourceType} from "../../../../core/models/enums";
import {StoreEventOrigin, StoreEventsService} from "../../services/store-events.service";
// @ts-ignore
import mixpanelEventsEnum from "../../../../gsApp/app/services/mixpanel/mixpanel.enum";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgModalsService} from "../../../angular-material/services/ng-modals.service";
import {StoreDataType} from "../../../angular-material/models/types";
import {InfoService} from "../../../../core/services/info.service";

@Component({
  selector: 'app-store',
  templateUrl: './store.component.html',
  styleUrls: ['./store.component.scss'],
})
export class StoreComponent implements OnInit, OnDestroy {
  @Input() data!: StoreDataType;
  @ViewChild('tabGroup') tabGroup: any;
  @ViewChild('container') container!: ElementRef;
  @ViewChild('battles') battlesSectionElement!: ElementRef;
  readonly SectionType = SectionType;
  readonly StoreState = StoreState;
  ready = false;
  req!: Observable<IGetStore>;
  store!: IGetStore;
  storeStateData?:IStoreStateData;

  sections = [
    {type: SectionType.CHALLENGES, name:SectionType.CHALLENGES},
  ];
  activeLink: SectionType = SectionType.CHALLENGES;
  scrolling: boolean = false;

  constructor(
    public storeService: StoreService,
    public bankrollService: BankrollService,
    private storeEventsService: StoreEventsService,
    private ngModalsService: NgModalsService,
    private infoService: InfoService
  ) {

    this.storeService.storeState$.pipe(
      takeUntilDestroyed()
    ).subscribe((value) => {
      this.storeStateData = value;
      setTimeout(()=> {
        this.setActiveLinkByScrollPos();
      });
    });

    this.bankrollService.itemClick$.pipe(
      takeUntilDestroyed()
    ).subscribe(resourceType => this.goToDeepLink(resourceType))
  }

  async ngOnInit(): Promise<void> {
    await this.init();
  }

  async init(): Promise<void> {
    console.log('this.data', this.data);
    this.store = await this.storeService.getStore();
    if(!this.store.success){
      this.ngModalsService.closeAll();
      return
    }
    if(this.store.battles?.categories?.length){
      this.sections.push({type: SectionType.BATTLES, name:SectionType.BATTLES});
    }
    if(this.data.storeItemId){
      console.log('storeItemId',this.data.storeItemId);
      let storeItemToBuy:IStoreItem | undefined = (await this.storeService.getStoreItem(this.data.storeItemId)).package;
      if(storeItemToBuy){
        console.log('storeItemToBuy',storeItemToBuy);
        this.storeService.setPurchaseActive(storeItemToBuy, StoreEventOrigin.STORE);
      }
      this.ready = true;
      return;
    }
    this.ready = true;
    setTimeout(()=>{
      if(this.data.deeplink){
        this.goToDeepLink(this.data.deeplink);
      }
    },200);
  }

  onScroll(): void {
    if(!this.scrolling){
     this.setActiveLinkByScrollPos();
    }
  }

  setActiveLinkByScrollPos(): void {
    if(this.sections.length > 1) {
      if (!this.container) {
        return;
      }
      const element: any = this.container.nativeElement;
      const battlesTop = this.battlesSectionElement.nativeElement.offsetTop;
      const tabsHeight = 109;
      const currentScroll = element.scrollTop + element.offsetHeight + 5;
      const reachedToBottom = (currentScroll >= element.scrollHeight);
      const passedBattleScroll = ((element.scrollTop + tabsHeight) >= battlesTop);
      this.activeLink = (passedBattleScroll || reachedToBottom)
        ? SectionType.BATTLES : SectionType.CHALLENGES;
    }
  }

  async goToDeepLink(deepLink: SectionType | ResourceType) {
    this.scrolling = true;
    await this.storeService.goToDeepLink(deepLink);
    this.scrolling = false;
    this.setActiveLinkByScrollPos();
  }

  async onTabClick(sectionType:SectionType) {
    if (!this.scrolling) {
      this.activeLink = sectionType;
      await this.goToDeepLink(sectionType);
      if (sectionType === SectionType.BATTLES) {
        this.storeEventsService.storeTabsEvent(mixpanelEventsEnum.UI_ACTION.BATTLES_TAB);
      } else {
        this.storeEventsService.storeTabsEvent(mixpanelEventsEnum.UI_ACTION.CHALLENGES_TAB);
      }
    }
  }

  ngOnDestroy(): void {
    this.storeService.storeState$.next({state: StoreState.STORE});
    this.infoService.dismiss();
  }
}
