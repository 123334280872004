const template = require(`html-loader!./profileSettings.html`).default;

import {
  memberLeaveModes,
  memberLeaveSteps
} from "../../../services/Modals/deactivateDeleteAccount/deactivateDeleteAccount.data";

class profileSettingsCtrl {
  /**
   * @param {ModalsService} Modals
   * @param {ToastsService} Toasts
   * @param {FacebookManager} FacebookManager
   * @param {Facebook} Facebook
   * @param {PhotosManager} PhotosManager
   * @param {notificationAlert} notificationAlert
   * @param {GooglePhotosManager} GooglePhotosManager
   * */

  constructor(
    pageData,
    RestManager,
    Toasts,
    $element,
    Facebook,
    $scope,
    $timeout,
    Modals,
    $rootScope,
    FacebookManager,
    GooglePhotosManager,
    PhotosManager,
    notificationAlert,
    stateService,
    tagEventService,
    $window,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.changedFields = [];
    this.errors = {};
    this.busy = false;
    this.inited = false;
    this.socialItems = [];

    this.pageData = pageData;
    this.RestManager = RestManager;
    this.Toasts = Toasts;
    this.$element = $element;
    this.Facebook = Facebook;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.FacebookManager = FacebookManager;
    this.PhotosManager = PhotosManager;
    this.notificationAlert = notificationAlert;
    this.GooglePhotosManager = GooglePhotosManager;
    this.stateService = stateService;
    this.tagEventService = tagEventService;
    this.$window = $window;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;

    this.save = _.debounce(this.save.bind(this), 500);
    this.changed = _.debounce(this.changed.bind(this), 500);
  }

  $onInit() {
    this.NgMetaDataService.setTitle('Profile Settings');
    this.init();
  }

  /** @return void **/
  async init() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('profile-settings');
    this.profileSettings = (await this.MemberService.getProfileSettings()).settings;
    this.tagEventService.sendPageViewEvent({title: 'Profile settings page'});
    this.inited = true;
    this.initSocialItems();
  }

  initSocialItems() {
    this.socialItems = [
      {
        type : 'facebook',
        link : this.sociallink.bind(this),
        unlink : this.socialUnlink.bind(this),
        connected: this.profileSettings.facebook_connected,
        name: 'Facebook',
        showTitle: true
      },
      {
        type : 'google_photos',
        link : this.sociallink.bind(this),
        unlink : this.socialUnlink.bind(this),
        connected: this.profileSettings.google_connected,
        name: 'Google Photos',
        showTitle: true
      }
    ];
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async sociallink(socialItem) {
    let res;
    switch (socialItem.type) {
      case 'google_photos':
        res = await this.GooglePhotosManager.auth();
        if(res.success){
          window.location.reload();
        }
        break;
      case 'facebook':
        await this.Facebook.link();
        break;
      default:
        return;
    }
    if (!res.success) {return}

    socialItem.connected = true;
    if (!this.$scope.$$phase) {this.$scope.$digest()}
  }

  async socialUnlink(socialItem) {
    if(socialItem.type === 'facebook'){
      if (!this.member.email) {
        this.Toasts.show(
          'simpleMessage',
          { message: 'Please enter email!', class: 'profile-settings error' },
          this.$element
        );
        return;
      }
    }

    const confirm = await this.Modals.confirm(
      'Disconnect?',
      `Are you sure you want to disconnect your ${socialItem.name} account from GuruShots?`,
      'Disconnect',
      'Cancel',
    );
    if (!confirm) return;

    let res;
    switch (socialItem.type) {
      case 'google_photos':
        res = await this.GooglePhotosManager.googlePhotosDisconnect();
        this.profileSettings.google_connected = false;
        break;
      case 'facebook':
        res = await this.FacebookManager.facebookUnlink();
        window.location.reload();
        return;
    }
    if (!res.success) {return}

    socialItem.connected = false;
    if (!this.$scope.$$phase) {this.$scope.$digest()}
  }

  setMemberSettings(alias, field, value) {
    this.RestManager.setMemberSettings(field, value).then(res => {
      if (!res.success) {
        this.Toasts.open(
          'simpleMessage',
          {
            data: {
              message: `${alias} not updated!`,
              class: 'profile-settings error',
            },
          },
          this.$element,
        );
        return;
      }

      this.Toasts.open(
        'simpleMessage',
        {
          data: {
            message: `${alias} saved!`,
            class: 'profile-settings',
          },
        },
        this.$element,
      );
    });
  }

  changePassword(form) {
    form.$submitted = true;

    // validate
    if (!this.validate('password', this.newPassword)) {
      form.newPassword.$error.validationError = true;
      return true;
    }
    form.newPassword.$error = {};

    // match repeat
    if (this.repeatPassword !== this.newPassword) {
      form.repeatPassword.$error.validationError = true;
      return true;
    }
    form.repeatPassword.$error = {};

    if (this.busy) {
      return true;
    }
    this.busy = true;

    this.save([{ field: 'password', value: this.newPassword }]);
  }

  changed(field, value, form) {
    form.$submitted = true;
    if (!value.length) return true;
    // validate
    if (!this.validate(field, value)) {
      form[field].$error.validationError = true;
      // delete from changedField
      this.changedFields.forEach((item, index) => {
        if (item.field === field) {
          delete this.changedFields[index];
        }
      });
      return true;
    }
    form[field].$error = {};

    const changedField = { field, value };

    let done = false;
    this.changedFields = this.changedFields.map(item => {
      if (item.field === field) {
        done = true;
        return changedField;
      }
      return item;
    });

    if (!done) this.changedFields.push(changedField);
  }

  updateMember() {
    if (this.busy) {
      return true;
    }
    this.busy = true;

    //Validate fields
    this.save(this.changedFields);
    this.changedFields = [];
  }

  async deactivateProfile(event) {
    const res = await this.Modals.open('deactivateDeleteAccount', event, {
      memberLeaveMode : null,
      memberLeaveStep : memberLeaveSteps.DEACTIVATE_OR_DELETE,
    });
    console.log('after deactivateDeleteAccount res', res);
    if(res){
      window.location.href = '/';
    }

    // const memberLeaveMode = memberLeaveModes.REACTIVATE;
    //
    // this.Modals.open('deactivateDeleteAccount', event, {
    //   memberLeaveMode : memberLeaveMode,
    //   memberLeaveStep : memberLeaveSteps.ADMIN,
    //   member: this.member
    // });
  }

  validate(field, value) {
    switch (field) {
      case 'first_name':
      case 'last_name':
      case 'city':
        return value.length >= 2;
      case 'user_name':
        return /^[0-9a-zA-Z_.]+$/.test(value) && value.length >= 3 && value.length <= 25;
      case 'email':
        return /^[a-zA-Z0-9\-_.+]+@[a-zA-Z0-9\-]+(\.[a-zA-Z]+){1,2}$/.test(value);
      case 'password':
        return value && !/\s/.test(value) && value.length >= 5 && value.length <= 25;
      default:
        return true;
    }
  }

  /** @return boolean */
  async save(changedFields) {
    let fields = {};
    changedFields.forEach(item => {
      fields[item.field] = item.value;
      if (item.field === 'adult') {
        this.member.adult = item.value;
      }
    });

    if (angular.equals({}, fields)) {
      this.$timeout(() => {
        this.busy = false;
      }, 500);
      return true;
    }

    const res = await this.RestManager.updateMemberSettings(fields);

    this.busy = false;

    if (!res.success) {
      this.Toasts.open(
        'simpleMessage',
        {
          data: {
            message: 'Not saved',
            class: 'profile-settings error',
          },
        },
        this.$element,
      );
      return;
    }

    this.Toasts.open(
      'simpleMessage',
      {
        data: {
          message: 'Saved',
          class: 'profile-settings',
        },
      },
      this.$element,
    );
  }

  async getBlockedUsersList() {
    await this.Modals.open('blockedUser', null);
  }
}
profileSettingsCtrl.$inject = [
  'pageData',
  'RestManager',
  'Toasts',
  '$element',
  'Facebook',
  '$scope',
  '$timeout',
  'Modals',
  '$rootScope',
  'FacebookManager',
  'GooglePhotosManager',
  'PhotosManager',
  'notificationAlert',
  'stateService',
  'tagEventService',
  '$window',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: profileSettingsCtrl,
};
