import { Injectable } from '@angular/core';
import RequestServiceModel from "../../../core/services/request/requestService.model";
import NgRequestEnum from "../../../core/services/request/request.enum";
import {BehaviorSubject, Observable, of, Subject} from "rxjs";
import {map} from "rxjs/operators";
import {Mission} from "../missions.interface";
import RequestService from "../../../core/services/request/requestService";
import {MissionClaimState, MissionsState} from "../missions.enum";
import {
  ISocketMissionsUpdateEventResult,
  SocketService
} from "../../../core/services/socket.service";
import {SocketsEnum} from "../../../core/enum/sockets.enum";
import {NgLocalStorageService} from "../../../core/services/ng-local-storage.service";
import {NgLocalStorageKeysEnum} from "../../../core/models/enums";
import {IProgress} from "../../../core/models/models";
import GsResponse from "../../../gsApp/app/api/models/responses/GsResponse";

interface IGetMissions {
  missions_state: MissionsState;
  list: Mission[];
  success: boolean;
}
interface ILatestMissionsLS{
  missionsMap: {[id: number]:{   id: number;   progress: IProgress; }};
}

@Injectable({
  providedIn: 'root'
})

export class MissionsService {
  tabIndicatorEnabled$ = new Subject<boolean>();
  missionsDirtyMap$ = new BehaviorSubject<Map<number, Mission>>(new Map());
  missionsList$ = new BehaviorSubject<Mission[] | undefined>(undefined);

  constructor(
    private requestService:RequestService,
    private socketService: SocketService,
    private localStorageService:NgLocalStorageService
  ) {}

  public init(){
    this.getMissions().pipe().subscribe(() => {});
  }

  addSocketListener(){
    this.socketService.socket?.on(SocketsEnum.MissionsUpdate,
      (result: ISocketMissionsUpdateEventResult) => {

      if (result.new || result.in_progress || result.completed) {
        this.tabIndicatorEnabled$.next(true);
      }

      if (!this.missionsList) {
        return;
      }

      if (result.new) {
        result.new.forEach((mission) => {
          this.missionsList$.next([...this.missionsList as Mission[], mission]);
          this.missionsDirtyMap.set(mission.id, mission);
          this.missionsDirtyMap$.next(this.missionsDirtyMap);
        });
      }
      if (result.in_progress || result.completed) {
        let dirtyMissionsList: Mission[] = [];
        if (result.in_progress) {
          dirtyMissionsList = [...result.in_progress];
        }
        if (result.completed) {
          dirtyMissionsList = [...dirtyMissionsList, ...result.completed];
        }

        dirtyMissionsList.forEach((mission) => {
          let updatedMission = this.missionsList!.find(oldMission => oldMission.id === mission.id);
          if (updatedMission) {
            updatedMission = mission;
            const index = this.missionsList!.findIndex(oldMission => oldMission.id === mission.id);
            updatedMission.initialOffset = this.createTranslateYStyle(index);
            this.missionsList![index] = updatedMission;
            this.missionsList$.next([...this.missionsList as Mission[]]);
          }
          this.missionsDirtyMap.set(mission.id, mission);
          this.missionsDirtyMap$.next(this.missionsDirtyMap);
        });
      }

      this.saveMissionsToLS();
    });
  }

  public getMissions(): Observable<Mission[]> {
    if (this.missionsList) {
      return of(this.missionsList);
    }
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_MY_MISSIONS.NAME
    });
    return this.requestService.request<IGetMissions>(conf).pipe(
      map( (res)=> {
        if(res.success) {
          this.missionsList$.next(res.list);
          this.updateMissionsDirtyMapFromLs();
          this.saveMissionsToLS();
          this.addSocketListener();
          return res.list;
        }
        return [];
      })
    );
  }

  public claimRewards(mission_id: number): Observable<GsResponse> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.CLAIM_MISSION_PRIZES.NAME,
      body: {mission_id}
    });
    return this.requestService.request<GsResponse>(conf);
  }

  private saveMissionsToLS(){
    if(this.missionsList && this.missionsList.length){
      let latestMissionsLS:ILatestMissionsLS = {
        missionsMap: {}
      };
      this.missionsList.forEach((mission) => {
        latestMissionsLS.missionsMap[mission.id] = {id : mission.id, progress: mission.progress};
      })
      this.localStorageService.saveMemberDataByKeyValue(NgLocalStorageKeysEnum.LATEST_MISSIONS, latestMissionsLS);
    }
  }

  private updateMissionsDirtyMapFromLs(){
    let latestMissionsLS:ILatestMissionsLS =
      this.localStorageService.getMemberDataByKey(NgLocalStorageKeysEnum.LATEST_MISSIONS) as ILatestMissionsLS;
    if(latestMissionsLS){
      this.missionsList?.forEach( (mission)=> {
        const missionFromLs = latestMissionsLS.missionsMap[mission.id];
        if(!missionFromLs ||
          missionFromLs.progress?.current !== mission.progress?.current){ // if new mission or current changed
          this.missionsDirtyMap.set(mission.id, mission);
        }
      });
      if(this.missionsDirtyMap.size > 0){
        this.tabIndicatorEnabled$.next(true);
        this.missionsDirtyMap$.next(this.missionsDirtyMap);
      }
    }
  }

  public clearMissionsDirtyMap(): void{
    this.missionsDirtyMap$.next(new Map<number, Mission>());
  }

  get missionsDirtyMap(): Map<number, Mission> {
    return this.missionsDirtyMap$.value;
  }

  get missionsList(): Mission[] | undefined {
    return this.missionsList$.value ? this.sortMissions(this.missionsList$.value as Mission[]) : undefined;
  }

  sortMissions(missions: Mission[]): Mission[] {
    return (missions ?? []).sort((a, b) => {
      // If a is 'completed' and b is not, a comes first
      if (a.claim_state === MissionClaimState.CLAIM && b.claim_state !== MissionClaimState.CLAIM) {
        return -1;
      }
      // If b is 'completed' and a is not, b comes first
      if (b.claim_state === MissionClaimState.CLAIM && a.claim_state !== MissionClaimState.CLAIM) {
        return 1;
      }
      // If both have the same status, sort by timestamp (ascending)
      return  a.expiration_timestamp - b.expiration_timestamp;
    });
  }

  filterMissions(missions: Mission[]): Mission[] {
    const nowTs = new Date().getTime();
    return missions.filter((mission) => {
      const expirationTs = mission.expiration_timestamp * 1000;
      return mission.claim_state === MissionClaimState.CLAIM || nowTs < expirationTs;
    });
  }

  createTranslateYStyle(index: number): string {
    return`translateY(${ index * 100 + (15 * index)}%)`;
  }

  generateMissionsOffsets(missions: Mission[]): Mission[] {
    return missions.map((filteredMission: Mission, index: number) => ({
      ...filteredMission,
      offset: this.createTranslateYStyle(index),
      initialOffset: null
    }));
  }
}
