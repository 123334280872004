import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

export interface MediatorMessage<T = unknown> {
  key: MediatorMessageKey;
  data?: T;
}

export enum MediatorMessageKey {
  OPEN_MINI_GAME,
  CLOSE_MINI_GAME,
  RELOAD_ACTIVE_CHALLENGES,
  UPDATE_ACTIVE_CHALLENGES,
  REMOVE_ACTIVE_CHALLENGE,
  ADD_ACTIVE_CHALLENGE,
  GET_ACTIVE_CHALLENGE,
  STOP_AUTO_UPDATE_ACTIVE_CHALLENGES,
  START_AUTO_UPDATE_ACTIVE_CHALLENGES,
  LOCKED,
  LOCKED_RESULT,
  OPEN_TURBO_RULES,
  OPEN_TURBO_ACTIVATION,
  AI_REPORT,
  OPEN_LOGIN_MODAL,
  OPEN_VOTE_MODAL,
  UPDATE_EXPOSURE,
  OPEN_FILL_MODAL,
  FILL,
  CLOSE_MODAL,
  DISABLE_SUBMIT_BUTTON
}

@Injectable({
  providedIn: 'root'
})
export class MediatorService<T = unknown> {
  private subject = new Subject<MediatorMessage<T>>();
  public rootScope = new BehaviorSubject<any>(null);
  public gotToState = new BehaviorSubject<any>(null);

  public broadcast(key: MediatorMessageKey, data?: T): void {
    this.subject.next({ key, data });
  }

  get takeUntil(){
    return takeUntil;
  }

  public on(key: MediatorMessageKey): Observable<MediatorMessage<T>> {
    return this.subject
      .asObservable()
      .pipe(filter((message) => message.key === key));
  }
}
