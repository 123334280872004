import ImageModel from './image/image.model';

class SpotlightModel {
  constructor({id, image = {}, label, title, text, action = {}, sort}) {
    image = new ImageModel(image);
    Object.assign(this, {id, image, label, title, text, action, sort});
  }
}

export default SpotlightModel;
