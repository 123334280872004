import { Component } from '@angular/core';

@Component({
  selector: 'app-challenge-full',
  templateUrl: './challenge-full.component.html',
  styleUrl: './challenge-full.component.scss'
})
export class ChallengeFullComponent {

}
