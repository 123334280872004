import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-liveops-wrapper',
  templateUrl: './admin-content-liveops-wrapper.component.html',
  styleUrl: './admin-content-liveops-wrapper.component.scss'
})
export class AdminContentLiveopsWrapperComponent {

}
