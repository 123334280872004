import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-exhibitions-wrapper',
  templateUrl: './admin-content-manager-exhibitions-wrapper.component.html',
  styleUrl: './admin-content-manager-exhibitions-wrapper.component.scss'
})
export class AdminContentManagerExhibitionsWrapperComponent {

}
