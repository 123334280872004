<div class="bankroll-item-wrapper">
  <div class="bankroll-item-inner-wrapper" (click)="openStore()">
    <div class="bankroll-item-icon gs-z-i-1 gs-p-r">
      <img
        id="bankroll-coin-plus"
        [ngSrc]="bankrollItem?.icon?.svg_url"
        width="33"
        height="33"
      >
    </div>
    <div class="bankroll-item-value">
      <span>{{amount$ | async | number}}</span>
    </div>
  </div>
</div>


