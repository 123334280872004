function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  // **********
  // Routes
  // **********
  //Redirect rules
  // $urlRouterProvider
  //   .when('/achievements', '/challenges')
  // ;

  $stateProvider
    .state('gs.achievements', {
      url: '/achievements/:cUrl/:userName',
      params: {
        cUrl: {
          value: null,
          squash: true
        },
        userName: {
          value: null,
          squash: true
        }
      },
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'challengeAchievements'},
        'footer@gs': null
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
