import { Component } from '@angular/core';
import {Subject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-challenge-achievements-wrapper',
  templateUrl: './challenge-achievements-wrapper.component.html',
  styleUrl: './challenge-achievements-wrapper.component.scss'
})
export class ChallengeAchievementsWrapperComponent {
  cUrl = '';
  userName = '';
  subject = new Subject();

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.params.pipe(
      takeUntilDestroyed()
    ).subscribe(async (params: any) => {
      this.cUrl = params.cUrl;
      this.userName = params.userName;
    });
  }
}
