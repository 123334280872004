import memberEnum from "../../../gsApp/app/api/enums/member.enum";
import {INgChallengeExhibitionModel} from "../interfaces/challenges.interface";

export class NgChallengeExhibitionModel implements INgChallengeExhibitionModel {
  state: any;

  constructor(props: INgChallengeExhibitionModel = {}) {
    Object.assign(this, props);
  }

  isExhibiting = (): boolean => this.state === memberEnum.EXHIBITION_STATE.EXHIBITING;
  isMemberPaid = (): boolean => [
    memberEnum.EXHIBITION_STATE.APPLY_NOW,
    memberEnum.EXHIBITION_STATE.EXHIBIT
  ].includes(this.state);
}
