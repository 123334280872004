import { Component } from '@angular/core';

@Component({
  selector: 'app-challenge-rank-guru-top-pick-wrapper',
  templateUrl: './challenge-rank-guru-top-pick-wrapper.component.html',
  styleUrl: './challenge-rank-guru-top-pick-wrapper.component.scss'
})
export class ChallengeRankGuruTopPickWrapperComponent {

}
