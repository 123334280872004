const template = require(`html-loader!./mobileHeader.html`).default;

const UPDATE_TIME = 240000;
const TYPE_ALL = 'all',
  TYPE_VOTE = 'vote',
  TYPE_ACTIVITY = 'activity';

class mobileHeaderCtrl {
  /**
   * @param {ModalsService} Modals
   * @param {gsNotificationsService} gsNotificationsService
   * */
  constructor(
    $rootScope,
    $scope,
    $interval,
    Modals,
    NotificationsManager,
    $timeout,
    gsNotificationsService,
    MemberService,
  ) {
    'ngInject';

    this.new = {};

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$interval = $interval;
    this.Modals = Modals;
    this.NotificationsManager = NotificationsManager;
    this.gsNotificationsService = gsNotificationsService;
    this.$timeout = $timeout;
    this.member = MemberService.getCurrentMember();

    this.onResize = _.debounce(this.onResize.bind(this), 250);
  }

  $onInit() {
    this.isMobile = document.body.clientWidth <= 961;
    $(window).on('resize', this.onResize);
    // interval
    if (this.isMobile) {
      this.$timeout(() => {
        this.updateNotifications();
        this.interval = this.$interval(() => {
          this.updateNotifications();
        }, UPDATE_TIME);
      }, 1000);
    }
  }

  $onDestroy() {
    $(window).off('resize', this.onResize);
    this.$interval.cancel(this.interval);
  }

  /** @return void */
  async updateNotifications() {
    const res = await this.NotificationsManager.get(0, 50, 'all');
    if (!res.success) {
      return;
    }
    // update new
    this.new = res.new;

    // trigger BROADCAST event
    if (res.broadcast !== null) {
      let data = res.items[TYPE_ACTIVITY][res.broadcast];
      this.gsNotificationsService.openModal(data);
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  notificationsClick() {
    // send watch new
    if (this.new.total > 0) {
      // update views
      this.new.total = 0;
      this.NotificationsManager.watchNotifications();
    }
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= 961;
    if (!this.isMobile && this.interval) {
      this.$interval.cancel(this.interval);
    }
  }
}

mobileHeaderCtrl.$inject = [
  '$rootScope',
  '$scope',
  '$interval',
  'Modals',
  'NotificationsManager',
  '$timeout',
  'gsNotificationsService',
  'MemberService',
];

export default {
  template: template,
  controller: mobileHeaderCtrl
};
