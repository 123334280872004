function routerLink(
  ng2Router
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      checkInclude:'@',
      routerLink: '@',
      routerLinkActive: '@',
      routerLinkReload: '@',
    },
    link: function(scope, element) {
      const activeClass = scope.routerLinkActive;
      if (scope.checkInclude ? ng2Router.url.includes(scope.routerLink) : ng2Router.url.split('?')[0] === '/' + scope.routerLink) {
        element[0].className += ' ' + activeClass;
      }

      Array.from(element).forEach(el => {
        el.href = scope.routerLink;
      });

      element.on('click', () => {
        const previousElements = document.getElementsByClassName(activeClass) ?? [];
        Array.from(previousElements).forEach(element => {
          element.classList.remove(activeClass);
        });

        const queryParams = JSON.parse(element[0].getAttribute('queryParams'));
        if(scope.routerLinkReload === 'true'){
          ng2Router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            ng2Router.navigate([scope.routerLink], { queryParams });
          });
        } else {
          ng2Router.navigate([scope.routerLink], { queryParams });
        }

        setTimeout(() => {
          const activeClass = scope.routerLinkActive;
          if (ng2Router.url === '/' + scope.routerLink) {
            element[0].className += ' ' + activeClass;
          }
        });
      });
    }
  };
}

routerLink.$inject = [
  'ng2Router'
];

export default routerLink;
