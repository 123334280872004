const ChallengesJoinedCoverPhotoTemplate = require(`html-loader!./challengesJoinedCoverPhoto.html`).default;
import badgeV2Mode from "../../api/enums/badgeV2.enum";

class ChallengesJoinedCoverPhoto {
  constructor(
    challengeService
  ) {
    'ngInject';

    this.badgeV2Mode = badgeV2Mode;
    this.challengeService = challengeService;
  }

  $onInit() {
    
  }

  onChallengeFinished() {
    this.challengeService.onChallengeFinished(this.challenge);
  }
}

ChallengesJoinedCoverPhoto.$inject = [
  'challengeService'
];

export default {
  template: ChallengesJoinedCoverPhotoTemplate,
  controller: ChallengesJoinedCoverPhoto,
  bindings: {
    challenge: '<'
  }
};
