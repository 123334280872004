const template = require(`html-loader!./player.html`).default;

class playerCtrl {

  constructor($sce, $mdDialog) {
    "ngInject";

    this.$sce = $sce;
    this.$mdDialog = $mdDialog;
  }

  $onChanges() {
    switch(this.data.type) {
      case 'youtube':
        this.url = this.$sce.trustAsResourceUrl(`https://www.youtube.com/embed/${this.data.id}?rel=0&autoplay=1&showinfo=0&hd=1`);
        console.log(this.url);
        break;
    }
  }

  close() {
    this.$mdDialog.hide();
  }
}
playerCtrl.$inject = [
  '$sce', '$mdDialog'
];
export default {
  template: template,
  controller: playerCtrl,
  bindings: {
    data: '='
  }
};
