<div class="challenge-full-wrapper">
  <app-ng-image
    class="gs-bg-i-none"
    [image]="'../../../../../assets/svg/full-challenge.svg'"
    [priority]="true"
    [width]="90"
    [height]="89"
  >
  </app-ng-image>
  <div class="challenge-full-text">Challenge is full</div>
</div>
