class ToastsService {

  constructor($mdToast, toastsSettings) {
    "ngInject";
    this.$mdToast = $mdToast;
    this.toastsSettings = toastsSettings;
  }

  open(name, data, target, position) {
    //Generate settings obj
    let settings = angular.extend({}, this.toastsSettings.default, this.toastsSettings[name]);
    //Set parent element and data
    settings.parent = target;
    settings.locals = data;
    if (position){
      settings.position = position;
    }
    //Show
    this.$mdToast.show(settings);
  }

  message(message, target, isError=true, position = 'bottom left') {
    let options = {
      data: {
        message: message,
      },
    };
    if (isError) {
      options.data.class = 'error';
    }
    this.open(
      'simpleMessage',
      options,
      target,
      position
    );
  }

  /** new method **/
  show(name, data, target) {
    //Generate settings obj
    let settings = angular.extend({}, this.toastsSettings.default, this.toastsSettings[name]);
    //Set parent element and data
    settings.parent = target;
    settings.locals = {data};
    //Show
    this.$mdToast.show(settings);
  }
}
ToastsService.$inject = [
  '$mdToast', 'toastsSettings'
];
export default ToastsService;
