import {
  ActiveChallengeActionButtonType
} from "../../../../../modules/challenges/components/active-challenge-action-button/active-challenge-action-button.component";

const template = require(`html-loader!./challengesItem.html`).default;
import badgeV2Mode from "../../../api/enums/badgeV2.enum";
import challengeEnum from '../../../api/enums/challenge.enum';
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";

const filterReasonIds = {
  adult: 1,
  off_topic: 2,
  copyright: 3,
  winning: 4,
  similar: 5,
};

class challengesItemController {
  /** @param {ChallengesManager} ChallengesManager **/

  ActiveChallengeActionButtonType

  constructor(
    $cookies,
    $element,
    $interval,
    $location,
    $rootScope,
    $scope,
    $timeout,
    ChallengesManager,
    photoSwipe,
    challengeService,
    mixpanelService,
    JoinedChallengesPageService,
    MemberService
  ) {
    'ngInject';
    this.$ctrl = this;
    this.challengeEnum = challengeEnum;

    this.$cookies = $cookies;
    this.$element = $element;
    this.$interval = $interval;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.ChallengesManager = ChallengesManager;
    this.photoSwipe = photoSwipe;
    this.badgeV2Mode = badgeV2Mode;
    this.challengeService = challengeService;
    this.ActiveChallengeActionButtonType = ActiveChallengeActionButtonType;
    this.challengeEnum = challengeEnum;
    this.mixpanelService = mixpanelService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.mixpanelEventsEnum = mixpanelEventsEnum;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.uString = Math.floor(Math.random() * 10000000000000001);
    // todo: GET TC FROM initData
    this.shareLink = `${this.$location.protocol()}://${this.$location.host()}/challenge/${
      this.challenge.url
    }/rank/top-photographer/${this.member?.user_name}/${this.uString}`;
  }

  $onChanges() {
    // calc end percent and init interval for speed challenge
    if (this.challenge.isOnePhoto()) {
      this.interval = this.$interval(() => {
        this.challenge.currentTimePercent = this.challenge.timePercent;
      }, 1000);
    }
  }

  $onDestroy() {
    if (this.interval) this.$interval.cancel(this.interval);
  }

  onChallengeFinished(){
    this.challengeService.onChallengeFinished(this.challenge);
  }
}
challengesItemController.$inject = [
  '$cookies',
  '$element',
  '$interval',
  '$location',
  '$rootScope',
  '$scope',
  '$timeout',
  'ChallengesManager',
  'photoSwipe',
  'challengeService',
  'mixpanelService',
  'JoinedChallengesPageService',
  'MemberService'
];
export default {
  template: template,
  controller: challengesItemController,
  bindings: {
    challenge: '=',
    index: '<',
  },
};

