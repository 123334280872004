import ImageModel from '../api/models/image/image.model';
import ChatImageModel from '../api/models/chat/chatImage.model';
import {NgImageModel} from "../../../core/models/fromOldApp/ng-image.model";

/** v.3.2.1 (Latest version)
 *  vendors: LazyImg library (must move here)
 *  Auto responsive URL generator for Thumbor images
 **/
function gsImg(
  $timeout,
  $compile,
  LazyImgMagic,
  $rootScope
) {
  'ngInject';

  return {
    restrict: 'A',
    controller: gsImgCtrl,
    scope: {
      gsImg: '=',
      gsImgWatch: '<', // watch scope (false)
      gsImgLazy: '@', // lazy load plugin (true)
      gsImgSizeBy: '@', // "width|height|original|value" (width)
      gsImgSizeData: '@', // "<width>x<height>"
    },
    link: function(scope, element, attrs, gsImgCtrl) {
      // watch or generate
      scope.gsImgWatch ? scope.$watch('gsImg', generateImage, true) : generateImage();

      function generateImage() {
        if (!scope.gsImg) return;
        // get tag name
        let tagName = element[0].tagName;
        // init variables & objects
        let url = `${$rootScope.photoDomain}/unsafe/`;
        // get settings
        let lazy = scope.gsImgLazy ? scope.gsImgLazy === 'true' : true;
        if($rootScope.disableLazy){
          lazy = false;
        }
        let sizeBy = scope.gsImgSizeBy ? scope.gsImgSizeBy : 'width';
        sizeBy = scope.gsImgSizeData ? 'value' : sizeBy;
        let sizeData = scope.gsImgSizeData ? scope.gsImgSizeData : '0x0';

        // helpers
        function setImage(url) {
          // add alt property
          if (tagName === 'IMG') element.attr('alt', scope.gsImg.alt || '');

          // lazy load
          if (lazy) {
            element.attr('lazy-image', url);
            scope.lazyImage = new LazyImgMagic(element);
            scope.lazyImage.setErrorSource(attrs.lazyImgError);
            scope.lazyImage.setSource(url);
            let deregister = attrs.$observe('lazyImg', function(newSource) {
              if (newSource) {
                deregister();
                scope.lazyImage.setSource(newSource);
              }
            });

            let eventsDeregister = $rootScope.$on('lazyImg:refresh', function() {
              scope.lazyImage.checkImages();
            });
            scope.$on('$destroy', function() {
              scope.lazyImage.removeImage();
              eventsDeregister();
            });
            return true;
          }
          setTimeout(() => {
            // without lazy
            if (tagName === 'IMG') {
              element.attr('src', url);
            } else {
              element.attr('style', 'background-image:url(' + url + ')');
            }
          });
        }

        // image url
        let imageId = scope.gsImg.image_id || scope.gsImg.id;
        let memberId = scope.gsImg.member_id || (scope.gsImg.member ? scope.gsImg.member.id : undefined);

        // if not image model try to use url field
        if (!scope.gsImg.token &&
          (!(scope.gsImg instanceof ImageModel || scope.gsImg instanceof ChatImageModel|| scope.gsImg instanceof NgImageModel) || (!imageId && !memberId))) {
          // no url
          if (!scope.gsImg || !scope.gsImg.url) return true;
          url = scope.gsImg.url;
          setImage(url);
          return true;
        }

        // Prepare image
        let size;
        let height;
        let width;

        // Device pixel ratio
        let ratio = gsImgCtrl.getDevicePixelRatio();

        // Get width & height
        width = parseInt(element[0].getBoundingClientRect().width);
        height = parseInt(element[0].getBoundingClientRect().height);

        switch (sizeBy) {
          case 'value':
            url += sizeData + '/';
            break;
          case 'original':
            url += '0x0/';
            break;
          case 'width':
            // Fix width ratio
            size = gsImgCtrl.getSize(ratio > 1 ? parseInt(width * ratio) : width);
            // if crop
            url += size === height ? size + 'x' + size + '/' : size + 'x0/';
            break;
          case 'height':
            // Fix height ratio
            size = gsImgCtrl.getSize(ratio > 1 ? parseInt(height * ratio) : height);
            // if crop
            url += width === size ? size + 'x' + size + '/' : '0x' + size + '/';
            break;
          default:
            return false;
        }


        url += scope.gsImg.token ? scope.gsImg.token : (memberId + '/3_' + imageId + '.jpg');

        setImage(url);
      }
    },
  };
}

class gsImgCtrl {
  //Device Pixel ratio
  getDevicePixelRatio() {
    //Default
    let ratio = 1;
    // To account for zoom, change to use deviceXDPI instead of systemXDPI
    if (
      window.screen.systemXDPI !== undefined &&
      window.screen.logicalXDPI !== undefined &&
      window.screen.systemXDPI > window.screen.logicalXDPI
    ) {
      // Only allow for values > 1
      ratio = window.screen.systemXDPI / window.screen.logicalXDPI;
    } else if (window.devicePixelRatio !== undefined) {
      ratio = window.devicePixelRatio;
    }

    return ratio;
  }

  getSize(size){
    const sizeSteps =
      [200,600,1200,1600,2000]
    for(const step of sizeSteps){
      if(size <= step){
        return step;
      }
    }
    return 0; //original
  }
}

gsImg.$inject = [
  '$timeout',
  '$compile',
  'LazyImgMagic',
  '$rootScope'
];

export default gsImg;
