const template = require(`html-loader!./gsTable.html`).default;
import {resolveObjectPath} from "../../helpers/object.helper";
import tableEnum from "../../api/enums/table.enum";
import teamEnum from "../../api/enums/team.enum";

class gsTableCtrl {
  constructor(
    $scope,
    $rootScope,
    teamsService,
    stateService,
    RestManager,
    Modals,
    RouterService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.teamsService = teamsService;
    this.stateService = stateService;
    this.RestManager = RestManager;
    this.Modals = Modals;
    this.RouterService = RouterService;
    /*
    * https://stackoverflow.com/questions/18342917/angularjs-ng-model-doesnt-work-inside-ng-if
    *
    * Note, that if you want to modify the data in the parent scope, you'll need to modify the internal
    * properties of an object like in the last div that I added.
    *
    * */
    this.filterObject = {};

    this.resolveObjectPath = resolveObjectPath;
    this.tableEnum = tableEnum;
    this.teamEnum = teamEnum;
  }

  $onInit() {
    this.fieldsConfig = tableEnum.TABLE_TYPE[this.type];
  }

  /** @return void **/
  async assignAdmin(member, assign=true){
    await this.teamsService.assignAdmin({
      member : member,
      assign : assign,
      teamId : this.team.id,
      admin : true,
    });
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  tableRowClicked($event, entity) {
    if (($event.target.innerText.toLowerCase() !== 'duplicate') && this.editableStates) {
      let editParams = {
        id: entity.id,
        data: entity
      };
      this.RouterService.r.navigate([this.editableStates.edit], {state: editParams});
    }

    if($event.target.innerText.toLowerCase() === 'duplicate' && this.duplicate) {
      this.duplicate(entity);
    }
    // if(this.type === tableEnum.TABLE_TYPE.MATCH.NAME){
    //   this.onMatchItemClick({selectedMatch: entity});
    // }
  }

  onLinkClick(entity, fieldConfig){
    if(fieldConfig.additionalData && fieldConfig.additionalData.eventName){
      if(fieldConfig.additionalData.eventName() === tableEnum.EVENTS.MATCH_MEMBERS){
        let eventData = {
          match: entity,
          team: fieldConfig.value === 'This members' ? this.team : entity.other_team
        };
        this.$rootScope.$emit(fieldConfig.additionalData.eventName(), eventData);
      }
    }
  }

  async onAction(entity, fieldConfig){
    let res;
    switch (fieldConfig.ACTION_TYPE()){
      case tableEnum.ACTION_TYPE.CHALLENGE_TEMPLATE_EDIT:
        this.RouterService.r.navigate(['admin/contentManager/challenges/editChallengeTemplate'], {state: { template: entity }});
        break;
      case tableEnum.ACTION_TYPE.CHALLENGE_TEMPLATE_DUPLICATE:
        res  = await this.RestManager.setChallengeTemplate(
          entity.getParsed(false, `copy of ${entity.name}`)
        );
        if(!res.success){
          return;
        }
        this.goToChallengeTemplates();
        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }
        break;
      case tableEnum.ACTION_TYPE.CHALLENGE_TEMPLATE_DELETE:
        const confirm = await this.Modals.confirm(
          'Delete template',
          `Are you sure you sure you want to delete  ${entity.name.toUpperCase()} template?`,
          'Yes, I\'m sure',
          'Cancel'
        );
        if (!confirm) return;
        res  = await this.RestManager.deleteChallengeTemplate(entity.id);
        if(!res.success){
          return;
        }
        this.goToChallengeTemplates();
        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }
        break;
    }
  }

  goToChallengeTemplates(){
    this.stateService.goToState(
      'admin/contentManager/challenges/challengeTemplates',
      {},
      true
    );
  }
}

gsTableCtrl.$inject = [
  '$scope',
  '$rootScope',
  'teamsService',
  'stateService',
  'RestManager',
  'Modals',
  'RouterService'
];

export default {
  template: template,
  controller: gsTableCtrl,
  bindings: {
    type: '<',
    entities: '<',
    team: '<',
    // onMatchItemClick: '&',
    editableStates: '<',
    filter: '<',
    duplicate: '<'
  }
};
