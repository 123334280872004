import {Component, Input, OnInit} from '@angular/core';
import {IStoreItem} from "../../services/store.service.types";
import {StoreService} from "../../services/store.service";
import {IStorePackageSelectEventData, StoreEventOrigin, StoreEventsService} from "../../services/store-events.service";
import {SectionType} from "../../models/enums";
import {BankrollService} from "../../../bankroll/bankroll.service";
import {NgModalsService} from "../../../angular-material/services/ng-modals.service";
import {NgLocalStorageKeysEnum} from "../../../../core/models/enums";
import {NgLocalStorageService} from "../../../../core/services/ng-local-storage.service";
import {OutOfResourceData} from "../../../angular-material/models/types";

export enum OutOfResourceCloseModalType {
  MORE_OPTIONS = 'more_options',
}
@Component({
  selector: 'app-out-of-resource',
  templateUrl: './out-of-resource.component.html',
  styleUrls: ['./out-of-resource.component.scss'],
})
export class OutOfResourceComponent implements OnInit{
  @Input() data!: OutOfResourceData;
  OutOfResourceCloseModalType = OutOfResourceCloseModalType;

  constructor(
    private storeService: StoreService,
    private storeEventsService: StoreEventsService,
    private bankrollService: BankrollService,
    private ngModalsService: NgModalsService,
    private ngLocalStorageService: NgLocalStorageService
  ) {}

  ngOnInit(): void {
    this.ngLocalStorageService
      .saveMemberDataByKeyValue(NgLocalStorageKeysEnum.SHOW_OOC, false);
  }

  itemSelected(storeItem: IStoreItem): void {
    this.storeService.itemSectionType = this.data.sectionType;
    this.ngModalsService.closeAll();
    this.storeService.setPurchaseActive(storeItem, StoreEventOrigin.OOO_POPUP);
    this.ngModalsService.materialDialogOpenStore();
    const data:IStorePackageSelectEventData = {
      storeItem,
      current_balance: this.bankrollService.getBankrollItemAmount(
        SectionType.BATTLES,
        storeItem.received[0].type),
      origin:StoreEventOrigin.OOO_POPUP
    }
    this.storeEventsService.storePackageSelectEvent(data);
  }

}
