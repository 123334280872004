const eventLeaderBoardEnum = Object.freeze({
  STATUSES: {
    DRAFT: {
      NAME: 'draft',
      VALUE: 'draft'
    },
    UPCOMING: {
      NAME: 'upcoming',
      VALUE: 'upcoming'
    },
    ACTIVE: {
      NAME: 'active',
      VALUE: 'active'
    },
    CLOSED: {
      NAME: 'closed',
      VALUE: 'closed'
    },
  }
});

export default eventLeaderBoardEnum;
