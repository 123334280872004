const template = require(`html-loader!./myChallengesManageItemMobileDraft.html`).default;
import badgeV2Mode from "../../../../api/enums/badgeV2.enum";

class myChallengesManageItemMobileDraftController {
  constructor() {
    'ngInject';
    this.badgeV2Mode = badgeV2Mode;
  }
}

export default {
  template: template,
  controller: myChallengesManageItemMobileDraftController,
  bindings: {
    challenge: '<'
  }
};
