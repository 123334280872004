const template = require(`html-loader!./termsPage.html`).default;

class termsPageComponent {

  constructor(
    $scope,
    pageData,
    $window,
    tagEventService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.$scope = $scope;
    this.pageData = pageData;
    this.$window = $window;
    this.tagEventService = tagEventService;
    this.NgMetaDataService = NgMetaDataService;
  }

  $onInit() {
    this.NgMetaDataService.setTitle('Terms');
    // to top
    this.$window.scrollTo(0, 0);
    // init
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.pageData.get('terms');
    this.tagEventService.sendPageViewEvent({title: 'Terms page'});
  }
}
termsPageComponent.$inject = [
  '$scope',
  'pageData',
  '$window',
  'tagEventService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: termsPageComponent
};
