const template = require(`html-loader!./myChallengesRankLevels.html`).default;
import myChallengesEditorBase from '../myChallengesEditorBase';
import challengeEnum from "../../../../api/enums/challenge.enum";

class myChallengesRankLevelsCtrl extends myChallengesEditorBase{
  /** @param {ChallengesManager} ChallengesManager **/
  constructor(ChallengesManager, $scope, $element, Toasts, Modals) {
    'ngInject';
    super({$scope, $element, Toasts});

    this.busy = true;
    this.rankLevels = [];
    this.challengeEnum = challengeEnum;

    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.$element = $element;
    this.Toasts = Toasts;
    this.Modals = Modals;
  }

  $onInit() {
    this.get_data();
    //this.challenge.ranking_levels_id;
  }

  /** @return void **/
  async get_data() {
    const res = await this.ChallengesManager.getRankingLevels();
    if (!res.success) {
      return;
    }
    this.rankLevels = res.ranking_levels;
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async onRankingLevelsIdChanged(){
    console.log('onRankingLevelsIdChanged');
    if(this.originalChallenge && this.challenge.status === challengeEnum.STATUSES.ACTIVE.VALUE &&
      this.challenge.ranking_levels_id !== this.originalChallenge.ranking_levels_id){
      const confirm = await this.Modals.confirm(
        'are you sure you want to change ranking levels',
        'Changing ranking levels while challenge is running can effect the ranking',
        'i am sure',
        'cancel'
      );
      if (!confirm) {
        this.challenge.ranking_levels_id = this.originalChallenge.ranking_levels_id;
        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }
      }
    }
  }

  async save() {
    const res = await this.ChallengesManager.setRankingLevels(this.challenge.id, this.challenge.ranking_levels_id);
    this.afterSave(res);
  }
}
myChallengesRankLevelsCtrl.$inject = [
  'ChallengesManager', '$scope', '$element', 'Toasts', 'Modals'
];
export default {
  template: template,
  controller: myChallengesRankLevelsCtrl,
  bindings: {
    challenge: '<',
    originalChallenge: '<',
    saveEnabled: '@',
  }
};
