import ChatImageModel from "./chatImage.model";

class ChatMediaModel {
  constructor({
    images = []
  } = {}) {
    Object.assign(this, {
      images: images.map( image => new ChatImageModel(image))
    });
  }

  get image(){
    return this.images && this.images[0] ? this.images[0] : null;
  }
}

export default ChatMediaModel;
