const template = require(`html-loader!./gsCloseButton.html`).default;

class gsCloseButtonCtrl {
  constructor() {}
}

gsCloseButtonCtrl.$inject = [

];

export default {
  template: template,
  controller: gsCloseButtonCtrl,
  bindings: {}
};
