const template = require(`html-loader!./achievementInfo.html`).default;

class achievementInfoCtrl {
  constructor(
    achievementsService
  ) {
    'ngInject';

    this.achievementsService = achievementsService;
  }

  $onInit() {
    this.achievement = angular.copy(this.achievement);
    this.achievement.levels.unshift({level:0});
  }
}

achievementInfoCtrl.$inject = [
  'achievementsService'
];

export default {
  template: template,
  controller: achievementInfoCtrl,
  bindings: {
    achievement: '<'
  }
};
