import ImageModel from '../image/image.model';
import { parseFromSecToMilisec } from '../../../helpers/date.helper';

class VirtualExhibitionModel {
  constructor(props) {
    this.id = props.id;
    this.title = props.title;
    this.link = props.link;
    this.image = new ImageModel(props.image);
    this.image_id = props.image ? props.image.id : null;
    this.order = props.order ? parseInt(props.order) : props.order;
    this.create_date = parseFromSecToMilisec(props.create_date);
    this.update_date = props.update_date ? parseFromSecToMilisec(props.update_date) : null;
  }
}

export default VirtualExhibitionModel;
