/* globals FB, angular */
const template = require(`html-loader!./socialBar.html`).default;

class socialBarCtrl {
  constructor(
    $timeout,
    $element
  ) {
    'ngInject';

    this.$timeout = $timeout;
    this.$element = $element;
  }

  $onInit() {
    this.$timeout(()=>{
      // init facebook like
      if (typeof FB !== 'undefined' && typeof FB.XFBML !== 'undefined') {
        FB.XFBML.parse(this.$element[0]);
      }
    });
  }
}

socialBarCtrl.$inject = [
  '$timeout',
  '$element'
];

export default {
  template: template,
  controller: socialBarCtrl
};
