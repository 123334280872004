const template = require(`html-loader!./challengesWinners.html`).default;

class challengesWinnersCtrl {
  /** @param {ChallengesManager} ChallengesManager */

  constructor(ChallengesManager, $scope, Modals, Toasts, $element) {
    'ngInject';
    this.winnersList = [
      {label: 'Top Photographer', value: 'top-photographer'},
      {label: 'Top Photo', value: 'top-photo'},
      {label: 'Guru Top Pick', value: 'gurus-top-pick'}
    ];
    this.busy = false;
    this.type = 'top-photographer';
    this.challengeId;
    this.memberId;
    this.imageId;

    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.Modals = Modals;
    this.Toasts = Toasts;
    this.$element = $element;
  }

  async save() {
    if (this.busy || !this.type || !this.challengeId || !(this.memberId || this.imageId)) {
      return;
    }

    const confirm = await this.Modals.confirm(
      'Change challenge winner?',
      `Are you sure? You want to change winner in challenge ${this.challengeId}`,
      'Change',
      'Cancel'
    );
    if (!confirm) {
      return;
    }
    this.busy = true;

    let res;
    switch (this.type) {
      case 'top-photographer':
        res = await this.ChallengesManager.replaceTopPhotographer(this.challengeId, this.memberId);
        break;
      case 'top-photo':
        res = await this.ChallengesManager.replaceTopPhoto(this.challengeId, this.imageId);
        break;
      case 'gurus-top-pick':
        res = await this.ChallengesManager.replaceGuruTopPick(this.challengeId, this.imageId);
        break;
    }

    this.busy = false;

    let data = {
      message: res.success ? 'Done' : res.message,
      class: !res.success ? 'error' : ''
    };
    this.Toasts.open('simpleMessage', {data}, this.$element);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
challengesWinnersCtrl.$inject = [
  'ChallengesManager', '$scope', 'Modals', 'Toasts', '$element'
];
export default {
  template: template,
  controller: challengesWinnersCtrl,
  bindings: {}
};
