const template = require(`html-loader!./gsChallengeNewAchievementHistory.html`).default;
import badgeV2Mode from "../../../api/enums/badgeV2.enum";

class gsChallengeNewAchievementHistoryCtrl {
  constructor(
    $scope,
    MemberService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.badgeV2Mode = badgeV2Mode;
    this.MemberService = MemberService;
  }

  $onInit() {
    this.member = this.MemberService.getCurrentMember();
  }
}

gsChallengeNewAchievementHistoryCtrl.$inject = [
  '$scope',
  'MemberService'
];

export default {
  template: template,
  controller: gsChallengeNewAchievementHistoryCtrl,
  bindings: {
    challenge: '<',
    achievement: '<',
    lazy: '@'
  }
};
