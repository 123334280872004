const template = require(`html-loader!./challengesNotificationTimes.html`).default;
import moment from 'moment';

class challengesNotificationTimesCtrl {
  /** @param {ChallengesManager} ChallengesManager */

  constructor(ChallengesManager, $scope) {
    'ngInject';
    this.busy = true;
    this.items = [];
    this.filter;

    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
  }

  $onInit() {
    this.getData();
  }

  async getData() {
    const res = await this.ChallengesManager.getCountriesNotificationTimes();
    if (!res.success) return;
    this.items = res.countries.map(item => {
      item.time = moment(item.time, 'HH:mm:ss').hours();
      return item;
    });
    this.busy = false;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  async save(item) {
    if (!item.time || item.time < 0 || item.time > 24) return;
    const hours = moment(item.time, 'HH').format('HH:mm:ss');
    this.ChallengesManager.setCountriesNotificationTimes({
      country_code: item.country_code,
      time: hours,
    });
  }
}
challengesNotificationTimesCtrl.$inject = [
  'ChallengesManager', '$scope'
];
export default {
  template: template,
  controller: challengesNotificationTimesCtrl,
  bindings: {},
};
