import gsModalsService from './gsModals.service';
import gsModals from './gsModals/gsModals';
import vote from './vote/vote';

let moduleGsModals = angular.module('gsApp.gsModals', [])
    .service('gsModals', gsModalsService)
    .component('gsModals', gsModals)
    .component('modalVote', vote)
  ;

export default moduleGsModals.name;
