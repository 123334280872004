import {autoUpdateConf} from "./autoUpdate.service.data";

class autoUpdateService {
  constructor(
    $rootScope,
    $interval,
    idleService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$interval = $interval;
    this.idleService = idleService;
  }

  startAutoUpdateByType({
    type,
    callback,
    callbackParams,
    initCallbackOnStart,
    initCallbackOnStartParams,
    delay
  }){
    let conf = autoUpdateConf[type];
    if(!conf){
      return;
    }
    conf.delay = delay || conf.delay;
    if(initCallbackOnStart){
      callback(initCallbackOnStartParams ? initCallbackOnStartParams : callbackParams);
    }
    this.endAutoUpdateByType(type);
    conf.intervalReference = this.$interval(() => {
      if(!this.idleService.isIdle){
        callback(callbackParams);
      }else{
        // console.log(`${type} autoUpdate was stopped because of user idle`);
      }
    }, conf.delay);
    // console.log(`startAutoUpdateByType - ${type}, delay - ${(delay ? delay : conf.delay)}`);
  }

  endAutoUpdateByType(type){
    let conf = autoUpdateConf[type];
    if(!conf || !conf.intervalReference){
      return;
    }

    this.$interval.cancel(conf.intervalReference);
    conf.intervalReference = undefined;
  }

}
autoUpdateService.$inject = [
  '$rootScope',
  '$interval',
  'idleService'
];
export default autoUpdateService;
