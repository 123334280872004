import {INgImageModel} from "../../../core/models/fromOldApp/models";
import {NgMemberModel} from "../../../core/models/fromOldApp/ng-member.model";
import {NgImageModel} from "../../../core/models/fromOldApp/ng-image.model";
import {NgExposureModel} from "../../../core/models/fromOldApp/ng-exposure.model";
import {INgOldRankingModel, INgRankingTotal} from "../interfaces/challenges.interface";

export class NgRankingModel implements INgOldRankingModel {
  total?: INgRankingTotal;
  entries;
  member;
  swaps;
  exposure;

  constructor(props: INgOldRankingModel = {}) {
    Object.assign(this, props);
    props.entries && (this.entries = props.entries!.map((image: INgImageModel):INgImageModel => new NgImageModel(image)));
    props.swaps && (this.swaps = props.swaps!.map((image: INgImageModel): INgImageModel => new NgImageModel(image)));
    props.member && (this.member = new NgMemberModel(props.member));
    props.exposure && (this.exposure = new NgExposureModel(props.exposure));
    if(this.total){
      this.total.rankingLevel = {
        name: this.total.level_name,
        level: this.total.level,
        level_rank: this.total.level_rank,
      }
    }
  }
}
