import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-article-wrapper',
  templateUrl: './article-wrapper.component.html',
  styleUrl: './article-wrapper.component.scss'
})
export class ArticleWrapperComponent {
  public url = ''

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.params.pipe(
      takeUntilDestroyed()
    ).subscribe((params: any) => {
      this.url = params.url;
    })
  }
}
