import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatSelect, MatSelectChange} from '@angular/material/select';
import {fromEvent, Subscription} from "rxjs";

export interface SortOption <T = any>{
  viewValue: string;
  value: T;
  selected: boolean;
}

export interface ChallengesSortOrder <T = any>{
  sortOptions:SortOption<T>[];
  isAscendingOrder: boolean;
}

export interface ISortChange {
  challengesSortOrder:ChallengesSortOrder;
  isUserSelected: boolean;
}

@Component({
  selector: 'app-sort-order',
  templateUrl: './sort-order.component.html',
  styleUrl: './sort-order.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class SortOrderComponent implements OnInit, OnDestroy{
  @ViewChild(MatSelect) matSelect!: MatSelect;
  @Input() config!: ChallengesSortOrder;
  @Output() onSortChange = new EventEmitter<ISortChange>
  @Output() onOrderChange = new EventEmitter<ISortChange>
  selected?:string;
  scrollSubscription!: Subscription;

  ngOnInit(): void {
    console.log('sortOptions', this.config);
    for(let option of this.config.sortOptions){
      if(option.selected){
        this.selected = option.value;
        this.onSortChange.emit({
          challengesSortOrder: this.config,
          isUserSelected: false
        });
        break;
      }
    }

    this.scrollSubscription = fromEvent(window, 'scroll').subscribe(() => {
      if (this.matSelect.panelOpen) {
        this.matSelect.close();
      }
    });
  }

  orderClicked(){
    this.config.isAscendingOrder = !this.config.isAscendingOrder;
    this.onSortChange.emit({
      challengesSortOrder: this.config,
      isUserSelected: true
    });
  }

  filterChanged(event:MatSelectChange){
    for(let option of this.config.sortOptions){
      option.selected = (this.selected === option.value);
    }
    this.onSortChange.emit({
      challengesSortOrder: this.config,
      isUserSelected: true
    });
    console.log('selected', event.source.selected);
    console.log('selectedOption', event);
  }

  openSelect() {
    this.matSelect.open();
  }

  ngOnDestroy(): void {
    if (this.scrollSubscription) {
      this.scrollSubscription.unsubscribe();
    }
    console.log('ngOnDestroy', this.config);
  }

}
