export abstract class ArrayHelper {
  public static max(array:number[]){
    return Math.max(...array);
  }
  public static min(array:number[]){
    return Math.min(...array);
  }
  public static getUniqueIdArray(array:any[]){
    const uniqueIds = new Set();
    return array.filter((item) => {
      if (uniqueIds.has(item.id)) {
        return false;
      } else {
        uniqueIds.add(item.id);
        return true;
      }
    });
  }
}
