import MemberModel from '../member.model';
import ImageModel from '../image/image.model';

class SuggestFollowResponseModel {
  constructor({success, items = []}) {
    this.success = success;
    this.items = items.map((item) => {
      return {
        member: new MemberModel(item.member),
        images: item.images.map(image => new ImageModel(image))
      };
    });
  }
}

export default SuggestFollowResponseModel;
