import config from './landingPages.config';
import lp1 from './lp1/lp1';
import lpv2 from './lpv2/lpv2';
import lpMobile from './lpMobile/lpMobile';
import photoContest from './photoContest/photoContest';

let moduleLandingPages = angular.module('gsApp.landingPages', [])
  .config(config)
  .component('lp1', lp1)
  .component('lpv2', lpv2)
  .component('photoContest', photoContest)
  .component('lpMobile', lpMobile)
;

export default moduleLandingPages.name;
