import {Component, Input} from '@angular/core';
import {CompletePurchaseDataType} from "../../../angular-material/models/types";

@Component({
  selector: 'app-complete-purchase',
  templateUrl: './complete-purchase.component.html',
  styleUrls: ['./complete-purchase.component.scss']
})
export class CompletePurchaseComponent {
  @Input() data!: CompletePurchaseDataType;
}
