import {Subject, takeUntil, filter} from "rxjs";
import TeamStateModel from "../../../api/models/team/teamState.model";

const template = require(`html-loader!./teamsLock.html`).default;

class teamsPageCtrl {
  subject = new Subject();
  constructor(tagEventService, teamsService) {
    'ngInject';
    this.tagEventService = tagEventService;
    this.teamsService = teamsService;

    this.teamsService.getTeams$.pipe(
      filter(res => !!res),
      takeUntil(this.subject)
    ).subscribe(res => {
      this.state = new TeamStateModel(res.state);
    });
  }

  $onInit(){
    this.tagEventService.sendPageViewEvent({title: 'Teams - lock page'});
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
teamsPageCtrl.$inject = [
  'tagEventService', 'teamsService'
];
export default {
  template: template,
  controller: teamsPageCtrl
};
