class SlackManager {
  constructor($http) {
    'ngInject';
    this.$http = $http;
  }

  log(text) {
    this.$http({
      url: 'https://hooks.slack.com/services/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',
      method: "POST",
      data: 'payload='+JSON.stringify({
        "text": text,
        "mrkdwn": true
      }),
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'X-Requested-With': undefined,
        'Cache-Control': undefined,
        'Pragma': undefined
      }
    })
  }
}

SlackManager.$inject = [
  '$http'
];

export default SlackManager;
