import {filter, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./editDailyPrize.html`).default;

class editDailyPrizeCtrl {
  subject = new Subject();

  constructor(RestManager, RouterService) {
    'ngInject';
    this.types = ["KEY", "PHOTO_SWAP", "EXPOSURE_AUTOFILL"];

    this.RestManager = RestManager;
    this.RouterService = RouterService;

    this.RouterService.activatedRoute
      .queryParams.pipe(
      filter(params => params.spotlight),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      this.prize = params.prize || {};
      if (!this.prize.id){
        this.prize.type = this.types[0];
      }
    });
  }

  async set() {
    if(this.validate()) {
      // let data = {
      //   percent: this.prize.percent,
      //   quantity: this.prize.quantity,
      //   type: this.prize.type,
      // }
      // if (this.prize.id) data.id = this.prize.id;

      let res = await this.RestManager.setDailyPrize(this.prize);
      this.handleResponse(res);
    }
  }

  async del() {
    let res = await this.RestManager.delDailyPrize(this.prize.id);
    this.handleResponse(res);
  }

  handleResponse(res) {
    if (!res.success) {
      console.log(res);
      return;
    }
    this.RouterService.go('admin/contentManager/dailyRewards/prizes');
  }

  validate(){
    this.errors = [];
    let required_fields = ['percent', 'quantity', 'type'];

    for (let required_field of required_fields) {
      if(!this.prize[required_field] && this.prize[required_field] !== 0){
        this.errors.push(`${required_field}  need to be defined`);
        return false;
      }
    }

    if(!this.testNumber(this.prize.percent) || this.prize.percent < 0 || this.prize.percent> 100){
      this.errors.push("percent not valid");
      return false;
    }

    if(!this.testNumber(this.prize.quantity) || this.prize.quantity < 0){
      this.errors.push("quantity not valid");
      return false;
    }
    return true;
  }

  testNumber(value){
    return Number(value) || value === 0;
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
editDailyPrizeCtrl.$inject = [
  'RestManager', 'RouterService'
];
export default {
  template: template,
  controller: editDailyPrizeCtrl
};
