import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-teams-leagues-next-wrapper',
  templateUrl: './admin-content-manager-teams-leagues-next-wrapper.component.html',
  styleUrl: './admin-content-manager-teams-leagues-next-wrapper.component.scss'
})
export class AdminContentManagerTeamsLeaguesNextWrapperComponent {

}
