const template = require(`html-loader!./leaderboardPage.html`).default;
import {LeagueMemberData} from "../../../api/models/team/leagueData.model";
import leaderBoardEnum from "../../../api/enums/leaderBoard.enum";
import leagueEnum from "../../../api/enums/league.enum";
import brazeEnum from "../../../api/enums/braze.enum";
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";

class leaderboardPageCtrl {

  constructor(
    $scope,
    $rootScope,
    RestManager,
    leaderboardService,
    stateService,
    Modals,
    brazeService,
    mixpanelService,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.stateService = stateService;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.leaderboardService = leaderboardService;
    this.Modals = Modals;
    this.brazeService = brazeService;
    this.mixpanelService = mixpanelService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;

    this.leaderBoardEnum = leaderBoardEnum;
    this.leagueEnum = leagueEnum;

    this.scrollClass = '.leaderboard-page__active__content';
    this.init();
  }

  async init() {
    this.NgMetaDataService.setTitle('Leagues');

    this.member = this.MemberService.getCurrentMember();
    if (this.member?.logged_in === false) {
      this.stateService.goToHomePage();
      return;
    }

    if (this.brazeService.getFilteredContentCard({filterConfig: brazeEnum.CONTENT_CARDS.LEAGUES})) {
      this.Modals.open('captionedImage', null, brazeEnum.CONTENT_CARDS.LEAGUES);
    }

    await this.getMemberLeagueData();

    if (!this.featureMessage &&
      !this.leaderboardService.isSeasonStatusPending(this.leagueData) &&
      this.leaderboardService.isLeagueStatusActive(this.leagueData)
    ) {
      this.eventMixPanelAction();
    }

    $(this.scrollClass).on('scroll', this.onScroll.bind(this));
  }

  async getMemberLeagueData() {
    if (!this.busy) {
      this.busy = true;
      const res = await this.RestManager.getMemberLeagueData();
      if (!res.success) {
        this.stateService.goToDefaultState();
        return;
      }
      this.leagueData = new LeagueMemberData(res);
      this.featureMessage = this.leagueData.featureMessage;

      this.myMember = this.leagueData.myItem;
      if(this.leagueData.league && this.leagueData.league.name){
        this.leagueConf = leagueEnum.TYPE[this.leagueData.league.name.toUpperCase()];
      }
      this.busy = false;
      // this.setInfoFields();
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  registerFloatingItemHandler(handler){
    this.checkfloatingItemHandler = handler;
  }

  onScroll(e) {
    this.checkfloatingItemHandler();
  }

  eventMixPanelAction() {
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.INDIVIDUAL_LEAGUE,
      data: {
        leagues_state: mixpanelEventsEnum.LEAGUES_STATE.ACTIVE_IN_LEAGUE,
        ui_category: mixpanelEventsEnum.UI_CATEGORY.SCREEN,
        ui_action: mixpanelEventsEnum.UI_ACTION.IMPRESSION,
        league_id: this.leagueData.league.id
      }
    });
  }
}
leaderboardPageCtrl.$inject = [
  '$scope',
  '$rootScope',
  'RestManager',
  'leaderboardService',
  'stateService',
  'Modals',
  'brazeService',
  'mixpanelService',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template,
  controller: leaderboardPageCtrl,
};
