import LoaderEnum from "../../../api/enums/loader.enum";

const template = require(`html-loader!./modalBlockedUser.html`).default;

class modalBlockedUser {

  constructor(
    BlockedUserService,
    $rootScope,
    $mdDialog
  ) {
    'ngInject';

    this.blockedUserService = BlockedUserService;
    this.$rootScope = $rootScope;
    this.$mdDialog = $mdDialog;
    this.LoaderEnum = LoaderEnum;

    this.blockedUserListData = [];
    this.isLoader = true;

    this.isLoadMoreEvent = true;

    this.$rootScope.$on('infinityScrollLoadMore', this.loadMoreEvent.bind(this));
  }

  $onInit() {
    this.init();
  }

  $onDestroy() {
    this.blockedUserListData = [];
    this.blockedUserService.setPaginationToDefault();
  }

  loadMoreEvent() {
    if(this.isLoadMoreEvent) {
      this.isLoadMoreEvent = false; /* To prevent send a lot of request to server */
      this.isLoader = true;

      this.loadData();
    }
  }

  async loadData() {
    const blockedUserListData = await this.getBlockedUserList();

    if (blockedUserListData.length >= this.blockedUserService.paginationLimitDefault) {
      this.blockedUserService.setPaginationStepForward();
      this.isLoadMoreEvent = true;
    } else {
      this.isLoadMoreEvent = false;
    }

    this.blockedUserListData = [...this.blockedUserListData, ...blockedUserListData];

    this.isLoader = false;

    if (!this.$rootScope.$$phase) {
      this.$rootScope.$digest();
    }
  }

  async getBlockedUserList() {
    return this.blockedUserService.getBlockedUserList();
  }

  async init() {
    this.loadData();
  }

  close() {
    this.$mdDialog.hide();
  }
}
modalBlockedUser.$inject = [
  'BlockedUserService',
  '$rootScope',
  '$mdDialog'
];
export default {
  template: template,
  controller: modalBlockedUser
};
