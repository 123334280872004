import memberEnum from '../../api/enums/member.enum';

class ModalsService {

  constructor(
    $mdDialog,
    modalsSettings,
    $timeout
  ) {
    'ngInject';

    this.memberEnum = memberEnum;
    this.$mdDialog = $mdDialog;
    this.modalsSettings = modalsSettings;
    this.$timeout = $timeout;

    // previous modal params
    this.previousParams = null;

    this.subscribe();
  }

  close(data = null){
    this.$mdDialog.hide(data);
  }

  open(name, event = null, data = {}) {
    if (typeof this.modalsSettings[name] === 'undefined') {
      window.console.log(' %c Modal%c not exist!', 'color:tomato', 'color:#ccc');
      return false;
    }

    // interceptors
    // switch (name) {
    //   case 'preSubmit':
    //   case 'gsUploader':
    //     if (data.challenge && data.challenge.member && data.challenge.member.submit_state === memberEnum.SUBMIT_STATES.JOIN_UNLOCK) {
    //       data.requestedName = name;
    //       name = 'challengeUnlock';
    //     }
    //     break;
    // }

    // settings
    let settings = angular.copy(this.modalsSettings[name]);
    settings.targetEvent = event;
    settings.locals = { data };
    settings.onComplete = data.onComplete ? data.onComplete : () => {};

    // prepare templateUrl for broadcast
    switch (name) {
      case 'broadcast':
      case 'intro':
      case 'introMobile':
        if (!settings.templates[data.value]) {
          window.console.log('Modals service:', 'template not exist!');
          return false;
        }

        settings.template = settings.templates[data.value];

        break;
    }

    // protect to show same modal again if modal is open
    let params = { name, data };
    let isOpen = $(document.body).hasClass('md-dialog-is-showing');
    if (isOpen && angular.equals(params, this.previousParams)) {
      window.console.log('%c Modal%c already is open!', 'color:tomato', 'color:#ccc');
      return true;
    }
    this.previousParams = params;

    // protect to show same modal again by name if modal is open
    let modalNames = ['lbEvent'];
    if (isOpen && modalNames.includes(name)) {
      window.console.log('%c Modal%c already is open!', 'color:tomato', 'color:#ccc');
      return true;
    }

    // show
    this.$mdDialog.hide();
    return this.$mdDialog.show(settings).catch(() => false);
  }

  confirm(title = 'Confirm dialog', textContent = '', ok = 'Close', cancel = 'Back') {
    let confirm = this.$mdDialog
      .confirm()
      .title(title)
      .textContent(textContent)
      .ariaLabel(title)
      .ok(ok)
      .cancel(cancel);
    return this.$mdDialog.show(confirm).catch(() => false);
  }

  confirmHtmlContent(title = 'Confirm dialog', textContent = '', ok = 'Close', cancel = 'Back') {
    let confirm = this.$mdDialog
      .confirm()
      .title(title)
      .htmlContent(textContent)
      .ariaLabel(title)
      .ok(ok)
      .cancel(cancel);
    return this.$mdDialog.show(confirm).catch(() => false);
  }

  subscribe() {
    $(window).on('gsAppModalOpen', (event, name, data = {}) => this.open(name, event, data));
  }
}
ModalsService.$inject = [
  '$mdDialog',
  'modalsSettings',
  '$timeout'
];
export default ModalsService;
