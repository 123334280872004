import {Subject, takeUntil, filter} from "rxjs";
const template = require(`html-loader!./rules.html`).default;

class challengeRulesCtrl {
  subject = new Subject();
  constructor($location, tagEventService, NgChallengesService) {
    "ngInject";

    this.$location = $location;
    this.tagEventService = tagEventService;
    this.NgChallengesService = NgChallengesService;

    this.NgChallengesService.currentChallenge$.pipe(
      filter(challenge => challenge !== undefined),
      takeUntil(this.subject)
    ).subscribe(async (challenge) => {
      this.challenge = challenge;
      this.rules = this.challenge.getRules();
    });
  }

  $onInit() {
    this.tagEventService.sendPageViewEvent({title: 'Challenge Rules'});
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
challengeRulesCtrl.$inject = [
  '$location', 'tagEventService', 'NgChallengesService'
];
export default {
  template: template,
  controller: challengeRulesCtrl
};
