const template = require(`html-loader!./teamMembers.html`).default;
import teamEnum from '../../../api/enums/team.enum';

class teamMembersCtrl {

  constructor($scope, $timeout, stateService, $location, Modals, RestManager, notificationAlert, teamsService) {
    'ngInject';
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.stateService = stateService;
    this.$location = $location;
    this.teamEnum = teamEnum;
    this.Modals = Modals;
    this.RestManager = RestManager;
    this.notificationAlert = notificationAlert;
    this.teamsService = teamsService;
  }

  $onInit() {}

  async remove(memberToRemove){
    const confirm = await this.Modals.confirm(
      '',
      `Are you sure you sure you want to remove  ${memberToRemove.name.toUpperCase()} from the Team?`,
      'Yes, I\'m sure',
      'Cancel'
    );
    if (!confirm) return;
    const res = await this.RestManager.removeMember(memberToRemove.id);
    const resObj = this.teamsService.handleTeamsResponse(res);
    if(!resObj.isValid){
      return;
    }
    const memberIndex = this.team.members.findIndex( member => member.id === memberToRemove.id);
    this.team.members.splice(memberIndex, 1);
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async assignAdmin(member, assign=true){
    await this.teamsService.assignAdmin({
      member : member,
      assign : assign,
      teamId : this.team.id,
    });
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
teamMembersCtrl.$inject = [
  '$scope', '$timeout', 'stateService', '$location', 'Modals', 'RestManager', 'notificationAlert', 'teamsService'
];

export default {
  template: template,
  controller: teamMembersCtrl,
  bindings: {
    team: '<',
  }
};
