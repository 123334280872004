import CardModel from './card.model';

class AdminCardModel extends CardModel {
  constructor({
    id,
    priority,
    type,
    data = {},
    member_id,
    c_id,
    join_days = 0,
    image_id,
    reason_text,
    old_image_id,
    new_image_id,
    popup_type,
  }) {
    super({ id, priority, type, data });
    Object.assign(this, { member_id, c_id, join_days, image_id, reason_text, old_image_id, new_image_id, popup_type });
  }
}

export default AdminCardModel;
