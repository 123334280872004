import { Component } from '@angular/core';
import {MemberService} from "../../core/services/member.service";
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-teams-info-wrapper',
  templateUrl: './teams-info-wrapper.component.html',
  styleUrl: './teams-info-wrapper.component.scss'
})
export class TeamsInfoWrapperComponent {
  teamId:string = '';
  constructor(
    private route: ActivatedRoute,
    public memberService: MemberService,
  ) {
    this.route.params.pipe(
      takeUntilDestroyed()
    ).subscribe(async (params: any) => {
      this.teamId = params.teamId;
    });
  }
}
