import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

class challengesListResponseModel {
  constructor({ success = false, challenges = [] }) {
    this.success = success;
    this.challenges = challenges.map(challenge => new NgChallengeModel(challenge));
  }
}

export default challengesListResponseModel;
