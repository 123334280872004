const template = require(`html-loader!./challengesPlannedTimings.html`).default;

import challengeEnum from "../../../../../api/enums/challenge.enum";

class ChallengesPlannedTimings {

  constructor(
    $scope,
    ChallengesManager,
    $rootScope,
    stateService
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.ChallengesManager = ChallengesManager;
    this.stateService = stateService;

    this.filter_data = [
      {
        NAME: 'Start time',
        VALUE: 'start_time'
      },
      {
        NAME: 'End time',
        VALUE: 'end_time'
      },
      {
        NAME: 'Start voting time',
        VALUE: 'start_voting_time'
      }
    ];
    this.filterSelected = [];
    this.filterHeaderText = '';
    this.all_challenges = [];
    this.customFilter = this.customFilter.bind(this);
  }

  //region Checkboxes + customFilter
  /* TODO: Refactoring: put to separated component */
  customFilter(item) {
    let res = false;

    if (this.filterSelected.length) {
      /**
       * Explanation:
       *
       * find will return (filter.VALUE === item.type) ? object : undefined
       *
       * */
      res = this.filterSelected.find((filter) => filter.VALUE === item.type);
    }

    return res;
  }

  toggle(item, list) {
    const index = list.indexOf(item);
    if (index > -1) {
      list.splice(index, 1);
    }
    else {
      list.push(item);
    }

    this.changeFilterHeaderText();
  }

  exists(item, list) {
    return list.indexOf(item) > -1;
  }

  isIndeterminate() {
    return (this.filterSelected.length !== 0 &&
      this.filterSelected.length !== this.filter_data.length);
  }

  isChecked() {
    return this.filterSelected.length === this.filter_data.length;
  }

  toggleAll() {
    if (this.filterSelected.length === this.filter_data.length) {
      this.filterSelected = [];
      this.changeFilterHeaderText();
    } else if (this.filterSelected.length === 0 || this.filterSelected.length > 0) {
      this.selectAll();
    }
  }

  selectAll() {
    this.filterSelected = this.filter_data.slice(0);
    this.changeFilterHeaderText();
  }

  changeFilterHeaderText() {
    let text = '';
    const selectedLength = this.filterSelected.length;

    if (selectedLength === this.filter_data.length) {
      text = 'All selected';
    } else if (selectedLength === 0) {
      text = 'Select any option';
    } else {
      text = 'Selected by:';

      this.filterSelected.forEach((item) => {
        text += ` ${item.NAME}`;
      });
    }

    this.filterHeaderText = text;
  }
  //endregion

  $onInit() {
    this.getData();
    this.selectAll();
  }

  async getData() {
    const resArr = await Promise.all([
      this.ChallengesManager.listChallenges({
        status: challengeEnum.STATUSES.ACTIVE.VALUE,
        start: 0,
        limit: 1000,
        order: 'DESC',
      }),
      this.ChallengesManager.listChallenges({
        status: challengeEnum.STATUSES.UPCOMING.VALUE,
        start: 0,
        limit: 1000,
        order: 'DESC',
      })
    ]);
    const success = resArr.every(r => r.success);

    if (!success) {
      //need to delete challenge
      return;
    }

    const challenges = [...resArr[0].challenges, ...resArr[1].challenges];

    challenges.forEach((item) => {
      const start_time_data = {
        date: item.start_time,
        id: item.id,
        event: 'Start time',
        title: item.title,
        url: item.url,
        type: 'start_time'
      };
      const start_voting_time_data = {
        date: item.challengeStartVotingTime,
        id: item.id,
        event: 'Voting time',
        title: item.title,
        url: item.url,
        type: 'start_voting_time'
      };
      const end_time_data = {
        date: item.close_time,
        id: item.id,
        event: 'End time',
        title: item.title,
        url: item.url,
        type: 'end_time'
      };

      this.all_challenges.push(start_time_data);
      this.all_challenges.push(start_voting_time_data);
      this.all_challenges.push(end_time_data);
    });

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  openFilter($mdMenu, ev) {
    $mdMenu.open(ev);
  }

  onFilterChange() {
    if (!this.$rootScope.$$phase) {
      this.$scope.$apply();
    }
  }

  onChallengeClicked(challenge){
    this.stateService.goToStateNewTab(`challenge/${challenge.url}/details`, {});
  }
}
ChallengesPlannedTimings.$inject = [
  '$scope',
  'ChallengesManager',
  '$rootScope',
  'stateService'
];
export default {
  template: template,
  controller: ChallengesPlannedTimings,
  bindings: {}
};
