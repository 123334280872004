export enum StoreLabelType  {
  BEST_OFFER = 'BEST_OFFER'
}
export enum StoreCurrencyType {
  USD = 'USD',
  COINS = 'COINS'
}
export enum Currency {
  CURRENCY = 'CURRENCY',
  HARD_CURRENCY = 'HARD_CURRENCY',
}
export enum SectionType {
  CHALLENGES = 'CHALLENGES',
  BATTLES = 'BATTLES'
}
export enum CloseModalType {
  X_BUTTON = 'x_button',
  BUY_WITH_COINS = 'BUY_WITH_COINS',
}
export enum StoreResourceType {
  BUNDLES= 'BUNDLES'
}

