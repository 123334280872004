import ImageModel from '../models/image/image.model';

class gsAdminManager {
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  set(params) {
    return this.api['gsAdmin']['set'](params).catch(() => {
      return { success: false };
    });
  }

  getExhibitionList(type, c_id) {
    return this.api['rest']
      ['getExhibitionList']({
        c_id: c_id,
        start: 0,
        limit: 10000,
        filter: `selected_expo_${type}`,
      })
      .then(res => {
        if (res.items) res.items = res.items.map(image => new ImageModel(image));
        return res;
      });
  }
}

gsAdminManager.$inject = [
  'api'
];

export default gsAdminManager;
