const NgRequestEnum = Object.freeze({
  PROTOCOL:'https',
  CONTROLLER:{
    REST:'rest'
  },
  END_POINTS: {
    GET_MOCK_DATA : {
      NAME: 'get_mock_data',
    },
    GET_MEMBER_BATTLE_STATUS : {
      NAME: 'get_member_battle_status',
    },
    GET_BATTLE_VOTING : {
      NAME: 'get_battle_voting',
    },
    GET_WAITING_ROOM_STATUS : {
      NAME: 'get_waiting_room_status',
    },
    GET_LATEST_ACHIEVEMENTS : {
      NAME: 'get_latest_achievements',
    },
    GET_PAGE_DATA : {
      NAME: 'get_page_data'
    },
    GET_DISCOVER_THEMES: {
      NAME: 'get_discover_themes'
    },
    GET_MY_BATTLE: {
      NAME: 'get_my_battle'
    },
    GET_MY_BATTLE_RANKING: {
      NAME: 'get_my_battle_ranking'
    },
    LEAVE_THEME: {
      NAME: 'leave_theme'
    },
    RESET_MEMBER_STATUS: {
      NAME: 'reset_member_status'
    },
    GET_MY_COMPLETED_BATTLE: {
      NAME: 'get_my_completed_battle'
    },
    GET_CURRENT_SPACE: {
      NAME: 'get_current_space'
    },
    GET_SPACE: {
      NAME: 'get_space'
    },
    GET_VISITOR_BONUS_STATUS: {
      NAME: 'get_visitor_bonus_status'
    },
    HANG_IMAGE: {
      NAME: 'hang_image'
    },
    CLAIM_VISITOR_BONUS: {
      NAME: 'claim_visitor_bonus'
    },
    GET_MAP : {
      NAME: 'get_map'
    },
    GET_TEAMS_CONFIG: {
      NAME: 'get_teams_config'
    },
    GET_BATTLES_CONFIG: {
      NAME: 'get_battles_config'
    },
    GET_BATTLE_ACTIONS: {
      NAME: 'get_battle_actions'
    },
    GET_EXPOSURE_FORMULA: {
      NAME: 'get_exposure_formula'
    },
    SUBMIT_1V1_VOTING: {
      NAME: 'submit_1v1_voting'
    },
    SUBMIT_VOTING_SKIP: {
      NAME: 'submit_voting_skip'
    },
    SWITCH_BATTLE_IMAGE: {
      NAME: 'switch_battle_image'
    },
    ACTIVATE_BATTLE_TURBO: {
      NAME: 'activate_battle_turbo'
    },
    FREEZE_BATTLE_MEMBER: {
      NAME: 'freeze_battle_member'
    },
    ACTIVATE_BATTLE_SHIELD: {
      NAME: 'activate_battle_shield'
    },
    GET_STORE: {
      NAME: 'get_store'
    },
    GET_PACKAGE: {
      NAME: 'get_package'
    },
    GET_BANKROLL: {
      NAME: 'get_bankroll'
    },
    GET_SUGGESTED_STORE_ITEMS: {
      NAME: 'get_suggested_store_items'
    },
    GET_PAYMENT_CONFIG: {
      NAME: 'get_payment_config'
    },
    PAY_CC: {
      NAME: 'pay_cc'
    },
    PAY: {
      NAME: 'pay'
    },
    PAYPAL_CREATE: {
      NAME: 'paypal_create'
    },
    PAYPAL_EXECUTE: {
      NAME: 'paypal_execute'
    },
    PAY_STORE_ITEM: {
      NAME: 'pay_store_item'
    },
    GET_BATTLE_STREAK: {
      NAME: 'get_battle_streak'
    },
    SHOW_ADS: {
      NAME: 'show_ads'
    },
    END_TRAINING_BATTLE: {
      NAME: 'end_training_battle'
    },
    CLAIM_DAILY_REWARD_NEW: {
      NAME: 'claim_daily_reward_new'
    },
    IS_DAILY_REWARD_AVAILABLE: {
      NAME: 'is_daily_reward_available'
    },
    GET_CHALLENGE_TURBO: {
      NAME: 'get_challenge_turbo'
    },
    SUBMIT_CHALLENGE_TURBO_SELECTION: {
      NAME: 'submit_challenge_turbo_selection'
    },
    SET_CHALLENGE_TURBO: {
      NAME: 'set_challenge_turbo'
    },
    GET_SESSION_CONFIGURATION: {
      NAME: 'get_session_configuration'
    },
    GET_CHALLENGE: {
      NAME: 'get_challenge'
    },
    GET_MY_ACTIVE_CHALLENGES: {
      NAME: 'get_my_active_challenges'
    },
    GET_MY_ACTIVE_CHALLENGE: {
      NAME: 'get_my_active_challenge'
    },
    GET_MY_ACTIVE_CHALLENGES_UPDATE: {
      NAME: 'get_my_active_challenges_update'
    },
    GET_MEMBER_CHALLENGES: {
      NAME: 'get_member_challenges'
    },
    IS_NEW_COMPLETED_CHALLENGES: {
      NAME: 'is_new_completed_challenges'
    },
    GET_MY_COMPLETED_CHALLENGES: {
      NAME: 'get_my_completed_challenges'
    },
    SET_WATCHED_COMPLETED_CHALLENGE: {
      NAME: 'set_watched_completed_challenge'
    },
    GET_CLOSED_CHALLENGES: {
      NAME: 'get_closed_challenges'
    },
    GET_CHALLENGE_PRIZES: {
      NAME: 'get_challenge_prizes'
    },
    GET_WINNERS_ACHIEVEMENTS_AND_PRIZES: {
      NAME: 'get_winners_achievements_and_prizes'
      // NAME: 'get_winners_and_prizes'
    },
    FOLLOW: {
      NAME: 'follow'
    },
    UNFOLLOW: {
      NAME: 'unfollow'
    },
    UPDATE_MEMBER_SETTINGS: {
      NAME: 'update_member_settings'
    },
    GET_FLASH_RANKING: {
      NAME: 'get_flash_ranking'
    },
    COINS_UNLOCK: {
      NAME: 'coins_unlock'
    },
    GET_CURRENT_MEMBER_PROFILE: {
      NAME: 'get_current_member_profile'
    },
    GET_PROFILE_BY_USER_NAME: {
      NAME: 'get_profile_by_user_name'
    },
    GET_MEMBER_PATH: {
      NAME: 'get_member_path'
    },
    GET_PROFILE_SETTINGS: {
      NAME: 'get_profile_settings'
    },
    GET_EXHIBITION_PAGE_ITEMS: {
      NAME: 'get_exhibition_page_items'
    },
    GET_MY_MISSIONS: {
      NAME: 'get_my_missions'
    },
    CLAIM_MISSION_PRIZES: {
      NAME: 'claim_mission_prizes'
    }
  }
});

export default NgRequestEnum;
