import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";
const template = require(`html-loader!./gsChallenge.html`).default;
import badgeV2Mode from "../../api/enums/badgeV2.enum";
import memberEnum from '../../api/enums/member.enum';
import challengeEnum from '../../api/enums/challenge.enum';
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";
import {NgModalsService} from "../../../../modules/angular-material/services/ng-modals.service";
import {ResourceType} from "../../../../core/models/enums";

class gsChallengeCtrl {
  challenge;
  constructor(
    Modals,
    gsModals,
    $filter,
    stateService,
    $scope,
    $rootScope,
    mixpanelService,
    challengeService,
    NgModalsService,
    NgChallengesService,
    RouterService
  ) {
    'ngInject';
    this.Modals = Modals;
    this.gsModals = gsModals;
    this.$filter = $filter;
    this.stateService = stateService;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.badgeV2Mode = badgeV2Mode;
    this.mixpanelService = mixpanelService;
    this.challengeService = challengeService;
    this.NgChallengesService = NgChallengesService;
    this.mixpanelEventsEnum = mixpanelEventsEnum;
    this.memberEnum = memberEnum;
    this.challengeEnum = challengeEnum;
    this.NgModalsService = NgModalsService;
    this.isJoinButtonDisabled = false;
    this.ResourceType = ResourceType;
    this.RouterService = RouterService;
  }

  $onInit() {
    this.done = this.challenge instanceof NgChallengeModel;

    if(this.challenge && this.type === 'match'){
      if(!this.subtype || this.subtype === 'all'){
        this.subtype = [memberEnum.SUBMIT_STATES.JOIN, memberEnum.SUBMIT_STATES.JOIN_UNLOCK].includes(this.challenge.member.submit_state) ? 'join' : 'view';
      }
    }
  }

  async preSubmit() {
    this.NgChallengesService.sendJoinChallengeMixpanelEvent({
        challenge :this.challenge,
        ui_name:mixpanelEventsEnum.UI_NAME.DISCOVER,
        ui_action:mixpanelEventsEnum.UI_ACTION.CLICK
      }
    );

    this.isJoinButtonDisabled = true;
    await this.challengeService.openPreSubmit(this.challenge);
    this.isJoinButtonDisabled = false;
  }

  viewDetails(){
    this.mixpanelService.track(
      mixpanelEventsEnum.EVENT_NAME.WEB_CLIENT_EVENTS,
      {
        ui_name:this.mixpanelEventsEnum.UI_NAME.VIEW_DETAILS_BUTTON,
        ui_category:this.mixpanelEventsEnum.UI_CATEGORY.UPCOMING_CHALLENGES,
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK
      });
  }

  vote() {
    this.gsModals.open('vote', {challenge: this.challenge});
  }

  challengeLocked(event) {
    this.Modals.open('challengeLocked', event, {challenge: this.challenge});
  }

  async startMatch(event){
    await this.Modals.open('startMatch', event, {challenge: this.challenge, teamId:this.team.id});
    this.$rootScope.$emit('onMatchChanged');
  }

  offtopiClicked(){
    // this.stateService.goToState('moderation/offtopicImages', {
    //   offtopicData:this.offtopicData
    // });
    this.RouterService.r.navigate(['moderation/offtopicImages'], {state: {offtopicData: this.offtopicData}});
  }
}

gsChallengeCtrl.$inject = [
  'Modals',
  'gsModals',
  '$filter',
  'stateService',
  '$scope',
  '$rootScope',
  'mixpanelService',
  'challengeService',
  'NgModalsService',
  'NgChallengesService',
  'RouterService'
];

export default {
  template: template,
  controller: gsChallengeCtrl,
  bindings: {
    challenge: '<data',
    type: '@',
    subtype: '<',
    team: '<',
    offtopicData: '<'
  },
};
