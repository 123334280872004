import { Component } from '@angular/core';

@Component({
  selector: 'app-teams-lock-wrapper',
  templateUrl: './teams-lock-wrapper.component.html',
  styleUrl: './teams-lock-wrapper.component.scss'
})
export class TeamsLockWrapperComponent {

}
