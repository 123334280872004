import PhotosResponseModel from './models/image/photosResponse.model';
import RankingResponseModel from './models/image/rankingResponse.model';
import FollowersResponseModel from './models/follower/followersResponse.model';
import SuggestFollowResponseModel from './models/follower/suggestFollowResponse.model';
import challengesListResponseModel from './models/challenge/challengesListResponse.model';
import CustomResponseModel from './models/customResponse.model';
import ClosedChallengeNewResponseModel from './models/closedChallengeNewResponse.model';
import ArticlesListResponseModel from './models/articles/articlesListResponse.model';
import ExhibitionResponseModel from './models/exhibition/exhibitionResponse.model';
import PageResponseModel from './models/pageResponse.model';
import restEnum from "./enums/rest.enum";
import {toCamelCaseFromUnderline} from "../helpers/string.helper";

function config(apiProvider, CacheFactoryProvider) {
  'ngInject';

  const METHOD = 'POST';
  apiProvider.enableHttpParamSerializerJQLikeMode();
  // cache
  angular.extend(CacheFactoryProvider.defaults, {
    maxAge: 15 * 60 * 1000, // 15 min
    storageMode: 'localStorage'
  });

  // response with model
  apiProvider
    .endpoint('photos')
    .route('rest/:action')
    .model(PhotosResponseModel)
    .addHttpAction(METHOD, 'getProfilePhotos', {params: {action: 'get_photos_profile'}})
    .addHttpAction(METHOD, 'getProfileLikes', {params: {action: 'get_likes_profile'}})
    .addHttpAction(METHOD, 'deleteProfileImage', {params: {action: 'delete_profile_image'}});

  apiProvider
    .endpoint('followers')
    .route('rest/:action')
    .model(FollowersResponseModel)
    //.addHttpAction(METHOD, 'getFollowers', {cache: 'followers', params: {action: 'get_followers'}})
    .addHttpAction(METHOD, 'getFollowers', {params: {action: 'get_followers'}})
    .addHttpAction(METHOD, 'getFollowing', {params: {action: 'get_following'}});

  apiProvider
    .endpoint('suggestFollow')
    .route('rest/:action')
    .model(SuggestFollowResponseModel)
    .addHttpAction(METHOD, 'getSuggestFollow', {params: {action: 'get_suggest_follow_new'}});

  apiProvider
    .endpoint('challenges')
    .route('rest/:action')
    .model(CustomResponseModel)
    .addHttpAction(METHOD, 'getMemberChallengeResult', {params: {action: 'get_member_challenge_result'}});

  apiProvider
    .endpoint('challengesNew')
    .route('rest/:action')
    .model(ClosedChallengeNewResponseModel)
    .addHttpAction(METHOD, 'getMemberChallengeResultNew', {params: {action: 'get_member_challenge_result_new'}});

  apiProvider
    .endpoint('challengesList')
    .route('rest/:action')
    .model(challengesListResponseModel)
    .addHttpAction(METHOD, 'getChallengesList', {params: {action: 'get_challenges_list'}});

  apiProvider
    .endpoint('articles')
    .route('rest/:action')
    .model(ArticlesListResponseModel)
    .addHttpAction(METHOD, 'get_articles_list', {params: {action: 'get_articles'}})
    .addHttpAction(METHOD, 'setArticle', {params: {action: 'set_article'}})
    .addHttpAction(METHOD, 'delArticle', {params: {action: 'del_article'}});

  apiProvider
    .endpoint('ranking')
    .route('rest/:action')
    .model(RankingResponseModel)
    .addHttpAction(METHOD, 'getTopPhotos', {params: {action: 'get_top_photos'}})
    .addHttpAction(METHOD, 'getGuruPicks', {params: {action: 'get_guru_picks'}});

  apiProvider
    .endpoint('exhibitions')
    .route('rest/get_exhibition_page_items')
    .model(ExhibitionResponseModel)
    .addHttpAction(METHOD, 'getExhibitionPageItems');

  apiProvider
    .endpoint('restPages')
    .route('rest/:action')
    .model(PageResponseModel)
    .addHttpAction(METHOD, 'getPageData', {params: {action: 'get_page_data'}});

  // other
  apiProvider
    .endpoint('auth')
    .route('rest/:action')
    .addHttpAction(METHOD, 'facebookRenewToken', {params: {action: 'facebook_renew_token'}})
    .addHttpAction(METHOD, 'facebook', {params: {action: 'facebook'}})
    .addHttpAction(METHOD, 'signUp', {params: {action: 'signup'}})
    .addHttpAction(METHOD, 'signIn', {params: {action: 'signin'}})
    .addHttpAction(METHOD, 'forgotPassword', {params: {action: 'forgot_password'}});

  // other
  apiProvider
    .endpoint('authentication')
    .route('auth/:action')
    .addHttpAction('GET', 'getAccessToken', {params: {action: 'get-token'}});

  apiProvider
    .endpoint('facebook')
    .route('rest/:action')
    .addHttpAction(METHOD, 'facebookConnect', {params: {action: 'facebook_connect'}})
    .addHttpAction(METHOD, 'facebookLink', {params: {action: 'facebook_link'}})
    .addHttpAction(METHOD, 'facebookUnlink', {params: {action: 'facebook_unlink'}})
    .addHttpAction(METHOD, 'facebookRenewToken', {params: {action: 'facebook_renew_token'}})
    .addHttpAction(METHOD, 'facebookFriends', {params: {action: 'facebook_friends'}})
  ;

  apiProvider
    .endpoint('events')
    .route('rest/:action')
    .addHttpAction(METHOD, 'like', {params: {action: 'like'}})
    .addHttpAction(METHOD, 'unlike', {params: {action: 'unlike'}})
    .addHttpAction(METHOD, 'submitToChallenge', {params: {action: 'submit_to_challenge'}})
    .addHttpAction(METHOD, 'swap', {params: {action: 'swap'}});

  apiProvider
    .endpoint('shop')
    .route('rest/:action')
    .addHttpAction(METHOD, 'getAllShelves', {params: {action: 'get_all_shelves'}})
    .addHttpAction(METHOD, 'getShelve', {params: {action: 'get_shelf'}})
    .addHttpAction(METHOD, 'pay', {params: {action: 'pay'}});

  apiProvider
    .endpoint('gsShop')
    .route('rest/:action')
    .addHttpAction(METHOD, 'paypalCreate', {params: {action: 'paypal_create'}})
    .addHttpAction(METHOD, 'paypalExecute', {params: {action: 'paypal_execute'}})
    .addHttpAction(METHOD, 'payCreditCard', {params: {action: 'pay_cc'}})
    .addHttpAction(METHOD, 'pay', {params: {action: 'pay'}});

  apiProvider
    .endpoint('gsAdmin')
    .route('gsadmin/rest')
    .addHttpAction(METHOD, 'set');

  /* event without models */
  apiProvider
    .endpoint('event')
    .route('rest/:action')
    .addHttpAction(METHOD, 'removeFromChallenge', {params: {action: 'remove_from_challenge'}});

  /* rest without models */

  apiProvider
    .endpoint('rest')
    .route('rest/:action')
    .addHttpAction(METHOD, 'getPhotoLibrary', {params: {action: 'get_photo_library'}})
    .addHttpAction(METHOD, 'getAuthenticationUrl', {params: {action: 'social_inviter_get_url'}})
    .addHttpAction(METHOD, 'getContacts', {params: {action: 'social_inviter_get_contacts'}})
    .addHttpAction(METHOD, 'sendInvite', {params: {action: 'social_inviter_send_invite'}})
    .addHttpAction(METHOD, 'trackViralSent', {params: {action: 'track_viral_sent'}})
    .addHttpAction(METHOD, 'handleReports', {params: {action: 'handle_reports'}})
    .addHttpAction(METHOD, 'deleteSuspendedImages', {params: {action: 'del_member_suspended_images'}})
    .addHttpAction(METHOD, 'getChallengePhotos', {params: {action: 'get_challenge_photos'}})
    .addHttpAction(METHOD, 'getSuccessfulSwap', {params: {action: 'get_scs'}})
    .addHttpAction(METHOD, 'getHostedChallenges', {params: {action: 'get_hosted_challenges'}})
    .addHttpAction(METHOD, 'selectForExpo', {params: {action: 'select_for_expo'}})
    .addHttpAction(METHOD, 'deletePopup', {params: {action: 'delete_popup'}})
    .addHttpAction(METHOD, 'getExhibitionList', {params: {action: 'get_top_photos'}})
    .addHttpAction(METHOD, 'getList', {params: {action: 'get_camera_list'}})
    .addHttpAction(METHOD, 'getCameraSlider', {params: {action: 'get_camera'}})
    .addHttpAction(METHOD, 'getCameraImages', {params: {action: 'get_camera_images'}})
    .addHttpAction(METHOD, 'setCameraImages', {params: {action: 'set_camera_images'}})
    .addHttpAction(METHOD, 'delCameraImages', {params: {action: 'del_camera_images'}})
    .addHttpAction(METHOD, 'unlockBoost', {params: {action: 'unlock_boost'}})
    .addHttpAction(METHOD, 'boostPhoto', {params: {action: 'boost_photo'}})
    .addHttpAction(METHOD, 'getMemberStatusResult', {params: {action: 'get_member_status_result'}})
    .addHttpAction(METHOD, 'getNotifications', {params: {action: 'get_notifications'}})
    .addHttpAction(METHOD, 'watchNotifications', {params: {action: 'watch_notifications'}})
    .addHttpAction(METHOD, 'readNotifications', {params: {action: 'read_notification'}})
    .addHttpAction(METHOD, 'getUploadRestrictions', {params: {action: 'get_upload_restrictions'}})
    .addHttpAction(METHOD, 'getPhotosInstagram', {params: {action: 'get_photos_instagram'}})
    .addHttpAction(METHOD, 'getPhotosGoogle', {params: {action: 'get_photos_google'}})
    .addHttpAction(METHOD, 'googlePhotosDisconnect', {params: {action: 'google_photos_disconnect'}})
    .addHttpAction(METHOD, 'claim', {params: {action: 'claim'}})
    .addHttpAction(METHOD, 'setPhotoRelation', {params: {action: 'set_photo_relation'}})
    .addHttpAction(METHOD, 'getMemberEntries', {params: {action: 'get_member_entries'}})
    .addHttpAction(METHOD, 'reportSimilar', {params: {action: 'report_similar'}})
    .addHttpAction(METHOD, 'getSpotlight', {params: {action: 'get_spotlight'}})
    .addHttpAction(METHOD, 'search', {params: {action: 'search'}})
    .addHttpAction(METHOD, 'updateMemberSettings', {params: {action: 'update_member_settings'}})
    .addHttpAction(METHOD, 'withdrawFunds', {params: {action: 'withdraw_funds'}})
    .addHttpAction(METHOD, 'setMemberSettings', {params: {action: 'set_member_settings'}})
    .addHttpAction(METHOD, 'getPhotosPrivate', {params: {action: 'get_photos_private'}})
    .addHttpAction(METHOD, 'getPhotosPublic', {params: {action: 'get_photos_public'}})
    .addHttpAction(METHOD, 'getJoinThemePhotos', {params: {action: 'get_join_theme_photos'}})
    .addHttpAction(METHOD, 'getSwitchBattlePhotos', {params: {action: 'get_switch_battle_photos'}})
    .addHttpAction(METHOD, 'setExhibitionPageItem', {params: {action: 'set_exhibition_page_item'}})
    .addHttpAction(METHOD, 'delExhibitionPageItem', {params: {action: 'del_exhibition_page_items'}})
    .addHttpAction(METHOD, 'follow', {params: {action: 'follow'}})
    .addHttpAction(METHOD, 'unfollow', {params: {action: 'unfollow'}})
    .addHttpAction(METHOD, 'addProfileImage', {params: {action: 'add_profile_image'}})
    .addHttpAction(METHOD, 'getImageData', {params: {action: 'get_image_data'}})
    .addHttpAction(METHOD, 'searchAutocomplete', {params: {action: 'search_autocomplete'}})
    .addHttpAction(METHOD, 'commentDelete', {params: {action: 'comment_delete'}})
    .addHttpAction(METHOD, 'commentCreate', {params: {action: 'comment_create'}})
    .addHttpAction(METHOD, 'commentUpdate', {params: {action: 'comment_update'}})
    .addHttpAction(METHOD, 'setPhotoData', {params: {action: 'set_photo_data'}})
    .addHttpAction(METHOD, 'getVotesList', {params: {action: 'get_votes_list'}})
    .addHttpAction(METHOD, 'getLikesList', {params: {action: 'get_likes_list'}})
    .addHttpAction(METHOD, 'notifyMembers', {params: {action: 'notify_members'}})
    .addHttpAction(METHOD, 'getChallengeData', {params: {action: 'get_challenge_data'}})
    .addHttpAction(METHOD, 'setChallenge', {params: {action: 'set_challenge'}})
    .addHttpAction(METHOD, 'getDiscoverPage', {params: {action: 'get_discover_page'}})
    .addHttpAction(METHOD, 'getFeed', {params: {action: 'get_feed'}})
    .addHttpAction(METHOD, 'sendContact', {params: {action: 'send_contact'}})
    .addHttpAction(METHOD, 'getChallengeDraft', {params: {action: 'get_challenge_draft'}})
    .addHttpAction(METHOD, 'activateChallenge', {params: {action: 'activate_challenge'}})
    .addHttpAction(METHOD, 'getVoteData', {params: {action: 'get_vote_data'}})
    .addHttpAction(METHOD, 'getVotePhotos', {params: {action: 'get_vote_photos'}})
    .addHttpAction(METHOD, 'submitVotes', {params: {action: 'submit_votes'}})
    .addHttpAction(METHOD, 'submitBattleVotes', {params: {action: 'submit_battle_votes'}})
    .addHttpAction(METHOD, 'reportImage', {params: {action: 'report_image'}})
    .addHttpAction(METHOD, 'getCurrentMemberToken', {params: {action: 'get_current_member_token'}})
    .addHttpAction(METHOD, 'getTopPhotographer', {params: {action: 'get_top_photographer'}})
    .addHttpAction(METHOD, 'getMemberRanking', {params: {action: 'get_member_ranking'}})
    .addHttpAction(METHOD, 'leaveChallenge', {params: {action: 'leave_challenge'}})
    .addHttpAction(METHOD, 'getGuruChallenges', {params: {action: 'get_guru_challenges'}})
    .addHttpAction(METHOD, 'listChallenges', {params: {action: 'list_challenges_by_status'}})
    .addHttpAction(METHOD, 'setCustomChallengeMessage', {params: {action: 'set_custom_challenge_message'}})
    .addHttpAction(METHOD, 'deactivateAccount', {params: {action: 'deactivate_account'}})
    .addHttpAction(METHOD, 'isFollowing', {params: {action: 'is_following'}})
    .addHttpAction(METHOD, 'setImageData', {params: {action: 'set_image_data'}})
    .addHttpAction(METHOD, 'getPrizesAttributes', {params: {action: 'get_prizes_attributes'}})
    .addHttpAction(METHOD, 'setChallengePrizes', {params: {action: 'set_challenge_prizes'}})
    .addHttpAction(METHOD, 'getSponsorsAttributes', {params: {action: 'get_sponsors_attributes'}})
    .addHttpAction(METHOD, 'setChallengeSponsors', {params: {action: 'set_challenge_sponsors'}})
    .addHttpAction(METHOD, 'getRankingLevels', {params: {action: 'get_ranking_levels'}})
    .addHttpAction(METHOD, 'setChallengeRankingLevels', {params: {action: 'set_challenge_ranking_levels'}})
    .addHttpAction(METHOD, 'setRankingLevels', {params: {action: 'set_ranking_levels'}})
    .addHttpAction(METHOD, 'getTc', {params: {action: 'get_tc'}})
    .addHttpAction(METHOD, 'getFeed', {params: {action: 'get_feed'}})
    .addHttpAction(METHOD, 'getWinners', {params: {action: 'get_winners'}})
    .addHttpAction(METHOD, 'getWinnersFriends', {params: {action: 'get_winners_friends'}})
    .addHttpAction(METHOD, 'exposureAutofill', {params: {action: 'exposure_autofill'}})
    .addHttpAction(METHOD, 'replaceTopPhotographer', {params: {action: 'replace_top_photographer'}})
    .addHttpAction(METHOD, 'replaceTopPhoto', {params: {action: 'replace_top_photo'}})
    .addHttpAction(METHOD, 'replaceGuruTopPick', {params: {action: 'replace_guru_top_pick'}})
    .addHttpAction(METHOD, 'setPopupMessage', {params: {action: 'set_popup_message'}})
    .addHttpAction(METHOD, 'submitGuruPicks', {params: {action: 'submit_guru_picks'}})
    .addHttpAction(METHOD, 'imageSearch', {params: {action: 'image_search'}})
    .addHttpAction(METHOD, 'imageSearchAutocomplete', {params: {action: 'image_search_autocomplete'}})
    .addHttpAction(METHOD, 'submitGuruPicks', {params: {action: 'submit_guru_picks'}})
    .addHttpAction(METHOD, 'submitGuruTopPicks', {params: {action: 'submit_guru_top_picks'}})
    .addHttpAction(METHOD, 'setPopupMessage', {params: {action: 'set_popup_message'}})
    .addHttpAction(METHOD, 'autoSubmitOnGoing', {params: {action: 'auto_submit_on_going'}})
    .addHttpAction(METHOD, 'cameraSearchAutocomplete', {params: {action: 'camera_search_autocomplete'}})
    .addHttpAction(METHOD, 'cameraSearch', {params: {action: 'camera_search'}})
    .addHttpAction(METHOD, 'getPermissionGroups', {params: {action: 'get_permission_groups'}})
    .addHttpAction(METHOD, 'filterSuggestedChallenge', {params: {action: 'filter_suggested_challenge'}})
    .addHttpAction(METHOD, 'globalSuggest', {params: {action: 'global_suggest'}})
    .addHttpAction(METHOD, 'autoSelectDigitalExhibition', {params: {action: 'auto_select_digital_exhibition'}})
    .addHttpAction(METHOD, 'getCountriesNotificationTimes', {params: {action: 'get_countries_notification_times'}})
    .addHttpAction(METHOD, 'setCountriesNotificationTimes', {params: {action: 'set_countries_notification_times'}})
    .addHttpAction(METHOD, 'keyUnlock', {params: {action: 'key_unlock'}})
    .addHttpAction(METHOD, 'getMemberData', {params: {action: 'get_member_data'}})
    .addHttpAction(METHOD, 'getDailyMissions', {params: {action: 'get_daily_missions'}})
    .addHttpAction(METHOD, 'claimDailyReward', {params: {action: 'claim_daily_reward'}})
    .addHttpAction(METHOD, 'getMatchedChallenges', {params: {action: 'get_matched_challenges'}})
    .addHttpAction(METHOD, 'getDailyMissionsDefinitions', {params: {action: 'get_daily_missions_definitions'}})
    .addHttpAction(METHOD, 'getDailyRewardsPrizes', {params: {action: 'get_daily_rewards_prizes'}})
    .addHttpAction(METHOD, 'setDailyMissionDefinition', {params: {action: 'set_daily_mission_definition'}})
    .addHttpAction(METHOD, 'delDailyMissionDefinition', {params: {action: 'del_daily_mission_definition'}})
    .addHttpAction(METHOD, 'setDailyPrize', {params: {action: 'set_daily_prize'}})
    .addHttpAction(METHOD, 'delDailyPrize', {params: {action: 'del_daily_prize'}})
    .addHttpAction(METHOD, 'getExhibitionWinners', {params: {action: 'get_exhibition_winners'}})
    .addHttpAction(METHOD, 'setExhibitionWinners', {params: {action: 'set_exhibition_winners'}})
    .addHttpAction(METHOD, 'resetAutoSelectExhibition', {params: {action: 'reset_auto_select_digital_exhibition'}})
    .addHttpAction(METHOD, 'setChallengeAutoSelectAttributes', {params: {action: 'set_challenge_auto_select_attributes'}})
    .addHttpAction(METHOD, 'getChallengeAutoSelectAttributes', {params: {action: 'get_challenge_auto_select_attributes'}})
    .addHttpAction(METHOD, 'newAutoSelectDigitalExhibition', {params: {action: 'new_auto_select_digital_exhibition'}})
    .addHttpAction(METHOD, 'sendSelectionNotifications', {params: {action: 'send_selection_notifications'}})
    .addHttpAction(METHOD, 'lockExhibitionChallenge', {params: {action: 'lock_exhibition_challenge'}})
    .addHttpAction(METHOD, 'rewardedVideoGetSettings', {params: {action: 'rewarded_video_get_settings'}})
    .addHttpAction(METHOD, 'rewardedVideoSetSettings', {params: {action: 'rewarded_video_set_settings'}})
    .addHttpAction(METHOD, 'getChallengePriorities', {params: {action: 'get_challenges_suggest_priorities'}})
    .addHttpAction(METHOD, 'setChallengePriorities', {params: {action: 'set_challenges_suggest_priorities'}})
    .addHttpAction(METHOD, 'getLbEvent', {params: {action: 'get_lb_event'}})
    .addHttpAction(METHOD, 'claimLbEvent', {params: {action: 'claim_lb_event'}})
    .addHttpAction(METHOD, 'setLbEventWatched', {params: {action: 'set_lb_event_watched'}})
    .addHttpAction(METHOD, 'getLbEvents', {params: {action: 'get_lb_events'}})
    .addHttpAction(METHOD, 'setLbEvent', {params: {action: 'set_lb_event'}})
    .addHttpAction(METHOD, 'get3dsToken', {params: {action: 'get_3ds_token'}})
    .addHttpAction(METHOD, 'getPackages', {params: {action: 'get_packages'}})
    .addHttpAction(METHOD, 'getGlobalPermissions', {params: {action: 'get_global_permissions'}})
    .addHttpAction(METHOD, 'getNewbieChallengeId', {params: {action: 'get_newbie_challenge_id'}})
    .addHttpAction(METHOD, 'getCopyrightReports', {params: {action: 'get_copyright_reports'}})
    .addHttpAction(METHOD, 'moderatorHandle', {params: {action: 'moderator_handle'}})
    .addHttpAction(METHOD, 'getOffTopicReports', {params: {action: 'get_off_topic_reports'}})
    .addHttpAction(METHOD, 'moderatorHandleBulk', {params: {action: 'moderator_handle_bulk'}})
    .addHttpAction(METHOD, 'moderatorHandleBulkAdultPorn', {params: {action: 'moderator_handle_bulk_adult_porn'}})
    .addHttpAction(METHOD, 'getImageReports', {params: {action: 'get_image_reports'}})
    .addHttpAction(METHOD, 'getAdminReports', {params: {action: 'get_admin_reports'}})
    .addHttpAction(METHOD, 'adminImageHandle', {params: {action: 'admin_image_handle'}})
    .addHttpAction(METHOD, 'adminMemberHandle', {params: {action: 'admin_member_handle'}})
    .addHttpAction(METHOD, 'adminGetChests', {params: {action: 'admin_get_chests'}})
    .addHttpAction(METHOD, 'adminSetChestRewards', {params: {action: 'admin_set_chest_rewards'}})
    .addHttpAction(METHOD, 'getTeams', {params: {action: 'get_teams'}})
    .addHttpAction(METHOD, 'getTeam', {params: {action: 'get_team'}})
    .addHttpAction(METHOD, 'createTeam', {params: {action: 'create_team'}})
    .addHttpAction(METHOD, 'updateTeam', {params: {action: 'update_team'}})
    .addHttpAction(METHOD, 'getTeamBadges', {params: {action: 'get_team_badges'}})
    .addHttpAction(METHOD, 'getTeamsConfig', {params: {action: 'get_teams_config'}})
    .addHttpAction(METHOD, 'isTeamNameFree', {params: {action: 'is_team_name_free'}})
    .addHttpAction(METHOD, 'joinTeam', {params: {action: 'join_team'}})
    .addHttpAction(METHOD, 'removeMember', {params: {action: 'remove_member'}})
    .addHttpAction(METHOD, 'updateMember', {params: {action: 'update_member'}})
    .addHttpAction(METHOD, 'leaveTeam', {params: {action: 'leave_team'}})
    .addHttpAction(METHOD, 'getTeamChatMessages', {params: {action: 'get_team_chat_messages'}})
    .addHttpAction(METHOD, 'getMatch', {params: {action: 'get_match'}})
    .addHttpAction(METHOD, 'joinMatch', {params: {action: 'join_match'}})
    .addHttpAction(METHOD, 'leaveMatch', {params: {action: 'leave_match'}})
    .addHttpAction(METHOD, 'getMatchesHistory', {params: {action: 'get_matches_history'}})
    .addHttpAction(METHOD, 'getLeaderboard', {params: {action: 'get_leaderboard'}})
    .addHttpAction(METHOD, 'getBrazeClientEvents', {params: {action: 'get_braze_client_events'}})
    .addHttpAction(METHOD, 'getAutocompleteChallengeTags', {params: {action: 'get_autocomplete_challenge_tags'}})
    .addHttpAction(METHOD, 'getDeeplinks', {params: {action: 'get_deeplinks'}})
    .addHttpAction(METHOD, 'getTeamLeagueData', {params: {action: 'get_team_league_data'}})
    .addHttpAction(METHOD, 'getMemberLeagueData', {params: {action: 'get_member_league_data'}})
    .addHttpAction(METHOD, 'deleteFeatureMessage', {params: {action: 'delete_feature_message'}})
    .addHttpAction(METHOD, 'claimChest', {params: {action: 'claim_chest'}})
    .addHttpAction(METHOD, 'getLeagueData', {params: {action: 'get_member_bucket_items'}})
    .addHttpAction(METHOD, 'getLeagueConfig', {params: {action: 'get_league_config'}})
    .addHttpAction(METHOD, 'getLeagueSeasonConfig', {params: {action: 'get_league_season_config'}})
    .addHttpAction(METHOD, 'getLeagueSeasonConfigForAdmin', {params: {action: 'get_league_season_config_for_admin'}})
    .addHttpAction(METHOD, 'getTeamsAdminLeagueInfo', {params: {action: 'get_teams_admin_league_info'}})
    .addHttpAction(METHOD, 'setCurrentLeagueConfig', {params: {action: 'set_current_league_config'}})
    .addHttpAction(METHOD, 'setDefaultLeagueConfig', {params: {action: 'set_default_league_config'}})
    .addHttpAction(METHOD, 'setUpcomingLeagueConfig', {params: {action: 'set_upcoming_league_config'}})
    .addHttpAction(METHOD, 'getReasonOptions', {params: {action: 'get_reason_options'}})
    .addHttpAction(METHOD, 'getDurationOptions', {params: {action: 'get_duration_options'}})
    .addHttpAction(METHOD, 'deleteMember', {params: {action: 'delete_member'}})
    .addHttpAction(METHOD, 'reactivateMember', {params: {action: 'reactivate_member'}})
    .addHttpAction(METHOD, 'suspendMember', {params: {action: 'suspend_member'}})
    .addHttpAction(METHOD, 'getRemoveDetails', {params: {action: 'get_remove_details'}})
    .addHttpAction(METHOD, 'getVirtualExhibitions', {params: {action: 'get_virtual_exhibitions'}})
    .addHttpAction(METHOD, 'createVirtualExhibition', {params: {action: 'create_virtual_exhibition'}})
    .addHttpAction(METHOD, 'updateVirtualExhibition', {params: {action: 'update_virtual_exhibition'}})
    .addHttpAction(METHOD, 'deleteVirtualExhibition', {params: {action: 'delete_virtual_exhibition'}})
    .addHttpAction(METHOD, 'getCountriesList', {params: {action: 'get_countries_list'}})
    .addHttpAction(METHOD, 'getLanguagesList', {params: {action: 'get_languages_list'}})
    .addHttpAction(METHOD, 'getMemberTeamChest', {params: {action: 'get_member_team_chest'}})
    .addHttpAction(METHOD, 'getTeamSuggestedMembers', {params: {action: 'get_team_suggested_members'}})
    .addHttpAction(METHOD, 'getTeamInvitedMembers', {params: {action: 'get_team_invited_members'}})
    .addHttpAction(METHOD, 'getTeamSuggestedMembersOptions', {params: {action: 'get_team_suggested_members_options'}})
    .addHttpAction(METHOD, 'getMemberTeamState', {params: {action: 'get_member_team_state'}})
    .addHttpAction(METHOD, 'declineInvitation', {params: {action: 'decline_invitation'}})
    .addHttpAction(METHOD, 'hideSuggestedMember', {params: {action: 'hide_suggested_member'}})
    .addHttpAction(METHOD, 'inviteMember', {params: {action: 'invite_member'}})
    .addHttpAction(METHOD, 'getFbIsConnected', {params: {action: 'get_fb_is_connected'}})
    .addHttpAction(METHOD, 'getTeamInvites', {params: {action: 'get_team_invites'}})
    .addHttpAction(METHOD, 'isNewInvitations', {params: {action: 'is_new_invitation'}})
    .addHttpAction(METHOD, 'resetNewInvitations', {params: {action: 'reset_new_invitation'}})
    .addHttpAction(METHOD, 'setChallengeMedia', {params: {action: 'set_challenge_media'}})
    .addHttpAction(METHOD, 'adminRefreshMemberAchievements', {params: {action: 'admin_refresh_member_achievements'}})
    .addHttpAction(METHOD, 'getTeamSuggest', {params: {action: 'get_team_suggest'}})
    .addHttpAction(METHOD, 'hideTeamSuggest', {params: {action: 'hide_team_suggest'}})
    .addHttpAction(METHOD, 'getAdminComments', {params: {action: 'get_admin_comments'}})
    .addHttpAction(METHOD, 'setAdminComment', {params: {action: 'set_admin_comment'}})
    .addHttpAction(METHOD, 'deleteAdminComment', {params: {action: 'delete_admin_comment'}})
    .addHttpAction(METHOD, 'reactivateOldMember', {params: {action: 'reactivate_old_member'}})
    .addHttpAction(METHOD, 'reactivateDeletedMember', {params: {action: 'reactivate_deleted_member'}})
    .addHttpAction(METHOD, 'getAdminTeamById', {params: {action: 'get_admin_team_by_id'}})
    .addHttpAction(METHOD, 'getAdminTeamByName', {params: {action: 'get_admin_team_by_name'}})
    .addHttpAction(METHOD, 'getTeamsAdminActions', {params: {action: 'get_teams_admin_actions'}})
    .addHttpAction(METHOD, 'getTeamsAdminGlobalActions', {params: {action: 'get_teams_admin_global_actions'}})
    .addHttpAction(METHOD, 'teamsAdminAction', {params: {action: 'teams_admin_action'}})
    .addHttpAction(METHOD, 'getTeamsAdminMatches', {params: {action: 'get_teams_admin_matches'}})
    .addHttpAction(METHOD, 'getTeamsAdminMatchMembers', {params: {action: 'get_teams_admin_match_members'}})
    .addHttpAction(METHOD, 'getTeamsAdminLeftMembers', {params: {action: 'get_teams_admin_left_members'}})
    .addHttpAction(METHOD, 'teamsAdminAssignLeader', {params: {action: 'teams_admin_assign_leader'}})
    .addHttpAction(METHOD, 'getChallengeTemplates', {params: {action: 'get_challenge_templates'}})
    .addHttpAction(METHOD, 'setChallengeTemplate', {params: {action: 'set_challenge_template'}})
    .addHttpAction(METHOD, 'deleteChallengeTemplate', {params: {action: 'delete_challenge_template'}})
    .addHttpAction(METHOD, 'claimResources', {params: {action: 'claim_resources'}})
    .addHttpAction(METHOD, 'getLeagueConfigWithRules', {params: {action: 'get_league_config_with_rules'}})
    .addHttpAction(METHOD, 'getItemHistory', {params: {action: 'get_item_history'}})
    .addHttpAction(METHOD, 'getMockData', {params: {action: 'get_mock_data'}})
    .addHttpAction(METHOD, 'getAllSponsors', {params: {action: 'get_all_sponsors'}})
    .addHttpAction(METHOD, 'createSponsor', {params: {action: 'create_sponsor'}})
    .addHttpAction(METHOD, 'getAllPrizes', {params: {action: 'get_all_prizes'}})
    .addHttpAction(METHOD, 'getMemberAchievements', {params: {action: 'get_member_achievements'}})
    .addHttpAction(METHOD, 'createPrize', {params: {action: 'create_prize'}})
    .addHttpAction(METHOD, 'getBlockedMembers', {params: {action: 'get_blocked_members'}})
    .addHttpAction(METHOD, 'blockMember', {params: {action: 'block_member'}})
    .addHttpAction(METHOD, 'unblockMembers', {params: {action: 'unblock_members'}})
    .addHttpAction(METHOD, 'isMemberBlocked', {params: {action: 'is_member_blocked'}})
    .addHttpAction(METHOD, 'joinTheme', {params: {action: 'join_theme'}})
    .addHttpAction(METHOD, 'getTotalChallengesAwaitingImport', {params: {action: 'get_total_challenges_awaiting_import'}})
    .addHttpAction(METHOD, toCamelCaseFromUnderline(restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_BY_LEVEL.NAME),
      {params: {action: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_BY_LEVEL.NAME}})
    .addHttpAction(METHOD, toCamelCaseFromUnderline(restEnum.END_POINTS.GET_CHALLENGES_BY_ACHIEVEMENT.NAME),
      {params: {action: restEnum.END_POINTS.GET_CHALLENGES_BY_ACHIEVEMENT.NAME}})
    .addHttpAction(METHOD, toCamelCaseFromUnderline(restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_CHALLENGES_HISTORY.NAME),
      {params: {action: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_CHALLENGES_HISTORY.NAME}})
    .addHttpAction(METHOD, toCamelCaseFromUnderline(restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_DELETE_MESSAGE.NAME),
      {params: {action: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_DELETE_MESSAGE.NAME}})
    .addHttpAction(METHOD, toCamelCaseFromUnderline(restEnum.END_POINTS.GET_SESSION_CONFIGURATION.NAME),
      {params: {action: restEnum.END_POINTS.GET_SESSION_CONFIGURATION.NAME}})
  ;
}
config.$inject = ['apiProvider', 'CacheFactoryProvider'];

export default config;
