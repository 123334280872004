import config from './moderation.page.config';
import moderationPage from './moderationPage/moderationPage';
import moderationCopyright from './moderationCopyright/moderationCopyright';
import moderationOfftopic from './moderationOfftopic/moderationOfftopic';
import moderationImages from './moderationImages/moderationImages';

let moduleModerationPage = angular
  .module('gsApp.moderationPage', [])
  .config(config)
  .component('moderationPage', moderationPage)
  .component('moderationCopyright', moderationCopyright)
  .component('moderationOfftopic', moderationOfftopic)
  .component('moderationImages', moderationImages)
;

export default moduleModerationPage.name;
