import {Component, Inject, Input} from '@angular/core';
import {ResourceInterface} from "../../../modules/challenges/interfaces/resource.interface";
import {ResourceNameEnum} from "../../../core/enum/resource-name.enum";
import mixpanelEventsEnum from "../../../gsApp/app/services/mixpanel/mixpanel.enum";
import {ClaimStateEnum} from "../../../core/enum/claim-state.enum";

@Component({
  selector: 'app-ng-resources',
  templateUrl: './ng-resources.component.html',
  styleUrl: './ng-resources.component.scss'
})
export class NgResourcesComponent {

  @Input() public set sections(values: ResourceInterface[]) {
    this._sections =
      this.onlyTotalMode ?
        values.filter(value => value.name === ResourceNameEnum.TOTAL) :
        values;
    this.allSections = values;
  }
  @Input() public title: string = '';
  @Input() public onlyTotalMode = true;
  @Input() public claimState?:ClaimStateEnum;

  constructor(
    @Inject('mixpanelService')  private mixpanelService: any,
  ) {
  }

  public get isInfoButtonVisible(): boolean {
    return (this.allSections || []).filter(
      section => section.name !== ResourceNameEnum.TOTAL
    )?.length >= 1;
  }

  public allSections: ResourceInterface[] = [];
  public ResourceNameEnum = ResourceNameEnum;

  public get sections(): ResourceInterface[] {
    return this._sections;
  }

  private _sections: ResourceInterface[] = [];

  onTooltipShow(){
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.CHALLENGE_SUMMARY,
      data:{
        ui_name: mixpanelEventsEnum.UI_NAME.CHALLENGE_SUMMARY_SHOW,
        ui_action: mixpanelEventsEnum.UI_ACTION.HOVER,
        ui_state: this.claimState?.toLowerCase(),
      }
    });
  }
}
