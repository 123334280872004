import {Subject, takeUntil} from "rxjs";

const template = require(`html-loader!./homePage.html`).default;
import {lpConsts} from '../landingPages/landingPages.data';
import ProtectionModel from '../../api/models/protection.model';
import {NgMetaDataService} from "../../../../core/services/ng-meta-data.service";

const MEDUIM_VIDOE_STEP= 1225;
const SMALL_VIDOE_STEP= 964;

class homePageCtrl {
  subject = new Subject();
  constructor(
    Modals,
    $timeout,
    $scope,
    deepLinkService,
    gsScrollTopService,
    protectionService,
    tagEventService,
    MemberService,
    NgMetaDataService,
    RouterService
  ) {
    'ngInject';
    this.busy = true;
    this.$ctrl = this;
    this.videoUrl = '';
    this.$scope = $scope;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.deepLinkService = deepLinkService;
    this.lpConsts = lpConsts;
    this.gsScrollTopService = gsScrollTopService;
    this.protectionService = protectionService;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
    this.RouterService = RouterService;
    this.member = this.MemberService.getCurrentMember();
  }

  $onInit() {
    if (this.member?.logged_in) {
      this.RouterService.go('challenges/my-challenges/current');
      return;
    }

    this.NgMetaDataService.updateMetaTags({
      title:`The World's Greatest Photography Game`,
      description:`Join GuruShots, the world's leading photography game, and compete in challenges, get inspired by amazing photos, and showcase your work to a global audience.`,
      keywords:[ 'photography', 'photo challenges', 'photography game', 'GuruShots', 'online photography', 'photo contests']
    })

    if (this.deepLinkService.getDl()) {
      this.login();
    }
    if (document.body.clientWidth > MEDUIM_VIDOE_STEP ){
      this.videoUrl = "/assets/videos/gurushots-cover-video-wide.mp4";
    }

    if (document.body.clientWidth <= MEDUIM_VIDOE_STEP  && document.body.clientWidth > SMALL_VIDOE_STEP){
      this.videoUrl = "/assets/videos/gurushots-cover-video-medium.mp4";
    }

    const signupProtectionModel = new ProtectionModel({
      elementClassName: 'cover__btn--s--',
      eventName: 'click',
      callback: this.signUp.bind(this)
    });
    const awesomeChallengesEveryDay = new ProtectionModel({
      elementClassName: 'home-page__challenges__btn--s--',
      eventName: 'click',
      callback: this.signUp.bind(this)
    });
    const motivated = new ProtectionModel({
      elementClassName: 'motivated__btn--s--',
      eventName: 'click',
      callback: this.signUp.bind(this)
    });

    this.protectionService.initEvent(signupProtectionModel);
    this.protectionService.initEvent(awesomeChallengesEveryDay);
    this.protectionService.initEvent(motivated);

    this.initPage();
  }

 initPage() {
    this.tagEventService.sendPageViewEvent({title: 'Home page'});
    this.busy = false;

    this.gsScrollTopService.scrollTop();
  }

  login(event) {
    this.Modals.open('login', event);
  }

  signUp(event) {
    this.Modals.open('login', event, { signUp: true });
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
homePageCtrl.$inject = [
  'Modals',
  '$timeout',
  '$scope',
  'deepLinkService',
  'gsScrollTopService',
  'protectionService',
  'tagEventService',
  'MemberService',
  'NgMetaDataService',
  'RouterService'
];

export default {
  template: template,
  controller: homePageCtrl,
};
