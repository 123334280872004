const template = require(`html-loader!./leaderBoardItems.html`).default;
import leaderBoardEnum from "../../api/enums/leaderBoard.enum";
import {teamItemMode} from "../teamItem/teamItem.data";

class leaderBoardItemsCtrl {
  constructor(
    $scope,
    $rootScope,
    leaderboardService,
    $timeout
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.leaderboardService = leaderboardService;
    this.leaderBoardEnum = leaderBoardEnum;
    this.teamItemMode = teamItemMode;
    this.$timeout = $timeout;
    this.showMe = true;
  }

  $onInit() {
    console.log('type', this.type);
    this.registerFloatingItemHandler({
      handler: this.checkFloatingItem.bind(this)
    });
    this.$timeout(()=>{
      this.checkFloatingItem();
    },1000);
  }

  checkFloatingItem() {
    const myItemClass = '.leader-board-item__my-item:not(.floating-item)';
    const floatingItemClass = '.leader-board-items__floating-item';
    if (this.showMe && $(myItemClass)[0] && $(floatingItemClass)[0]) {
      const floatingMyTeamOffsetTop = $(floatingItemClass)[0].offsetTop;
      const myTeamOffsetTop = $(myItemClass)[0].offsetTop;
      if (floatingMyTeamOffsetTop >= myTeamOffsetTop) {
        this.showMe = false;

        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }
      }
    }
  }

  isTypeGlobal() {
    return this.leaderboardService.isTypeGlobal(this.type);
  }

  isTypeLeague() {
    return this.leaderboardService.isTypeLeague(this.type);
  }

  isTypeIndividual() {
    return this.leaderboardService.isTypeIndividual(this.type);
  }

  isMyItem(id) {
    return this.myItem.id === id;
  }
}

leaderBoardItemsCtrl.$inject = [
  '$scope',
  '$rootScope',
  'leaderboardService',
  '$timeout'
];

export default {
  template: template,
  controller: leaderBoardItemsCtrl,
  bindings: {
    type: '@',
    globalItems: '<',
    promoteItems: '<',
    stayItems: '<',
    dropItems: '<',
    myItem: '<',
    registerFloatingItemHandler: '&',
  }
};
