const template = require(`html-loader!./myTeamChatMemberList.html`).default;

class myTeamChatMemberListCtrl {

  constructor($scope, $timeout) {
    'ngInject';
    this.members;

    this.$scope = $scope;
    this.$timeout = $timeout;
  }

  $onInit() {
    this.onKeydown = this.onKeydown.bind(this);
    window.addEventListener("keyup",  this.onKeydown);
  }

  $onDestroy(){
    window.removeEventListener('keyup', this.onKeydown);
  }

  onKeydown(event){
    if (event.defaultPrevented) {
      return; // Do nothing if the event was already processed
    }
    console.log('onKeydown', event.key);
    switch (event.key) {
      case "Tab":
        // this.onMemberSelect({member:this.members[this.selectedIndex]});
        this.onMemberSelect({member:this.members[0]});
        break;
      case "Esc": // IE/Edge specific value
      case "Escape":
        this.closeMemberList({digest:true});
        // Do something for "esc" key press.
        break;
      default:
        return; // Quit when this doesn't handle the key event.
    }
    if (!this.$scope.$$phase) {this.$scope.$digest()}
    // Cancel the default action to avoid it being handled twice
    event.preventDefault();
  }
}

myTeamChatMemberListCtrl.$inject = [
  '$scope', '$timeout'
];
export default {
  template: template,
  controller: myTeamChatMemberListCtrl,
  bindings: {
    members: '<',
    onMemberSelect: '&',
    closeMemberList: '&',
  }
};
