import ImageModel from './image/image.model';
import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";

class ClosedChallengeNewResponseModel {
  constructor({
    success = false,
    rank = null,
    rewards = [],
    challenge = {},
    ss_url,
    share_urls,
    member,
    image_achievements,
    member_achievements,
  }) {
    this.success = success;
    this.rank = rank;
    this.rewards = rewards;
    this.ss_url = ss_url;
    this.share_urls = share_urls;
    this.challenge = new NgChallengeModel(challenge);
    this.member = member;
    this.achievements = [];
    this.image_achievements = image_achievements;
    this.member_achievements = member_achievements;
    this.levelBadge;

    if (this.rank.total.points) {
      this.rewards.push({
        type: 'points',
        value: this.rank.total.points,
      });
    }

    this.setAchievements();
  }

  setAchievements() {
    let topPhotographerBadge;
    let topPhotographerAchievmentBadge;
    let guruPickBadge;
    let guruTopPickBadge;
    let achievement;

    for (let image_id in this.image_achievements) {
      achievement = {
        badges: [],
        image: new ImageModel(
          {
            id: image_id,
            member_id: this.member?.id,
          }),
        type: 'top-photo',
      };
      guruPickBadge = null;
      guruTopPickBadge = null;

      for (let image_achievement of this.image_achievements[image_id]) {
        switch (image_achievement.type) {
          case 'EXHIBITION_ACHIEVEMENT' :
            break;
          case 'TOP_PHOTOGRAPHER_ACHIEVEMENT' :
            topPhotographerAchievmentBadge = image_achievement.badge;
            break;
          case 'CHALLENGE_TOP_PHOTOGRAPHER' :
            topPhotographerBadge = image_achievement.badge;
            break;
          case 'CHALLENGE_LEVEL_ACHIEVEMENT':
            this.levelBadge = image_achievement.badge;
            break;
          case 'GURU_PICK':
            guruPickBadge = image_achievement.badge;
            break;
          case 'GURU_TOP_PICK':
            guruTopPickBadge = image_achievement.badge;
            break;
          default:
            achievement.badges[0] = image_achievement.badge;
        }
      }

      if (guruTopPickBadge) {
        achievement.badges[0] = guruTopPickBadge;
      }
      if (guruPickBadge) {
        achievement.badges[1] = guruPickBadge;
      }

      this.achievements.push(achievement);
    }

    if (topPhotographerBadge) {
      this.achievements.unshift({
        badges: [topPhotographerBadge],
        type: 'top-photographer',
        member: this.member,
      });
    } else if (topPhotographerAchievmentBadge) {
      this.achievements.unshift({
        badges: [topPhotographerAchievmentBadge],
        type: 'top-photographer',
        member: this.member,
      });
    }

    if (this.member_achievements.EXHIBITION_ACHIEVEMENT) {
      this.achievements.unshift({
        badges: [{
          type: 'icon',
          value: this.member_achievements.EXHIBITION_ACHIEVEMENT.icon,
        }],
        type: 'icon',
      });
    }

    if (!this.levelBadge) {
      this.levelBadge = {
        'type': 'badge',
        'value': 0,
      };
    }
  }
}

export default ClosedChallengeNewResponseModel;
