<div class="out-of-resource">
  <div class="head-title gs-t-a-c">You’re Out Of Coins!</div>
  <div>
    <div class="out-of-resource__title gs-theme-text-regular gs-theme-color-white_100 gs-theme-gradient-quickshots gs-t-a-c">Buy Coins</div>
    <div class="out-of-resource__section gs-d-f gs-j-c-c gs-f-w gs-g-8 gs-p-10">
      <div *ngFor="let categoryItem of data!.items; let i = index"
           class="out-of-resource__section__item m-min-size">
        <app-store-item (click)="itemSelected(categoryItem)"
                        [storeItem]="categoryItem"
                        [sectionType]="data!.sectionType!"></app-store-item>
      </div>
    </div>
  </div>
  <div class="out-of-resource__actions gs-m-t-24 gs-t-a-c gs-center-flex">
    <div [mat-dialog-close]="OutOfResourceCloseModalType.MORE_OPTIONS"
            class="out-of-resource__more gs-theme-text-caps-bold gs-theme-color-blue_solid
            clickable gs-center-flex">
      See more options</div>
  </div>
</div>
