const template = require(`html-loader!./adminWidget.html`).default;

class adminWidgetCtrl {
  /** @param {CamerasManager} CamerasManager **/

  constructor(CamerasManager, $scope) {
    'ngInject';
    this.busy = true;

    this.CamerasManager = CamerasManager;
    this.$scope = $scope;
  }

  $onInit() {
    this.getData();
  }

  /** @return void **/
  async getData() {
    const res = await this.CamerasManager.getList();
    if (!res.success) {
      return;
    }
    this.items = res.items;
    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
adminWidgetCtrl.$inject = [
  'CamerasManager', '$scope'
];
export default {
  template: template,
  controller: adminWidgetCtrl,
  bindings: {},
};
