import mediaEnum from "../../../gsApp/app/api/enums/media.enum";
import {NgImageModel} from "../../../core/models/fromOldApp/ng-image.model";
import {INgMediaItemModel} from "../interfaces/challenges.interface";

export class NgMediaItemModel implements INgMediaItemModel {
  data?: NgImageModel;

  constructor(props: INgMediaItemModel = {}) {
    Object.assign(this, props);

    (props.type === mediaEnum.TYPE.IMAGE) && (this.data = new NgImageModel(this.data));
  }
}
