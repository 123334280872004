import ChestModel from '../chest/chest.model';
import ChestAttributesModel from '../chest/chestAttributes.model';

class TeamChestModel {
  constructor(props) {
    this.member_chest = props.member_chest ? new ChestModel(props.member_chest) : null;
    this.chest_attributes = props.chest_attributes ? new ChestAttributesModel(props.chest_attributes) : null;
  }
}

export default TeamChestModel;
