import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dl-exhibition-wrapper',
  templateUrl: './dl-exhibition-wrapper.component.html',
  styleUrl: './dl-exhibition-wrapper.component.scss'
})
export class DlExhibitionWrapperComponent {
  cId = null;
  state = {
    dlState: 'dl/exhibition',
    redirectState: 'challenges/my-challenges/current',
    type: 'exhibition'
  }

  constructor(
    private route: ActivatedRoute
  ) {
    this.route
      .params.pipe(
    ).subscribe(async (params: any) => {
      this.cId = params.cId;
    });
  }
}
