import { Component, Input } from '@angular/core';
import {StoreItemInfoDataType} from "../../../angular-material/models/types";

@Component({
  selector: 'app-store-item-info',
  templateUrl: './store-item-info.component.html',
  styleUrl: './store-item-info.component.scss'
})
export class StoreItemInfoComponent {
  @Input() data!:StoreItemInfoDataType;
}
