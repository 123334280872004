import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dl-vote-wrapper',
  templateUrl: './dl-vote-wrapper.component.html',
  styleUrl: './dl-vote-wrapper.component.scss'
})
export class DlVoteWrapperComponent {
  cId = null;
  state = {
    dlState: 'dl/vote',
    redirectState: 'challenge/details',
    type: 'vote'
  }

  constructor(
    private route: ActivatedRoute
  ) {
    this.route
      .params.pipe(
    ).subscribe(async (params: any) => {
      this.cId = params.cId;
    });
  }
}
