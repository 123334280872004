function highlighter(
  $timeout
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      model: '=highlighter'
    },
    link: (scope, element) => {
      scope.$watch('model', (newValue, previousValue) => {
        if (newValue !== previousValue) {
          element.addClass('highlight');

          $timeout(() => {
            element.removeClass('highlight');
          }, 600);
        }
      });
    }
  };
}

highlighter.$inject = [
  '$timeout'
];

export default highlighter;
