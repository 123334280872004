<div class="complete-purchase">
  <div class="complete-purchase__image gs-a-i-c gs-d-f gs-j-c-c">
    <img [ngSrc]="data.item!.icon.png_url"
         [title]="data.item!.received[0].type"
         [alt]="data.item!.received[0].type"
         width="130"
         height="130" *ngIf="data.item!.icon.png_url">
  </div>
  <div class="gs-theme-text-headline-big gs-theme-color-gray_80 gs-t-a-c gs-p-15-0">You purchased<br/>
    <div *ngIf="data.item!.received.length === 1">{{data.item!.received[0].amount}} {{data.item!.received[0].type}}!</div>
    <div *ngIf="data.item!.received.length > 1">{{data.item!.name}}!</div>
  </div>
  <div class="gs-d-f gs-f-d-c gs-p-0-24-20">
    <button [mat-dialog-close]="'redirect'"
            class="gs-t-t-u gs-theme-button gs-theme-gradient-blue gs-theme-text-caps-bold gs-theme-color-white_100">Let’s play with it</button>
    <button [mat-dialog-close]
            class="complete-purchase__back-button gs-m-t-16 gs-theme-text-caps-regular gs-theme-color-blue_solid">Back to store</button>
  </div>
</div>
