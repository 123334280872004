const template = require(`html-loader!./leaderBoardMemberItem.html`).default;

class leaderBoardMemberItemCtrl {
  constructor(
    $rootScope
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
  }
}

leaderBoardMemberItemCtrl.$inject = [
  '$rootScope'
];

export default {
  template,
  controller: leaderBoardMemberItemCtrl,
  bindings: {
    lbMember: '<',
    goalParam: '<',
    goalType: '<'
  }
};
