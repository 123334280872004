function config($stateProvider) {
  "ngInject";
  $stateProvider
    .state('gs.landingClaim', {
      url: '/landing_claim',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'landingClaimPage'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.lp1', {
      url: '/lp1',
      views: {
        'header@gs': null,
        'page@gs': {component: 'lp1'},
        'footer@gs': null
      }
    })
    .state('gs.lp2', {
      url: '/lp2',
      views: {
        'header@gs': null,
        'page@gs': {component: 'lp1'},
        'footer@gs': null
      }
    })
    .state('gs.lp3', {
      url: '/lp3',
      views: {
        'header@gs': null,
        'page@gs': {component: 'lp1'},
        'footer@gs': null
      }
    })
    .state('gs.lp4', {
      url: '/lp4',
      views: {
        'header@gs': null,
        'page@gs': {component: 'lp1'},
        'footer@gs': null
      }
    })
    .state('gs.lp5', {
      url: '/lp5',
      views: {
        'header@gs': null,
        'page@gs': {component: 'lpv2'},
        'footer@gs': null
      }
    })
    .state('gs.lp6', {
      url: '/lp6',
      views: {
        'header@gs': null,
        'page@gs': {component: 'lpv2'},
        'footer@gs': null
      }
    })
    .state('gs.lpMobile', {
      url: '/lp_mobile',
      views: {
        'header@gs': null,
        'page@gs': {component: 'lpMobile'},
        'footer@gs': null
      }
    })
    .state('gs.photoContest', {
      url: '/photo_contest',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'photoContest'},
        'footer@gs': null
      }
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
