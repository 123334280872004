const template = require(`html-loader!./successfulSwapCard.html`).default;

class successfulSwapCardCtrl {
  constructor($element, Modals) {
    'ngInject';

    this.$element = $element;
    this.Modals = Modals;
  }

  open(event) {
    this.Modals.open('successfulSwap', event, {
      challenge: this.challenge,
      gspopup: this.gspopup
    });

    this.close();
  }
}
successfulSwapCardCtrl.$inject = [
  '$element', 'Modals'
];
export default {
  template: template,
  controller: successfulSwapCardCtrl,
  bindings: {
    challenge: '<',
    gspopup: "<",
    close: '&'
  }
};
