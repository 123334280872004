<div class="material-dialog gs-d-f gs-j-c-c gs-a-i-c gs-h-100-p">
  <ng-template materialDialogContent></ng-template>
  <div *ngIf="data.isXButton"
       [ngClass]="data.xButtonStyle"
       class="material-dialog__close-button"
       [mat-dialog-close]="CloseModalType.X_BUTTON">
    <mat-icon>close</mat-icon>
  </div>
  <mat-dialog-actions *ngIf="data.isOkButton || data.cancelButtonText"
                      align="center"
                      class="gs-m-t-24">
    <button *ngIf="data.isOkButton"
            mat-button
            mat-dialog-close
            class="gs-theme-button gs-theme-gradient-blue">{{data.okButtonText}}</button>
    <button
      *ngIf="data.cancelButtonText"
      [mat-dialog-close]="true"
      cdkFocusInitial
      class="gs-theme-text-caps-bold gs-theme-color-white_100 cancel-button"
    >{{data.cancelButtonText}}</button>
  </mat-dialog-actions>
</div>
