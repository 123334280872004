import {Injectable} from "@angular/core";
import {MaterialDialogComponent} from "../components/material-dialog/material-dialog.component";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {ComponentType} from "@angular/cdk/overlay";
import {SectionType} from "../../store/models/enums";
import {IStoreItem, IStoreItemReceived} from "../../store/services/store.service.types";
import {ResourceType} from "../../../core/models/enums";
import {StoreComponent} from "../../store/containers/store/store.component";
import {GsModalConfigType, OutOfResourceData, defaultsGsModalGeneral, confirmDataType} from "../models/types";
import {ConfirmationDialogComponent} from "../components/confirmation-dialog/confirmation-dialog.component";
import {firstValueFrom} from "rxjs";
import {CompletePurchaseComponent} from "../../store/components/complete-purchase/complete-purchase.component";
import {ConfirmPurchaseComponent} from "../../store/components/confirm-purchase/confirm-purchase.component";
import {OutOfResourceComponent} from "../../store/containers/out-of-resource/out-of-resource.component";
import {ChallengeFullComponent} from "../../challenges/components/challenge-full/challenge-full.component";
import {StoreItemInfoComponent} from "../../store/components/store-item-info/store-item-info.component";


@Injectable({
  providedIn: 'root'
})
export class NgModalsService {
  constructor(
    public dialog: MatDialog,
  ) {
  }

  closeAll(){
    this.dialog.closeAll();
  }

  gsModalConfig(): GsModalConfigType {
    return {
      storeItemInfo:{
        data: {
          ...defaultsGsModalGeneral,
          componentRef: StoreItemInfoComponent,
          isOkButton: false,
          received:[]
        },
      },
      // helpButton: {
      //   data: {
      //     ...defaultsGsModalGeneral,
      //     isOkButton: false,
      //     message: '',
      //     title: "Battles FAQ",
      //     isModalScroll: true,
      //     challenge: new NgChallengeModel(),
      //     isXButton: true,
      //     componentRef: HelpButtonContentComponent
      //   },
      //   panelClass: 'gs-modal',
      //   width: '528px',
      //   minHeight: '528px'
      // },
      store: {
        data: {
          ...defaultsGsModalGeneral,
          componentRef: StoreComponent,
          isOkButton: false,
          xButtonStyle: "style2"
        },
        panelClass: 'gs-modal-store',
        width: '80vw'
      },
      completePurchase: {
        data: {
          ...defaultsGsModalGeneral,
          componentRef: CompletePurchaseComponent,
          isOkButton: false,
          isXButton: false
        },
        width: '300px'
      },
      confirmPurchase: {
        data: {
          ...defaultsGsModalGeneral,
          componentRef: ConfirmPurchaseComponent,
          isOkButton: false,
        },
      },
      outOfCoins: {
        data: {
          ...defaultsGsModalGeneral,
          componentRef: OutOfResourceComponent,
          isOkButton: false
        },
        panelClass: 'gs-modal-out-of-resource',
        width: '375px'
      },
      fullChallenge: {
        data: {
          ...defaultsGsModalGeneral,
          componentRef: ChallengeFullComponent,
          isOkButton: false,
          isXButton: false,
          cancelButtonText: 'CLOSE'
        },
        panelClass: 'gs-modal-full-challenge',
        width: '360px'
      },
      confirm:{
        disableClose: false,
        minWidth: 350,
        data: {
          message: '',
        }
      }
    };
  }

  gsOpenDialog(config: MatDialogConfig): MatDialogRef<any> {
    config.closeOnNavigation = true;
    return this.openDialog(MaterialDialogComponent, config);
  }

  openDialog<T>(component:ComponentType<T>,config: MatDialogConfig){
    config.backdropClass = 'gs-modal-backdrop';
    return this.dialog.open(component, config);
  }

  materialDialogOpenStore(deepLink?: SectionType | ResourceType, storeItemId?:number): MatDialogRef<any> {
    let storeConfig = this.gsModalConfig().store;
    if(storeConfig.data){
      storeConfig.data.deeplink = deepLink;
      if(storeItemId){
        storeConfig.data.storeItemId = storeItemId;
      }
    }
    return this.gsOpenDialog(storeConfig);
  }

  materialDialogOpenCompletePurchase(storeItem: IStoreItem): MatDialogRef<any> {
    let matDialogConfigCompletePurchase = this.gsModalConfig().completePurchase;
    matDialogConfigCompletePurchase.data!.item = storeItem;
    return this.gsOpenDialog(matDialogConfigCompletePurchase);
  }

  materialDialogOpenOutOfCoins(data: OutOfResourceData): MatDialogRef<any> {
    let matDialogConfigOutOfCoins = this.gsModalConfig().outOfCoins;
    matDialogConfigOutOfCoins.data!.items = data.items;
    matDialogConfigOutOfCoins.data!.sectionType = data.sectionType;
    matDialogConfigOutOfCoins.data!.amountMissing = data.amountMissing;
    return this.gsOpenDialog(matDialogConfigOutOfCoins);
  }

  materialDialogOpenConfirmPurchase(data: {storeItem: IStoreItem, sectionType: SectionType}): MatDialogRef<any> {
    let matDialogConfigConfirmPurchase = this.gsModalConfig().confirmPurchase;
    matDialogConfigConfirmPurchase.data!.item = data.storeItem;
    matDialogConfigConfirmPurchase.data!.sectionType = data.sectionType;
    matDialogConfigConfirmPurchase.data!.itemSize = 100;
    return this.gsOpenDialog(matDialogConfigConfirmPurchase);
  }

  openChallengeIsFullDialog(): MatDialogRef<any> {
    let matDialogConfigFullChallenge = this.gsModalConfig().fullChallenge;
    return this.gsOpenDialog(matDialogConfigFullChallenge);
  }


  openStoreItemInfoModal(received: IStoreItemReceived[]): MatDialogRef<any> {
    let config = this.gsModalConfig().storeItemInfo;
    config.data!.received = received
    return this.gsOpenDialog(config);
  }

  confirm(data:confirmDataType): Promise<boolean> {
    let config = this.gsModalConfig().confirm;
    config.data!.resourceTypeAmount = data.resourceTypeAmount;
    config.data!.resourceType = data.resourceType;
    Object.assign(config.data!, data);
    const dialogRef =
      this.dialog.open(ConfirmationDialogComponent, config);
    return firstValueFrom(dialogRef.afterClosed());
  }
}
