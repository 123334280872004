import { Injectable } from '@angular/core';
import RequestService from "../../../core/services/request/requestService";
import RequestServiceModel from "../../../core/services/request/requestService.model";
import NgRequestEnum from "../../../core/services/request/request.enum";
import {Observable} from "rxjs";
import {RankingResponseInterface} from "../interfaces/ranking.interface";

@Injectable({
  providedIn: 'root'
})
export class RankingService {

  constructor(
    private requestService: RequestService
  ) { }

  getRanking(
    c_id: string,
    init = false,
    index: null | number = null,
    limit_above = 100,
    limit_below = 100,
  ): Observable<RankingResponseInterface> {
    const indexOption = index || index === 0 ? {index} : {};
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_FLASH_RANKING.NAME,
      body: {
        c_id,
        init,
        limit_above,
        limit_below,
        ...indexOption
      }
    });
    return this.requestService.request<RankingResponseInterface>(conf);
  }
}
