const template = require(`html-loader!./gsIcon.html`).default;
import gsIconEnum from "../../api/enums/gsIcon.enum";

class gsIconCtrl {
  constructor() {
    'ngInject';
  }

  $onInit() {
    this.icon = gsIconEnum.TYPE[this.type.NAME];
  }
}

gsIconCtrl.$inject = [

];

export default {
  template: template,
  controller: gsIconCtrl,
  bindings: {
    type: '<',
    value: '<',
    customTitle: '@',
    showTitle: '@',
    valueFirst: '@'
  }
};
