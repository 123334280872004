import {delay, map, Subject, takeUntil} from "rxjs";

const template = require(`html-loader!./challengesSponsorsEdit.html`).default;
import environment from '../../gsApp.environment';
import memberEnum from "../../api/enums/member.enum";

class challengesSponsorsEdit {
  subject = new Subject();
  constructor(
    challengesSponsorsService,
    notificationAlert,
    RestManager,
    $rootScope,
    RouterService
  ) {
    'ngInject';

    this.memberEnum = memberEnum;

    this.sponsorFormDataModel = {
      id: null,
      brand: null,
      name: null,
      description: null,
      url: null,
      image_id: null
    };
    this.sponsorFormData = this.sponsorFormDataModel;
    this.notificationAlert = notificationAlert;
    this.challengesSponsorsService = challengesSponsorsService;
    this.RestManager = RestManager;
    this.rootScope = $rootScope;
    this.RouterService = RouterService;

    /*this.photoDomain = new Image();*/
    /*this.srcPreview = new Image().src;*/
    /* TODO: create service for Image() to generate links; */
    this.photoDomain = 'https://' + environment.domains.photos;

    this.title = 'none';
    this.sponsorsConfig = this.challengesSponsorsService.getSponsorConfig();

    this.RouterService.activatedRoute
      .queryParams.pipe(
      delay(10),
      map(() => window.history.state),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      this.title = params.id ? `Edit ${this.sponsorsConfig.buttonText} with ID:${params.id}` : `Create new ${this.sponsorsConfig.buttonText}`;

      if (params.data) {
        this.stateParamsToForm(params);
      } else {
        this.goToNew();
      }
    });
  }

  stateParamsToForm(params) {
    Object
      .keys(params.data)
      .forEach(stateParamItem => {
        let stateParamValue = params.data[stateParamItem];
        stateParamValue = typeof stateParamValue === 'string' ? stateParamValue.trim() : stateParamValue;
        this.sponsorFormData[stateParamItem] = stateParamValue;
      });
  }

  goToHome() {
    this.RouterService.go(this.sponsorsConfig.states.home);
  }

  goToNew() {
    this.RouterService.go(this.sponsorsConfig.states.new);
  }

  async sponsorsSubmit() {
    await this.challengesSponsorsService.createSponsor(this.sponsorFormData);
    if (this.sponsorFormData.id) {
      this.rootScope.$emit('challengeSponsorsChanged', this.sponsorFormData);
    } else {
      this.rootScope.$emit('challengeSponsorsSave');
    }
    this.goToHome();
  }

  help() {
    this.notificationAlert.open(
      `<div class="challenges-sponsors-hint-text">
    URL validation
    <ul>
        <li>
            Valid
            <ul>
                <li>http://www.foufos.gr</li>
                <li>https://www.foufos.gr</li>
                <li>http://foufos.gr</li>
                <li>http://www.foufos.gr/kino</li>
                <li>http://werer.gr</li>
                <li>www.foufos.gr</li>
                <li>www.mp3.com</li>
                <li>www.t.co</li>
                <li>http://t.co</li>
                <li>http://www.t.co</li>
                <li>https://www.t.co</li>
                <li>www.aa.com</li>
                <li>http://aa.com</li>
                <li>http://www.aa.com</li>
                <li>https://www.aa.com</li>
            </ul>
        </li>
        <li>
            Not valid
            <ul>
                <li>www.foufos</li>
                <li>www.foufos-.gr</li>
                <li>www.-foufos.gr</li>
                <li>foufos.gr</li>
                <li>http://www.foufos</li>
                <li>http://foufos</li>
                <li>www.mp3#.com</li>
            </ul>
        </li>
    </ul>
</div>`,
      false
    );
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}

challengesSponsorsEdit.$inject = [
  'challengesSponsorsService',
  'notificationAlert',
  'RestManager',
  '$rootScope',
  'RouterService'
];

export default {
  template: template,
  controller: challengesSponsorsEdit
};
