import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, EMPTY, Observable} from "rxjs";

import RequestServiceModel from './requestService.model';
import ObjectHelper from '../../helpers/object.helper';
import requestEnum from "./request.enum";
import {UrlService} from "../url.service";

@Injectable({providedIn: 'root'})
class RequestService {
  constructor(
    private httpClient: HttpClient,
    private urlService: UrlService,
  ) {}

  request<T>(config: RequestServiceModel):Observable<T> {
    if (config.url) {
      /* TODO: LocalServer */
      return this.httpClient.get<T>(
        config.url,
        {
          responseType: 'json'
        }
      ).pipe(
        catchError(this.handleHttpError)
      );
    } else {
      config.url = this.getRequestUrl(config);
      this.setRequestBody(config);

      return this.httpClient.post<T>(
        config.url,
        config.body,
      ).pipe(
        catchError(this.handleHttpError)
      );
    }

    // if (!response.success) {
    //   const errorMessage = {
    //     url: config.url,
    //     response: response
    //   };
    //
    //   this.error(errorMessage);
    // }
    //
    // return response;
  }

  private getRequestUrl(config: RequestServiceModel){
    config.url = this.urlService.getApiBaseUrl(config.endPointController);
    if (config.getMockData){
      config.url += requestEnum.END_POINTS.GET_MOCK_DATA.NAME;
      config.body =
        {
          endpoint: config.endPoint,
          state: config.mockDataState
        }
    } else {
      config.url += config.endPoint;
    }
    return config.url
  }

  private setRequestBody(config: RequestServiceModel){
    if(config.body){
      config.body = ObjectHelper.jsonToURLSearchParams(config.body);
    }
  }

  error(error: any) {
    /**
     * TODO: GSDEV-3325
     *
     * After toaster server notification would be implemented
     *
     * */
    /*this.toasterService.error(error);*/
    console.log('request error');
    console.log(error);
  }

  private handleHttpError(error: HttpErrorResponse) {
    console.error(
      `Backend returned code ${error.status}, body was: `, error.error, error);
    // return throwError(() => new Error(error.error));
    return EMPTY;
  }
}

export default RequestService;
