import teamEnum from '../../enums/team.enum';
import TeamMemberModel from './teamMember.model';
import CountryModel from './country.model';
import LanguageModel from './language.model';
import LeagueRankModel from './leagueRank.model';
import LeagueModel from './league.model';
import {pareDateFromUnixTime} from "../../../helpers/date.helper";

class TeamModel {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.chat_id = props.chat_id;
    this.points = props.points;
    this.required_status = props.required_status;
    this.required_status_name = props.required_status_name;
    this.status = props.status;
    this.team_capacity = props.team_capacity;
    this.description = props.description;
    this.type = props.type;
    this.wins = props.wins;
    this.member_count = props.member_count;
    this.total_votes = props.total_votes;
    this.badge = props.badge ? new TeamBadge(props.badge) : props.badge;
    this.members = props.members ? props.members.map(member => new TeamMemberModel(member)) : [];
    this.current_member = props.current_member ? new TeamMemberModel(props.current_member) : props.current_member;
    this.score = props.score;
    this.can_join = props.can_join;
    this.language = props.language ? new LanguageModel(props.language) : props.language;
    this.country = props.country ? new CountryModel(props.country) : props.country;
    //for admin
    this.date_created = pareDateFromUnixTime(props.date_created);

    if(props.rank) {
      this.rank = new LeagueRankModel(props.rank);
    }

    if(props.league) {
      this.league = new LeagueModel(props.league);
    }
  }

  ifFull() {
    return this.member_count >= this.team_capacity;
  }

  isClosed() {
    return this.type === teamEnum.TYPE.CLOSED.VALUE;
  }

  isDeleted() {
    return this.status === teamEnum.STATUS.DELETED;
  }

  isRequiredStatusReached(memberStatus) {
    return memberStatus && (memberStatus >= this.required_status);
  }
}

class TeamBadge {
  constructor({icon_id, icon_url}) {
    this.icon_id = icon_id;
    this.icon_url = icon_url;
  }
}

export default TeamModel;
