import { Component } from '@angular/core';

@Component({
  selector: 'app-activity-wrapper',
  templateUrl: './activity-wrapper.component.html',
  styleUrl: './activity-wrapper.component.scss'
})
export class ActivityWrapperComponent {

}
