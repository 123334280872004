import {delay, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./dl.html`).default;

class dlCtrl {
  subject = new Subject();
  constructor(
    pageData,
    $cookies,
    deepLinkService,
    $rootScope,
    $timeout,
    MemberService,
    RouterService
  ) {
    'ngInject';
    this.pageData = pageData;
    this.$cookies = $cookies;
    this.deepLinkService = deepLinkService;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.MemberService = MemberService;
    this.RouterService = RouterService;

    this.RouterService.activatedRoute
      .params.pipe(
      delay(50),
      takeUntil(this.subject)
    ).subscribe(async () => {
      await this.init();
    });
  }

  async init() {
    this.member = this.MemberService.getCurrentMember();
    if (!this.state) {
      this.RouterService.go('challenges/my-challenges/current');
      return;
    }

    let dl = {params: {}};
    Object.assign(dl, this.state);

    if (this.id) {
      Object.assign(dl.params, {cId: this.id});
    }

    if (dl.type === 'profileUpload') {
      dl.params.cUserName =  this.member?.user_name;
    }

    let expired = new Date();
    expired.setDate(expired.getDate() + 1);
    this.$cookies.putObject('dl', dl, {expires: expired});

    if (this.member?.logged_in === false) {
      this.RouterService.go('');
      return;
    }

    this.RouterService.go(dl.redirectState, dl.params);
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
dlCtrl.$inject = [
  'pageData',
  '$cookies',
  'deepLinkService',
  '$rootScope',
  '$timeout',
  'MemberService',
  'RouterService'
];

export default {
  template: template,
  controller: dlCtrl,
  bindings: {
    state: '<',
    id: '<'
  }
};
