import {Component, Input} from '@angular/core';
import {
  animationFrameScheduler, BehaviorSubject,
  distinctUntilChanged,
  endWith, iif,
  interval,
  Observable, of,
  switchMap,
  takeWhile
} from "rxjs";
import {map} from "rxjs/operators";
import {PlayersAmountService} from "./players-amount.service";

@Component({
  selector: 'app-players-amount',
  templateUrl: './players-amount.component.html',
  styleUrl: './players-amount.component.scss'
})
export class PlayersAmountComponent {
  @Input() set players(value: number) {
    this.playersSubject.next(value);
  }

  @Input() maxPlayers = 0;
  @Input() duration = 1000;
  @Input() challengeId = 0;

  public players$: Observable<number> = new Observable<number>()
  public playersSubject = new BehaviorSubject<number>(0);

  constructor(
    private playersAmountService: PlayersAmountService
  ) {
    this.players$ = this.playersSubject.pipe(
      switchMap((players) => {
        const initialPlayers = this.playersAmountService.getPlayersAmount(this.challengeId) ?? 0;
        const isNotAnimatedNumber = (players === this.playersAmountService.getPlayersAmount(this.challengeId)) ||
          this.playersAmountService.getPlayersAmount(this.challengeId) === undefined;
        const startTime = animationFrameScheduler.now();
        return iif(() => isNotAnimatedNumber, this.getLastPlayers(players),
          interval(0, animationFrameScheduler).pipe(
            map(() => animationFrameScheduler.now() - startTime),
            map((elapsedTime) => elapsedTime / this.duration),
            takeWhile((progress) => progress <= 1),
            map((progress) => {
                const playersToAdd = Math.ceil(progress * (players - initialPlayers));
                this.playersAmountService.setPlayersAmount(this.challengeId, players);
                return initialPlayers + playersToAdd;
              }
            ),
            endWith(players),
            distinctUntilChanged()
          )
        )
      })
    );
  }

  getLastPlayers(players: number): Observable<number> {
    this.playersAmountService.setPlayersAmount(this.challengeId, players);
    return of(players);
  }
}
