//Intro
const intro_step2_tpl = require(`html-loader!./tpl/intro_step2.tpl.html`).default;
const intro_step7_tpl = require(`html-loader!./tpl/intro_step7.tpl.html`).default;
const intro_step12_tpl = require(`html-loader!./tpl/intro_step12.tpl.html`).default;
const introMobile_step1_tpl = require(`html-loader!./tpl/introMobile_step1.tpl.html`).default;
const introMobile_step6_tpl = require(`html-loader!./tpl/introMobile_step6.tpl.html`).default;
const introMobile_step13_tpl = require(`html-loader!./tpl/introMobile_step13.tpl.html`).default;


import modalIntroCtrl from './intro.ctrl';

export const introConf = {
  controller: modalIntroCtrl,
  controllerAs: 'intro',
  template: '[see this.templates]',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: false,
  fullscreen: false,
  templates: {
    2: intro_step2_tpl,
    7: intro_step7_tpl,
    12: intro_step12_tpl
  }
};

export const introMobileConf = {
  controller: modalIntroCtrl,
  controllerAs: 'intro',
  template: '[see this.templates]',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: false,
  fullscreen: false,
  templates: {
    1: introMobile_step1_tpl,
    6: introMobile_step6_tpl,
    13: introMobile_step13_tpl
  }
};
