import {Component} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-challenge-rank-top-photographer-wrapper',
  templateUrl: './challenge-rank-top-photographer-wrapper.component.html',
  styleUrl: './challenge-rank-top-photographer-wrapper.component.scss'
})
export class ChallengeRankTopPhotographerWrapperComponent {
  cUrl = null;
  subject = new Subject();

  constructor(
    private route: ActivatedRoute
  ) {
    // @ts-ignore
    this.route.parent.parent.params.pipe(
      takeUntilDestroyed()
    ).subscribe(async (params: any) => {
      this.cUrl = params.cUrl;
    });
  }
}
