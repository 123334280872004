const template = require(`html-loader!./challengeLocked.html`).default;

class challengeLockedCtrl {
  /**
   * @param {RestManager} RestManager
   * @param {GsModalsService} gsModals
   * */

  constructor($mdDialog, gsModals) {
    "ngInject";
    this.$mdDialog = $mdDialog;
    this.gsModals = gsModals;
  }

  $onInit() {
    this.challenge = this.data.challenge;
  }

  vote() {
    this.close();
    this.gsModals.open('vote', {challenge: this.challenge});
  }

  close() {
    this.$mdDialog.hide();
  }
}
challengeLockedCtrl.$inject = [
  '$mdDialog', 'gsModals'
];
export default {
  template: template,
  controller: challengeLockedCtrl,
  bindings: {
    data: '=data'
  }
};
