import {Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./gsGallery.html`).default;
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";

class photosController {

  constructor(
    PhotosManager,
    $window,
    $timeout,
    $rootScope,
    $scope,
    $cookies,
    photoSwipe,
    Modals,
    notificationAlert,
    stateService,
    tagEventService,
    mixpanelService,
    infinityScrollService,
    MemberService,
  ) {
    'ngInject';
    this.$ctrl = this;
    this.subject = new Subject();
    this.type = 'photos';
    this.start = 0;
    this.limit = 40;
    this.items;
    this.allLoaded = false;
    this.loading = false;
    this.inited = false;
    this.imgHeight = 300;
    this.searchText = '';
    this.filters = {
      Votes: { order: 'votes', sort: 'desc' },
      Achievements: { order: 'achievements', sort: 'desc' },
      Newest: { order: 'date', sort: 'desc' },
      Oldest: { order: 'date', sort: 'asc' },
    };

    this.PhotosManager = PhotosManager;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$cookies = $cookies;
    this.photoSwipe = photoSwipe;
    this.Modals = Modals;
    this.notificationAlert = notificationAlert;
    this.stateService = stateService;
    this.tagEventService = tagEventService;
    this.mixpanelService = mixpanelService;
    this.infinityScrollService = infinityScrollService;
    this.MemberService = MemberService;
    this.loggedinMember = MemberService.getCurrentMember();

    this.infinityScrollService.initWindowScrollEvent(); /* Init windowInfinityScroll */
    this.$rootScope.$on('windowInfinityScroll', this.loadMore.bind(this)); /* Subscribe windowInfinityScroll */

    this.loggedinMember = this.MemberService.getCurrentMember();
    this.filtersName = Object.keys(this.filters);
    this.filter =
      this.loggedinMember?.id === this.MemberService.profilePageMember?.id
        ? this.$cookies.get('profile_filter') || this.filtersName[0]
        : this.filtersName[0];

    if (this.MemberService.profilePageMember?.member_group_id === 2000) {
      this.filters = {
        Newest: { order: 'date', sort: 'desc' },
        Oldest: { order: 'date', sort: 'asc' },
      };
    }
    setTimeout(()=>{
      this.loadMore();
    })
  }

  $onInit() {
    this.isMobile = document.body.clientWidth <= 961;

    this.tagEventService.sendPageViewEvent({
      sendToMixPanel:false,
      title: ('Profile page' + this.type)
    });
    this.eventMixPanelAction();
  }

  eventMixPanelAction() {
    this.mixpanelService.trackProfilePageImpression({
      ui_name: mixpanelEventsEnum.UI_NAME[this.type.toUpperCase()]
    });
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    this.infinityScrollService.offWindowScrollEvent(); /* Unsubscribe windowInfinityScroll */
  }

  async loadMore() {
    if (!this.MemberService.profilePageMember || !this.type || this.loading || this.allLoaded) {
      return;
    }

    this.loading = true;

    // prepare params
    let params = {
      member_id: this.MemberService.profilePageMember.id,
      start: this.start,
      limit: this.limit,
      type: this.type,
      search: this.searchText,
      get_likes: true,
      get_liked: true,
      get_votes: true,
      get_member: true,
      get_achievements: true,
    };
    Object.assign(params, this.filters[this.filter]);

    // select service
    let service;
    switch (this.type) {
      case 'photos':
        service = 'getPhotosPublic';
        break;
      case 'likes':
        service = 'getProfileLikes';
        break;
    }

    // get data
    const res = await this.PhotosManager[service](params);

    if (!res.success) {
      this.loading = false;
    }

    // all loaded
    if (res.items.length < this.limit) {
      this.allLoaded = true;
    }

    this.lastSearchText = _.clone(this.searchText);

    // no items
    if (res.items.length === 0) {
      this.inited = true;
      this.loading = false;

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }

      return;
    }
    if(!this.items){
      this.items = [];
    }

    this.items.push(...res.items);
    this.start += this.limit;
    this.inited = true;
    this.loading = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  removePhoto($index, photoId) {
    this.items[$index].isDeleted = true;
    this.PhotosManager.deleteProfileImage(photoId).then(res => {
      this.items[$index].isDeleted = res.success;
    });
  }

  gridAnimationDelete({index}) {
    this.items.splice(index, 1);
  }

  async remove(image) {
    const confirm = await this.Modals.confirm(
      'Delete Photo?',
      'Photo will be deleted from your profile',
      'Delete',
      'Cancel'
    );
    if (!confirm) {
      return;
    }

    const calculatedIndex = this.items.findIndex((item) => item.id === image.id);
    const res = await this.PhotosManager.deleteProfileImage(this.items[calculatedIndex].id);
    /*const res = {success: true};*/
    if (res.success) {
      if(res.error_type){
        if(res.error_type === 'active_challenges'){
          const goToActiveChallenges = await this.Modals.confirm(
            `Can't delete photo`,
            `This photo is in a challenge, please swap the photo or wait until the challenge is over before deleting.`,
            'Go to Active Challenges',
            'Back'
          );
          if (goToActiveChallenges) {
            this.stateService.goToDefaultState();
          }
          return;
        }
        if(res.error_type === 'image_won'){
          await this.notificationAlert.info({
            message: `You can not delete a winning photo from your account. If you wish to remove a winning photo, you will need to delete your account.`,
            buttonText: 'Ok',
            title: `Can't delete photo`
          });
          return;
        }
      } else {
        this.gridAnimationDelete({index: calculatedIndex});
      }
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onChangeFilter(filter) {
    if (this.filter === filter || this.filtersName.indexOf(filter) === -1) {
      return false;
    }
    this.reset();
    this.filter = filter;
    if (this.MemberService.isMyProfilePage) {
      this.$cookies.put('profile_filter', filter);
    }
    this.inited = false;
    this.loadMore();
  }

  search() {
    this.$timeout(() => {
      this.reset();
      this.loadMore();
    }, 100);
  }

  reset() {
    this.start = 0;
    this.limit = 50;
    this.items = [];
    this.allLoaded = false;
    this.loading = false;
    this.inited = false;
  }

  localSearch(str) {
    return this.PhotosManager.searchAutocomplete(str, this.MemberService.profilePageMember.id).then(res => res.items);
  }
}
photosController.$inject = [
  'PhotosManager',
  '$window',
  '$timeout',
  '$rootScope',
  '$scope',
  '$cookies',
  'photoSwipe',
  'Modals',
  'notificationAlert',
  'stateService',
  'tagEventService',
  'mixpanelService',
  'infinityScrollService',
  'MemberService',
];
export default {
  template: template,
  controller: photosController,
  bindings: {
    type: '@',
  },
};
