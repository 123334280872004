import { Component } from '@angular/core';
import {moderatorReason} from "../../gsApp/app/pages/moderation/moderationPage.data";

@Component({
  selector: 'app-moderation-adult-wrapper',
  templateUrl: './moderation-adult-wrapper.component.html',
  styleUrl: './moderation-adult-wrapper.component.scss'
})
export class ModerationAdultWrapperComponent {
  reason = moderatorReason.ADULT_CONTENT;
}
