const template = require(`html-loader!./statusUp.html`).default;

class modalStatusUpCtrl {
  /**
   * @param {RestManager} RestManager
   * @param {NotificationsManager} NotificationsManager
   * */

  constructor($mdDialog, Modals, $http, Toasts, RestManager, NotificationsManager) {
    "ngInject";
    this.stage = 1;
    this.showShareBtns = false;

    this.$mdDialog = $mdDialog;
    this.Modals = Modals;
    this.$http = $http;
    this.RestManager = RestManager;
    this.Toasts = Toasts;
    this.NotificationsManager = NotificationsManager;
  }

  $onChanges() {
    if (!this.data || !this.data.status) {
      return;
    }

    this.init();
  }

  init() {
    this.busy = true;
    this.RestManager.getMemberStatusResult(this.data.status).then((res) => {
      if (!res.success) {
        this.close();
        return;
      }

      this.busy = false;

      this.ss_url = res.items.ss_url;
      this.share_urls = res.items.share_urls;
      this.rewards = res.items.rewards;
    });
  }

  shareResult(event) {
    let btn = $(event.currentTarget);
    btn.addClass('run');

    this.getImage()
      .then(() => {
        this.showShareBtns = true;
      });
  }

  getImage() {
    return $.ajax({
      url: this.ss_url,
      type: 'GET'
    }).done(()=> true);
  }

  copyDone(event) {
    //Show toast
    this.Toasts.open('copy', {}, $('.c-modal-status-up__share__item.icon-chain')[0]);
  }

  //Track click
  shareClick(va_id) {
    this.RestManager.trackViralSent(va_id);
  }

  next() {
    this.showShareBtns = false;
    this.stage = 2;
  }

  close() {
    if(this.data.message) {
      this.NotificationsManager.readNotifications(this.data.message.date, 'activity');
    }
    this.$mdDialog.hide();
  }
}
modalStatusUpCtrl.$inject = [
  '$mdDialog', 'Modals', '$http', 'Toasts', 'RestManager', 'NotificationsManager'
];
export default {
  template: template,
  controller: modalStatusUpCtrl,
  bindings: {
    data: '<data'
  }
};
