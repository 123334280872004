/**
 * DEPRECATED
 * use components from custom folder instead
 * */

const template = require(`html-loader!./brazeContentCards.html`).default;


class brazeContentCards {
  constructor(
    brazeService
  ) {
    'ngInject';

    this.brazeService = brazeService;
  }

  $onInit() {
    const brazeContentCaptionedImageConfig = {
      feedElement: document.getElementById("gsBrazeContentCards"),
      filterConfig: ''
    };

    /*this.brazeService.getFilteredContentCardsBrazeFlow(brazeContentCaptionedImageConfig);*/
  }
}

brazeContentCards.$inject = [
  'brazeService'
];

export default {
  template: template,
  controller: brazeContentCards
};
