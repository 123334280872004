import HttpInterceptor from "./HttpInterceptor";

class MyHttpInterceptor extends HttpInterceptor {
  constructor($window, $injector, $q, NgTokenService) {
    'ngInject';
    super();

    this.retry = false;
    this.$window = $window;
    this.$injector = $injector;
    this.$q = $q;
    this.tokenService = NgTokenService;
  }

  response(response) {
    const res = response.data;
    if (!res.success && res.error_code === 10000) {
      this.$injector.get('Modals').open('appUpdated');
    }

    return response;
  }

  async responseError(response){
    console.log('responseError', response);
    // if(response.status === 401 && configEnum.GWT_ENABLED){//jwt
    //   const $http = this.$injector.get('$http');
    //   const AuthenticationManager = this.$injector.get('AuthenticationManager');
    //   const tokenService = this.$injector.get('NgTokenService');
    //   if(!this.lock){ // lock the other 401 responses
    //     this.lock = true;
    //     const token = await AuthenticationManager.getAccessToken();
    //     tokenService.setAccessToken(token);
    //     tokenService.setAuthorizationHeader(token, response);
    //     await new Promise(resolve => setTimeout(resolve, 2000));
    //     this.lock = false;
    //   }else{
    //     await new Promise(resolve => setTimeout(resolve, 2000));
    //     tokenService.setAuthorizationHeader(tokenService.getAccessToken(), response);
    //   }
    //   return await $http(response.config);
    // }

    if(response.status === 503){
      this.$injector.get('stateService').goToState('gs.error.maintenance');

      return {data:{status:503}};
    }

    return this.$q.reject(response);
  }
}
MyHttpInterceptor.$inject = ['$window', '$injector', '$q', 'NgTokenService'];

export default MyHttpInterceptor;

