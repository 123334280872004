import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

export interface IUpdateMetaTags {
  title:string;
  description:string;
  keywords: string[];
}

@Injectable()
export class NgMetaDataService {

  defaults = {
    description:`Join GuruShots, the world's leading photography game, and compete in challenges, get inspired by amazing photos, and showcase your work to a global audience.`,
    keywords:['photography', 'photo challenges', 'photography game', 'GuruShots', 'online photography', 'photo contests'],
    title:`The World's Greatest Photography Game`
  }
  constructor(
    private titleService: Title,
    private metaService: Meta,
  ) { }

  updateMetaTags(data: IUpdateMetaTags = this.defaults): void {
   data.title = data.title || this.defaults.title;
   data.description = data.description || this.defaults.description;
   data.keywords = data.keywords || this.defaults.keywords;
   this.setTitle(data.title);
    this.metaService.updateTag({ name: 'description', content: data.description });
    this.metaService.updateTag({ property: 'og:description', content: data.description });
    this.metaService.updateTag({ name: 'keywords', content: data.keywords.join(', ') });
  }

  setTitle(title:string){
    title = `${title} | GuruShots`
    this.titleService.setTitle(title);
    this.metaService.updateTag({ property: 'og:title', content: title });
  }
}
