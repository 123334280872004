import { Component } from '@angular/core';

@Component({
  selector: 'app-mobile-menu-wrapper',
  templateUrl: './mobile-menu-wrapper.component.html',
  styleUrl: './mobile-menu-wrapper.component.scss'
})
export class MobileMenuWrapperComponent {

}
