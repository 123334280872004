<div
  [ngClass]="{'green-border': mission.claim_state === missionClaimState.CLAIM}"
  class="mission-card-wrapper">

  <div [ngClass]="{'gs-red-dot': isMissionDirty(mission.id)}"></div>

  <div class="mission-card-icon">
    <img [ngSrc]="mission.icon.svg_url" width="80" height="80" alt="Mission Icon">
    <div
      class="mission-icon-info"
      customToolTip
      [contentTemplate]="tooltipTemplate"
      [overlayX]="'center'"
      [offsetX]="90"
      [offsetY]="10"
    ></div>
  </div>

  <div
    class="mission-card-progress-wrapper"
  >
    <div class="mission-card-progress-title gs-theme-text-bold">{{mission.name}}</div>
    <div class="mission-card-progress">
      <div
        *ngIf="mission.progress.current === mission.progress.required"
        class="mission-card-success-icon"
        [ngClass]="{'disable-animation': isAnimationDisabled}"
      ></div>

      <div class="mission-card-progress-steps">{{mission.progress.current}}/{{mission.progress.required}}</div>

      <div
        class="mission-card-progress-success"
        [ngStyle]="{ width: getProgressWidth() }"
        [ngClass]="{
            'all-corners-radius': mission.progress.required === mission.progress.current,
            'disable-animation': isAnimationDisabled
         }"
      ></div>
    </div>
  </div>

  <div class="mission-card-prizes">
    <app-ng-rewards
      class="gs-w-f-c"
      [prizes]="mission.prizes"
      [title]="'Prize'"
      [styleClass]="'rank-mission-item'"
    ></app-ng-rewards>
  </div>

  <div class="mission-card-action-button">
      <button
        [ngClass]="{'claim-button': mission.claim_state === missionClaimState.CLAIM}"
        (click)="actionButtonClick()"
      >
        {{mission.claim_state === missionClaimState.DISABLED ? 'Go' : 'Claim'}}
      </button>

    <ng-container *ngIf="mission.expiration_timestamp && mission.claim_state !== missionClaimState.CLAIM">
      <div
        class="clock"
        [ngClass]="{'clock-red': countDownTime < NgDateHelper.millisecondsInSecond * NgDateHelper.secondsInMinute * NgDateHelper.minutesInHour}">
        <i class="icon-timer-clock"></i>
        {{countDownTime | gsTimer : gsTimerEnum.MISSION_CARD_TIMER}}
      </div>
    </ng-container>

  </div>
</div>


<ng-template #tooltipTemplate>
  <div class="mission-card-custom-tooltip">
    <div class="mission-card-custom-tooltip-text">{{mission.description}}</div>
  </div>
  <div class="mission-card-custom-tooltip-square"></div>
</ng-template>
