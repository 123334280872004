const template = require(`html-loader!./adminExhibitionsVirtual.html`).default;
import VirtualExhibitionModel from "../../../../../api/models/exhibition/virtualExhibition.model";

class adminExhibitionsVirtualCtrl {

  constructor(RestManager, $scope, RouterService) {
    'ngInject';
    this.busy = false;

    this.RestManager = RestManager;
    this.$scope = $scope;
    this.RouterService = RouterService;
  }

  $onInit() {
    this.loadData();
  }

  async loadData() {
    this.busy = true;
    let res = await this.RestManager.getVirtualExhibitions();
    this.busy = false;
    if (!res.success) return;
    this.virtualExhibitions = res.exhibitions.map( exhibition => new VirtualExhibitionModel(exhibition));
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  editEvent(event) {
    this.RouterService.go('admin/contentManager/events/editLbEvent', { event: event });
  }

  async deleteVirtualExhibition(virtualExhibition){
    if(!this.busy){
      this.busy = true;
      let res = await this.RestManager.deleteVirtualExhibition(virtualExhibition.id);
      this.busy = false;
      if (!res.success) return;
      this.loadData();
    }
  }

  editExhibition(virtualExhibition) {
    this.RouterService.r.navigate(['admin/contentManager/exhibitions/virtualEdit'], {state: {virtualExhibition}})
  }
}
adminExhibitionsVirtualCtrl.$inject = [
  'RestManager', '$scope', 'RouterService'
];
export default {
  template: template,
  controller: adminExhibitionsVirtualCtrl,
  bindings: {},
};
