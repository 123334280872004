const teamEnum = Object.freeze({
  STATE: {
    LOCKED: 'STATUS_LOCKED',
    UNLOCKED: 'STATUS_UNLOCKED',
    JOINED: 'STATUS_JOINED',
    FAILURE: 'GENERAL_ERROR',
    SUCCESS: 'OK',
  },
  STATUS: {
    ACTIVE: 'ACTIVE',
    DELETED: 'DELETED',
  },
  DETAILS: {
    TYPES: {
      SCORE: 'score',
      SCORE_WHITE: 'scoreWhite',
      WIN: 'win',
      MEMBERS: 'members',
      STATUS: 'status',
      LOSTS: 'losts',
      TOTAL_MATCHES: 'totalMatches',
      VOTE: 'vote',
      LANGUAGE: 'language',
      COUNTRY: 'country'
    }
  },
  NAME_VALIDATION: {
    VALID: 'GENERAL_ERROR',
    INVALID: 'OK',
  },
  TYPE: {
    OPEN: {
      NAME: 'Open Team',
      VALUE: 'OPEN'
    },
    CLOSED: {
      NAME: 'Private Team',
      VALUE: 'CLOSED'
    }
  },
  INTRO_STATES: {
    ACTIVE_MATCH_1: 'active_match_1',
    ACTIVE_MATCH_2: 'active_match_2',
    AVAILABLE_MATCH: 'available_match',
    JOIN: 'join',
    LEADERBOARD: 'leaderboard',
    LEAGUES: 'leagues',
    MY_TEAM: 'my_team',
    TEAM_LEADER: 'team_leader',
  },
  MEMBER: {
    STATUS: {
      DECLINED: 'DECLINED',
      LEFT: 'LEFT',
      ACTIVE: 'ACTIVE',
      WAITING: 'WAITING'
    },
    STATE: {
      IN_TEAM: 'IN_TEAM',
      NONE: 'NONE',
      INVITE: 'INVITE',
      INVITED: 'INVITED',
    },
    TYPE: {
      CREATOR: 'CREATOR',
      ADMIN: 'ADMIN',
      USER: 'USER',
    }
  },
  MATCH_STATUS: {
    WAITING_FOR_MINIMUM: 'WAITING_FOR_MINIMUM',
    WAITING_FOR_OPPONENT: 'WAITING_FOR_OPPONENT',
    TIMEOUT: 'TIMEOUT',
    CANCELLED: 'CANCELLED',
    RUNNING: 'RUNNING',
    FINALIZING: 'FINALIZING',
    CLOSED: 'CLOSED',
    NO_ACTIVE_MATCH: 'NO_ACTIVE_MATCH'
  },
  JOIN_BY: {
    SUGGEST: `SUGGEST`,
    DEFAULT: `DEFAULT`,
    INVITE: `INVITE`
  }
});

export default teamEnum;
