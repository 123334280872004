import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-following-wrapper',
  templateUrl: './profile-following-wrapper.component.html',
  styleUrl: './profile-following-wrapper.component.scss'
})
export class ProfileFollowingWrapperComponent {

}
