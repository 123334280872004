import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import NgTokenService from '../services/ng-token.service';
import {catchError} from "rxjs/operators";
import {MediatorService} from "../services/mediator.service";

@Injectable()
class GsHttpInterceptor implements HttpInterceptor {
  constructor(
    public tokenService: NgTokenService,
    private mediatorService: MediatorService,
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const headers: {[key: string]: string} = {
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest',
      'X-ENV': 'WEB',
      'X-API-VERSION': '13'
    };
    if (this.tokenService.memberToken) {
      headers['X-TOKEN'] = this.tokenService.memberToken;
    }

    if (this.tokenService.adminToken) {
      headers['X-ADMIN-TOKEN'] = this.tokenService.adminToken;
    }

    const modified = req.clone({
      setHeaders: headers
    });

    return next
      .handle(modified)
      .pipe(
        // takeUntil(this.httpCancelService.onCancelPendingRequests()),
        catchError(
          (error: HttpErrorResponse) => {

            switch (error.status) {
              case 503:
                this.mediatorService.gotToState.next("error/maintenance");
                break;
              default:
                // this.mediatorService.gotToState.next("error/maintenance");
                break;
            }

            return throwError(() => new Error('Request error interceptor.'));
        }
      )
    );
  }
}

export default GsHttpInterceptor;
