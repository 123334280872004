const template = require(`html-loader!./preSubmit.html`).default;
import badgeV2Mode from "../../../api/enums/badgeV2.enum";
import mixpanelEventsEnum from "../../mixpanel/mixpanel.enum";

class PreSubmitCtrl {
  /**
   * @param {$mdDialog} $mdDialog
   * @param {ModalsService} Modals
   * */

  constructor(
    $mdDialog,
    Modals,
    mixpanelService
  ) {
    'ngInject';
    this.$ctrl = this;
    this.fullBrief = false;

    this.$mdDialog = $mdDialog;
    this.Modals = Modals;
    this.badgeV2Mode = badgeV2Mode;
    this.mixpanelService = mixpanelService;
    this.mixpanelEventsEnum = mixpanelEventsEnum;
  }

  hide() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.CLOSE);
    this.$mdDialog.hide();
  }

  eventMixPanelAction(uiName) {
    this.mixpanelService.track(
      {
        data: {
          /* TODO: Omri, why we have here this.DATA.challenge? In other parts of the app it's without data, this.challenge */
          challenge_id: this.data.challenge.id,
          ui_category: mixpanelEventsEnum.UI_CATEGORY.JOIN_CHALLENGE_POPUP,
          ui_name: uiName
        }
      }
    );
  }
}
PreSubmitCtrl.$inject = [
  '$mdDialog',
  'Modals',
  'mixpanelService'
];
export default {
  template: template,
  controller: PreSubmitCtrl,
  bindings: { data: '<data' },
};
