import {Directive, ElementRef, Injector} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {ScopeProvider} from "./ScopeProvider";

@Directive({
  providers: [ ScopeProvider ],
  selector: 'ng1-admin'
})
export class ng1AdminPageComponent extends UpgradeComponent {
  constructor(
    elementRef: ElementRef,
    injector: Injector
  ) {
    super('gsAdmin', elementRef, injector);
  }
}
