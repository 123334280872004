import { Component } from '@angular/core';
import {moderatorReason} from "../../gsApp/app/pages/moderation/moderationPage.data";

@Component({
  selector: 'app-moderation-offtopic-images-wrapper',
  templateUrl: './moderation-offtopic-images-wrapper.component.html',
  styleUrl: './moderation-offtopic-images-wrapper.component.scss'
})
export class ModerationOfftopicImagesWrapperComponent {
  reason = moderatorReason.OFF_TOPIC;
}
