import {Component, Input, OnInit} from '@angular/core';
import {IStoreSection} from "../../services/store.service.types";
import {SectionType, StoreCurrencyType} from "../../models/enums";
import {StoreService} from "../../services/store.service";

@Component({
  selector: 'app-store-section',
  templateUrl: './store-section.component.html',
  styleUrls: ['./store-section.component.scss']
})
export class StoreSectionComponent implements OnInit {

  @Input() storeSection!:IStoreSection
  @Input() sectionType!:SectionType
  sectionTitle: string = '';
  storeCurrencyType = StoreCurrencyType;

  constructor(
    public storeService: StoreService
  ) {

  }

  ngOnInit(): void {// TODO: wow
    switch (this.sectionType) {
      case SectionType.BATTLES:
        this.sectionTitle = 'Battles';
        break;
      case SectionType.CHALLENGES:
        this.sectionTitle = 'Challenges'
    }
  }
}
