const template = require(`html-loader!./teamsPage.html`).default;
import TeamModel from "../../../api/models/team/team.model";
import TeamStateModel from '../../../api/models/team/teamState.model';
import teamEnum from '../../../api/enums/team.enum';
import {delay, Subject, takeUntil} from "rxjs";


class teamsPageCtrl {

  constructor(
    pageData,
    $window,
    $scope,
    $timeout,
    RestManager,
    stateService,
    $cookies,
    $location,
    $rootScope,
    teamsService,
    MemberService,
    NgMetaDataService,
    RouterService
  ) {
    'ngInject';
    this.start = 0;
    this.subject = new Subject();
    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.RestManager = RestManager;
    this.stateService = stateService;
    this.$cookies = $cookies;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.teamsService = teamsService;
    this.RouterService = RouterService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
    this.member = MemberService.getCurrentMember();
    if (this.member?.isRestricted()) {
      this.stateService.goToState('');
    }

    if (this.member?.logged_in === false) {
      this.stateService.goToState('');
    }

    this.RouterService.activatedRoute
      .queryParams.pipe(
        delay(50),
        takeUntil(this.subject)
    ).subscribe(async (params) => {
      await this.initPage(params);
    });
  }

  $onInit() {
    this.NgMetaDataService.setTitle('Teams');
    if (document.body.clientWidth <= 961 ){
      this.stateService.goToDefaultState();
    }
  }

  async initPage(params) {
    this.pageData.get('teams');

    await this.getTeamsConfig();
    await this.getTeams(this.start, 1);

    // in case of team info/create  dont want to redirect
    if(this.state.status !== teamEnum.STATE.LOCKED && (this.$location.path().indexOf('teams/info') === 1
      || (this.state.status !== teamEnum.STATE.JOINED && this.$location.path().indexOf('teams/create') === 1))
    ){
      if (!this.$scope.$$phase) {this.$scope.$digest()}
      return;
    }

    if(!this.state || this.state.status === -1){
      this.stateService.goToDefaultState();
      return;
    }

    //disable invite interval
    this.teamsService.cancelInviteNotificationInterval(true);

    if (this.state.status === teamEnum.STATE.JOINED) {
      if (['/teams', '/teams/join', '/teams/create'].includes(this.RouterService.currentUrl)) {
        this.stateService.goToState('teams/home/match', params);
      }

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }

      return;
    }

    if (this.state.status === teamEnum.STATE.UNLOCKED) {
      this.stateService.goToState('teams/join');

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }

      return;
    }

    if (this.state.status === teamEnum.STATE.LOCKED) {
      this.stateService.goToState('teams/lock');

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async getTeams(start, limit){
    const res = await this.teamsService.getTeams({ start, limit});

    if (!res.success) {
      return;
    }

    this.state = new TeamStateModel(res.state);
    this.teams = res.data.teams.map( team => new TeamModel(team));

    if (this.state.status === teamEnum.STATE.JOINED) {
      this.team = this.teams[0];
    }
  }

  async getTeamsConfig(){
    await this.teamsService.getTeamsConfig();
    this.configData = this.teamsService.getTeamConfig();
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    this.teamsService.setInviteNotificationInterval();
  }
}

teamsPageCtrl.$inject = [
  'pageData',
  '$window',
  '$scope',
  '$timeout',
  'RestManager',
  'stateService',
  '$cookies',
  '$location',
  '$rootScope',
  'teamsService',
  'MemberService',
  'NgMetaDataService',
  'RouterService'
];
export default {
  template: template,
  controller: teamsPageCtrl,
};
