import {CookieService} from 'ngx-cookie';
import {Injectable} from "@angular/core";

export enum CookieEnum {
  GS_SHOW_ADS = 'gsShowAds',
}

@Injectable()
class NgCookieService {

  constructor(private cookieService: CookieService) {
  }

  removeCookie(key: string){
    return this.cookieService.remove(key);
  }

  getCookie(key: string){
    return this.cookieService.get(key);
  }

  setCookie(key:string, value:string, expiredDays:number = 45){
    this.cookieService.put(key, value,{expires:this.getExpireDate(expiredDays)});
  }

  getExpireDate(days:number): Date {
    const expired = new Date();
    expired.setDate(expired.getDate() + days);
    return expired;
  }
}

export default NgCookieService;
