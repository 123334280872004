const template = require(`html-loader!./partnersPage.html`).default;
import { partners } from './partnersPage.data';

class partnersPageComponent {

  constructor(pageData, $window, $scope, $timeout, MemberService, NgMetaDataService) {
    'ngInject';
    this.fixedFooter = null;
    this.partners = partners;
    this.ready = false;

    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
  }

  $onInit() {
    this.NgMetaDataService.setTitle('Partners');
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('partners');
    this.ready = true;
  }
}
partnersPageComponent.$inject = [
  'pageData', '$window', '$scope', '$timeout', 'MemberService', 'NgMetaDataService'
];
export default {
  template: template,
  controller: partnersPageComponent,
};
