// import {AdsService} from "../../core/services/ads.service";
import {MediatorMessageKey, MediatorService} from "../../core/services/mediator.service";
import {Subject} from "rxjs";
import {CookieKeyEnum} from "../../core/enum/cookies.enum";

class MainCtrl {

  subject = new Subject();

  constructor(
    $rootScope,
    stateService,
    $cookies,
    $location,
    $timeout,
    $transitions,
    photoSwipe,
    Modals,
    notificationAlert,
    $http,
    idleService,
    NgTokenService,
    token,
    // AdsService,
    MediatorService
  ) {
    'ngInject';
    this.$rootScope = $rootScope;
    this.stateService = stateService;
    this.$cookies = $cookies;
    this.$location = $location;
    this.$timeout = $timeout;
    this.$transitions = $transitions;
    this.photoSwipe = photoSwipe;
    this.Modals = Modals;
    this.notificationAlert = notificationAlert;
    this.$http = $http;
    this.idleService = idleService;
    this.NgTokenService = NgTokenService;
    // this.AdsService = AdsService;
    this.mediatorService = MediatorService;
    // if(token){
    //   this.tokenService.setAccessToken(token);
    // }
    this.subject = new Subject();
    this.transitionOnSuccess = this.transitionOnSuccess.bind(this);

    this.$rootScope.header = {};
    this.$rootScope.meta = {};
    this.$rootScope.body = {
      classs: 'BODY CLASS',
    };

    this.$transitions.onSuccess({}, this.transitionOnSuccess);
    // this.$transitions.onBefore({}, this.transitionOnBefore.bind(this));
    angular.element(()=> {
      // console.log('page loading completed');
      this.$timeout(()=>{
        if (!$("#gs-meta-loaded").length ) {
          // console.log("add");
          $( "body" ).append( $( "<div id='gs-meta-loaded'></div>" ) );
        }
      },500);
    });

    this.hooks();
    this.transitionOnSuccess();
    this.initSubscriptions();
  }

  hooks() {
    switch (this.$location.hash()) {
      case 'resetPassword':
        const token = this.$location.search()[CookieKeyEnum.TOKEN];
        if (!token) break;
        this.NgTokenService.memberToken = token;
        this.$location.search({});
        this.$location.hash('');
        this.Modals.open('login', null, { state: 'setPassword' });
        break;
      case 'emailUnsubscribed':
        this.$location.hash('');
        this.notificationAlert.open('Unsubscribed!');
        break;
    }
    if(this.$location.search().disableLazy){
      // console.log('disableLazy');
      this.$rootScope.disableLazy = true;
    }
  }

  $onInit() {
    this.$timeout(()=>{
      this.init = true;
    },100);
    this.mediatorService.rootScope.next(this.$rootScope);
  }

  initSubscriptions() {
    this.mediatorService.on(MediatorMessageKey.OPEN_LOGIN_MODAL).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(event => {
      this.Modals.open('login', event.data, { signUp: true });
    });

    this.mediatorService.on(MediatorMessageKey.CLOSE_MODAL).pipe(
      this.mediatorService.takeUntil(this.subject)
    ).subscribe(()=> {
      this.Modals.close();
    });
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
MainCtrl.$inject = [
  '$rootScope',
  'stateService',
  '$cookies',
  '$location',
  '$timeout',
  '$transitions',
  'photoSwipe',
  'Modals',
  'notificationAlert',
  '$http',
  'idleService',
  'NgTokenService',
  'token',
  // 'AdsService',
  'MediatorService',
];
export default MainCtrl;
