import {RouterService} from "../../../../core/services/router.service";

const template = require(`html-loader!./teamJoinButton.html`).default;

class teamJoinButtonCtrl {
  constructor(
    $scope,
    $rootScope,
    teamsService,
    stateService,
    $timeout,
    MemberService,
    RouterService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.teamsService = teamsService;
    this.stateService = stateService;
    this.RouterService = RouterService;
    this.$timeout = $timeout;
    this.MemberService = MemberService;
  }

  $onInit() {
    this.member = this.MemberService.getCurrentMember();
    this.setJoinBtnText();
  }

  setJoinBtnText(){
    if(this.team.isDeleted()){
      this.JoinBtnText = 'Team Deleted';
      return;
    }
    if(this.isJoined()){
      this.JoinBtnText = 'Joined Team';
      return;
    }
    if(this.isClosed()){
      this.JoinBtnText = 'Private Team';
      return;
    }
    if(this.team.ifFull()){
      this.JoinBtnText = 'Team is full';
      return;
    }
    if(!this.team.isRequiredStatusReached(this.member.member_status)){
      this.JoinBtnText = 'Status limit not reached';
      return;
    }
    this.JoinBtnText = 'Join Team';
  }

  isJoined(){
    return this.myTeam && this.myTeam.id === this.team.id;
  }

  isClosed(){
    return !this.team.can_join && this.team.isClosed();
  }

  canMemberJoinTeam(){
    return !(
      this.team.isDeleted() ||
      this.isJoined() ||
      !this.team.isRequiredStatusReached(this.member.member_status) ||
      this.team.ifFull() ||
      this.isClosed()
    );
  }

  async joinTeam(){
    if(this.joinTeamBusy){return}

    this.joinTeamBusy = true;
    if(this.myTeam){
      if(!await this.teamsService.leaveTeam(this.myTeam)){
        this.joinTeamBusy = false;
        return;
      }
    }

    if (await this.teamsService.joinTeam(this.team.id, this.joinBy)) {
      this.RouterService.reloadRoute('teams');
      return;
    }
    this.joinTeamBusy = false;
  }
}

teamJoinButtonCtrl.$inject = [
  '$scope',
  '$rootScope',
  'teamsService',
  'stateService',
  '$timeout',
  'MemberService',
  'RouterService',
];

export default {
  template: template,
  controller: teamJoinButtonCtrl,
  bindings: {
    myTeam: '<',
    team: '<',
    joinBy: '<'
  }
};
