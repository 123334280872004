import config from './api.config';
import PhotosManager from './managers/PhotosManager';
import FollowersManager from './managers/FollowersManager';
import ChallengesManager from './managers/ChallengesManager';
import AuthenticationManager from './managers/AuthenticationManager';
import ArticlesManager from './managers/ArticlesManager';
import gsAdminManager from './managers/gsAdminManager';
import CamerasManager from './managers/CamerasManager';
import ShopManager from './managers/ShopManager';
import RankingManager from './managers/RankingManager';
import NotificationsManager from './managers/NotificationsManager';
import GooglePhotosManager from './managers/GooglePhotosManager';
import RestManager from './managers/RestManager';
import ExhibitionsManager from './managers/ExhibitionsManager';
import CommentsManager from './managers/CommentsManager';
import SlackManager from './managers/SlackManager';
import VoteManager from './managers/VoteManager';
import PagesManager from './managers/PagesManager';
import FacebookManager from './managers/FacebookManager';

let api = angular
    .module('api', ['ng-rest-api', 'angular-cache'])
    .config(config)
    .service('PhotosManager', PhotosManager)
    .service('FollowersManager', FollowersManager)
    .service('ChallengesManager', ChallengesManager)
    .service('AuthenticationManager', AuthenticationManager)
    .service('ArticlesManager', ArticlesManager)
    .service('gsAdminManager', gsAdminManager)
    .service('CamerasManager', CamerasManager)
    .service('ShopManager', ShopManager)
    .service('RankingManager', RankingManager)
    .service('NotificationsManager', NotificationsManager)
    .service('GooglePhotosManager', GooglePhotosManager)
    .service('RestManager', RestManager)
    .service('ExhibitionsManager', ExhibitionsManager)
    .service('CommentsManager', CommentsManager)
    .service('SlackManager', SlackManager)
    .service('VoteManager', VoteManager)
    .service('PagesManager', PagesManager)
    .service('FacebookManager', FacebookManager)
  ;

export default (api = api.name);
