import mixpanelEventsEnum from "../../../mixpanel/mixpanel.enum";

const template = require(`html-loader!./gsUploaderPhotos.html`).default;

import ImageModel from '../../../../api/models/image/image.model';
import { filters, justifiedGalleryConf } from './gsUploaderPhotos.data';

class gsUploaderPhotosComponent {
  /**
   * @param {GooglePhotosManager} GooglePhotosManager
   * @param {PhotosManager} PhotosManager
   * */

  constructor(
    $element,
    $mdDialog,
    $mdToast,
    $rootScope,
    $scope,
    $timeout,
    GooglePhotosManager,
    PhotosManager,
    MemberService,
    mixpanelService
  ) {
    'ngInject';
    this.busy = true;
    this.connected = false;
    this.allLoaded = false;
    this.filterChanged = false;
    this.markSelected = false;
    this.start = 0;
    this.limit = 50;
    this.items = [];
    this.suggested = [];
    this.selected = [];
    this.searchText = '';
    this.tags = ['white wiskey', 'white board'];
    this.googleSocialItem = {
      type : 'google_photos',
      connected: false,
      name: 'Google Photos',
      showTitle: false
    };

    this.$element = $element;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.GooglePhotosManager = GooglePhotosManager;
    this.PhotosManager = PhotosManager;
    this.mixpanelService = mixpanelService;
    this.member = MemberService.getCurrentMember();

    this.localSearch = this.localSearch.bind(this);
    this.onJgComplete = this.onJgComplete.bind(this);
    this.onScroll = _.debounce(this.onScroll.bind(this), 250);

    // filters
    this.filters = filters;
    this.filtersName = Object.keys(this.filters);
    this.filter = this.filtersName[2];
  }

  $onInit() {
    // console.log('settings', this.settings.target);
    // swap image selected
    if (this.settings.currentImage) {
      this.settings.currentImage = new ImageModel(this.settings.currentImage);
    }
    // images selected before
    if (this.settings.selected) {
      this.selected = this.settings.selected;
      this.markSelected = true;
    }
    // get data
    this.loadMore();
  }

  $onDestroy() {
    $(this.$element[0])
      .find('.gs-uploader-photos')
      .off('scroll', this.onScroll);
    $(document).off('scroll', this.onScroll);
    try {
      $('.gs-uploader-photos__wrap')
        .justifiedGallery('destroy')
        .off('jg.complete', this.onJgComplete);
    } catch (e) {}
  }

  onScroll(event) {
    const element = event.currentTarget;
    if (element.scrollTop >= element.scrollHeight - element.offsetHeight - 600) {
      this.loadMore();
    }
  }

  /** @return void **/
  async loadMore() {
    if (this.allLoaded || this.loading) {
      return true;
    }
    this.loading = true;
    this.lastSearchText = this.searchText;

    // Select API method
    let res;
    switch (this.settings.source) {
      case 'google':
        res = await this.GooglePhotosManager.getPhotos(this.start, this.limit, this.searchText || `' '`);
        break;
      case 'profile':
        this.connected = true;
        let params = {
          c_id: this.settings.challenge ? this.settings.challenge.id : null,
          member_id: this.settings.member ? this.settings.member.id : null,
          order: this.order,
          sort: this.sort,
          start: this.start,
          limit: this.limit,
          search: this.searchText || null,
          usage: 'submit',
        };
        Object.assign(params, this.filters[this.filter]);
        // Select method
        switch (this.settings.target) {
          case 'swap':
            params.usage = 'swap';
            res = await this.PhotosManager.getPhotosPrivate(params);
            break;
          case 'theme':
            res = await this.PhotosManager.getJoinThemePhotos({
              theme_id:this.settings.challenge.id,
              start: this.start,
              limit: this.limit,
              text: this.searchText || null
            });
            break;
          case 'challenge':
          case 'challengeCover':
            res = await this.PhotosManager.getPhotosPrivate(params);
            break;
          case 'chat':
          case 'suggestedChallenge':
          case 'getImages':
            params.get_member = true;
            res = await this.PhotosManager.getPhotosPublic(params);
            break;
          default:
            res = await this.PhotosManager.getPhotosPublic(params);
        }
        break;
      default:
        this.close();
        return;
    }

    if (!res.success) {
      // check error code
      switch (res.error_code) {
        case 1011:
          this.loading = false;
          this.busy = false;
          if (!this.$scope.$$phase) {
            this.$scope.$digest();
          }
          return;
      }
      this.close();
      return;
    }

    // all
    this.items.push(...res.items);
    // suggested
    if (!this.filterChanged && res.suggested && this.start === 0 && !this.lastSearchText.length) {
      this.suggested = res.suggested;
    }

    // mark selected
    if (this.markSelected) {
      this.markSelected = false;
      this.selected.map(image => {
        let selectedImage;
        if ((selectedImage = _.find(this.suggested, { id: image.id }))) selectedImage.selected = true;
        if ((selectedImage = _.find(this.items, { id: image.id }))) selectedImage.selected = true;
      });
    }

    this.start += this.limit;
    this.connected = true;
    this.loading = false;

    // stop loading
    if (res.items.length < this.limit) {
      this.allLoaded = true;
      $(this.$element[0])
        .find('.gs-uploader-photos')
        .off('scroll', this.onScroll);
    }

    // add scroll event
    if (!this.allLoaded && this.start === this.limit) {
      //on scroll
      this.$timeout(() => {
        $(this.$element[0])
          .find('.gs-uploader-photos')
          .on('scroll', this.onScroll);
      });
    }

    if (this.suggested.length || this.items.length) {
      this.$timeout(() => {
        if (!this.inited) {
          this.inited = true;
          $('.gs-uploader-photos__wrap')
            .justifiedGallery(justifiedGalleryConf)
            .on('jg.complete', this.onJgComplete);
        } else {
          $('.gs-uploader-photos__wrap').justifiedGallery('norewind');
        }
      });
    } else {
      this.busy = false;
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onJgComplete() {
    console.log('onJgComplete');
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onChangeFilter(filter) {
    if (this.filter === filter || this.filtersName.indexOf(filter) === -1) {
      return false;
    }
    this.filter = filter;
    this.filterChanged = true;
    this.clean();
    this.loadMore();
  }

  select(image) {
    // permissions
    if (image.permission && !image.permission.allowed) return true;

    // deselect
    if (image.selected) {
      let index = _.findIndex(this.selected, { id: image.id });
      if (index !== -1) this.selected.splice(index, 1);
      this.selectedUpdated();
      image.selected = false;
      return;
    }

    // select

    // max reached
    if (this.selected.length === this.settings.items_limit) {
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .parent(this.$element)
          .textContent('Max photos selected!')
          .position('bottom right')
          .hideDelay(1500)
      );
      return true;
    }

    // image already selected
    if (_.find(this.selected, { id: image.id })) {
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .parent(this.$element)
          .textContent('Photo already selected!')
          .position('bottom right')
          .hideDelay(1500)
      );
      return true;
    }

    // add to array
    this.selected.push(image);
    this.selectedUpdated();

    // change image state
    image.selected = true;

    if (this.settings.autoSubmit) this.submit();
  }

  selectedUpdated(){
    this.settings.selectedImagesUrl = this.selected.map( (image) => {
      if(image instanceof ImageModel){
        return image.src(600,0);
      }else {
        return image.url;
      }
    });
  }

  deselect(image) {
    this.selected.splice(this.selected.indexOf(image), 1);
    this.selectedUpdated();
    let selectedImage;
    if ((selectedImage = _.find(this.suggested, { id: image.id }))) selectedImage.selected = false;
    if ((selectedImage = _.find(this.items, { id: image.id }))) selectedImage.selected = false;
  }

  clean() {
    this.allLoaded = false;
    this.busy = true;
    this.inited = false;
    this.items = [];
    this.start = 0;
    this.suggested = [];
    try {
      $('.gs-uploader-photos__wrap')
        .justifiedGallery('destroy')
        .off('jg.complete', this.onJgComplete);
    } catch (e) {}
  }

  search() {
    this.clean();
    // track
    this.$timeout(() => {
      this.loadMore();
    }, 100);
  }

  localSearch(str) {
    return this.PhotosManager.searchAutocomplete(str, this.member.id).then(res => res.items);
  }

  connect() {
    switch (this.settings.source) {
      case 'google':
        this.busy = true;
        this.GooglePhotosManager.auth().then(res => {
          if (!res.success) {
            this.$mdDialog.hide();

          }else {
            this.loadMore();
          }
        });
        break;
    }
  }

  submit() {
    if (!this.selected.length) return;
    // submit callback
    this.tools.submit(this.selected);
  }

  eventMixPanelAction(uiAction){
    if (['challenge'].includes(this.settings.target)) {
      this.sendContinueVotingClientEvent(uiAction);
    }
  }

  sendContinueVotingClientEvent(uiAction) {
    this.mixpanelService.track({
      // event: mixpanelEventsEnum.EVENT_NAME.JOIN,
      data: {
        ui_name: mixpanelEventsEnum.UI_NAME.CHOOSE_PHOTO,
        ui_action: uiAction,
        type: 'challenge',
        member_id: this.member.id,
      }
    });
  }

  back() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_ACTION.CANCEL);
    this.tools.back();
  }

  close() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_ACTION.CONTINUE);
    this.$mdDialog.hide();
  }
}
gsUploaderPhotosComponent.$inject = [
  '$element',
  '$mdDialog',
  '$mdToast',
  '$rootScope',
  '$scope',
  '$timeout',
  'GooglePhotosManager',
  'PhotosManager',
  'MemberService',
  'mixpanelService',
];
export default {
  template: template,
  controller: gsUploaderPhotosComponent,
  bindings: {
    settings: '=',
    tools: '=',
  },
};
