import {Inject, Injectable} from '@angular/core';
import RequestServiceModel from "../../../core/services/request/requestService.model";
import NgRequestEnum from "../../../core/services/request/request.enum";
import {BehaviorSubject, firstValueFrom} from "rxjs";
import RequestService from "../../../core/services/request/requestService";
import {INgChallengeModel, PrizeItem} from "../interfaces/challenges.interface";
import {IGsResponse} from "../../../core/models/gsResponse.types";
import {ChallengeUnlockUsage} from "../enums/challenges.enum";
import mixpanelEventsEnum from "../../../gsApp/app/services/mixpanel/mixpanel.enum";
import {tap} from "rxjs/operators";
import {NgChallengeModel} from "../models/ng-challenge.model";

export interface IGetGetChallengePrizes  extends IGsResponse{
  prizes: PrizeItem[]
}
export interface IGetChallenge extends IGsResponse{
  challenge: INgChallengeModel,
  member:any
}

@Injectable({
  providedIn: 'root'
})
export class NgChallengesService {
  public currentChallenge$ = new BehaviorSubject<INgChallengeModel | undefined>(undefined);
  constructor(
    private requestService: RequestService,
    @Inject('mixpanelService')  private mixpanelService: any,
  ) { }

  setChallengeTurbo(challenge_id: string, image_id: string): Promise<any> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.SET_CHALLENGE_TURBO.NAME,
      body: {
        challenge_id,
        image_id
      }
    });
    return firstValueFrom(this.requestService.request<any>(conf));
  }

  getChallengeById(challengeId: number): Promise<IGetChallenge> {
    return this.getChallenge({
      challenge_id: challengeId
    });
  }

  getChallengeByUrl(challengeUrl: string): Promise<IGetChallenge> {
    return this.getChallenge({
      challenge_url:challengeUrl
    })
  }

  private getChallenge(body: any): Promise<IGetChallenge> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_CHALLENGE.NAME,
      body
    });
    return firstValueFrom(this.requestService.request<IGetChallenge>(conf).pipe(
      tap(challengeRes => {
        challengeRes.challenge.member = challengeRes.member;
        challengeRes.challenge = new NgChallengeModel(challengeRes.challenge);
        this.currentChallenge$.next(challengeRes.challenge);
      })
    ));
  }

  getChallengePrizes(challengeId:number): Promise<IGetGetChallengePrizes> {
    const conf: RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_CHALLENGE_PRIZES.NAME,
      body: {
        c_id: challengeId
      },
      // getMockData: true,
      // mockDataState: 2
    });
    return firstValueFrom(this.requestService.request<IGetGetChallengePrizes>(conf));
  }

  getWinnersAchievementsAndPrizes(challengeId: string): Promise<any> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_WINNERS_ACHIEVEMENTS_AND_PRIZES.NAME,
      body: {c_id: challengeId},
      // getMockData: true,
      // mockDataState: 1
    });
    return firstValueFrom(this.requestService.request<any>(conf));
  }

  coinsUnlock(challengeId:string, usage = ChallengeUnlockUsage.JOIN_CHALLENGE): Promise<IGsResponse> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.COINS_UNLOCK.NAME,
      body: {
        challenge_id: challengeId,
        usage: usage
      },
    });
    return firstValueFrom(this.requestService.request<any>(conf));
  }

  //events
  sendJoinChallengeMixpanelEvent(data : {challenge: INgChallengeModel, ui_name:any, ui_action:any, ui_type?:any}){
    if(!data.challenge.isLockedWithKey || !data.challenge.isLockedWithCoins){return}
    let ui_state = data.challenge.isLockedWithKey() ? 'key' : (data.challenge.isLockedWithCoins() ? 'coins' : 'free');
    this.mixpanelService.track({
      event:mixpanelEventsEnum.EVENT_NAME.JOIN_CHALLENGE,
      data:{
        ui_name: data.ui_name,
        ui_action: data.ui_action,
        ui_state: ui_state,
        ui_category: ui_state === 'key' ? 1 : (ui_state === 'coins' ? data.challenge.join_coins : undefined),
        ui_type: data.ui_type
      }
    });
  }

}
