class ResponseService {

  constructor(
    notificationAlert
  ) {
    'ngInject';

    this.notificationAlert = notificationAlert;
  }

  isResponseError(response) {
    return !!response.message;
  }

  responseHandler(response) {
    if(this.isResponseError(response)) {
      this.handleErrorMessage(response);
    }
  }

  handleErrorMessage(response) {
    this.notificationAlert.info({
      message: response.message,
      buttonText: 'Got it',
      title: 'Error'
    });
  }
}
ResponseService.$inject = [
  'notificationAlert'
];
export default ResponseService;
