const template = require(`html-loader!./teamsScore.html`).default;

class TeamScore {
  constructor(

  ) {
    'ngInject';
  }
}

TeamScore.$inject = [

];

export default {
  template: template,
  controller: TeamScore,
  bindings: {
    value: '<'
  }
};
