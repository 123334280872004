const template = require(`html-loader!./spotlight.html`).default;

class spotlightCtrl {
  triggerClick() {
    return true;
  }
}

spotlightCtrl.$inject = [

];

export default {
  template: template,
  controller: spotlightCtrl,
  bindings: {
    data: '<'
  }
};
