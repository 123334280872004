import * as _ from 'lodash';
/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {array}         Return array of the diff
 */
export function objectDifference(object, base) {
  // clear angular tracking
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = _.isObject(value) && _.isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  }
  return _.keys(changes(object, base));
}

export function clearObject(obj) {
  // clear angular tracking
  Object.entries(obj).forEach(([key, val]) => {
    if (val && typeof val === 'object') clearObject(val);
    else if (val === null || val === undefined) delete obj[key];
  });
  return obj;
}

// get the value of a string path in object
export function resolveObjectPath(path, obj) {
  return path.split('.').reduce(function(prev, curr) {
    return prev ? prev[curr] : null;
  }, obj || self);
}

// example - getPropertyName(person, person.age)
export function getPropertyName(obj,type){
  return Object.keys(obj).find(key => obj[key] === type);
}

