import {INgTeamMemberModel} from "./models";
import {pareDateFromUnixTime} from "../../../gsApp/app/helpers/date.helper";
import teamEnum from "../../../gsApp/app/api/enums/team.enum";
import {NgTeamsRankDataModel} from "./ng-teams-rank-data.model";

export class NgTeamMemberModel implements INgTeamMemberModel {
  rank_data;
  date_created;
  left_date;
  match_points;

  name: any;
  user_name: any;
  type: any;

  private _displayName;

  get displayName() {
    return this._displayName;
  }

  set displayName(value) {
    this._displayName = value;
  }

  constructor(props: INgTeamMemberModel = {}) {
    Object.assign(this, props);

    (props.name || props.user_name) && (this._displayName = this.name || `@${this.user_name}`);
    props.match_points && (this.match_points = props.match_points);
    props.rank_data && (this.rank_data = new NgTeamsRankDataModel({rank_data: props.rank_data, member_id: props.member_id}));
    //for admin
    props.date_created && (this.date_created = pareDateFromUnixTime(props.date_created));
    props.left_date && (this.left_date = pareDateFromUnixTime(props.left_date));
  }

  isAdmin = (): boolean => [
    teamEnum.MEMBER.TYPE.ADMIN,
    teamEnum.MEMBER.TYPE.CREATOR
  ].includes(this.type);
}
