const template = require(`html-loader!./memberPath.html`).default;
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";

class memberPath {
  constructor(
    $element,
    $timeout,
    $rootScope,
    notificationAlert,
    mixpanelService,
    MemberService,
  ) {
    'ngInject';
    this.$element = $element;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.notificationAlert = notificationAlert;
    this.mixpanelService = mixpanelService;
    this.loggedinMember = MemberService.getCurrentMember();
  }

  $onChanges() {
    if(!this.data){
      return;
    }

    if(this.data.progress && this.data.progress[0] && this.data.progress[0].status === 9){
      this.isGuruPath = true;
    }

    //run animation
    this.$timeout(()=>{
      if(this.isGuruPath){
        this.progress = (this.data.member_status-8) * 11.11;
      }else{
        this.progress = this.data.member_status * 12.5;
      }
    });
  }

  help(type = '') {

    let message;
    switch(type) {
      case 'achievementCalc':
        message = `Levels and challenge placement achievements are calculated once each challenge ends.
                   <a href="https://gurushots.freshdesk.com/support/solutions/articles/13000096928-how-to-move-up-the-path-" target="_blank">Read More</a>`;
        break;
      default:
        message = `GuruShots was born of the idea that anyone has the potential to grow and become a Guru of photography.
                   You will begin a “Newbie”, and as you show determination and talent- the status of “Guru” is within your reach.
                   <a href="https://gurushots.freshdesk.com/solution/articles/13000013699-your-path-to-becoming-a-guru" target="_blank">Read More</a>`;
    }

    this.notificationAlert.open(message);
  }

  eventMixPanelAction(memberStatus, uiAction = 'click') {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.STATUS_PROGRESSION_BAR,
          ui_action: mixpanelEventsEnum.UI_ACTION[uiAction.toUpperCase()],
          ui_name: `${memberStatus.toLowerCase()}_${mixpanelEventsEnum.UI_NAME.PROGRESSION_INFO}`,
          member_status: memberStatus.toLowerCase()
        }
      }
    );
  }
}

memberPath.$inject = [
  '$element',
  '$timeout',
  '$rootScope',
  'notificationAlert',
  'mixpanelService',
  'MemberService',
];

export default {
  template: template,
  controller: memberPath,
  bindings: {
    data: '<'
  }
};
