import photoSwipeService from "../../../modules/photoSwipe/photoSwipe.service";

const template = require(`html-loader!./gsModals.html`).default;

import { conf } from './gsModals.conf';

class gsModalsCtrl {
  /** @param {GsModalsService} gsModals */

  constructor(gsModals, $scope, $timeout,
              // AdsService
  ) {
    'ngInject';
    this.components = [];
    this.isOpen = false;
    this.tools = {
      close: this.close.bind(this),
    };

    this.gsModals = gsModals;
    this.$scope = $scope;
    this.$timeout = $timeout;
    // this.AdsService = AdsService;

    this.open = this.open.bind(this);
    this.onResize = this.onResize.bind(this);
  }

  $onInit() {
    // rxJs subscribe
    this.onOpen = this.gsModals.onOpen.subscribe(this.open);
  }

  $onDestroy() {
    // rxJs unSubscribe
    if (this.onOpen) {
      this.onOpen.dispose();
    }
    $(window).off('resize', this.onResize);
  }

  open(modal) {
    console.log('gsModal:open ', modal);
    this.isOpen = true;
    this.modal = modal;
    this.conf = conf[modal.name];

    // fix body
    this.top = $(window).scrollTop();
    $('body').css({
      top: '-' + $(window).scrollTop() + 'px',
      position: 'fixed',
      left: 0,
      width: '100%'
    });

    // fix for iOs bottom bar
    this.availHeight = window.innerHeight;
    $(window).on('resize', this.onResize);
  }

  onResize() {
    this.$timeout(() => {
      this.availHeight = window.innerHeight;
    }, 500);
  }

  close() {
    // callback
    if (this.modal.data.callback) {
      this.modal.data.callback();
    }
    this.isOpen = false;
    this.modal = undefined;
    this.conf = undefined;
    // enable scroll
    $('body').removeAttr('style');
    $(window).scrollTop(this.top);
  }
}
gsModalsCtrl.$inject = [
  'gsModals', '$scope', '$timeout',
  // 'AdsService'
];
export default {
  template: template,
  controller: gsModalsCtrl,
};
