/**
 * @param {number} n
 * @returns {string} Return suffix: th, td, st, nd
 */
export const getNumberSuffix = (n) => {
  return Math.floor(n / 10) === 1 ? 'th' : (n % 10 === 1 ? 'st' : (n % 10 === 2 ? 'nd' : (n % 10 === 3 ? 'rd' : 'th')));
};

export function isNotANumber(value) {
  return isNaN(value*1);
}
