/* globals angular */
const template = require(`html-loader!./gsBanner.component.html`).default;

class gsBannerCtrl {
  constructor() {}
}

gsBannerCtrl.$inject = [

];

export default {
  template: template,
  controller: gsBannerCtrl,
  bindings: {
    data: '<'
  }
};
