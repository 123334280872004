import {NgModule} from '@angular/core';
import {BattleBankrollItemComponent} from "./components/battle-bankroll-item/battle-bankroll-item.component";
import {SharedModule} from "../../shared/shared.module";
import {BankrollStoreComponent} from "./components/bankroll-store/bankroll-store.component";
import {AngularMaterialModule} from "../angular-material/angular-material.module";
import {GlobalBankrollComponent} from "./components/global-bankroll/global-bankroll.component";

@NgModule({
  declarations: [
    BattleBankrollItemComponent,
    BankrollStoreComponent,
    GlobalBankrollComponent
  ],
    exports: [
        BankrollStoreComponent,
        BattleBankrollItemComponent,
        GlobalBankrollComponent
    ],
  imports: [
    SharedModule,
    AngularMaterialModule
  ]
})

export class BankrollModule { }
