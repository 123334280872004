import {Subject, takeUntil, filter} from "rxjs";
const template = require(`html-loader!./followers.html`).default;
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";

class FollowersController {

  constructor(
    FollowersManager,
    $timeout,
    $rootScope,
    $window,
    $scope,
    tagEventService,
    mixpanelService,
    MemberService,
  ) {
    'ngInject';
    this.current = 0;
    this.limit = 50;
    this.items = [];
    this.suggestFollow = [];
    this.allLoaded = false;
    this.loading = true;
    this.ready = false;
    this.subject = new Subject();
    this.FollowersManager = FollowersManager;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.mixpanelService = mixpanelService;
    this.MemberService = MemberService;

    this.MemberService.profilePageMember$.pipe(
      filter(member => !!member),
      takeUntil(this.subject)
    ).subscribe(member => {
      this.profileMember = member;
      this.loadMore();
      this.getProfileFollowSuggest();
    });
  }

  $onChanges() {
    this.loadMore();
    this.getProfileFollowSuggest();
  }

  $onInit(){
    this.tagEventService.sendPageViewEvent({
      sendToMixPanel:false,
      title: 'Profile page Followers'
    });
    this.eventMixPanelAction();
  }

  eventMixPanelAction() {
    this.mixpanelService.trackProfilePageImpression({
      ui_name: this.method === 'getFollowers' ? mixpanelEventsEnum.UI_NAME.FOLLOWERS : mixpanelEventsEnum.UI_NAME.FOLLOWING
    });
  }

  async loadMore() {
    if (!this.profileMember || !this.method || this.allLoaded) {
      return;
    }
    this.loading = true;

    const items = await this.FollowersManager.getProfileFollowers(
      this.profileMember.id,
      this.current,
      this.limit,
      this.method
    );

    if (!items.length) {
      this.allLoaded = true;
    }

    this.items.push(...items.filter(item => !item.member.isRestricted()));

    this.ready = true;
    this.loading = false;
    this.current += this.limit;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  getProfileFollowSuggest() {
    if (!this.MemberService.profilePageMember || !this.MemberService.isMyProfilePage) {
      return;
    }

    this.FollowersManager['getProfileSuggestFollow'](this.MemberService.profilePageMember.id).then(
      suggestFollow => (this.suggestFollow = suggestFollow)
    );
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
FollowersController.$inject = [
  'FollowersManager',
  '$timeout',
  '$rootScope',
  '$window',
  '$scope',
  'tagEventService',
  'mixpanelService',
  'MemberService',
];
export default {
  template: template,
  controller: FollowersController,
  bindings: {
    method: '<',
  },
};
