export class EnvironmentStaticMixpanelModel {
  token: { [key: string]: string } = {
    preprod: 'web_PoU9JZO1jl',
    prod: 'web_PoU9JZO1jl',
    beta: 'web_PoU9JZO1jl',
    stage: 'web_PoU9JZO1jl',
  };

  host: { [key: string]: string } = {
    preprod: 'https://client-events.gurushots.com/client-events-prd',
    prod: 'https://client-events.gurushots.com/client-events-prd',
    stage: 'https://client-events.gurushots.com/client-events-stg'
  };

  constructor() {
  }

  getToken(env: string): string {
    return this.token[env];
  }

  getHost(env: string): string {
    return this.host[env];
  }
}
