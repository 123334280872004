const template = require(`html-loader!./lpMobile.html`).default;

class lpMobileCtrl {
  constructor(
    Modals,
    $timeout,
    $scope,
    RouterService,
    gsScrollTopService,
    tagEventService,
    MemberService,
  ) {
    'ngInject';
    this.busy = true;
    this.comments = [
      {
        text:"GuruShots is the best photographic challenges in the world and I'm just loving this ** Cheers **",
        by:"Ian Popple, UK",
      },
      {
        text:"I am completely addicted to this site it keeps me amused for hours!",
        by:"Wendy Oserle, UK",
      },
      {
        text:"I think this app beats Instagram hands down. It's for people who really love photography.",
        by:"Garrett Green, US",
      },
      {
        text:"I love GuruShots. Ever since my friend introduced me to it, I check it 20 times a day.",
        by:"Law Ream, United States",
      }
    ];

    this.$scope = $scope;
    this.Modals = Modals;
    this.$timeout = $timeout;
    this.RouterService = RouterService;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.scope = {
      coverUrl: '/assets/images/pages/lp1/cover.jpg'
    }
    // init page
    this.initPage();
  }

  initPage() {
    this.tagEventService.sendPageViewEvent({title: 'Landing page - lpMobile'});
    this.busy = false;
    if (this.member.logged_in) {
      this.RouterService.go('challenges/my-challenges/current');
      return;
    }
    this.gsScrollTopService.scrollTop();
  }

  login(event) {
    this.Modals.open('login', event, {signUp: true});
  }

}
lpMobileCtrl.$inject = [
  'Modals',
  '$timeout',
  '$scope',
  'RouterService',
  'gsScrollTopService',
  'tagEventService',
  'MemberService',
];
export default {
  template: template,
  controller: lpMobileCtrl
};
