const template = require(`html-loader!./socialItem.html`).default;

class socialItemCtrl {

  constructor( MemberService) {
    'ngInject';
    this.member = MemberService.getCurrentMember();

  }

  onClick(){
    if(this.item.unlink &&  this.item.link){
      this.item.connected ? this.item.unlink(this.item) : this.item.link(this.item);
    }
  }

  // $doCheck() {
  //   this.isGooglePhotosDisconnected = this.item.type === 'google_photos' && this.item.connected;
  // }
}
socialItemCtrl.$inject = [
  'MemberService',
];
export default {
  template: template,
  controller: socialItemCtrl,
  bindings: {
    item: '<'
  }
};
