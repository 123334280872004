const restEnum = Object.freeze({
  END_POINTS: {
    GET_MEMBER_ACHIEVEMENTS_CHALLENGES_HISTORY : {
      NAME: 'get_member_achievements_challenges_history',
      STATES: {
        1:1,
        5:5
      }
    },
    GET_MEMBER_ACHIEVEMENTS_BY_LEVEL : {
      NAME: 'get_member_achievements_by_level',
      STATES: {
        1:1,
        2:2,
        5:5
      }
    },
    GET_CHALLENGES_BY_ACHIEVEMENT : {
      NAME: 'get_challenges_by_achievement',
      STATES: {
        1:1,
        5:5
      }
    },
    GET_MEMBER_ACHIEVEMENTS_DELETE_MESSAGE : {
      NAME: 'get_member_achievements_delete_message',
    },
    GET_SESSION_CONFIGURATION : {
      NAME: 'get_session_configuration',
    },
  }
});

export default restEnum;
