import MyHttpInterceptor  from './myHttpInterceptor';

const config = function ($httpProvider) {
  'ngInject';
  $httpProvider.interceptors.push('MyHttpInterceptor');
};

config.$inject = ['$httpProvider'];

let moduleInterceptors = angular
  .module('gsApp.interceptors', [])
  .service('MyHttpInterceptor', MyHttpInterceptor)
  .config(config)
;

export default moduleInterceptors.name;
