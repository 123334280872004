/* globals angular */

const template = require(`html-loader!./cards.html`).default;
import mixpanelEventsEnum from "../mixpanel/mixpanel.enum";

class cardsCtrl {
  /**
   * @param $element
   * @param $compile
   * @param $scope
   * @param {RestManager} RestManager
   * */

  constructor(
    $element,
    $compile,
    $scope,
    RestManager,
    mixpanelService
  ) {
    'ngInject';

    this.$element = $element;
    this.$compile = $compile;
    this.$scope = $scope;
    this.RestManager = RestManager;
    this.mixpanelService = mixpanelService;
  }

  $onChanges() {
    if (!this.gspopup || !this.challenge) {
      this.close();
      return;
    }
    this.showCard();
  }

  showCard() {
    let el;
    //Compile template
    switch (this.gspopup.type) {
      case 'suspended_images':
        el = angular.element(`<suspended-images-card challenge="$ctrl.challenge"
                                                     gspopup="$ctrl.gspopup"
                                                     close="$ctrl.close()"></suspended-images-card>`);
        break;
      case 'try_swap':
        el = angular.element(`<try-swap-card challenge="$ctrl.challenge"
                                             gspopup="$ctrl.gspopup"
                                             close="$ctrl.close()"></try-swap-card>`);
        break;
      case 'unlock_state':
        el = angular.element(`<unlock-state-card challenge="$ctrl.challenge"
                                             gspopup="$ctrl.gspopup"
                                             close="$ctrl.close()"></unlock-state-card>`);
        break;
      case 'successful_swap':
        el = angular.element(`<successful-swap-card challenge="$ctrl.challenge"
                                                    gspopup="$ctrl.gspopup"
                                                    close="$ctrl.close()"></successful-swap-card>`);
        break;
      case 'off_topic':
        el = angular.element(`<off-topic-card challenge="$ctrl.challenge"
                                                    gspopup="$ctrl.gspopup"
                                                    close="$ctrl.close()"></off-topic-card>`);
        break;
      case 'similar':
        el = angular.element(`<similar-card challenge="$ctrl.challenge"
                                                    gspopup="$ctrl.gspopup"
                                                    close="$ctrl.close()"></similar-card>`);
        break;
      case 'custom':
        el = angular.element(`<custom-card gspopup="$ctrl.gspopup"
                                           close="$ctrl.close()"></custom-card>`);
        break;
      case 'swap_suggest':
        el = angular.element(`<swap-suggest-card gspopup="$ctrl.gspopup"
                                                         challenge="$ctrl.challenge"
                                                         close="$ctrl.close()"
                              ></swap-suggest-card>`);
        break;
      default:
        this.$element.remove();
        return;
    }

    el = this.$compile(el)(this.$scope);
    this.$element.append(el);
  }

  close() {
    if (this.gspopup.type === 'swap_suggest') {
      this.eventMixPanelAction({
        uiName: mixpanelEventsEnum.UI_NAME.CLOSE,
        uiCategory: mixpanelEventsEnum.UI_CATEGORY.SWAP_SUGGESTION
      });
    }

    if (this.gspopup.type === 'unlock_state') {
      this.eventMixPanelAction({
        uiName: mixpanelEventsEnum.UI_NAME.CLOSE,
        uiCategory: mixpanelEventsEnum.UI_CATEGORY.MISSED_BOOST
      });
    }
    this.RestManager.deletePopup(this.gspopup.id);
    this.$element.remove();
  }

  eventMixPanelAction({uiName, uiCategory}) {
    this.mixpanelService.track(
      {
        data: {
          ui_category: uiCategory,
          ui_name: uiName,
          challenge_id: this.challenge.id
        }
      }
    );
  }
}
cardsCtrl.$inject = [
  '$element',
  '$compile',
  '$scope',
  'RestManager',
  'mixpanelService'
];
export default {
  template: template,
  controller: cardsCtrl,
  bindings: {
    gspopup: '<',
    challenge: '<',
  },
};
