import {Component, Input} from '@angular/core';
import {
  animationFrameScheduler,
  BehaviorSubject,
  distinctUntilChanged,
  endWith,
  interval,
  switchMap,
  takeWhile
} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-ng-exposure-meter',
  templateUrl: './ng-exposure-meter.component.html',
  styleUrl: './ng-exposure-meter.component.scss'
})
export class NgExposureMeterComponent {
  @Input() set exposure(value: number) {
    this.degreeSubject.next(this.setDegree(value));
  }

  @Input() isVoting = false;
  @Input() showMeterBackground = false;
  @Input() duration = 1000;

  public degree = -90;
  public degreeSubject = new BehaviorSubject<number>(-90);

  degree$ = this.degreeSubject.pipe(
    switchMap((degree) => {
      const initialDegree = this.degree;
      const startTime = animationFrameScheduler.now();
      return interval(0, animationFrameScheduler).pipe(
        map(() => animationFrameScheduler.now() - startTime),
        map((elapsedTime) => elapsedTime / this.duration),
        takeWhile((progress) => {
          return progress <= 1;
        }),
        map((progress) => {
            const degreeToAdd = Math.round(progress * (degree - initialDegree));
            this.degree = degree;
            return initialDegree + degreeToAdd;
          }
        ),
        endWith(degree),
        distinctUntilChanged()
      );
    })
  )

  setDegree(value: number): number {
    let degree = value ? (value * 180) / 100 - 90 : -90;

    if (degree > 90) {
      degree = 90;
    }
    if (degree < -90) {
      degree = -90;
    }
    return degree;
  }
}
