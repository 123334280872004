class photoSwipeService {

  constructor($rootScope, $location, $timeout, $interval, $transitions, $window) {
    'ngInject';
    this.opened = false;
    this.closeEnabled = true;
    this.slideShow = false;

    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.$transitions = $transitions;
    this.$window = $window;

    this.open = this.open.bind(this);
    this.galleryImageLoadComplete = this.galleryImageLoadComplete.bind(this);
    this.galleryAfterChange = this.galleryAfterChange.bind(this);
    this.galleryClose = this.galleryClose.bind(this);
    this.transitionOnBefore = this.transitionOnBefore.bind(this);
  }

  transitionListener() {
    if (this.transitionListenerInit === true) return;
    this.transitionListenerInit = true;
    // Manege transition to stage
    this.$transitions.onBefore({}, this.transitionOnBefore);
  }

  transitionOnBefore($transition$) {
    // prevent redirect to photo page
    if (this.opened && $transition$.$to().name === 'photo') return false;
    // from photo to other pages (close modal)
    if (this.opened || ($transition$.$to().name !== 'photo' && $transition$.$from().name === 'photo')) {
      this.close();
    }
    return true;
  }

  open({images, index = 0, slideShow = false, closeEnabled = true}) {
    this.closeEnabled = closeEnabled;
    // No images
    if (!images || !images.length) return false;
    // slideShow settings
    this.slideShow = slideShow;
    // event listeners
    this.transitionListener();
    // get element
    const pswpElement = document.querySelectorAll('.pswp')[0];
    // build items array
    let items = [];
    for (const image of images) {
      items.push({
        src: `${this.$rootScope.photoDomain}/unsafe/0x0/${image.member_id}/3_${image.id}.jpg`,
        w: 0,
        h: window.screen.availHeight,
        pid: image.id,
        adult: image.adult,
      });
    }

    // define options (if needed)
    let options = {
      index: index,
      maxSpreadZoom: 2,
      // Close events
      closeOnScroll: false,
      closeOnVerticalDrag: false,
      tapToClose: false,
      clickToCloseNonZoomable: false,
      pinchToClose: false,
      closeElClasses: [],
      escKey: this.closeEnabled,
      // History
      history: false,
      galleryUID: 'photo',
      galleryPIDs: false,
      focus: false,
      modal: true,
      // Default Buttons
      fullscreenEl: true,
      closeEl: false,
      captionEl: false,
      zoomEl: false,
      shareEl: false,
      counterEl: false,
      preloaderEl: false,
      // Styles and Animation
      barsSize: {top: 0, bottom: 0},
      timeToIdle: 3000,
      // bgOpacity: 0.9,
      showAnimationDuration: 333,
      hideAnimationDuration: 333,
      showHideOpacity: false,
      // functions
      isClickableElement: function(el) {
        return el.tagName === 'A' || el.tagName === 'ADULT-FILTER';
      }
    };

    // Initializes and opens PhotoSwipe
    this.gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);

    // on gallery image Load Complete
    this.gallery.listen('imageLoadComplete', this.galleryImageLoadComplete);
    // on gallery load/change slide
    this.gallery.listen('afterChange', this.galleryAfterChange);
    // on gallery close
    this.gallery.listen('close', this.galleryClose);

    // Replace location
    if (this.$location.path().indexOf('/photo/') === -1) {
      this.opened = true;
      // Save referrer
      this.location = this.$location.path();
      // Update location
      // this.$location.path('/photo/' + this.currentId).replace();
    }

    // stop body scroll
    $('body').css({
      width: document.body.clientWidth,
      overflow: 'hidden'
    });

    // emmit open event
    this.$rootScope.$emit('onPhotoSwipeOpen', {
      imageId: images[index].id,
      adult: images[index].adult,
      slideShow: this.slideShow
    });

    // open gallery
    this.gallery.init();

    if(this.slideShow) {
      this.interval = this.$interval(() => this.gallery.next(), 6000);
      this.gallery.ui.getFullscreenAPI().enter();
    }

    if (!this.$rootScope.$$phase) this.$rootScope.$digest();
  }

  galleryImageLoadComplete(index, item) {
    if (item.resized) {
      return true;
    }
    // update image size
    let img = new Image();
    img.src = item.src;
    item.w = img.naturalWidth;
    item.h = img.naturalHeight;
    item.resized = true;
    // fix sizes
    this.$timeout(() => {
      this.gallery.invalidateCurrItems();
      this.gallery.updateSize(true);
    });
  }

  galleryAfterChange() {
    if (this.currentId === this.gallery.currItem.pid) return true;
    this.currentId = this.gallery.currItem.pid;

    this.$rootScope.$emit('onPhotoSwipeIndex', {
      image_id: this.currentId,
      adult: this.gallery.currItem.adult,
    });

    // replace history
    // this.$location.path('/photo/' + this.currentId).replace();
  }

  galleryClose() {
    if(this.interval) this.$interval.cancel(this.interval);

    // enable body scroll
    $('body').removeAttr('style');

    // emmit close event
    this.$rootScope.$emit('onPhotoSwipeClose');

    this.closeEnabled = true;
    this.opened = false;

    // replace history
    this.$timeout(() => {
      if (this.$location.path().indexOf('/photo/') !== -1) {
        this.$location.path(this.location).replace();
      }
      delete this.location;
    });
  }

  close() {
    if (this.gallery) {
      this.gallery.close();
    }
  }
}
photoSwipeService.$inject = [
  '$rootScope', '$location', '$timeout', '$interval', '$transitions', '$window'
];
export default photoSwipeService;
