import ImageModel from '../image/image.model';
import ArticleModel from './article.model';

class ArticleItemModel {
  constructor({id, order = 0, type, title, description, content, keywords, image = {}, article = {}}) {
    image = new ImageModel(image);
    article = new ArticleModel(article);
    Object.assign(this, {id, order, type, title, description, content, keywords, image, article});
  }
}

export default ArticleItemModel;
