import { Component } from '@angular/core';

@Component({
  selector: 'app-articles-wrapper',
  templateUrl: './articles-wrapper.component.html',
  styleUrl: './articles-wrapper.component.scss'
})
export class ArticlesWrapperComponent {

}
