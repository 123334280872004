import { Component } from '@angular/core';

@Component({
  selector: 'app-challenges-open-wrapper',
  templateUrl: './challenges-open-wrapper.component.html',
  styleUrl: './challenges-open-wrapper.component.scss'
})
export class ChallengesOpenWrapperComponent {

}
