function config() {
  'ngInject';

  // $stateProvider
  //   .state('gs.moderation', {
  //     url: '/moderation',
  //     redirectTo: 'gs.moderation.copyright',
  //     views: {
  //       'header@gs': {component: 'gsHeader'},
  //       'page@gs': {component: 'moderationPage'},
  //       'footer@gs': {component: 'gsFooter'}
  //     }
  //   })
  //   .state('gs.moderation.copyright', {
  //     url: '/copyright',
  //     template: '<moderation-copyright></moderation-copyright>'
  //   })
  //   .state('gs.moderation.offtopic', {
  //     url: '/offtopic',
  //     template: '<moderation-offtopic></moderation-offtopic>'
  //   })
  //   .state('gs.moderation.spam', {
  //     url: '/spam',
  //     template: '<moderation-images reason="$ctrl.moderatorReason.SPAM"></moderation-images>',
  //   })
  //   .state('gs.moderation.adult', {
  //     url: '/adult',
  //     template: '<moderation-images class="moderation-image__adult" reason="$ctrl.moderatorReason.ADULT_CONTENT"></moderation-images>',
  //   })
  //   .state('gs.moderation.offtopicImages', {
  //     template: '<moderation-images  reason="$ctrl.moderatorReason.OFF_TOPIC"></moderation-images>',
  //     params: {
  //       offtopicData: {
  //         value: null,
  //         squash: true
  //       }
  //     }
  //   })
  // ;
}

export default config;
