import { Injectable } from '@angular/core';
import RequestService from "./request/requestService";
import RequestServiceModel from "./request/requestService.model";
import NgRequestEnum from "./request/request.enum";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class FollowService {

  constructor(private requestService:RequestService) { }

  follow(el: string, el_id: string, om_id: string): Promise<any> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.FOLLOW.NAME,
      body: {
        el,
        el_id,
        om_id
      }
    });
    return firstValueFrom(this.requestService.request<any>(conf));
  }

  unfollow(el: string, el_id: string, om_id: string): Promise<any> {
    const conf = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.UNFOLLOW.NAME,
      body: {
        el,
        el_id,
        om_id
      }
    });
    return firstValueFrom(this.requestService.request<any>(conf));
  }
}
