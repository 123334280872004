const template = require(`html-loader!./adminLiveops.html`).default;

class adminLiveopsCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Suggest', route: 'admin/contentManager/liveops/suggest'},
    ];
  }
}

export default {
  template: template,
  controller: adminLiveopsCtrl,
  bindings: {}
};
