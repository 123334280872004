const template = require(`html-loader!./gsMembersList.html`).default;

class gsMembersListCtrl {
  constructor(
    PhotosManager,
    $element
  ) {
    'ngInject';

    this.start = 0;
    this.limit = 50;
    this.busy = false;
    this.allLoaded = false;
    this.items = [];

    this.PhotosManager = PhotosManager;
    this.$element = $element;

    this.onScroll = _.debounce(this.onScroll.bind(this), 250);
  }

  $onInit() {
    // Events
    $(this.$element[0]).find('.pl-data')
      .on('scroll', this.onScroll);
  }

  $onChanges() {
    if (!this.type || !this.image) {
      return false;
    }

    this.$element.addClass(this.type);

    this.loadMore();
  }

  loadMore() {
    if (this.allLoaded || this.busy) {
      return false;
    }
    this.busy = true;

    const data = {
      id: this.image.id,
      start: this.start,
      limit: this.limit
    };
    let Manager;
    switch (this.type) {
      case 'votes':
        Manager = this.PhotosManager.getVotesList(data);
        break;
      case 'likes':
        Manager = this.PhotosManager.getLikesList(data);
        break;
      default:
        return false;
    }
    Manager.then(res => {
      if(!res.success) {
        this.allLoaded = true;
        this.busy = false;
        return false;
      }

      this.items.push(...res.items);

      if(res.items.length < this.limit) {
        this.allLoaded = true;
      }

      this.start += this.limit;
      this.busy = false;
    });
  }

  onScroll(event) {
    const element = event.currentTarget;
    if (element.scrollTop === element.scrollHeight - element.offsetHeight) {
      this.loadMore();
    }
  }

  $onDestroy() {
    $(this.$element[0]).find('.pl-data').off('scroll', this.onScroll);
  }
}

gsMembersListCtrl.$inject = [
  'PhotosManager',
  '$element'
];

export default {
  template: template,
  controller: gsMembersListCtrl,
  bindings: {
    type: '@',
    image: '<'
  }
};
