import {NgModule} from "@angular/core";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {MatIconModule} from "@angular/material/icon";
import {MaterialDialogComponent} from "./components/material-dialog/material-dialog.component";
import {NgModalsService} from "./services/ng-modals.service";
import {
  MaterialDialogContentDirective
} from "./components/material-dialog/material-dialog-content.directive";
import {CommonModule} from "@angular/common";
import {MatDialogModule} from "@angular/material/dialog";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {NgSnackBarService} from "./services/ng-snack-bar.service";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatTabsModule} from "@angular/material/tabs";
import {MatInputModule} from "@angular/material/input";
import {ConfirmationDialogComponent} from "./components/confirmation-dialog/confirmation-dialog.component";
import {MatButtonModule} from "@angular/material/button";
import {SharedModule} from "../../shared/shared.module";

@NgModule({
  declarations: [
    MaterialDialogComponent,
    MaterialDialogContentDirective,
    ConfirmationDialogComponent
  ],
    imports: [
      SharedModule,
      /*A11yModule,*/
      /*CdkAccordionModule,*/
      /*ClipboardModule,*/
      /*CdkStepperModule,*/
      /*CdkTableModule,*/
      /*CdkTreeModule,*/
      DragDropModule,
      /*MatAutocompleteModule,*/
      /*MatBadgeModule,*/
      /*MatBottomSheetModule,*/
      /*MatButtonModule,*/
      /*MatButtonToggleModule,*/
      /*MatCardModule,*/
      /*MatCheckboxModule,*/
      /*MatChipsModule,*/
      /*MatStepperModule,*/
      /*MatDatepickerModule,*/
      MatDialogModule,
      /*MatDividerModule,*/
      /*MatExpansionModule,*/
      /*MatGridListModule,*/
      MatIconModule,
      MatInputModule,
      /*MatListModule,*/
      /*MatMenuModule,*/
      /*MatNativeDateModule,*/
      /*MatPaginatorModule,*/
      /*MatProgressBarModule,*/
      MatProgressSpinnerModule,
      /*MatRadioModule,*/
      /*MatRippleModule,*/
      /*MatSelectModule,*/
      /*MatSidenavModule,*/
      /*MatSliderModule,*/
      /*MatSlideToggleModule,*/
      MatSnackBarModule,
      /*MatSortModule,*/
      /*MatTableModule,*/
      MatTabsModule,
      /*MatToolbarModule,*/
      /*MatTooltipModule,*/
      /*MatTreeModule,*/
      /*OverlayModule,*/
      /*PortalModule,*/
      /*ScrollingModule,*/
      CommonModule,
      MatButtonModule
    ],
  exports: [
    MatDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatTabsModule,
    DragDropModule,
  ],
  providers: [
    NgModalsService,
    NgSnackBarService,
  ]
})

export class AngularMaterialModule { }




