import ChestAttributesModel from '../chest/chestAttributes.model';
import LeagueRankModel from './leagueRank.model';

class LeagueModel {
  constructor(props) {
    this.id = props.id;
    this.name = props.name;
    this.bucket_id = props.bucket_id;
    this.sub_name = props.sub_name;
    this.full_name = this.name + ' ' + this.sub_name;
    this.internal_name = props.internal_name;
    this.major_league_id = props.major_league_id;
    this.badge_url = props.badge_url;
    this.lobby_items = props.lobby_items;
    this.bucket_size = props.bucket_size;
    this.num_of_buckets = props.num_of_buckets || 0;
    this.percent_items_promote = props.percent_items_promote;
    this.percent_items_drop = props.percent_items_drop;
    this.drop = props.drop;
    this.promote = props.promote;

    if(props.rank) {
      this.rank = new LeagueRankModel(props.rank);
    }

    if(props.chest) {
      this.chest = new ChestAttributesModel(props.chest);
    }
  }
}

export default LeagueModel;
