const template = require(`html-loader!./adminDailyRewards.html`).default;

class adminDailyRewardsCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Missions', route: 'admin/contentManager/dailyRewards/missions'},
      {name: 'Prizes', route: 'admin/contentManager/dailyRewards/prizes'},
    ];
  }
}

export default {
  template: template,
  controller: adminDailyRewardsCtrl,
};
