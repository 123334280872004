import {PromiseHelper} from "../../../../../core/helpers/PromiseHelper";

const template = require(`html-loader!./challengeJoin.html`).default;
import mixpanelEventsEnum from "../../mixpanel/mixpanel.enum";

class challengeJoin {
  /**
   * @param {ChallengesManager} ChallengesManager
   * @param {ModalsService} Modals
   * @param {challengeService} challengeService
   * **/

  constructor(
    $mdDialog,
    $rootScope,
    ChallengesManager,
    $cookies,
    RouterService,
    gsModals,
    Modals,
    challengeService,
    mixpanelService,
    JoinedChallengesPageService,
    MemberService,
    brazeService,
  ) {
    'ngInject';
    this.busy = false;

    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
    this.ChallengesManager = ChallengesManager;
    this.$cookies = $cookies;
    this.RouterService = RouterService;
    this.gsModals = gsModals;
    this.Modals = Modals;
    this.challengeService = challengeService;
    this.mixpanelService = mixpanelService;
    this.joinedChallengesPageService = JoinedChallengesPageService;
    this.brazeService = brazeService;
    this.member = MemberService.getCurrentMember();
  }

  $onInit() {
    this.challenge = this.data.challenge;
    this.exposure = 0;
  }

  async fill() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.FILL);
    if (!this.busy) {
      if (this.challengeService.isFillEmpty()) {
        this.$mdDialog.hide();
        const fillsStoreEventParams = {outOfResourceId: 'out_fills' + Date.now()};
        this.brazeService.logCustomEvent('out_fills', fillsStoreEventParams);
        await this.brazeService.waitForBrazeMessage(this.openStoreFillsSection.bind(this), fillsStoreEventParams.outOfResourceId);
        return;
      }
      this.busy = true;
      const res = await this.challengeService.exposureAutofill({
        el: 'my_challenges',
        el_id: this.member.id,
        challenge_ids: [this.challenge.id],
      });

      if (!res.success) {
        this.$mdDialog.hide();
        return;
      } else {
        this.exposure = 100;
        await PromiseHelper.wait(1200);
        const challenge  = this.joinedChallengesPageService.activeChallenges.find(
          challenge => challenge.id === this.challenge.id);
        if (challenge) {
          challenge.member.ranking.total.exposure = 100;
        }
      }

      this.$mdDialog.hide();
      if (!this.$rootScope.$$phase) this.$rootScope.$digest();
    }
  }

  vote() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.VOTE);
    this.gsModals.open('vote', {
      challenge: this.challenge,
      callback: () => {
        this.RouterService.go('challenges/my-challenges/current');
      },
    });

    this.$mdDialog.hide();
  }

  close() {
    /** Explanation
     * Can't use this.close, in that case we will send to mixpanel a two events vote + close.
     * this.close - uses from template only
     * */
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.CLOSE);
    this.$mdDialog.hide();
  }

  eventMixPanelAction(eventName) {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.JOINED_CHALLENGE_POPUP,
          ui_name: eventName,
          challenge_id: this.challenge.id
        }
      }
    );
  }

  openStoreFillsSection() {
    this.challengeService.openStoreFillsSection();
  }
}
challengeJoin.$inject = [
  '$mdDialog',
  '$rootScope',
  'ChallengesManager',
  '$cookies',
  'RouterService',
  'gsModals',
  'Modals',
  'challengeService',
  'mixpanelService',
  'JoinedChallengesPageService',
  'MemberService',
  'brazeService',
];
export default {
  template: template,
  controller: challengeJoin,
  bindings: {
    data: '=data',
  },
};
