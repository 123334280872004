import { Component } from '@angular/core';

@Component({
  selector: 'app-dl-page-wrapper',
  templateUrl: './dl-page-wrapper.component.html',
  styleUrl: './dl-page-wrapper.component.scss'
})
export class DlPageWrapperComponent {

}
