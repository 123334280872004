import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlayersAmountService {

  public challengePlayersMap = new Map();

  getPlayersAmount(challengeId: number): number {
    return this.challengePlayersMap.get(challengeId);
  }

  setPlayersAmount(challengeId: number, players: number): void {
    this.challengePlayersMap.set(challengeId, players);
  }
}
