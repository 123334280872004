const template = require(`html-loader!./adminExhibitionsManagerItem.html`).default;

class adminExhibitionsManagerItemCtrl {
  /**
   * @param {ExhibitionsManager} ExhibitionsManager
   * */

  constructor(ExhibitionsManager, $rootScope, Modals) {
    'ngInject';
    this.busy = false;

    this.ExhibitionsManager = ExhibitionsManager;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
  }

  async select(image, type) {
    if (!this.busy) {
      if (image[type]) {
        const confirm = await this.Modals.confirm(
          'Remove this photo from selection?',
          'it will not appear in the exhibition.',
          'Remove',
          'Cancel'
        );
        if (!confirm) return;
      }
      this.sendExhibitionSelection(image, type);
      image[type] = image[type] === 1 ? 0 : 1;
      for (let photo of this.item.images) {
        if (photo.id === image.id) {
          photo[type === "print" ? "digital" : "print"] = 0;
          continue;
        }
        photo.print = 0;
        photo.digital = 0;
      }

      let resImages = [];
      if (image[type]) {
        resImages.push({
          print: image.print,
          digital: image.digital,
          id: image.id,
        });
      }
      console.log(resImages);
      this.busy = true;
      const res = await this.ExhibitionsManager.setExhibitionWinners(
        {
          challenge_id: this.challenge.id,
          member_id: this.item.member.id,
          images_data: resImages,
        }
      );
      console.log(res, 'response');
      if (!res.success) {
        console.log("error setExhibitionPhoto", res);
      }
      this.busy = false;
    }
  }

  sendExhibitionSelection(image, type){
    let counter = {print:0,digital:0};
    for (let photo of this.item.images) {
      if (photo.id === image.id) {
        counter[type] += photo[type] ? -1 : 1;
        const type2 = type === "print" ? "digital" : "print";
        if (photo[type2]){
          counter[type2] = -1;
        }
      }else{
        if (photo.print){counter.print--};
        if (photo.digital){counter.digital--};
      }
    }
    this.$rootScope.$emit('onExhibitionSelection', counter);
  }
}
adminExhibitionsManagerItemCtrl.$inject = [
  'ExhibitionsManager', '$rootScope', 'Modals'
];
export default {
  template,
  controller: adminExhibitionsManagerItemCtrl,
  bindings: {
    item: '<',
    challenge: '<',
  },
};
