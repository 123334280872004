import {NgGsIcon} from "./ng-gs-icon.model";
import {NgAchievementLevelModel} from "./ng-achievement-level.model";
import {INgAchievementLevelModel, INgAchievementModel} from "../interfaces/challenges.interface";

export class NgAchievementModel implements INgAchievementModel {
  unique_key = null;
  achievement_icon;
  achievement_white_icon;
  level_icon;
  levels: INgAchievementLevelModel[] = [];
  parsed_unique_key;

  constructor(props: INgAchievementModel = {}) {
    Object.assign(this, props);

    props.achievement_icon && (this.achievement_icon = new NgGsIcon(props.achievement_icon));
    props.achievement_white_icon && (this.achievement_white_icon = new NgGsIcon(props.achievement_white_icon));
    props.level_icon && (this.level_icon = new NgGsIcon(props.level_icon));
    props.levels && (this.levels = props.levels.map((level: INgAchievementLevelModel): INgAchievementLevelModel => new NgAchievementLevelModel(level)));
    props.unique_key && (this.parsed_unique_key = props.unique_key.replace(/\./g, '_'));
  }
}
