import {Subject} from "rxjs";
const template = require(`html-loader!./profilePage.html`).default;
import TeamModel from "../../../api/models/team/team.model";
import teamEnum from '../../../api/enums/team.enum';

class profilePageCtrl {
  profilePageMember;
  constructor(
    $scope,
    pageData,
    $rootScope,
    Modals,
    $window,
    $timeout,
    $location,
    deepLinkService,
    RestManager,
    stateService,
    MemberService,
    NgMetaDataService,
    RouterService
  ) {
    'ngInject';
    this.memberPath = null;
    this.bioFull = false;
    this.subject = new Subject();
    this.$scope = $scope;
    this.pageData = pageData;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.$window = $window;
    this.$timeout = $timeout;
    this.$location = $location;
    this.deepLinkService = deepLinkService;
    this.RestManager = RestManager;
    this.stateService = stateService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
    this.RouterService = RouterService;
    this.teamEnum = teamEnum;

    this.isBlockUserButton = false;

    this.forceReload = this.forceReload.bind(this);
    this.onChangeCover = this.onChangeCover.bind(this);
    this.onChangeAvatar = this.onChangeAvatar.bind(this);

    this.$rootScope.$on('userBlockingSuccess', this.displayUserButton.bind(this));
    this.member = this.MemberService.getCurrentMember();
  }

  $onInit() {
    setTimeout(()=>{
      this.init();
    });
  }

  isPhotosPageActive(name) {
    return this.RouterService.currentUrl.includes(`${name}/photos`);
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    $(document)
      .off('coverChange', this.onChangeCover)
      .off('avatarChange', this.onChangeAvatar);
    this.MemberService.resetMemberProfile();
  }

  async init(params = {}) {
    if (this.MemberService.profilePageMember) {
      this.NgMetaDataService.updateMetaTags({
        title: `${this.MemberService.profilePageMember.name}'s Photos`,
        description: `Explore the photography portfolio of ${this.MemberService.profilePageMember.name} on GuruShots. Discover their best shots and follow their journey in the photography community.`,
        keywords:[this.MemberService.profilePageMember.name, 'photography portfolio', 'GuruShots profile', 'user photos', 'photography journey']
      })
      this.counters = this.MemberService.profilePageMember.counters;
      this.profilePageMember = this.MemberService.profilePageMember;
      if (this.MemberService.isMyProfilePage) {
        this.MemberService.getMemberPath().then((res)=> {
          this.member_path = res.member_path;
        });
      }

      $(document)
        .on('coverChange', this.onChangeCover)
        .on('avatarChange', this.onChangeAvatar);

      this.$window.scrollTo(0, 0);
      // share link
      this.shareLink = `${this.$location.protocol()}://${this.$location.host()}/${this.member.user_name}`;
      // redirect if user deleted
      if (this.profilePageMember.isRestricted() && !this.member.is_admin) {
        window.location.href = '/error/page_404';
        return;
      }
      await this.getMemberTeamState();

      this.deepLinkService.check();
      await this.displayUserButton();

      this.done = true;

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      const numberOfSlashes = this.RouterService.currentUrl.split('/').length -1;
      if (numberOfSlashes <= 1) {
        const d = this.stateService.getProfileDefaultState(this.MemberService.isMyProfilePage);
        this.RouterService.go(`/${this.profilePageMember.user_name}/${d}`, params);
      }

    } else {
      this.stateService.goToDefaultState();
    }
  }

  async displayUserButton() {
    if (this.MemberService.isMyProfilePage) {
      this.isBlockUserButton = false;
    } else {
      const isMemberBlocked =
        await this.RestManager.isMemberBlocked({member_id: this.profilePageMember.id});
      this.isBlockUserButton = !isMemberBlocked.blocked; /* true/false */
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async getMemberTeamState(){
    const res = await this.RestManager.getMemberTeamState(this.profilePageMember.id);

    if(!res.success) {
      return;
    }

    this.memberTeamState = res.state;

    if(res.team) {
      this.team = new TeamModel(res.team);
    }
  }

  /** @return void **/
  async inviteMember(){
    if(!this.inviteMemberBusy){
      this.inviteMemberBusy = true;
      const res = await this.RestManager.inviteMember(this.profilePageMember.id);
      if(!res.success){
        this.inviteMemberBusy = false;
      } else {
        this.memberTeamState = teamEnum.MEMBER.STATE.INVITED;
      }
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  forceReload() {
    this.RouterService.go(this.RouterService.currentUrl, { force: true });
  }

  onChangeCover(e, url) {
    this.$timeout(() => {
      this.profilePageMember.profile_cover = url;
    });
  }

  onChangeAvatar(e, url) {
    this.$timeout(() => {
      this.profilePageMember.avatar = url;
    });
  }

  order(event) {
    this.Modals.open('shop.guruReview', event, {
      name: 'shop.guruReview',
      guruId: this.profilePageMember.id,
    });
  }

  bioMoreTrigger() {
    this.bioFull = !this.bioFull;
  }
}
profilePageCtrl.$inject = [
  '$scope',
  'pageData',
  '$rootScope',
  'Modals',
  '$window',
  '$timeout',
  '$location',
  'deepLinkService',
  'RestManager',
  'stateService',
  'MemberService',
  'NgMetaDataService',
  'RouterService'
];

export default {
  template,
  controller: profilePageCtrl,
};
