import { Component } from '@angular/core';

@Component({
  selector: 'app-lp5-page-wrapper',
  templateUrl: './lp5-page-wrapper.component.html',
  styleUrl: './lp5-page-wrapper.component.scss'
})
export class Lp5PageWrapperComponent {

}
