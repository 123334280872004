import {AchievementModel} from "../achievement/achievement.model";
import GsResponse from "./GsResponse";

class GetMemberAchievementsDeleteMessage extends GsResponse{
  constructor({success, achievements}) {
    super(success);
    if(this.success){
      this.achievements = achievements.map(achievement => new AchievementModel(achievement));
    }
  }
}


export default GetMemberAchievementsDeleteMessage;
