import {filter, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./teamsManager.html`).default;
import {
  TEAM_MANAGER_SEARCH_BY_ID_OPTION, TEAM_MANAGER_SEARCH_BY_NAME_OPTION, teamsManagerActionFieldType,
  teamsManagerConf
} from "./teamsManager.data";
import TeamModel from "../../../api/models/team/team.model";
import {resolveObjectPath} from "../../../helpers/object.helper";
import teamEnum from "../../../api/enums/team.enum";
import tableEnum from "../../../api/enums/table.enum";
import {isFieldValidNew} from "../../../helpers/validate.helper";
import {capitalizeFirstLetter} from "../../../helpers/string.helper";
import TeamMemberModel from "../../../api/models/team/teamMember.model";

class teamsManagerCtrl {
  subject = new Subject();
  constructor(
    $scope,
    $rootScope,
    RestManager,
    Modals,
    teamsService,
    notificationAlert,
    Toasts,
    $element,
    $timeout,
    RouterService
  ) {
    'ngInject';

    this.$scope = $scope;
    this.Toasts = Toasts;
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.RestManager = RestManager;
    this.notificationAlert = notificationAlert;
    this.teamsService = teamsService;
    this.$timeout = $timeout;
    this.resolveObjectPath = resolveObjectPath;
    this.teamsManagerActionFieldType = teamsManagerActionFieldType;
    this.RouterService = RouterService;

    this.firstInit = false;
    this.busy = false;
    this.error = false;
    this.teamEnum = teamEnum;
    this.tableEnum = tableEnum;
    this.teamsManagerConf = teamsManagerConf;
  }
  $onInit() {
    if (this.teamId) {
      this.searchText = this.teamId;
      this.searchType = TEAM_MANAGER_SEARCH_BY_ID_OPTION.value;
      this.findTeam();
    } else {
      this.searchType = TEAM_MANAGER_SEARCH_BY_NAME_OPTION.value;
    }
    this.offonMatchMembers = this.$rootScope.$on(tableEnum.EVENTS.MATCH_MEMBERS, this.onMatchMembers.bind(this));
    this.getGlobalActions();
  }

  async findTeam(){
    this.reset();
    if(!this.searchText){return}

    this.busy = true;
    if(!await this.getTeam()){
      this.busy = false;
      if (!this.$scope.$$phase) this.$scope.$digest();
      return;
    }

    this.getTeamMatches();

    const res = await Promise.all([
      this.getTeamLeftMembers(),
      this.getTeamLeagueInfo(),
      this.getTeamActions(),
    ]);

    this.success = res.every(success => success);
    if(!this.success){
      this.reset();
    }
    this.busy = false;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  reset(){
    this.team = null;
    this.teamLeftMembers = null;
    this.matches = null;
    this.matchMembers = null;
    this.selectedMatch = null;
    this.leagueInfo = null;
  }

  async getTeam(){
    const method = `getAdminTeamBy${capitalizeFirstLetter(this.searchType)}`;
    const res = await this.RestManager[method](this.searchText);
    if(res.success && res.team){
      this.team = new TeamModel(res.team);
      return true;
    }
    return false;
  }

  async getTeamLeftMembers(){
    const res = await this.RestManager.getTeamsAdminLeftMembers(this.team.id);
    if(res.success){
      this.teamLeftMembers = res.members.map( member => new TeamMemberModel(member));
    }
    return res.success;
  }

  async getTeamActions(){
    const res = await this.RestManager.getTeamsAdminActions(this.team.id);
    if(res.success){
      this.actions = res.actions;
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
    return res.success;
  }

  async getGlobalActions(){
    const res = await this.RestManager.getTeamsAdminGlobalActions();
    if(res.success){
      this.globalActions = res.actions;
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
    // return res.success;
  }

  async getTeamMatches(){
    this.matchesBusy = true;
    const res = await this.RestManager.getTeamsAdminMatches({
      team_id: this.team.id,
      // start: 0,
      // limit: 10,
    });
    this.matchesBusy = false;
    if(res.success){
      this.matches = res.matches;
    }
    if (!this.$scope.$$phase) this.$scope.$digest();
    return res.success;
  }

  async getTeamMatchMembers(){
    const res = await this.RestManager.getTeamsAdminMatchMembers({
      match_id: this.selectedMatch.match_id,
      team_id: this.selectedMatch.team.id
    });
    if(res.success){
      this.matchMembers = res.members.map( member => new TeamMemberModel(member));
    }else {
      this.matchMembers = [];
    }
    if (!this.$scope.$$phase) this.$scope.$digest();
    this.$timeout(() => {
      const targetOffset = $('.teams-manager__content__match-members').offset().top;
      $('html, body').animate({scrollTop: targetOffset}, 500);
      // $('.teams-manager__content').scrollTo('.teams-manager__content__match-members', 100);
    });
  }

  async getTeamLeagueInfo(){
    const res = await this.RestManager.getTeamsAdminLeagueInfo(this.team.id);
    if(res.success){
      this.leagueInfo = res.leagueInfo;
    }
    return res.success;
  }



  /** @return void */
  async teamAction(action){
    let reqData = {
      type: action.type,
    };
    if(this.team){
      reqData.team_id = this.team.id
    }
    if(action.fields){
      let fieldsData = {};
      for (let field of action.fields) {
        fieldsData[field.name] = this[field.name];
        let validRes = isFieldValidNew(fieldsData, {
          field: field.name,
          title: field.title,
          isNumber: field.type === 'number',
          minNumber: -10000,
          maxNumber: 10000,
        });

        if (!validRes.valid) {
          this.notificationAlert.infoSmall({message:validRes.message});
          return;
        }
      }
      reqData.json_data = JSON.stringify(fieldsData);
    }
    const res = await this.RestManager.teamsAdminAction(reqData);
    if(!res.success){
      this.Toasts.message('ERROR',
        angular.element(document.querySelector('.teams-manager__content__team-actions')), !res.success, 'bottom right');
      return;
    }
    this.findTeam();
  }

  onSearchByTypeChange(){
    this.searchText = "";
    this.reset();
    // this.findTeam();
  }

  onMatchMembers(event, data){
    this.selectedMatch = data.match;
    this.selectedMatch.team = data.team;
    this.getTeamMatchMembers();
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    if (this.offonMatchMembers) {
      this.offonMatchMembers();
    }
  }
}
teamsManagerCtrl.$inject = [
  '$scope',
  '$rootScope',
  'RestManager',
  'Modals',
  'teamsService',
  'notificationAlert',
  'Toasts',
  '$element',
  '$timeout',
  'RouterService'
];
export default {
  template: template,
  controller: teamsManagerCtrl,
  bindings: {
    teamId:'<'
  },
};
