const template = require(`html-loader!./adminSeasons.html`).default;
import SeasonModel from '../../../../../api/models/team/season.model';

class adminSeasonsCtrl {

  constructor($scope, RestManager) {
    'ngInject';
    this.busy = true;
    this.$scope = $scope;
    this.RestManager = RestManager;
  }

  $onInit() {
    this.getData();
    this.orderBy = '-id';
  }

  orderByToggle(field){
    this.orderBy = this.orderBy === `-${field}` ? `${field}` : `-${field}`;
  }

  async getData(){
    this.seasonsData = await this.RestManager.getLeagueSeasonConfig([0]);

    if (!this.seasonsData.success) {
      return false;
    }

    this.seasons = (this.seasonsData.seasons || []).map( season => new SeasonModel(season));
    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
adminSeasonsCtrl.$inject = [
  '$scope', 'RestManager'
];
export default {
  template: template,
  controller: adminSeasonsCtrl
};
