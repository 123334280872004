import ChatMemberColorsConst from './chatMemberColors.const';
import MessageModel from './message.model';
import chatEnum from '../../enums/chat.enum';
import ChatExternalModel from './chatExternal.model';
import ChatMediaModel from './chatMedia.model';

class ChatMessageModel {
  constructor(props) {
    this.id = props.id;
    this.chatID = props.chatID;
    this.memberID = props.memberID;
    this.memberName = props.memberName;
    this.memberUserName = props.memberUserName;
    this.type = props.type || chatEnum.CHAT_MESSAGE.TYPE.DEFAULT; //default, system
    this.additional = props.additional;
    this.avatar = props.avatar;
    this.origDateTime = props.dateTime;
    this.dateTime = props.dateTime;
    this.message = new MessageModel(props.message || {});

    if(props.external) {
      this.external = new ChatExternalModel(props.external);
    }
    if(props.media){
      this.media = new ChatMediaModel(props.media);
    }
  }

  get color() {
    return ChatMessageModel.getChatMemberColor(this.memberID);
  }

  static getChatMemberColor(memberId) {
    const last6 = memberId.slice(memberId.length - 6);
    let last6AsciiSum = 0;

    for (let i = 0; i < last6.length; i++) {
      last6AsciiSum += last6.charCodeAt(i);
    }

    const index = last6AsciiSum % 32;

    return ChatMemberColorsConst[index];
  }

  isMyMessage(myMemberId){
    return this.memberID === myMemberId;
  }
}

export default ChatMessageModel;
