class modalIntroCtrl {

  constructor($mdDialog, data) {
    'ngInject';

    this.$mdDialog = $mdDialog;
    this.data = data;
    this.selectedTab = 0;
  }

  next(step) {
    $(document).trigger({type: 'intro_next', step: step});
    this.$mdDialog.hide();
  }

  nextTab() {
    if(this.selectedTab === 2) {
      $(document).trigger({type: 'intro_next', step: '1.1'});
      return true;
    }

    this.selectedTab += 1;
  }

  hide() {
    $(document).trigger({type: 'intro_next', step: 'skip'});
    this.$mdDialog.hide();
  }
}
modalIntroCtrl.$inject = [
  '$mdDialog', 'data'
];
export default modalIntroCtrl;
