import pageData from './pageData.service';
import Toasts from './toasts/toasts';
import Modals from './Modals/modals';
import cards from './cards/cards';
import pushService from './push.service';
import GSPay from './GSPay/GSPay';
import popup from './popup.service';
import gsModals from './gsModals/gsModals';
import notificationAlert from './notificationAlert/notificationAlert';
import Facebook from './facebook.service';
import authService from './auth.service';
import rankFilters from "./ranking/rankFilters.service";
import deepLinkService from "./deepLink.service";
import stateService from "./state.service";
import challengeService from "./challenge.service";
import animationService from "./animation.service";
import tagEventService from "./tagEvent.service";
import brazeService from "./braze.service";
import socketService from "./socket.service";
import teamsService from "./teams.service";
import eventsHeaderService from "./eventsHeader.service";
import cookies from "./saveToClientSide/cookies";
import LocalStorageService from "./saveToClientSide/LocalStorageService";
import GsScrollTopService from "./scrollTop.service";
import ProtectionService from "./protection.service";
import chatService from "./chat.service";
import mixpanelService from "./mixpanel/mixpanel.service";
import autoUpdateService from './autoUpdate.service';
import idleService from './idle.service';
import leaderboardService from './leaderboard.service';
import gsBrowserService from "./gsBrowser.service";
import gsCommentsService from "../components/gsComments/gsComments.service";
import challengesSponsorsService
    from "../pages/gsAdmin/contentManager/adminChallenges/challengesSponsors/challengesSponsors.service";
import InfinityScrollService from "./infinityScroll.service";
import MarkAsAdultService from "../components/markAsAdult/markAsAdult.service";
import BlockedUserService from "./blockedUser.service";
import ResponseService from "./response.service";
import ModalsServiceBridge from "./modalsServiceBridge";
import {downgradeInjectable} from "@angular/upgrade/static";
import {BankrollService} from "../../../modules/bankroll/bankroll.service";
import {MemberService} from "../../../core/services/member.service";
import {SessionService} from "../../../core/services/session.service";
import {MediatorService} from "../../../core/services/mediator.service";
import {NgChallengesService} from "../../../modules/challenges/services/ng-challenges.service";
import {NgLocalStorageService} from "../../../core/services/ng-local-storage.service";
import {InfoService} from "../../../core/services/info.service";
import {JoinedChallengesPageService} from "../../../modules/challenges/services/joined-challenges-page.service";
import {SocketService} from "../../../core/services/socket.service";
import {NgModalsService} from "../../../modules/angular-material/services/ng-modals.service";
import {SessionConfigService} from "../../../core/services/session-config.service";
import RestrictedMemberService from "./restrictedMember.service";
import NgTokenService from "../../../core/services/ng-token.service";
import {StoreService} from "../../../modules/store/services/store.service";
import {NgMetaDataService} from "../../../core/services/ng-meta-data.service";
import {RouterService} from "../../../core/services/router.service";
import {MissionsService} from "../../../modules/missions/services/missions.service";

let moduleServices = angular.module('gsApp.services', [
    // service modules
    Modals,
    cards,
    Toasts,
    pageData,
    gsModals
  ])
    .service('pushService', pushService)
    .service('GSPay', GSPay)
    .service('popup', popup)
    .service('notificationAlert', notificationAlert)
    .service('Facebook', Facebook)
    .service('authService', authService)
    .service('rankFilters', rankFilters)
    .service('deepLinkService', deepLinkService)
    .service('stateService', stateService)
    .service('challengeService', challengeService)
    .service('animationService', animationService)
    .service('tagEventService', tagEventService)
    .service('brazeService', brazeService)
    .service('teamsService', teamsService)
    .service('socketService', socketService)
    .service('eventHeaderService', eventsHeaderService)
    .service('cookies', cookies)
    .service('localStorageService', LocalStorageService)
    .service('gsScrollTopService', GsScrollTopService)
    .service('protectionService', ProtectionService)
    .service('chatService', chatService)
    .service('mixpanelService', mixpanelService)
    .service('autoUpdateService', autoUpdateService)
    .service('idleService', idleService)
    .service('leaderboardService', leaderboardService)
    .service('gsBrowserService', gsBrowserService)
    .service('gsCommentsService', gsCommentsService)
    .service('challengesSponsorsService', challengesSponsorsService)
    .service('infinityScrollService', InfinityScrollService)
    .service('MarkAsAdultService', MarkAsAdultService)
    .service('BlockedUserService', BlockedUserService)
    .service('RestrictedMemberService', RestrictedMemberService)
    .service('ResponseService', ResponseService)
    .service('ModalsServiceBridge', ModalsServiceBridge)
    .factory('BankrollService', downgradeInjectable(BankrollService))
    .factory('MemberService', downgradeInjectable(MemberService))
    .factory('SessionService', downgradeInjectable(SessionService))
    .factory('MediatorService', downgradeInjectable(MediatorService))
    .factory('NgChallengesService', downgradeInjectable(NgChallengesService))
    .factory('NgLocalStorageService', downgradeInjectable(NgLocalStorageService))
    .factory('InfoService', downgradeInjectable(InfoService))
    .factory('JoinedChallengesPageService', downgradeInjectable(JoinedChallengesPageService))
    .factory('SocketService', downgradeInjectable(SocketService))
    .factory('NgModalsService', downgradeInjectable(NgModalsService))
    .factory('SessionConfigService', downgradeInjectable(SessionConfigService))
    .factory('NgTokenService', downgradeInjectable(NgTokenService))
    .factory('StoreService', downgradeInjectable(StoreService))
    .factory('NgMetaDataService', downgradeInjectable(NgMetaDataService))
    .factory('RouterService', downgradeInjectable(RouterService))
    .factory('MissionsService', downgradeInjectable(MissionsService))
;
export default moduleServices.name;
