import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MemberService} from "../../core/services/member.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-exhibition-widget-wrapper',
  templateUrl: './exhibition-widget-wrapper.component.html',
  styleUrl: './exhibition-widget-wrapper.component.scss'
})
export class ExhibitionWidgetWrapperComponent {
  id!: number;
  constructor(
    private route: ActivatedRoute,
    public memberService: MemberService,
  ) {
    this.route.params.pipe(
      takeUntilDestroyed()
    ).subscribe(async (params: any) => {
      this.id = params.id;
    });
  }
}
