const template = require(`html-loader!./teamInvites.html`).default;

class teamInvitesCtrl {

  constructor($scope, $rootScope) {
    'ngInject';
    this.showMaxItems = false;
    this.minItems = 2;
    this.maxItems = 20;
    this.limit = this.minItems;

    this.$scope = $scope;
    this.$rootScope = $rootScope;
  }

  $onInit() {}

  onHideShowItems(){
    this.showMaxItems = !this.showMaxItems;
    this.limit = this.showMaxItems ? this.maxItems : this.minItems;
  }
}
teamInvitesCtrl.$inject = [
  '$scope', '$rootScope'
];

export default {
  template: template,
  controller: teamInvitesCtrl,
  bindings: {
    teamInvites: '<'
  }
};
