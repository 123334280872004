import { Component } from '@angular/core';

@Component({
  selector: 'app-privacy-wrapper',
  templateUrl: './privacy-wrapper.component.html',
  styleUrl: './privacy-wrapper.component.scss'
})
export class PrivacyWrapperComponent {

}
