import { Component } from '@angular/core';

@Component({
  selector: 'app-challenges-closed-wrapper',
  templateUrl: './challenges-closed-wrapper.component.html',
  styleUrl: './challenges-closed-wrapper.component.scss'
})
export class ChallengesClosedWrapperComponent {

}
