import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SoundService {
  private audio?:HTMLAudioElement = undefined;
  previousMuteFlag:boolean = false;
  constructor(
  ) {}

  play(src:string, force:boolean = false) : Promise<Event> | undefined{
    if(!this.audio){
      return undefined;
    }

    //console.log('play', src, 'this.audio.ended', this.audio.ended);
    if(!this.muted && !this.audio.src || this.audio.ended || force) { //in order 2 sounds wont play in the same time
      this.audio.src = src;
      // this.audio.load();
      this.audio.play();
      return  new Promise<Event>((resolve)=>{
        this.audio!.addEventListener('ended', (e)=>{
          console.log('ended', e, new Date());
          resolve(e);
        },{once:true});
      });
    }
    return undefined;
  }

  pause(){
    if(!this.audio){return;}
    this.audio.pause()
  }

  load(srcList:string[]) {
    srcList.forEach( (src) => {
      new Audio(src).load();
    })
  }

  onMutButtonClick(){
    if(!this.audio){
      this.audio = new Audio();
      this.audio.muted = true;
    }
    this.muted = !this.muted;
  }

  get muted(){
    if(!this.audio){return true}
    return this.audio.muted;
  }

  set muted(muted){
    if(!this.audio){return}
    this.audio.muted = muted;
  }
}
