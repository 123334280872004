const template = require(`html-loader!./articleBlockYoutube.html`).default;

class articleBlockYoutubeCtrl {

  constructor(Modals) {
    "ngInject";

    this.Modals = Modals;
  }

  openVideo(event) {
    this.Modals.open('player', event, {
      type: 'youtube',
      id: this.item.content
    });
  }
}
articleBlockYoutubeCtrl.$inject = [
  'Modals',
];
export default {
  template: template,
  controller: articleBlockYoutubeCtrl,
  bindings: {
    item: '<'
  }
};
