import {Component, Input} from '@angular/core';
import {IStoreItem} from "../../services/store.service.types";
import {SectionType} from "../../models/enums";

@Component({
  selector: 'app-store-item-icon-amount',
  templateUrl: './store-item-icon-amount.component.html',
  styleUrls: ['./store-item-icon-amount.component.scss']
})
export class StoreItemIconAmountComponent {
  @Input() item!: IStoreItem;
  @Input() typeClass!: SectionType;
  @Input() itemSize!: number;
}
