import {filter, takeUntil, Subject} from "rxjs";
const template = require(`html-loader!./matchHistory.html`).default;
import MatchModel from "../../../../api/models/team/match.model";
import teamEnum from '../../../../api/enums/team.enum';
import TeamStateModel from '../../../../api/models/team/teamState.model';
import TeamModel from "../../../../api/models/team/team.model";

class matchHistoryCtrl {
  subject = new Subject();
  constructor(
    $scope,
    $rootScope,
    $filter,
    RestManager,
    stateService,
    tagEventService,
    teamsService
  ) {
    'ngInject';
    this.loadMore = true;
    this.busy = false;
    this.start = 0;
    this.limit = 20;
    this.matches = [];

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$filter = $filter;
    this.RestManager = RestManager;
    this.stateService = stateService;
    this.tagEventService = tagEventService;
    this.teamsService = teamsService;

    this.teamsService.getTeamsConfig$.pipe(
      filter(configData => !!configData),
      takeUntil(this.subject)
    ).subscribe(configData => {
      this.configData = configData;
    });

    this.teamsService.getTeams$.pipe(
      filter(res => !!res),
      takeUntil(this.subject)
    ).subscribe(async (res) => {
      this.team = new TeamModel(res.data.teams[0]);
      await this.init();
    });
  }

  async init(){
    this.tagEventService.sendPageViewEvent({title: 'Teams - my team - match history page'});
    $('match-history').on('scroll', this.onScroll.bind(this));
    await this.getData();
    this.initFinished = true;
  }

  $onDestroy(){
    $('match-history').off('scroll', this.onScroll);
    this.subject.next();
    this.subject.complete();
  }

  onScroll(e) {
    if($(e.target).scrollTop() + $(e.target).innerHeight() >= $(e.target)[0].scrollHeight - 800) {
      if(this.matches && this.loadMore){
        this.getData();
      }
    }
  }

  back(match_id){
    this.stateService.goToState('teams/home/match', {matchId: match_id}, true);
  }

  async getData(){
    if(!this.busy) {
      this.busy = true;
      const res = await this.RestManager.getMatchesHistory({
        team_id: this.team.id,
        start: this.start,
        limit: this.limit
      });

      if (!res.success) {
        return;
      }

      if (!this.team.totalMatches) {
        this.team.totalMatches = res.data.total_matches;
      }
      this.data = res.data.matches.map((match) => {
        let m = new MatchModel(match);
        m.winner = m.teams[0].total_votes > m.teams[1].total_votes;
        return m;
      });
      this.matchState = new TeamStateModel(res.state);
      this.setDetailItems();

      if (this.start === 0) {
        this.matches = this.data;
      } else {
        this.loadMore = this.data.length === this.limit;
        this.matches = [...this.matches, ...this.data];
      }
      this.start += this.limit;
      this.busy = false;
      if (!this.$scope.$$phase) {this.$scope.$digest()}
    }
  }

  setDetailItems() {
    this.teamDetailTypes = [
      teamEnum.DETAILS.TYPES.SCORE,
      teamEnum.DETAILS.TYPES.WIN,
      teamEnum.DETAILS.TYPES.LOSTS,
      teamEnum.DETAILS.TYPES.TOTAL_MATCHES
    ];
  }
}
matchHistoryCtrl.$inject = [
  '$scope',
  '$rootScope',
  '$filter',
  'RestManager',
  'stateService',
  'tagEventService',
  'teamsService'
];

export default {
  template: template,
  controller: matchHistoryCtrl,
};
