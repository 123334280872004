const template = require(`html-loader!./articles.html`).default;

class articlesCtrl {

  constructor(ArticlesManager,
              $timeout,
              $window,
              $document,
              $scope,
              pageData,
              $rootScope,
              tagEventService,
              MemberService,
              NgMetaDataService,
  ) {
    'ngInject';
    this.loading = false;
    this.inited = false;
    this.allLoaded = false;
    this.articles = [];
    this.start = 0;
    this.limit = 7;

    this.ArticlesManager = ArticlesManager;
    this.$timeout = $timeout;
    this.$window = $window;
    this.$document = $document;
    this.$scope = $scope;
    this.pageData = pageData;
    this.$rootScope = $rootScope;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;

    this.onScroll = this.onScroll.bind(this);
  }

  $onInit() {
    this.NgMetaDataService.updateMetaTags({
      title:`Photography Articles`,
      description:`Read the latest articles on photography tips, tutorials, and inspiring stories from the GuruShots community. Learn new techniques and stay updated with photography trends.`,
      keywords:[ 'photography articles', 'photo tips', 'photography tutorials', 'GuruShots articles', 'photography stories' ]
    });

    this.init();
  }

  init() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('articles');

    this.tagEventService.sendPageViewEvent({title: 'Articles page'});
    this.loggedIn = this.member?.logged_in;
    this.isAdmin = !!this.member?.is_admin;
    this.done = true;

    this.$window.scroll({top: 0, left: 0, behavior: 'smooth'});
    this.$document.on('scroll', this.onScroll);

    this.loadMore();
  }

  loadMore() {
    if (this.loading || this.allLoaded) {
      return;
    }
    this.loading = true;

    let params = {
      start: this.start,
      limit: this.limit
    };

    this.ArticlesManager.getArticles(params)
      .then(res => {
        if (!res.success) {
          return;
        }

        if (res.articles.length < this.limit) {
          this.allLoaded = true;
        }

        this.articles.push(...res.articles);

        this.$timeout(() => {
          this.inited = true;
          this.loading = false;
          this.start += this.limit;
        });
      })
      .catch(() => {
        this.inited = true;
        this.allLoaded = true;
      });
  }

  onScroll() {
    if (this.busy) {
      return true;
    }

    //Get more items event
    var windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    var body = document.body,
      html = document.documentElement;
    var docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );
    var windowBottom = windowHeight + window.pageYOffset;

    var getMoreHeight = screen.width > 800 ? -15 : -15;

    if (windowBottom - docHeight > getMoreHeight) {
      this.loadMore();
    }

    return true;
  }

  $onDestroy() {
    this.$document.off('scroll', this.onScroll);
  }
}
articlesCtrl.$inject = [
  'ArticlesManager',
  '$timeout',
  '$window',
  '$document',
  '$scope',
  'pageData',
  '$rootScope',
  'tagEventService',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: articlesCtrl,
};
