<div
  class="gs-avatar"
  [ngClass]="{'gs-p-0__important' : !showBadge, 'show-avatar': loaded}"
  [ngStyle]="{'background': showBadge && leagueConf.COLORS.AVATAR.BORDER}"
>

  <app-ng-image
    class="gs-avatar__img"
    [image]="avatar"
    [priority]="!lazy"
    [fill]="true"
    (click)="isClickEnabled && onClick()"
    (error)="onImageError()"
    (load)="onImageLoaded()"
  ></app-ng-image>

  <app-ng-image
    *ngIf="showBadge"
    class="gs-avatar__badge"
    image="/assets/svg/pages/leaderboard/avatar/{{member?.member_league_id}}.svg"
    [width]="19"
    [height]="19"
  ></app-ng-image>


  <div
    *ngIf="isTeamAdmin"
    class="admin-icon"
  >
  </div>
</div>
