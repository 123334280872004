function Confirm(
  $mdDialog
) {
  "ngInject";

  return {
    restrict: 'A',
    scope: {
      confirmTitle: '@',
      parentElmSel: '@',
      confirm: '&',
      canceled: '&'
    },
    link: (scope, elem) => {
      elem.bind('click', (ev) => {
        const options = {
          title: scope.confirmTitle,
          targetEvent: ev,
          hasBackdrop: false,
          ok: 'Yes',
          cancel: 'No'
        };
        if(scope.parentElmSel) {
          options.parent = $(scope.parentElmSel);
        }
        const modalConfirm = $mdDialog.confirm(options);
        $mdDialog
          .show(modalConfirm)
          .then(
            () => scope.confirm(),
            () => scope.canceled()
          );
      });
    }
  };
};

Confirm.$inject = [
  '$mdDialog'
];

export default Confirm;
