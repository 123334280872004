import {filter} from "rxjs";

class rankFilters {

  constructor(RouterService, $rootScope, MemberService) {
    'ngInject';
    this.filter = 'default';

    this.RouterService = RouterService;
    this.$rootScope = $rootScope;
    this._filterList = {};
    this._filtersListAdmin = {};
    MemberService.member$.pipe(
      filter(member => member !== undefined),
    ).subscribe(member => {
      this.member = member;
    });
  }

  filterList(url) {
    return {
    [`/challenge/${url}/rank/top-photographer`]: {
        default: 'All Members',
        following: 'Following',
        friends: 'Friends'
      },
      [`/challenge/${url}/rank/top-photo`]: {
        default: 'All Members',
        following: 'Following',
      },
      [`/challenge/${url}/rank/gurus-top-pick`]: {
        default: 'All Members',
        following: 'Following',
        my_photos: 'Me'
      }
    };
  }

  filtersListAdmin(url) {
    return {
      [`/challenge/${url}/rank/top-photographer`]: {
        adult: 'Adult content',
        off_topic: 'Off Topic',
        copyright: 'Copyrights Infringement',
        winning: 'Previous Winning Photo',
        similar: 'Similar image',
        paid_exhibition: 'Paid members'
      },
      [`/challenge/${url}/rank/top-photo`]: {
        paid_members: 'Paid members',
        selected_expo_1: 'Selected Expo Print',
        selected_expo_2: 'Selected Expo Digit'
      },
      [`/challenge/${url}/rank/gurus-top-pick`]: {
        paid_members: 'Paid members'
      }
    };
  }

  getList(url) {
    this._filterList = _.cloneDeep(this.filterList(url));
    this._filtersListAdmin = _.cloneDeep(this.filtersListAdmin(url));
    return this.member?.is_admin
      ? _.assignIn(this._filterList[this.RouterService.currentUrl], this._filtersListAdmin[this.RouterService.currentUrl])
      : this._filterList[this.RouterService.currentUrl];
  }

  getFilter() {
    if (!this._filterList[this.RouterService.currentUrl]) {
      return false;
    }

    if (!this._filterList[this.RouterService.currentUrl][this.filter]) {
      this.filter = 'default';
    }

    return this.filter;
  }

  setFilter(filter, event) {
    if (event) {
      event.stopPropagation();
    }
    // if same return false
    if (this.filter === filter) {
      return this.filter;
    }
    // set filter
    this.filter = filter ? filter : 'default';
    // this.$stateParams.filter = null;
    // update subscribers
    this.$rootScope.$emit('onFilterChanged', {filter: this.filter});
    return this.filter;
  }

  isAdminFilter() {
    return _.includes(Object.keys(this._filtersListAdmin[this.RouterService.currentUrl]), this.filter);
  }
}
rankFilters.$inject = [
  'RouterService', '$rootScope', 'MemberService'
];
export default rankFilters;
