import MediaModel from "../media.model";
import MemberModel from "../member.model";
import ImageModel from "../image/image.model";
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

// Helpers
class Summary {
  constructor({votes, photos, countries, members}) {
    Object.assign(this, {votes, photos, countries, members});
  }
}

class Comment {
  constructor({text, member = {}, image = {}}) {
    member = new MemberModel(member);
    image = new ImageModel(image);
    Object.assign(this, {text, member, image});
  }
}

class Action {
  constructor({text, url}) {
    Object.assign(this, {text, url});
  }
}

class Sponsor {
  constructor({url, image_id, image = {}}) {
    image = new ImageModel(image);
    Object.assign(this, {url, image_id, image});
  }
}
////

class ExhibitionModel {
  constructor({
    id,
    published = false, country, city, country_code, date, name, summary = {},
    challenge = {}, media = {}, comment = {}, action = {}, sponsor = {}
  }) {
    summary = new Summary(summary);
    challenge = new NgChallengeModel(challenge);
    media = new MediaModel(media);
    comment = new Comment(comment);
    action = new Action(action);
    sponsor = new Sponsor(sponsor);

    Object.assign(this, {
      id, published, country, city, country_code, date, name, summary, challenge,
      media, comment, action, sponsor
    });
  }
}

export default ExhibitionModel;
