import { Component } from '@angular/core';

@Component({
  selector: 'app-challenge-winners-all-wrapper',
  templateUrl: './challenge-winners-all-wrapper.component.html',
  styleUrl: './challenge-winners-all-wrapper.component.scss'
})
export class ChallengeWinnersAllWrapperComponent {

}
