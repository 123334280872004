export enum GsTimerEnum {
  BATTLE = 'battle',
  COOLDOWN = 'cooldown',
  FREEZE = 'freeze',
  SHIELD = 'shield',
  VOTE_INFO = 'vote_info',
  GALLERY_VISITOR_BONUS = 'gallery_visitor_bonus',
  CHALLENGE_TURBO_BUTTON = 'CHALLENGE_TURBO_BUTTON',
  CHALLENGE_TURBO_BUTTON_TOOLTIP = 'CHALLENGE_TURBO_BUTTON',
  RANKING = 'RANKING',
  MISSION_CARD_TIMER = 'MISSION_CARD_TIMER',
}
