function hideInvisible() {
  'ngInject';

  return {
    restrict: 'A',
    link: (scope, element, attr) => {
      let selector = attr.hideInvisible;
      let limit = attr.hideInvisibleLimit || 200;
      let elems = attr.hideInvisibleElems || '> *';
      onScroll = _.throttle(onScroll, 500);
      $(window)
        .off('scroll', onScroll)
        .on('scroll', onScroll);
      scope.$on('$destroy', function() {
        $(window).off('scroll', onScroll);
      });

      function onScroll() {
        let visible = [];
        let invisible = [];
        let el = $(selector);
        // limit to enable
        if (el.length < limit) return true;
        // show / hide
        el.each(function() {
          $(this).visible(true) ? visible.push(this) : invisible.push(this);
        });
        // if all invisible (for big vertical photos)
        if (visible.length) {
          visible.map(function(item) {
            $(item)
              .find(elems)
              .show();
          });
          invisible.map(function(item) {
            $(item)
              .find(elems)
              .hide();
          });
        }
      }
    },
  };
}

hideInvisible.$inject = [

];

export default hideInvisible;
