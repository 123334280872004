const challengeEnum = Object.freeze({
  TYPE: {
    BOOK: 'book',
    DEFAULT: 'default',
    EXHIBITION: 'exhibition',
    SPEED: 'speed',
    BATTLE: 'battle',
    FLASH: 'flash',
  },
  BADGE: {
    TEXT: {
      EXHIBITION: {
        LEFT: 'Exhibition',
        RIGHT: 'Challenge',
      },
      MAGAZINE: {
        LEFT: 'Magazine',
        RIGHT: 'Challenge',
      },
      PREMIUM: {
        LEFT: 'Premium',
        RIGHT: 'Exhibition',
      },
      FLASH: {
        LEFT: 'Flash',
        RIGHT: 'Challenge',
      },
    },
    TYPE: {
      PREMIUM: {
        NAME: 'premium',
        VALUE: 'premium',
      },
      EXHIBITION: {
        NAME: 'exhibition',
        VALUE: 'exhibition',
      },
      MAGAZINE: {
        NAME: 'magazine',
        VALUE: 'magazine',
      },
      FOUR_PHOTO: {
        NAME: 'Four photo',
        VALUE: 'fourphoto',
      },
      FLASH: {
        NAME: 'FLASH',
        VALUE: 'flash',
      },
    },
  },
  IMAGE: {
    SUGGEST: {
      TYPE: {
        DISABLED: 'disabled',
        ESQUERY: 'es_query',
        MOST_VOTED: 'most_voted',
      },
    },
  },
  STATUSES: {
    DRAFT: {
      NAME: 'draft',
      VALUE: 'draft',
    },
    PENDING: {
      NAME: 'pending',
      VALUE: 'pending',
    },
    UPCOMING: {
      NAME: 'upcoming',
      VALUE: 'upcoming',
    },
    UNASSIGNED: {
      NAME: 'unassigned',
      VALUE: 'unassigned',
    },
    IN_BID: {
      NAME: 'in bid',
      VALUE: 'in_bid',
    },
    ASSIGNED: {
      NAME: 'assigned',
      VALUE: 'assigned',
    },
    ACTIVE: {
      NAME: 'active',
      VALUE: 'active',
    },
    CLOSED: {
      NAME: 'closed',
      VALUE: 'closed',
    },
    CLOSING: {
      NAME: 'closing',
      VALUE: 'closing'
    },
    PENDING_FILTERED: {
      NAME: 'pending filtered',
      VALUE: 'pending_filtered',
    },
    PLANNING: {
      NAME: 'planning',
      VALUE: 'planning'
    }
  },
  RANK_LEVEL: {
    NOT_RANKED:{
      NAME: 'Not Ranked',
      VALUE: 0
    },
    POPULAR: {
      NAME: 'Popular',
      VALUE: 1
    },
    SKILLED: {
      NAME: 'Skilled',
      VALUE: 2
    },
    PREMIER: {
      NAME: 'Premier',
      VALUE: 3
    },
    ELITE: {
      NAME: 'Elite',
      VALUE: 4
    },
    ALL_STAR: {
      NAME: 'All Star',
      VALUE: 5
    },
  },
  ACTION: {
    SWAP: {
      NAME: 'swap',
      ICON: 'icon-swap',
      VALUE: 'swap',
    },
    BOOST: {
      NAME: 'boost',
      ICON: 'icon-boost',
      VALUE: 'boost',
    },
    FILL: {
      NAME: 'fill',
      VALUE: 'fill',
    },
    EXHIBIT: {
      NAME: 'exhibit',
      ICON: 'icon-star',
      VALUE: 'exhibit',
    },
    BOOK: {
      NAME: 'book',
      ICON: 'icon-book',
      VALUE: 'book',
    },
    KEY: {
      NAME: 'key',
      VALUE: 'key',
    },
  },
  SORT: {
    START_TIME: {
      NAME: 'Start time',
      VALUE: 'start_time',
    },
    CLOSE_TIME: {
      NAME: 'Close time',
      VALUE: 'close_time',
    },
    LAST_UPDATE_TIME: {
      NAME: 'Last update time',
      VALUE: 'last_update_time',
    },
    VOTES: {
      NAME: 'Votes',
      VALUE: 'votes',
    },
    ENTRIES: {
      NAME: 'Entries',
      VALUE: 'entries',
    },
    PLAYERS: {
      NAME: 'Players',
      VALUE: 'players',
    },
    ID: {
      NAME: 'Id',
      VALUE: 'id',
    },
  },
  BOOST: {
    AVAILABLE: 'AVAILABLE',
    AVAILABLE_KEY: 'AVAILABLE_KEY',
    LOCKED: 'LOCKED',
    MISSED: 'MISSED',
    USED: 'USED',
  },
  RULE:{
    LEVEL_REQUIREMENTS:'level requirements',
    PARTICIPATION:'participation'
  }
});

export default challengeEnum;
