<div class="challenges-navigation-wrapper ng1-container">
  <div class="challenges-navigation">
    <ng-container *ngFor="let tab of tabs;">
      <div class="top-tab-menu__item-wrapper"
           *ngIf="!tab.permissions || isTabPermitted(tab.permissions)"
      >
        <a
          class="top-tab-menu-item gs-theme-text-headline-sub"
          (click)="tabClicked(tab)"
          [routerLink]="tab.state"
          [routerLinkActive]="'active'"
        >
          <span [ngClass]="{'gs-red-dot': (tab.TITLE === 'Completed' && updateIndicator)}">{{tab.TITLE}}</span>
        </a>

      </div>
    </ng-container>
  </div>

  <div class="challenges-bankroll">
    <app-global-bankroll></app-global-bankroll>
  </div>
</div>
