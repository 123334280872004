const template = require(`html-loader!./activity.html`).default;
import ImageModel from '../../../api/models/image/image.model';

class activityComponent {
  /** @param {RestManager} RestManager **/

  constructor(
    $scope,
    pageData,
    RestManager,
    $timeout,
    gsScrollTopService,
    tagEventService,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.init = true;
    this.items = [];
    this.ready = false;
    this.busy = false;

    this.$scope = $scope;
    this.pageData = pageData;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;

    this.tools = {
      getFeed: this.getFeed.bind(this),
      busy: this.busy,
    };
  }

  $onInit() {
    this.NgMetaDataService.setTitle('Activity');

    this.gsScrollTopService.scrollTop();
    this.nextTime = Math.floor(Date.now() / 1000);
    this.initPage();
    this.getFeed();
  }

  /** @return void **/
  initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('activity');
    this.tagEventService.sendPageViewEvent({title: 'Activity page'});
  }

  /** @return void **/
  async getFeed() {
    if (this.busy) return;
    this.busy = true;
    const res = await this.RestManager.getFeed({
      s_t: this.nextTime,
      init: this.init,
    });
    if (!res.success) return;
    this.ready = true;
    this.nextTime = res.next_time;
    // if first request
    if (this.init) {
      this.init = false;
      this.side = res.side || {};
    }
    res.items = res.items.map(slide => {
      slide.photos = slide.photos.map(image => new ImageModel(image));
      slide.member.following = slide.following;
      return slide;
    });
    this.items.push(...res.items);
    await this.$timeout();
    this.busy = false;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }
}
activityComponent.$inject = [
  '$scope',
  'pageData',
  'RestManager',
  '$timeout',
  'gsScrollTopService',
  'tagEventService',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: activityComponent,
};
