import challengeEnum from '../../../api/enums/challenge.enum';
import memberEnum from "../../../api/enums/member.enum";
import {ChallengeType} from "../../../../../modules/challenges/enums/challenges.enum";

export const challengeFields = [
  'id',
  'as_limit_members',
  'as_max_joined_challenges',
  'auto_submit',
  'badge',
  'badge_text',
  'badge_left',
  'badge_right',
  'boost_enable',
  'exposure_decay_minutes',
  'exposure_required_votes',
  'description_title',
  'filter_submit_by_date',
  'guru_points',
  'guru_points_members',
  'image_id',
  'image_suggest_type',
  'image_suggest_query',
  'latest_upload_date',
  'key_lock_minutes',
  'max_photo_submits',
  'member_id',
  'member_income',
  'prizes_worth',
  'ranking_levels_id',
  'send_push',
  'status',
  'title',
  'type',
  'url',
  'vote_minutes_delay',
  'welcome_message',
  'welcome_video',
  'start_time',
  'close_time',
  'enable_rank_auto_update',
  'enable_off_topic',
  'enable_team_match',
  'apply_package_id',
  'tags',
  'bid_start_time',
  'apply_small_image_id',
  'apply_big_image_id',
  'apply_text',
  'apply_title',
  'join_member_status',
  'join_member_status_type',
  'exposure_decay_interval_minutes',
  'give_freebies_enable',
  'voting_max_photos_web',
  'voting_max_photos_mobile',
  'turbo_enable',
  'turbo_min_delay',
  'turbo_max_selections',
  'turbo_required_selections',
  'turbo_difficulty',
  'turbo_unlock_type',
  'guru_pick_enable',
  'top_photo_enable',
  'top_photographer_enable',
  'achievements_and_prizes',
  'legacy_achievements_enable',
  'vote_minimum_players',
  'max_players',
  'levels_rank_enable',
  'new_ranking_enable',
  'join_coins',
  'fill_enable',
  'fill_locked',
  'swap_enable',
  'swap_locked',
  'coins_instead_of_keys',
  'turbo_unlock_amount'
];

export const adminFieldsList = [
  'as_limit_members',
  'as_max_joined_challenges',
  'auto_submit',
  'badge',
  'badge_text',
  'badge_left',
  'badge_right',
  'boost_enable',
  'exposure_decay_minutes',
  'exposure_required_votes',
  'description_title',
  'duration',
  'filter_submit_by_date',
  'guru_points',
  'guru_points_members',
  'image_suggest_query',
  'latest_upload_date',
  'key_lock_minutes',
  'image_suggest_type',
  'max_photo_submits',
  'member_income',
  'prizes_worth',
  'send_push',
  'type',
  'vote_minutes_delay',
  'welcome_video',
  'enable_rank_auto_update',
  'enable_off_topic',
  'enable_team_match',
  'apply_package_id',
  'tags',
  'bid_start_time',
  'apply_small_image_id',
  'apply_big_image_id',
  'apply_text',
  'apply_title',
  'join_member_status',
  'join_member_status_type',
  'exposure_decay_interval_minutes',
  'give_freebies_enable',
  'voting_max_photos_web',
  'voting_max_photos_mobile',
  'turbo_enable',
  'turbo_min_delay',
  'turbo_max_selections',
  'turbo_required_selections',
  'turbo_difficulty',
  'turbo_unlock_type',
  'guru_pick_enable',
  'top_photo_enable',
  'top_photographer_enable',
  'achievements_and_prizes',
  'legacy_achievements_enable',
  'vote_minimum_players',
  'max_players',
  'levels_rank_enable',
  'new_ranking_enable',
  'join_coins',
  'fill_enable',
  'fill_locked',
  'swap_enable',
  'swap_locked',
  'coins_instead_of_keys',
];

export const unsavedFieldsList = [
  'is_bid_schedule'
];

const challengeDynamicPhotoDefault = {};

export const challengeDefSettings = {
  photos_2: challengeDynamicPhotoDefault,
  photos_3: challengeDynamicPhotoDefault,
  flash: {// 4 photos
    badge: ChallengeType.FLASH,
    badge_text: ChallengeType.FLASH,
    apply_small_image_id: '',
    apply_big_image_id: '',
    apply_text: '',
    apply_title: '',
    boost_enable: false,
    max_photo_submits: 4,
    key_lock_minutes:0,
    give_freebies_enable:false,
    enable_team_match:false,
    top_photo_enable:true,
    guru_pick_enable:false,
    top_photographer_enable:true,
  },
  default: {// 4 photos
    badge: ChallengeType.DEFAULT,
    badge_text: ChallengeType.DEFAULT,
    apply_small_image_id: '',
    apply_big_image_id: '',
    apply_text: '',
    apply_title: '',
    boost_enable: true,
    max_photo_submits: 4,
    top_photo_enable:true,
    guru_pick_enable:true,
    top_photographer_enable:true,
  },
  speed: {
    badge: ChallengeType.SPEED,
    badge_text: ChallengeType.SPEED,
    apply_small_image_id: '',
    apply_big_image_id: '',
    apply_text: '',
    apply_title: '',
    max_photo_submits: 1,
    boost_enable: false,
    top_photo_enable:true,
    guru_pick_enable:true,
    top_photographer_enable:false,
  },
  exhibition: {
    badge: ChallengeType.EXHIBITION,
    badge_text: ChallengeType.EXHIBITION,
    max_photo_submits: 4,
    boost_enable: true,
    top_photo_enable:true,
    guru_pick_enable:true,
    top_photographer_enable:true,
  }
};
export const maxPhotoSubmitsOptions = [1,2,3,4];
/* TODO: es6 enum */

export const exhibitionPackages = [102, 600, 601, 602, 603, 604, 605];

export const challengeTypes = [
  ChallengeType.DEFAULT,
  ChallengeType.EXHIBITION,
  ChallengeType.SPEED,
  ChallengeType.FLASH,
];

export const exhibitionBadges = [
  challengeEnum.BADGE.TYPE.PREMIUM,
  challengeEnum.BADGE.TYPE.EXHIBITION,
  challengeEnum.BADGE.TYPE.MAGAZINE
];

export const joinMemberStatuses = [
  memberEnum.STATUS.ANY_STATUS,
  memberEnum.STATUS.NOT_RANKED,
  memberEnum.STATUS.NEWBIE,
  memberEnum.STATUS.ROOKIE,
  memberEnum.STATUS.CHALLENGER,
  memberEnum.STATUS.ADVANCED,
  memberEnum.STATUS.VETERAN,
  memberEnum.STATUS.EXPERT,
  memberEnum.STATUS.CHAMPION,
  memberEnum.STATUS.MASTER,
  memberEnum.STATUS.GURU,
  memberEnum.STATUS.GURU_1,
  memberEnum.STATUS.GURU_2,
  memberEnum.STATUS.GURU_3,
  memberEnum.STATUS.GURU_4,
  memberEnum.STATUS.GURU_5,
  memberEnum.STATUS.GURU_6,
  memberEnum.STATUS.GURU_7,
  memberEnum.STATUS.GURU_8
];

export const joinMemberStatusTypeOnly = {
  VALUE:'only',
  NAME:'only'
};

export const joinMemberStatusTypes = [
  joinMemberStatusTypeOnly,
  {
    VALUE:'equals_and_above',
    NAME:'Equals and above'
  },
  {
    VALUE:'equals_and_below',
    NAME:'Equals and below'
  },
];

