/* globals angular, dataLayer, $ */
const template = require(`html-loader!./blackFriday.html`).default;

class blackFridayController {
  /**
   * @param {ModalsService} Modals
   * */

  constructor($element, Modals, $timeout, $cookies, challengeService) {
    'ngInject';

    this.$element = $element;
    this.Modals = Modals;
    this.$cookies = $cookies;
    this.$timeout = $timeout;
    this.challengeService = challengeService;
  }

  $onInit() {
    if(this.$cookies.get('hide-black-friday') === 'true') {
      return;
    }

    this.$timeout(()=> {
      this.$element.addClass('active');
    },4000);
  }

  close() {
    this.$element.removeClass('active');

    //Hide for 2 days
    let expired = new Date();
    expired.setDate(expired.getDate() + 2);
    this.$cookies.put('hide-black-friday', true, {expires: expired});
  }

  shop(event) {
    this.challengeService.openStoreSwapsSection();
  }
}
blackFridayController.$inject = [
  '$element', 'Modals', '$timeout', '$cookies', 'challengeService'
];
export default {
  template: template,
  controller: blackFridayController,
  bindings: {
    endDate: '<'
  }
};
