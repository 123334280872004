import { Component } from '@angular/core';

@Component({
  selector: 'app-lp2-page-wrapper',
  templateUrl: './lp2-page-wrapper.component.html',
  styleUrl: './lp2-page-wrapper.component.scss'
})
export class Lp2PageWrapperComponent {

}
