import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-mini-game-votes-meter',
  templateUrl: './mini-game-votes-meter.component.html',
  styleUrls: ['./mini-game-votes-meter.component.scss']
})
export class MiniGameVotesMeterComponent {
  @Input() game: any = {
    images: [],
    required_selections: 0,
    max_selections: 0
  };

  get successVotes(): number {
    return (this.game?.images ?? []).filter((image: any) => image.is_success)?.length;
  }
}
