import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'app-modal-help-button',
  templateUrl: './modal-help-button.component.html',
  styleUrls: ['./modal-help-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ModalHelpButtonComponent {}
