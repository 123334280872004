import TeamStateModel from './teamState.model';
import FeatureMessageModel from '../featureMessage.model';
import TeamModel from './team.model';
import LeagueModel from './league.model';
import SeasonModel from './season.model';
import MemberModel from "../member.model";

class LeagueDataModel {
  constructor(props) {
    this.bucket = props.data.buckets;
    this.myItem = props.data.my_item;
    // this.myItem = props.data.my_team;
    this.state = new TeamStateModel(props.state);
    this.season = new SeasonModel(props.data.config.season);
    this.league = new LeagueModel(props.data.config.league);
    this.featureMessages = props.data.feature_messages ? props.data.feature_messages.map (featureMessage => new FeatureMessageModel(featureMessage)) : [];
    this.featureMessage = this.featureMessages[0] || null;
  }
}

class LeagueTeamData extends LeagueDataModel{
  constructor(props) {
    super(props);
    this.promote = this.bucket.promote ? this.bucket.promote.map(team => new TeamModel(team)) : this.bucket.promote;
    this.stay = this.bucket.stay ? this.bucket.stay.map(team => new TeamModel(team)) : this.bucket.stay;
    this.drop = this.bucket.drop ? this.bucket.drop.map(team => new TeamModel(team)) : this.bucket.drop;
    this.myItem = new TeamModel(this.myItem);
  }
}

class LeagueMemberData extends LeagueDataModel{
  constructor(props) {
    super(props);
    this.promote = this.bucket.promote ? this.bucket.promote.map(member => new MemberModel(member)) : this.bucket.promote;
    this.stay = this.bucket.stay ? this.bucket.stay.map(member => new MemberModel(member)) : this.bucket.stay;
    this.drop = this.bucket.drop ? this.bucket.drop.map(member => new MemberModel(member)) : this.bucket.drop;
    this.myItem = new MemberModel(this.myItem);
  }
}

export {
  LeagueDataModel,
  LeagueTeamData,
  LeagueMemberData
};
