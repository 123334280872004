import {filter, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./achievementsCategory.html`).default;
import resolutionEnum from "../../../../../api/enums/resolution.enum";

class achievementsCategoryCtrl {
  subject = new Subject();
  constructor(achievementsService, RouterService) {
    'ngInject';
    this.showAchievements = false;
    this.achievementsService = achievementsService;
    this.RouterService = RouterService;

    this.RouterService.activatedRoute
      .queryParams.pipe(
      filter(params => params.achievementUniqueKey),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      if(params.achievementUniqueKey &&
        this.achievements.some(achievement => achievement.unique_key === params.achievementUniqueKey)){
        this.achievementCategory.showAchievements = true;
      }
    });
  }

  $onInit() {
    this.isMobile = document.body.clientWidth <= resolutionEnum.WIDTH.MOBILE_ACHIEVEMENTS_CATEGORY;
  }

  showCategoryAchievements(){
    if (this.achievementCategory.showAchievements) {
      this.achievementCategory.showAchievements = false;
    } else {
      this.achievementsService.hideAllCategoryAchievements();
      this.achievementCategory.showAchievements = true;
    }
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
achievementsCategoryCtrl.$inject = [
  'achievementsService', 'RouterService'
];
export default {
  template: template,
  controller: achievementsCategoryCtrl,
  bindings: {
    achievementCategory: '<',
    achievements: '<',
    preOpen: '@',
  }
};
