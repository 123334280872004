import LoaderEnum from "../../../../api/enums/loader.enum";
const template = require(`html-loader!./profileAchievementsAchievements.html`).default;
import PostRequest from "../../../../api/models/requests/post.request";
import restEnum from "../../../../api/enums/rest.enum";
import GetMemberAchievementsByLevelResponse
  from "../../../../api/models/responses/GetMemberAchievementsByLevel.response";
import LocalStorageEnum from "../../../../api/enums/localStorage.enum";
import {Subject, takeUntil} from "rxjs";

class profileAchievementsAchievementsCtrl {
  subject = new Subject();
  constructor($scope, $rootScope, RestManager, achievementsService, brazeService, localStorageService, $window, $timeout, MemberService, RouterService) {
    'ngInject';
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.achievementsService = achievementsService;
    this.brazeService = brazeService;
    this.window = $window;
    this.$timeout = $timeout;
    this.localStorageService = localStorageService;
    this.LoaderEnum = LoaderEnum;
    this.MemberService = MemberService;
    this.RouterService = RouterService;
    this.member = MemberService.getCurrentMember();
    if (this.member?.logged_in) {
      this.sendBrazeEvent();
    }
    this.busy = true;

    this.RouterService.activatedRoute.queryParams
      .pipe(
        takeUntil(this.subject)
      ).subscribe(async (params) => {
      await this.getData();
      this.scrollToCategory(params);
    });
  }

  scrollToCategory(params){
    if(params.achievementUniqueKey){
      const achievementCategoryToScrollTo = this.achievementsService.achievementCategories.find((achievementCategory) => {
        return achievementCategory.achievements.some(achievement =>
          achievement.unique_key === params.achievementUniqueKey);
      });
      if(achievementCategoryToScrollTo){
        this.$timeout(() => {
          const categoryClass = `.${params.achievementUniqueKey.replace(/\./g, '_')}`;
          console.log('categoryClass', categoryClass);
          $(window).scrollTo(categoryClass, 1000, {offset: -100});
        }, 250);
      }
    }
  }

  sendBrazeEvent(){
    const isFirstAchievementPageView =
      this.localStorageService.getDataByKey({key:LocalStorageEnum.KEY.FIRST_ACHIEVEMENT_PAGE_VIEW});

    if (!isFirstAchievementPageView){
      this.brazeService.sendFirstAchievementPageViewEvent();
      this.localStorageService.save({
        key: LocalStorageEnum.KEY.FIRST_ACHIEVEMENT_PAGE_VIEW,
        value: true
      });
    }
  }

  async getData() {
    const responseData = new GetMemberAchievementsByLevelResponse(
      await this.RestManager.postRequest(
        new PostRequest({
          endpoint: restEnum.END_POINTS.GET_MEMBER_ACHIEVEMENTS_BY_LEVEL.NAME,
          data: {
            member_id: this.MemberService.profilePageMember.id
          }
        })
      )
    );
    this.busy = false;
    if(!responseData.success){return}
    this.achievementsService.achievementCategories = responseData.categories;
    this.achievementsService.achievementsInfoHtml = responseData.info_html;
    this.info_html = responseData.info_html;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
profileAchievementsAchievementsCtrl.$inject = [
  '$scope', '$rootScope', 'RestManager', 'achievementsService', 'brazeService', 'localStorageService', '$window', '$timeout', 'MemberService', 'RouterService'
];
export default {
  template: template,
  controller: profileAchievementsAchievementsCtrl,
};
