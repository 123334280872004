export const teamConfirmTypes = {
  FACEBOOK_CONNECT : 'FACEBOOK_CONNECT',
  NO_COUNTRY : 'NO_COUNTRY',
};

export const teamConfirmTypesData = {
  [teamConfirmTypes.FACEBOOK_CONNECT] :
  {
    title: "Connect to Facebook",
    text: "In order to view and invite your Facebook friends, you will need to connect your Facebook account",
    imageUrl:"/assets/svg/modals/teamsConfirm/facebook-connect.svg",
    confirmText: "Connect Facebook account",
    dismissText: "Dismiss",
  },
  [teamConfirmTypes.NO_COUNTRY] :
  {
    title: "No Country Defined",
    text: "To see suggested members by country, you will need to define a country for your Team. Only Team Leaders can define this setting.",
    imageUrl:"/assets/svg/modals/teamsConfirm/no-country.svg",
    confirmText: "Edit Team",
    dismissText: "Dismiss",
  },
};
