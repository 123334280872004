export const trySwapConf = {
  controller: () => {},
  controllerAs: 'toast',
  template: `<try-swap-toast challenge="toast.challenge"></try-swap-toast>`,
  autoWrap: false,
  hideDelay: 0,
  position: 'bottom right',
  parent: '',
  bindToController: true
};
