const template = require(`html-loader!./country.html`).default;

class countryCtrl {
  constructor(
    $scope,
    $rootScope
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
  }
}

countryCtrl.$inject = [
  '$scope',
  '$rootScope'
];

export default {
  template: template,
  controller: countryCtrl,
  bindings: {
    country: '<',
    lazy: '@',
  }
};
