import challengePageCtrl from './challenge.page.ctrl';
//Directives
import challengeMenuScrollDirective from '../../directives/challenge.page.menuScroll.directive';
//Components
import challengeRank from './rank/rank';
import mobileRankingMenu from './rank/mobileRankingMenu/mobileRankingMenu';
import rankTopPhotographer from './rank/topPhotographer/topPhotographer';
import topPhotographerItem from './rank/topPhotographer/topPhotographerItem/topPhotographerItem';
import topPhotographerItemPlaceholder
  from './rank/topPhotographer/topPhotographerItemPlaceholder/topPhotographerItemPlaceholder';
import topPhotographerItemMobile from './rank/topPhotographer/topPhotographerItemMobile/topPhotographerItemMobile';
import topPhotographerItemMobilePlaceholder
  from './rank/topPhotographer/topPhotographerItemMobilePlaceholder/topPhotographerItemMobilePlaceholder';
import rankTopPhoto from './rank/topPhoto/topPhoto';
import rankGurusTopPick from './rank/gurusTopPick/gurusTopPick';
import challengeDetails from './details/details';
import challengePrizes from './prizes/prizes';
import challengeRules from './rules/rules';
import challengeWinnersAll from './winners/winners.all';
import challengeWinners from './winners/winners';
import challengeWinnersExhibition from './winners/winnersExhibition/winners.exhibition';
import myRank from './rank/myRank/myRank';
import challengePageService from "./challengePage.service";

let moduleChallengePage = angular.module('gsApp.challengePage', [])
  .directive('menuScroll', challengeMenuScrollDirective)
  .component('challengePageCtrl', challengePageCtrl)
  .service('challengePageService', challengePageService)
  //New structure
  .component('challengeRank', challengeRank)
  .component('mobileRankingMenu', mobileRankingMenu)
  .component('rankTopPhotographer', rankTopPhotographer)
  .component('topPhotographerItem', topPhotographerItem)
  .component('topPhotographerItemPlaceholder', topPhotographerItemPlaceholder)
  .component('topPhotographerItemMobile', topPhotographerItemMobile)
  .component('topPhotographerItemMobilePlaceholder', topPhotographerItemMobilePlaceholder)
  .component('rankTopPhoto', rankTopPhoto)
  .component('rankGurusTopPick', rankGurusTopPick)
  .component('challengeDetails', challengeDetails)
  .component('challengePrizes', challengePrizes)
  .component('challengeRules', challengeRules)
  .component('challengeWinnersAll', challengeWinnersAll)
  .component('challengeWinners', challengeWinners)
  .component('challengeWinnersExhibition', challengeWinnersExhibition)
  .component('myRank', myRank)
;

export default moduleChallengePage.name;
