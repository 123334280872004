const template = require(`html-loader!./followCard.html`).default;
import MemberModel from '../../api/models/member.model';

class followCardComponent {
  constructor(
    MemberService
  ) {
    'ngInject';

    this.callback;
    this.member;

    this.loggedinMember = MemberService.getCurrentMember();
  }

  $onChanges() {
    if (!(this.member instanceof MemberModel)) {
      this.member = new MemberModel(this.member);
    }
  }

  click() {
    if (this.member.following && this.callback) this.callback();
  }
}

followCardComponent.$inject = [
  'MemberService',
];

export default {
  template: template,
  controller: followCardComponent,
  bindings: {
    member: '<',
    callback: '&',
  },
};
