import {Subject, takeUntil, filter, take} from "rxjs";
const template = require(`html-loader!./teamHome.html`).default;
import TeamModel from "../../../api/models/team/team.model";
import teamEnum from '../../../api/enums/team.enum';
import {autoUpdateTypeEnum} from "../../../services/autoUpdate.service.data";
import leaderBoardEnum from "../../../api/enums/leaderBoard.enum";

class teamHomeCtrl {
  subject = new Subject();
  constructor(
    $scope,
    RestManager,
    $timeout,
    $location,
    Modals,
    Toasts,
    $element,
    $rootScope,
    teamsService,
    autoUpdateService,
    RouterService
  ) {
    'ngInject';

    this.refreshTime = 60000 * 10;
    this.$scope = $scope;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.$location = $location;
    this.Modals = Modals;
    this.Toasts = Toasts;
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.teamsService = teamsService;
    this.autoUpdateService = autoUpdateService;
    this.RouterService = RouterService;
    this.leaderBoardEnum = leaderBoardEnum;

    this.teamsService.getTeamsConfig$.pipe(
      filter(configData => !!configData),
      takeUntil(this.subject)
    ).subscribe(configData => {
      this.configData = configData;
    });

    this.teamsService.getTeams$.pipe(
      filter(res => !!res),
      take(1),
      takeUntil(this.subject)
    ).subscribe(async (res) => {
      this.team = new TeamModel(res.data.teams[0]);
      this.setAutoUpdate();

      await this.teamsService.openTeamIntro(teamEnum.INTRO_STATES.MY_TEAM);

      if (this.team?.current_member.isAdmin()) {
        await this.teamsService.openTeamIntro(teamEnum.INTRO_STATES.TEAM_LEADER);
      }
    });
  }

  $onInit() {
    this.teamChangedOff = this.$scope.$on('teamChanged', (evt, data)=>{
      this.getTeam(data.teamId);
    });
  }

  setAutoUpdate(){
    this.removeAutoRefresh();
    let autoUpdateObject = {
      type : autoUpdateTypeEnum.TEAM,
      callback : this.getTeam.bind(this),
      callbackParams : {teamId: this.team?.id},
      initCallbackOnStart: true
    };
    this.autoUpdateService.startAutoUpdateByType(autoUpdateObject);
  }

  removeAutoRefresh(){
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.TEAM);
  }

  async getTeam({teamId}){
    const res = await this.RestManager.getTeam(teamId);

    if (!res.success || !res.data || !res.data.teams || !res.data.teams[0]) {
      return;
    }

    this.team = new TeamModel(res.data.teams[0]);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  isActiveClassAdded(name) {
    if (
      (
        this.RouterService.currentUrl.includes('teams/home/leaderboard/leagues') ||
        this.RouterService.currentUrl.includes('teams/home/leaderboard/global')
      ) &&
      name === 'LeaderBoard'
    ) {
      return true
    }
    return false;
  }

  $onDestroy() {
    this.teamChangedOff();
    this.removeAutoRefresh();
    this.subject.next();
    this.subject.complete();
  }
}
teamHomeCtrl.$inject = [
  '$scope',
  'RestManager',
  '$timeout',
  '$location',
  'Modals',
  'Toasts',
  '$element',
  '$rootScope',
  'teamsService',
  'autoUpdateService',
  'RouterService'
];
export default {
  template: template,
  controller: teamHomeCtrl
};
