import ImageModel from './image/image.model';
import MemberModel from "./member.model";
import {Exposure} from "./Exposure";

class RankingModel {
  constructor({ total = {}, entries = [], swaps = [] , member, exposure = {}}) {
    this.total = new RankingTotal(total);
    this.entries = entries.map(image => new ImageModel(image));
    this.swaps = swaps.map(image => new ImageModel(image));
    if(member){
      this.member = new MemberModel(member);
    }
    this.exposure = new Exposure(exposure);
  }
}

class RankingTotal {
  constructor({ votes, rank, level, level_name, level_rank, next, percent, exposure, points, guru_picks, next_message}) {
    this.votes = votes;
    this.rank = rank;
    this.level = level;
    this.level_name = level_name;
    this.level_rank = level_rank;
    this.rankingLevel = new RankingLevel({
      name: level_name,
      level: level,
      level_rank: level_rank,
    });
    this.next = next;
    this.percent = percent;
    this.exposure = exposure;
    this.points = points;
    this.guru_picks = guru_picks;
    this.next_message = next_message;
  }
}

class RankingLevel {
  constructor({ name, level, level_rank}) {
    this.name = name; // level name form challengeEnum.RANK_LEVEL.NAME
    this.level = level; // level value form challengeEnum.RANK_LEVEL.VALUE
    this.level_rank = level_rank; // rank in level
  }
}

export default RankingModel;
