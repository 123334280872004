import reportCtrl from './report.ctrl';
const reportTemplate = require(`html-loader!./report.html`).default;


export const reportConf = {
  controller: reportCtrl,
  controllerAs: 'report',
  template: reportTemplate,
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
