import { Component } from '@angular/core';

@Component({
  selector: 'app-dmca-wrapper',
  templateUrl: './dmca-wrapper.component.html',
  styleUrl: './dmca-wrapper.component.scss'
})
export class DmcaWrapperComponent {

}
