import { Component } from '@angular/core';

@Component({
  selector: 'app-photo-contest-page-wrapper',
  templateUrl: './photo-contest-page-wrapper.component.html',
  styleUrl: './photo-contest-page-wrapper.component.scss'
})
export class PhotoContestPageWrapperComponent {

}
