import { Component } from '@angular/core';

@Component({
  selector: 'app-ai-page-wrapper',
  templateUrl: './ai-page-wrapper.component.html',
  styleUrl: './ai-page-wrapper.component.scss'
})
export class AiPageWrapperComponent {

}
