class protectionController {
  constructor() {
    'ngInject';
  }
}

protectionController.$inject = [

];

function protection() {
  'ngInject';

  return {
    restrict: 'E',
    transclude: true,
    scope: {
      gsText: '@',
    },
    controller: protectionController,
    link: function (scope, element, attr) {
      const fakeClasses = [
        'fakeU--s--',
        'fakeG--s--',
        'fakeZ--s--',
        'fakeS--s--',
        'fakeR--s--',
        'fakeT--s--',
        'fakeH--s--',
        'fakeO--s--',
        'fake0--s--',
        'fakeS--s--'
      ];
      const transcludeSelector = element[0].querySelectorAll('[ng-transclude]')[0];
      const transcludeElement = transcludeSelector.children[0];
      element[0].appendChild(transcludeElement);
      transcludeSelector.remove();

      const from = 1;
      const to = 10;
      const protectionValue1 = Math.floor(Math.random() * to) + from;
      const protectionValue2 = Math.floor(Math.random() * to) + from;
      element[0].prepend(tagGenerator(protectionValue1));
      element[0].append(tagGenerator(protectionValue2));

      function tagGenerator(amountOfElements) {
        const fragment = document.createDocumentFragment();

        for(let i = 0; i < amountOfElements; i++) {
          const newElement = document.createElement(transcludeElement.tagName.toLowerCase());

          newElement.className = fakeClasses[i];
          const copyOfAttributeList = Array.from(transcludeElement.attributes).filter(attribute => {if(attribute.name !== 'class' && attribute.name !== 'ng-click') {return attribute}});
          copyOfAttributeList.forEach((attribute) => {newElement.setAttribute(attribute.name, attribute.value)});
          newElement.textContent = scope.gsText ? scope.gsText : transcludeElement.textContent;

          fragment.appendChild(newElement);
        }

        return fragment;
      }
    },
    template: '<div ng-transclude></div>'
  };
}

protection.$inject = [

];

export default protection;
