import { Component } from '@angular/core';

@Component({
  selector: 'app-dl-lb-wrapper',
  templateUrl: './dl-lb-wrapper.component.html',
  styleUrl: './dl-lb-wrapper.component.scss'
})
export class DlLbWrapperComponent {
  state = {
    dlState: 'dl/lb',
    redirectState: 'challenges/my-challenges/current',
    type: 'lb'
  }
}
