/* globals angular */
/*
 * Simple Bg-image directive
 * */
function bgImage(
  $timeout,
  $rootScope
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      image: '<bgImage'
    },
    link: function (scope, element) {
      scope.$watch('image', () => {

        $timeout(() => {
          if (!scope.image) {
            return;
          }

          //Simple url
          if(typeof scope.image === 'string') {
            element.attr('style', `background-image:url('${scope.image}')`);
            return;
          }

          let height = $(element[0]).height();
          let width = $(element[0]).width();
          element.attr('style', `background-image:url('${$rootScope.photoDomain}/unsafe/${width}x${height}/${scope.image.member_id}/3_${scope.image.image_id}.jpg')`);
        });
      });

    }
  };
}

bgImage.$inject = [
  '$timeout',
  '$rootScope'
];

export default bgImage;
