import {filter, Subject, takeUntil} from "rxjs";

const template = require(`html-loader!./myRank.html`).default;
import ImageModel from '../../../../api/models/image/image.model';
import challengeEnum from "../../../../api/enums/challenge.enum";

class myRankCtrl {
  /** @param {ChallengesManager} ChallengesManager */
  /** @param {ModalsService} Modals */

  constructor(Modals, $rootScope, $scope, RankingManager, ChallengesManager, challengeService, MemberService) {
    'ngInject';
    this.busy = true;
    this.subject = new Subject();
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.RankingManager = RankingManager;
    this.ChallengesManager = ChallengesManager;
    this.challengeService = challengeService;
    this.challengeEnum = challengeEnum;

    MemberService.member$.pipe(
      filter(member => member !== undefined),
      takeUntil(this.subject)
    ).subscribe(member => {
      this.member = member;
    });
  }

  $onInit() {
    this.getData();
  }

  getData() {
    if (!this.challenge) {
      return;
    }

    let requestData = {
      c_id: this.challenge.id,
      start: 0,
      limit: 4,
      filter: 'my_photos'
    };
    this.RankingManager.getTopPhoto(requestData).then(res => {
      if (!res.success) {
        return;
      }
      this.images = res.items.map(image => new ImageModel(image));
      this.busy = false;
    });
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }

  preSubmit(event) {
    this.Modals.open('gsUploader', event, {
      target: 'challenge',
      challenge: this.challenge
    });
  }

  async remove(imageToRemove) {
    if(await this.challengeService.removeImageFromChallenge(
      {
        imageToRemove:imageToRemove,
        challenge: this.challenge,
      }
    )){
      this.images = this.images.filter(image => image.id !== imageToRemove.id);
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
  }
}
myRankCtrl.$inject = [
  'Modals', '$rootScope', '$scope', 'RankingManager', 'ChallengesManager', 'challengeService', 'MemberService'
];
export default {
  template: template,
  controller: myRankCtrl,
  bindings: {
    challenge: '<'
  }
};
