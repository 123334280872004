import GsResponse from "./GsResponse";
import Chest from "../chest/chest.model";
import {AchievementModel, AchievementCategory} from "../achievement/achievement.model";

class GetMemberAchievementsByLevelResponse extends GsResponse{
  constructor({chest, info_html, categories, success}) {
    super(success);
    Object.assign(this,
      (![undefined,null].includes(chest)) && {chest: new Chest(chest)},
      (![undefined,null].includes(info_html)) && {info_html},
      (![undefined,null].includes(categories)) && {categories: categories.map(
        (item) => {
          item.category = new AchievementCategory(item.category);
          item.achievements = item.achievements.map(achievement => new AchievementModel(achievement));
          return item;
        })
      },
    );
  }
}


export default GetMemberAchievementsByLevelResponse;
