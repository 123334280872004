import gsIconEnum from "../../api/enums/gsIcon.enum";

const challengesRewardsTemplate = require(`html-loader!./challengesRewards.html`).default;

class ChallengesRewards {
  isChest = false;

  constructor(

  ) {
    'ngInject';

    this.gsIconEnum = gsIconEnum;
  }

  $onInit() {
    this.isRewards = !this.challenge.member.isChest() && this.challenge.member.rewards.length;
  }
}

ChallengesRewards.$inject = [

];

export default {
  template: challengesRewardsTemplate,
  controller: ChallengesRewards,
  bindings: {
    challenge: '<'
  }
}
