const template = require(`html-loader!./teamsViewButton.html`).default;

class TeamsViewButton {
  constructor(

  ) {
    'ngInject';
  }
}

TeamsViewButton.$inject = [

];

export default {
  template: template,
  controller: TeamsViewButton,
  bindings: {
    teamId: '<',
    joinBy: '<'
  }
};
