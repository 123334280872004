<div class="saved-card">
  <div class="gs-theme-text-bold gs-theme-color-gray_60 gs-t-a-c">Pay with your saved card</div>
  <div class="card gs-m-t-25">
    <div class="gs-d-f gs-f-d-r gs-j-c-s-b">
      <div class="gs-theme-text-regular-small gs-theme-color-cool_90">Card number</div>
      <div class="gs-theme-text-regular-small gs-theme-color-cool_90">{{cardType?.toUpperCase()}}</div>
    </div>
    <div class="gs-theme-text-bold gs-theme-color-cool_90">XXX XXX XXX {{last4numbers}}</div>
  </div>
  <div class="gs-t-a-c gs-m-t-20">
    <button (click)="useAnotherCard()"
            class="another-card">use another card</button>
  </div>
</div>
