import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dl-boost-wrapper',
  templateUrl: './dl-boost-wrapper.component.html',
  styleUrl: './dl-boost-wrapper.component.scss'
})
export class DlBoostWrapperComponent {
  cId = null;
  state = {
    dlState: 'dl/boost',
    redirectState: 'challenges/my-challenges/current',
    type: 'boost'
  }

  constructor(
    private route: ActivatedRoute
  ) {
    this.route
      .params.pipe(
    ).subscribe(async (params: any) => {
      this.cId = params.cId;
    });
  }
}
