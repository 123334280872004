const template = require(`html-loader!./gsChallengeNew.html`).default;
import gsChallengeEnum from "../../api/enums/gsChallenge.enum";

class gsChallengeNewCtrl {
  constructor(
    $scope,
    $rootScope
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.gsChallengeEnum = gsChallengeEnum;
  }
}

gsChallengeNewCtrl.$inject = [
  '$scope',
  '$rootScope'
];

export default {
  template: template,
  controller: gsChallengeNewCtrl,
  bindings: {
    challenge: '<',
    achievement: '<',
    mode: '@',
    lazy: '@'
  }
};
