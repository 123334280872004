const template = require(`html-loader!./adminLiveopsSuggest.html`).default;

class adminLiveopsCtrl {

  constructor($scope, RestManager, Toasts, $element, stateService) {
    'ngInject';
    this.busy = true;
    this.newChallenge = {};

    this.RestManager = RestManager;
    this.Toasts = Toasts;
    this.$element = $element;
    this.stateService = stateService;
    this.$scope = $scope;
  }

  $onInit() {
    this.loadData();
  }

  async loadData(){
    let res = await this.RestManager.getChallengePriorities();
    this.busy = false;
    if (!res.success) {
      this.stateService.goToDefaultState();
      return;
    }
    this.challenge_priorities = res.challenge_priorities;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  add(){
    if(this.newChallenge.challenge_id && this.newChallenge.priority){
      const priorityExist = this.challenge_priorities.find( el => el.priority === this.newChallenge.priority);
      if(priorityExist){
        this.Toasts.message(`Priority ${priorityExist.priority} already exist`, this.$element);
        return;
      }
      const challengeExist = this.challenge_priorities.find( el => el.challenge_id === this.newChallenge.challenge_id);
      if(challengeExist){
        this.Toasts.message(`Challenge ${challengeExist.challenge_id} already exist`, this.$element);
        return;
      }
      this.challenge_priorities.unshift(angular.copy(this.newChallenge));
      this.newChallenge = {};
      console.log(this.challenge_priorities);
    }
  }

  delete(challenge_priority){
    for (let i=0; i<this.challenge_priorities.length; i++) {
      if(this.challenge_priorities[i].challenge_id === challenge_priority.challenge_id){
        this.challenge_priorities.splice(i, 1);
        break;
      }
    }
  }

  async update(){
    let res = await this.RestManager.setChallengePriorities({challenge_priorities:this.challenge_priorities});
    if (!res.success) {
      this.Toasts.message('ERROR', this.$element);
      return;
    }
    if(res.invalid_challenge_ids && res.invalid_challenge_ids.length > 0){
      this.Toasts.message('invalid ids - \n' + res.invalid_challenge_ids.join('\n'), this.$element);
    }else{
      this.Toasts.message('SUCCESS', this.$element, false);
    }

    await this.loadData();
    // this.stateService.reload();
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
adminLiveopsCtrl.$inject = [
  '$scope', 'RestManager', 'Toasts', '$element', 'stateService'
];
export default {
  template: template,
  controller: adminLiveopsCtrl,
  bindings: {}
};
