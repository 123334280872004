const template = require(`html-loader!./privacyPage.html`).default;

class privacyPageComponent {

  constructor(
    pageData,
    $window,
    $scope,
    tagEventService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.NgMetaDataService = NgMetaDataService;
  }

  $onInit() {
    this.NgMetaDataService.setTitle('Privacy');
    // to top
    this.$window.scrollTo(0, 0);
    // init
    this.initPage();
  }

  /** @return void **/
 initPage() {
    this.pageData.get('privacy');
    this.tagEventService.sendPageViewEvent({title: 'Privacy page'});
  }
}
privacyPageComponent.$inject = [
  'pageData',
  '$window',
  '$scope',
  'tagEventService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: privacyPageComponent
};
