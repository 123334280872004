const template = require(`html-loader!./adminRewardedVideo.html`).default;
import {rewardedVideoValues} from './adminRewardedVideo_data';

class adminRewardedVideoCtrl {
  /**
   * @param {RestManager} RestManager
   * */

  constructor($scope, RestManager, Toasts, $element, RouterService) {
    'ngInject';
    this.busy = true;

    this.RestManager = RestManager;
    this.Toasts = Toasts;
    this.$element = $element;
    this.RouterService = RouterService;
    this.$scope = $scope;
  }

  $onInit() {
    this.getValues();
  }

  async getValues() {
    let res = await this.RestManager.rewardedVideoGetSettings();
    this.busy = false;
    if (!res.success) {
      this.RouterService.go('challenges/my-challenges/current');
      return;
    }
    this.placements = res.placements;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async save() {
    if (this.validate()) {
      let res = await this.RestManager.rewardedVideoSetSettings({placements: this.placements});
      if (!res.success) {
        this.Toasts.message('Error', this.$element);
        return;
      }
      this.Toasts.message('Saved!', this.$element, false);
    }
  }

  validate() {
    let errors = [];
    let global_required_fields = ['days_since_signup', 'days_since_payment'];
    if(!this.placements){
      return false;
    }

    for (let placement of this.placements) {
      if (this.isGlobal(placement)){
        for (let required_field of global_required_fields) {
          if ((!placement[required_field] && placement[required_field] !== 0) || placement[required_field] < 0) {
            errors.push(`global - ${required_field} is not valid`);
          }
        }

      }else{
        if ((!placement.cool_down && placement.cool_down !== 0) || placement.cool_down < 0) {
          errors.push(`${placement.name} - cooldown_minutes is not valid`);
        }
      }
    }
    if(errors.length){
      this.Toasts.message(errors.join('\n'), this.$element);
    }
    return errors.length === 0;
  }

  isGlobal(placement) {
    return placement.placement_id === 0 || placement.placement_id === '0';
  }

}
adminRewardedVideoCtrl.$inject = [
  '$scope', 'RestManager', 'Toasts', '$element', 'RouterService'
];
export default {
  template: template,
  controller: adminRewardedVideoCtrl,
  bindings: {},
};
