const template = require(`html-loader!./gsNotificationsItem.html`).default;
import ImageModel from '../../../api/models/image/image.model';

class gsNotificationsItemCtrl {
  /**
   * @param {NotificationsManager} NotificationsManager
   * @param {gsNotificationsService} gsNotificationsService
   * */
  constructor(
    $element,
    $timeout,
    Modals,
    NotificationsManager,
    photoSwipe,
    gsNotificationsService,
    $rootScope
  ) {
    'ngInject';

    this.$element = $element;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.NotificationsManager = NotificationsManager;
    this.photoSwipe = photoSwipe;
    this.gsNotificationsService = gsNotificationsService;
    this.$rootScope = $rootScope;
  }

  $onChanges() {
    this.message.avatar = {
      url: this.message.icon_1
    };
    this.message.image1 = {
      url: `${this.$rootScope.photoDomain}/unsafe/60x60/${this.message.icon_1}/3_${this.message.icon_1_param}.jpg`
    };
    this.message.image2 = {
      url: `${this.$rootScope.photoDomain}/unsafe/60x60/${this.message.icon_2}/3_${this.message.icon_2_param}.jpg`
    };

    switch (this.message.event_type) {
      case 'LIKE':
      case 'VOTE':
      case 'LIKE_AGG_TWO':
      case 'LIKE_AGG_MANY':
        this.image = new ImageModel({
          id: this.message.icon_2_param,
          member_id: this.message.icon_2
        });
        return false;
    }
  }

  clickLink(event, column) {
    // close notifications
    this.tools.close();
    // read notification
    if (!this.message.read) {
      this.message.read = true;
      this.NotificationsManager.readNotifications(this.message.date, this.type);
    }

    if (this.message.event_type === 'VOTE') {
      if (column === 2) {
        this.openPhoto(event);
        return false;
      }
      return true;
    }

    // open modal
    if (this.gsNotificationsService.openModal(this.message, true)) {
      return true;
    }
    event.preventDefault();
    return false;
  }

  openPhoto(event) {
    event.preventDefault();
    const image = new ImageModel({
      member_id: this.message.icon_2,
      id: this.message.icon_2_param
    });
    this.photoSwipe.open({images:[image]});
    return false;
  }
}

gsNotificationsItemCtrl.$inject = [
  '$element',
  '$timeout',
  'Modals',
  'NotificationsManager',
  'photoSwipe',
  'gsNotificationsService',
  '$rootScope'
];

export default {
  template: template,
  controller: gsNotificationsItemCtrl,
  bindings: {
    message: '<',
    tools: '<',
    type: '@'
  }
};
