import {Component, Input} from '@angular/core';
import {PaymentService, PaymentState} from "../../payment.service";

@Component({
  selector: 'app-saved-card',
  templateUrl: './saved-card.component.html',
  styleUrls: ['./saved-card.component.scss']
})
export class SavedCardComponent {
  @Input() last4numbers?: string;
  @Input() cardType?: string;

  constructor(
    private paymentService: PaymentService
  ) {}

  useAnotherCard(): void {
    this.paymentService.paymentState$.next(PaymentState.NEW_CARD);
  }
}
