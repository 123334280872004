const template = require(`html-loader!./horizontalScrollbarArrows.html`).default;

class horizontalScrollbarArrowsCtrl {
  constructor(
    $scope,
    $rootScope,
    $interval
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$interval = $interval;
    this.position = 0;
    this.duration = 300;
    this.inited = false;

    this.offinitArrows = this.$rootScope.$on('listReady', this.initArrows.bind(this));
  }

  $onDestroy() {
    this.offinitArrows();
  }

  initArrows(){
    this.divMain = $(`.${this.mainSelector}`)[0];
    if(this.divMain){
      this.children = $(this.divMain).children();
      this.slideAmount = parseInt(this.slideAmount);
      this.position = 0;
      this.inited = true;
      this.hasScrollBar = this.divMain.scrollWidth > this.divMain.clientWidth;
    }
  }

  arrowLeft(){
    if(this.isScrollLeft()) {
      this.position -= this.slideAmount;
      this.scrollLeft(this.position, ()=>{
        if(this.scrolling){
          this.arrowLeft();
        }
      });
    }
  }

  arrowRight(){
    if(this.isScrollRight()) {
      this.position += this.slideAmount;
      this.scrollLeft(this.position, ()=>{
        if(this.scrolling){
          if(this.isScrollRight()){
            this.arrowRight();
          }else {
            this.stopInterval();
            this.stopeRetry = this.$interval(this.arrowRight.bind(this), 1000);
          }
        }
      });
    }
  }

  scrollLeft(scrollToPosition, callback){
    $(this.divMain).animate({
      scrollLeft: scrollToPosition
    },
    this.duration,
    'linear',
    callback);
  }

  isScrollLeft(){
    this.position = this.position > 0 ? this.position : 0;
    return this.position > 0;
  }

  isScrollRight(){
    if(!this.inited){
      return true;
    }
    if(this.inited) {
      if(this.position < this.getMaxScrollRight()){
        return true;
      } else {
        this.position = this.getMaxScrollRight();
      }
    }
    return false;
  }

  getMaxScrollRight(){
    return $(this.divMain)[0].scrollWidth - $(this.divMain)[0].offsetWidth;
  }

  onRightArrowDown(){
    if(this.isScrollRight()){
      this.scrolling = true;
      this.arrowRight();
    }
  }

  onLeftArrowDown(){
    if(this.isScrollLeft()){
      this.scrolling = true;
      this.arrowLeft();
    }
  }

  stopInterval(){
    if(this.stopeRetry){
      this.$interval.cancel(this.stopeRetry);
    }
  }

  onMouseUp(){
    this.scrolling = false;
    this.stopInterval();
  }

  onMouseLeave(){
    this.scrolling = false;
    this.stopInterval();
  }
}

horizontalScrollbarArrowsCtrl.$inject = [
  '$scope',
  '$rootScope',
  '$interval'
];

export default {
  template: template,
  controller: horizontalScrollbarArrowsCtrl,
  bindings: {
    mainSelector: '@',
    slideAmount: '@'
  }
};
