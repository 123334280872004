import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClaimStateEnum} from "../../../core/enum/claim-state.enum";

@Component({
  selector: 'app-claim-button',
  templateUrl: './claim-button.component.html',
  styleUrl: './claim-button.component.scss'
})
export class ClaimButtonComponent {
  @Input() public status: ClaimStateEnum = ClaimStateEnum.DISABLED;
  @Input() public challengeId = 0;

  @Output() claim: EventEmitter<number> = new EventEmitter<number>();

  public claimStatusEnum = ClaimStateEnum;
  public isDisabled = false;

  constructor(
  ) {}

  public onClaim(): void {
    this.isDisabled = true;
    this.claim.emit(this.challengeId);
  }
}
