import {Component, ElementRef, Inject, Input} from '@angular/core';
import {ImageInterface} from "../../../core/models/models";
import {MemberService} from "../../../core/services/member.service";
import {NgModalsService} from "../../../modules/angular-material/services/ng-modals.service";

export enum AdultFilterMode{
    DEFAULT= 'DEFAULT',
    SMALL= 'SMALL',
    NONE= 'NONE',
}

@Component({
  selector: 'app-ng-adult-filter',
  templateUrl: './ng-adult-filter.component.html',
  styleUrl: './ng-adult-filter.component.scss'
})

export class NgAdultFilterComponent{
  @Input() image!:ImageInterface;
  @Input() mode:AdultFilterMode = AdultFilterMode.DEFAULT;

  AdultFilterMode = AdultFilterMode;

  constructor(
    private memberService:MemberService,
    private ngModalsService:NgModalsService,
    @Inject("Modals") private Modals: any,
    private host: ElementRef<HTMLElement>,
  ) {}

  show(){
    this.image.adult = false;
    // this.host.nativeElement.remove();
    this.host;
  }
  async disableAdult() {
    // not logged in
    if (!this.memberService.isLoggedIn()) {
        this.Modals.open('login');
        return;
    }
    const message = `Are you sure you want to disable "Adult Filter"`;
    if(await this.ngModalsService.confirm({message})) {
      // save settings
      const res = await this.memberService.updateMemberSettings({adult: true});
      if (!res.success) {
          return;
      }
      // update local settings
      this.memberService.getCurrentMember().adult = true;

      // remove all filters
      // const elements = document.querySelectorAll('app-ng-adult-filter, app-ng-adult-filter-component');
      // elements.forEach(e => {
      //     e.remove();
      // });
    }
  }
}
