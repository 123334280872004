import {Component} from '@angular/core';
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {RouterService} from "../../core/services/router.service";

@Component({
  selector: 'app-footer-wrapper',
  templateUrl: './footer-wrapper.component.html',
  styleUrl: './footer-wrapper.component.scss'
})
export class FooterWrapperComponent {
  public displayFooter = false;
  constructor(private routerService: RouterService) {
    this.routerService.routerEvents.pipe(
      takeUntilDestroyed()
    ).subscribe((event: {displayFooter: boolean}) => {
      this.displayFooter = event.displayFooter;
    });
  }
}
