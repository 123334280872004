const template = require(`html-loader!./teamBadge.html`).default;

class teamBadgeCtrl {
  constructor(
    $rootScope,
    stateService
  ) {
    'ngInject';

    this.url;

    this.$rootScope = $rootScope;
    this.stateService = stateService;
  }

  goToTeamInfoPage(){
    if(!this.teamId){
      return;
    }
    this.stateService.goToState(`teams/info/${this.teamId}`);
  }
}

teamBadgeCtrl.$inject = [
  '$rootScope',
  'stateService'
];

export default {
  template: template,
  controller: teamBadgeCtrl,
  bindings: {
    url: '<',
    teamId: '<',
    leagueInternalName: '<',
    lazy:'@',
    watch:'@'
  }
};
