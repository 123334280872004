function like(
  $rootScope,
  PhotosManager,
  Modals,
  MemberService,
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      like: '=', // object with data
      update: '&' // update callback
    },
    link: (scope, element) => {
      const member = MemberService.getCurrentMember();
      element.bind('click', event => {
        event.preventDefault();

        if (!member.logged_in) {
          Modals.open('login', null, {signUp: true});
          return false;
        }

        let reqData = {
          token: scope.like.token,
          image_id: scope.like.id,
        };

        if (!reqData.image_id && !reqData.token) {
          console.log('like.js:31', 'Data not valid!');
          return false;
        }

        let resultPromise = scope.like.liked
          ? PhotosManager.unlike(reqData)
          : PhotosManager.like(reqData);

        //Update parent scope
        if (scope.update) {
          scope.like.liked = !scope.like.liked;
          scope.update({image: scope.like});
        } else {
          element.toggleClass('liked');
        }

        //Clear cache
        localStorage.clear();

        resultPromise.then(res => {
          if (!res.success) {
            if (res.error_code === 1000) {
              Modals.open('login', event, {signUp: true});
            }
          }
        });

        return false;
      });
    }
  };
}

like.$inject = [
  '$rootScope',
  'PhotosManager',
  'Modals',
  'MemberService',
];

export default like;
