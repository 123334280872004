import {Component, Inject, Input, OnChanges} from '@angular/core';
import {ImageInterface} from "../../../core/models/models";
import memberEnum from "../../../gsApp/app/api/enums/member.enum";

@Component({
  selector: 'app-ng-photo-swipe-link',
  templateUrl: './ng-photo-swipe-link.component.html',
  styleUrl: './ng-photo-swipe-link.component.scss'
})
export class NgPhotoSwipeLinkComponent  implements OnChanges {
  @Input() images: ImageInterface[] = [];
  index: number = 0

  constructor(
    @Inject("photoSwipe") private photoSwipeService: any,
  ) {
  }

  open(event:MouseEvent){
    event.preventDefault();
    if (!this.images.length) {
      return false;
    }
    // open image
    this.photoSwipeService.open(this.images, this.index);
    return false;
  }

  ngOnChanges(): void {
    this.images.forEach((image, i) => {
      if (image.member && [memberEnum.GROUP.DEACTIVATE_BY_MEMBER, memberEnum.GROUP.DEACTIVATE_BY_ADMIN, memberEnum.GROUP.DELETED_BY_MEMBER, memberEnum.GROUP.DELETED_BY_ADMIN]
        // @ts-ignore
        .includes(image.member?.member_group_id)) {
        this.images.splice(i, 1);
        if (this.index > i) this.index--;
      }
    });
  }
}
