import {MediatorMessageKey, MediatorService} from "../../../../../core/services/mediator.service";

const template = require(`html-loader!./miniGame.html`).default;

class miniGameCtrl {
  constructor(
    $mdDialog,
    Modals,
    MediatorService
  ) {
    "ngInject";
    this.$mdDialog = $mdDialog;
    this.Modals = Modals;
    this.mediatorService = MediatorService;
  }

  $onDestroy() {

  }

  $onInit() {
    this.mediatorService.on(MediatorMessageKey.CLOSE_MINI_GAME).subscribe(() => {
      this.close();
    });
  }

  close() {
    this.$mdDialog.hide();
  }
}
miniGameCtrl.$inject = [
  '$mdDialog',
  'Modals',
  'MediatorService'
];
export default {
  template: template,
  controller: miniGameCtrl,
  bindings: {
    data: '=data'
  }
};
