import { Component } from '@angular/core';

@Component({
  selector: 'app-discover-search',
  templateUrl: './discover-search.component.html',
  styleUrl: './discover-search.component.scss'
})
export class DiscoverSearchComponent {

}
