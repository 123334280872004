import { Component } from '@angular/core';

@Component({
  selector: 'app-teams-create-wrapper',
  templateUrl: './teams-create-wrapper.component.html',
  styleUrl: './teams-create-wrapper.component.scss'
})
export class TeamsCreateWrapperComponent {

}
