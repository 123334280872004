import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-spotlight-edit-wrapper',
  templateUrl: './admin-content-manager-spotlight-edit-wrapper.component.html',
  styleUrl: './admin-content-manager-spotlight-edit-wrapper.component.scss'
})
export class AdminContentManagerSpotlightEditWrapperComponent {

}
