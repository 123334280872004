import {Component, Input} from "@angular/core";
import {GsLouderType} from "./gs-louder-type.enum";

@Component({
  selector: 'app-gs-loader',
  templateUrl: './gs-loader.html',
  styleUrls: ['./gs-loader.scss']
})

export class GsLoader {
  @Input() type: GsLouderType = GsLouderType.ROUND;

  readonly GsLouderType = GsLouderType;
  constructor() {

  }
}

