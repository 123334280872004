const template = require(`html-loader!./stars.html`).default;

class starsCtrl {
  constructor(
    $scope,
    $rootScope
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
  }
}

starsCtrl.$inject = [
  '$scope',
  '$rootScope'
];

export default {
  template: template,
  controller: starsCtrl,
  bindings: {
    total: '@',
    selectedd: '@',
  }
};
