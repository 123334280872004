const template = require(`html-loader!./photoShareChat.html`).default;

class photoShareChatCtrl {

  constructor($mdDialog, $rootScope, chatService) {
    'ngInject';

    this.messageSent = false;
    this.textMessage = '';

    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
    this.chatService = chatService;
  }

  $onInit() {
  }

  close() {
    this.$mdDialog.hide();
  }

  share(){
    this.chatService.sendMessage({
      textMessage:this.textMessage,
      selectedPhoto:this.data.image
    });
    this.messageSent = true;
  }
}
photoShareChatCtrl.$inject = [
  '$mdDialog', '$rootScope', 'chatService'
];
export default {
  template: template,
  controller: photoShareChatCtrl,
  bindings: {
    data: '=data',
  },
};
