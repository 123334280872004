import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-challenges-editor-wrapper',
  templateUrl: './challenges-editor-wrapper.component.html',
  styleUrl: './challenges-editor-wrapper.component.scss'
})
export class ChallengesEditorWrapperComponent {
  cUrl:string = '';
  dup:boolean = false;
  assignMode:string = '';
  suggestedImageId:string = '';
  constructor(
    private route: ActivatedRoute
  ) {
    // this.route.paramMap.pipe(
    //   takeUntilDestroyed()
    // ).subscribe(async (params: any) => {
    //   this.cUrl = params.cUrl;
    //   this.dup = params.dup;
    //   this.assignMode = params.assignMode;
    //   this.suggestedImageId = params.suggestedImageId;
    // });

    this.route.paramMap
      .pipe(takeUntilDestroyed())
      .subscribe((params: any) => {
        this.cUrl = params.get('cUrl'); // Route param
      });

    this.route.queryParamMap
      .pipe(takeUntilDestroyed())
      .subscribe((queryParams: any) => {
        this.assignMode = queryParams.get('assignMode'); // Query param
        this.dup = queryParams.get('dup');               // Query param
        this.suggestedImageId = queryParams.get('suggestedImageId'); // Query param
      });
  }
}
