import {Inject, Injectable} from '@angular/core';
// @ts-ignore
import mixpanelEventsEnum from "../../../gsApp/app/services/mixpanel/mixpanel.enum";
import {BankrollItem} from "../../bankroll/bankroll.service";
import {IStoreItem} from "./store.service.types";

export enum StoreEventOrigin {
  STORE= 'store',
  OOO_POPUP= 'ooo_popup',
}
export interface IStorePackageSelectEventData {
  storeItem:IStoreItem
  current_balance:number
  origin:StoreEventOrigin
}
export interface IStoreCompletePurchaseModalEventData {
  storeItem:IStoreItem
  current_balance:number
  origin:StoreEventOrigin
  ui_action:StoreCompletePurchaseUiAction,
}
export enum StoreCompletePurchaseUiAction {
  PLAY= 'play',
  BACK_TO_STORE= 'back_to_store',
  APPEAR= 'appear',
}
@Injectable()
export class StoreEventsService {

  constructor(
    @Inject('mixpanelService')  private mixpanelService: any,
  ) { }

  storeTabsEvent(ui_action:string): void {
    this.sendStoreClientEvent({
      ui_name: mixpanelEventsEnum.UI_NAME.STORE_TABS,
      ui_action
    })
  }

  storeOpenFromBankrollEvent(bankrollItem:BankrollItem): void {
    this.sendStoreClientEvent({
      ui_name: mixpanelEventsEnum.UI_NAME.BANKROLL_TOP_NAV,
      ui_action : `${bankrollItem.type.toLowerCase()}_top_nav`,
      current_balance : bankrollItem.amount
    })
  }

  storeCompletePurchaseModalEvent(data:IStoreCompletePurchaseModalEventData): void {
    this.sendStoreClientEvent({
      ui_name: mixpanelEventsEnum.UI_NAME.CONFIRM_PURCHASE_POPUP,
      ui_action: data.ui_action,
      current_balance:data.current_balance,
      package_id: data.storeItem.id,
      amount_collected: data.storeItem.received[0].amount,
      origin:data.origin
    })
  }

  storePaymentFailedEvent(storeItem:IStoreItem, current_balance:number): void {
    this.sendStoreClientEvent({
      ui_name: mixpanelEventsEnum.UI_NAME.PAYMENT_FAILED,
      ui_action:mixpanelEventsEnum.UI_ACTION.CLOSE,
      current_balance:current_balance,
      package_id: storeItem.id,
    })
  }

  storePackageSelectEvent(data:IStorePackageSelectEventData): void {
    const received = data.storeItem.received[0];
    this.sendStoreClientEvent({
      ui_name: mixpanelEventsEnum.UI_NAME.PACKAGE,
      ui_action : mixpanelEventsEnum.UI_ACTION.PACKAGE_SELECT,
      current_balance:data.current_balance,
      package_type: received.type,
      package_id: data.storeItem.id,
      offer_coins: received.amount,
      price: data.storeItem.cost_amount,
      origin:data.origin
    })
  }

  sendStoreClientEvent(data:any): void {
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.STORE,
      data: data
    });
  }

  //region OutOfCoins
  sendOutOfCoinsAppearClientEvent(galleryId: string): void {
    this.sendOOCClientEvent({
      ui_action: mixpanelEventsEnum.UI_ACTION.APPEAR,
      gallery_id: galleryId
    });
  }

  sendOutOfCoinsExitClientEvent(ui_name:any): void {
    this.sendOOCClientEvent({
      ui_action: mixpanelEventsEnum.UI_ACTION.CLICKED,
      ui_name: ui_name
    });
  }

  sendOOCClientEvent(data:any): void {
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.OOC_POPUP,
      data: data
    });
  }
  //endregion

}
