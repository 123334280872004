const template = require(`html-loader!./dmcaPage.html`).default;

class dmcaPageComponent {

  constructor(
    $scope,
    $window,
    tagEventService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.$scope = $scope;
    this.$window = $window;
    this.tagEventService = tagEventService;
    this.NgMetaDataService = NgMetaDataService;
  }

  $onInit() {
    this.NgMetaDataService.setTitle('DMCA');
    this.$window.scrollTo(0, 0);
    this.tagEventService.sendPageViewEvent({title: 'DMCA page'});
  }
}
dmcaPageComponent.$inject = [
  '$scope',
  '$window',
  'tagEventService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: dmcaPageComponent
};
