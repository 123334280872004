import { Component } from '@angular/core';

@Component({
  selector: 'app-discover-wrapper',
  templateUrl: './discover-wrapper.component.html',
  styleUrl: './discover-wrapper.component.scss'
})
export class DiscoverWrapperComponent {

}
