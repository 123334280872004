const template = require(`html-loader!./adminDailyRewardsMissions.html`).default;

class adminDailyRewardsMissionsCtrl {

  constructor(RestManager, $scope, RouterService) {
    'ngInject';
    this.items = [];

    this.RestManager = RestManager;
    this.$scope = $scope;
    this.RouterService = RouterService;
  }

  $onInit() {
    this.init();
  }

  /** @return void **/
  async init() {
    let res = await this.RestManager.getDailyMissionsDefinitions();
    if (!res.success) return;
    this.items = res.missions;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  editMission(mission) {
    this.RouterService.r.navigate(['admin/contentManager/exhibitions/virtualEdit'], {state: {mission}});
  }
}
adminDailyRewardsMissionsCtrl.$inject = [
  'RestManager', '$scope', 'RouterService'
];
export default {
  template: template,
  controller: adminDailyRewardsMissionsCtrl,
  bindings: {},
};
