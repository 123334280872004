const template = require(`html-loader!./reportSimilar.html`).default;

class reportSimilarCtrl {
  /**
   * @param $mdDialog
   * @param $element
   * @param {ChallengesManager} ChallengesManager
   * @param $mdToast
   * */

  constructor($mdDialog, $element, ChallengesManager, $mdToast) {
    "ngInject";

    this.$mdDialog = $mdDialog;
    this.$element = $element;
    this.ChallengesManager = ChallengesManager;
    this.$mdToast = $mdToast;

    this.busy = true;
    this.selected = [];
    this.items = [];

    this.start = 0;
    this.limit = 50;
  }

  $onInit() {
    this.ChallengesManager.getMemberEntries({member_id:this.data.member_id, token:this.data.token, c_id:this.data.c_id}).then((res) => {

      if(!res.success) {
        this.close();
      }

      this.items = res.items;

      this.busy = false;
    });
  }

  select(image) {

    if (image.selected) {
      this.selected.splice(this.selected.indexOf(image), 1);
    }
    else {
      this.selected.push(image);
    }

    image.selected = !image.selected;
  }

  unSelect(image) {
    if (this.items[this.items.indexOf(image)]) {
      this.items[this.items.indexOf(image)].selected = false;
    }
    else {
      image.selected = false;
    }

    this.selected.splice(this.selected.indexOf(image), 1);
  }

  report(event, similar) {

    let image_ids = [];
    let value;

    if(similar) {
      //Selected not enough
      if(this.selected.length < 2) {
        this.$mdToast.show(
          this.$mdToast.simple()
            .parent(this.$element)
            .textContent('You need to select minimum 2 images!')
            .position('bottom right')
            .hideDelay(3000)
        );
        return true;
      }
      //Get selected ids
      this.selected.map(image => {
        image_ids.push(image.id);
      });
      value = 1;
    }
    else {
      //Get all ids
      this.items.map(image => {
        image_ids.push(image.id);
      });
      value = -1;
    }

    this.close();

    //Report similar
    this.ChallengesManager.reportSimilar(this.data.c_id, image_ids, value);
  }

  close() {
    this.$mdDialog.hide();
  }
}
reportSimilarCtrl.$inject = [
  '$mdDialog', '$element', 'ChallengesManager', '$mdToast'
];
export default {
  template: template,
  controller: reportSimilarCtrl,
  bindings: {
    data: '='
  }
};
