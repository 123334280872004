import challengesPage from './challenges/challenges.page';
import challengePage from './challenge/challenge.page';
import profilePage from './profile/profile.page';
import achievementsPage from './achievements/achievements.page';
import articlesPage from './articles/articles.page';
import gsAdminPage from './gsAdmin/gsAdmin.page';
import profileSettings from './profileSettings/profileSettings.page';
import exhibitions from './exhibitions/exhibitions.module';
import homePage from './homePage/homePage.module';
import discover from './discover/discover.module';
import activity from './activity/activity.module';
import photo from './photo/photo.module';
import about from './about/about.module';
import contact from './contact/contact.module';
import terms from './terms/terms.module';
import dmca from './dmca/dmca.module';
import privacy from './privacy/privacy.module';
import sponsors from './sponsors/sponsors.module';
import vote from './vote/vote.module';
import mobileMenu from './mobileMenu/mobileMenu.module';
import mobileMembersSearch from './mobileMembersSearch/mobileMembersSearch.module';
import mobileNotifications from './mobileNotifications/mobileNotifications.module';
import landingPages from './landingPages/landingPages.module';
import deactivated from './deactivated/deactivated.module';
import exhibitionWidget from './exhibitionWidget/exhibitionWidget.module';
import error from './error/error.module';
import partners from './partners/partners.module';
import boarding from './boarding/boarding.module';
import dl from './dl/dl.module';
import moderation from './moderation/moderation.page';
import teamsPage from './teams/teams.page';
import virtualExhibitions from './virtualExhibitions/virtualExhibitions.page';
import leaderboard from './leaderboard/leaderboard.module';
import aiModule from "./ai/ai.module";

let modulePages = angular.module('gsApp.pages', [
  homePage,
  photo,
  challengesPage,
  challengePage,
  achievementsPage,
  articlesPage,
  gsAdminPage,
  profileSettings,
  exhibitions,
  discover,
  activity,
  about,
  contact,
  terms,
  dmca,
  privacy,
  sponsors,
  vote,
  mobileMenu,
  mobileMembersSearch,
  mobileNotifications,
  landingPages,
  deactivated,
  exhibitionWidget,
  error,
  partners,
  boarding,
  dl,
  moderation,
  teamsPage,
  virtualExhibitions,
  leaderboard,
  // !!! Profile page always latest
  aiModule,
  profilePage,
]);

export default modulePages.name;
