import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {ResourceType} from "../../../core/models/enums";

@Component({
  selector: 'app-resource-indicator',
  templateUrl: './resource-indicator.component.html',
  styleUrl: './resource-indicator.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush

})
export class ResourceIndicatorComponent {
  @Input({ required: true }) resource!:ResourceType;
  @Input({ required: true }) amount!:number;
  @Input() styleClass: 'regular' | 'big' = 'regular';
}
