// import halloweenAnim from "../../../../assets/animations/halloween_1.json";
import {getDate} from "../helpers/date.helper";

const eventsList = [
  // {
  //   day: 31,
  //   month: 10,
  //   daysBefore: 7,
  //   animationJSON: halloweenAnim,
  //   isIncludeEventDay: true,
  //   querySelector: ".gs-header__event",
  //   name: "halloween"
  // },
  // {
  //   day: 2,
  //   month: 1,
  //   daysBefore: 13,
  //   imagePath: '/assets/images/components/gs-header/christmas-logo.png',
  //   isIncludeEventDay: true,
  //   querySelector: ".gs-header__event",
  //   name: "christmas"
  // },
];


class eventsHeaderService {

  constructor(animationService) {
    'ngInject';
    this.currentEvent = null;

    this.animationService = animationService;

    this.setEvent();
  }

  /**
   * From today calculate the range of days before when logo will be replaced to event logo
   * @param {number} daysBeforeParam - amount of days before event
   * @param {boolean} isIncludeEventDay - include or exclude the event day
   * @param {number} eventDay - the number of the event date day
   * @param {number} eventMonth - the number of the event month
   * @returns {boolean} - change logo or not.
   */


  setEvent(){
    for ( const event of eventsList){
      const now = new Date();
      const year = event.month === 1 ? (now.getFullYear() + 1) : now.getFullYear(); //TODO need too find generic solution

      let startDate = getDate({
        initTime: new Date(year, event.month - 1, event.day),
        daysToAdd:-event.daysBefore
      });
      let endDate = getDate({
        initTime: new Date(year, event.month - 1, (event.day + Number(event.isIncludeEventDay)))
      });
      if(now >= startDate && now < endDate){
        this.currentEvent = event;
        break;
      }
    }
  }

  getEvent(){
    return this.currentEvent;
  }

  async startAction(event){
    if(event.animationJSON && !this.lock) {
      this.lock = true;
      await this.animationService.startAnim({
        animData: event.animationJSON,
        elementName: event.querySelector,
        destroy: false
      });
      this.lock = false;
    }
  }
}
eventsHeaderService.$inject = [
  'animationService'
];
export default eventsHeaderService;
