export const simpleMessageConf = {
  controller: () => {},
  controllerAs: 'toast',
  template: '<simple-message data="toast.data"></simple-message>',
  autoWrap: false,
  hideDelay: 4000,
  position: 'top right',
  parent: '',
  bindToController: true
};
