import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {ChallengesSortOrder, ISortChange} from "../../../../shared/components/sort-order/sort-order.component";
import {ChallengeFastFilterType, ChallengeFastFilterUrlParamType, ChallengeGroup} from "../../enums/challenges.enum";
import {
  IFastFilter,
  JoinedChallengesPageService,
  SelectedFastFilters
} from "../../services/joined-challenges-page.service";
import {combineLatest, filter} from "rxjs";
import {RouterService} from "../../../../core/services/router.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-challenges-header',
  templateUrl: './challenges-header.component.html',
  styleUrl: './challenges-header.component.scss'
})
export class ChallengesHeaderComponent  implements OnInit, OnDestroy{
  @Input() challengeGroup!:ChallengeGroup;
  @Output() onFastFilterChange = new EventEmitter<SelectedFastFilters>

  sortOrderConfig?:ChallengesSortOrder;
  fastFilters?:IFastFilter[];
  isFilterEmpty = false;

  constructor(
    private JoinedChallengesPageService:JoinedChallengesPageService,
    private routerService:RouterService
  ) {
    combineLatest([
      this.JoinedChallengesPageService.$fastFilters.pipe(
        filter(res => !!res),
        takeUntilDestroyed()), // Observing $fastFilters
      this.routerService.activatedRoute.queryParams.pipe(
        takeUntilDestroyed())
    ]).subscribe(async ([fastFilters, params]) => {
      if(fastFilters){
        if(params && Object.keys(params).length){
          Object.keys(ChallengeFastFilterUrlParamType).forEach((ParamKey)=>{
            const key = ParamKey as keyof typeof ChallengeFastFilterUrlParamType;
            if(params[ChallengeFastFilterUrlParamType[key]]){
              let filter = fastFilters.find(filter => filter.type === ChallengeFastFilterType[key])
              filter!.selected = params[ChallengeFastFilterUrlParamType[key]] === 'true';
            }
          });
          await this.routerService.removeQueryParams();
          JoinedChallengesPageService.fastFilters = fastFilters;
        }

        this.fastFilters = fastFilters;
      }
    });
  }

  ngOnInit(): void {
    this.sortOrderConfig = this.JoinedChallengesPageService.getSortOrderConfByGroup(this.challengeGroup);
    // this.setIsFilterEmpty();
  }

  onActiveSortOrderChange(config: ISortChange){
    if(config.isUserSelected){
      this.JoinedChallengesPageService.saveChallengesSortOrderFilterDataToLS();
    }
    this.JoinedChallengesPageService.sortChallengesByGroup(this.challengeGroup);
  }

  onFilterClick(filter:IFastFilter){
    filter.selected = !filter.selected;
    // this.setIsFilterEmpty();
    this.JoinedChallengesPageService.fastFilters = this.fastFilters;
    this.onFastFilterChange.emit(this.JoinedChallengesPageService.selectedFastFilters);
    this.JoinedChallengesPageService.saveChallengesSortOrderFilterDataToLS();
  }

  // setIsFilterEmpty(){
  //   this.isFilterEmpty = !!this.fastFilters && this.fastFilters.every(val => !val.selected);
  // }

  ngOnDestroy(): void {
  }
}
