const template = require(`html-loader!./likeBtn.html`).default;

class likeBtnCtrl {
  /**
   * @param {PhotosManager} PhotosManager
   * */
  constructor(
    $element,
    $rootScope,
    Modals,
    PhotosManager,
    ResponseService,
    MemberService
  ) {
    'ngInject';

    this.$element = $element;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.PhotosManager = PhotosManager;
    this.ResponseService = ResponseService;
    this.MemberService = MemberService;

    this.submit = _.throttle(this.submit.bind(this));
    this.member = this.MemberService.getCurrentMember();
  }

  like(event) {
    if (!this.member.logged_in) {
      this.Modals.open('login', null, {signUp: true});
      return;
    }
    this.image.liked = !this.image.liked;
    this.submit();
  }

  /** @return void **/
  async submit(event) {
    let reqData = {
      token: this.image.token,
      image_id: this.image.id,
    };

    let manager = this.image.liked
      ? this.PhotosManager.like(reqData)
      : this.PhotosManager.unlike(reqData);

    const res = await manager;
    if (res.error_code === 1000) {
      this.Modals.open('login', event, {signUp: true});
    } else if (this.ResponseService.isResponseError(res)) {
      this.ResponseService.responseHandler(res);

      return false;
    }
  }
}

likeBtnCtrl.$inject = [
  '$element',
  '$rootScope',
  'Modals',
  'PhotosManager',
  'ResponseService',
  'MemberService',
];

export default {
  template: template,
  controller: likeBtnCtrl,
  bindings: {
    image: '<'
  }
};
