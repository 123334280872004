<div class="mini-game-votes-meter-wrapper">
  <div class="mini-game-votes-meter-indication">
    <div
      *ngFor="let item of game.images"
      class="mini-game-votes-meter-item"
      [ngClass]="{
        'success-voted': item.is_success,
        'un_success-voted': item.is_success === false
      }"
    >
    </div>

    <div class="mini-game-votes-meter-bg">
      <div class="mini-game-votes-meter-count">
        {{successVotes}}/{{game.required_selections}}
      </div>
    </div>
  </div>
  <div class="mini-game-votes-meter-subtitle">
    which photo is more popular?
  </div>
</div>
