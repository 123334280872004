import ImageModel from '../models/image/image.model';
import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";

class ChallengesManager {
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  // get

  getData({id, url, fields = [], include = [], raw = false}) {
    return this.api['rest']['getChallengeData']({id, url, fields, include}).then(res => {
      if (!res.success || raw) {
        return res;
      }
      res.challenge = new NgChallengeModel(res.challenge);
      return res;
    });
  }

  getMemberChallengeResult(challenge_id) {
    return this.api['challenges']['getMemberChallengeResult']({
      c_id: challenge_id,
    });
  }

  getMemberChallengeResultNew(challenge_id) {
    return this.api['challengesNew']['getMemberChallengeResultNew']({
      c_id: challenge_id,
    });
  }

  getChallengesList() {
    return this.api['challengesList']['getChallengesList']();
  }

  getChallengePhotos(c_id, limit) {
    return this.api['rest']['getChallengePhotos']({c_id, limit}).then(res => {
      res.images = res.images ? res.images.map(image => new ImageModel(image)) : [];
      return res;
    });
  }

  getSuccessfulSwap({new_image_id, old_image_id, c_id}) {
    return this.api['rest']['getSuccessfulSwap']({new_image_id, old_image_id, c_id}).then(res => {
      let response = {};
      response.success = res.success;
      if (res.success) {
        response.images = res.items.images.map(image => new ImageModel(image));
        response.entries = res.items.entries.map(image => new ImageModel(image));
        response.challenge = res.items.challenge;
        response.permissions = res.items.permissions;
      }
      return response;
    });
  }

  getHostedChallenges(host) {
    return this.api['rest']['getHostedChallenges']({host}).then(res => {
      if (res.items) {
        res.items = res.items.map(challenge => new NgChallengeModel(challenge));
      }
      return res;
    });
  }

  selectForExpo(c_id, image_id, member_id, selected_expo) {
    return this.api['rest']['selectForExpo']({c_id, image_id, member_id, selected_expo});
  }

  getMemberEntries({member_id, c_id, token}) {
    return this.api['rest']['getMemberEntries']({member_id, c_id, token}).then(res => {
      if (!res.success) {
        return res;
      }
      res.items = res.items.map(image => new ImageModel(image));
      return res;
    });
  }

  getChallengeDraft() {
    return this.api['rest']['getChallengeDraft']().then(res => {
      if (res.success) {
        res.challenge = new NgChallengeModel(res.challenge);
      }
      return res;
    });
  }

  getGuruChallenges() {
    return this.api['rest']['getGuruChallenges']().then(res => {
      // challenges model
      for (let status in res.challenges) {
        res.challenges[status] = res.challenges[status]
          ? res.challenges[status].map(challenge => new NgChallengeModel(challenge))
          : [];
      }
      return res;
    });
  }

  listChallenges({status, start, limit, sort_field, order}) {
    return this.api['rest']['listChallenges']({status, start, limit, sort_field, order}).then(res => {
      if (res.success) {
        res.challenges = res.challenges.map(challenge => new NgChallengeModel(challenge));
      }
      return res;
    });
  }

  // set

  submitToChallenge(image_ids, el, el_id, c_id) {
    return this.api['events']['submitToChallenge']({image_ids, el, el_id, c_id});
  }

  swap(el, el_id, c_id, img_id, new_img_id) {
    return this.api['events']['swap']({el, el_id, c_id, img_id, new_img_id});
  }

  setChallenge(data) {
    return this.api['rest']['setChallenge'](data);
  }

  reportImage({reason_id, id, c_id, value, token}) {
    return this.api['rest']['reportImage']({reason_id, id, c_id, value, token});
  }

  reportSimilar(c_id, image_ids, value) {
    return this.api['rest']['reportSimilar']({c_id, image_ids, value});
  }

  submitVotes(c_id, image_ids) {
    return this.api['rest']['submitVotes']({c_id, image_ids});
  }

  activateChallenge(challenge_id) {
    return this.api['rest']['activateChallenge']({challenge_id});
  }

  // delete

  removeFromChallenge(c_id, img_id, reason_id) {
    return this.api['event']['removeFromChallenge']({c_id, img_id, reason_id});
  }

  deleteSuspendedImages(challenge_id) {
    return this.api['rest']['deleteSuspendedImages']({
      c_id: challenge_id,
    });
  }

  getPrizesAttributes() {
    return this.api['rest']['getPrizesAttributes']();
  }

  setChallengePrizes(data) {
    return this.api['rest']['setChallengePrizes'](data);
  }

  getSponsorsAttributes() {
    return this.api['rest']['getSponsorsAttributes']();
  }

  setChallengeSponsors(c_id, ids = []) {
    return this.api['rest']['setChallengeSponsors']({c_id, ids});
  }

  //region Ranking levels
  /* Naming from server side */
  getRankingLevels() {
    return this.api['rest']['getRankingLevels']();
  }

  setChallengeRankingLevels(c_id, id) {
    return this.api['rest']['setChallengeRankingLevels']({c_id, id});
  }
  //endregion

  getWinners(c_id) {
    return this.api['rest']['getWinners']({c_id});
  }

  exposureAutofill({el, el_id, challenge_ids = []}) {
    return this.api['rest']['exposureAutofill']({el, el_id, challenge_ids});
  }

  replaceTopPhotographer(c_id, member_id) {
    return this.api['rest']['replaceTopPhotographer']({c_id, member_id});
  }

  replaceTopPhoto(c_id, image_id) {
    return this.api['rest']['replaceTopPhoto']({c_id, image_id});
  }

  replaceGuruTopPick(c_id, image_id) {
    return this.api['rest']['replaceGuruTopPick']({c_id, image_id});
  }

  setPopupMessage(params) {
    return this.api['rest']['setPopupMessage'](params);
  }

  autoSubmitOnGoing({c_id, last_joined_date, limit_members, max_active_challenges, max_joined_challenges}) {
    return this.api['rest']['autoSubmitOnGoing']({
      c_id,
      last_joined_date,
      limit_members,
      max_active_challenges,
      max_joined_challenges,
    });
  }

  getWinnersFriends(c_id, user_name) {
    return this.api['rest']['getWinnersFriends']({c_id, user_name});
  }

  getPermissionGroups() {
    return this.api['rest']['getPermissionGroups']();
  }

  setCustomChallengeMessage({join_days, c_id, title, body, media_id, media_type, action_url, action_text}) {
    return this.api['rest']['setCustomChallengeMessage']({
      join_days,
      c_id,
      title,
      body,
      media_id,
      media_type,
      action_url,
      action_text,
    });
  }

  filterSuggestedChallenge(c_id) {
    return this.api['rest']['filterSuggestedChallenge']({c_id});
  }

  autoSelectDigitalExhibition(c_id) {
    return this.api['rest']['autoSelectDigitalExhibition']({c_id});
  }

  getCountriesNotificationTimes() {
    return this.api['rest']['getCountriesNotificationTimes']();
  }

  setCountriesNotificationTimes(data) {
    return this.api['rest']['setCountriesNotificationTimes'](data);
  }

  //new exhibition api

  resetAutoSelectExhibition(challengeId) {
    return this.api['rest']['resetAutoSelectExhibition']({challenge_id: challengeId});
  }

  newAutoSelectDigitalExhibition(challengeId) {
    return this.api['rest']['newAutoSelectDigitalExhibition']({c_id: challengeId});
  }

  setChallengeAutoSelectAttributes(data) {
    return this.api['rest']['setChallengeAutoSelectAttributes'](data);
  }

  getChallengeAutoSelectAttributes(challengeId) {
    return this.api['rest']['getChallengeAutoSelectAttributes']({'challenge_id': challengeId});
  }

  sendSelectionNotifications(challengeId) {
    return this.api['rest']['sendSelectionNotifications']({'challenge_id': challengeId});
  }

  lockExhibitionChallenge(challengeId) {
    return this.api['rest']['lockExhibitionChallenge']({'challenge_id': challengeId});
  }
}

ChallengesManager.$inject = [
  'api'
];

export default ChallengesManager;
