const template = require(`html-loader!./vimeo.html`).default;

class vimeoController {
  constructor(

  ) {
    'ngInject';
  }

  $onInit() {
    this.src = `https://player.vimeo.com/video/${this.iframeSrc}?autoplay=1&title=0&byline=0&portrait=0&controls=0`;
  }
}

vimeoController.$inject = [

];

export default {
  template: template,
  controller: vimeoController,
  bindings: {
    iframeSrc: '<'
  }
};
