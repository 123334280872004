const template = require(`html-loader!./achievements.html`).default;
import {topTabMenuData} from "../../../components/topTabsMenu/topTabMenu.data";
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";

class achievementsController {

  constructor(
    $scope,
    tagEventService,
    mixpanelService,
  ) {
    "ngInject";
    this.busy = true;
    this.itemsLimit = 6;
    this.achievements = [];
    this.challenges = [];

    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.topTabMenuData = topTabMenuData;
    this.mixpanelService = mixpanelService;
  }

  $onInit(){
    this.tagEventService.sendPageViewEvent({
      sendToMixPanel:false,
      title: 'Profile page achievements'
    });
    this.eventMixPanelAction();
  }

  eventMixPanelAction() {
    this.mixpanelService.trackProfilePageImpression({
      ui_name: mixpanelEventsEnum.UI_NAME.ACHIEVEMENTS
    });
  }
}
achievementsController.$inject = [
  '$scope',
  'tagEventService',
  'mixpanelService',
];
export default {
  template: template,
  controller: achievementsController,
};
