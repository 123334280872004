/**
 * GetBrowserName
 * https://developer.mozilla.org/en-US/docs/Web/API/Window/navigator
 * */

class gsBrowserService {
  constructor() {
    "ngInject";
  }

  initBrowserData() {
    let sUsrAg = navigator.userAgent,
      type,
      version
    ;

    if (sUsrAg.indexOf("Firefox") > -1) {
      type = "Mozilla Firefox";
      version = this._getBrowserVersion(sUsrAg.indexOf("Firefox"));
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
      type = "Samsung Internet";
      version = this._getBrowserVersion(sUsrAg.indexOf("SamsungBrowser"));
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
      type = "Opera";
      version = this._getBrowserVersion(sUsrAg.indexOf("Opera") || sUsrAg.indexOf("OPR"));
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf("Trident") > -1) {
      type = "Microsoft Internet Explorer";
      version = this._getBrowserVersion(sUsrAg.indexOf("Trident"));
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf("Edge") > -1) {
      type = "Microsoft Edge (Legacy)";
      version = this._getBrowserVersion(sUsrAg.indexOf("Edge"));
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf("Edg") > -1) {
      type = "Microsoft Edge (Chromium)";
      version = this._getBrowserVersion(sUsrAg.indexOf("Edg"));
      // Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.124 Safari/537.36 Edg/91.0.864.64
    } else if (sUsrAg.indexOf("Chrome") > -1) {
      type = "Google Chrome or Chromium";
      version = this._getBrowserVersion(sUsrAg.indexOf("Chrome"));
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf("Safari") > -1) {
      type = "Apple Safari";
      version = this._getBrowserVersion(sUsrAg.indexOf("Safari"));
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      type = "unknown";
    }
    
    return {type, version};
  }

  _getBrowserVersion(index) {
    /**
     * Steps:
     * 1. navigator.userAgent
     * 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/93.0.4577.82 Safari/537.36'
     * 2. For example, we use Chrome browser and get index 87.
     * 3. Slice will cut a first part of string reminds
     * 'Chrome/93.0.4577.82 Safari/537.36'
     * 4. Now cut off redundant part ( Safari/537.36) by split(" ") and return first element
     * 'Chrome/93.0.4577.82'
     * 5. Again repeat split to get version as .split("/")[1]
     * '93.0.4577.82'
     * */
    return navigator.userAgent.slice(index).split(" ")[0].split("/")[1];
  }
}

export default gsBrowserService;
