const template = require(`html-loader!./badgeV2ExhibitionCompleted.html`).default;

class badgeV2ExhibitionPreviewController {
  constructor(

  ) {
    'ngInject';
  }
}

badgeV2ExhibitionPreviewController.$inject = [

];

export default {
  template: template,
  controller: badgeV2ExhibitionPreviewController,
  bindings: {
    name: '<'
  }
};
