const template = require(`html-loader!./captionedImage.html`).default;

class captionedImage {

  constructor(
    $mdDialog
  ) {
    'ngInject';

    this.$mdDialog = $mdDialog;
  }

  close() {
    this.$mdDialog.hide();
  }
}
captionedImage.$inject = [
  '$mdDialog'
];
export default {
  controller: captionedImage,
  template: template,
  bindings: {
    data: '<',
  }
};
