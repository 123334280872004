import teamEnum from '../../enums/team.enum';

class Ftue {
  constructor(props) {
    const states = Object.values(teamEnum.INTRO_STATES);
    for (let state of states) {
      if(props[state]) {
        this[state] = new FtueItem(props[state]);
      }
    }
  }
}

class FtueItem {
  constructor(props) {
    this.buttonText = props.button_text;
    this.imageUrl = props.image_url.png;
    this.text = props.text;
    this.title = props.title;
  }
}

class TeamsConfigModel {
  constructor({
    default_points_per_member,
    keys_to_create,
    minimum_number_of_members_in_challenge,
    teams_page_size,
    member_status_list,
    match,
    my_team_help_info,
    my_team,
    leaderboard,
    ftue,
    leagues_help_info,
    max_members_to_show_suggested,
    only_admin_can_invite
  }) {
    this.default_points_per_member = default_points_per_member;
    this.keys_to_create = keys_to_create;
    this.minimum_number_of_members_in_challenge = minimum_number_of_members_in_challenge;
    this.teams_page_size = teams_page_size;
    this.match = match;
    this.my_team_help_info = my_team_help_info;
    this.my_team = my_team;
    this.leaderboard = leaderboard;
    this.leagues_help_info = leagues_help_info;
    this.max_members_to_show_suggested = max_members_to_show_suggested;
    this.only_admin_can_invite = only_admin_can_invite;
    this.member_status_list = member_status_list || [];
    this.ftue = ftue ? new Ftue(ftue) : null;
  }
}

export default TeamsConfigModel;
