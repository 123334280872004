export const miniGameConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-mini-game data="modal.data"></modal-mini-game>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: false,
  fullscreen: true
};
