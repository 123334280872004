const template = require(`html-loader!./photoSwipe.html`).default;

import ImageModel from '../../../api/models/image/image.model';
import { shareList } from './photoSwipe.data';
import {teamConfirmTypes} from "../../../services/Modals/teamsConfirm/teamsConfirm.data";

class PhotoSwipeCtrl {
  /**
   * @param {photoSwipeService} photoSwipe
   * @param {PhotosManager} PhotosManager
   * */

  constructor(
    $element,
    photoSwipe,
    $scope,
    $timeout,
    PhotosManager,
    $location,
    $rootScope,
    tagEventService,
    Modals,
    teamsService,
    MemberService
  ) {
    'ngInject';
    this._dataClosed = window.screen.availWidth <= 961;
    this.closeEnabled = true;
    this.imageData = {};
    this.busy = {};
    this.events = [];
    this.slideShow = false;

    this.$element = $element;
    this.photoSwipe = photoSwipe;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.PhotosManager = PhotosManager;
    this.$location = $location;
    this.Modals = Modals;
    this.teamsService = teamsService;
    this.tagEventService = tagEventService;
    this.member = MemberService.getCurrentMember();

    this.shareList = shareList;

    this.onPhotoSwipeClose = this.onPhotoSwipeClose.bind(this);
    this.onPhotoSwipeOpen = this.onPhotoSwipeOpen.bind(this);
    this.onPhotoSwipeIndex = this.onPhotoSwipeIndex.bind(this);
    this.openPhotoSwipe = this.openPhotoSwipe.bind(this);
    this.close = this.close.bind(this);

    // close panel on mobile or open on desktop
    this.dataClosed = document.body.clientWidth <= 961;
  }

  $onInit() {
    // root events
    this.events['onPhotoSwipeOpen'] = this.$rootScope.$on('onPhotoSwipeOpen', this.onPhotoSwipeOpen);
    this.events['onPhotoSwipeClose'] = this.$rootScope.$on('onPhotoSwipeClose', this.onPhotoSwipeClose);
    this.events['onPhotoSwipeIndex'] = this.$rootScope.$on('onPhotoSwipeIndex', this.onPhotoSwipeIndex);
    // events
    $(document).on('closePhotoBox', this.close);
    $(document).on('openPhotoSwipe', this.openPhotoSwipe);
  }

  $onDestroy() {
    this.events['onPhotoSwipeOpen']();
    this.events['onPhotoSwipeClose']();
    this.events['onPhotoSwipeIndex']();
    $(document).off('closePhotoBox', this.close);
    $(document).off('openPhotoSwipe', this.openPhotoSwipe);
    console.log('destroy');
  }

  openPhotoSwipe(event, data) {
    this.photoSwipe.open({images:data.images, index:data.index});
  }

  onPhotoSwipeOpen(event, data) {
    this.image_id = data.imageId;
    this.currentImageAdultFlag = data.adult;
    console.log('onPhotoSwipeOpen', data, this.currentImageAdultFlag);
    this.slideShow = data.slideShow || false;

    // close panel on mobile or open on desktop
    this.dataClosed = document.body.clientWidth <= 961;

    // disable close buttons
    this.closeEnabled = this.photoSwipe.closeEnabled;

    this.open = true;
    this.$element.addClass('active');
    this.shareUrl = `${window.location.origin}/photo/${this.image_id}`;
    // Load data
    this.getPhotoData();
  }

  onPhotoSwipeClose() {
    this.open = false;
    this.$element.removeClass('active');
    this.imageData = {};
  }

  onPhotoSwipeIndex(event, data) {
    this.open = true;
    this.$element.addClass('active');

    this.image_id = data.image_id;
    this.currentImageAdultFlag = data.adult;
    this.shareUrl = undefined;
    this.getPhotoData();

    // Update image id
    this.$timeout(() => {
      this.image_id = data.image_id;
      // Share url
      this.shareUrl = `${window.location.origin}/photo/${this.image_id}`;
    });

    // Slide changed
    $(document).trigger('pb_change', data.image_id);
    this.tagEventService.sendPageViewEvent({title: 'Photo page'});
  }

  getPhotoData() {
    const image_id = this.image_id;
    if (this.imageData[image_id]) {
      this.$location.path(`/photo/${this.imageData[image_id].image_url}`).replace();
      return false;
    }
    if (!image_id || this.busy[image_id]) return false;
    this.busy[image_id] = true;

    this.PhotosManager.getImageData(image_id).then(res => {
      this.busy[image_id] = false;
      if (!res.success) {
        return false;
      }
      if(!this.open){
        console.log('not opened', res.data);
        return false;
      }
      this.imageData[image_id] = new ImageModel(res.data);
      if(this.currentImageAdultFlag === undefined){
        this.currentImageAdultFlag = this.imageData[image_id].adult;
      }
      // add alt attr
      $('.pswp__img').attr('alt', this.imageData[image_id].alt);
      // labels
      this.$location.path(`/photo/${this.imageData[image_id].image_url}`).replace();
    });
  }

  openPanel(event) {
    event.preventDefault();
    this.dataClosed = false;
    return false;
  }

  close() {
    this.open = false;
    this.$timeout(() => {
      this.photoSwipe.close();
    }, 200); // Delay to fix timeline
  }

  set dataClosed(value) {
    this._dataClosed = value;
    if (value) {
      this.$element.addClass('closed');
    } else {
      this.$element.removeClass('closed');
    }
    // Refresh
    this.$timeout(() => {
      if (this.photoSwipe.gallery) {
        this.photoSwipe.gallery.updateSize(true);
      }
    });
  }

  get dataClosed() {
    return this._dataClosed;
  }

  openPhotoShare(){
    this.Modals.open('photoShareChat', null, {image: this.imageData[this.image_id]});
  }
}
PhotoSwipeCtrl.$inject = [
  '$element',
  'photoSwipe',
  '$scope',
  '$timeout',
  'PhotosManager',
  '$location',
  '$rootScope',
  'tagEventService',
  'Modals',
  'teamsService',
  'MemberService',
];
export default {
  template: template,
  controller: PhotoSwipeCtrl,
  bindings: {},
};
