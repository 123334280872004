const template = require(`html-loader!./leaderBoard.html`).default;

import {autoUpdateConf, autoUpdateTypeEnum} from "../../services/autoUpdate.service.data";
import eventLeaderBoardEnum from "../../api/enums/eventLeaderBoard.enum";
import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";

class leaderBoardCtrl {
  /**
   * @param {ModalsService} Modals
   * @param {RestManager} RestManager
   * */
  constructor(
    Modals,
    RestManager,
    $rootScope,
    $scope,
    $cookies,
    $element,
    $timeout,
    animationService,
    notificationAlert,
    autoUpdateService,
    mixpanelService
  ) {
    'ngInject';
    this.busy = true;
    this.isOpen = false;
    this.showMe = true;
    this.meChanged = false;

    this.Modals = Modals;
    this.RestManager = RestManager;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$cookies = $cookies;
    this.$element = $element;
    this.$timeout = $timeout;
    this.animationService = animationService;
    this.notificationAlert = notificationAlert;
    this.autoUpdateService = autoUpdateService;
    this.mixpanelService = mixpanelService;
  }

  $onInit() {
    this.init();
  }

  async init(){
    await this.getData({formInterval:false});
    if(this.event){
      this.checkMeUpdate(this.event.lb.me);
    }
  }

  setInterval(){
    const delay = this.event && this.event.event && this.event.event.status === eventLeaderBoardEnum.STATUSES.ACTIVE.VALUE
      ? autoUpdateConf[autoUpdateTypeEnum.LB_EVENT].delayActive : autoUpdateConf[autoUpdateTypeEnum.LB_EVENT].delayInactive;
    let autoUpdateObject = {
      type: autoUpdateTypeEnum.LB_EVENT,
      callback: this.getData.bind(this),
      callbackParams: {formInterval: true},
      delay: delay
    };
    this.autoUpdateService.startAutoUpdateByType(autoUpdateObject);
  }

  $onDestroy() {
    $('.leader-board__modal').off('scroll');
    this.animationService.destroyAll();
    this.autoUpdateService.endAutoUpdateByType(autoUpdateTypeEnum.LB_EVENT);
  }

  $onChanges(changes) {
    if (changes.buttonPressed.currentValue) {
      this.openedFromEvent = true;
      this.open();
    }
  }

  async getData({formInterval}){
    if(formInterval && this.isOpen){
      return;
    }

    this.busy = true;

    const res = await this.RestManager.getLbEvent();

    if(!res.success ||
      !res.event ||
      !res.event.event ||
      !res.event.lb.items ||
      res.event.lb.items.length === 0 ||
      res.event.event.status === eventLeaderBoardEnum.STATUSES.UPCOMING.VALUE
    ) {
      this.setInterval();
      this.close();

      if (!this.$rootScope.$$phase) {
        this.$rootScope.$digest();
      }

      return false;
    }

    this.event = res.event;

    this.setInterval();
    if(formInterval) {
      this.didSetWatched = false;
      this.checkMeUpdate(this.event.lb.me);

      if (!this.$rootScope.$$phase) {
        this.$rootScope.$digest();
      }

      return;
    }

    //open dialog
    if((this.event.behavior.show_joined_dialog) && !this.openedFromEvent){
      this.Modals.open('lbEvent', null, {event:res.event});
    }
    this.openedFromEvent = false;
    //check if member is in to 3
    if(this.event.lb.items.length >=3) {
      for (let i = 0; i < 3; i++) {
        for (let member of this.event.lb.items[i].members) {
          if (member.member?.id === this.event.lb.me.member?.id) {
            console.log('me is in top 3 , number - ' + i);
            this.showMe = false;
          }
        }
      }
    }else{
      this.showMe = false;
    }
    this.busy = false;

    if (!this.$rootScope.$$phase) this.$rootScope.$digest();
    return true;
  }

  checkMeUpdate(me){
    if(!me){
      return;
    }
    const lbMe =this.$cookies.getObject('lbMe');
    console.log(lbMe);
    if (lbMe && lbMe.rank) {
      if(lbMe.rank !== me.rank || lbMe.score !== me.score){
        this.meChanged = true;
        console.log('meChanged');
      }
    }
    let expired = new Date();
    expired.setDate(expired.getDate() + 2);
    this.$cookies.putObject('lbMe', {rank: me.rank, score:me.score}, {expires: expired});
  }

  async open() {
    this.eventMixPanelAction();

    if(!this.isOpen ) {
      this.meChanged = false;
      this.isOpen = true;
      this.showMe = true;

      if(await this.getData({formInterval:false})) {
        if(!this.event.behavior.can_claim){
          this.setWatch();
        }
      }

      $('.leader-board__modal').on('scroll', this.onScroll.bind(this));
      this.meChanged = false;

      if (!this.$rootScope.$$phase) {
        this.$rootScope.$digest();
      }
    }
  }

  close(){
    $('.leader-board__modal').off('scroll');
    this.isOpen = false;
  }

  async claim(){
    if(!this.event.behavior.can_claim){return}
    $('.leader-board__modal').scrollTop(0);
    this.claimMode = true;
    const res = await this.RestManager.claimLbEvent();
    if(!res.success){
      this.close();
      if (!this.$scope.$$phase) {this.$scope.$digest()}
      return;
    }
    console.log(res);
    this.setWatch();
    await  this.animationService.startAnim({path:res.prizes.lottie.present_grow_open, elementName:'.leader-board__modal__claim__gift', destroy:false});
    for (let prize of res.prizes.prizes) {
      console.log('startAnim - ' + prize.type);
      this.rewardSkipMode = true;
      if(prize.amount) {
        this.rewardsText = prize.amount;
        this.rewardsAnimText = true;
        this.rewardsAnimTextPromise =  this.$timeout(() => {
          this.rewardsAnimText = false;
        }, 2000);

      }
      await this.animationService.startAnim({path:prize.lottie, elementName:'.leader-board__modal__claim__reward'});
    }
    this.rewardSkipMode = false;
    this.animationService.destroyAll();
    await this.animationService.startAnim({path:res.prizes.lottie.present_close, elementName:'.leader-board__modal__claim__gift'});
    this.animationService.destroyAll();
    this.claimMode = false;
    this.close();
    if (!this.$scope.$$phase) {this.$scope.$digest()}
  }

  giftClicked(){
    if(this.rewardSkipMode){
      this.rewardsAnimText = false;
      this.$timeout.cancel(this.rewardsAnimTextPromise);
      this.animationService.destroyCurrentAnimation();
    }
  }

  onScroll(){
    // let modal = $('.leader-board__modal');
    // console.log('modal.scrollTop()-', modal.scrollTop());
    // console.log('modal.innerHeight()-', modal.innerHeight());
    // console.log('modal[0].scrollHeight-', modal[0].scrollHeight);
    // this.scrollHitBottom = false;
    // if(modal.scrollTop() + modal.innerHeight() >= modal[0].scrollHeight-30) {
    //   this.scrollHitBottom = true;
    //   console.log('end reached');
    // }
    // if (!this.$rootScope.$$phase) this.$rootScope.$digest();

    if(this.showMe && !this.event.behavior.can_claim) {
      const modalMeOffsetTop = $('.leader-board__modal__me')[0].offsetTop;
      const meOffsetTop = $('.leader-board__modal__members_me')[0].offsetTop;
      const itemsOffsetTop = $('.leader-board__modal__items')[0].offsetTop;
      let bottom = meOffsetTop + itemsOffsetTop;
      // console.log('modalMeOffsetTop-', modalMeOffsetTop);
      // console.log('itemsOffsetTop-', itemsOffsetTop);
      // console.log('bottom-', bottom);
      // console.log('meOffsetTop-', meOffsetTop);
      if (modalMeOffsetTop >= bottom) {
        this.showMe = false;
      }
      if (!this.$rootScope.$$phase) this.$rootScope.$digest();
    }
  }

  async setWatch(){
    if (!this.didSetWatched && this.event.event.status === 'closed') {
      console.log('setWatch');
      const res = await this.RestManager.setLbEventWatched();
      if(!res.success){
        return;
      }
      this.didSetWatched = true;
      this.$cookies.remove('lbMe');
    }
  }

  info() {
    this.notificationAlert.open(`
    GuruShots suggests photos that may fit challenges according to your labels. Upload more photos to get further suggestions!
    <br><br>
    Use the "Replace" icon to get a different photo suggestion for the challenge. You can remove specific photos and select a replacement yourself.
    <br><br>
    * you might get similar images suggested based those in your account
    `);
  }

  eventMixPanelAction() {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.EVENT_WIDGET,
          ui_name: mixpanelEventsEnum.UI_NAME.OPEN_WIDGET
        }
      }
    );
  }
}

leaderBoardCtrl.$inject = [
  'Modals',
  'RestManager',
  '$rootScope',
  '$scope',
  '$cookies',
  '$element',
  '$timeout',
  'animationService',
  'notificationAlert',
  'autoUpdateService',
  'mixpanelService'
];

export default {
  template,
  controller: leaderBoardCtrl,
  bindings: {
    buttonPressed: '<'
  }
};
