import { Injectable } from '@angular/core';
import {NgSnackBarService} from "../../modules/angular-material/services/ng-snack-bar.service";
import {NgDateHelper} from "../helpers/ngDateHelper";
import {MatSnackBarRef} from "@angular/material/snack-bar";

@Injectable()
export class InfoService {
  currentSnackbar?:MatSnackBarRef<any> | null
  constructor(
    private snackBarService: NgSnackBarService
  ) { }

  showErrorMessage(data:{message:string, seconds?:number, panelClass?:''|'white-snackbar'}){
    this.currentSnackbar = this.snackBarService.open({
      message:data.message,
      config:{
        duration: NgDateHelper.millisecondsInSecond * (data.seconds || 5),
        panelClass: data.panelClass || ''
      }
    })
  }

  dismiss(){
    if(this.currentSnackbar){
      this.currentSnackbar.dismiss();
      this.currentSnackbar = null;
    }
  }
}
