import {ResourceType} from "../../../../../core/models/enums";

const template = require(`html-loader!./shopExhibition.html`).default;

class shopExhibitionCtrl {
  /**
   * @param $mdDialog
   * @param $mdToast
   * @param $timeout
   * @param guruShots
   * @param $rootScope
   * @param {ShopManager} ShopManager
   * @param {GSPay} GSPay
   * */

  constructor($scope, $mdDialog, $mdToast, $timeout, $rootScope, ShopManager, GSPay, RestManager, BankrollService) {
    'ngInject';
    this.$scope = $scope;
    this.$mdDialog = $mdDialog;
    this.$mdToast = $mdToast;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.ShopManager = ShopManager;
    this.RestManager = RestManager;
    this.bankrollService = BankrollService;
    this.GSPay = GSPay;

    this.busy = true;

    this.scopes = {
      'shop.exhibition': {
        type: 'EXHIBITION',
        googleEv: label => {
          return { event: 'Store', action: 'Exhibition', label: label };
        },
      },
      'shop.book': {
        type: 'BOOK',
        googleEv: label => {
          return { event: 'Store', action: 'book', label: label };
        },
      },
    };

    this.stage = 0;
    this.card = {};
    this.card.expire = {
      month: 0,
      year: 0,
    };
  }

  $onInit() {
    this.init();
  }

  async init(){
    if(!this.data.challenge.apply.package_id){
      this.hide();
      return;
    }

    let res  = await this.ShopManager.getPackage(this.scopes[this.data.name].type);
    if (!res.success) {
      this.hide();
      return;
    }
    this.shopData = res.items;
    this.shopData.provider = 'blueSnap';

    res  = await this.RestManager.getPackages([this.data.challenge.apply.package_id]);
    if (!res.success) {
      this.hide();
      return;
    }
    this.shopData.pack = res.packages[this.data.challenge.apply.package_id];

    this.busy = false;

    this.card.email = this.shopData.email;
    this.card.first_name = this.shopData.first_name;
    this.card.last_name = this.shopData.last_name;

    //Change state
    this.stage = this.shopData.payment_card ? 'cardExist' : 'payment';

    this.$timeout(() => {
      this.initPayPal();
    });
  }

  async pay() {
    if (this.busy) return false;
    //Validate
    if (!this.validate()) return false;

    let bluesnap3dsToken = this.shopData.bluesnap_3ds_token;
    if(this.shopData.payment_card && this.stage === 'payment'){
      this.changeBusy(true);
      const res = await this.RestManager.get3dsToken();
      if(!res.success){
        this.changeBusy(false);
        return false;
      }
      bluesnap3dsToken = res.bluesnap_3ds_token;
    }

    //Parse expire date
    let expire = typeof this.card.expiration === 'undefined' ? false : this.card.expiration.split('/');
    this.card.expire = {
      month: expire[0],
      year: expire[1],
    };

    //Init pay provider
    let data = {
      public_key: this.shopData.public_key,
      test_mode: this.shopData.test_mode,
      packId: this.shopData.pack.id,
      param: this.data.challenge.id || '',
      card: this.card,
      pack: this.shopData.pack,
      card_exists: 0,
      bluesnap_3ds_token: bluesnap3dsToken
    };
    let callbacks = {
      changeBusy: this.changeBusy.bind(this),
      changeState: this.changeState.bind(this),
      showMessage: this.showMessage.bind(this),
      setShopData: this.setShopData.bind(this),
      onError: () =>
        this.showMessage(`
                            Something went wrong. Make sure you are using a Visa or Master
                            credit card (Not debit) or use PayPal.`),
    };
    this.GSPay.init(this.shopData.provider, data, callbacks);

    //Pay
    this.$timeout(() => {
      this.GSPay.pay(this.shopData.provider, data, callbacks);
    });

    // if (!this.$scope.$$phase) {
    //   this.$scope.$digest();
    // }
  }

  /* Card exist process */
  payments(cardExist) {
    if (this.busy) return false;
    this.busy = true;

    let data = {
      card: this.card,
      pack: this.shopData.pack,
      param: this.data.challenge.id,
      shopData: this.shopData,
      card_exists: 1,
      bluesnap_3ds_token: this.shopData.bluesnap_3ds_token,
      test_mode: this.shopData.test_mode,
    };
    //GuruReview
    if (this.data.name === 'shop.guruReview') {
      data.param = this.data.guruId;
    }
    let callbacks = {
      changeBusy: this.changeBusy.bind(this),
      changeState: this.changeState.bind(this),
      showMessage: this.showMessage.bind(this),
      setShopData: this.setShopData.bind(this),
    };

    //Process payment
    this.GSPay.process(this.shopData.provider, data, callbacks);
  }

  changeState(state) {
    if (state === 'error') {
      return;
    }
    this.stage = state;
  }

  changeBusy(value) {
    this.busy = value;
  }

  changeCard() {
    this.shopData.payment_card = null;
    this.stage = 'payment';
  }

  setShopData(data) {
    this.shopData = data;
  }

  initPayPal() {
    const data = {
      pack: this.shopData.pack,
      packId: this.shopData.pack.id,
      c_id: this.data.challenge.id,
    };
    const callbacks = {
      changeState: this.changeState.bind(this),
      changeBusy: this.changeBusy.bind(this),
      showMessage: this.showMessage.bind(this),
    };
    this.GSPay.init('payPal', data, callbacks);
  }

  validate() {
    //Validate first name
    if (!this.card.first_name || this.card.first_name.length < 2) {
      this.showMessage('Please enter First name!');
      return false;
    }

    //Validate first name
    if (!this.card.last_name || this.card.last_name.length < 2) {
      this.showMessage('Please enter Last name!');
      return false;
    }

    //Validate email
    if (
      !/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
        this.card.email
      )
    ) {
      this.showMessage('Email not valid!');
      return false;
    }

    //Validate card number
    let number = typeof this.card.number === 'undefined' ? '' : this.card.number.replace(/ /g, '');
    if (number.length < 14) {
      this.showMessage('Card number not valid!');
      return false;
    }

    //Validate card cvv
    if (!this.card.cvv || this.card.cvv.length !== 3) {
      this.showMessage('CVV not valid!');
      return false;
    }

    //Validate expire
    let expire = typeof this.card.expiration === 'undefined' ? false : this.card.expiration.split('/');
    if (!expire || expire[0].length !== 2 || expire[1].length !== 2) {
      this.showMessage('Expire not valid!');
      return false;
    }

    // Valid
    return true;
  }

  showMessage(text) {
    this.$timeout(() => {
      let el = $('md-dialog .right');
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .parent(el)
          .textContent(text)
          .position('bottom right')
          .hideDelay(10000)
      );
    });
  }

  hide() {
    this.$mdDialog.hide();
  }

  paymentSuccess(){
    let resources = [];
    if(this.shopData.pack.grant_fills){
      resources.push({
          resourceType: ResourceType.FILLS,
          amountToAddOrReduce: this.shopData.pack.grant_fills
        }
      )
    }
    if(this.shopData.pack.grant_swaps){
      resources.push({
          resourceType: ResourceType.SWAPS,
          amountToAddOrReduce: this.shopData.pack.grant_swaps
        }
      )
    }
    if(this.shopData.pack.grant_keys){
      resources.push({
          resourceType: ResourceType.KEYS,
          amountToAddOrReduce: this.shopData.pack.grant_keys
        }
      )
    }
    if(resources.length){
      this.bankrollService.updateBankrollItemAmount(resources);
    }
    this.done('paid');
  }

  done(message) {
    //Fix for mobile
    this.$timeout(() => {
      this.$mdDialog.hide(message);
      // location.reload();
    }, 250);
    return true;
  }
}
shopExhibitionCtrl.$inject = [
  '$scope', '$mdDialog', '$mdToast', '$timeout', '$rootScope', 'ShopManager', 'GSPay', 'RestManager', 'BankrollService'
];
export default {
  template: template,
  controller: shopExhibitionCtrl,
  bindings: {
    data: '<data',
  },
};
