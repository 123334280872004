const template = require(`html-loader!./myChallengesAutoSelectWinners.html`).default;
import myChallengesEditorBase from '../myChallengesEditorBase';
import
{
  exhibitionTypes,
  additionalExhibitorTypes,
  winnersTypes,
  guaranteedExhibitorsProps,
  autoSelectWinnersDefault
} from './myChallengesAutoSelectWinners_data';

class myChallengesAutoSelectWinnersCtrl extends myChallengesEditorBase{
  /** @param {ChallengesManager} ChallengesManager **/

  constructor(ChallengesManager, $scope, Toasts, $element) {
    'ngInject';
    super({$scope, Toasts, $element});

    this.challenge;
    this.busy = true;
    this.autoSelectWinners;
    this.exhibitionTypes = exhibitionTypes;
    this.additionalExhibitorTypes = additionalExhibitorTypes;
    this.winnersTypes = winnersTypes;
    this.guaranteedExhibitorsProps = guaranteedExhibitorsProps;
    this.autoSelectWinnersDefault = autoSelectWinnersDefault;

    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.Toasts = Toasts;
    this.$element = $element;
  }

  $onInit() {
    this.get_data();
  }

  /** @return void **/
  async get_data() {
    this.busy = true;
    const res = await this.ChallengesManager.getChallengeAutoSelectAttributes(this.challenge.id);
    this.busy = false;
    // const res = await getAutoSelectWinners();
    if (!res.success) return;

    if (!res.auto_select_configuration){
      this.autoSelectWinners = this.autoSelectWinnersDefault;
    }else{
      this.autoSelectWinners = res.auto_select_configuration;
    }

    this.parseData();
    console.log('get_data', this.autoSelectWinners);

    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  parseData() {
    this.autoSelectWinners.guaranteed_exhibitors = this.autoSelectWinners.guaranteed_exhibitors || {};
    this.guaranteedExhibitorsProps.forEach((prop) => {
      this.autoSelectWinners.guaranteed_exhibitors[prop] = this.autoSelectWinners.guaranteed_exhibitors[prop] || {status: false};
      if (prop === 'winners' && !this.autoSelectWinners.guaranteed_exhibitors[prop].type) {
        this.autoSelectWinners.guaranteed_exhibitors[prop].type = 'DIGITAL';
      }
    });
  }

  async save() {
    if (!this.validate()) {
      return;
    }
    // let angular.copy(this.autoSelectWinners);
    // this.autoSelectWinners.catalogue.other = this.autoSelectWinners.catalogue.other.replace(/'/g, "\\'");
    this.autoSelectWinners.min_member_num.total = this.autoSelectWinners.min_member_num.print + this.autoSelectWinners.min_member_num.digital;
    if (!this.autoSelectWinners.guaranteed_exhibitors.top_photo_rank.status){
      delete this.autoSelectWinners.guaranteed_exhibitors.top_photo_rank.rank;
    }
    console.log('autoSelectWinners', this.autoSelectWinners);
    this.busy = true;
    const res = await this.ChallengesManager.setChallengeAutoSelectAttributes(
      {
        challenge_id:this.challenge.id,
        auto_select_configuration:JSON.stringify(this.autoSelectWinners),
      });
    this.busy = false;
    this.afterSave(res);
  }

  validate() {
    if (this.autoSelectWinners.min_member_num.print !== 0 && (!this.autoSelectWinners.min_member_num.print || this.autoSelectWinners.min_member_num.print < 0)) {
      this.Toasts.message('print must be defined', this.$element);
      return false;
    }
    if (this.autoSelectWinners.min_member_num.digital !== 0 && (!this.autoSelectWinners.min_member_num.digital || this.autoSelectWinners.min_member_num.digital < 0)) {
      this.Toasts.message('digital must be defined', this.$element);
      return false;
    }
    if (this.autoSelectWinners.guaranteed_exhibitors.top_photo_rank.status  && (!this.autoSelectWinners.guaranteed_exhibitors.top_photo_rank.rank
      || this.autoSelectWinners.guaranteed_exhibitors.top_photo_rank.rank < 0)) {
      this.Toasts.message('top_photo_rank must be defined', this.$element);
      return false;
    }

    return true;
  }
}
myChallengesAutoSelectWinnersCtrl.$inject = [
  'ChallengesManager', '$scope', 'Toasts', '$element'
];
export default {
  template: template,
  controller: myChallengesAutoSelectWinnersCtrl,
  bindings: {
    challenge: '<'
  }
};
