/* globals angular */

/*
 * v.1.0.0 (Latest version)
 * Set data-[attr]=[value] attribute to element and watching for changes
 * Fix for photobox with dynamic update data
 *
 * <tag attr>: set-data-attr="[string]"
 *             set-data-attr-value="[string]"
 * */
function setDataAttr() {
  return {
    restrict: 'A',
    scope: {
      setDataAttr: '@',
      setDataValue: '='
    },
    link: function (scope, element, attrs) {
      scope.$watch('setDataValue', function () {

        $(element[0]).data(scope.setDataAttr, scope.setDataValue);

      }, true);
    }
  };
}

setDataAttr.$inject = [

];

export default setDataAttr;
