import {Component, Input} from '@angular/core';
import {AdultFilterMode} from "../ng-adult-filter/ng-adult-filter.component";
import {MemberService} from "../../../core/services/member.service";
import {ImageInterface} from "../../../core/models/models";

@Component({
  selector: 'app-winner-list-item',
  templateUrl: './winner-list-item.component.html',
  styleUrl: './winner-list-item.component.scss'
})
export class WinnerListItemComponent {
  // @ts-ignore
  @Input() item!;
  // @ts-ignore
  @Input() prizes!;
  AdultFilterMode = AdultFilterMode;

  constructor(
    private memberService:MemberService,
  ) {}
  isAdultFilterEnabled(image:ImageInterface){
    return !this.memberService.getCurrentMember().adult && image.adult;
  }
}
