export const teamBadgesConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-team-badges data="modal.data"></modal-team-badges>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
