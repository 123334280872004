// export const rewardedVideoValues =
//   {
//     success: true,
//     values:
//       {
//         global:
//           {
//             rewarded_on: true,
//             days_since_signup: 1,
//             days_since_last_pay: 2,
//             cooldown_minutes: 3
//           },
//         placements:
//           [
//             {
//               id: 1,
//               name: "End of Challenge",
//               rewarded_on: true,
//               cooldown_minutes: 4,
//             },
//             {
//               id: 2,
//               name: "Status Up",
//               rewarded_on: true,
//               cooldown_minutes: 5,
//             }
//           ]
//       }
//   };



export const rewardedVideoValues =
  {
    success: true,
    placements:
      [
        {
          placement_id: 0,
          name: "Global",
          enabled: true,
          days_since_signup: 1,
          days_since_payment: 2,
          cool_down: 3
        },
        {
          placement_id: 1,
          name: "End of Challenge",
          enabled: true,
          cool_down: 4,
        },
        {
          placement_id: 2,
          name: "Status Up",
          enabled: true,
          cool_down: 5,
        }
      ]
  };
