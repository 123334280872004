import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiniGameModalComponent } from "./components/mini-game-modal/mini-game-modal.component";
import { MiniGameVotesMeterComponent } from "./components/mini-game-votes-meter/mini-game-votes-meter.component";
import { MiniGameVotingComponent } from "./components/mini-game-voting/mini-game-voting.component";
import {SharedModule} from "../../shared/shared.module";
import {AiFilterComponent} from "./components/ai-filter/ai-filter.component";
import { PrizeItemComponent } from './components/prize-item/prize-item.component';
import { ChallengeFullComponent } from './components/challenge-full/challenge-full.component';
import { PhotographerRankingComponent } from './components/photographer-ranking/photographer-ranking.component';
import {MatButtonModule} from "@angular/material/button";
import {ScrollingModule} from "@angular/cdk/scrolling";
import { PhotographerRankingItemComponent } from './components/photographer-ranking-item/photographer-ranking-item.component';
import {
  ActiveChallengeActionButtonComponent
} from "./components/active-challenge-action-button/active-challenge-action-button.component";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import { ChallengesNavigationComponent } from './components/challenges-navigation/challenges-navigation.component';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {BankrollModule} from "../bankroll/bankroll.module";
import { ChallengesHeaderComponent } from './containers/challenges-header/challenges-header.component';



@NgModule({
  declarations: [
    MiniGameModalComponent,
    MiniGameVotesMeterComponent,
    MiniGameVotingComponent,
    AiFilterComponent,
    PrizeItemComponent,
    ChallengeFullComponent,
    PhotographerRankingComponent,
    PhotographerRankingItemComponent,
    ActiveChallengeActionButtonComponent,
    ChallengesNavigationComponent,
    ChallengesHeaderComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MatButtonModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    RouterLink,
    RouterLinkActive,
    BankrollModule
  ]
})
export class ChallengesModule { }
