const template = require(`html-loader!./photoSwipeLink.html`).default;
import memberEnum from "../../../api/enums/member.enum";

class PhotoSwipeLinkCtrl {
  /**
   * @param {photoSwipeService} photoSwipe
   * */

  constructor(photoSwipe) {
    'ngInject';
    this.photoSwipe = photoSwipe;
  }

  $onChanges() {
    if (!this.items) {
      return false;
    }
    // copy obj
    this.images = _.clone(this.items);
    // convert to array if one item
    if (!(this.images instanceof Array)) {
      this.images = [this.images];
    }
    // set index
    this.index = this.index ? parseInt(this.index) : 0;
    // remove deleted members from set
    this.images.forEach((image, i) => {
      if (image.member && [memberEnum.GROUP.DEACTIVATE_BY_MEMBER, memberEnum.GROUP.DEACTIVATE_BY_ADMIN, memberEnum.GROUP.DELETED_BY_MEMBER, memberEnum.GROUP.DELETED_BY_ADMIN]
        .includes(parseInt(image.member.member_group_id))) {
        this.images.splice(i, 1);
        if (this.index > i) this.index--;
      }
    });
  }

  open(event) {
    event.preventDefault();
    if (!this.images.length) {
      return false;
    }
    // open image
    this.photoSwipe.open({images:this.images, index:this.index});
    return false;
  }
}
PhotoSwipeLinkCtrl.$inject = [
  'photoSwipe',
];
export default {
  template: template,
  controller: PhotoSwipeLinkCtrl,
  bindings: {
    items: '<',
    index: '<'
  }
};
