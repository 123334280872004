function follow(
  $rootScope,
  PhotosManager,
  Modals,
  MemberService,
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      follow: '=', //object with data
      update: '&' //Update callback
    },
    link: (scope, element) => {
      const member = MemberService.getCurrentMember();
      element.bind('click', () => {
        if (!member.logged_in) {
          Modals.open('login', null, {signUp: true});
          return false;
        }

        let member_id = scope.follow.member_id || scope.follow.member.id;

        if (!member_id) {
          console.log('follow.js:31', 'Data not valid!');
          return false;
        }

        let resultPromise =
          scope.follow.member.following == 1
            ? PhotosManager.unfollow('', '', [member_id])
            : PhotosManager.follow('', '', [member_id]);

        //Update parent scope
        scope.follow.member.following = scope.follow.member.following == 1 ? 0 : 1;

        //Run callback
        if (scope.update) {
          scope.update({item: scope.follow});
        }

        //Clear cache
        localStorage.clear();

        //Check server response
        resultPromise.then(res => {
          if (!res.success) {
            if (res.error_code === 1000) {
              Modals.open('login', event, {signUp: true});
            }
          }

          return true;
        });
      });
    }
  };
}

follow.$inject = [
  '$rootScope',
  'PhotosManager',
  'Modals',
  'MemberService',
];

export default follow;
