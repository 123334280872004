import { preSubmitConf } from './preSubmit/preSubmit.conf';
import { successfulSwapModalConf } from './successfulSwapModal/successfulSwapModal.conf';
import { challengeActionLockedConf } from './challengeActionLocked/challengeActionLocked.conf';
import { challengeActionActivateConf } from './challengeActionActivate/challengeActionActivate.conf';
import { boostUnlockConf } from './boostUnlock/boostUnlock.conf';
import { shopExhibitionConf } from './shopExhibition/shopExhibition.conf';
import { statusUpConf } from './statusUp/statusUp.conf';
import { broadcastConf } from './broadcast/broadcast.conf';
import { autoFillConf } from './autoFill/autoFill.conf';
import { introConf, introMobileConf } from './intro/intro.conf';
import { reportConf } from './report/report.conf';
import { reportSimilarConf } from './reportSimilar/reportSimilar.conf';
import { playerConf } from './player/player.conf';
import { readMoreConf } from './readMore/readMore.conf';
import { gsUploaderConf } from './gsUploader/gsUploader.conf';
import { challengeIntroConf } from './challengeIntro/challengeIntro.conf';
import { loginConf } from './login/login.conf';
import { shareConf } from './share/share.conf';
import { wixOfferConf } from './wixOffer/wixOffer.conf';
import { autoSubmitConf } from './autoSubmit/autoSubmit.conf';
import { enterPasswordConf } from './enterPassword/enterPassword.conf';
import { deactivateDeleteAccountConf } from './deactivateDeleteAccount/deactivateDeleteAccount.conf';
import { appUpdatedConf } from './appUpdated/appUpdated.conf';
import { toDraftConf } from './toDraft/toDraft.conf';
import { challengeLockedConf } from './challengeLocked/challengeLocked.conf';
import { queryBuilderConf } from './queryBuilder/queryBuilder.conf';
import { selectListConf } from './selectList/selectList.conf';
import { challengeUnlockConf } from './challengeUnlock/challengeUnlock.conf';
import { challengeJoinConf } from './challengeJoin/challengeJoin.conf';
import { createChallengeConf } from './createChallenge/createChallenge.conf';
import { lbEventConf } from './lbEvent/lbEvent.conf';
import { teamBadgesConf } from './teamBadges/teamBadges.conf';
import { startMatchConf } from './startMatch/startMatch.conf';
import { claimConf } from './claim/claim.conf';
import { teamsConfirmConf } from './teamsConfirm/teamsConfirm.conf';
import { photoShareChatConf } from './photoShareChat/photoShareChat.conf';
import { individualLeaderboardInfoConf } from './individualLeaderboardInfo/individualLeaderboardInfo.conf';
import { modalContentCardsConf } from "./braze/modalContentCards/modalContentCards.conf";
import { captionedImageConf } from "./braze/custom/captionedImage/captionedImage.conf";
import { achievementsInfoConf } from "./achievementsInfo/achievementsInfo.conf";
import { modalBlockedUserConf } from "./modalBlockedUser/modalBlockedUser.conf";
import { miniGameConf } from "./miniGame/miniGame.conf";

function modalsSettings() {
  'ngInject';
  return {
    preSubmit: preSubmitConf,
    successfulSwap: successfulSwapModalConf,
    challengeActionLocked: challengeActionLockedConf,
    boostUnlock: boostUnlockConf,
    challengeActionActivate: challengeActionActivateConf,
    'shop.exhibition': shopExhibitionConf,
    statusUp: statusUpConf,
    broadcast: broadcastConf,
    miniGame: miniGameConf,
    autoFill: autoFillConf,
    intro: introConf,
    introMobile: introMobileConf,
    report: reportConf,
    reportSimilar: reportSimilarConf,
    player: playerConf,
    readMore: readMoreConf,
    gsUploader: gsUploaderConf,
    challengeIntro: challengeIntroConf,
    login: loginConf,
    share: shareConf,
    wixOffer: wixOfferConf,
    autoSubmit: autoSubmitConf,
    enterPassword: enterPasswordConf,
    deactivateDeleteAccount: deactivateDeleteAccountConf,
    appUpdated: appUpdatedConf,
    toDraft: toDraftConf,
    queryBuilder: queryBuilderConf,
    challengeLocked: challengeLockedConf,
    selectList: selectListConf,
    challengeUnlock: challengeUnlockConf,
    challengeJoin: challengeJoinConf,
    createChallenge: createChallengeConf,
    lbEvent: lbEventConf,
    teamBadges: teamBadgesConf,
    startMatch: startMatchConf,
    claim: claimConf,
    teamsConfirm: teamsConfirmConf,
    photoShareChat: photoShareChatConf,
    individualLeaderboardInfo: individualLeaderboardInfoConf,
    modalContentCards: modalContentCardsConf,
    captionedImage: captionedImageConf,
    blockedUser: modalBlockedUserConf,
    achievementsInfo: achievementsInfoConf,
  };
}

export default modalsSettings;
