import {clearObject} from "../../helpers/object.helper";
import challengeEnum from "../enums/challenge.enum";

class ChallengeTemplate {
  constructor(props = {}) {
    this.id = props.id || null;
    this.name = props.name || null;
    this.params = new ChallengeTemplateParams (props.params ? typeof props.params === 'string' ? JSON.parse(props.params) : props.params : {
      // defaults for new templates
      voting_max_photos_mobile: 100,
      voting_max_photos_web: 200,
      join_coins: 0,
    });
  }

  getParsed(includeId = true, duplicationName){
    return {
      id: includeId ? this.id : null,
      name: duplicationName || this.name,
      params: JSON.stringify(clearObject(this.params))
    };
  }
}

class ChallengeTemplateParams {
  constructor(props) {
    this.type = props.type;
    this.tags = props.tags || [];
    this.badge = props.badge;
    this.badge_text = props.badge_text;
    this.badge_left = props.badge_left;
    this.badge_right = props.badge_right;
    this.exposure_required_votes = props.exposure_required_votes;
    this.exposure_decay_minutes = props.exposure_decay_minutes;
    this.exposure_decay_interval_minutes = props.exposure_decay_interval_minutes;
    this.vote_minutes_delay = props.vote_minutes_delay;
    this.max_photo_submits = props.max_photo_submits;
    this.key_lock_minutes = props.key_lock_minutes;
    this.recommended_hours = props.recommended_hours || [];
    this.recommended_start_time = props.recommended_start_time || [];
    this.recommended_end_time = props.recommended_end_time || [];
    this.ranking_levels_id = props.ranking_levels_id;
    this.apply_package_id = props.apply_package_id;
    this.join_member_status = props.join_member_status;
    this.join_member_status_type = props.join_member_status_type;
    this.prizes_worth = props.prizes_worth;
    this.guru_points = props.guru_points;
    this.guru_points_members = props.guru_points_members;
    this.turbo_min_delay = props.turbo_min_delay;
    this.turbo_max_selections = props.turbo_max_selections;
    this.turbo_required_selections = props.turbo_required_selections;
    this.turbo_difficulty = props.turbo_difficulty;
    this.achievements_and_prizes = props.achievements_and_prizes;
    this.vote_minimum_players = props.vote_minimum_players;
    this.max_players = props.max_players;
    this.voting_max_photos_mobile = props.voting_max_photos_mobile;
    this.voting_max_photos_web = props.voting_max_photos_web;
    this.join_coins = props.join_coins;
    this.coins_instead_of_keys = props.coins_instead_of_keys;
    this.turbo_unlock_type = props.turbo_unlock_type;
    this.turbo_unlock_amount = props.turbo_unlock_amount;

    //boolean
    this.boost_enable = !!props.boost_enable;
    this.enable_team_match = !!props.enable_team_match;
    this.enable_rank_auto_update = !!props.enable_rank_auto_update;
    this.filter_submit_by_date = !!props.filter_submit_by_date;
    this.enable_off_topic = !!props.enable_off_topic;
    this.turbo_enable = !!props.turbo_enable;
    this.guru_pick_enable = !!props.guru_pick_enable;
    this.top_photo_enable = !!props.top_photo_enable;
    this.top_photographer_enable = !!props.top_photographer_enable;
    this.levels_rank_enable = !!props.levels_rank_enable;
    this.legacy_achievements_enable = !!props.legacy_achievements_enable;
    this.new_ranking_enable = !!props.new_ranking_enable;
    this.give_freebies_enable = !!props.give_freebies_enable;
    this.fill_enable = !!props.fill_enable;
    this.fill_locked = !!props.fill_locked;
    this.swap_enable = !!props.swap_enable;
    this.swap_locked = !!props.swap_locked;
  }
  isFlash(){
    return this.type === challengeEnum.TYPE.FLASH
  }
  isOnePhoto(){
    return this.max_photo_submits === 1;
  }
}

export {
  ChallengeTemplate,
};
