export function extractContentFromHtml(s, space) {
  let span= document.createElement('span');
  span.innerHTML= s;
  if(space) {
    let children= span.querySelectorAll('*');
    for(let i = 0 ; i < children.length ; i++) {
      if(children[i].textContent)
        children[i].textContent+= ' ';
      else
        children[i].innerText+= ' ';
    }
  }
  return [span.textContent || span.innerText].toString().replace(/ +/g,' ');
}

export function getTextLineBreakToBr(text){
  return text.replace(/(\r\n|\n|\r)/gm, "<br />");
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function isTrue(string) {
  return string === 'true';
}

export function toCamelCaseFromUnderline(string) {
  string = string.split('_').map((word, index)=>{
    if(index !== 0){
      word = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return word;
  });
  return string.join('');
}
