import {delay, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./sponsorsPage.html`).default;

class sponsorsPageComponent {
  subject = new Subject();
  constructor(pageData, $window, $scope, $sce, RouterService) {
    'ngInject';
    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.$sce = $sce;
    this.RouterService = RouterService;

    this.RouterService.activatedRoute
      .params.pipe(
      delay(50),
      takeUntil(this.subject)
    ).subscribe(async () => {
      this.initPage();
    });
  }

  $onInit() {
    this.$window.scrollTo(0, 0);
  }

  initPage() {
    this.pageData.get('sponsors');

    if (!this.id) {
      return;
    }

    this.url = this.$sce.trustAsResourceUrl('https://gurushots.wufoo.eu/forms/' + this.id);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
sponsorsPageComponent.$inject = [
  'pageData', '$window', '$scope', '$sce', 'RouterService'
];
export default {
  template: template,
  controller: sponsorsPageComponent,
  bindings: {
    id: '<'
  }
};
