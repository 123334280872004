import GsIcon from "../GsIcon";
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

class AchievementModel {
  constructor({
    name,
    description,
    achievement_icon,
    achievement_white_icon,
    level_icon,
    is_active,
    current_level,
    levels_enabled,
    next_star_percent,
    achievements_to_next_star,
    count,
    type,
    param,
    title,
    value,
    landing_title,
    levels,
    unique_key,
  }) {
    Object.assign(this, {
      name,
      description,
      is_active,
      current_level,
      levels_enabled,
      next_star_percent,
      achievements_to_next_star,
      count,
      type,
      param: param || value,
      title,
      value,
      landing_title,
      unique_key,
    });
    this.achievement_icon =  achievement_icon ?  new GsIcon(achievement_icon) : undefined;
    this.achievement_white_icon =  achievement_white_icon ?  new GsIcon(achievement_white_icon) : undefined;
    this.level_icon =  level_icon ?  new GsIcon(level_icon) : undefined;
    this.levels =  levels ?  levels.map(level => new AchievementLevel(level)) : undefined;
    this.parsed_unique_key = unique_key ? unique_key.replace(/\./g, '_') : null;
  }
}

class AchievementLevel {
  constructor({
    level,
    threshold
  }) {
    Object.assign(this, {level, threshold});
  }
}

class AchievementChallenge {
  constructor({badge, challenge}) {
    this.challenge = new NgChallengeModel(challenge);
    this.badge = badge;
  }
}

class AchievementCategory {
  constructor({id, name, total_count, category_icon, claimed_count}) {
    Object.assign(this,
      (![undefined,null].includes(name)) && {name},
      (![undefined,null].includes(id)) && {id},
      (![undefined,null].includes(total_count)) && {total_count},
      (![undefined,null].includes(claimed_count)) && {claimed_count},
      (![undefined,null].includes(category_icon)) && {category_icon: new GsIcon(category_icon)},
    );
  }
}

export {AchievementModel, AchievementChallenge, AchievementCategory};
