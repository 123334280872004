const template = require(`html-loader!./discoverPhotos.html`).default;
import ImageModel from '../../../api/models/image/image.model';
import {Subject, takeUntil} from "rxjs";

class discoverPhotosComponent {

  constructor(
    RestManager,
    $element,
    $timeout,
    $scope,
    $rootScope,
    gsScrollTopService,
    tagEventService,
    MemberService,
    RouterService
  ) {
    'ngInject';
    this.start = 0;
    this.limit = 50;
    this.items = [];
    this.busy = false;
    this.inited = false;
    this.allLoaded = false;
    this.imgHeight = 500;
    this.subject$ = new Subject();

    this.RestManager = RestManager;
    this.$element = $element;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
    this.routerService = RouterService;

    this.onScroll = _.debounce(this.onScroll.bind(this), 250);
    this.onJgComplete = this.onJgComplete.bind(this);

    this.routerService.lastRouteData$.pipe(
      takeUntil(this.subject$)
    ).subscribe(event => {
      this.type = event.type;
      this.loadMore();
      this.tagEventService.sendPageViewEvent({title: 'Discover page ' + this.type });
    })
  }

  $onInit() {
    this.gsScrollTopService.scrollTop();
    $(document).on('scroll', this.onScroll);
    this.disableLazy = this.$rootScope.disableLazy;
  }

  $onDestroy() {
    this.subject$.next();
    this.subject$.complete();
    $(document).off('scroll', this.onScroll);
    try {
      $('.discover-photos__items')
        .justifiedGallery('destroy')
        .off('jg.complete', this.onJgComplete);
    } catch (e) {}
  }

  /** @return void **/
  async loadMore() {
    if (!this.type || this.busy || this.allLoaded) {
      return;
    }
    this.busy = true;

    const res = await this.RestManager.getDiscoverPage({
      start: this.start,
      limit: this.limit,
      type: this.type
    });

    if (!res.success) {
      this.busy = false;
      return;
    }

    if (res.items.length < this.limit) {
      this.allLoaded = true;
    }

    // no items
    if (res.items.length === 0) {
      this.inited = true;
      this.busy = false;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      return;
    }

    res.items = res.items.map(image => new ImageModel(image));
    this.items.push(...res.items);

    this.start += this.limit;

    this.$timeout(() => {
      if (!this.inited) {
        this.inited = true;
        $('.discover-photos__items')
          .justifiedGallery({
            rowHeight: 300,
            maxRowHeight: 300,
            lastRow: 'nojustify',
            captions: false,
            margins: 5,
            waitThumbnailsLoad: false,
            selector: 'div',
            cssAnimation: false,
            imagesAnimationDuration: 0
          })
          .on('jg.complete', this.onJgComplete);
      } else {
        $('.discover-photos__items').justifiedGallery('norewind');
      }
    });

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onJgComplete() {
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  onScroll() {
    const windowHeight = "innerHeight" in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body,
      html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    const getMoreHeight = (screen.width > 800) ? -15 : -15;
    if ((windowBottom - docHeight) > getMoreHeight) {
      this.loadMore();
    }
    return true;
  }
}
discoverPhotosComponent.$inject = [
  'RestManager',
  '$element',
  '$timeout',
  '$scope',
  '$rootScope',
  'gsScrollTopService',
  'tagEventService',
  'MemberService',
  'RouterService'
];
export default {
  template: template,
  controller: discoverPhotosComponent
};
