import {Injectable} from '@angular/core';
import { io } from "socket.io-client";
import NgTokenService from "./ng-token.service";
import {environment} from "../../../environments/environment";
import {IGsResponse} from "../models/gsResponse.types";
import {ChallengeBoostState, ChallengeStatus} from "../../modules/challenges/enums/challenges.enum";
import {Mission} from "../../modules/missions/missions.interface";
import {Socket} from "socket.io-client/build/esm/socket";
import {filter} from "rxjs";

export interface ISocketStatusUpdateEventResult extends IGsResponse{
  status: ChallengeStatus,
  challenge_id:number
}

export interface ISocketBoostStateUpdateEventResult extends IGsResponse{
  state: ChallengeBoostState;
  timeout: any;
  challenge_id:number;
}

export interface ISocketMissionsUpdateEventResult {
  new?: Mission[];
  in_progress?: Mission[];
  completed?: Mission[];
}

@Injectable({providedIn: 'root'})
export class SocketService {

  public socket?:Socket;

  constructor(
    tokenService: NgTokenService
  ) {
    tokenService.memberToken$.pipe(
      filter(res => res !== undefined)
    ).subscribe((memberToken)=>{
      this.socket = io(`${environment.domains.globalSocket}`, {
        query: {
          authorization: memberToken
        },
        withCredentials: true
      })

      this.socket.on('error', (error: any) => {
        console.error('Error ', error);
      });

      this.socket.on('connect', () => {
        console.log('socket connected')
      });
    });
  }
}
