import {getDate, getOldDate, pareDateFromUnixTime, parseFromSecToMilisec} from "../../../gsApp/app/helpers/date.helper";
import challengeEnum from "../../../gsApp/app/api/enums/challenge.enum";
import {extractContentFromHtml} from "../../../gsApp/app/helpers/string.helper";
import {environment} from "../../../../environments/environment";
import {NgImageModel} from "../../../core/models/fromOldApp/ng-image.model";
import {NgChallengeMemberModel} from "./ng-challenge-member.model";
import {NgMediaItemModel} from "./ng-media-item.model";
import {NgTeamMemberModel} from "../../../core/models/fromOldApp/ng-team-member.model";
import {ChallengeTurboState, ChallengeType} from "../enums/challenges.enum";
import {
  INgApply, INgChallengeMemberModel,
  INgChallengeModel,
  INgGuruInfoModel,
  INgMediaItemModel, INgPopupModel, INgPrizeModel, INgSponsorModel
} from "../interfaces/challenges.interface";
import {INgImageModel} from "../../../core/models/fromOldApp/models";
import memberEnum from "../../../gsApp/app/api/enums/member.enum";

export type IMAGE_SUGGEST_TYPE = 'disabled' | 'es_query' | 'most_voted';

export class NgChallengeModel implements INgChallengeModel {
  apply?: INgApply;
  as_last_joined_date = new Date();
  badge_left = '';
  badge_right = '';
  badge_text = '';
  banners = [];
  bid_start_time;
  close_time: Date = new Date();
  entries = [];
  exhibition = null;
  give_freebies_enable = false;
  guru_info?: INgGuruInfoModel;
  guru_pick_images: INgImageModel[] = [new NgImageModel({})];
  guru_top_pick: INgImageModel = new NgImageModel({});
  image: INgImageModel = new NgImageModel({});
  image_suggest_query = '(())';
  image_suggest_type: IMAGE_SUGGEST_TYPE = challengeEnum.IMAGE.SUGGEST.TYPE.DISABLED as IMAGE_SUGGEST_TYPE;
  is_bid_schedule;
  last_update_time;
  latest_upload_date = new Date();
  max_photo_submits = 0;
  media: INgMediaItemModel[] = [new NgMediaItemModel({})];
  member: INgChallengeMemberModel = new NgChallengeMemberModel({});
  players = 0;
  max_players: number = 0;
  popups: INgPopupModel[] = [];
  prizes: INgPrizeModel[] = [];
  ranking_levels = {};
  rules = [];
  send_push = false;
  sponsors?: INgSponsorModel[] = [];
  start_time;
  status = challengeEnum.STATUSES.DRAFT.VALUE;
  suspended_images: INgImageModel[] = [new NgImageModel({})];
  tags = [];
  team_members = [new NgTeamMemberModel({})];
  time_joined?: number;
  type = ChallengeType.SPEED;
  voteLevels = [];
  vote_minutes_delay = 0;
  voting_start_time = null;
  welcome_message: any;
  id?: number;
  join_coins: number = 0;
  votes:any = 0;

  private _coverUrl = '';
  private _isEnded = false;
  private _timeLeft = 0;
  private _timeFromStart = 0;
  private _timeUntilStart = 0;
  private _timePercent = 0;
  private _playersNext = 0;
  private _guruPointsByMembers: number;
  private _challengeStartVotingTime: Date;

  // @ts-ignore
  constructor(props: INgChallengeModel = {}) {
    Object.assign(this, props);

    //region Models
    //region Image models
    props.banners && props.banners.length && (this.banners = props.banners.map((banner: any) => {banner['image'] = new NgImageModel({id: banner.image_id, member_id: banner.member_id}); return banner;}));
    props.sponsors && props.sponsors.length && (this.sponsors = props.sponsors.map((sponsor: any) => {sponsor['image'] = new NgImageModel({id: sponsor.image_id, member_id: sponsor.member_id}); return sponsor;}));
    props.guru_pick_images && props.guru_pick_images.length &&(this.guru_pick_images = props.guru_pick_images.map((image: INgImageModel): INgImageModel => new NgImageModel(image)));
    props.guru_top_pick && (this.guru_top_pick = new NgImageModel(props.guru_top_pick));
    props.image && (this.image = new NgImageModel(props.image));
    props.suspended_images && props.suspended_images.length && (this.suspended_images = props.suspended_images.map((image: INgImageModel): INgImageModel => new NgImageModel(image)));
    //endregion
    props.media && props.media.length && (this.media = props.media.map((mediaItem: INgMediaItemModel): INgMediaItemModel => new NgMediaItemModel(mediaItem)));
    props.member && (this.member = new NgChallengeMemberModel(props.member));
    props.team_members && props.team_members.length && (this.team_members = props.team_members.map((member: any) => new NgTeamMemberModel(member)));
    //endregion

    //region Time
    //region pareDateFromUnixTime
    props.as_last_joined_date && (this.as_last_joined_date = pareDateFromUnixTime(props.as_last_joined_date));
    props.bid_start_time && (this.bid_start_time = pareDateFromUnixTime(props.bid_start_time));
    props.is_bid_schedule && (this.is_bid_schedule = props.bid_start_time && pareDateFromUnixTime(props.bid_start_time) > new Date());
    props.last_update_time && (this.last_update_time = pareDateFromUnixTime(props.last_update_time));
    props.latest_upload_date && (this.latest_upload_date = pareDateFromUnixTime(props.latest_upload_date));
    props.start_time && (this.start_time = pareDateFromUnixTime(props.start_time));
    //endregion
    props.close_time && (this.close_time = new Date(parseFromSecToMilisec(props.close_time)));
    props.time_joined && (this.time_joined = parseFromSecToMilisec(props.time_joined));
    //endregion

    this.initVoteLevels();
    this.initFixExhibitionText();

    const nowMilisec = new Date().getTime();

    this._coverUrl = `${'https://' + environment.domains.photos}/unsafe/0x0/${this.image.member_id}/3_${this.image.id}.jpg`;
    this._isEnded = +this.close_time < nowMilisec;
    this._timeLeft = +this.close_time - nowMilisec;
    this._timeFromStart = nowMilisec  - this.start_time;
    this._timeUntilStart = this.start_time - nowMilisec;
    this._timePercent = this.timePercentFn();
    props.players && (this._playersNext = Math.floor((props.players + 1000) / 1000) * 1000); /* for guru challenges. next members count to get bonus*/
    this._guruPointsByMembers = Math.floor(this.players / 1000) * 100;
    this._challengeStartVotingTime = this.challengeStartVotingTimeFn();
  }

  formatVotes(num:number) {
    if (num < 1000) {
      return num.toString();
    } else if (num >= 1000 && num < 1000000) {
      return Math.round(num / 1000) + 'K';
    } else if (num >= 1000000 && num < 1000000000) {
      return Math.round(num / 1000000) + 'M';
    } else {
      return Math.round(num / 1000000000) + 'T';
    }
  }

  getWelcomeMessageAsText = (): string => extractContentFromHtml(this.welcome_message, true);

  updateRankingLevel(): void {
    const levelNames =
      [
        challengeEnum.RANK_LEVEL.NOT_RANKED.NAME,
        challengeEnum.RANK_LEVEL.POPULAR.NAME,
        challengeEnum.RANK_LEVEL.SKILLED.NAME,
        challengeEnum.RANK_LEVEL.PREMIER.NAME,
        challengeEnum.RANK_LEVEL.ELITE.NAME,
        challengeEnum.RANK_LEVEL.ALL_STAR.NAME
      ];
    for (let [level, votes] of this.voteLevels.entries()) {
      if (this.member.ranking!.total!.votes >= votes) {
        this.member.ranking!.total!.level = level;
        this.member.ranking!.total!.level_name = levelNames[level];
      }
    }
  }

  setEmptyRanking(): void {
    /* used only once, we can set it as default */
    this.member.ranking = {
      swaps: [],
      entries: [],
      exposure: { exposure_factor: 0, vote_exposure_factor: 0, vote_ratio: 2 },
      // @ts-ignore
      total: {
        exposure: 0,
        guru_picks: 0,
        level: 0,
        level_name: 'NOT RANKED',
        level_rank: 'NOT RANKED',
        next: 50,
        next_message: '',
        percent: 0,
        points: 0,
        rank: 'NOT RANKED',
        votes: 0,
      },
    };
  }

  resetBidStartTime(): void {
    this.bid_start_time = getOldDate();
  }

  setDefaultBidStartTime(): void {
    // @ts-ignore
    this.bid_start_time = getDate({
      daysToAdd: -2,
      initTime: this.start_time.getTime()
    });
  }

  getRules(): any {
    return this.rules;
  }

  isDefault = (): boolean => this.type === ChallengeType.DEFAULT;

  isOnePhoto = (): boolean => this.max_photo_submits === 1;
  isFlash = (): boolean => this.type === ChallengeType.FLASH;
  getFirstImage = (): any => this.member.ranking?.entries?.length ? this.member.ranking?.entries[0] : null;

  isSpeed = (): boolean => this.type === ChallengeType.SPEED;

  isClosingStatus = (): boolean => this.status === challengeEnum.STATUSES.CLOSING.VALUE;

  isActiveStatus = (): boolean => this.status === challengeEnum.STATUSES.ACTIVE.VALUE;

  isExhibition = (): boolean => this.type === ChallengeType.EXHIBITION;

  isLocked = (): boolean => this.member && this.member.submit_state === memberEnum.SUBMIT_STATES.JOIN_UNLOCK;

  isLockedWithKey = (): boolean => this.isLocked() && this.join_coins === 0;

  isLockedWithCoins = (): boolean => this.isLocked() && this.join_coins > 0;

  getBadgeLeftRight = (): string => `${this.badge_left} ${this.badge_right}`;

  getPoints = (): number => this.member.ranking!.total!.points; /* TODO: why we need init methods to get simply value? */

  getMemberVotes = (): number => this.member.ranking!.total!.votes;

  isLimitedByNumberOfPlayers(){
    return this.max_players > 0
  }

  setTurboState(state:ChallengeTurboState){
    if(this.member.turbo){
      this.member.turbo.state = state;
    }
  }

  private initVoteLevels(): void {
    // ranking levels to integer
    for (const prop in this.ranking_levels) {
      if (this.ranking_levels.hasOwnProperty(prop)) {
        // @ts-ignore
        this.ranking_levels[prop as keyof typeof this.ranking_levels] = parseInt(this.ranking_levels[prop as keyof typeof this.ranking_levels]);
        this.voteLevels.push(this.ranking_levels[prop as keyof typeof this.ranking_levels]);
      }
    }
  }

  private initFixExhibitionText(): void {
    /* TODO: exhibition.model isn't defined */
    if (this.exhibition !== null && this.exhibition['time_left']) {
      if (this.exhibition['time_left']['days'] > 0) {
        // @ts-ignore
        this.exhibition['text'] =
          this.exhibition['time_left']['days'] > 1
            ? this.exhibition['time_left']['days'] + ' days'
            : this.exhibition['time_left']['days'] + ' day';
      } else {
        // @ts-ignore
        this.exhibition['text'] = this.exhibition['time_left']['hours'] + 'h';
      }
    }
  }

  private timePercentFn = (): number => {
    // Start time correction: -1 day (86400000ms)
    // const start = new Date(this.start_time - 86400000).getTime();
    const start = new Date(this.start_time).getTime();
    const end = +this.close_time;
    const now = new Date().getTime();
    let percent = Math.floor(((now - start) * 100) / (end - start));
    if (percent < 0) {
      percent = 0;
    }
    if (percent > 100) {
      percent = 100;
    }

    return percent; // Return challenge time progress in %
  }

  private challengeStartVotingTimeFn = (): Date => {
    let startVotingTime = new Date(this.start_time);

    this.vote_minutes_delay && startVotingTime.setMinutes(startVotingTime.getMinutes() + this.vote_minutes_delay);

    return startVotingTime;
  }

  get coverUrl(): string { return this._coverUrl;};

  set coverUrl(value: string) { this._coverUrl = value;};

  get isEnded(): boolean { return this._isEnded;};

  set isEnded(value) { this._isEnded = value;};

  get timeLeft(): number { return this._timeLeft; };
  get timeFromStart(): number { return  this._timeFromStart; };
  get timeUntilStart(): number { return  this._timeUntilStart; };
  get timePercent(): number { return this._timePercent; }

  set timePercent(value: number) { this._timePercent = value; }

  get playersNext(): number { return this._playersNext; };

  set playersNext(value: number) { this._playersNext = value; }

  get guruPointsByMembers(): number { return this._guruPointsByMembers; }

  set guruPointsByMembers(value: number) { this._guruPointsByMembers = value; }

  get challengeStartVotingTime(): Date { return this._challengeStartVotingTime; }

  set challengeStartVotingTime(value: Date) { this._challengeStartVotingTime = value; }

  get parsedVotes():string{
    return this.formatVotes(this.votes);
  }
}
