import {ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, Input, OnInit} from '@angular/core';
import {SectionType} from "../../../store/models/enums";
import {BankrollItem, BankrollService} from "../../bankroll.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {filter} from "rxjs";

@Component({
  selector: 'app-bankroll-store',
  templateUrl: './bankroll-store.component.html',
  styleUrls: ['./bankroll-store.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BankrollStoreComponent implements OnInit{
  @Input() sectionType!:SectionType;

  items!:BankrollItem[];
  destroyRef = inject(DestroyRef);

  constructor(
    private bankrollService: BankrollService,
    private cdr: ChangeDetectorRef,
  ) {
  }
  ngOnInit(): void {
    this.bankrollService.bankrollData$.pipe(
        filter(bankrollData => !!bankrollData?.get(this.sectionType)),
        takeUntilDestroyed(this.destroyRef)
    ).subscribe((bankrollData)=> {
      this.items = Array.from(bankrollData?.get(this.sectionType) as Map<unknown, any>, entry => entry[1]);
      this.cdr.markForCheck();
    });
  }
  itemClick(item:BankrollItem): void {
    this.bankrollService.itemClick$.emit(item.type);
  }
}
