import {
  INgImageModel,
  INgMemberModel
} from "./models";
import {NgImageModel} from "./ng-image.model";
import {NgRankingModel} from "../../../modules/challenges/models/ng-ranking.model";
import memberEnum from "../../../gsApp/app/api/enums/member.enum";
import {NgLeagueModel} from "./ng-league.model";
import {parseFromSecToMilisec} from "../../../gsApp/app/helpers/date.helper";

export class NgMemberModel implements INgMemberModel {
  exhibition = null;
  is_joined = false;
  logged_in = false;
  permissions = undefined;
  ranking = {};
  suggested_images;
  member_league_id = 0;
  time_joined;
  league;
  reg_time;
  id: any;
  member_group_id!: number;
  user_name: any;
  avatar: any;
  adult: any;
  is_admin = false;

  constructor(props: INgMemberModel = {}) {
    Object.assign(this, props);
    props.ranking && (this.ranking = new NgRankingModel(props.ranking));
    props.suggested_images && (this.suggested_images = props.suggested_images.map((image: INgImageModel): INgImageModel => new NgImageModel(image)));
    props.time_joined && (this.time_joined = parseFromSecToMilisec(props.time_joined)); /* TODO: time_joined maybe we can remove it. */
    props.reg_time && (this.reg_time = new Date(parseFromSecToMilisec(props.reg_time)));
    props.member_league_id && (this.member_league_id = props.member_league_id);
    props.league && (this.league = new NgLeagueModel(props.league));
  }

  canShowLeagueBadge = (): number => this.member_league_id; /*&& this.member_league_id >= 7*/

  isOwner = (id: any): boolean => this.id === id;

  inTestGroup = (): boolean => this.member_group_id === 1001; /* TODO: create enum for 1001 value in memberEnum.GROUP.TEST_GROUP */

  isRestricted = (): boolean => this.isDeactivatedByMember() || this.isDeactivatedByAdmin() || this.isDeletedByMember() || this.isDeletedByAdmin();

  isDeactivatedByMember = (): boolean => this.member_group_id === memberEnum.GROUP.DEACTIVATE_BY_MEMBER;

  isDeactivatedByAdmin = (): boolean => this.member_group_id === memberEnum.GROUP.DEACTIVATE_BY_ADMIN;

  isDeletedByMember = (): boolean => this.member_group_id === memberEnum.GROUP.DELETED_BY_MEMBER;

  isDeletedByAdmin = (): boolean => this.member_group_id === memberEnum.GROUP.DELETED_BY_ADMIN;

  get isGuru(): boolean { return this.member_group_id === 2000};

}
