import {millisecondsInMinute, millisecondsInSecond} from "../helpers/date.helper";
//TODO enum refactor
export const autoUpdateTypeEnum = {
  NOTIFICATION: 'NOTIFICATION',
  TEAM: 'TEAM',
  MATCH: 'MATCH',
  BRAZE_SERVER_EVENTS: 'BRAZE_SERVER_EVENTS',
  TEAMS_IS_NEW_INVITATION: 'TEAMS_IS_NEW_INVITATION',
  LB_EVENT: 'LB_EVENT',
  COMPLETED_CHALLENGES: 'COMPLETED_CHALLENGES',
  MY_ACTIVE_CHALLENGES_UPDATE: 'MY_ACTIVE_CHALLENGES_UPDATE',
  BATTLE_WAITING_ROOM_STATUS: 'BATTLE_WAITING_ROOM_STATUS',
  BATTLE_RANKING: 'BATTLE_ACTIVE',
  BATTLE_STATUS: 'BATTLE_STATUS',
  ALL_GALLERIES_COMPLETED_CHECK: 'ALL_GALLERIES_COMPLETED_CHECK',
  RANKING: 'RANKING',
  CHALLENGES_BULK_UPLOAD: 'CHALLENGES_BULK_UPLOAD'
};

export const autoUpdateConf = {
  [autoUpdateTypeEnum.NOTIFICATION] : {
    delay : millisecondsInMinute * 4
    // delay : millisecondsInSecond  * 15 // 3 min
  },
  [autoUpdateTypeEnum.TEAM] : {
    delay : millisecondsInMinute * 20
  },
  [autoUpdateTypeEnum.MATCH] : {
    delay : millisecondsInMinute
  },
  [autoUpdateTypeEnum.BRAZE_SERVER_EVENTS] : {
    delay : millisecondsInMinute * 10
  },
  [autoUpdateTypeEnum.TEAMS_IS_NEW_INVITATION] : {
    delay : millisecondsInMinute * 10
  },
  [autoUpdateTypeEnum.COMPLETED_CHALLENGES] : {
    delay : millisecondsInMinute * 10
  },
  [autoUpdateTypeEnum.BATTLE_WAITING_ROOM_STATUS] : {
    delay : millisecondsInSecond * 5
  },
  [autoUpdateTypeEnum.LB_EVENT] : {
    delayActive : millisecondsInMinute * 4,
    delayInactive : millisecondsInMinute * 30
  },
  [autoUpdateTypeEnum.BATTLE_RANKING]: {
    delay : millisecondsInSecond * 5
  },
  [autoUpdateTypeEnum.BATTLE_STATUS]: {
    delay : millisecondsInSecond * 5
  },
  [autoUpdateTypeEnum.ALL_GALLERIES_COMPLETED_CHECK]: {
    delay : millisecondsInMinute * 5
  },
  [autoUpdateTypeEnum.RANKING]: {
    delay : millisecondsInSecond * 5
  },
  [autoUpdateTypeEnum.MY_ACTIVE_CHALLENGES_UPDATE]: {
    delay : millisecondsInMinute * 5
  },
  [autoUpdateTypeEnum.CHALLENGES_BULK_UPLOAD]: {
    delay : millisecondsInMinute * 5
  }
};
