import {INgExifDataModel} from "./models";

export class NgExifDataModel implements INgExifDataModel {
  /* TODO:
  *   all this fields declaration is reuqires by
  *   class NgImageModel implements INgImageModel
  *   in constructor line if(props.meta_data) {...}
  * */
  make!: boolean;
  model!: boolean;
  lens!: boolean;
  f_number!: boolean;
  exposure_time!: boolean;
  focal_length!: boolean;
  iso!: boolean;
  post_processing!: boolean;

  constructor(props: INgExifDataModel = {}) {
    Object.assign(this, props);
  }
}
