export const challengeActionActivateConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-challenge-action-activate data="modal.data"></modal-challenge-action-activate>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
