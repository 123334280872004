<div id="app-wrapper">
  <app-header-wrapper></app-header-wrapper>
  <div class="content" *ngIf="isMemberLoaded">
    <div id="page">
      <router-outlet></router-outlet>
    </div>
    <ng1-photo-swipe></ng1-photo-swipe>
    <ng1-gs-modals></ng1-gs-modals>
    <ng1-mobile-header></ng1-mobile-header>
  </div>
  <app-footer-wrapper id="footer"></app-footer-wrapper>
</div>

