const template = require(`html-loader!./articleBlockImage.html`).default;
import {shareList} from "../articles.share.data";

class articleBlockImageCtrl {
  constructor() {
    "ngInject";
    this.shareList = shareList;
  }

  $onChanges() {
    this.shareInfo = this.article.shareInfo;
  }

  openPhoto(event) {
    event.preventDefault();
    this.tools.openPhoto(parseInt(this.index));
    return false;
  }
}

export default {
  template: template,
  controller: articleBlockImageCtrl,
  bindings: {
    item: '<',
    article: '<',
    index: '@',
    tools: '<'
  }
};
