import {ChangeDetectionStrategy, Component} from '@angular/core';
import {SoundService} from "../../../core/services/sound.service";

@Component({
  selector: 'app-mute-button',
  templateUrl: './mute-button.component.html',
  styleUrls: ['./mute-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MuteButtonComponent {

  constructor(public soundService:SoundService) {}

  mute() {
    this.soundService.onMutButtonClick();
  }
}
