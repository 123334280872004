function config() {
  "ngInject";

  // $stateProvider
  //   .state('gs.exhibitionWidget', {
  //     url: '/exhibition_widget/:cId',
  //     views: {
  //       'header@gs': null,
  //       'page@gs': {component: 'exhibitionWidget'},
  //       'footer@gs': null
  //     },
  //     params: {
  //       cId: null,
  //       squash: true
  //     },
  //   });
}

config.$inject = [];
export default config;
