<div
  class="mini-game-voting-wrapper"
  [ngClass]="{'block-events': isVoteSubmitGoing}"
>

  <div class="mini-game-voting-inner">

    <div
      class="image-first"
      [ngClass]="{
        'success': isFirstImageActive && voteState === true,
        'un_success': isFirstImageActive && voteState === false,
        'show-score': voteState === false || voteState === true,
        'scale-in': !isLoading
       }"
    >
      <div class="vote-result-overlay" [ngStyle]="{'z-index': images.length + 1}">

        <span
          class="gs-theme-text-headline-sub image-percentage"
          [ngStyle]="{'z-index': images.length + 2}"
        >{{firstImageScore}}%</span>

      </div>

      <ng-container *ngFor="let image of images; let i = index;">
        <app-ng-image
          [image]="image.first_image"
          [fill]="true"
          [priority]="true"
          [width]="imageWidth"
          [ngClass]="{
            'is-image-visible': i === currentIndex,
            'previous-image': i === currentIndex - 1
          }"
          [ngStyle]="{'z-index': images.length - i}"
          (click)="submitImage(image.first_image.id, true)"
        >
        </app-ng-image>
      </ng-container>
    </div>

    <div
      class="image-second"
      [ngClass]="{
        'success': !isFirstImageActive && voteState === true,
        'un_success': !isFirstImageActive && voteState === false,
        'show-score': voteState === false || voteState === true,
        'scale-in': !isLoading
       }"
    >
      <div class="vote-result-overlay" [ngStyle]="{'z-index': images.length + 1}">

        <span
          class="gs-theme-text-headline-sub image-percentage"
          [ngStyle]="{'z-index': images.length + 2}"
        >{{secondImageScore}}%</span>

      </div>

      <ng-container *ngFor="let image of images; let i = index;">
        <app-ng-image
          [image]="image.second_image"
          [fill]="true"
          [priority]="true"
          [width]="imageWidth"
          [ngClass]="{
            'is-image-visible': i === currentIndex,
            'previous-image': i === currentIndex - 1
          }"
          [ngStyle]="{'z-index': images.length - i}"
          (click)="submitImage(image.second_image.id, false)"
        >
        </app-ng-image>
      </ng-container>
    </div>

    <div
      class="mini-game-voting-vs"
      [ngClass]="{'animate-vs': !isLoading}"
    >vs</div>

  </div>
</div>
