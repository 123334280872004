export const individualLeaderboardInfoConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-individual-leaderboard-info data="modal.data"></modal-individual-leaderboard-info>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
