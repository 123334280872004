import {Subject, takeUntil, filter} from "rxjs";
const template = require(`html-loader!./prizes.html`).default;

class challengePrizesCtrl {
  challenge;
  prizes;

  constructor($location, tagEventService, ChallengesManager, NgChallengesService) {
    "ngInject";
    this.subject = new Subject();
    this.$location = $location;
    this.tagEventService = tagEventService;
    this.ChallengesManager = ChallengesManager;
    this.NgChallengesService = NgChallengesService;
  }

  async $onInit() {
    this.tagEventService.sendPageViewEvent({title: 'Challenge Prizes'});
    this.NgChallengesService.currentChallenge$.pipe(
      filter(challenge => challenge !== undefined),
      takeUntil(this.subject)
    ).subscribe(async (challenge) => {
      this.challenge = challenge;

      const res = await this.NgChallengesService.getChallengePrizes(this.challenge.id);
      if (res.success) {
        this.prizes = res.prizes;
      }
    });
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}

challengePrizesCtrl.$inject = [
  '$location', 'tagEventService', 'ChallengesManager', 'NgChallengesService'
];
export default {
  template: template,
  controller: challengePrizesCtrl
};
