export const photoShareChatConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-photo-share-chat data="modal.data"></modal-photo-share-chat>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false,
};
