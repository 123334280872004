const template = require(`html-loader!./articleItemEditor.html`).default;

import ImageModel from '../../../api/models/image/image.model';
import {types, banners} from '../articles.data';

class articleItemEditorCtrl {
  /** @param {PhotosManager} PhotosManager **/

  constructor($mdSidenav, Modals, $mdToast, $element, PhotosManager, $timeout, $rootScope, MemberService) {
    'ngInject';
    this.types = types;
    this.banners = banners;

    this.$mdSidenav = $mdSidenav;
    this.Modals = Modals;
    this.$mdToast = $mdToast;
    this.$element = $element;
    this.PhotosManager = PhotosManager;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();
  }

  $onChanges() {
    this.activateEditor();
  }

  onChange() {
    this.activateEditor();
  }

  activateEditor() {
    if(this.item.type === 'html') {
      this.$timeout(() => {
        new MediumEditor('.article-item-editor__editable', {
          elementsContainer: document.querySelector('article-edit'),
          spellcheck: false,
          targetBlank: true,
          imageDragging: false,
          toolbar: {
            buttons: ['bold', 'italic', 'underline', 'h2', 'h3', 'h4', 'anchor'],
            diffTop: -72
          },
          paste: {
            cleanPastedHTML: true,
            cleanAttrs: ['style', 'dir'],
            cleanTags: ['label', 'meta']
          },
        });
      });
    }
  }

  closeItemEditor() {
    $('body').removeAttr('style');

    if (this.callbacks && this.callbacks.onClose) {
      this.callbacks.onClose();
    }
    this.$mdSidenav('newItem').close();

    console.log(this.item);
  }

  deleteItem() {
    this.$mdSidenav('newItem').close();
    this._deleteItem(this.item);
  }

  changeImage(event) {
    this.Modals.open('gsUploader', event, {
      target: 'getImages',
      items_limit: 1,
      member: {
        id: this.item.image ? this.item.image.member_id : this.member?.id
      },
      callback: images => {
        this.item.image = images[0];
        this.item.content = images[0].id;
      }
    });
  }

  getImageData() {
    if (this.item.content && this.item.content.length < 32) {
      if (this.item.content.length === 0) {
        return false;
      }
      // Show error
      this.$mdToast.show(
        this.$mdToast
          .simple()
          .parent(this.$element)
          .textContent('Put correct image id!')
          .position('top right')
          .hideDelay(3000)
      );
      return false;
    }

    // get image data
    this.PhotosManager.getImageData(this.item.content).then(res => {
      if (!res.success || res.data.id !== this.item.content) {
        return false;
      }

      // Update image
      this.$timeout(() => {
        this.item.image = new ImageModel(res.data);
        console.log(this.item, 'this.item');
      });
    });
  }
}
articleItemEditorCtrl.$inject = [
  '$mdSidenav', 'Modals', '$mdToast', '$element', 'PhotosManager', '$timeout', '$rootScope', 'MemberService'
];
export default {
  template: template,
  controller: articleItemEditorCtrl,
  bindings: {
    item: '=',
    _deleteItem: '&deleteItem'
  }
};
