export const autoSelectWinnersDefault =
{
  exhibition_type: "EXHIBITION",//EXHIBITION, MAGAZINE, NEWSPAPER, TV
  min_member_num: {
    total: 530,
    print: 30,
    digital: 500,
  },
  auto_select: true,
  guaranteed_exhibitors: {
    winners: {
      status: true,
      type: "PRINT"//"PRINT","DIGITAL"
    },
    all_star: {
      status: true,
    },
    guru_picks: {
      status: true,
    },
    top_photo_rank: {
      status: false,
      rank: 100
    },
  },
  catalogue:{
    name:'',
    country:'',
    dates:'',
    gallery:'',
    type:'',
    custom:'',
  },
  additional_exhibitor: "TOP_PHOTOGRAPHER",//TOP_PHOTOGRAPHER,TOP_PHOTO
  image_limit_per_member: 1,
};
export const exhibitionTypes = [
  {title: 'Exhibition', value: 'EXHIBITION'},
  {title: 'Magazine', value: 'MAGAZINE'},
  {title: 'Newspaper', value: 'NEWSPAPER'},
  {title: 'TV', value: 'TV'},
];
export const additionalExhibitorTypes = [
  {title: 'Top Photographer', value: 'TOP_PHOTOGRAPHER'},
  {title: 'Top Photo', value: 'TOP_PHOTO'},
];
export const winnersTypes = [
  {title: 'Digital', value: 'DIGITAL'},
  {title: 'Print', value: 'PRINT'},
];
export const guaranteedExhibitorsProps = [
  'winners',
  'all_star',
  'guru_picks',
  'top_photo_rank'
];

