import {
  millisecondsInSecond,
  getDaysByDelta,
  daysToHours,
  getHoursByDelta,
  getMinutesByDelta,
  getSecondsByDelta
} from "../helpers/date.helper";

class gsTimerCtrl {
  constructor(
    $scope,
    $element,
    $compile,
    $interval
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$element = $element;
    this.$compile = $compile;
    this.$interval = $interval;

    this.resetSecondsCounter = 0;
    this.$scope.hours = 0;
    this.$scope.minutes = 0;
    this.$scope.seconds = 0;

    this.run();
  }

  run() {
    this.countdown = this.$scope.countdown - new Date().getTime();

    this.convertMillisecondsToTime(this.countdown);

    this.interval = this.$interval(() => {
      this.convertMillisecondsToTime(this.countdown);
    }, millisecondsInSecond);

    this.$scope.$on('$destroy', () => {
      this.$interval.cancel(this.interval);
    });
  }

  convertMillisecondsToTime(milliseconds) {
    const resetAfterSeconds = 60;

    let days = getDaysByDelta(milliseconds),
      hours,
      minutes = getMinutesByDelta(milliseconds),
      seconds = getSecondsByDelta(milliseconds)
    ;

    this.resetSecondsCounter++;

    if (this.resetSecondsCounter >= resetAfterSeconds) {
      let currentDate = new Date().getTime();

      this.resetSecondsCounter = 0;
      this.countdown = this.$scope.countdown - currentDate;
    } else {
      this.countdown -= millisecondsInSecond;
    }

    if (this.countdown <= 0) {
      /* TODO:
      *
      * Create 'ENDED' state for timer
      *
      * How it works now: <div class="c-challenges-item__title__days_left c-challenges-item__title__ended" ng-if="$ctrl.challenge.isEnded">ENDED</div>
      *  */
      if (this.$scope.callback) {
        this.$scope.callback();
      }

      this.$interval.cancel(this.interval);
      this.$compile(this.$element.contents())(this.$scope);
      return;
    }

    if (this.$scope.showDays) {
      hours = getHoursByDelta(milliseconds);

      this.$scope.days = days;
    } else {
      hours = getHoursByDelta(milliseconds) + daysToHours(days);
    }

    this.$scope.intHours = hours;
    this.$scope.intMinutes = minutes;
    this.$scope.intSeconds = seconds;
    this.$scope.hours = this.parseTime(hours);
    this.$scope.minutes = this.parseTime(minutes);
    this.$scope.seconds = this.parseTime(seconds);
    // console.log(`hours - ${this.$scope.hours} minutes - ${this.$scope.minutes} seconds - ${this.$scope.seconds}`);
    this.$compile(this.$element.contents())(this.$scope);
  }

  parseTime(time){
    return time < 10 ? (this.$scope.hideZero ? '' : '0') + time : time;
  }

  /*
  * TODO: timer display options via default pipe date.
  *
  * <gs-timer countdown="$ctrl.value | date:'DD:MM'"></gs-timer>
  * */
}

gsTimerCtrl.$inject = [
  '$scope',
  '$element',
  '$compile',
  '$interval'
];

export default function gsTimer() {
  'ngInject';

  return {
    restrict: 'E',
    replace: false,
    scope: {
      countdown: '<',
      showDays: '@',
      hideZero: '@',
      callback: '&'
    },
    controller: gsTimerCtrl,
  };
}
