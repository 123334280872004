const template = require(`html-loader!./challengesSponsors.html`).default;


class ChallengesSponsors {

  constructor(
    challengesSponsorsService,
    $rootScope,
    $scope,
    Toasts
  ) {
    'ngInject';

    this.challengesSponsorsService = challengesSponsorsService;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.toasts = Toasts;

    this.sponsorsConfig = null;
  }

  $onInit() {
    this.getConfig();
    this.putSponsorsToConfig({});
    this.getConfig();

    this.$rootScope.$on('challengeSponsorsSave',     this.challengeSponsorsSave.bind(this));
    this.$rootScope.$on('challengeSponsorsChanged',  this.challengeSponsorsChanged.bind(this));
    this.$rootScope.$on('challengeSponsorsLoadMore', this.shakeTemplate.bind(this));
  }

  shakeTemplate() {
    if (!this.$rootScope.$$phase) this.$scope.$apply();
  }

  async challengeSponsorsSave() {
    await this.challengesSponsorsService.putSponsorsToConfigGetLatest({});
    this.challengesSponsorsService.notification(`ID ${this.sponsorsConfig.gsTable.entities[0].id} was saved successfully`);
    this.getConfigAndShake();
  }

  challengeSponsorsChanged(event, data) {
    this.challengesSponsorsService.updateEntity(data);
    this.challengesSponsorsService.notification(`ID ${data.id} was updated successfully`);
    this.getConfigAndShake();
  }

  getConfigAndShake() {
    this.getConfig();
    this.shakeTemplate();
  }

  getConfig() {
    this.sponsorsConfig = this.challengesSponsorsService.getSponsorConfig();
  }

  putSponsorsToConfig(
    {
      start = this.sponsorsConfig.pagination.start,
      limit = this.sponsorsConfig.pagination.limit
    }
  ) {
    this.challengesSponsorsService.putSponsorsToConfig({start, limit});
  }
}
ChallengesSponsors.$inject = [
  'challengesSponsorsService',
  '$rootScope',
  '$scope',
  'Toasts'
];
export default {
  template: template,
  controller: ChallengesSponsors
};
