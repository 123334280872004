import range from "./range.filter";
import timeago from "./timeago.filter";
import abbreviateNumber from "./abbreviateNumber.filter";

let moduleFilters = angular.module('gsApp.filters', [])
  .filter('range', range)
  .filter('timeago', timeago)
  .filter('abbreviateNumber', abbreviateNumber)
;
export default moduleFilters.name;
