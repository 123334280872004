import ModalsService from './modals.service';
import ModalsConf from './modals.conf';
//Components
import preSubmit from './preSubmit/preSubmit';
import modalSuccessfulSwap from './successfulSwapModal/successfulSwapModal';
import ruleBlock from './preSubmit/ruleBlock/ruleBlock';
import challengeActionLocked from './challengeActionLocked/challengeActionLocked';
import challengeActionActivate from './challengeActionActivate/challengeActionActivate';
import boostUnlock from './boostUnlock/boostUnlock';
import shopExhibition from './shopExhibition/shopExhibition';
import statusUp from './statusUp/statusUp';
import reportSimilar from './reportSimilar/reportSimilar';
import player from './player/player';
import readMore from './readMore/readMore';
import gsUploader from './gsUploader/gsUploader';
import gsUploaderButtons from './gsUploader/gsUploaderButtons/gsUploaderButtons';
import gsUploaderPhotos from './gsUploader/gsUploaderPhotos/gsUploaderPhotos';
import gsUploaderFiles from './gsUploader/gsUploaderFiles/gsUploaderFiles';
import gsUploaderSwap from './gsUploader/gsUploaderSwap/gsUploaderSwap';
import challengeIntro from './challengeIntro/challengeIntro';
import miniGameCtrl from './miniGame/miniGame';
import login from './login/login';
import share from './share/share';
import wixOffer from './wixOffer/wixOffer';
import autoSubmit from './autoSubmit/autoSubmit';
import enterPassword from './enterPassword/enterPassword';
import deactivateDeleteAccount from './deactivateDeleteAccount/deactivateDeleteAccount';
import autoFill from './autoFill/autoFill';
import appUpdated from './appUpdated/appUpdated';
import toDraft from './toDraft/toDraft';
import challengeLocked from './challengeLocked/challengeLocked';
import queryBuilder from './queryBuilder/queryBuilder';
import selectList from './selectList/selectList';
import challengeUnlock from './challengeUnlock/challengeUnlock';
import challengeJoin from './challengeJoin/challengeJoin';
import createChallenge from "./createChallenge/createChallenge";
import lbEvent from './lbEvent/lbEvent';
import teamBadges from './teamBadges/teamBadges';
import startMatch from './startMatch/startMatch';
import claim from './claim/claim';
import teamsConfirm from './teamsConfirm/teamsConfirm';
import photoShareChat from './photoShareChat/photoShareChat';
import individualLeaderboardInfo from './individualLeaderboardInfo/individualLeaderboardInfo';
import modalContentCards from "./braze/modalContentCards/modalContentCards";
import captionedImage from "./braze/custom/captionedImage/captionedImage";
import achievementsInfo from "./achievementsInfo/achievementsInfo";
import modalBlockedUser from "./modalBlockedUser/modalBlockedUser";

let moduleServiceModals = angular
  .module('gsApp.service.modals', [])
  .factory('modalsSettings', ModalsConf)
  .service('Modals', ModalsService)
  .component('modalPreSubmit', preSubmit)
  .component('modalSuccessfulSwap', modalSuccessfulSwap)
  .component('ruleBlock', ruleBlock)
  .component('modalChallengeActionLocked', challengeActionLocked)
  .component('modalBoostUnlock', boostUnlock)
  .component('modalChallengeActionActivate', challengeActionActivate)
  .component('modalStatusUp', statusUp)
  .component('modalReportSimilar', reportSimilar)
  .component('shopExhibitionModal', shopExhibition)
  .component('modalPlayer', player)
  .component('modalReadMore', readMore)
  .component('modalLogin', login)
  .component('modalChallengeIntro', challengeIntro)
  .component('modalMiniGame', miniGameCtrl)
  .component('gsUploader', gsUploader)
  .component('gsUploaderButtons', gsUploaderButtons)
  .component('gsUploaderPhotos', gsUploaderPhotos)
  .component('gsUploaderFiles', gsUploaderFiles)
  .component('gsUploaderSwap', gsUploaderSwap)
  .component('modalShare', share)
  .component('modalWixOffer', wixOffer)
  .component('modalAutoSubmit', autoSubmit)
  .component('modalEnterPassword', enterPassword)
  .component('modalDeactivateDeleteAccount', deactivateDeleteAccount)
  .component('modalAutoFill', autoFill)
  .component('modalAppUpdated', appUpdated)
  .component('modalToDraft', toDraft)
  .component('modalQueryBuilder', queryBuilder)
  .component('modalChallengeLocked', challengeLocked)
  .component('modalSelectList', selectList)
  .component('modalChallengeUnlock', challengeUnlock)
  .component('modalChallengeJoin', challengeJoin)
  .component('modalCreateChallenge', createChallenge)
  .component('modalLbEvent', lbEvent)
  .component('modalTeamBadges', teamBadges)
  .component('modalStartMatch', startMatch)
  .component('modalClaim', claim)
  .component('modalTeamsConfirm', teamsConfirm)
  .component('modalPhotoShareChat', photoShareChat)
  .component('modalIndividualLeaderboardInfo', individualLeaderboardInfo)
  .component('modalContentCards', modalContentCards)
  .component('modalCaptionedImage', captionedImage)
  .component('modalBlockedUser', modalBlockedUser)
  .component('modalAchievementsInfo', achievementsInfo)
;

export default moduleServiceModals.name;
