import {Component, Input} from '@angular/core';
import {RankingItemInterface} from "../../interfaces/ranking.interface";

@Component({
  selector: 'app-photographer-ranking-item',
  templateUrl: './photographer-ranking-item.component.html',
  styleUrl: './photographer-ranking-item.component.scss'
})
export class PhotographerRankingItemComponent {
  @Input() public rankingItem!: RankingItemInterface;
  @Input() public isUser = false;
  @Input() public exposure = 0;
}
