import ImageModel from '../models/image/image.model';
import {clearObject} from "../../helpers/object.helper";

class PhotosManager {
  constructor(
    api,
    CacheFactory,
    $http,
    notificationAlert,
    ResponseService
  ) {
    'ngInject';
    this.api = api;
    this.$http = $http;
    this.cache = CacheFactory.get('PhotosManager') || CacheFactory.createCache('PhotosManager');
    this.notificationAlert = notificationAlert;
    this.ResponseService = ResponseService;
  }

  getProfileLikes({ member_id, start, limit }) {
    return this.api['photos']['getProfileLikes']({ member_id, start, limit });
  }

  getPhotosPrivate(params) {
    params = clearObject(params);
    console.log('params', params);
    console.log('getPhotosPrivate', this.api['rest']['getPhotosPrivate']);
    return this.api['rest']['getPhotosPrivate'](params).then(res => {
      res.items = res.items ? res.items.map(image => new ImageModel(image)) : [];
      res.suggested = res.suggested ? res.suggested.map(image => new ImageModel(image)) : [];
      return res;
    });
  }

  getJoinThemePhotos(params) {
    return this.api['rest']['getJoinThemePhotos'](params).then(res => {
      res.items = res.items ? res.items.map(image => new ImageModel(image)) : [];
      res.suggested = res.suggested ? res.suggested.map(image => new ImageModel(image)) : [];
      return res;
    });
  }

  getSwitchBattlePhotos(params) {
    return this.api['rest']['getSwitchBattlePhotos'](params).then(res => {
      res.items = res.items ? res.items.map(image => new ImageModel(image)) : [];
      res.suggested = res.suggested ? res.suggested.map(image => new ImageModel(image)) : [];
      return res;
    });
  }

  getPhotosPublic(params) {
    return this.api['rest']['getPhotosPublic'](params).then(res => {
      res.items = res.items ? res.items.map(image => new ImageModel(image)) : [];
      res.suggested = res.suggested ? res.suggested.map(image => new ImageModel(image)) : [];
      return res;
    });
  }

  getMyPhotos({ limit, start, id, c_id, order, sort }) {
    return this.api['rest']['getPhotoLibrary']({ limit, start, id, c_id, order, sort });
  }

  deleteProfileImage(id) {
    return this.api['photos']['deleteProfileImage']({ id });
  }

  like({token, image_id}) {
    return this.api['events']['like']({ token, image_id}).then(res => {
      this.setForce(true);
      return res;
    });
  }

  unlike({token, image_id}) {
    return this.api['events']['unlike']({ token, image_id}).then(res => {
      this.setForce(true);
      return res;
    });
  }

  follow(el, el_id, om_id) {
    return this.api['rest']['follow']({ el, el_id, om_id }).then(res => {
      if (this.ResponseService.isResponseError(res)) {
        this.ResponseService.responseHandler(res);

        return false;
      }

      this.setForce(true);
      return res;
    });
  }

  unfollow(el, el_id, om_id) {
    return this.api['rest']['unfollow']({ el, el_id, om_id }).then(res => {
      this.setForce(true);
      return res;
    });
  }

  isForce() {
    return this.cache.get('force') || undefined;
  }

  setForce(bool) {
    this.cache.put('force', bool);
  }

  setPhotoRelation(action, relation, relation_id, photos = [], el, el_id, chlg_id) {
    return this.api['rest']['setPhotoRelation']({ photos, el, el_id, chlg_id, action, relation, relation_id });
  }

  addProfileImage(image_ids) {
    return this.api['rest']['addProfileImage']({ image_ids });
  }

  searchAutocomplete(search, member_id) {
    return this.api['rest']['searchAutocomplete']({ search, member_id });
  }

  getImageData(id) {
    return this.api['rest']['getImageData']({ id });
  }

  setImageData(fields) {
    return this.api['rest']['setImageData'](fields);
  }

  setPhotoData(data) {
    return this.api['rest']['setPhotoData'](data);
  }

  getVotesList({ id, start, limit }) {
    return this.api['rest']['getVotesList']({ id, start, limit });
  }

  getLikesList({ id, start, limit }) {
    return this.api['rest']['getLikesList']({ id, start, limit });
  }

  getMatchedChallenges(image_ids = []) {
    return this.api['rest']['getMatchedChallenges']({ image_ids });
  }

  globalSuggest({ query, start, limit }) {
    return this.api['rest']['globalSuggest']({ query, start, limit }).then(res => {
      res.images = res.images ? res.images.map(image => new ImageModel(image)) : [];
      res.keywords = res.keywords ? res.keywords : [];
      return res;
    });
  }

  remoteUpload({ upload_domain, token, images }) {
    return this.$http({
      url: upload_domain + '/rest/remote_upload',
      method: 'POST',
      data: $.param({ upload_domain, token, images }),
    })
      .then(res => res.data)
      .catch(() => {
        return { success: false };
      });
  }
}

PhotosManager.$inject = [
  'api',
  'CacheFactory',
  '$http',
  'notificationAlert',
  'ResponseService'
];

export default PhotosManager;
