const template = require(`html-loader!./activitySide.html`).default;

import ImageModel from '../../../../api/models/image/image.model';

class activitySideCtrl {

  constructor($element) {
    'ngInject';

    this.$element = $element;

    this.onScroll = _.debounce(this.onScroll.bind(this), 250);
  }

  $onInit() {
    $(document).on('scroll', this.onScroll);
  }

  $onChanges() {
    if (!this.data) return
    this.inspire = this.data.inspire ? this.data.inspire.map(image => new ImageModel(image)) : [];
  }

  $onDestroy() {
    $(document).off('scroll', this.onScroll);
  }

  removeFollower(index) {
    this.data.follow_suggestions.splice(index, 1);
  }

  onScroll() {
    const visible = $('activity-side:visible').visible(true);
    if (visible && !this.$element.hasClass('active')) this.$element.addClass('active');
    if (!visible && this.$element.hasClass('active')) this.$element.removeClass('active');
  }
}
activitySideCtrl.$inject = [
  '$element',
];
export default {
  template: template,
  controller: activitySideCtrl,
  bindings: {
    data: '<',
  },
};
