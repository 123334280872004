export const MixpanelProvider = {
  provide: 'mixpanelService',
  useFactory:  (i: any) => i.get('mixpanelService'),
  deps: ['$injector']
};

export const BrazeProvider = {
  provide: 'brazeService',
  useFactory: (i: any) => i.get('brazeService'),
  deps: ['$injector']
};

export const TagEventProvider = {
  provide: 'tagEventService',
  useFactory: (i: any) => i.get('tagEventService'),
  deps: ['$injector']
};

export const autoUpdateProvider = {
  provide: 'autoUpdateService',
  useFactory: (i: any) => i.get('autoUpdateService'),
  deps: ['$injector']
};
export const gsModalsProvider = {
  provide: 'gsModals',
  useFactory: (i: any) => i.get('gsModals'),
  deps: ['$injector']
};

export const stateProvider = {
  provide: '$state',
  useFactory: (i: any) => i.get('$state'),
  deps: ['$injector']
};

export const StateParamsProvider ={
  provide: '$stateParams',
  useFactory: (i: any) => i.get('$stateParams'),
  deps: ['$injector']
};

export const rootScopeServiceProvider ={
  provide: '$rootScope',
  useFactory: (i: any) => i.get('$rootScope'),
  deps: ['$injector']
};

export const PageDataProvider ={
  provide: 'pageData',
  useFactory: (i: any) => i.get('pageData'),
  deps: ['$injector']
};

export const ModalsServiceBridgeProvider ={
  provide: 'ModalsServiceBridge',
  useFactory: (i: any) => i.get('ModalsServiceBridge'),
  deps: ['$injector']
};

export const voteManagerProvider = {
  provide: 'VoteManager',
  useFactory: (i: any) => i.get('VoteManager'),
  deps: ['$injector']
};

export const photoSwipeProvider = {
  provide: 'photoSwipe',
  useFactory: (i: any) => i.get('photoSwipe'),
  deps: ['$injector']
};

export const ModalsProvider = {
  provide: 'Modals',
  useFactory: (i: any) => i.get('Modals'),
  deps: ['$injector']
};

export const challengeServiceProvider = {
  provide: 'challengeService',
  useFactory: (i: any) => i.get('challengeService'),
  deps: ['$injector']
};
