const template = require(`html-loader!./markAsAdult.html`).default;

class MarkAsAdult {
  constructor(
    MarkAsAdultService
  ) {
    'ngInject';

    this.MarkAsAdultService = MarkAsAdultService;
  }

  saveAdult() {
    const data = {
      id: this.image.id,
      adult: this.image.adult ? 1 : 0,
    };
    this.MarkAsAdultService.setPhotoData(data);
  }
}

MarkAsAdult.$inject = [
  'MarkAsAdultService'
];

export default {
  controller: MarkAsAdult,
  template: template,
  bindings: {
    image: '<'
  }
};
