import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-wrapper',
  templateUrl: './admin-wrapper.component.html',
  styleUrl: './admin-wrapper.component.scss'
})
export class AdminWrapperComponent {

}
