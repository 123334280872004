import { Component } from '@angular/core';

@Component({
  selector: 'app-profile-settings-wrapper',
  templateUrl: './profile-settings-wrapper.component.html',
  styleUrl: './profile-settings-wrapper.component.scss'
})
export class ProfileSettingsWrapperComponent {

}
