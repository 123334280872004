class ArticlesManager {
  /** @param {api} api **/
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  getArticles({ start, limit, url }) {
    return this.api['articles']['get_articles_list']({ start, limit, url });
  }

  saveArticle(data) {
    return this.api['articles']['setArticle'](data);
  }

  delArticle(id) {
    return this.api['articles']['delArticle']({ id });
  }

  notifyMembers(article_id) {
    return this.api['rest']['notifyMembers']({ article_id });
  }
}

ArticlesManager.$inject = [
  'api'
];

export default ArticlesManager;
