const template = require(`html-loader!./gsProgressBar.html`).default;

class gsProgressBarCtrl {
  constructor(
    $scope,
    $rootScope
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
  }
}

gsProgressBarCtrl.$inject = [
  '$scope',
  '$rootScope'
];

export default {
  template: template,
  controller: gsProgressBarCtrl,
  bindings: {
    present: '@',
    text: '@',
  }
};
