import {Component, Input} from '@angular/core';
import {PrizeType, ResourceType} from "../../../core/models/enums";
import {BankrollService} from "../../../modules/bankroll/bankroll.service";
import {IPrize} from "../../../core/models/models";

@Component({
  selector: 'app-ng-rewards',
  templateUrl: './ng-rewards.component.html',
  styleUrl: './ng-rewards.component.scss'
})
export class NgRewardsComponent{
  @Input() prizes: IPrize[] = [];
  @Input() title = '1st Prize';
  @Input() styleClass: '' | 'rank-header' | 'rank-item' | 'rank-mission-item' = '';

  prizesMap = new Map<PrizeType,ResourceType>();
  PrizeType = PrizeType

  constructor(
    public bankrollService:BankrollService
  ) {
    this.prizesMap = bankrollService.prizeTypeResourceTypeMap;
  }
}
