import requestEnum from "./request.enum";
import RequestInterface from "./request.interface";

class RequestServiceModel implements RequestInterface {
  endPointController;
  endPoint;
  body;
  getMockData;
  mockDataState;
  force;
  url;

  constructor(
    {
      endPoint,
      body,
      url,
      endPointController = requestEnum.CONTROLLER.REST,
      getMockData = false,
      mockDataState = 1,
      force = true,
    }: RequestInterface
  ) {
    this.endPoint = endPoint;
    this.force = force;
    this.getMockData = getMockData;
    this.mockDataState = mockDataState;
    this.endPointController = endPointController;
    this.url = url;

    if (body) {
      this.body = body;
    }
  }
}

export default RequestServiceModel;
