export const gsUploaderImage = {
  // Return Thumb for image, fix rotation
  getThumbnailUrl(file) {
    return new Promise((resolve, reject) => {
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      var maxW = 200;
      var maxH = 200;
      var img = document.createElement('img');

      img.onload = () => {
        var iw = img.width;
        var ih = img.height;
        var scale = Math.min((maxW / iw), (maxH / ih));
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        // canvas.width = iwScaled;
        // canvas.height = ihScaled;

        this.getOrientation(file).then(orientation => {
          // By canvas
          switch (orientation) {
            case 5:
            case 6:
            case 7:
            case 8:
              canvas.width = ihScaled;
              canvas.height = iwScaled;
              break;
            default:
              canvas.width = iwScaled;
              canvas.height = ihScaled;
          }
          // By context
          switch (orientation) {
            case 2:
              // horizontal flip
              context.translate(iwScaled, 0);
              context.scale(-1, 1);
              break;
            case 3:
              // 180 rotate left
              context.translate(iwScaled, ihScaled);
              context.rotate(Math.PI);
              break;
            case 4:
              // vertical flip
              context.translate(0, ihScaled);
              context.scale(1, -1);
              break;
            case 5:
              // vertical flip + 90 rotate right
              context.rotate(0.5 * Math.PI);
              context.scale(1, -1);
              break;
            case 6:
              // 90 rotate right
              context.rotate(0.5 * Math.PI);
              context.translate(0, -ihScaled);
              break;
            case 7:
              // horizontal flip + 90 rotate right
              context.rotate(0.5 * Math.PI);
              context.translate(iwScaled, -ihScaled);
              context.scale(-1, 1);
              break;
            case 8:
              // 90 rotate left
              context.rotate(-0.5 * Math.PI);
              context.translate(-iwScaled, 0);
              break;
            default:
              break;
          }

          context.drawImage(img, 0, 0, iwScaled, ihScaled);

          resolve(canvas.toDataURL());
        });
      };

      img.src = URL.createObjectURL(file);
    });
  },

  //Get orientation from EXIF
  getOrientation(file, callback) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = (e) => {
        var view = new DataView(e.target.result);
        if (view.getUint16(0, false) != 0xFFD8) return resolve(-2);
        var length = view.byteLength, offset = 2;
        while (offset < length) {
          var marker = view.getUint16(offset, false);
          offset += 2;
          if (marker == 0xFFE1) {
            if (view.getUint32(offset += 2, false) != 0x45786966) return resolve(-1);
            var little = view.getUint16(offset += 6, false) == 0x4949;
            offset += view.getUint32(offset + 4, little);
            var tags = view.getUint16(offset, little);
            offset += 2;
            for (var i = 0; i < tags; i++)
              if (view.getUint16(offset + (i * 12), little) == 0x0112)
                return resolve(view.getUint16(offset + (i * 12) + 8, little));
          }
          else if ((marker & 0xFF00) != 0xFF00) break;
          else offset += view.getUint16(offset, false);
        }
        return resolve(-1);
      };
      reader.readAsArrayBuffer(file);
    });
  }
};