import ChestModel from "./chest/chest.model";
import {getTextLineBreakToBr} from "../../helpers/string.helper";

class FeatureMessageModel {
  constructor(props) {
    this.chest = props.chest ? new ChestModel(props.chest) : props.chest;
    this.data = props.data ? new FeatureMessageData(props.data) : props.data;
    this.id = props.id;
    this.member_id = props.member_id;
    this.type = props.type;
  }
}

class FeatureMessageData {
  constructor(props) {
    this.button = props.button ? new FeatureMessageButton(props.button) : props.button;
    this.image_url = props.image_url;
    this.lottie_url = props.lottie_url;
    this.subtitle = props.subtitle;
    this.text = props.text ? getTextLineBreakToBr(props.text) : props.text;
    this.title = props.title;
  }
}

class FeatureMessageButton {
  constructor(props) {
    this.text = props.text;
    this.type = props.type;
  }
}

export default FeatureMessageModel;
