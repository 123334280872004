const template = require(`html-loader!./adultFilter.html`).default;
import adultFilterEnum from "../../api/enums/adultFilter.enum";

class adultFilterCtrl {
  /**
   * @param {RestManager} RestManager
   * */
  constructor(
    $element,
    Modals,
    RestManager,
    $scope,
    MemberService,
  ) {
    'ngInject';

    this.$element = $element;
    this.Modals = Modals;
    this.RestManager = RestManager;
    this.$scope = $scope;
    this.MemberService = MemberService;
    this.adultFilterEnum = adultFilterEnum;
  }

  $onInit(){
    this.member = this.MemberService.getCurrentMember();
    this.mode = this.mode ? this.mode : adultFilterEnum.MODE.DEFAULT;
  }

  show() {
    this.$element.remove();
  }

  /** @return void */
  async disableAdult() {
    // not logged in
    if (!this.member?.logged_in) {
      this.Modals.open('login');
      return;
    }
    // remove all filters
    angular.element(document).find('adult-filter').remove();
    // save settings
    const res = await this.RestManager.updateMemberSettings({adult: true});
    if (!res.success) {
      return;
    }
    // update local settings
    this.member.adult = true;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}

adultFilterCtrl.$inject = [
  '$element',
  'Modals',
  'RestManager',
  '$scope',
  'MemberService'
];

export default {
  template: template,
  controller: adultFilterCtrl,
  bindings: {
    image: '<',
    mode: '<'
  }
};
