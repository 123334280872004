import MemberModel from './member.model';
import ImageModel from './image/image.model';
import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";

class PageResponseModel {
  constructor(data) {
    // items
    if (data.items && data.items.member) {
      data.items.member = new MemberModel(data.items.member);
    }
    if (data.items && data.items.challenge) {
      data.items.challenge = new NgChallengeModel(data.items.challenge);
    }
    if (data.items && data.items.image) {
      data.items.image = new ImageModel(data.items.image);
    }
    // items.page
    if (data.items && data.items.page && data.items.page.member) {
      data.items.page.member = new MemberModel(data.items.page.member);
    }
    if (data.items && data.items.page && data.items.page.challenge) {
      data.items.page.challenge = new NgChallengeModel(data.items.page.challenge);
    }
    if (data.items && data.items.page && data.items.page.image) {
      data.items.page.image = new ImageModel(data.items.page.image);
    }
    this.items = data.items || {};
    this.success = data.success;
  }
}

export default PageResponseModel;
