const HTTP_ENUM = {
  HEADER: {
    X_TOKEN: 'X-TOKEN',
    X_ADMIN_TOKEN: 'X-ADMIN-TOKEN',
    AUTHORIZATION: 'Authorization',
    X_API_VERSION: 'X-API-VERSION',
    X_ENV: 'X-ENV',
    CONTENT_TYPE: 'Content-Type',
    X_REQUESTED_WITH: 'X-Requested-With',
    CACHE_CONTROL: 'Cache-Control',
    PRAGMA: 'Pragma',
  }
};

export {HTTP_ENUM};
