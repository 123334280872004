import ImageModel from '../models/image/image.model';

class CamerasManager {
  /** @param {api} api **/
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  getList() {
    return this.api['rest']['getList']().then(res => {
      if (res.items) {
        res.items = res.items.map(slider => {
          slider.images = slider.images.map(image => new ImageModel(image));
          slider.link = `https://widget.gurushots.com/${slider.brand}/${slider.url}`;
          return slider;
        });
      }
      return res;
    });
  }

  getCameraImages(brand, model, limit) {
    return this.api['rest']['getCameraImages']({ brand, model, limit }).then(res => {
      if (res.items) {
        res.items = res.items.map(image => new ImageModel(image));
      }

      return res;
    });
  }

  getCameraSlider(brand, url, mode) {
    return this.api['rest']['getCameraSlider']({ brand, url, mode }).then(res => {
      if (res.camera) {
        res.camera.images = res.camera.images.map(image => new ImageModel(image));
      }

      return res;
    });
  }

  setCameraImages(brand, model, images) {
    return this.api['rest']['setCameraImages']({ brand, model, images });
  }

  delCameraImages({ brand, url }) {
    return this.api['rest']['delCameraImages']({ brand, url });
  }

  cameraSearchAutocomplete(text, limit) {
    return this.api['rest']['cameraSearchAutocomplete']({ text, limit });
  }

  cameraSearch({ text, start, limit }) {
    return this.api['rest']['cameraSearch']({ text, start, limit }).then(res => {
      if (res.items) {
        res.items = res.items.map(image => new ImageModel(image));
      }
      return res;
    });
  }
}

CamerasManager.$inject = [
  'api'
];

export default CamerasManager;
