import moment from 'moment';
const template = require(`html-loader!./editLbEvent.html`).default;
import {
  isFieldValid,
  isNumber
} from "../../../../../helpers/validate.helper";
import challengeEnum from '../../../../../api/enums/challenge.enum';
import memberEnum from '../../../../../api/enums/member.enum';
import {delay, filter, map, Subject, takeUntil} from "rxjs";
import SpotlightModel from "../../../../../api/models/spotlight.model";

const NUM_OF_POSITIONS = 6;

class editLbEventCtrl {
  subject = new Subject();
  constructor(RestManager, RouterService, Toasts, $element, notificationAlert) {
    'ngInject';

    this.challengeEnum = challengeEnum;
    this.errors = [];
    this.prizePosition = 1;
    this.goalTypes = ['points','successful_swap','level_up', 'receive_votes', 'top_rank'];  /* TODO: enum */
    this.challengeStatuses = [
      challengeEnum.STATUSES.DRAFT.VALUE,
      challengeEnum.STATUSES.UPCOMING.VALUE
    ];
    this.prizeTypes = ['NONE', 'KEY', 'PHOTO_SWAP', 'EXPOSURE_AUTOFILL', 'EARN_POINTS']; /* TODO: enum */
    this.memberStatuses = [
      memberEnum.STATUS.NEWBIE,
      memberEnum.STATUS.ROOKIE,
      memberEnum.STATUS.CHALLENGER,
      memberEnum.STATUS.ADVANCED,
      memberEnum.STATUS.VETERAN,
      memberEnum.STATUS.EXPERT,
      memberEnum.STATUS.CHAMPION,
      memberEnum.STATUS.MASTER,
      memberEnum.STATUS.GURU,
      memberEnum.STATUS.GURU_1,
      memberEnum.STATUS.GURU_2,
      memberEnum.STATUS.GURU_3,
      memberEnum.STATUS.GURU_4,
      memberEnum.STATUS.GURU_5,
      memberEnum.STATUS.GURU_6,
      memberEnum.STATUS.GURU_7,
      memberEnum.STATUS.GURU_8
    ];

    this.RestManager = RestManager;
    this.RouterService = RouterService;
    this.Toasts = Toasts;
    this.$element = $element;
    this.notificationAlert = notificationAlert;

    this.RouterService.activatedRoute
      .queryParams.pipe(
      delay(10),
      map(() => window.history.state),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      if(!params.event && this.RouterService.currentUrl.includes('/edit')){
        this.gotoLeaderBoard();
        return;
      }
      this.event = params.event || {};
      this.parseEvent(this.event, params);
    });
  }

  parseEvent(event, params = {}){
    if(params.event){
      //set the checked TeamsState
      for (let ms of this.memberStatuses){
        ms.checked = event.segmentation_member_statuses.find((sms)=>{return sms === ms.VALUE}) !== undefined;
      }

      let numberOfPrizeTypes = this.prizeTypes.length-1;
      for(let prizePosition in event.prizes){
        while(event.prizes[prizePosition].prizes.length < numberOfPrizeTypes){
          /* TODO enum prizeTypes */
          event.prizes[prizePosition].prizes.push({type: 'NONE', amount: null});
        }
      }
    }else{
      //new event
      event.prizes = {};
      event.status = challengeEnum.STATUSES.DRAFT.VALUE;
    }
  }

  parseResData(data){
    /* TODO enum challenge */
    for(let dateName of ['start_time', 'close_time', 'lock_time']){
      if(data[dateName]){
        data[dateName] = moment(data[dateName]).format('X');
      }
    }

    data.segmentation_member_statuses = [];
    for (let ms of this.memberStatuses){
      if(ms.checked){
        data.segmentation_member_statuses.push(ms.VALUE);
      }
    }

    for(let prizePosition in data.prizes){
      /* TODO enum prizeTypes */
      data.prizes[prizePosition].prizes = data.prizes[prizePosition].prizes.filter(prize => prize.type !== 'NONE');
      if(!data.prizes[prizePosition].prizes.length){
        delete data.prizes[prizePosition];
      }
    }
  }

  async save() {
    let resData = angular.copy(this.event);
    this.parseResData(resData);
    if (this.validate(resData)) {
      let res = await this.RestManager.setLbEvent(resData);
      if (!res.success) {
        //show error
        return;
      }
      this.gotoLeaderBoard();
    }
  }

  gotoLeaderBoard(){
    this.RouterService.go('admin/contentManager/events/leaderBoard');
  }

  validate(event) {
    if(Object.keys(event.prizes).length < 1){
      this.Toasts.message(`need to add at least 1 prize position`, this.$element);
      return false;
    }
    for(let prizePosition in event.prizes){
      if(event.prizes[prizePosition].prizes.length === 0){
        continue;
      }
      if(!isFieldValid(event.prizes[prizePosition].title)){
        this.Toasts.message(`prize title in  position ${prizePosition} need to be defined`, this.$element);
        return false;
      }
      for(let prize of event.prizes[prizePosition].prizes){
        if(!isNumber(prize.amount)){
          this.Toasts.message(`prize amount in  position ${prizePosition} , prize type ${prize.type} need to be defined`, this.$element);
          return false;
        }
        if(prize.amount <= 0){
          this.Toasts.message(`prize amount in  position ${prizePosition} , prize type ${prize.type} need to be greater than 0`, this.$element);
          return false;
        }
      }
    }

    /* TODO: enum challenge */
    let required_fields = ['goal_type', 'status', 'start_time', 'close_time', 'lock_time', 'title', 'subtitle',
      'description', 'lb_image_id', 'dialog_title', 'dialog_subtitle', 'intro_image_id', 'no_prize_title'];

    let number_fields = ['lb_max_members', 'cooldown_hours'];

    /* TODO: enum goal_types */
    if(['top_rank', 'level_up'].includes(event.goal_type)){
      required_fields.unshift('goal_param');
    }
    for (let required_field of required_fields) {
      if (!isFieldValid(event[required_field])) {
        this.Toasts.message(`${required_field}  need to be defined`, this.$element);
        return false;
      }
    }
    for (let number_field of number_fields) {
      if (!isNumber(event[number_field])) {
        this.Toasts.message(`${number_field}  need to be defined`, this.$element);
        return false;
      }
    }
    if(event.lb_max_members <= 0){
      this.Toasts.message(`lb_max_members need to be greater than 0`, this.$element);
      return false;
    }
    if(event.cooldown_hours < 0){
      this.Toasts.message(`cooldown_hours not valid`, this.$element);
      return false;
    }
    if(event.segmentation_member_statuses.length === 0){
      this.Toasts.message(`segmentation_member_statuses need to be defined`, this.$element);
      return false;
    }
    return true;
  }

  // generatePrize(){
  //   if(this.event.prizes[this.prizePosition]){
  //     this.notificationAlert.open('position already exist');
  //     return;
  //   }
  //   if(this.prizePosition > NUM_OF_POSITIONS){
  //     this.notificationAlert.open(`max position is ${NUM_OF_POSITIONS}`);
  //     return;
  //   }
  //
  //   let newPosition = {
  //     title:'',
  //     prizes:[]
  //   };
  //
  //   for (let i = 0; i < this.prizeTypes.length-1; i++) {
  //     newPosition.prizes.push({type:'NONE', amount:null});
  //   }
  //   this.event.prizes[this.prizePosition] = newPosition;
  // }

  generatePrize(){
    let prizesLength = Object.keys(this.event.prizes).length;
    if(prizesLength >= NUM_OF_POSITIONS){
      this.notificationAlert.open(`max position is ${NUM_OF_POSITIONS}`);
      return;
    }

    let newPosition = {
      title:'',
      prizes:[]
    };

    for (let i = 0; i < this.prizeTypes.length-1; i++) {
      /* TODO enum prizeTypes */
      newPosition.prizes.push({type:'NONE', amount:null});
    }

    this.event.prizes[prizesLength+1] = newPosition;
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
editLbEventCtrl.$inject = [
  'RestManager', 'RouterService', 'Toasts', '$element', 'notificationAlert'
];
export default {
  template: template,
  controller: editLbEventCtrl,
  bindings: {},
};
