const template = require(`html-loader!./badgeV2Exhibition.html`).default;

import badgeV2Mode from "../../../api/enums/badgeV2.enum";

class badgeV2ExhibitionController {
  constructor(

  ) {
    'ngInject';

    this.badgeV2Mode = badgeV2Mode;
  }
}

badgeV2ExhibitionController.$inject = [

];

export default {
  template: template,
  controller: badgeV2ExhibitionController,
  bindings: {
    mode: '<',
    name: '<',
    textLeft: '<',
    textRight: '<',
    previewSizeClass: '<',
    exhibitionMobileFlagsClass: '<'
  }
};
