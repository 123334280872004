import {delay, filter, Subject, takeUntil} from "rxjs";

const template = require(`html-loader!./teamsCreate.html`).default;
import TeamModel from "../../../api/models/team/team.model";
import teamEnum from '../../../api/enums/team.enum';
import CountryModel from '../../../api/models/team/country.model';
import LanguageModel from '../../../api/models/team/language.model';
import TeamStateModel from "../../../api/models/team/teamState.model";
import {ResourceType} from "../../../../../core/models/enums";
import {SectionType} from "../../../../../modules/store/models/enums";

class teamsCreateCtrl {
  subject = new Subject();
  constructor(
    $rootScope,
    $scope,
    RestManager,
    $timeout,
    Modals,
    stateService,
    $element,
    notificationAlert,
    Toasts,
    teamsService,
    gsScrollTopService,
    tagEventService,
    challengeService,
    RouterService,
    brazeService
  ) {
    'ngInject';
    this.busy = true;
    this.descriptionMax = 240;
    this.nameMax = 22;
    this.nameMin = 3;
    this.errors = {};
    this.countryLanguageChanged = false;
    this.noCountryItem = {code:'none', name:'No country'};
    this.noLanguageItem = {code:'none', name:'No language'};
    this.teamTypes = [
      teamEnum.TYPE.OPEN,
      teamEnum.TYPE.CLOSED
    ];

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.stateService = stateService;
    this.$element = $element;
    this.notificationAlert = notificationAlert;
    this.Toasts = Toasts;
    this.teamsService = teamsService;
    this.teamEnum = teamEnum;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.challengeService = challengeService;
    this.RouterService = RouterService;
    this.brazeService = brazeService;

    this.teamsService.getTeamsConfig$.pipe(
      filter(configData => !!configData),
      takeUntil(this.subject)
    ).subscribe(configData => {
      this.configData = configData;
    });

    this.teamsService.getTeams$.pipe(
      filter(res => !!res),
      takeUntil(this.subject)
    ).subscribe(res => {
      this.state = new TeamStateModel(res.state);
    });

    this.RouterService.activatedRoute
      .queryParams.pipe(
      delay(50),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      await this.init(params);
    });
  }

  $onInit() {
    this.gsScrollTopService.scrollTop();
  }

  async init(params = {}){
    let reqArr = [this.getCountries(), this.getLanguages()];
    if(this.RouterService.currentUrl.includes('teams/edit')) {
      this.tagEventService.sendPageViewEvent({title: 'Teams - edit'});
      if (!params.teamId) {
        this.RouterService.reloadRoute('teams');
        return;
      }
      this.editMode = true;
      reqArr.push(this.getTeam(params));
    } else {
      this.tagEventService.sendPageViewEvent({title: 'Teams - create'});
      this.team = { type: teamEnum.TYPE.OPEN.VALUE };
    }
    let isSuccessArr =  await Promise.all(reqArr);
    const success = isSuccessArr.every(isSuccess => isSuccess);
    if (!success) {
      this.stateService.goToDefaultState();
      return;
    }
    this.afterDatReceived();
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  afterDatReceived(){
    if(this.team?.id){
      this.team.country = this.team.country || new CountryModel(this.noCountryItem);
      this.team.language = this.team.language || new LanguageModel(this.noLanguageItem);
      return;
    }
    this.team.country = new CountryModel(this.noCountryItem);
    this.team.language = new LanguageModel(this.noLanguageItem);
  }


  async getCountries(){
    const res = await this.RestManager.getCountriesList();
    if (!res.success) {
      return false;
    }
    res.countries.unshift(this.noCountryItem);
    this.countries = res.countries.map(country => new CountryModel(country));

    return true;
  }

  async getLanguages(){
    const res = await this.RestManager.getLanguagesList();
    if (!res.success) {
      return false;
    }
    res.languages.unshift(this.noLanguageItem);
    this.languages = res.languages.map(language => new LanguageModel(language));
    return true;
  }

  async getTeam(params = {}){
    this.busy = true;
    const res = await this.RestManager.getTeam(params.teamId);
    if (!res.success || !res.data || !res.data.teams || !res.data.teams[0] || !res.state) {
      return false;
    }
    this.team = new TeamModel(res.data.teams[0]);
    this.orgTeam = angular.copy(this.team);
    return true;
  }

  async createTeam(){
    if(this.validate()){
      let resData = {
        description : this.team.description || '',
        icon_id : this.team.badge.icon_id,
        country_code : this.team.country.code,
        language_code : this.team.language.code,
        type : this.team.type,
      };
      console.error('team',this.team);
      console.error('resData',resData);
      let res;
      if(!this.editMode){
        if(this.challengeService.getKeysAmount() >= this.configData.keys_to_create) {
          resData.required_status = this.team.required_status;
          resData.name = this.team.name;
          res = await this.teamsService.createTeam(resData);
          if (!res.success && res.message === "No enough keys available") {
            this.challengeService.openStoreKeysSection();
            return;
          } else {
            this.challengeService.updateKeysAmount(-this.configData.keys_to_create);
          }
        } else {
          const outKeyEventParams = {outOfResourceId: 'out_key' + Date.now()};
          this.brazeService.logCustomEvent('out_key', outKeyEventParams);
          await this.brazeService.waitForBrazeMessage(this.openStoreKeysSection.bind(this), outKeyEventParams.outOfResourceId);
          return;
        }
      }else{
        resData.required_status = this.team.required_status;
        resData.name = this.team.name;
        resData.team_id = this.team.id;
        res = await this.RestManager.updateTeam(resData);
      }

      const resObj = this.teamsService.handleTeamsResponse(res);
      if(!resObj.isValid){
        this.showError(resObj.message);
        return;
      }
      this.stateService.goToState('teams/home/my-team');
    }
  }

  openStoreKeysSection() {
    this.challengeService.openStoreKeysSection();
  }

  async onNameChange() {
    if (this.editMode) {
      return;
    }

    this.nameMessage = '';
    this.errors.name = false;
    const resName = await this.RestManager.isTeamNameFree(this.team.name);

    if (!resName.success || resName.state.status === teamEnum.NAME_VALIDATION.INVALID) {
      this.errors.name = true;
      this.nameMessage = 'Team name already exists. Please choose different one.';
    } else {
      this.nameMessage = 'Team name is available';
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  validate(){
    if(this.team.name && this.team.name.length && this.errors.name){
      return false;
    }
    this.errors = {};
    const requiredFields = [
      {name:'Name', value:"name"},
      {name:'Team Level', value:"required_status"},
      {name:'Team Badge', value:"badge"},
      {name:'Team Type', value:"type"}
    ];
    for (let required_field of requiredFields) {
      if (!this.team[required_field.value] && this.team[required_field.value] !== 0) {
        this.errors[required_field.value] = true;
        this.showError(`${required_field.name} is required`);
        return false;
      }
    }
    if(this.team.name.length < this.nameMin){
      this.showError(`Team name min length is ${this.nameMin} chars`);
      return false;
    }
    return true;
  }

  showError(text){
    // this.errorText = text;
    this.Toasts.message(text, this.$element, true, 'bottom right');
  }

  chooseBadge(){
    this.Modals.open('teamBadges', null, {team:this.team});
  }

  levelChanged(){
    //console.log(this.team);
  }
  isCountryLanguageChanged(){
    this.countryLanguageChanged = true;
  }

  isDisabled(){
    let isEmpty = !this.team?.badge && !this.team?.name && !this.team?.description && !this.team?.required_status;
    if (this.editMode && this.team){
      const isChanged =
        this.team.badge.icon_id !== this.orgTeam.badge.icon_id ||
        this.team.description !== this.orgTeam.description ||
        this.team.type !== this.orgTeam.type ||
        this.team.required_status !== this.orgTeam.required_status ||
        this.countryLanguageChanged;
      return isEmpty || !isChanged;
    }

    return isEmpty;
  }

  cancel(){
    this.stateService.goToState(this.editMode ? 'teams/my-team' : 'teams/join');
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
teamsCreateCtrl.$inject = [
  '$rootScope',
  '$scope',
  'RestManager',
  '$timeout',
  'Modals',
  'stateService',
  '$element',
  'notificationAlert',
  'Toasts',
  'teamsService',
  'gsScrollTopService',
  'tagEventService',
  'challengeService',
  'RouterService',
  'brazeService',
];

export default {
  template: template,
  controller: teamsCreateCtrl
};
