const badgeBattleTemplate = require(`html-loader!./badgeV2Battle.html`).default;

class badgeV2Battle {
  constructor(

  ) {
    'ngInject';

  }
}

badgeV2Battle.$inject = [

];

export default {
  template: badgeBattleTemplate,
  controller: badgeV2Battle,
  bindings: {}
};
