const template = require(`html-loader!./activityFeed.html`).default;

class activityFeed {

  constructor(MemberService) {
    'ngInject';
    this.member = MemberService.getCurrentMember();
  }

}
activityFeed.$inject = [
  'MemberService',
];
export default {
  template: template,
  controller: activityFeed,
  transclude: true,
  bindings: {
    items: '<',
    tools: '<',
  },
};
