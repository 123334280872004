import { Injectable } from '@angular/core';

@Injectable()
class ObjectHelper {
  constructor() {
  }

  public static clearObjectHelper(obj: any): any {
    Object.entries(obj)
      .forEach(([key, val]) => {
        if (val && typeof val === 'object') {
          this.clearObjectHelper(val);
        } else if (val === null || val === undefined) {
          delete obj[key];
        }
      });

    return obj;
  }

  private static buildURLSearchParamsData(urlSearchParams:URLSearchParams, data:any, parentKey?:any) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
      Object.keys(data).forEach(key => {
        this.buildURLSearchParamsData(urlSearchParams, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;

      urlSearchParams.append(parentKey, value);
    }
  }

  public static jsonToURLSearchParams(data:object) {
    const uRLSearchParams = new URLSearchParams()
    this.buildURLSearchParamsData(uRLSearchParams, data);
    return uRLSearchParams;
  }

  public static deepMerge(obj1:any, obj2:any) {
    const result = { ...obj1 };

    for (let key in obj2) {
      if (obj2.hasOwnProperty(key)) {
        if (obj2[key] instanceof Object && obj1[key] instanceof Object) {
          result[key] = this.deepMerge(obj1[key], obj2[key]);
        } else {
          result[key] = obj2[key];
        }
      }
    }

    return result;
  }

}

export default ObjectHelper;
