import challengeEnum from "../../../../api/enums/challenge.enum";

const template = require(`html-loader!./badgeV2ExhibitionPreview.html`).default;

class badgeV2ExhibitionPreviewController {
  constructor(

  ) {
    'ngInject';
  }

  $onInit() {
    this.className = `badge-preview__${this.name}`;
    if(this.name === challengeEnum.TYPE.FLASH){
      this.iconClassName = 'icon-flash-challenge';
    }else{

      this.iconClassName = this.name === 'exhibition' ? `icon-badge-${this.name}` : `icon-badge-${this.name}-exhibition`;
    }
  }
}

badgeV2ExhibitionPreviewController.$inject = [

];

export default {
  template: template,
  controller: badgeV2ExhibitionPreviewController,
  bindings: {
    name: '<'
  }
};
