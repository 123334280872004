import {Subject, takeUntil, filter} from "rxjs";

const template = require(`html-loader!./winners.html`).default;

class challengeWinnersCtrl {
  subject = new Subject();
  constructor(
    NgChallengesService
  ) {
    'ngInject';
    this.NgChallengesService = NgChallengesService;
  }

  $onInit() {
    this.NgChallengesService.currentChallenge$.pipe(
      filter(challenge => challenge !== undefined),
      takeUntil(this.subject)
    ).subscribe(challenge => {
      this.challenge = challenge;
    });
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
challengeWinnersCtrl.$inject = [
  'NgChallengesService'
];

export default {
  template: template,
  controller: challengeWinnersCtrl
};
