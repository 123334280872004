class AuthenticationManager {
  constructor(
    api,
    $http,
    $rootScope
  ) {
    'ngInject';
    this.api = api;
    this.$http = $http;
    this.$rootScope = $rootScope;
  }

  facebookRenewToken() {
    return this.api['auth']['facebookRenewToken']();
  }

  signUp(login, password, token) {
    return this.api['auth']['signUp']({login, password, token});
  }

  signIn(login, password) {
    return this.api['auth']['signIn']({login, password});
  }

  forgotPassword(email) {
    return this.api['auth']['forgotPassword']({email});
  }

  facebook() {
    return this.api['auth']['facebook']();
  }

  getCurrentMemberToken() {
    return this.api['rest']['getCurrentMemberToken']();
  }

  deactivateAccount() {
    return this.api['rest']['deactivateAccount']();
  }

  async getAccessToken(){
    const res = await this.api['authentication']['getAccessToken']();
    return res && res.data && res.data.token ? res.data.token : null;
  }
}

AuthenticationManager.$inject = [
  'api',
  '$http',
  '$rootScope'
];

export default AuthenticationManager;
