class ChatMemberModel {
  constructor({ user_name, id, name, avatar, country, member_status_name }) {
    Object.assign(this, {
      user_name,
      id,
      name,
      avatar,
      country,
      member_status_name,
    });
  }

  get displayName() {
    return this.name || `@${this.user_name}`;
  }
}

export default ChatMemberModel;
