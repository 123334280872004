import { Component } from '@angular/core';

@Component({
  selector: 'app-terms-page-wrapper',
  templateUrl: './terms-page-wrapper.component.html',
  styleUrl: './terms-page-wrapper.component.scss'
})
export class TermsPageWrapperComponent {

}
