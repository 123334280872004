class challengePageService {
  _challenge;

  constructor() {
    'ngInject';
  }

  set challenge (challenge){
    this._challenge = challenge;
  }

  getRankTabs(url, challenge){
    let tabs = [];
    if (challenge?.top_photographer_enable) {
      tabs.push({
        NAME: 'Top Photographer',
        STATE: `challenge/${url}/rank/top-photographer`
      })
    }
    if (challenge?.top_photo_enable) {
      tabs.push({
        NAME: 'Top Photo',
        STATE: `challenge/${url}/rank/top-photo`
      })
    }
    if (challenge?.guru_pick_enable) {
      tabs.push({
        NAME: `Guru's Top Pick`,
        STATE: `challenge/${url}/rank/gurus-top-pick`
      })
    }
    return tabs;
  }
}
challengePageService.$inject = [];
export default challengePageService;
