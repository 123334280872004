import {Component, Inject, ViewChild} from '@angular/core';
import {MaterialDialogContentDirective} from "./material-dialog-content.directive";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {CloseModalType} from "../../../store/models/enums";
import {GsModalDataGeneralType} from "../../models/types";

@Component({
  selector: 'lib-material-dialog',
  templateUrl: './material-dialog.component.html',
  styleUrls: ['./material-dialog.component.scss']
})
export class MaterialDialogComponent {
  @ViewChild(MaterialDialogContentDirective, {static: true}) modalContent!: MaterialDialogContentDirective;
  CloseModalType = CloseModalType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GsModalDataGeneralType
  ) {
  }

  ngOnInit() {
    const viewContainerRef = this.modalContent.viewContainerRef;
    viewContainerRef.clear();

    const componentRef: any = viewContainerRef.createComponent(this.data.componentRef);
    componentRef.instance.data = this.data;
  }
}
