import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {BankrollItem, BankrollService} from "../../bankroll.service";
import {
  animationFrameScheduler,
  BehaviorSubject,
  distinctUntilChanged,
  endWith,
  interval,
  switchMap,
  takeWhile
} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-battle-bankroll-item',
  templateUrl: './battle-bankroll-item.component.html',
  styleUrls: ['./battle-bankroll-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BattleBankrollItemComponent {
  amountSubject$ = new BehaviorSubject(0);
  previousCoinsAmount = -1;
  @Input() bankrollItem?: BankrollItem;
  @Input() duration = 1200;
  @Input() set amount(value: number) {
    this.previousCoinsAmount = this.amountSubject$.getValue();
    this.amountSubject$.next(value);
  }
  amount$ = this.amountSubject$.pipe(
    switchMap((count) => {
      const duration = this.previousCoinsAmount === -1 ? 0 : this.duration;
      const startTime = animationFrameScheduler.now();
      return interval(10, animationFrameScheduler).pipe(
        map(() => animationFrameScheduler.now() - startTime),
        map((elapsedTime) => elapsedTime / duration),
        takeWhile((progress) => progress <= 1),
        map((progress) =>
          Math.round(this.previousCoinsAmount + progress * (count - this.previousCoinsAmount))
        ),
        endWith(count),
        distinctUntilChanged()
      );
    })
  )
  constructor(
    private bankrollService: BankrollService,
  ) {}
  openStore() {
    this.bankrollService.onBankrollItemClick(this.bankrollItem!);
  }
}
