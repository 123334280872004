export enum MissionsState {
  MISSIONS_AVAILABLE = 'MISSIONS_AVAILABLE',
  MISSIONS_UNAVAILABLE = 'MISSIONS_UNAVAILABLE'
}

export enum MissionClaimState{
  DISABLED='DISABLED',
  CLAIM='CLAIM',
  CLAIMED='CLAIMED',
}
