// This Angular directive will act as an interface to the "upgraded" AngularJS component
import {Directive, ElementRef, Injector} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {ScopeProvider} from "./ScopeProvider";

@Directive({
  providers: [ ScopeProvider ],
  selector: 'ng1-exhibitions'
})
export class ng1ExhibitionsComponent extends UpgradeComponent {
  constructor(
    elementRef: ElementRef,
    injector: Injector
  ) {
    // We must pass the name of the directive as used by AngularJS to the super
    super('exhibitions', elementRef, injector);
  }
}
