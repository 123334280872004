import MemberModel from '../member.model';
import ExifDataModel from './exifData.model';
import {AchievementModel} from "../achievement/achievement.model";

import environment from '../../../gsApp.environment';

class ImageModel {
  constructor(props) {
    this.achievement = props.achievement;
    this.achievements = props.achievements ? props.achievements.map(achievement => new AchievementModel(achievement)) : [];
    this.adult = props.adult;
    this.boosted = props.boosted;
    this.boosting = props.boosting;
    this.comments = props.comments || [];
    this.description = props.description;
    this.e_id = props.e_id;
    this.event_id = props.event_id;
    this.guru_pick = props.guru_pick;
    this.guru_picks = props.guru_picks;
    this.id = props.id;
    this.labels = props.labels || [];
    this.liked = props.liked;
    this.likes = props.likes;
    this.member = new MemberModel(props.member || {});
    this.member_id = props.member_id;
    this.meta_data = new ExifDataModel(props.meta_data || {});
    this.orientation = props.orientation;
    this.permission = props.permission;
    this.rank = props.rank;
    this.ratio = props.ratio;
    this.reason_id = props.reason_id;
    this.reason_text = props.reason_text;
    this.reported = props.reported;
    this.scope = props.scope;
    this.selected_expo = props.selected_expo;
    this.views = props.views;
    this.title = props.title;
    this.voted = props.voted;
    this.votes = props.votes;
    this.image_url = props.image_url || props.id;
    this.winner = props.winner;
    this.digital = props.digital;
    this.print = props.print;
    this.show_indicator = props.show_indicator;
    this.token = props.token;
    this.width = props.width;
    this.height = props.height;
    this.turbo = props.turbo;
  }

  src(width = 0, height = 0) {
    return `${this.gePhotoDomain()}/unsafe/${width}x${height}/${this.member_id}/3_${this.id}.jpg`;
  }

  srcToken(width = 0, height = 0) {
    return `${this.gePhotoDomain()}/unsafe/${width}x${height}/${this.token}`;
  }

  get pbLarge() {
    return `${this.gePhotoDomain()}/unsafe/0x0/${this.member_id}/3_${this.id}.jpg`;
  }

  gePhotoDomain(){
    return 'https://' + environment.domains.photos;
  }

  get shareUrl() {
    return `https://${window.location.host}/photo/${this.id}`;
  }

  get noExifData() {
    return !(
      this.meta_data.make ||
      this.meta_data.model ||
      this.meta_data.lens ||
      this.meta_data.f_number ||
      this.meta_data.exposure_time ||
      this.meta_data.focal_length ||
      this.meta_data.iso ||
      this.meta_data.post_processing
    );
  }

  get alt() {
    return this.labels.join(', ');
  }
}

export default ImageModel;
