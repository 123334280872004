import {Component, Inject} from '@angular/core';
import {topTabMenuData} from "../../../../gsApp/app/components/topTabsMenu/topTabMenu.data";
import {SessionConfigService} from "../../../../core/services/session-config.service";
import {JoinedChallengesPageService} from "../../services/joined-challenges-page.service";
import mixpanelEventsEnum from "../../../../gsApp/app/services/mixpanel/mixpanel.enum";

@Component({
  selector: 'app-challenges-navigation',
  templateUrl: './challenges-navigation.component.html',
  styleUrl: './challenges-navigation.component.scss'
})
export class ChallengesNavigationComponent {
  public tabs = topTabMenuData.TYPE.CHALLENGES.TABS as any[];
  public updateIndicator = false;
  constructor(
    private sessionConfigService: SessionConfigService,
    private joinedChallengesPageService: JoinedChallengesPageService,
    @Inject('mixpanelService') private mixpanelService: any
  ) {
  }

  async isNewCompletedChallenges(){
    const res = await this.joinedChallengesPageService.isNewCompletedChallenges();
    if (res.success) {
      this.updateIndicator = res.is_new as boolean;
    }
  }

  async tabClicked(tab: any) {
    if (tab.TITLE === 'Completed') {
      this.updateIndicator = false;
    }
    await this.isNewCompletedChallenges();
    this.eventMixPanelAction(tab);
  }

  isTabPermitted(tabPermissions: any[]){
    if (!this.sessionConfigService.permissions) { return false; }
    for (const permissionName of tabPermissions) {
      // @ts-ignore
      if (this.sessionConfigService.permissions[permissionName]) {
        return true;
      }
    }
    return false;
  }

  eventMixPanelAction(tab: any) {
    tab = tab.TITLE.toLowerCase();
    let data = {
      ui_category: mixpanelEventsEnum.UI_CATEGORY.TOP_SECONDARY_NAV,
      ui_name: tab
    };

    if (tab === 'completed') {
      // @ts-ignore
      data['ui_notification'] = this.updateIndicator ? 'active': null;
    }

    this.mixpanelService.track({data});
  }
}
