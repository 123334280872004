export const successfulSwapConf = {
  controller: () => {},
  controllerAs: 'toast',
  template: `<toast-successful-swap challenge="toast.challenge">
             </toast-successful-swap>`,
  autoWrap: false,
  hideDelay: 0,
  position: 'bottom right',
  parent: '',
  bindToController: true
};
