import config from './activity.config'
import activity from './activity/activity'
import activityFeed from './activity/activityFeed/activityFeed'
import activitySide from './activity/activitySide/activitySide'

let moduleActivity = angular
  .module('gsApp.activity', [])
  .config(config)
  .component('activity', activity)
  .component('activityFeed', activityFeed)
  .component('activitySide', activitySide);
export default moduleActivity.name;
