<!--<mat-form-field (mouseover)="openSelect()" (mouseleave)="matSelect.close()">-->
<div class="gs-d-f gs-g-6">
  <div class="sort-button">
    <mat-form-field>
      <mat-label>Choose an option</mat-label>
      <mat-select
        [(value)]="selected"
        (selectionChange)="filterChanged($event)"
      >
        @for (option of config.sortOptions; track option) {
          <mat-option [value]="option.value">{{option.viewValue}}</mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
  <div
    class="order-button gs-center-flex gs-c-p"
    [title]="config.isAscendingOrder ? 'ascending' : 'descending'"
    (click)="orderClicked()"
  >
    <img [src]="'/assets/images/sort-order/' +
    (config.isAscendingOrder ? 'ascending' : 'descending') +
    '.svg'">
  </div>
</div>


