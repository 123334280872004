export class ImageHelper {
  public static async preloadImages(imagesSrc:string[]) {
    await Promise.all(imagesSrc.map(imageSrc => ImageHelper.preloadImage(imageSrc)))
  }
  private static preloadImage(src:string){
    return new Promise((resolve) => {
      const image = new Image()
      image.onload = resolve
      image.onerror = resolve
      image.src = src
    });
  }
}
