class broadcastCtrl {
  /**
   * @param {ChallengesManager} ChallengesManager
   * @param {RestManager} RestManager
   * @param {NotificationsManager} NotificationsManager
   * @param {brazeService} brazeService
   */

  constructor(
    $mdDialog,
    data,
    Facebook,
    $timeout,
    ChallengesManager,
    RestManager,
    Toasts,
    Modals,
    NotificationsManager,
    $scope,
    $rootScope,
    brazeService,
    MemberService
  ) {
    'ngInject';
    this.busy = true;
    this.stage = 1;

    this.$mdDialog = $mdDialog;
    this.data = data;
    this.Facebook = Facebook;
    this.$timeout = $timeout;
    this.ChallengesManager = ChallengesManager;
    this.RestManager = RestManager;
    this.Toasts = Toasts;
    this.Modals = Modals;
    this.NotificationsManager = NotificationsManager;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.brazeService = brazeService;
    this.member = MemberService.getCurrentMember();

    this.facebookConnected = this.member.facebook_connected;
  }

  hide() {
    //Set read
    if (this.data.date) {
      this.NotificationsManager.readNotifications(this.data.date, 'activity');
    }

    this.$mdDialog.hide();
  }

  setRead() {
    return true;
  }

  link() {
    this.Facebook.login();
  }

  moreDetails(event, name) {
    this.Toasts.open(name, {}, $('.c-modal-broadcast--type12')[0]);
  }

  //New
  challengePay(event) {
    //Tmp fix for book challenge
    if (parseInt(this.data.value) === 21) {
      this.data.c_id = 1520;
    }

    this.Modals.open('shop.exhibition', event, {
      name: 'shop.' + this.data.type,
      c_id: this.data.c_id,
      assets_id: this.data.value,
    });
  }

  //Old
  payExhibition(event, c_id) {
    this.Modals.open('shop.exhibition', event, { name: 'shop.exhibition', c_id: c_id });
  }
}
broadcastCtrl.$inject = [
  '$mdDialog',
  'data',
  'Facebook',
  '$timeout',
  'ChallengesManager',
  'RestManager',
  'Toasts',
  'Modals',
  'NotificationsManager',
  '$scope',
  '$rootScope',
  'brazeService',
  'MemberService',
];
export default broadcastCtrl;
