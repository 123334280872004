const template = require(`html-loader!./gsChallengeNewCompleted.html`).default;
import resolutionEnum from "../../../api/enums/resolution.enum";
import badgeV2Mode from "../../../api/enums/badgeV2.enum";

class gsChallengeNewCompletedCtrl {
  constructor(
    $scope,
    $rootScope
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.badgeV2Mode = badgeV2Mode;
  }

  $onInit() {
    this.isMobile = document.body.clientWidth <= resolutionEnum.WIDTH.MOBILE_HEADER;
  }
}

gsChallengeNewCompletedCtrl.$inject = [
  '$scope',
  '$rootScope'
];

export default {
  template: template,
  controller: gsChallengeNewCompletedCtrl,
  bindings: {
    challenge: '<',
  }
};
