function config($stateProvider, $urlRouterProvider) {
  "ngInject";

  $stateProvider
    .state('gs.ai', {
      url: '/ai',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {component: 'aiPage'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.ai.terms', {
      url: '/terms',
      component: 'appAiTerms'
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
