const template = require(`html-loader!./leagueTimer.html`).default;
import leagueEnum from "../../../api/enums/league.enum";

class leagueTimerCtrl {
  constructor() {
    'ngInject';
    this.isFinalizing = false;
    this.leagueEnum = leagueEnum;
  }

  onTimerFinished(){
    this.isFinalizing = true;
  }

  $onChanges(changes) {
    if (changes.date && changes.date.currentValue) {
      this.date = parseInt(changes.date.currentValue);
    }
  }
}


export default {
  template: template,
  controller: leagueTimerCtrl,
  bindings: {
    date: '@',
    leagueRank: '<'
  }
};
