import { Component } from '@angular/core';

@Component({
  selector: 'app-article-edit-wrapper',
  templateUrl: './article-edit-wrapper.component.html',
  styleUrl: './article-edit-wrapper.component.scss'
})
export class ArticleEditWrapperComponent {

}
