class achievementsService {

  constructor() {
    'ngInject';
    this._achievementCategories = null;
  }

  set achievementCategories(achievementCategories){
    this._achievementCategories = achievementCategories;
  }

  get achievementCategories(){
    return this._achievementCategories;
  }

  set achievementsInfoHtml(achievementsInfoHtml){
    this._achievementsInfoHtml = achievementsInfoHtml;
  }

  get achievementsInfoHtml(){
    return this._achievementsInfoHtml;
  }

  hideAllAchievementsDetails(){
    this._achievementCategories.forEach((achievementCategory) => {
      achievementCategory.achievements.forEach((achievement) => {
        achievement.showAchievementsDetails = false;
        return achievement;
      });
    });
  }

  hideAllCategoryAchievements(){
    this._achievementCategories.forEach((achievementCategory) => {
      achievementCategory.category.showAchievements = false;
    });
  }
}

export default achievementsService;
