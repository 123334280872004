import {Component, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {Subject} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-challenge-wrapper',
  templateUrl: './challenge-wrapper.component.html',
  styleUrl: './challenge-wrapper.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ChallengeWrapperComponent {
  cUrl = null;
  subject = new Subject();

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.params.pipe(
        takeUntilDestroyed()
    ).subscribe(async (params: any) => {
      this.cUrl = params.cUrl;
    });
  }
}
