const template = require(`html-loader!./offTopicFilter.html`).default;

class offTopicFilterCtrl {
  /**
   * @param {ChallengesManager} ChallengesManager
   * @param {ModalsService} Modals
   * */
  constructor(
    $element,
    ChallengesManager
  ) {
    'ngInject';

    this.reported = false;

    this.$element = $element;
    this.ChallengesManager = ChallengesManager;
  }

  like() {
    this.report(true);
    this.reported = true;
  }

  unLike() {
    this.report(false);
    this.$element.remove();
  }

  report(up) {
    this.ChallengesManager.reportImage({
      reason_id: 2,
      c_id: this.challenge.id,
      value: up ? 1 : -1,
      token: this.image.token,
      id: this.image.id,
    });
  }
}

offTopicFilterCtrl.$inject = [
  '$element',
  'ChallengesManager'
];

export default {
  template: template,
  controller: offTopicFilterCtrl,
  bindings: {
    image: '<',
    challenge: '<'
  }
};
