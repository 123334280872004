//region menu
import adminMenu from './adminMenu/adminMenu';
import adminSubMenu from './adminSubMenu/adminSubMenu';
//endregion
//region member manager
import memberManager from './memberManager/memberManager';
//endregion
//region teams manager
import teamsManager from './teamsManager/teamsManager';
//endregion
//region content managers
import contentManager from './contentManager/contentManager';
import adminSpotlight from './contentManager/adminSpotlight/adminSpotlight';
import editSpotlight from './contentManager/adminSpotlight/editSpotlight/editSpotlight';
import adminCustomCard from './contentManager/adminCustomCard/adminCustomCard';
//endregion
//region challenges
import adminChallenges from './contentManager/adminChallenges/adminChallenges';
import challengesSchedule from './contentManager/adminChallenges/challengesSchedule/challengesSchedule';
import challengeTemplates from './contentManager/adminChallenges/challengeTemplates/challengeTemplates';
import bulkUpload from './contentManager/adminChallenges/bulkUpload/bulkUpload';
import editChallengeTemplate from './contentManager/adminChallenges/challengeTemplates/editChallengeTemplate/editChallengeTemplate';
import challengesWinners from './contentManager/adminChallenges/challengesWinners/challengesWinners';
import challengesCalendar from './contentManager/adminChallenges/challengesCalendar/challengesCalendar';
import challengesAutoSubmit from './contentManager/adminChallenges/challengesAutoSubmit/challengesAutoSubmit';
import challengesNotificationTimes from './contentManager/adminChallenges/challengesNotificationTimes/challengesNotificationTimes';
import challengesPlannedTimings from './contentManager/adminChallenges/challengesPlannedTimings/challengesPlannedTimings';
import challengesRankingLevels from "./contentManager/adminChallenges/challengesRankingLevels/challengesRankingLevels";
//endregion
//region exhibitions
import adminExhibitions from './contentManager/adminExhibitions/adminExhibitions';
import adminExhibitionsSelected from './contentManager/adminExhibitions/adminExhibitionsSelected/adminExhibitionsSelected';
import adminExhibitionsPage from './contentManager/adminExhibitions/adminExhibitionsPage/adminExhibitionsPage';
import adminExhibitionsEdit from './contentManager/adminExhibitions/adminExhibitionsEdit/adminExhibitionsEdit';
import adminExhibitionsManager from './contentManager/adminExhibitions/adminExhibitionsManager/adminExhibitionsManager';
import adminExhibitionsManagerItem from './contentManager/adminExhibitions/adminExhibitionsManager/adminExhibitionsManagerItem/adminExhibitionsManagerItem';
import adminExhibitionsVirtual from './contentManager/adminExhibitions/adminExhibitionsVirtual/adminExhibitionsVirtual';
import editVirtualExhibition from './contentManager/adminExhibitions/adminExhibitionsVirtual/editVirtualExhibition/editVirtualExhibition';
//endregion
//region widgets
import adminWidget from './contentManager/adminWidget/adminWidget';
//endregion
//region adminDailyRewards
import adminDailyRewards from './contentManager/adminDailyRewards/adminDailyRewards';
import adminDailyRewardsMissions from './contentManager/adminDailyRewards/adminDailyRewardsMissions/adminDailyRewardsMissions';
import editDailyMission from './contentManager/adminDailyRewards/editDailyMission/editDailyMission';
import editDailyPrize from './contentManager/adminDailyRewards/editDailyPrize/editDailyPrize';
import adminDailyRewardsPrizes from './contentManager/adminDailyRewards/adminDailyRewardsPrizes/adminDailyRewardsPrizes';
//endregion
//region adminRewardedVideo
import adminRewardedVideo from './contentManager/adminRewardedVideo/adminRewardedVideo';
//endregion
//region adminLiveops
import adminLiveops from './contentManager/adminLiveops/adminLiveops';
import adminLiveopsSuggest from './contentManager/adminLiveops/adminLiveopsSuggest/adminLiveopsSuggest';
//endregion
//region adminEvents
import adminEvents from './contentManager/adminEvents/adminEvents';
import adminEventsLeaderBoard from './contentManager/adminEvents/adminEventsLeaderBoard/adminEventsLeaderBoard';
import editLbEvent from './contentManager/adminEvents/editLbEvent/editLbEvent';
//endregion
//region chests
import adminChests from './contentManager/adminChests/adminChests';
//endregion
//region Teams
import adminTeams from './contentManager/adminTeams/adminTeams';
import adminLeagues from './contentManager/adminTeams/adminLeagues/adminLeagues';
import adminEditLeagues from './contentManager/adminTeams/adminLeagues/adminEditLeagues/adminEditLeagues';
import adminSeasons from './contentManager/adminTeams/adminSeasons/adminSeasons';
//endregion
//region Sponsors
import challengesSponsors from "./contentManager/adminChallenges/challengesSponsors/challengesSponsors";
import challengesSponsorsEdit
  from "../../components/challengesSponsorsEdit/challengesSponsorsEdit";
//endregion
import gsAdmin from './gsAdmin.page.ctrl';

let moduleGsAdminPage = angular
  .module('gsApp.gsAdminPage', [])
  .component('gsAdmin', gsAdmin)
  .component('adminMenu', adminMenu)
  .component('adminSubMenu', adminSubMenu)
  .component('contentManager', contentManager)
  .component('memberManager', memberManager)
  .component('adminSpotlight', adminSpotlight)
  .component('editSpotlight', editSpotlight)
  .component('adminCustomCard', adminCustomCard)
  .component('adminChallenges', adminChallenges)
  .component('challengesSchedule', challengesSchedule)
  .component('challengesWinners', challengesWinners)
  .component('challengesCalendar', challengesCalendar)
  .component('challengesAutoSubmit', challengesAutoSubmit)
  .component('challengesNotificationTimes', challengesNotificationTimes)
  .component('adminExhibitions', adminExhibitions)
  .component('adminExhibitionsSelected', adminExhibitionsSelected)
  .component('adminExhibitionsPage', adminExhibitionsPage)
  .component('adminExhibitionsEdit', adminExhibitionsEdit)
  .component('adminExhibitionsManager', adminExhibitionsManager)
  .component('adminExhibitionsManagerItem', adminExhibitionsManagerItem)
  .component('adminExhibitionsVirtual', adminExhibitionsVirtual)
  .component('editVirtualExhibition', editVirtualExhibition)
  .component('adminDailyRewards', adminDailyRewards)
  .component('adminDailyRewardsMissions', adminDailyRewardsMissions)
  .component('adminDailyRewardsPrizes', adminDailyRewardsPrizes)
  .component('editDailyMission', editDailyMission)
  .component('editDailyPrize', editDailyPrize)
  .component('adminRewardedVideo', adminRewardedVideo)
  .component('adminLiveops', adminLiveops)
  .component('adminLiveopsSuggest', adminLiveopsSuggest)
  .component('adminEvents', adminEvents)
  .component('adminEventsLeaderBoard', adminEventsLeaderBoard)
  .component('editLbEvent', editLbEvent)
  .component('adminWidget', adminWidget)
  .component('adminChests', adminChests)
  .component('adminTeams', adminTeams)
  .component('adminLeagues', adminLeagues)
  .component('adminEditLeagues', adminEditLeagues)
  .component('adminSeasons', adminSeasons)
  .component('teamsManager', teamsManager)
  .component('challengeTemplates', challengeTemplates)
  .component('bulkUpload', bulkUpload)
  .component('editChallengeTemplate', editChallengeTemplate)
  .component('challengesPlannedTimings', challengesPlannedTimings)
  .component('challengesRankingLevels', challengesRankingLevels)
  .component('challengesSponsors', challengesSponsors)
  .component('challengesSponsorsEdit', challengesSponsorsEdit)
;
export default moduleGsAdminPage.name;
