import {Component} from '@angular/core';
import {RouterService} from "../../core/services/router.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-header-wrapper',
  templateUrl: './header-wrapper.component.html',
  styleUrl: './header-wrapper.component.scss'
})
export class HeaderWrapperComponent {
  public displayHeader = false;
  constructor(
    private routerService: RouterService
  ) {
    this.routerService.routerEvents.pipe(
      takeUntilDestroyed()
    ).subscribe((event: {displayHeader: boolean}) => {
      this.displayHeader = event.displayHeader;
    });
  }
}
