const template = require(`html-loader!./achievementsInfo.html`).default;

class achievementsInfoCtrl {
  constructor(
    achievementsService
  ) {
    'ngInject';

    this.achievementsService = achievementsService;
  }
}

achievementsInfoCtrl.$inject = [
  'achievementsService'
];

export default {
  template: template,
  controller: achievementsInfoCtrl,
  bindings: {}
};
