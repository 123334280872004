import { Component } from '@angular/core';

@Component({
  selector: 'app-dl-profile-upload-wrapper',
  templateUrl: './dl-profile-upload-wrapper.component.html',
  styleUrl: './dl-profile-upload-wrapper.component.scss'
})
export class DlProfileUploadWrapperComponent {
  state = {
    dlState: 'dl/profileUpload',
    redirectState: 'profile/photos',
    type: 'profileUpload'
  }
}
