class ChestAttributesModel {
  constructor(props) {
    this.animation = props.animation;
    this.animation_delay = props.animation_delay;
    this.chest_id = props.chest_id;
    this.description = props.description;
    this.icon = props.icon;
    this.member_chest_type = props.member_chest_type;
    this.name = props.name;
    this.type = props.type;
    this.domain = props.domain;
  }
}

export default ChestAttributesModel;
