const resolutionEnum = Object.freeze({
  WIDTH: {
    MOBILE_HEADER: 960,
    MOBILE_CHALLENGE_ITEM: 1200,
    MOBILE_LEADERBOARD_ITEM: 1200,
    MOBILE_ACHIEVEMENTS_CATEGORY: 1200,
  }
});

export default resolutionEnum;
