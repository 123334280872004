const template = require(`html-loader!./teamsIntro.html`).default;

import TeamsIntroModel from './teamsIntro.model';
import LocalStorageEnum from '../../api/enums/localStorage.enum';

class teamsIntroController {
  constructor(
    $timeout,
    $element,
    $window,
    $rootScope,
    localStorageService
  ) {
    'ngInject';

    this.lockedStatus = false;
    this.vimeoVideoId = 481128091;
    this.youtubeVideoId = 'Lzp1jfkEoQA';
    this.isDisplayed = true;

    this.timeout = $timeout;
    this.element = $element;
    this.window = $window;
    this.rootScope = $rootScope;
    this.localStorageService = localStorageService;
  }

  $onInit() {
    this.getStoredData();
    console.log('service saveToClientSideService');
  }

  getStoredData() {
    let teamIntroData = this.localStorageService.getDataByKey({key:LocalStorageEnum.KEY.TEAM_INTRO});
    if (teamIntroData) {
      this.isDisplayed = teamIntroData.isDisplayed;
    }
  }

  hide() {
    this.isDisplayed = false;

    this.animateHide();

    this.localStorageService.save({
      key: LocalStorageEnum.KEY.TEAM_INTRO,
      value: new TeamsIntroModel({isDisplay: this.isDisplayed})
    });
  }

  animateHide() {
    angular.element(document.querySelector('.teams-intro')).addClass('teams-intro--hide');
    this.timeout(() => {
      angular.element(document.querySelector('.teams-intro')).addClass('teams-intro--hide--padding');
    }, 150);
    this.timeout(() => {
      angular.element(document.querySelector('teams-intro')).remove();
    }, 1000);
  }
}

teamsIntroController.$inject = [
  '$timeout',
  '$element',
  '$window',
  '$rootScope',
  'localStorageService'
];

export default {
  template: template,
  controller: teamsIntroController,
  bindings: {
    lockedStatus: '<'
  }
};
