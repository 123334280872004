const template = require(`html-loader!./memberStatus.html`).default;

class memberStatusCtrl {
  constructor(

  ) {
    'ngInject';
  }
}

memberStatusCtrl.$inject = [

];

export default {
  template: template,
  controller: memberStatusCtrl,
  bindings: {
    member: '<'
  }
};
