import RestrictedMemberService from "../../services/restrictedMember.service";

const template = require(`html-loader!./blockUserButton.component.html`).default;


class BlockUserButtonComponent {
  constructor(
    RestrictedMemberService
  ) {
    'ngInject';

    this.RestrictedMemberService = RestrictedMemberService;
  }

  blockUser() {
    this.RestrictedMemberService.blockUser(this.memberId);
  }
}

BlockUserButtonComponent.$inject = [
  'RestrictedMemberService'
];

export default {
  controller: BlockUserButtonComponent,
  template: template,
  bindings: {
    memberId: '<'
  }
};
