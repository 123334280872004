export const teamsConfirmConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-teams-confirm data="modal.data"></modal-teams-confirm>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
