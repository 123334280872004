<div *ngIf="item" class="winner-item-wrapper">
  <div class="winner-member-info">
    <div class="winner-index gs-theme-text-headline-sub gs-theme-color-gray_90">
      {{item?.rank}}
    </div>
    <div class="avatar avatar-list-item">
      <app-ng-avatar
        [member]="item?.member"
        [size]="'128x128'"
        [clickable]="true"
        [showLeagueBadge]="true"
        [lazy]="true"
      >
      </app-ng-avatar>
    </div>

    <div class="member-info-data">
      <div class="member-name text-ellipsis">{{item?.member?.name}}</div>
      <div class="member-country">{{item?.member?.country?.name}}</div>
      <a target="_self" href="/{{item?.member?.user_name}}"></a>
      <div class="member-info-data-status">{{item?.member?.member_status_name}}</div>
    </div>

    <app-ng-follow
      [member]="item?.member"
    >
    </app-ng-follow>
  </div>

  <app-ng-rewards
    [prizes]="prizes"
  ></app-ng-rewards>

  <div class="winner-image-wrapper">
    <app-ng-image
      [image]="item?.images[0]"
      [fill]="true"
    >
    </app-ng-image>
    <app-ng-adult-filter
            *ngIf="isAdultFilterEnabled(item?.images[0])"
            [image]="item?.images[0]"
            [mode]="AdultFilterMode.SMALL">
    </app-ng-adult-filter>
    <app-ng-photo-swipe-link [images]="item?.images"></app-ng-photo-swipe-link>
  </div>

</div>
