const template = require(`html-loader!./notificationAlert.html`).default;
const infoTemplate = require(`html-loader!./notificationInfo/notificationInfo.html`).default;
const teamIntroTemplate = require(`html-loader!./notificationTeamIntro/notificationTeamIntro.html`).default;

class notificationAlertService {

  constructor(
    $compile,
    $rootScope
  ) {
    'ngInject';

    this.$compile = $compile;
    this.$rootScope = $rootScope;
    this.infoTemplate = infoTemplate;
    this.teamIntroTemplate = teamIntroTemplate;
  }

  open(message, showIcon = true) {
    return new Promise((resolve, reject) => {
      let newScope = this.$rootScope.$new(true);
      newScope.message = message;
      newScope.showIcon = showIcon;
      newScope.close = () => {
        $('.gs-notification-alert').remove();
        resolve({success: true});
      };
      $('body').append(this.$compile(template)(newScope));
      if (!this.$rootScope.$$phase) {
        this.$rootScope.$digest();
      }
    });
  }

  openTemplate(templateName, data) {
    return new Promise((resolve, reject) => {
      if($('.gs-notification-wrap')[0]){
        resolve({success: false});
        return;
      }
      this.templateOpen = true;
      let newScope = this.$rootScope.$new(true);
      newScope.data = data;
      newScope.close = () => {
        this.templateOpen = false;
        $('.gs-notification-wrap').remove();
        resolve({success: true});
      };
      $('body').append(this.$compile(this[`${templateName}Template`])(newScope));
      if (!this.$rootScope.$$phase) {
        this.$rootScope.$digest();
      }
    });
  }

  infoSmall({message, title, size = 'small',buttonText = 'Got it'}){
    return this.openTemplate('info',{
      message,
      buttonText,
      title,
      size
    });
  }

  info({message, title, size = 'large',buttonText = 'Got it'}){
    return this.openTemplate('info',{
      message,
      buttonText,
      title,
      size
    });
  }

  teamIntro({text, title, imageUrl, buttonText='Got it'}){
    return this.openTemplate('teamIntro',{
      text,
      buttonText,
      title,
      imageUrl
    });
  }
}
notificationAlertService.$inject = [
  '$compile',
  '$rootScope'
];
export default notificationAlertService;
