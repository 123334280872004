import config from './gsApp.config';
import Api from './api/api.module';
import gsAppRun from './gsApp.run';
import constants from './gsApp.constants';
import interceptors from './interceptors/interceptors.module';
import filters from './filters/_gsApp.filters';
import directives from './directives/_gsApp.directives';
import components from './components/_gsApp.components';
import services from './services/_gsApp.services';
import pages from './pages/gsApp.pages';
import modules from './modules/_gsApp.modules';
import gsAnimationsFade from './animations/gsApp.animations';

export const mainModule = angular
  .module('gsApp', [
    'ui.router',
    'ngSanitize',
    'ngCookies',
    'ngclipboard',
    'angular-bind-html-compile',
    'ngAnimate',
    'angular-svg-round-progressbar',
    'ngMaterial',
    'ngMask',
    'ngMessages',
    'ngFileUpload',
    'angular-click-outside',
    'angularLazyImg',
    'rx',
    'infinite-scroll',
    'ng.deviceDetector',
    'angularjs-gauge',
    'slick',
    'angularDurationFormat',
    'uuid',
    'angular-md5',
    'vkEmojiPicker',
    constants,
    interceptors,
    filters,
    directives,
    services,
    components,
    Api,
    pages,
    modules
  ])
  .config(config)
  .run(gsAppRun)
  .animation('.gs-a__fade', gsAnimationsFade);

export const oldAngular = angular;

