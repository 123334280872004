import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-photo-page-wrapper',
  templateUrl: './photo-page-wrapper.component.html',
  styleUrl: './photo-page-wrapper.component.scss'
})
export class PhotoPageWrapperComponent {
  id = null;

  constructor(
    private route: ActivatedRoute
  ) {
    this.route
      .params.pipe(
    ).subscribe(async (params: any) => {
      this.id = params.id;
    });
  }
}
