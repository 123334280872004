const adultFilterEnum = {

  MODE: {
    DEFAULT: 'DEFAULT',
    SMALL: 'SMALL',
  }
};

Object.freeze(adultFilterEnum);

export default adultFilterEnum;
