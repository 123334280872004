import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-challenge-rank-wrapper',
  templateUrl: './challenge-rank-wrapper.component.html',
  styleUrl: './challenge-rank-wrapper.component.scss'
})
export class ChallengeRankWrapperComponent {
  cUrl = null;

  constructor(
    private route: ActivatedRoute
  ) {
    // @ts-ignore
    this.route.parent.params.pipe(
      takeUntilDestroyed()
    ).subscribe(async (params: any) => {
      this.cUrl = params.cUrl;
    });
  }
}
