const template = require(`html-loader!./teamInvitesItem.html`).default;
import teamEnum from '../../../../../api/enums/team.enum';

class teamInvitesItemCtrl {

  constructor($scope, $rootScope, RestManager, teamsService, stateService) {
    'ngInject';
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.teamsService = teamsService;
    this.stateService = stateService;
  }

  $onInit() {
    this.setDetailItems();
    this.team = this.teamInvite.team;
  }

  setDetailItems(){
    this.teamDetailTypes = [
      teamEnum.DETAILS.TYPES.SCORE,
      teamEnum.DETAILS.TYPES.WIN,
      teamEnum.DETAILS.TYPES.MEMBERS,
      teamEnum.DETAILS.TYPES.STATUS,
      teamEnum.DETAILS.TYPES.LANGUAGE,
      teamEnum.DETAILS.TYPES.COUNTRY
    ];
  }

  /** @return void **/
  async removeInvite(){
    const res = await this.RestManager.declineInvitation(this.team.id);
    if(!res.success){
      return;
    }
    this.$scope.$emit('removeInvite', {teamId: this.team.id});
  }
}
teamInvitesItemCtrl.$inject = [
  '$scope', '$rootScope', 'RestManager', 'teamsService', 'stateService'
];

export default {
  template: template,
  controller: teamInvitesItemCtrl,
  bindings: {
    teamInvite: '<'
  }
};
