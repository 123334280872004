import profilePage from './profilePage/profilePage';
import profileAchievements from './achievements/achievements';
import profileAchievementsAchievements from './achievements/profileAchievementsAchievements/profileAchievementsAchievements';
import achievementsLevel from './achievements/profileAchievementsAchievements/achievementsLevel/achievementsLevel';
import achievementsBanner from './achievements/profileAchievementsAchievements/achievementsBanner/achievementsBanner';
import achievementsCategory from './achievements/profileAchievementsAchievements/achievementsCategory/achievementsCategory';
import achievementDetails from './achievements/profileAchievementsAchievements/achievementDetails/achievementDetails';
import achievementChallenges from './achievements/profileAchievementsAchievements/achievementChallenges/achievementChallenges';
import profileAchievementsChallenges from './achievements/profileAchievementsChallenges/profileAchievementsChallenges';
import profileFollowers from './followers/followers';
import gsGallery from './gsGallery/gsGallery';
import achievementsService from './achievements/achievements.service';

let moduleProfilePage = angular.module('gsApp.profilePage', [])
  .component('profilePage', profilePage)
  .component('profileAchievements', profileAchievements)
  .component('profileAchievementsAchievements', profileAchievementsAchievements)
  .component('profileAchievementsChallenges', profileAchievementsChallenges)
  .component('profileFollowers', profileFollowers)
  .component('gsGallery', gsGallery)
  .component('achievementsBanner', achievementsBanner)
  .component('achievementsCategory', achievementsCategory)
  .component('achievementsLevel', achievementsLevel)
  .component('achievementDetails', achievementDetails)
  .component('achievementChallenges', achievementChallenges)
  .service('achievementsService', achievementsService)
;

export default moduleProfilePage.name;
