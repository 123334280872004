const template = require(`html-loader!./page404.html`).default;

class page404Ctrl {

}

export default {
  template: template,
  controller: page404Ctrl
};
