const template = require(`html-loader!./badge.html`).default;

class badgeCtrl {
  $onChanges() {
    if(this.badge){
      if(this.badge.value || this.badge.value === 0){
        this.level = this.badge.value;
        return;
      }

      if (this.badge.rank) {
        this.badge.type = 'badge';
        this.level = this.badge.rank;
        return;
      }

      this.level = this.badge.param;
    }
  }
}

export default {
  template: template,
  controller: badgeCtrl,
  bindings: {
    badge: '<data',
    showCount: '@'
  }
};
