const memberEnum = Object.freeze({
  AVATAR:{
    PLACEHOLDER:'/assets/images/placeholders/no-avatar.svg'
  },
  SUBMIT_STATES: {
    JOIN: 'join',
    JOIN_UNLOCK: 'join_unlock',
    SUBMIT: 'submit',
    NOT_ALLOWED: 'not_allowed',
    OFF_TOPIC: 'offtopic',
  },
  GROUP: {
    DEACTIVATE_BY_MEMBER: 10000,
    DEACTIVATE_BY_ADMIN: 10001,
    DELETED_BY_MEMBER: 11000,
    DELETED_BY_ADMIN: 11001,
  },
  STATUS: {
    ANY_STATUS: {
      VALUE:-1,
      NAME:'ANY STATUS'
    },
    NOT_RANKED: {
      VALUE:0,
      NAME:'NOT RANKED'
    },
    NEWBIE: {
      VALUE:1,
      NAME:'NEWBIE'
    },
    ROOKIE: {
      VALUE:2,
      NAME:'ROOKIE'
    },
    CHALLENGER: {
      VALUE:3,
      NAME:'CHALLENGER'
    },
    ADVANCED: {
      VALUE:4,
      NAME:'ADVANCED'
    },
    VETERAN: {
      VALUE:5,
      NAME:'VETERAN'
    },
    EXPERT: {
      VALUE:6,
      NAME:'EXPERT'
    },
    CHAMPION: {
      VALUE:7,
      NAME:'CHAMPION'
    },
    MASTER: {
      VALUE:8,
      NAME:'MASTER'
    },
    GURU: {
      VALUE:9,
      NAME:'GURU'
    },
    GURU_1: {
      VALUE:10,
      NAME:'GURU I'
    },
    GURU_2: {
      VALUE:11,
      NAME:'GURU II'
    },
    GURU_3: {
      VALUE:12,
      NAME:'GURU III'
    },
    GURU_4: {
      VALUE:13,
      NAME:'GURU IV'
    },
    GURU_5:{
      VALUE:14,
      NAME:'GURU V'
    },
    GURU_6: {
      VALUE:15,
      NAME:'GURU VI'
    },
    GURU_7: {
      VALUE:16,
      NAME:'GURU VII'
    },
    GURU_8: {
      VALUE:17,
      NAME:'GURU VIII'
    }
  },
  EXHIBITION_STATE: {
    APPLY_NOW: 'APPLY_NOW', // not payed and less then all star
    EXHIBIT: 'EXHIBIT', // not payed and all star
    REGISTERED: 'REGISTERED', // payed and less then all star
    EXHIBITING: 'EXHIBITING', // payed and all star
  },
  MEMBER_ID: {
    FOR_IMAGES: 'eec411c6ab7e36c00bd78109714c7447'
  }
});

export default memberEnum;
