export const startMatchConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-start-match data="modal.data"></modal-start-match>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
