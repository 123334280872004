const template = require(`html-loader!./leagueLevel.html`).default;

class leagueLevelCtrl {
  constructor(

  ) {
    'ngInject';
  }
}

leagueLevelCtrl.$inject = [

];

export default {
  template: template,
  controller: leagueLevelCtrl,
  bindings: {
    league: '<'
  }
};
