/* globals angular */
import config from './achievements.page.config';
import challengeAchievements from './challengeAchievements/challengeAchievements';

let moduleAchievementsPage = angular.module('gsApp.achievementsPage', [])
    .config(config)
    .component('challengeAchievements', challengeAchievements)
  ;

export default moduleAchievementsPage.name;
