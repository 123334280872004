const tableEnum = Object.freeze({
  TABLE_TYPE: {
    TEAM_MEMBER: {
      NAME: 'TEAM_MEMBER',
      ORDER_BY: 'date_created',
      REVERSE: true,
      FIELDS: [
        {
          title: 'Profile Image',
          type: ()=>{ return tableEnum.FIELD_TYPE.MEMBER_IMAGE },
          additionalData: {
            newTab: true,
            goToAdminMemberManager: true,
          }
        },
        {
          title: 'Name',
          type: ()=>{ return tableEnum.FIELD_TYPE.MEMBER_NAME },
          additionalData: {
            newTab: true,
            goToAdminMemberManager: true,
          }
        },
        {
          title: 'User Id',
          value: 'id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'First join date',
          value: 'date_created',
          type: ()=>{ return tableEnum.FIELD_TYPE.DATE },
        },
        {
          title: 'User type',
          value: 'type',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Join Type',
          value: 'join_by',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Inviting Member Name',
          value: 'invitation.name',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Inviting Member Id',
          value: 'invitation.id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Assign/remove admin',
          type: ()=>{ return tableEnum.FIELD_TYPE.ASSIGN_ADMIN },
        },
      ]
    },
    LEFT_TEAM_MEMBER: {
      NAME: 'LEFT_TEAM_MEMBER',
      ORDER_BY: 'left_date',
      REVERSE: true,
      FIELDS: [
        {
          title: 'Profile Image',
          type: ()=>{ return tableEnum.FIELD_TYPE.MEMBER_IMAGE },
          additionalData: {
            newTab: true,
            goToAdminMemberManager: true,
          }
        },
        {
          title: 'Name',
          type: ()=>{ return tableEnum.FIELD_TYPE.MEMBER_NAME },
          additionalData: {
            newTab: true,
            goToAdminMemberManager: true,
          }
        },
        {
          title: 'User Id',
          value: 'id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'First join date',
          value: 'date_created',
          type: ()=>{ return tableEnum.FIELD_TYPE.DATE },
        },
        {
          title: 'Left date',
          value: 'left_date',
          type: ()=>{ return tableEnum.FIELD_TYPE.DATE },
        },
      ]
    },
    MATCH_MEMBER: {
      NAME: 'MATCH_MEMBER',
      // ORDER_BY: 'date_created',
      ORDER_BY: '',
      REVERSE: true,
      FIELDS: [
        {
          title: 'Profile Image',
          type: ()=>{ return tableEnum.FIELD_TYPE.MEMBER_IMAGE },
          additionalData: {
            newTab: true,
            goToAdminMemberManager: true,
          }
        },
        {
          title: 'Name',
          type: ()=>{ return tableEnum.FIELD_TYPE.MEMBER_NAME },
          additionalData: {
            newTab: true,
            goToAdminMemberManager: true,
          }
        },
        {
          title: 'User Id',
          value: 'id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Score',
          value: 'score',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
      ]
    },
    MATCH: {
      NAME: 'MATCH',
      ORDER_BY: '',
      REVERSE: true,
      FIELDS: [
        {
          title: 'Match Id',
          value: 'match_id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Team total votes',
          value: 'total_votes',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Team score',
          value: 'score',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Other team name',
          value: 'other_team.name',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Other team id',
          value: 'other_team.id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Other team total votes',
          value: 'other_team.total_votes',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Other team score',
          value: 'other_team.score',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'This team members',
          value: 'This members',
          type: ()=>{ return tableEnum.FIELD_TYPE.LINK },
          additionalData: {
            eventName: ()=>{ return tableEnum.EVENTS.MATCH_MEMBERS },
            type: 'thisTeam'
          }
        },
        {
          title: 'Other team members',
          value: 'Other members',
          type: ()=>{ return tableEnum.FIELD_TYPE.LINK },
          additionalData: {
            eventName: ()=>{ return tableEnum.EVENTS.MATCH_MEMBERS },
            type: 'otherTeam'
          }
        },
      ]
    },
    LEAGUE: {
      NAME: 'LEAGUE',
      ORDER_BY: '',
      REVERSE: true,
      FIELDS: [
        {
          title: 'Season Id',
          value: 'season_id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Placement in league',
          value: 'placement_in_league',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Chest ID given',
          value: 'chest_id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
      ]
    },
    CHALLENGE_TEMPLATES: {
      NAME: 'CHALLENGE_TEMPLATES',
      ORDER_BY: '',
      REVERSE: false,
      FIELDS: [
        {
          title: 'Id',
          value: 'id',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Name',
          value: 'name',
          type: ()=>{ return tableEnum.FIELD_TYPE.TEXT },
        },
        {
          title: 'Edit',
          value: 'Edit',
          // class: 'gs-no-border',
          class: 'gs-t-a-c',
          type: ()=>{ return tableEnum.FIELD_TYPE.BUTTON },
          ACTION_TYPE: ()=>{ return tableEnum.ACTION_TYPE.CHALLENGE_TEMPLATE_EDIT },
        },
        {
          title: 'Duplicate',
          value: 'Duplicate',
          // class: 'gs-no-border',
          class: 'gs-t-a-c',
          type: ()=>{ return tableEnum.FIELD_TYPE.BUTTON },
          ACTION_TYPE: ()=>{ return tableEnum.ACTION_TYPE.CHALLENGE_TEMPLATE_DUPLICATE },
        },
        {
          title: 'Delete',
          value: 'Delete',
          // class: 'gs-no-border',
          class: 'gs-t-a-c',
          type: ()=>{ return tableEnum.FIELD_TYPE.BUTTON },
          ACTION_TYPE: ()=>{ return tableEnum.ACTION_TYPE.CHALLENGE_TEMPLATE_DELETE },
        },
      ]
    },
    INDIVIDUAL_LEAGUE: {
      NAME: 'INDIVIDUAL_LEAGUE',
      ORDER_BY: '',
      REVERSE: true,
      FIELDS: [
        {
          title: 'Season end date',
          value: 'season_end_date',
          type: () => tableEnum.FIELD_TYPE.DATE
        },
        {
          title: 'Season',
          value: 'season',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'League',
          value: 'league',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Bucket',
          value: 'bucket',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'GS points',
          value: 'gs_points',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Rank at end of league',
          value: 'rank_at_end_of_league',
          type: () => tableEnum.FIELD_TYPE.TEXT
        }
      ]
    },
    CHALLENGES_SPONSOR: {
      NAME: 'CHALLENGES_SPONSOR',
      ORDER_BY: '',
      REVERSE: false,
      FIELDS: [
        {
          title: 'Id',
          value: 'id',
          type: () => tableEnum.FIELD_TYPE.TEXT
          /*type: () => tableEnum.FIELD_TYPE.NUMBER*/
        },
        {
          title: 'Name',
          value: 'name',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Brand',
          value: 'brand',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Description',
          value: 'description',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Url',
          value: 'url',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Image id',
          value: 'image_id',
          type: () => tableEnum.FIELD_TYPE.TEXT
        }
      ]
    },
    CHALLENGES_PRIZE: {
      NAME: 'CHALLENGES_PRIZE',
      ORDER_BY: '',
      REVERSE: false,
      FIELDS: [
        {
          title: 'Id',
          value: 'id',
          type: () => tableEnum.FIELD_TYPE.TEXT
          /*type: () => tableEnum.FIELD_TYPE.NUMBER*/
        },
        {
          title: 'Name',
          value: 'name',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Brand',
          value: 'brand',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Description',
          value: 'description',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Url',
          value: 'url',
          type: () => tableEnum.FIELD_TYPE.TEXT
        },
        {
          title: 'Image id',
          value: 'image_id',
          type: () => tableEnum.FIELD_TYPE.TEXT
        }
      ]
    },
  },
  FIELD_TYPE: {
    MEMBER_IMAGE: 'MEMBER_IMAGE',
    MEMBER_NAME: 'MEMBER_NAME',
    ASSIGN_ADMIN: 'ASSIGN_ADMIN',
    DATE: 'DATE',
    LINK: 'LINK',
    BUTTON: 'BUTTON',
    TEXT: 'TEXT',
    NUMBER: 'NUMBER'
  },
  ACTION_TYPE: {
    CHALLENGE_TEMPLATE_EDIT: 'CHALLENGE_TEMPLATE_EDIT',
    CHALLENGE_TEMPLATE_DUPLICATE: 'CHALLENGE_TEMPLATE_DUPLICATE',
    CHALLENGE_TEMPLATE_DELETE: 'CHALLENGE_TEMPLATE_DELETE',
  },
  EVENTS: {
    MATCH_MEMBERS: 'MATCH_MEMBERS'
  }
});

export default tableEnum;
