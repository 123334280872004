const template = require(`html-loader!./leaderboardInfo.html`).default;
import leagueEnum from "../../../api/enums/league.enum";
import mixpanelEventsEnum from "../../../services/mixpanel/mixpanel.enum";

class leaderboardInfoCtrl {

  constructor(
    leaderboardService,
    stateService,
    Modals,
    mixpanelService
  ) {
    'ngInject';

    this.leaderboardService = leaderboardService;
    this.stateService = stateService;
    this.Modals = Modals;
    this.mixpanelService = mixpanelService;
  }

  $onChanges(changes) {
    if (changes.leagueData && changes.leagueData.currentValue) {
      this.setInfoFields();
    }
  }

  setInfoFields(){
    const baseUrl = '/assets/svg/pages/leaderboard/';
    this.infoFields = {
      title: null,
      subtitle: null,
      buttonText: null,
      buttonAction: null,
      badgeUrl: null,
    };
    if(this.leagueData.featureMessage){
      this.featureMessage = this.leagueData.featureMessage;
    }
    if(this.featureMessage){
      this.eventMixPanelAction({
        leagues_state: mixpanelEventsEnum.LEAGUES_STATE.RESULTS,
        ui_action: mixpanelEventsEnum.UI_ACTION.IMPRESSION,
        league_id: this.leagueData.league.id
      });
      this.infoFields = {
        title: this.featureMessage.data.subtitle,
        subtitle: this.featureMessage.data.text,
        buttonText: this.featureMessage.data.button.text,
        badgeUrl: this.featureMessage.data.image_url,
        buttonAction: this.onFeatureMessageAction.bind(this)
      };
      return;
    }
    if(this.leaderboardService.isSeasonStatusPending(this.leagueData)){
      this.eventMixPanelAction({
        leagues_state: mixpanelEventsEnum.LEAGUES_STATE.PENDING,
        ui_action: mixpanelEventsEnum.UI_ACTION.IMPRESSION,
        league_id: this.leagueData.league.id
      });
      this.infoFields.title = 'Next season starts in';
      this.infoFields.subtitle = `You will be in ${this.leagueData.league.name} League ${this.leagueData.league.sub_name}`;
      this.infoFields.badgeUrl = this.leagueData.league.badge_url;
      return;
    }
    switch (this.leagueData.state.status){
      case leagueEnum.STATE.COMING_SOON:
      case leagueEnum.STATE.INACTIVE_NOT_IN_LEAGUE:
        this.eventMixPanelAction({
          leagues_state: mixpanelEventsEnum.LEAGUES_STATE.INACTIVE_NOT_IN_LEAGUE,
          ui_action: mixpanelEventsEnum.UI_ACTION.IMPRESSION
        });
        this.infoFields.title = this.leagueData.state.message;
        this.infoFields.badgeUrl = `${baseUrl}inactive_not_in_league-badge.svg`;
        break;
      case leagueEnum.STATE.INACTIVE_IN_LEAGUE:
        this.eventMixPanelAction({
          leagues_state: mixpanelEventsEnum.LEAGUES_STATE.INACTIVE_IN_LEAGUE,
          ui_action: mixpanelEventsEnum.UI_ACTION.IMPRESSION,
          league_id: this.leagueData.league.id
        });
        this.infoFields.title = 'Earn GS Points to join a League';
        this.infoFields.subtitle = 'Level up and get achievements in Challenges to earn GS points.';
        this.infoFields.buttonText = 'Join a Challenge';
        this.infoFields.badgeUrl = this.leagueData.league.badge_url;
        this.infoFields.buttonAction = this.goToDiscoverPage.bind(this);
        break;
    }
  }

  async goToDiscoverPage() {
    this.stateService.goToDiscoverState();
  }

  async onFeatureMessageAction() {
    this.eventMixPanelAction({
      leagues_state: mixpanelEventsEnum.LEAGUES_STATE.RESULTS,
      league_id: this.leagueData.league.id,
      ui_name: mixpanelEventsEnum.UI_NAME.GOT_IT
    });
    await this.leaderboardService.onFeatureMessageAction(this.featureMessage);
    this.featureMessage = null;
    this.stateService.reload();
    // this.getTeamLeagueData();
  }

  help() {
    this.Modals.open('individualLeaderboardInfo');
  }

  eventMixPanelAction({leagues_state, ui_action, league_id, ui_name}) {
    let data = {
      leagues_state,
      ui_category: mixpanelEventsEnum.UI_CATEGORY.SCREEN
    };

    (ui_action !== undefined) && Object.assign(data, {ui_action});
    (league_id !== undefined) && Object.assign(data, {league_id});
    (ui_name   !== undefined) && Object.assign(data,   {ui_name});

    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.INDIVIDUAL_LEAGUE,
      data
    });
  }
}
leaderboardInfoCtrl.$inject = [
  'leaderboardService',
  'stateService',
  'Modals',
  'mixpanelService'
];
export default {
  template: template,
  controller: leaderboardInfoCtrl,
  bindings: {
    leagueData: '<',
  }
};
