export class EnvironmentStaticCaptchaModel {
  captchaKey: {[key: string]: string} = {
    preprod: '6LeSQacUAAAAALRYbxJ9HgLnnH2DB8Ht0__5scTC',
    prod: '6LeSQacUAAAAALRYbxJ9HgLnnH2DB8Ht0__5scTC',
    beta: '6LeSQacUAAAAALRYbxJ9HgLnnH2DB8Ht0__5scTC',
    stage: '6LdjQKcUAAAAAK7f1Sj82rd-of7QRVB8t0mX_OSr'
  }

    constructor() {}

    get(env: string): string {
        return this.captchaKey[env];
    }
}
