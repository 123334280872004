const template = require(`html-loader!./adminExhibitionsPage.html`).default;

class adminExhibitionsPage {
  /**
   * @param {ExhibitionsManager} ExhibitionsManager
   * @param {Toasts} Toasts
   * @param {ModalsService} Modals
   * */

  constructor(ExhibitionsManager, Toasts, $element, Modals, $scope) {
    'ngInject';
    this.$ctrl = this;

    this.ExhibitionsManager = ExhibitionsManager;
    this.Toasts = Toasts;
    this.$element = $element;
    this.Modals = Modals;
    this.$scope = $scope;
  }

  $onInit() {
    this.ExhibitionsManager.getExhibitionPageItems().then(res => {
      if (!res.success) {
        return;
      }
      this.exhibitions = res.items;
    });
  }

  /** @return void **/
  async deleteExhibition(exhibition) {
    const confirm = await this.Modals.confirm('Delete exhibition?', `Are you sure? No way to revert!`, 'Delete', 'Cancel');
    if (!confirm) {
      return;
    }

    const res = await this.ExhibitionsManager.delExhibitionPageItem(exhibition.id);
    let data;
    if (!res.success) {
      data = {
        message: res.error_code,
        class: 'error',
      };
      this.Toasts.open('simpleMessage', { data }, this.$element);
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      return;
    }

    this.exhibitions.splice(this.exhibitions.indexOf(exhibition), 1);
    data = {
      message: 'Done',
    };
    this.Toasts.open('simpleMessage', { data }, this.$element);
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
adminExhibitionsPage.$inject = [
  'ExhibitionsManager', 'Toasts', '$element', 'Modals', '$scope'
];
export default {
  template: template,
  controller: adminExhibitionsPage,
};
