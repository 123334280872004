import {Directive, ElementRef, Injector} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";
import {ScopeProvider} from "./ScopeProvider";

@Directive({
  providers: [ ScopeProvider ],
  selector: 'ng1-challenges-completed'
})
export class ng1ChallengesCompletedComponent extends UpgradeComponent {
  constructor(
    elementRef: ElementRef,
    injector: Injector
  ) {
    super('myChallengesCompleted', elementRef, injector);
  }
}
