class popupService {

  constructor($interval, $timeout) {
    'ngInject';
    this.$interval = $interval;
    this.$timeout = $timeout;
  }

  open(url = '', callback = null, title = '', w = 600, h = 400, scrollbars = false) {
    this.cleanUp();
    const left = (screen.availWidth / 2) - (w / 2);
    const top = (screen.availHeight / 2) - (h / 2);
    // detect when the window is closed
    if (callback) {
      this.callback = callback;
      this.interval = this.$interval(this.isClosed.bind(this), 1000);
      // prevent unlimited loop
      this.$timeout(() => {
        this.$interval.cancel(this.interval);
      }, 4000 * 60);
    }
    const strWindowFeatures = `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=${scrollbars?'yes':'no'}, resizable=yes, copyhistory=no,
     width=${w}, height=${h}, top=${top}, left=${left}`;

    // open the window
    this.newWindow = window.open(url, title, strWindowFeatures);
    // this.newWindow.moveTo(left, top);
    // focus on the new window
    if (this.newWindow.focus)
      this.newWindow.focus();
    // return modal object
    return this.newWindow;
  }

  close() {
    if(this.newWindow) {
      this.newWindow.close();
    }
  }

  isClosed() {
    console.log('Waiting for popup closing');
    console.log(this.newWindow, 'closed:' + this.newWindow.closed);
    if (this.newWindow && this.newWindow.closed) {
      if (this.callback) {
        this.callback();
      }
      this.cleanUp();
    }
  }

  cleanUp() {
    this.newWindow = null;
    this.callback = null;
    if (this.interval) {
      this.$interval.cancel(this.interval);
    }
  }
}
popupService.$inject = [
  '$interval', '$timeout'
];
export default popupService;
