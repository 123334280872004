import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-teams-leagues-active-wrapper',
  templateUrl: './admin-content-manager-teams-leagues-active-wrapper.component.html',
  styleUrl: './admin-content-manager-teams-leagues-active-wrapper.component.scss'
})
export class AdminContentManagerTeamsLeaguesActiveWrapperComponent {

}
