import {INgLeagueModel} from "./models";
import {NgChestAttributesModel} from "./ng-chest-attributes.model";
import {NgLeagueRankModel} from "./ng-league-rank.model";

export class NgLeagueModel implements INgLeagueModel {
  num_of_buckets = 0;
  full_name;
  rank;
  chest;

  constructor(props: INgLeagueModel = {}) {
    Object.assign(this, props);

    this.full_name = props.name + ' ' + props.sub_name;
    this.num_of_buckets = props.num_of_buckets || 0;
    props.rank && (this.rank = new NgLeagueRankModel(props.rank));
    props.chest && (this.chest = new NgChestAttributesModel(props.chest));
  }
}
