import { Component } from '@angular/core';
import {Subject} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ActivatedRoute} from "@angular/router";
import {MemberService} from "../../core/services/member.service";
import {StateService} from "../../core/services/state.service";

@Component({
  selector: 'app-profile-wrapper',
  templateUrl: './profile-wrapper.component.html',
  styleUrl: './profile-wrapper.component.scss'
})
export class ProfileWrapperComponent {
  cUserName:string = '';
  subject = new Subject();

  constructor(
    private route: ActivatedRoute,
    private stateService: StateService,
    public memberService: MemberService,
  ) {
    this.route.params.pipe(
      takeUntilDestroyed()
    ).subscribe(async (params: any) => {
      this.cUserName = params.cUserName;
      const res = await this.memberService.getProfileByUserName(this.cUserName);
      if(!res.success){
        this.stateService.goToDefaultState();
      }
    });
  }
}
