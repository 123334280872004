import {SectionType} from "../../store/models/enums";
import {ResourceType} from "../../../core/models/enums";
import {MatDialogConfig} from "@angular/material/dialog";
import {IStoreItem, IStoreItemReceived} from "../../store/services/store.service.types";
import {NgChallengeModel} from "../../challenges/models/ng-challenge.model";

export const defaultsGsModalGeneral: GsModalDataGeneralType = {
  isOkButton: true,
  cancelButtonText: "",
  componentRef: undefined,
  okButtonText: "Got it",
  isXButton: true,
  xButtonStyle: "style1"
};

export type GsModalDataGeneralType = {
  componentRef: any;
  isOkButton: boolean;
  okButtonText: string;
  cancelButtonText: string;
  isXButton: boolean;
  xButtonStyle: 'style1' | 'style2';
  galleryId?: string
}

export type StoreDataType = GsModalDataGeneralType & {
  deeplink?: SectionType | ResourceType,
  storeItemId?: number
};

export type GsModalConfigType = {
  // helpButton: MatDialogConfig<HelpButtonDataType>;
  store: MatDialogConfig<StoreDataType>;
  completePurchase: MatDialogConfig<CompletePurchaseDataType>;
  confirmPurchase: MatDialogConfig<ConfirmPurchaseDataType>;
  outOfCoins: MatDialogConfig<OutOfResourceDataType>;
  fullChallenge: MatDialogConfig<GsModalDataGeneralType>;
  storeItemInfo: MatDialogConfig<StoreItemInfoDataType>;
  confirm: MatDialogConfig<confirmDataType>;
};

export type confirmDataType = {
  message: string,
  resourceType?: ResourceType,
  resourceTypeAmount?: number
};

export type StoreItemInfoDataType = GsModalDataGeneralType & {
  received: IStoreItemReceived[]
};

export type HelpButtonDataType = GsModalDataGeneralType & {
  title: string,
  message?: string,
  isModalScroll: boolean,
  challenge: NgChallengeModel
};

export type CompletePurchaseDataType = GsModalDataGeneralType & {
  item?: IStoreItem
};

export type ConfirmPurchaseDataType = GsModalDataGeneralType & {
  item?: IStoreItem,
  sectionType?: SectionType,
  itemSize?: number
};

export type OutOfResourceData = {
  items?: IStoreItem[],
  sectionType?: SectionType,
  resourceType?: ResourceType,
  amountMissing?: number
}

export type OutOfResourceDataType = GsModalDataGeneralType & OutOfResourceData;
