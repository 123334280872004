import {MediatorMessageKey} from "../../../../../core/services/mediator.service";
const template = require(`html-loader!./challengeActionLocked.html`).default;
import {
  ChallengeTurboUnlockType,
  ChallengeUnlockType,
  ChallengeUnlockUsage
} from "../../../../../modules/challenges/enums/challenges.enum";
import {ResourceType} from "../../../../../core/models/enums";

class challengeActionLockedCtrl {
  data;
  indicatorType = ResourceType.KEYS;
  unlockAmount = 1;

  constructor(
    $mdDialog,
    Modals,
    notificationAlert,
    $scope,
    RestManager,
    challengeService,
    MediatorService,
    $timeout) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.notificationAlert = notificationAlert;
    this.$scope = $scope;
    this.RestManager = RestManager;
    this.challengeService = challengeService;
    this.mediatorService = MediatorService;
    this.ResourceType = ResourceType;
  }

  /** @return void **/

  $onInit(){
    if(this.isTurbo()){
      const turboData = this.data.challenge.member.turbo;
      this.requiredSelections = turboData.required_selections;
      this.strikes = turboData.max_selections - turboData.required_selections + 1;
      this.image = '/assets/images/modals/turbo/turbo-rules.png';
      this.title = 'TURBO';
      this.buttonText = this.data.locked ? 'UNLOCK NOW' : 'LET’S DO IT!';
      if(this.data.locked){
        if(this.data.lockedType === ChallengeTurboUnlockType.COINS){
          this.indicatorType = ResourceType.COINS;
        } else if(this.data.lockedType === ChallengeTurboUnlockType.KEY){
          this.indicatorType = ResourceType.KEYS;
        }
        this.unlockAmount = this.data.challenge.member?.turbo?.turbo_unlock_amount;
      }
    } else { //boost
      this.data.locked = true;
      this.image = '/assets/images/gsApp/modals/modal-boost-locked-cover.png';
      this.title = 'BOOST IS LOCKED!';
      this.buttonText = 'UNLOCK NOW';
    }
  }

  isTurbo(){
    return this.data.type === ChallengeUnlockType.TURBO;
  }

  async unlockTurbo() {
    if (this.busy) {
      return;
    }
    this.busy = true;
    let res;
    if(this.data.lockedType === ChallengeTurboUnlockType.COINS){
      res = await this.challengeService.unlockTurboWithCoins(this.data.challenge);
    } else {
      res = await this.challengeService.keyUnlock(this.data.challenge, ChallengeUnlockUsage.TURBO);
    }

    if (res.success) {
      this.goToMiniGame();
    } else {
      this.close();
    }
  }

  async unlockBoost(event) {
    if (this.busy) {
      return;
    }
    this.busy = true;
    const res = await this.challengeService.keyUnlock(this.data.challenge, ChallengeUnlockUsage.EXPOSURE_BOOST);
    if (!res.success) {
      this.close();
      return;
    }

    // update parent scope
    if(this.data.challenge.isOnePhoto()){
      if (!this.boosting) {
        this.boosting = true;
        const res = await this.challengeService.boostPhoto(this.data.challenge.getFirstImage(), this.data.challenge);
        if (!res || !res.success) {
          this.close();
          return;
        }
        this.$timeout(() => {
          this.close();
        }, 2000);
      }
    } else{
      this.Modals.open('challengeActionActivate', event, { challenge: this.data.challenge });
    }
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  goToMiniGame(){
    this.mediatorService.broadcast(MediatorMessageKey.OPEN_MINI_GAME, this.data.challenge);
  }

  close() {
    this.$mdDialog.hide();
  }
}
challengeActionLockedCtrl.$inject = [
  '$mdDialog',
  'Modals',
  'notificationAlert',
  '$scope',
  'RestManager',
  'challengeService',
  'MediatorService',
  '$timeout'
];
export default {
  template: template,
  controller: challengeActionLockedCtrl,
  bindings: {
    data: '=data',
  },
};
