import { getAngularElement } from '../helpers/element.helper';


class ProtectionService {

  constructor($timeout) {
    'ngInject';
    this.$timeout = $timeout;
  }

  initEvent(protectionModel) {
    angular
      .element(document)
      .ready(
        () => getAngularElement()
          .getElementsByClassName(protectionModel.elementClassName)
          .on(
            protectionModel.eventName,
            (event) => protectionModel.callback(event)
          )
      )
    ;
  }
}
ProtectionService.$inject = [
  '$timeout'
];
export default ProtectionService;
