import { Component } from '@angular/core';

@Component({
  selector: 'app-ads-view1-view2-wrapper',
  templateUrl: './ads-view1-view2-wrapper.component.html',
  styleUrl: './ads-view1-view2-wrapper.component.scss'
})
export class AdsView1View2WrapperComponent {

}
