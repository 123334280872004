import { Component } from '@angular/core';

@Component({
  selector: 'app-challenge-winners-exhibit-print-wrapper',
  templateUrl: './challenge-winners-exhibit-print-wrapper.component.html',
  styleUrl: './challenge-winners-exhibit-print-wrapper.component.scss'
})
export class ChallengeWinnersExhibitPrintWrapperComponent {

}
