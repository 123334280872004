import {Injectable} from "@angular/core";

@Injectable()
export class ScriptService {

  private scripts: any = {};

  constructor() {
  }

  load(...sources: string[]) {
    let promises: any[] = [];
    sources.forEach((source) => promises.push(this.loadScript(source)));
    return Promise.all(promises);
  }

  loadScript(src: string) {
    return new Promise((resolve) => {
      //resolve if already loaded
      if (this.scripts[src]) {
        resolve(true);
      }
      else {
        //load script
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = src;
        script.onload = () => {
          this.scripts[src] = true;
          resolve(true);
        };
        script.onerror = (error: any) => {
          console.log('error', error);
          resolve(false)
        };
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

}
