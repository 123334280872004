import ImageModel from './image/image.model';
import BadgeModel from './badge.model';
import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";

class CustomResponseModel {
  constructor({
    success = false,
    rank = null,
    rewards = [],
    achievements = [],
    challenge = {},
    image = {},
    ss_url,
    share_urls,
    chest
  }) {
    this.success = success;
    this.rank = rank;
    this.rank = rank;
    this.rewards = rewards;
    this.ss_url = ss_url;
    this.share_urls = share_urls;
    this.image = new ImageModel(image);
    this.challenge = new NgChallengeModel(challenge);
    this.achievements = achievements.map(achievement => new BadgeModel(achievement));
    this.chest = chest;
  }
}

export default CustomResponseModel;
