<div class="missions-wrapper">

  <div class="missions-header">
    <div class="missions-header-title">
      <div
        class="missions-header-title-main gs-theme-text-headline-xl"
      >Missions</div>
      <div
        class="missions-header-title-sub gs-theme-text-headline"
      >Complete missions and win prizes!</div>
    </div>
  </div>

  <ng-container
    *ngIf="!isLoading else loading"
  >
    <div
      class="missions-content"
      [style.height.px]="missions.length > 0 ? (163 * missions.length + 190) : 0"
    >
      <ng-container *ngIf="missions.length === 0" [ngTemplateOutlet]="noMissions"></ng-container>
      <div
        *ngFor="let mission of missions; index as index; trackBy: trackBy"
        class="missions-content-item"
        [ngClass]="{'disable-animation': animationDisabled}"
        [style.transform]="mission.offset"
        [style.z-index]="mission.claim_state ===  missionClaimState.CLAIM ? (mission.claimed ? 1 : 29) : 2"
      >

        <app-mission-card
          [ngClass]="{'claimed-mission': mission.claimed}"
          @enterLeaveTrigger
          [@.disabled]="animationDisabled"
          [mission]="mission"
          [isAnimationDisabled]="animationDisabled"
          (onTimerExpired)="onMissionTimerExpired($event)"
          (onClaimed)="claimRewards($event, mission)"
        ></app-mission-card>
      </div>
    </div>
  </ng-container>

</div>

<ng-template #noMissions>
  <div class="no-missions-placeholder">
    New missions coming soon
  </div>
</ng-template>

<ng-template #loading>
  <div class="loader-wrapper gs-center-v-flex">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
