/**
 * @param {ModalsService} Modals
 * */
function openModal(
  Modals
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {
      openModal: '@', // modal name
      modalData: '@',
      challenge: '<'
    },
    link: (scope, element) => {
      element.bind('click', event => {
        event.preventDefault();
        if (!scope.openModal) {
          return false;
        }
        const params = scope.modalData ? JSON.parse(scope.modalData) : {};
        if(scope.challenge) {
          params.challenge = scope.challenge;
        }
        // open modal
        Modals.open(scope.openModal, event, params);
        return false;
      });
    }
  };
}

openModal.$inject = [
  'Modals'
];

export default openModal;
