import {NgModule} from '@angular/core';
import { StoreComponent } from './containers/store/store.component';
import {StoreService} from "./services/store.service";
import {StoreSectionComponent} from './containers/store-section/store-section.component';
import {SharedModule} from "../../shared/shared.module";
import { StoreItemComponent } from './containers/store-item/store-item.component';
import { CompletePurchaseComponent } from './components/complete-purchase/complete-purchase.component';
import { ConfirmPurchaseComponent } from './components/confirm-purchase/confirm-purchase.component';
import { StoreItemPriceComponent } from './components/store-item-price/store-item-price.component';
import { StoreItemIconAmountComponent } from './components/store-item-icon-amount/store-item-icon-amount.component';
import {BankrollModule} from "../bankroll/bankroll.module";
import {PaymentModule} from "../payment/payment.module";
import {StoreEventsService} from "./services/store-events.service";
import {OutOfResourceComponent} from "./containers/out-of-resource/out-of-resource.component";
import { StoreItemInfoComponent } from './components/store-item-info/store-item-info.component';
import {AngularMaterialModule} from "../angular-material/angular-material.module";

@NgModule({
  imports: [
    SharedModule,
    BankrollModule,
    PaymentModule,
    AngularMaterialModule
  ],
  exports: [
    StoreComponent,
    StoreItemPriceComponent
  ],
  declarations: [
    StoreComponent,
    StoreSectionComponent,
    StoreItemComponent,
    CompletePurchaseComponent,
    ConfirmPurchaseComponent,
    StoreItemPriceComponent,
    StoreItemIconAmountComponent,
    OutOfResourceComponent,
    StoreItemInfoComponent
  ],
  providers: [
    StoreService,
    StoreEventsService
  ],
})

export class StoreModule {
}
