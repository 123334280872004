import {getDateHours} from "../helpers/date.helper";
import challengeEnum from '../api/enums/challenge.enum';
import memberEnum from "../api/enums/member.enum";
import {filterReasonIds} from '../pages/challenge/rank/topPhotographer/topPhotographer.data';
import {
  exhibitionBadges,
  joinMemberStatusTypeOnly,
  joinMemberStatusTypes
} from "../pages/challenges/myChallengesEditor/myChallengesEditor.data";
import {ResourceType} from "../../../core/models/enums";
import {
  ChallengeTurboState,
  ChallengeType,
  ChallengeUnlockUsage
} from "../../../modules/challenges/enums/challenges.enum";
import {isFieldValidNew} from "../helpers/validate.helper";
import {MediatorMessageKey} from "../../../core/services/mediator.service";
import {SectionType} from "../../../modules/store/models/enums";
import {NgModalsService} from "../../../modules/angular-material/services/ng-modals.service";
import {PromiseHelper} from "../../../core/helpers/PromiseHelper";
import mixpanelEventsEnum from "./mixpanel/mixpanel.enum";
import {filter, Subject, takeUntil} from "rxjs";

class challengeService {

  constructor(
    $rootScope,
    ChallengesManager,
    RestManager,
    notificationAlert,
    brazeService,
    tagEventService,
    $timeout,
    Modals,
    BankrollService,
    MediatorService,
    NgModalsService,
    MemberService,
    NgChallengesService,
    JoinedChallengesPageService
  ) {
    'ngInject';
    this.Modals = Modals;
    this.ChallengesManager = ChallengesManager;
    this.notificationAlert = notificationAlert;
    this.brazeService = brazeService;
    this.tagEventService = tagEventService;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.bankrollService = BankrollService;
    this.ngModalsService = NgModalsService;
    this.mediatorService = MediatorService;
    this.NgChallengesService = NgChallengesService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    MemberService.member$.pipe(
      filter(member => member !== undefined),
    ).subscribe(member => {
      this.member = member;
    });
  }

  setForBid(challenge, saveChallenge = false){
    return this.openPopup({
      challenge,
      saveChallenge,
      title: 'Bid Now?',
      textContent: 'Do you want to bid this challenge to Gurus now?',
      ok:'Bid Now',
      statusToSet: challengeEnum.STATUSES.IN_BID.VALUE
    });
  }

  setForScheduleBid(challenge, saveChallenge = false){
    return this.openPopup({
      challenge,
      saveChallenge,
      title: 'Schedule Bid?',
      textContent: 'Do you want to schedule this challenge for a bid?',
      ok:'Schedule Bid',
      statusToSet: challengeEnum.STATUSES.IN_BID.VALUE
    });
  }

  setAsUnassigned(challenge, saveChallenge = false){
    return this.openPopup({
      challenge,
      saveChallenge,
      title:'Set As Unassigned?',
      textContent:'Do you want set this challenge as unassigned?',
      ok:'Set As Unassigned',
      statusToSet: challengeEnum.STATUSES.UNASSIGNED.VALUE
    });
  }

  setAsAssigned(challenge, saveChallenge = false) {
    return this.openPopup({
      challenge,
      saveChallenge,
      title:'Set As assigned?',
      textContent:'Do you want set this challenge as assigned?',
      ok:'Set As Assigned',
      statusToSet: challengeEnum.STATUSES.ASSIGNED.VALUE
    });
  }

  setAsUpcoming(challenge, saveChallenge = false) {
    return this.openPopup({
      challenge,
      saveChallenge,
      title:'Approve?',
      textContent:'Move this challenge to upcoming challenges?',
      ok:'Move',
      statusToSet: challengeEnum.STATUSES.UPCOMING.VALUE
    });
  }

  setAsPending(challenge, saveChallenge = false) {
    return this.openPopup({
      challenge,
      saveChallenge,
      title:'Submit challenge?',
      textContent:'Are you sure you want to save and this submit challenge for review?',
      ok:'Submit',
      statusToSet: challengeEnum.STATUSES.PENDING.VALUE
    });
  }

  async openPopup({challenge, title, textContent, ok, cancel = 'Cancel', statusToSet, saveChallenge = false}) {
    const confirm = await this.Modals.confirm(
      title,
      textContent,
      ok,
      cancel
    );
    console.log('confirm', confirm);
    if (!confirm) {
      return false;
    }

    if(!saveChallenge){
      challenge.status = statusToSet;
      return true;
    }
    const data = {
      id: challenge.id,
      status: statusToSet
    };
    return await this.saveChallenge(data, challenge, statusToSet);
  }

  async saveChallenge(dataToSave, challenge, statusToSet) {
    if([challengeEnum.STATUSES.IN_BID.VALUE, challengeEnum.STATUSES.UNASSIGNED.VALUE].includes(challenge.status) && !this.isScheduleBidValid(challenge)){

      return false;
    }

    const res = await this.ChallengesManager.setChallenge(dataToSave);

    if(res.success) {
      if(statusToSet) {
        challenge.status = statusToSet;
      }
    } else {
      this.notificationAlert.infoSmall({message: (res.message || 'Error!')});
    }

    return res.success;
  }

  isValidDates(challenge){
    if([challengeEnum.STATUSES.ACTIVE.VALUE, challengeEnum.STATUSES.CLOSED.VALUE, challengeEnum.STATUSES.CLOSING.VALUE].includes(challenge.status)){
      return true;
    }
    const nowDate = new Date();
    if(nowDate > challenge.start_time){
      this.notificationAlert.infoSmall({
        message : 'Challenge cant start in the past',
        title : 'Challenge time is incorrect',
      });
      return false;
    }

    return true;
  }

  isScheduleBidValid(challenge) {
    if (!challenge.is_bid_schedule) {
      return true;
    }

    const title = 'You can\'t schedule this bid';
    const bidHoursToStartChallenge = getDateHours(challenge.start_time - challenge.bid_start_time);

    if (bidHoursToStartChallenge < 0) {
      this.notificationAlert.infoSmall({
        message : 'The bid is scheduled to start after the challenge start time',
        title : title,
      });

      return false;
    }

    if (bidHoursToStartChallenge < 24) {
      this.notificationAlert.infoSmall({
        message : 'The bid starts less than 48h before the challenge starts',
        title : title,
      });

      return false;
    }

    const now = new Date();

    if (now > challenge.bid_start_time) {
      this.notificationAlert.infoSmall({
        message : 'The bid starts in the past',
        title : title,
      });

      return false;
    }

    if (challenge.bid_start_time > challenge.start_time) {
      this.notificationAlert.infoSmall({
        message : 'The bid is scheduled to start after the challenge start time',
        title : title,
      });

      return false;
    }

    return true;
  }

  isRankAutoUpdate(challengeHours){
    return challengeHours >= 10;
  }

  onChallengeTagUpdate(chip) {
    let isValid = /^([1-9a-zA-Z- ]){2,30}$/.test(chip);
    return isValid ? undefined : null;
  }

  onJoinMemberStatusChanged({challenge, scope, resetJoinStatusType}){
    if(challenge && [memberEnum.STATUS.ANY_STATUS.VALUE, memberEnum.STATUS.NOT_RANKED.VALUE].includes(challenge.join_member_status)){
      scope.joinMemberStatusTypes = [joinMemberStatusTypeOnly];
      challenge.join_member_status_type = joinMemberStatusTypeOnly.VALUE;
    }else {
      scope.joinMemberStatusTypes = joinMemberStatusTypes;
      if(resetJoinStatusType){
        challenge.join_member_status_type = "";
      }
    }
  }

  getBadgesByType(challenge, saveDefault){
    let badges = [];
    let savedValue = challenge.badge
    switch(challenge.type) {
      case ChallengeType.EXHIBITION:
        challenge.badge = saveDefault ? challengeEnum.BADGE.TYPE.EXHIBITION.VALUE : savedValue;
        badges = exhibitionBadges;
        break;
      case ChallengeType.SPEED:
        challenge.badge = '';
        badges = [];
        break;
      case ChallengeType.FLASH:
        challenge.badge = saveDefault ? challengeEnum.BADGE.TYPE.FLASH.VALUE : savedValue;
        badges = [challengeEnum.BADGE.TYPE.FLASH];
        break;
      default:
        challenge.badge = saveDefault ? challengeEnum.BADGE.TYPE.FOUR_PHOTO.VALUE : savedValue;
        badges = [challengeEnum.BADGE.TYPE.FOUR_PHOTO];
        break;
    }
    challenge.badge_text = saveDefault ? challenge.type : challenge.badge_text;
    return badges;
  }
  getChallengeFieldsToValidate(preObjectName = ''){
    return  [
      {field: `${preObjectName}type`, title: 'Type'},
      {field: `${preObjectName}join_member_status`, title: 'Join member status'},
      {field: `${preObjectName}join_member_status_type`, title: 'Join member status type'},
      {field: `${preObjectName}voting_max_photos_mobile`, title: 'Voting max photos mobile', isNumber: true, minNumber: 1, isRequired: false},
      {field: `${preObjectName}voting_max_photos_web`, title: 'Voting max photos web', isNumber: true, minNumber: 1, isRequired: false},
      {field: `${preObjectName}vote_minutes_delay`, title: 'Vote minutes delay', isNumber: true, minNumber: 1},
      {field: `${preObjectName}join_coins`, title: 'Join coins', isNumber: true, minNumber: 0},
      {field: `${preObjectName}key_lock_minutes`, title: 'Key lock minutes', isNumber: true},
      {field: `${preObjectName}prizes_worth`, title: 'Prizes_worth', isNumber: true, minNumber: 1},
      {field: `${preObjectName}exposure_required_votes`, title: 'Autofill required votes', isNumber: true, isRequired: false},
      {field: `${preObjectName}exposure_decay_minutes`, title: 'Autofill empty minutes', isNumber: true, isRequired: false},
      {field: `${preObjectName}exposure_decay_interval_minutes`, title: 'Autofill decay interval minutes', isNumber: true, isRequired: true},
      {field: `${preObjectName}guru_points`, title: 'Guru points', isNumber: true, isRequired: false},
      {field: `${preObjectName}guru_points_members`, title: 'Guru points members', isNumber: true, isRequired: false},
      {field: `${preObjectName}vote_minimum_players`, title: 'Vote minimum players', isNumber: true, isRequired: false, minNumber: 1},
      {field: `${preObjectName}max_photo_submits`, title: 'Max photo submits'}
    ];
  }

  isAchievementPrizeValid(achievementPrize){
    const preTitleText = 'Achievement and prize -';
    let fieldsToValidate = [
      {field: `winners_layout`, title: `${preTitleText} Winners layout`},
      {field: `title`, title: `${preTitleText} title`},
    ]
    if(achievementPrize.image && (achievementPrize.image.id || achievementPrize.image.member_id)){
      fieldsToValidate.push({field: `image.id`, title: `${preTitleText} Image id`})
      fieldsToValidate.push({field: `image.member_id`, title: `${preTitleText} Image member_id`})
    }
    if(achievementPrize.rank && (achievementPrize.rank.type || achievementPrize.rank.min_rank || achievementPrize.rank.max_rank)){
      fieldsToValidate.push({field: `rank.type`, title: `${preTitleText} Rank type`})
      fieldsToValidate.push({field: `rank.min_rank`, title: `${preTitleText} Rank min rank`, isNumber: true,  minNumber: 1})
      fieldsToValidate.push({field: `rank.max_rank`, title: `${preTitleText} Rank max rank`, isNumber: true, minNumber: 1})
    }
    if(achievementPrize.prizes && achievementPrize.prizes.length){
      for(let prize of achievementPrize.prizes){
        if(prize){
          const prizesFieldsToValidate = []
          if(!prize.type){
            prizesFieldsToValidate.push({field: `type`, title: `${preTitleText} prize type`});
          }
          if(!prize.amount){
            prizesFieldsToValidate.push({field: `amount`, title: `${preTitleText} prize amount`, isNumber: true});
          }
          if(prizesFieldsToValidate.length && !this.isFieldsValid(prize, prizesFieldsToValidate)){
            return false;
          }
        }
      }
    }
    return this.isFieldsValid(achievementPrize, fieldsToValidate);
  }

  badgeChanged(challenge, saveDefault = true) {
    let textLeft = '',
      textRight = ''
    ;

    switch (challenge.badge) {
      case challengeEnum.BADGE.TYPE.EXHIBITION.VALUE: {
        textLeft = challengeEnum.BADGE.TEXT.EXHIBITION.LEFT;
        textRight = challengeEnum.BADGE.TEXT.EXHIBITION.RIGHT;
        break;
      }
      case challengeEnum.BADGE.TYPE.MAGAZINE.VALUE: {
        textLeft = challengeEnum.BADGE.TEXT.MAGAZINE.LEFT;
        textRight = challengeEnum.BADGE.TEXT.MAGAZINE.RIGHT;
        break;
      }
      case challengeEnum.BADGE.TYPE.PREMIUM.VALUE: {
        textLeft = challengeEnum.BADGE.TEXT.PREMIUM.LEFT;
        textRight = challengeEnum.BADGE.TEXT.PREMIUM.RIGHT;
        break;
      }
      case challengeEnum.BADGE.TYPE.FLASH.VALUE: {
        textLeft = challengeEnum.BADGE.TEXT.FLASH.LEFT;
        textRight = challengeEnum.BADGE.TEXT.FLASH.RIGHT;
        break;
      }
      case '': {
        textLeft = '';
        textRight = '';
        break;
      }
      default:
        break;
    }

    challenge.badge_left = saveDefault ? textLeft : challenge.badge_left;
    challenge.badge_right = saveDefault ? textRight : challenge.badge_right;
  }

  isFieldsValid(objectToValidate, fields){
    for (let field of fields) {
      let validRes = isFieldValidNew(objectToValidate, field);
      if (!validRes.valid) {
        this.notificationAlert.infoSmall({message: validRes.message});
        return false;
      }
    }
    return true;
  }

  isMaxPhotoSubmitDisabled(challenge){
    if(!challenge){
      return true;
    }
    const isTypeInvalid = [challengeEnum.TYPE.SPEED, challengeEnum.TYPE.EXHIBITION].includes(challenge.type);
    const isStatusInvalid = [challengeEnum.TYPE.SPEED, challengeEnum.TYPE.EXHIBITION].includes(challenge.type);
    return challenge && (isTypeInvalid || isStatusInvalid);
  }

  searchTags(text) {
    return this.RestManager.getAutocompleteChallengeTags(text).then(
      (res) => {
        if (!res.success){
          return [];
        }

        return res.tags;
      }
    );
  }

  async submitToChallenge(image_ids, c_id, c_type){
    const res = await this.ChallengesManager.submitToChallenge(image_ids, 'challenges', true, c_id);
    if (res.success) {
      if (res.member_challenge_count && res.member_challenge_count <= 15) {
        this.tagEventService.joinChallenge(res.member_challenge_count);
      }
      this.brazeService.sendJoinChallengeEvent(c_id, c_type, this.getKeysAmount());
      this.JoinedChallengesPageService.cleanUpJoinedChallenge(c_id);
    } else {
      if (res.error_code === 1024) {
        this.ngModalsService.openChallengeIsFullDialog();
      }
    }
    return res;
  }

  async joinTheme({image_id, theme_id}){
    return await this.RestManager.joinTheme({image_id, theme_id});
  }

  async outOfSwapLogic(){
    const swapStoreEventParams = {outOfResourceId: 'out_swap' + Date.now()};
    this.brazeService.logCustomEvent('out_swap', swapStoreEventParams);
    return await this.brazeService.waitForBrazeMessage(this.openStoreSwapsSection.bind(this), swapStoreEventParams.outOfResourceId);
  }

  async swapAction({event, image, state, challenge, tip}) {
    // open shop
    if (this.isSwapsEmpty()) {
      await this.outOfSwapLogic();
      return true;
    }

    // swap
    const params = {
      target: 'swap',
      challenge: challenge,
      currentImage: image,
    };
    if(tip){
      params.tip = tip;
    }
    if (state) {
      params.state = state;
      params.source = 'profile';
    }
    await this.Modals.open('gsUploader', event, params);
  }



  async swap(c_id, img_id, new_img_id) {
    const res = await this.ChallengesManager.swap('challenges', true, c_id, img_id, new_img_id);
    if(res.success){
      this.tagEventService.swap(res.first_time);
      this.brazeService.sendPhotoSwapEvent(c_id, this.getSwapsAmount());
    }
    return res;
  }

  async removeImageFromChallenge({imageToRemove, challenge}) {
    let reasonId;
    if (!this.member.is_admin) {
      const confirm = await this.Modals.confirm(
        'Delete Photo?',
        'You can delete photos from challenge up to 5 minutes after submit. Are you sure you want to delete?',
        'Delete',
        'Cancel'
      );
      if (!confirm) {
        return false;
      }
    } else {
      const options = [];
      for (const key in filterReasonIds) {
        options.push({
          id: filterReasonIds[key],
          name: key,
        });
      }
      reasonId = await this.Modals.open('selectList', null, { options });
      if (reasonId === false) {
        return false;
      }
    }

    const res = await this.ChallengesManager.removeFromChallenge(challenge.id, imageToRemove.id, reasonId);
    if (res.success) {
      return true;
    }

    await this.swapAction({
      imageToRemove,
      challenge,
      target: 'swap',
      tip:  'Your Photo was submitted more than 5 minutes ago. Delete time period has passed, you can only swap.'
    }
    );
    return false;
  }

  async boostPhoto(image, challenge) {
    const res = await this.RestManager.boostPhoto(image.id, challenge.id);
    if (res.error_code === 1002) {
      return false;
    }
    if(res.success){
      this.tagEventService.boost();
      this.brazeService.sendExposureBoostEvent(challenge.id, this.getKeysAmount());
      challenge.member.boost.state = challengeEnum.BOOST.USED;
      image.boosting = true;
      image.boosted = true;
    }
    return res;
  }

  async keyUnlock(challenge, usage) {
    const res = await this.RestManager.keyUnlock(challenge.id, usage);
    if(res.success){
      this.brazeService.sendKeyEvent(challenge.id, usage.toLowerCase(), this.getKeysAmount());
      this.updateKeysAmount(-1);
      if(usage === ChallengeUnlockUsage.TURBO){
        challenge.setTurboState(ChallengeTurboState.IN_PROGRESS);
      } else{
        if(challenge.member.boost){
          challenge.member.boost.state = challengeEnum.BOOST.AVAILABLE_KEY;
          challenge.member.boost.timeout = null;
        }
      }
    } else {
      if(res.error_code === 1105 && res.exception_code === 2019){
        // user already unlocked the boost
        window.location.reload();
      } else if (res.error_code === 1022) {
        if(usage === ChallengeUnlockUsage.TURBO){
          this.mediatorService.broadcast(MediatorMessageKey.CLOSE_MINI_GAME);
        }
        this.openStoreKeysSection()
      }
    }
    return res;
  }

  async exposureAutofill({el, el_id, challenge_ids, numOfFills = 1}) {
    const res = await this.ChallengesManager.exposureAutofill({el, el_id, challenge_ids});
    if(res.success){
      for(let i = 0; i < numOfFills; i++){
        this.tagEventService.fill();
      }
      this.brazeService.sendExposureAutofillEvent(challenge_ids, numOfFills, this.getFillAmount());
      setTimeout(()=>{
        challenge_ids.forEach((challengeId)=>{
          const selector = `.c-id-${challengeId} .c-challenges-item__exposure__meter__arrow, ` +
            `.c-id-${challengeId} .c-challenges-item-mobile__exposure__meter__arrow`;
          $(selector)
            .css('-webkit-transform', 'rotate(90deg)')
            .css('-ms-transform', 'rotate(90deg)')
            .css('transform', 'rotate(90deg)');
        })
      }, 2000)
      // animation

      this.updateFillAmount(-numOfFills);
    }
    return res;
  }

  onChallengeFinished(challenge){
    console.log('onChallengeFinished', challenge);
    challenge.status = challengeEnum.STATUSES.CLOSING.VALUE;
  }

  async scrollToChallenge({
    targetClass,
    highlightChallengeAfterScroll
  }){
    const challengeClass = targetClass;
    if($(challengeClass)[0]){
      this.$timeout(async () => {
        $(window).scrollTo(challengeClass, 1000, {offset: -150});
        await PromiseHelper.wait(1000);
        if(highlightChallengeAfterScroll){
          $(challengeClass).addClass('my-challenges__added');
        }
        return Promise.resolve();
      }, 250);
    } else {
      return Promise.resolve();
    }

  }

  openExhibitionShop(challenge){
    return this.Modals.open('shop.exhibition', null, {
      name: 'shop.' + challenge.type,
      challenge: challenge
    });
  }

  isKeysEmpty(){
    return this.bankrollService.isChallengeResourceEmpty(ResourceType.KEYS);
  }
  isFillEmpty(){return this.bankrollService.isChallengeResourceEmpty(ResourceType.FILLS)}
  isSwapsEmpty(){return this.bankrollService.isChallengeResourceEmpty(ResourceType.SWAPS)}
  isCoinsEmpty(){return this.bankrollService.isChallengeResourceEmpty(ResourceType.COINS)}

  getFillAmount(){return this.bankrollService.getBankrollItemAmount(SectionType.CHALLENGES, ResourceType.FILLS)}
  getSwapsAmount(){return this.bankrollService.getBankrollItemAmount(SectionType.CHALLENGES, ResourceType.SWAPS)}
  getKeysAmount(){return this.bankrollService.getBankrollItemAmount(SectionType.CHALLENGES, ResourceType.KEYS)}
  getCoinsAmount(){return this.bankrollService.getBankrollItemAmount(SectionType.CHALLENGES, ResourceType.COINS)}

  updateFillAmount(amountToAddOrReduce){
    this.bankrollService.updateBankrollItemAmount([{
      resourceType: ResourceType.FILLS,
      amountToAddOrReduce: amountToAddOrReduce
    }]);
  }

  updateSwapsAmount(amountToAddOrReduce){
    this.bankrollService.updateBankrollItemAmount([{
      resourceType: ResourceType.SWAPS,
      amountToAddOrReduce: amountToAddOrReduce
    }]);
  }

  updateKeysAmount(amountToAddOrReduce){
    this.bankrollService.updateBankrollItemAmount([{
      resourceType: ResourceType.KEYS,
      amountToAddOrReduce: amountToAddOrReduce
    }]);
  }

  updateKeysCoin(amountToAddOrReduce){
    this.bankrollService.updateBankrollItemAmount([{
      resourceType: ResourceType.COINS,
      amountToAddOrReduce: amountToAddOrReduce
    }]);
  }

  openStoreKeysSection(){this.bankrollService.openStoreByResourceType(ResourceType.KEYS);}
  openStoreFillsSection(){this.bankrollService.openStoreByResourceType(ResourceType.FILLS);}
  openStoreSwapsSection(){this.bankrollService.openStoreByResourceType(ResourceType.SWAPS);}
  openStoreCoinsSection(){this.bankrollService.openStoreByResourceType(ResourceType.COINS);}

  openStorePayment(storeItemId){
    this.bankrollService.openStorePayment(storeItemId);
  }

  async openPreSubmit(challenge){
    let openPreSubmitModal = await this.unlockChallengeBeforeJoin(challenge);
    if(openPreSubmitModal){
      this.Modals.open('preSubmit', null, {challenge: challenge});
    }
  }

  async unlockTurboWithCoins(challenge){
    const res = await this.NgChallengesService.coinsUnlock(challenge.id, ChallengeUnlockUsage.TURBO);
    if (res.success) {
      challenge.setTurboState(ChallengeTurboState.IN_PROGRESS);
      this.updateKeysCoin(-challenge.member.turbo.turbo_unlock_amount);
    } else if(res.error_code === 1022) {
      setTimeout(() => {
        this.openStoreCoinsSection();
      }, 1800);
    }
    return res;
  }

  async unlockChallengeBeforeJoin(challenge, closeModal = () => {}){
    if(!challenge) return false;
    let canJoin = false;
    if (challenge.isLockedWithCoins()) {
      const amountOfCoinsAfterRedaction = this.getCoinsAmount() - challenge.join_coins;
      if (amountOfCoinsAfterRedaction < 0) {
        const coinsFlashEventParams = {outOfResourceId: 'coins_flash' + Date.now()};
        this.brazeService.logCustomEvent('coins_flash', coinsFlashEventParams);
        await this.brazeService.waitForBrazeMessage(this.openStoreCoinsSection.bind(this), coinsFlashEventParams.outOfResourceId);
      } else {
        const config = {
          message: `“${challenge.title}” cost ${challenge.join_coins} coins`,
          resourceTypeAmount: challenge.join_coins,
          resourceType: ResourceType.COINS
        }
        this.NgChallengesService.sendJoinChallengeMixpanelEvent({
            challenge :challenge,
            ui_name:mixpanelEventsEnum.UI_NAME.JOIN_CONFIRMATION,
            ui_action:mixpanelEventsEnum.UI_ACTION.APPEAR
          }
        );
        closeModal();
        const confirmed = await this.ngModalsService.confirm(config);
        if (confirmed) { // confirm payment of  coins to join
          this.NgChallengesService.sendJoinChallengeMixpanelEvent({
              challenge : challenge,
              ui_name:mixpanelEventsEnum.UI_NAME.JOIN_CONFIRMATION,
              ui_action:mixpanelEventsEnum.UI_ACTION.CLICK,
              ui_type : this.isCoinsEmpty() ? 'out_currency' : undefined
            }
          );
          const res = await this.NgChallengesService.coinsUnlock(challenge.id);
          if (res.success) {
            challenge.member.submit_state = memberEnum.SUBMIT_STATES.JOIN;
            this.updateKeysCoin(-challenge.join_coins);
            canJoin = true;
          }
        }
      }
    } else if (challenge.isLockedWithKey()){
      if (this.isKeysEmpty()) {
        const outKeyEventParams = {outOfResourceId: 'out_key' + Date.now()};
        this.brazeService.logCustomEvent('out_key', outKeyEventParams);
        await this.brazeService.waitForBrazeMessage(this.openStoreKeysSection.bind(this), outKeyEventParams.outOfResourceId);
      } else {
        closeModal();
        if (await this.Modals.open('challengeUnlock', null, {challenge: challenge})) {
          canJoin = true;
        }
      }
    } else {
      //can join
      canJoin = true;
    }
    return canJoin;
  }
}
challengeService.$inject = [
  '$rootScope',
  'ChallengesManager',
  'RestManager',
  'notificationAlert',
  'brazeService',
  'tagEventService',
  '$timeout',
  'Modals',
  'BankrollService',
  'MediatorService',
  'NgModalsService',
  'MemberService',
  'NgChallengesService',
  'JoinedChallengesPageService'
];
export default challengeService;
