export class EnvironmentStaticBrazeDesktopModel {
  brazeDesktop: { [key: string]: string } = {
    preprod: '18f4ba6c-91b3-46c8-aeb1-66689e5c4b34',
    prod: '18f4ba6c-91b3-46c8-aeb1-66689e5c4b34',
    beta: '18f4ba6c-91b3-46c8-aeb1-66689e5c4b34',
    stage: 'a41c34ce-ce18-4bdf-84df-c019f8922344'
  }

  constructor() {
  }

  get(env: string): string {
    return this.brazeDesktop[env];
  }
}
