const template = require(`html-loader!./aboutPage.html`).default;
import {lpConsts} from '../../landingPages/landingPages.data';

class aboutPageComponent {

  constructor(
    pageData,
    $window,
    $scope,
    $timeout,
    gsScrollTopService,
    tagEventService,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.fixedFooter = null;
    this.ready = false;

    this.pageData = pageData;
    this.$window = $window;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.lpConsts = lpConsts;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
  }

  $onInit() {
    this.NgMetaDataService.setTitle('About');
    this.gsScrollTopService.scrollTop();
    this.initPage();
  }

  /** @return void **/
 initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('about');
    this.tagEventService.sendPageViewEvent({title: 'About page'});
    this.ready = true;
  }
}
aboutPageComponent.$inject = [
  'pageData',
  '$window',
  '$scope',
  '$timeout',
  'gsScrollTopService',
  'tagEventService',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: aboutPageComponent,
};
