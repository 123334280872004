const template = require(`html-loader!./gsTableEditable.html`).default;

class gsTableEditable {
  constructor(
    RouterService
  ) {
    'ngInject';

    this.currentStart = null;
    this.RouterService = RouterService;
  }

  $onInit() {
    this.currentStart = this.config.pagination.start;
  }

  createNew() {
    this.RouterService.go(this.config.states.new);
  }

  loadMore() {
    this.currentStart += this.config.pagination.limit;

    this.config.loadMore.loadMoreFn({
      start: this.currentStart
    });
  }
}

gsTableEditable.$inject = ['RouterService'];

export default {
  template: template,
  controller: gsTableEditable,
  bindings: {
    'config': '<'
  }
};
