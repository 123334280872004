const template = require(`html-loader!./achievementsInfo.html`).default;
import achievementEnum from "../../../api/enums/achievement.enum";

class achievementsInfoCtrl {

  constructor($mdDialog, $rootScope, Modals) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.achievementEnum = achievementEnum;
  }

  $onInit() {}

  close() {
    this.Modals.close();
  }
}
achievementsInfoCtrl.$inject = [
  '$mdDialog', '$rootScope', 'Modals'
];
export default {
  template: template,
  controller: achievementsInfoCtrl,
  bindings: {
    data: '=data',
  },
};
