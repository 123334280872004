<div
  class="photographer-item-wrapper"
  [ngClass]="{
    'current-user': isUser,
    'red-exposure': exposure < 33.3,
    'yellow-exposure': 66.6 >= exposure && exposure > 33.3,
    'green-exposure': 66.6 < exposure
  }">

  <div
    class="item-index"
    [ngClass]="{
      'first-place': rankingItem.total?.rank === 1,
      'second-place': rankingItem.total?.rank === 2,
      'third-place': rankingItem.total?.rank === 3,
      'current-user': isUser,
      'red-exposure': exposure < 33.3,
      'yellow-exposure': 66.6 >= exposure && exposure > 33.3,
      'green-exposure': 66.6 < exposure,
    }"
    >{{rankingItem.total?.rank}}</div>

  <div class="item-member">
    <app-ng-image
      class="item-member-avatar"
      [image]="rankingItem.member?.avatar"
      [width]="60"
      [height]="60"
      [priority]="true"
    ></app-ng-image>

    <div class="item-member-flag">
      <app-ng-image
        [image]="rankingItem.member?.country?.icon?.svg_url"
        [width]="20"
        [height]="20"
        [priority]="true"
      ></app-ng-image>
    </div>

    <div class="item-member-name gs-text-dots">{{rankingItem.member?.name}}</div>
  </div>

  <div
    class="item-rewards">
    <app-ng-rewards
      *ngIf="rankingItem.total.prizes && rankingItem.total.prizes.length > 0"
      [styleClass]="'rank-item'"
      [prizes]="rankingItem.total.prizes"
    ></app-ng-rewards>
  </div>

  <div class="item-image">
    <app-ng-image
      [image]="rankingItem.entries[0]"
      [width]="120"
      [height]="120"
      [priority]="true"
    >
    </app-ng-image>

    <div class="item-image-votes">
      <div class="item-image-votes-amount">{{rankingItem.total?.votes}}</div>
      <div class="item-image-votes-label">Votes</div>
    </div>
  </div>
</div>
