const template = require(`html-loader!./mobileMenu.html`).default;

class mobileMenuComponent {
  /** @param {authService} authService **/

  constructor(
    pageData,
    $timeout,
    $scope,
    $rootScope,
    authService,
    gsScrollTopService,
    MemberService,
  ) {
    'ngInject';
    this.fixedFooter = null;

    this.pageData = pageData;
    this.$timeout = $timeout;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.authService = authService;
    this.gsScrollTopService = gsScrollTopService;
    this.MemberService = MemberService;
  }

  $onInit() {
    this.gsScrollTopService.scrollTop();
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('mobileMenu');
  }

  signOut() {
    this.authService.signOut();
  }
}
mobileMenuComponent.$inject = [
  'pageData',
  '$timeout',
  '$scope',
  '$rootScope',
  'authService',
  'gsScrollTopService',
  'MemberService',
];
export default {
  template: template,
  controller: mobileMenuComponent
};
