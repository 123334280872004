import MemberModel from '../models/member.model';
import ImageModel from "../models/image/image.model";
import {toCamelCaseFromUnderline} from "../../helpers/string.helper";

/** @enum {string} */
export const Usage = {
  joinChallenge: 'JOIN_CHALLENGE',
  exposureBoost: 'EXPOSURE_BOOST',
};

export class RestManager {
  // mockUrl = 'https://us-central1-testrest-319e0.cloudfunctions.net/app/';

  constructor(
    api,
    $http
  ) {
    'ngInject';

    this.api = api;
    this.$http = $http;
  }

  ////////////MOCKS////////////////////////

  // async getTeamInvites(){
  //   const req = {
  //     method: 'GET',
  //     url: `${this.mockUrl}get_team_invites`,
  //     data: {}
  //   };
  //   return (await this.$http(req)).data;
  // }

  ///////////////END MOCKS////////////////////////

  claim() {
    return this.api['rest']['claim']();
    // test cache
  }

  trackViralSent(va_id) {
    return this.api['rest']['trackViralSent']({ va_id });
  }

  unlockBoost(c_id) {
    return this.api['rest']['unlockBoost']({ c_id });
  }

  boostPhoto(image_id, c_id) {
    return this.api['rest']['boostPhoto']({ image_id, c_id });
  }

  getMemberStatusResult(status) {
    return this.api['rest']['getMemberStatusResult']({ status });
  }

  deletePopup(popup_id) {
    return this.api['rest']['deletePopup']({ popup_id });
  }

  handleReports({ member_id, c_id, reason_id }) {
    return this.api['rest']['handleReports']({ member_id, c_id, reason_id });
  }

  getSpotlight(limit) {
    return this.api['rest']['getSpotlight']({ limit });
  }

  updateMemberSettings(fields) {
    return this.api['rest']['updateMemberSettings'](fields);
  }

  setMemberSettings(field, value) {
    return this.api['rest']['setMemberSettings']({ field, value });
  }

  withdrawFunds() {
    return this.api['rest']['withdrawFunds']();
  }

  search(text) {
    return this.api['rest']['search']({ text }).then(res => {
      return !res.success ? res : { success: true, items: res.items.map(member => new MemberModel(member)) };
    });
  }

  getUploadRestrictions(scope, scope_id) {
    return this.api['rest']['getUploadRestrictions']({ scope, scope_id });
  }

  getDiscoverPage({ start, limit, type }) {
    return this.api['rest']['getDiscoverPage']({ start, limit, type });
  }

  imageSearch({ text, start, limit, member_id }) {
    return this.api['rest']['imageSearch']({ text, start, limit, member_id });
  }

  imageSearchAutocomplete(text, member_id) {
    return this.api['rest']['imageSearchAutocomplete']({ text, member_id });
  }

  getFeed({ s_t, init = 0 }) {
    return this.api['rest']['getFeed']({ s_t, init });
  }

  sendContact({ name = '', email = '', subject = ' ', message = '' }) {
    return this.api['rest']['sendContact']({ name, email, subject, message });
  }

  getMemberRanking(member_id, c_id) {
    return this.api['rest']['getMemberRanking']({ member_id, c_id });
  }

  leaveChallenge(c_id) {
    return this.api['rest']['leaveChallenge']({ c_id });
  }

  getTc() {
    return this.api['rest']['getTc']();
  }

  /**
   * @param {number} c_id "challenge id"
   * @param {Usage} usage
   * **/
  keyUnlock(c_id, usage) {
    return this.api['rest']['keyUnlock']({ c_id, usage });
  }

  getMemberData({ id, user_name, email }) {
    return this.api['rest']
      ['getMemberData']({ id, user_name, email })
      .then(res => {
        if (res.member) res.member = new MemberModel(res.member);
        if (res.payments && res.payments.payments.length) {
          res.payments.payments = res.payments.payments.map(pay => {
            pay.date_paid = Date.parse(pay.date_paid);
            return pay;
          });
        }
        return res;
      })
      .catch(() => {
        return { success: false };
      });
  }

  getDailyMissions() {
    return this.api['rest']['getDailyMissions']();
  }

  claimDailyReward() {
    return this.api['rest']['claimDailyReward']();
  }

  getDailyMissionsDefinitions() {
    return this.api['rest']['getDailyMissionsDefinitions']();
  }

  getDailyRewardsPrizes() {
    return this.api['rest']['getDailyRewardsPrizes']();
  }

  setDailyMissionDefinition(data) {
    return this.api['rest']['setDailyMissionDefinition'](data);
  }

  delDailyMissionDefinition(id) {
    return this.api['rest']['delDailyMissionDefinition']({id});
  }

  setDailyPrize(data) {
    return this.api['rest']['setDailyPrize'](data);
  }

  delDailyPrize(id) {
    return this.api['rest']['delDailyPrize']({id});
  }

  rewardedVideoGetSettings() {
    return this.api['rest']['rewardedVideoGetSettings']();
  }

  rewardedVideoSetSettings(data) {
    return this.api['rest']['rewardedVideoSetSettings'](data);
  }

  getChallengePriorities() {
    return this.api['rest']['getChallengePriorities']();
  }

  setChallengePriorities(data) {
    return this.api['rest']['setChallengePriorities'](data);
  }

  getLbEvent() {
    return this.api['rest']['getLbEvent']().then(res => {
      if(res.event && res.event.event){
        res.event.event.lb_image = new ImageModel(res.event.event.lb_image);
        res.event.event.intro_image = new ImageModel(res.event.event.intro_image);
        res.event.event.close_time = new Date(res.event.event.close_time * 1000);
      }
      return res;
    });
  }

  claimLbEvent() {
    return this.api['rest']['claimLbEvent']();
  }

  setLbEventWatched() {
    return this.api['rest']['setLbEventWatched']();
  }

  getLbEvents(status) {
    return this.api['rest']['getLbEvents']({status});
  }

  setLbEvent(data) {
    return this.api['rest']['setLbEvent'](data);
  }

  get3dsToken() {
    return this.api['rest']['get3dsToken']();
  }

  getPackages(package_ids) {
    return this.api['rest']['getPackages']({package_ids});
  }

  getGlobalPermissions() {
    return this.api['rest']['getGlobalPermissions']();
  }

  getNewbieChallengeId() {
    return this.api['rest']['getNewbieChallengeId']();
  }

  getAdminReports({ start, limit, reason_id, starting_member_id}) {
    return this.api['rest']['getAdminReports']({ start, limit, reason_id, starting_member_id});
  }

  adminImageHandle({ member_id, c_id, reason_id, image_id, action}) {
    return this.api['rest']['adminImageHandle']({ member_id, c_id, reason_id, image_id, action});
  }

  adminMemberHandle({ member_id, reason_id, action}) {
    return this.api['rest']['adminMemberHandle']({ member_id, reason_id, action});
  }

  getCopyrightReports({ start, limit, starting_member_id}) {
    return this.api['rest']['getCopyrightReports']({ start, limit, starting_member_id});
  }

  moderatorHandle({ member_id, c_id, reason_id, image_id, action}) {
    return this.api['rest']['moderatorHandle']({ member_id, c_id, reason_id, image_id, action});
  }

  getOffTopicReports() {
    return this.api['rest']['getOffTopicReports']();
  }

  getImageReports({ c_id, reason_id}) {
    return this.api['rest']['getImageReports']({ c_id, reason_id});
  }

  moderatorHandleBulk({ image_reports, c_id, reason_id}) {
    return this.api['rest']['moderatorHandleBulk']({ image_reports, c_id, reason_id});
  }

  moderatorHandleBulkAdultPorn({ image_reports }) {
    return this.api['rest']['moderatorHandleBulkAdultPorn']({ image_reports });
  }

  adminGetChests() {
    return this.api['rest']['adminGetChests']();
  }

  adminSetChestRewards(rewards, chest_id) {
    return this.api['rest']['adminSetChestRewards']({rewards, chest_id});
  }

  //teams

  getTeams({ start, limit, name}) {
    return this.api['rest']['getTeams']({ start, limit, name});
  }

  getTeam(team_id) {
    return this.api['rest']['getTeam']({team_id});
  }

  createTeam(team) {
    return this.api['rest']['createTeam'](team);
  }

  updateTeam(team) {
    return this.api['rest']['updateTeam'](team);
  }

  getTeamBadges({ start, limit }) {
    return this.api['rest']['getTeamBadges']({ start, limit});
  }

  getTeamsConfig() {
    return this.api['rest']['getTeamsConfig']();
  }

  isTeamNameFree(name) {
    return this.api['rest']['isTeamNameFree']({name});
  }

  joinTeam(team_id, join_by) {
    return this.api['rest']['joinTeam']({team_id, join_by});
  }

  leaveTeam(team_id) {
    return this.api['rest']['leaveTeam']({team_id});
  }

  removeMember(member_id) {
    return this.api['rest']['removeMember']({member_id});
  }

  updateMember({team_id, member_id, type}) {
    return this.api['rest']['updateMember']({team_id, member_id, type});
  }

  getTeamChatMessages({chat_id, start_time, limit_above, limit_below}) {
    return this.api['rest']['getTeamChatMessages']({chat_id, start_time, limit_above, limit_below});
  }

  leaveMatch({team_id, challenge_id}) {
    return this.api['rest']['leaveMatch']({team_id, challenge_id});
  }

  getMatch({team_id, match_id}) {
    return this.api['rest']['getMatch']({team_id, match_id});
  }

  joinMatch({team_id, challenge_id}) {
    return this.api['rest']['joinMatch']({team_id, challenge_id});
  }

  getMatchesHistory({team_id, start, limit}) {
    return this.api['rest']['getMatchesHistory']({team_id, start, limit});
  }

  getLeaderboard({start, limit}) {
    return this.api['rest']['getLeaderboard']({start, limit});
  }

  getBrazeClientEvents() {
    return this.api['rest']['getBrazeClientEvents']();
  }

  getAutocompleteChallengeTags(text) {
    return this.api['rest']['getAutocompleteChallengeTags']({text});
  }

  getDeeplinks(usage) {
    return this.api['rest']['getDeeplinks']({usage});
  }

  getTeamLeagueData() {
    return this.api['rest']['getTeamLeagueData']();
  }

  getMemberLeagueData() {
    return this.api['rest']['getMemberLeagueData']();
  }

  deleteFeatureMessage(id){
    return this.api['rest']['deleteFeatureMessage']({id});
  }

  claimChest({type, entity_id}){
    return this.api['rest']['claimChest']({type, entity_id});
  }

  getLeagueConfig(){
    return this.api['rest']['getLeagueConfig']({type:'teams'});
  }

  getLeagueSeasonConfigForAdmin(){
    return this.api['rest']['getLeagueSeasonConfigForAdmin']({type:'teams'});
  }

  setCurrentLeagueConfig(leagues){
    return this.api['rest']['setCurrentLeagueConfig']({
      type:'teams',
      leagues
    });
  }

  setDefaultLeagueConfig(leagues){
    return this.api['rest']['setDefaultLeagueConfig']({
      type:'teams',
      leagues
    });
  }

  setUpcomingLeagueConfig(leagues){
    return this.api['rest']['setUpcomingLeagueConfig']({
      type:'teams',
      leagues
    });
  }

  getLeagueSeasonConfig(ids){
    return this.api['rest']['getLeagueSeasonConfig']({
      type:'teams',
      active_only:false,
      ids:ids
    });
  }

  getReasonOptions(type){
    return this.api['rest']['getReasonOptions']({type});
  }

  getDurationOptions(){
    return this.api['rest']['getDurationOptions']();
  }

  deleteMember({id, reason, other_text, send_email}){
    return this.api['rest']['deleteMember']({id, reason, other_text, send_email});
  }

  suspendMember({id, reason, other_text, duration, action, send_email}){
    return this.api['rest']['suspendMember']({id, reason, other_text, duration, action, send_email});
  }

  reactivateMember({id, reason, other_text, send_email}){
    return this.api['rest']['reactivateMember']({id, reason, other_text, send_email});
  }

  getRemoveDetails(id){
    return this.api['rest']['getRemoveDetails']({id});
  }

  getVirtualExhibitions(){
    return this.api['rest']['getVirtualExhibitions']();
  }

  createVirtualExhibition({title, link, image_id, order}){
    return this.api['rest']['createVirtualExhibition']({title, link, image_id, order});
  }

  updateVirtualExhibition({title, link, image_id, order, exhibition_id}){
    return this.api['rest']['updateVirtualExhibition']({title, link, image_id, order, exhibition_id});
  }

  deleteVirtualExhibition(exhibition_id){
    return this.api['rest']['deleteVirtualExhibition']({exhibition_id});
  }

  getCountriesList(){
    return this.api['rest']['getCountriesList']();
  }

  getLanguagesList(){
    return this.api['rest']['getLanguagesList']();
  }

  getMemberTeamChest(){
    return this.api['rest']['getMemberTeamChest']();
  }

  async getTeamInvites(){
    return this.api['rest']['getTeamInvites']();
  }

  async getTeamSuggestedMembers({option_types, start, limit}){
    return this.api['rest']['getTeamSuggestedMembers']({option_types, start, limit});
  }

  async getTeamInvitedMembers(){
    return this.api['rest']['getTeamInvitedMembers']();
  }

  async getTeamSuggestedMembersOptions(){
    return this.api['rest']['getTeamSuggestedMembersOptions']();
  }

  async getMemberTeamState(member_id){
    return this.api['rest']['getMemberTeamState']({member_id});
  }

  async declineInvitation(team_id){
    return this.api['rest']['declineInvitation']({team_id});
  }

  async hideSuggestedMember(member_id){
    return this.api['rest']['hideSuggestedMember']({member_id});
  }

  async inviteMember(member_id){
    return this.api['rest']['inviteMember']({member_id});
  }

  async getFbIsConnected(){
    return this.api['rest']['getFbIsConnected']();
  }

  async isNewInvitations(){
    return this.api['rest']['isNewInvitations']();
    // return {
    //   success: true,
    //   flag: true
    // };
  }

  async resetNewInvitations(){
    return this.api['rest']['resetNewInvitations']();
    // return {
    //   success: true
    // };
  }

  setChallengeMedia({challenge_id, media}){
    return this.api['rest']['setChallengeMedia']({challenge_id, media});
  }

  async resetCacheAchievements(member_id){
    return this.api['rest']['adminRefreshMemberAchievements']({member_id});
  }

  async getTeamSuggest(){
    return this.api['rest']['getTeamSuggest']({start: 0, limit: 30});
  }

  async hideTeamSuggest({team_id}){
    return this.api['rest']['hideTeamSuggest']({team_id});
  }

  async getAdminComments(member_id) {
    return this.api['rest']['getAdminComments']({member_id});
  }

  async setAdminComment({member_id, text}) {
    return this.api['rest']['setAdminComment']({member_id, text});
  }

  async deleteAdminComment({member_id, id}) {
    return this.api['rest']['deleteAdminComment']({member_id, id});
  }

  async reactivateOldMember(id) {
    return this.api['rest']['reactivateOldMember']({id});
  }

  async reactivateDeletedMember({id, reason}) {
    return this.api['rest']['reactivateDeletedMember']({id, reason});
  }

  /// teams manager
  async getAdminTeamById(team_id) {
    return this.api['rest']['getAdminTeamById']({team_id});
  }

  async getAdminTeamByName(team_name) {
    return this.api['rest']['getAdminTeamByName']({team_name});
  }

  async getTeamsAdminGlobalActions() {
    return this.api['rest']['getTeamsAdminGlobalActions']();
  }

  async getTeamsAdminActions(team_id) {
    return this.api['rest']['getTeamsAdminActions']({team_id});
  }

  async teamsAdminAction({type, team_id, json_data}) {
    return this.api['rest']['teamsAdminAction']({type,team_id , json_data});
  }

  async getTeamsAdminMatches({team_id, start, limit }) {
    return this.api['rest']['getTeamsAdminMatches']({team_id, start, limit });
  }

  async getTeamsAdminMatchMembers({match_id, team_id}) {
    return this.api['rest']['getTeamsAdminMatchMembers']({match_id, team_id});
  }

  async getTeamsAdminLeagueInfo(team_id) {
    return this.api['rest']['getTeamsAdminLeagueInfo']({team_id});
  }

  async getTeamsAdminLeftMembers(team_id) {
    return this.api['rest']['getTeamsAdminLeftMembers']({team_id});
  }

  teamsAdminAssignLeader({team_id, member_id, type}) {
    return this.api['rest']['teamsAdminAssignLeader']({team_id, member_id, type});
  }

  async getChallengeTemplates() {
    return this.api['rest']['getChallengeTemplates']();
  }

  async setChallengeTemplate(params) {
    return this.api['rest']['setChallengeTemplate'](params);
  }

  async deleteChallengeTemplate(id) {
    return this.api['rest']['deleteChallengeTemplate']({id});
  }

  async claimResources(challenge_id) {
    return this.api['rest']['claimResources']({challenge_id});
  }

  async setRankingLevels(ranking_levels) {
    return this.api['rest']['setRankingLevels'](ranking_levels);
  }

  async getLeagueConfigWithRules() {
    return this.api['rest']['getLeagueConfigWithRules']({
      type: 'members',
      rules: true
    });
  }

  async getMemberAchievementsByLevel(member_id) {
    return this.api['rest']['getMemberAchievementsByLevel']({member_id});
  }

  async getItemHistory({type, item_id, offset}) {
    return this.api['rest']['getItemHistory']({
      type,
      item_id,
      offset
    });
  }

  async getAllSponsors({start, limit}) {
    return this.api['rest']['getAllSponsors']({ start, limit });
  }

  async createSponsor(data) {
    return this.api['rest']['createSponsor'](data);
  }

  async getAllPrizes({start, limit}) {
    return this.api['rest']['getAllPrizes']({ start, limit });
  }

  async createPrize(data) {
    return this.api['rest']['createPrize'](data);
  }

  // endpoint - the name of the endpoint to server
  // state - the version number
  async getMockData({endpoint, state}) {
    return this.api['rest']['getMockData']({endpoint, state});
  }

  async postRequest({endpoint, mockState, data}) {
    let res;
    if(mockState) { // if mockState get mock from server
      res = this.getMockData({endpoint, state: mockState});
    } else {
      const name = toCamelCaseFromUnderline(endpoint);
      res = this.api['rest'][name](data);
    }
    return res;
  }


  async getAllPrizes({start, limit}) {
    return this.api['rest']['getAllPrizes']({ start, limit });
  }

  async createPrize(data) {
    return this.api['rest']['createPrize'](data);
  }

  async getBlockedMembers({start = 0, limit = 10}) {
    return this.api['rest']['getBlockedMembers']({start, limit});
  }

  async blockMember({member_id}) {
    return this.api['rest']['blockMember']({member_id});
  }

  async unblockMembers({member_ids}) {
    return this.api['rest']['unblockMembers']({member_ids});
  }

  async isMemberBlocked({member_id}) {
    return this.api['rest']['isMemberBlocked']({member_id});
  }

  async joinTheme({image_id, theme_id}) {
    return this.api['rest']['joinTheme']({theme_id, image_id});
  }

  async getTotalChallengesAwaitingImport() {
    return this.api['rest']['getTotalChallengesAwaitingImport']();
  }

}

RestManager.$inject = [
  'api',
  '$http'
];

export default RestManager;
