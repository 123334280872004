
const template = require(`html-loader!./customCard.html`).default;
import ImageModel from "../../../api/models/image/image.model";

class customCardCtrl {

  constructor(Modals) {
    'ngInject';

    this.Modals = Modals;
  }

  $onChanges() {
    if(this.gspopup.data.media && this.gspopup.data.media.image) {
      this.gspopup.data.media.image = new ImageModel(this.gspopup.data.media.image);
    }
  }

  openVideo(event) {
    this.Modals.open('player', event, this.gspopup.data.media);
  }
}
customCardCtrl.$inject = [
  'Modals'
];
export default {
  template: template,
  controller: customCardCtrl,
  bindings: {
    challenge: '<',
    gspopup: '<',
    close: '&'
  }
};
