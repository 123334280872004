import {Component, Input} from '@angular/core';
import {ConfirmPurchaseDataType} from "../../../angular-material/models/types";
import {CloseModalType} from "../../models/enums";

@Component({
  selector: 'app-confirm-purchase',
  templateUrl: './confirm-purchase.component.html',
  styleUrls: ['./confirm-purchase.component.scss'],
})
export class ConfirmPurchaseComponent {
  @Input() data!: ConfirmPurchaseDataType;
  CloseModalType = CloseModalType
}
