const template = require(`html-loader!./modalContentCards.html`).default;


class modalContentCards {
  constructor() {
    'ngInject';
  }
}

export default {
  template: template,
  controller: modalContentCards
};
