export const toDraftList = [
  {
    id: 1,
    title: 'Specific Challenge',
    reject_title: 'Challenge moved to draft',
    reject_description: 'The challenge theme is too specific - try changing the theme to a broader subject.'
  },
  {
    id: 2,
    title: 'Duplicate Challenge',
    reject_title: 'Challenge moved to draft',
    reject_description: 'A similar challenge is already scheduled. Try to change the conditions or submit again in a later date.'
  },
  {
    id: 3,
    title: 'Schedule full',
    reject_title: 'Challenge moved to draft',
    reject_description: 'The challenge que is currently full for the next few weeks. Try submitting in a later date.'
  },
  {
    id: 4,
    title: 'No reason - Message will not be sent',
    reject_title: null,
    reject_description: null
  },
  {
    id: 5,
    title: 'Cover image',
    reject_title: 'Cover image',
    reject_description: 'Please try another cover image'
  },
  {
    id: 6,
    title: 'Theme',
    reject_title: 'Theme',
    reject_description: 'Please choose another theme'
  }
];

export const toInBidList = [
  {
    id: 4,
    title: 'No reason - Message will not be sent',
    reject_title: null,
    reject_description: null
  },
  {
    id: 5,
    title: 'Cover image',
    reject_title: 'Cover image',
    reject_description: 'Please try another cover image'
  }
];
