const template = require(`html-loader!./myChallengesSponsors.html`).default;
import SponsorModel from '../../../../api/models/challenge/sponsor.model';
import myChallengesEditorBase from '../myChallengesEditorBase';

class myChallengesSponsorsCtrl extends myChallengesEditorBase{
  /** @param {ChallengesManager} ChallengesManager **/

  constructor(ChallengesManager, $scope, $element, Toasts) {
    'ngInject';
    super({$scope, $element, Toasts});

    this.busy = true;
    this.prizes = [];

    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.$element = $element;
    this.Toasts = Toasts;
  }

  $onInit() {
    this.get_data();
  }

  add() {
    this.challenge.sponsors.push(new SponsorModel({}));
  }

  /** @return void **/
  async get_data() {
    const res = await this.ChallengesManager.getSponsorsAttributes();
    if (!res.success) {
      return;
    }
    this.sponsors = res.sponsors;
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  delete(index) {
    this.challenge.sponsors.splice(index, 1);
  }

  async save() {
    let ids = [];
    for (const [index, sponsor] of this.challenge.sponsors.entries()) {
      ids.push(sponsor.id);
    }
    const res = await this.ChallengesManager.setChallengeSponsors(this.challenge.id, ids);
    this.afterSave(res);
  }
}
myChallengesSponsorsCtrl.$inject = [
  'ChallengesManager', '$scope', '$element', 'Toasts'
];
export default {
  template: template,
  controller: myChallengesSponsorsCtrl,
  bindings: {
    challenge: '<'
  }
};
