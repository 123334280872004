const template = require(`html-loader!./moderationPage.html`).default;
import {moderatorReason} from "../moderationPage.data";

class moderationPageCtrl {

  constructor(
    $scope,
    pageData,
    stateService,
    $rootScope,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';

    this.$scope = $scope;
    this.pageData = pageData;
    this.stateService = stateService;
    this.$rootScope = $rootScope;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;
    this.moderatorReason = moderatorReason;
    this.init();
  }

  /** @return void **/
  init() {
    this.NgMetaDataService.setTitle('Moderation');
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('moderation');
    if(!this.member.logged_in || !(this.member.is_admin || this.member.moderator)){
      this.stateService.goToDefaultState();
      return;
    }
    this.done = true;
  }
}

moderationPageCtrl.$inject = [
  '$scope',
  'pageData',
  'stateService',
  '$rootScope',
  'MemberService',
  'NgMetaDataService',
];

export default {
  template,
  controller: moderationPageCtrl,
};
