<div class="prize-item gs-d-f gs-a-i-c">
  <div class="gs-d-f gs-a-i-c gs-g-27">
    <app-ng-image
            class="gs-bg-i-none"
            [image]="prize.achievement?.achievement_icon?.svg_url"
            [priority]="true"
            [width]="74"
            [height]="74"
    ></app-ng-image>
    <div class="gs-theme-text-bold gs-theme-color-gray_90" [innerHTML]="prize.title"></div>
  </div>
  <div class="gs-d-f gs-a-i-c gs-m-l-a gs-h-100-p">
    <app-ng-rewards class="gs-m-0-40" [prizes]="prize.prizes!"></app-ng-rewards>
    <div
      class="gs-p-r gs-h-100-p"
      *ngIf="prize.layout === PrizesLayout.EXHIBITION || prize.layout === PrizesLayout.MAGAZINE">
      <app-ng-image
              class="gs-w-290 gs-h-100-p"
              [image]="prize.image"
              [priority]="true"
              [fill]="true"
      ></app-ng-image>
      <app-ng-image
              class="gs-b-14 gs-center-x gs-w-176 gs-h-32 gs-b-r-8 gs-bg-i-none"
              [ngClass]="{'magazine-width': prize.layout === PrizesLayout.MAGAZINE}"
              [image]="'assets/images/pages/prizes/prize-' + prize.layout.toLowerCase() + '-label.svg'"
              [priority]="true"
              [fill]="true"
      ></app-ng-image>
    </div>
  </div>
</div>
