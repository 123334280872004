import {INgChestAttributesModel, INgChestModel, INgTeamChestModel} from "./models";

import {NgChestModel} from "./ng-chest.model";
import {NgChestAttributesModel} from "./ng-chest-attributes.model";

export class NgTeamChestModel implements INgTeamChestModel {
  chest_attributes: INgChestAttributesModel | null = null;
  member_chest: INgChestModel | null = null;

  constructor(props: INgTeamChestModel = {}) {
    Object.assign(this, props);

    props.chest_attributes && (this.chest_attributes = new NgChestAttributesModel(props.chest_attributes));
    props.member_chest && (this.member_chest = new NgChestModel(props.member_chest));
  }
}
