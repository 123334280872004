import {AnimationTriggerMetadata, style, animate, trigger, transition, state, keyframes, animation} from '@angular/animations';

export const enterTriggerAnimation =
  trigger('enterTrigger', [
    state('fadeIn',
      style({opacity: '1', transform: 'scale(1)'})
    ),
    transition('void => *', [
      style({opacity: '0', transform: 'scale(0)'}),
      animate('500ms')
    ])
  ])
;
export const enterLeaveTriggerAnimation =
  trigger('enterLeaveTrigger', [
    transition(':enter', [
      style({transform: 'scale(0)'}),
      animate(500, style({transform: 'scale(1)'}))
    ])
  ])
;
export const battleExposureShowAnimation =
  trigger('battleExposureShow', [
    state('in',
      style({transform: 'scale(1) rotate(0)'})
    ),
    transition('void => *', [
      style({transform: 'scale(0) rotate(45deg)'}),
      animate('300ms')
    ])
  ])
;


// Popup animation
export function fadeInFadeOut(timing: number): AnimationTriggerMetadata  {
  return trigger('fadeInFadeOut', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate(timing, style({ opacity: 1 })),
    ]),
    transition(':leave', [
      animate(timing, style({ opacity: 0 }))
    ])
  ])
}

export function scaleInFadeOut(): AnimationTriggerMetadata  {
  return trigger('scaleInFadeOut', [
    state('scaleIn', style({ transform: 'scale(1)' })),
    state('fadeOut', style({ opacity: '0' })),
    transition('scaleIn => fadeOut',
      animate('{{fadeOutTiming}}ms'),
      {params: {fadeOutTiming: 0}}),
    transition('* => scaleIn', [
      style({ transform: 'scale(0)' }),
      animate('{{scaleInTiming}}ms', style({ transform: 'scale(1)' })),
    ], {params: {scaleInTiming: 0}})
  ])
}

export function scaleInOutFadeOut(): AnimationTriggerMetadata  {
  return trigger('scaleInOutFadeOut', [
    state('scaleInOut', style({ transform: 'scale(1)' })),
    state('fadeOut', style({ opacity: '0' })),
    transition('scaleInOut => fadeOut',
      animate('{{fadeOutTiming}}ms'),
      {params: {fadeOutTiming: 0}}),
    transition('* => scaleInOut', [
      style({ transform: 'scale(0)' }),
      animate('{{scaleInTiming}}ms', style({ transform: 'scale(1.07)' })),
      animate('{{scaleOutTiming}}ms', style({ transform: 'scale(1)' })),
    ], {params: {scaleInTiming: 0, scaleOutTiming: 0}})
  ])
}

export const scaleAnimation =
  animation([
    style({transform: '{{ initScale }}'}),
    animate('{{ time }}',
      keyframes([
        style({transform: 'scale(0)', offset: 0}),
        style({transform: 'scale(1.2)', offset: 0.7}),
        style({transform: 'scale(1)', offset: 1.0})
      ])
    )
  ])

export const scaleAnimationTrigger =
  trigger('scaleElement', [
    transition(':leave',
[
        style({transform: '{{ initScale }}'}),
        animate('0.5s',
          keyframes([
            style({transform: 'scale(1)', offset: 0}),
            style({transform: 'scale(0)', offset: 1.0})
          ])
        )
      ]
    ),
  ])
