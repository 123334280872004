/* globals angular */
/*
 * Select on click
 * */
function selectOnClick(
  $window
) {
  'ngInject';

  return {
    restrict: 'A',
    scope: {},
    link: function (scope, element, attrs) {
      element.on('click', function () {
        if (!$window.getSelection().toString()) {
          // Required for mobile Safari
          this.setSelectionRange(0, this.value.length)
        }
      });
    }
  };
}

selectOnClick.$inject = [
  '$window'
];

export default selectOnClick;
