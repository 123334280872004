import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-exhibitions-virtual-new-wrapper',
  templateUrl: './admin-content-manager-exhibitions-virtual-new-wrapper.component.html',
  styleUrl: './admin-content-manager-exhibitions-virtual-new-wrapper.component.scss'
})
export class AdminContentManagerExhibitionsVirtualNewWrapperComponent {

}
