import ExhibitionModel from './exhibition.model';

class ExhibitionResponseModel {
  constructor({success, items = []}) {
    this.success = success;
    this.items = items.map((exhibition) => new ExhibitionModel(exhibition));
  }
}

export default ExhibitionResponseModel;
