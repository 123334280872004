import ChatMemberModel from './chatMember.model';

class Mentions {
  constructor({members = []}) {
    this.members = members.map( member => new ChatMemberModel(member));
  }
}

class MessageModel {
  constructor({ mentions = {}, text = '', values = [] } = {}) {
    this.text = text;

    if (text) {
      this.origText = this.text;
    }

    if (mentions && mentions.members) {
      this.mentions = new Mentions(mentions);
    }
    this.values = values;
  }
}

export default MessageModel;
