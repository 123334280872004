import {
  Directive,
  Input,
  TemplateRef,
  ElementRef,
  OnInit,
  HostListener,
  ComponentRef,
  OnDestroy,
  Output, EventEmitter
} from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { CustomTooltipComponent } from "./custom-tooltip.component";

@Directive({
  selector: '[customToolTip]'
})
export class ToolTipDirective implements OnInit, OnDestroy {
  @Input() contentTemplate!: TemplateRef<any>;
  @Input() overlayX = 'end';
  @Input() offsetX = 0;
  @Input() offsetY = 0;
  @Output() onShow = new EventEmitter();

  private _overlayRef!: OverlayRef;

  constructor(
    private _overlay: Overlay,
    private _overlayPositionBuilder: OverlayPositionBuilder,
    private _elementRef: ElementRef,
  ) { }

  @HostListener('mouseenter')
  show(): void {
    if (this._overlayRef && !this._overlayRef.hasAttached()) {
      this.onShow.emit();
      const tooltipRef: ComponentRef<CustomTooltipComponent> = this._overlayRef.attach(new ComponentPortal(CustomTooltipComponent));
      tooltipRef.instance.contentTemplate = this.contentTemplate;
    }
  }

  @HostListener('mouseleave')
  hide(): void {
    this.closeToolTip();
  }

  ngOnInit() {
    const positionStrategy = this._overlayPositionBuilder
      .flexibleConnectedTo(this._elementRef)
      .withPositions([{
        originX: 'start',
        originY: 'bottom',
        overlayX: this.overlayX as any,
        overlayY: 'top',
        offsetX: this.offsetX,
        offsetY: this.offsetY
      }]);

    this._overlayRef = this._overlay.create({ positionStrategy});
  }

  ngOnDestroy(): void {
    this.closeToolTip();
  }

  private closeToolTip(): void {
    if (this._overlayRef) {
      this._overlayRef.detach();
    }
  }
}
