import {MediatorMessageKey, MediatorService} from "../../../../../core/services/mediator.service";

const template = require(`html-loader!./swapSuggestCard.html`).default;
import ImageModel from '../../../api/models/image/image.model';
import mixpanelEventsEnum from "../../mixpanel/mixpanel.enum";

class swapSuggestCardCtrl {
  challenge;
  constructor(
    Modals,
    $rootScope,
    ChallengesManager,
    challengeService,
    mixpanelService,
    MediatorService,
  ) {
    'ngInject';
    this.suggestedImages = [];
    this.busy = false;

    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.ChallengesManager = ChallengesManager;
    this.challengeService = challengeService;
    this.mixpanelService = mixpanelService;
    this.mediatorService = MediatorService;
  }

  $onInit() {
    // main
    this.gspopup.data.swap_image = new ImageModel(this.gspopup.data.swap_image);
    const challengeImage = _.find(this.challenge.member.ranking.entries, { id: this.gspopup.data.swap_image.id });
    if (challengeImage && challengeImage.votes) this.gspopup.data.swap_image.votes = challengeImage.votes;
    // images
    this.gspopup.data.images = this.gspopup.data.images.map(image => new ImageModel(image));
    // suggested
    this.suggestedImages = _.clone(this.gspopup.data.images);
    this.image = this.suggestedImages.splice(0, 1)[0];
  }

  change() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.SWAP_NOW);
    if (!this.suggestedImages.length) return;
    this.suggestedImages.push(this.image);
    this.image = this.suggestedImages.splice(0, 1)[0];
  }

  async swap() {
    this.eventMixPanelAction(mixpanelEventsEnum.UI_NAME.REPLACE_PHOTO);
    if (this.busy) return;
    this.busy = true;
    if (this.challengeService.isSwapsEmpty()) {
      await this.challengeService.outOfSwapLogic();
      return;
    }
    const res = await this.challengeService.swap(
      this.challenge.id,
      this.gspopup.data.swap_image.id,
      this.image.id
    );
    if (!res.success) {
      switch (res.error_code) {
        case 1002:
          this.challengeService.openStoreSwapsSection();
          break;
      }
      this.busy = false;
      if (!this.$rootScope.$$phase) this.$rootScope.$digest();
      return;
    } else {
      this.challengeService.updateSwapsAmount(-1);
      this.mediatorService.broadcast(MediatorMessageKey.GET_ACTIVE_CHALLENGE, {
        challengeId: this.challenge.id
      });
    }
    // Update data
    $(document).trigger('challenge-swap', [
      {
        c_id: this.challenge.id,
        prev_img_id: this.gspopup.data.swap_image.id,
        new_img: this.image,
      },
    ]);
    this.close();
    if (!this.$rootScope.$$phase) this.$rootScope.$digest();
  }

  eventMixPanelAction(uiName) {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.SWAP_SUGGESTION,
          ui_name: uiName,
          challenge_id: this.challenge.id
        }
      }
    );
  }
}
swapSuggestCardCtrl.$inject = [
  'Modals',
  '$rootScope',
  'ChallengesManager',
  'challengeService',
  'mixpanelService',
  'MediatorService',
];
export default {
  template: template,
  controller: swapSuggestCardCtrl,
  bindings: {
    challenge: '<',
    gspopup: '<',
    close: '&',
  },
};
