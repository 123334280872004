import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {MissionsService} from "../../services/missions.service";
import {Mission} from "../../missions.interface";
import {filter, Subject, switchMap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MissionClaimState} from "../../missions.enum";
import {BankrollService, IUpdateBankrollItemAmountParams} from "../../../bankroll/bankroll.service";
import {PrizeType} from "../../../../core/models/enums";
import {map} from "rxjs/operators";
import {MissionCardComponent} from "../mission-card/mission-card.component";
import {enterLeaveTriggerAnimation} from "../../../../core/animations/animations";

@Component({
  selector: 'app-missions-wrapper',
  templateUrl: './missions-wrapper.component.html',
  styleUrl: './missions-wrapper.component.scss',
  animations: [enterLeaveTriggerAnimation]
})
export class MissionsWrapperComponent implements OnDestroy, AfterViewInit {
  @ViewChild(MissionCardComponent) public missionCardComponent!: MissionCardComponent;
  public missions: Mission[] = [];
  public isLoading = true;
  public animationDisabled = true;
  private claimRewards$ = new Subject<number>();
  private claimedMission: Mission | null = null;
  public missionClaimState = MissionClaimState;
  constructor(
    public missionsService: MissionsService,
    public bankrollService: BankrollService
  ) {
    const appWrapperElement = document.getElementById('app-wrapper');
    if (appWrapperElement) {
      appWrapperElement.className = 'missions';
    }

    // @ts-ignore
    this.missionsService.missionsList$.pipe(filter((missions: Mission[]) => !!missions),
      map((missions: Mission[]) => {
        const filteredMissions = this.missionsService.filterMissions(missions);
        this.missionsService.sortMissions(filteredMissions);
        return filteredMissions;
      }),
      takeUntilDestroyed()
    ).subscribe((missions)=> {
      this.missions = this.animateMissionCards(missions as Mission[]);
      this.isLoading = false;
    });

    this.claimRewards$.pipe(
      switchMap((id: number) => this.missionsService.claimRewards(id)),
      takeUntilDestroyed()
    ).subscribe((response) => {
      if (response.success) {
        const bankrollItems = (this.claimedMission?.prizes ?? []).map(resource => ({
          resourceType: this.bankrollService.getResourceTypeByPrizeType(resource?.type as PrizeType),
          amountToAddOrReduce: resource.amount
        }));
        this.bankrollService.updateBankrollItemAmount(bankrollItems as IUpdateBankrollItemAmountParams[]);
        this.missions = (this.missions || []).filter((m: Mission) => m.id !== this.claimedMission?.id) || [];
        this.missionsService.missionsList$.next(this.missions);
        this.missions = this.missionsService.generateMissionsOffsets(this.missions)
        this.claimedMission = null;
      } else {
        // error notification
      }
    });

    this.missionsService.tabIndicatorEnabled$.next(false);
  }

  onMissionTimerExpired(mission: Mission): void {
    this.missions = (this.missions || []).filter((m: Mission) => m.id !== mission.id) || [];
    this.missionsService.missionsList$.next(this.missions);
  }

  claimRewards(mission: Mission, oldMission: Mission): void {
    oldMission.claimed = true;
    this.claimedMission = mission as Mission;
    this.claimRewards$.next(mission.id);
  }

  animateMissionCards(missions: Mission[]) {
    missions = missions.map((filteredMission: Mission, index: number) => ({
      ...filteredMission,
      offset: filteredMission.initialOffset || this.missionsService.createTranslateYStyle(index)
    }));

    setTimeout(async () => {
      const updatedItem = this.missions.find((filteredMission: Mission) => !!filteredMission.initialOffset);
      if (updatedItem) {
        const index = this.missions.findIndex((filteredMission: Mission) => !!filteredMission.initialOffset);
        updatedItem.offset = this.missionsService.createTranslateYStyle(index);
        this.missions = this.missionsService.generateMissionsOffsets(this.missions);
        // if(this.missionCardComponent.mission.id === updatedItem.id){
        //   this.missionCardComponent.updatePoints(updatedItem.progress.current);
        // }
      }
    });
    return missions;
  }

  trackBy(index: number, mission: Mission) {
    console.log(index)
    return mission.id;
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.animationDisabled = false;
    }, 500);
  }

  ngOnDestroy(): void {
    this.missionsService.clearMissionsDirtyMap();
  }
}
