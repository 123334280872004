
class pageData {
  constructor(
  ) {
    'ngInject';
  }

  get(pageName) {
    // Update body class
    const appWrapperElement = document.getElementById('app-wrapper');
    if (appWrapperElement) {
      appWrapperElement.className = pageName;
    }
  }
}
pageData.$inject = [];
let modulePageData = angular
  .module('gsApp.model.pageData', [])
  .service('pageData', pageData);

export default modulePageData.name;
