import LoaderEnum from "../../api/enums/loader.enum";

const template = require(`html-loader!./blockedUserItem.html`).default;

class BlockedUserItem {
  constructor(
    BlockedUserService,
    $scope
  ) {
    'ngInject';

    this.blockedUserService = BlockedUserService;
    this.$scope = $scope;

    this.isLoader = false;
    this.isUserBlocked = false;
    this.LoaderEnum = LoaderEnum;
  }

  async unblockUser() {
    this.isLoader = true;
    const unblockUserRespnose = await this.blockedUserService.unblockMembers([this.member.id]);
    this.isLoader = false;

    if(unblockUserRespnose.success) {
      this.isUserBlocked = true;
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}

BlockedUserItem.$inject = [
  'BlockedUserService',
  '$scope'
];

export default {
  controller: BlockedUserItem,
  template: template,
  bindings: {
    member: '<'
  }
};
