import { Component } from '@angular/core';

@Component({
  selector: 'app-teams-join-wrapper',
  templateUrl: './teams-join-wrapper.component.html',
  styleUrl: './teams-join-wrapper.component.scss'
})
export class TeamsJoinWrapperComponent {

}
