import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-challenges-challenges-ranking-levels-wrapper',
  templateUrl: './admin-content-manager-challenges-challenges-ranking-levels-wrapper.component.html',
  styleUrl: './admin-content-manager-challenges-challenges-ranking-levels-wrapper.component.scss'
})
export class AdminContentManagerChallengesChallengesRankingLevelsWrapperComponent {

}
