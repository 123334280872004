import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";
const template = require(`html-loader!./myTeamChat.html`).default;
import ChatMessageModel from '../../../api/models/chat/chatMessage.model';
import TeamModel from '../../../api/models/team/team.model';
import FeatureMessageModel from "../../../api/models/featureMessage.model";
import chatEnum from '../../../api/enums/chat.enum';
import adultFilterEnum from "../../../api/enums/adultFilter.enum";
import teamEnum from '../../../api/enums/team.enum';
import { getTextLineBreakToBr } from "../../../helpers/string.helper";
import { parseFromSecToMilisec } from "../../../helpers/date.helper";

const NEW_MESSAGES = 'above';
const OLD_MESSAGES = 'below';
const AT_SYMBOL = '@';

class myTeamChatCtrl {

  constructor(
    $timeout,
    $element,
    $scope,
    $rootScope,
    stateService,
    socketService,
    RestManager,
    notificationAlert,
    $sce,
    teamsService,
    $cookies,
    $window,
    $filter,
    $interval,
    Modals,
    chatService,
    MemberService,
    RouterService,
  ) {
    'ngInject';

    this.team;
    this.chatMessages = [];
    this.mentionMemberList = [];
    this.selectedMentions = [];
    this.textMessage = '';
    this.replyMessage;
    this.ready = false;
    this.busy = false;
    this.maxMessageLength = 4000;
    this.loadMore = {
      above: true,
      below: true
    };
    this.limit = {
      above: 20,
      below: 20,
    };
    this.messagesClass = ".my-team-chat__chat-messages";
    this.messageContainerClass = ".my-team-chat__chat-message-container_";
    this.unreadClass = 'my-team-chat__unread';
    this.messageInputClass = '.my-team-chat__chat__bottom__send-message__textarea';
    this.lastScroll = 0;
    this.lastSavedMessageTs = 0;
    this.showScrollButtonDelta = 200;
    this.loadMessagesDelta = 300;
    this.messagesDateTitleRefreshDuration = 5000;

    this.$scope = $scope;
    this.RouterService = RouterService;
    this.$rootScope = $rootScope;
    this.socketService = socketService;
    this.RestManager = RestManager;
    this.stateService = stateService;
    this.notificationAlert = notificationAlert;
    this.$element = $element;
    this.$timeout = $timeout;
    this.chatEnum = chatEnum;
    this.$sce = $sce;
    this.teamsService = teamsService;
    this.$cookies = $cookies;
    this.$window = $window;
    this.$filter = $filter;
    this.$interval = $interval;
    this.Modals = Modals;
    this.chatService = chatService;
    this.member = MemberService.getCurrentMember();
    this.adultFilterEnum = adultFilterEnum;
  }

  $onInit() {
    this.socket = this.socketService.getSocket();
    this.setSocketListeners();
    //when user return to app and new messages entered
    this.offOnChatTabFocus = this.$rootScope.$on('onChatTabFocus', this.onChatTabFocus.bind(this));
    this.init();
    this.$window.onbeforeunload = () => {
      this.setLastVisibleMessage();
    };
  }

  $onDestroy() {
    this.socketService.removeListener(this.socketService.socketEvents.msgToClient, this.msgToClientListener);
    $(this.messagesClass).off('scroll');
    this.$interval.cancel(this.stopRefreshData);
    this.setLastVisibleMessage();
    this.offOnChatTabFocus();
  }

  async init() {
    this.messagesSelector = $(this.messagesClass);
    this.lastSavedMessage = this.teamsService.getChatCookie();

    if (this.lastSavedMessage && this.lastSavedMessage.origDateTime && this.lastSavedMessage.messageId) {
      await this.getTeamMessages(
        {
          start_time: this.lastSavedMessage.origDateTime,
          limitNewMessages: this.limit[NEW_MESSAGES],
          limitOldMessages: this.limit[OLD_MESSAGES],
          messageId: this.lastSavedMessage.messageId,
        });
      if (this.getLastMessageInList() && this.getLastMessageInList().id === this.lastSavedMessage.messageId) {
        await this.oldMessagesMode();
      } else {
        this.lastSavedMessageTs = this.parseMilisecToSec(this.lastSavedMessage.dateTime);
        this.unreadMessagesMode = true;
        await this.goToMessage(this.lastSavedMessage.messageId, true);
      }
    } else {
      await this.getTeamMessages(
        {
          scrollDirection: OLD_MESSAGES,
          limitOldMessages: this.limit[OLD_MESSAGES],
        });
      await this.oldMessagesMode();
    }
    $(this.messagesClass).on('scroll', this.onMessagesScroll.bind(this));
    this.setDateTitleInterval();
    this.setOffFocusLastMessage();
  }

  getLastMessageInList() {
    return this.chatMessages.length && this.chatMessages[this.chatMessages.length - 1] ? this.chatMessages[this.chatMessages.length - 1] : null;
  }

  async getTeamMessages({
                          start_time = 0,
                          scrollDirection,
                          limitNewMessages = 0,
                          limitOldMessages = 0,
                          reset = false,
                          messageId,
                        }) {
    if (!this.busy && (!scrollDirection || this.loadMore[scrollDirection])) {
      if (reset) {
        this.chatMessages = [];
        this.loadMore = { above: true, below: true };
        this.initFinished = false;
      }
      this.busy = true;
      let reqData = {
        chat_id: this.team?.chat_id,
        limit_above: limitNewMessages,
        limit_below: limitOldMessages,
        start_time: start_time,
      };
      const res = await this.RestManager.getTeamChatMessages(reqData);
      if (!res.success) {
        this.stateService.goToDefaultState();
        return;
      }
      this.busy = false;
      const data = res.chatMessages ? this.parseChatMessages(res.chatMessages) : [];

      if (scrollDirection === OLD_MESSAGES) {
        this.chatMessages = [...data, ...this.chatMessages];
      } else {
        this.chatMessages = [...this.chatMessages, ...data];
      }
      // update load more flag for both scroll directions
      if (scrollDirection) {
        if (data.length < this.limit[scrollDirection]) {
          this.loadMore[scrollDirection] = false;
        }
      } else {
        //load by last unread message so no scroll direction
        const index = data.findIndex((item) => {
          return item.id === messageId;
        });
        if (index !== -1) {
          this.loadMore[NEW_MESSAGES] = (data.length - 1 - index) === this.limit[NEW_MESSAGES];
          this.loadMore[OLD_MESSAGES] = index === this.limit[OLD_MESSAGES];
        }
      }
      this.$rootScope.teamsShowTabNotificationIcon = false;
      this.initFinished = true;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  parseChatMessages(chatMessages) {
    return chatMessages.map((chatMessage) => {
      return this.parseChatMessage(chatMessage);
    });
  }

  parseChatMessage(chatMessage) {
    let cm = new ChatMessageModel(chatMessage);
    cm.dateTime = parseFromSecToMilisec(cm.dateTime);
    cm.dateTitle = this.getDateByMessage(cm);

    if (cm.type === chatEnum.CHAT_MESSAGE.TYPE.DEFAULT) {
      //remove multiple avatars
      cm.member = this.getTeamMember(cm.memberID);
      if (this.avatarMemberId && cm.memberID === this.avatarMemberId) {
        cm.dontShowAvatar = true;
      } else {
        this.avatarMemberId = cm.memberID;
      }

      //add mentions
      if (cm.message.mentions && cm.message.mentions.members && cm.message.mentions.members.length) {
        cm.origMessage = angular.copy(cm.message);
        cm.message.mentions.members.forEach((member) => {
          cm.message.text = cm.message.text.replace(new RegExp(member.displayName, 'g'), this.getMemberTag(member.id, member.displayName));
        });
      }
    }

    if (cm.type === chatEnum.CHAT_MESSAGE.TYPE.SYSTEM) {
      this.avatarMemberId = null;
      cm.message.values.forEach((value) => {
        if (value.type === chatEnum.MESSAGE.TYPE.DEEP_LINK && value.subtype === chatEnum.MESSAGE.SUB_TYPE.USER_PROFILE) {
          cm.message.text = cm.message.text.replace('_%GS%_', this.getMemberTag(value.value, value.text));
        }
      });
    }

    if (cm.type === chatEnum.CHAT_MESSAGE.TYPE.MATCH) {
      cm.message.values.forEach((value) => {
        if (value.type === chatEnum.MESSAGE.TYPE.CHALLENGE) {
          value.value = new NgChallengeModel(value.value);
          value.value.votes = this.$filter('abbreviateNumber')(value.value.votes);
          value.value.votes = value.value.votes === '0' ? 0 : value.value.votes;
        }
        if (value.type === chatEnum.MESSAGE.TYPE.TEAMS) {
          value.value = value.value.map(team => new TeamModel(team));
        }
      });

      switch (cm.additional.system_event) {
        case chatEnum.SYSTEM_EVENT.MATCH_SELECTED:
          cm.matchTitle = 'New match selected!';
          break;
        case chatEnum.SYSTEM_EVENT.MATCH_STARTED:
          cm.matchTitle = 'Match started!';
          break;
        case chatEnum.SYSTEM_EVENT.MATCH_TIMEOUT:
          cm.matchTitle = 'Match timeout';
          cm.matchSubtitle = cm.message.text;
          break;
        case chatEnum.SYSTEM_EVENT.MATCH_CANCELLED:
          cm.matchTitle = 'Match canceled';
          cm.matchSubtitle = cm.message.text;
          break;
        case chatEnum.SYSTEM_EVENT.MATCH_CLOSED:
          cm.myTeam = cm.message.values[1].value[0];
          cm.opponentTeam = cm.message.values[1].value[1];
          cm.myTeamWon = cm.myTeam.total_votes >= cm.opponentTeam.total_votes;
          cm.matchTitle = cm.myTeamWon ? 'Congrats!!' : 'Maybe next time…';
          cm.matchSubtitle = cm.message.text.replace('_%GS%_', `"${cm.message.values[0].text}"`);
          break;
      }
    }

    if (cm.type === chatEnum.CHAT_MESSAGE.TYPE.LEAGUE) {
      cm.featureMessage = new FeatureMessageModel({ data: cm.message.values[0].value });
    }

    if (cm.type === chatEnum.CHAT_MESSAGE.TYPE.DEFAULT) {
      cm.message.text = getTextLineBreakToBr(cm.message.text);
    }
    return cm;
  }

  getTeamMember(memberId) {
    let m = this.team.members.find(member => member.id === memberId);
    return m || null;
  }

  goToProfile(userName) {
    if (userName) {
      this.stateService.goToState(userName);
    }
  }

  trustedHtml(plainText) {
    return this.$sce.trustAsHtml(plainText);
  }

  getMessageSelectorById(messageId) {
    return $(`${this.messageContainerClass}${messageId}`);
  }

  async oldMessagesMode() {
    //no new messages scroll to last message and save it
    this.loadMore[NEW_MESSAGES] = false;
    await this.scrollToBottom();
    this.reset();
  }

  reset() {
    this.showNotificationIcon = false;
    this.unreadMessagesMode = false;
    this.lastSavedMessageTs = 0;
    this.teamsService.resetChatCookie();
  }

  goToMessage(messageId, isUnreadMessage = false) {
    return this.$timeout(() => {
      const goToMessageSelector = this.getMessageSelectorById(messageId);
      if (this.messagesSelector.length && goToMessageSelector.length) {
        if (isUnreadMessage) {
          $(`.${this.unreadClass}`).remove();
          $(`<div class="${this.unreadClass}">Unread Messages</div>`).insertAfter(goToMessageSelector);
        }
        this.messagesSelector.scrollTo(goToMessageSelector, 0);
        this.flagUnreadMessagesInView();
      } else {
        if (isUnreadMessage) {
          this.oldMessagesMode();
        }
      }
    });
  }

  async goToReplyMessage(replyMessage) {
    if (this.lastClickedReplyMessage) {
      return;
    }

    const goToMessageSelector = this.getMessageSelectorById(replyMessage.id);
    console.log('goToMessageSelector', goToMessageSelector);
    if(!goToMessageSelector.length) {
      this.lastMessageBeforeReload =  this.getLastVisibleMessage();
      await this.getTeamMessages(
        {
          start_time: replyMessage.origDateTime,
          limitNewMessages: this.limit[NEW_MESSAGES],
          limitOldMessages: this.limit[OLD_MESSAGES],
          reset: true,
          messageId: replyMessage.id,
        });
    }
    this.goToMessage(replyMessage.id);
    this.lastClickedReplyMessage = replyMessage;
    this.$timeout(() => {
      this.lastClickedReplyMessage = null;
    }, 3000);
  }

  async goToNewestMessage() {
    if (this.loadMore[NEW_MESSAGES]) {
      await this.getTeamMessages(
        {
          scrollDirection: OLD_MESSAGES,
          limitOldMessages: this.limit[OLD_MESSAGES],
          reset: true,
        });
    }
    this.oldMessagesMode();
  }

  scrollToBottom() {
    return this.$timeout(() => {
      if (this.messagesSelector.length) {
        this.messagesSelector[0].scrollTop = this.messagesSelector[0].scrollHeight;
      }
    });
  }

  scrollRefesh() {
    const selector = $(this.messagesClass);
    if (selector[0] && selector.scrollTop() <= 50) {
      this.$timeout(() => {
        selector.scrollTop(200);
      });
    }
  }

  setSocketListeners() {
    this.msgToClientListener = this.msgToClient.bind(this);
    this.socketService.addListener(this.socketService.socketEvents.msgToClient, this.msgToClientListener);
  }

  msgToClient(data) {
    const chatMessage = this.parseChatMessage(data);

    if (chatMessage.type === chatEnum.CHAT_MESSAGE.TYPE.SYSTEM) {
      if ([chatEnum.SYSTEM_EVENT.REMOVED, chatEnum.SYSTEM_EVENT.LEFT].includes(chatMessage.additional.system_event) &&
        chatMessage.message.values[0].value === this.team.current_member.member_id) {
        this.teamsService.resetTeams();
        this.RouterService.reloadRoute('teams');

        return;
      }

      if ([chatEnum.SYSTEM_EVENT.REMOVED, chatEnum.SYSTEM_EVENT.LEFT, chatEnum.SYSTEM_EVENT.JOINED].includes(chatMessage.additional.system_event)) {
        this.$scope.$emit('teamChanged', { teamId: this.team.id });
      } else if (
        chatMessage.additional.system_event === chatEnum.SYSTEM_EVENT.PROMOTE_ADMIN
        && chatMessage.message.values[1].value === this.team.current_member.member_id
      ) {
        this.teamsService.openTeamIntro(teamEnum.INTRO_STATES.TEAM_LEADER);
        this.$scope.$emit('teamChanged', { teamId: this.team.id });
      }
    }

    if (chatMessage.type === chatEnum.CHAT_MESSAGE.TYPE.MATCH) {
      this.$rootScope.$emit('onMatchChanged');
      this.$rootScope.$emit('teamMemberChestReload');
    }

    if (chatMessage.type === chatEnum.CHAT_MESSAGE.TYPE.LEAGUE) {
      this.$rootScope.$emit('onLeagueChanged');
    }

    const isMyChatMessage = chatMessage.isMyMessage(this.team.current_member.member_id);
    // dont add a message to the list (if its not from me)if i have more new messages from the server
    if (!isMyChatMessage && this.loadMore[NEW_MESSAGES]) {
      return;
    }
    //add message to the list
    this.chatMessages.push(chatMessage);

    if (isMyChatMessage || this.isScrollReachedBottom(this.loadMessagesDelta)) {
      this.goToNewestMessage();
    } else {
      this.showNotificationIcon = this.showScrollBottomBtn;
    }
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  sendMessage() {
    this.chatService.sendMessage({
      textMessage:this.textMessage,
      replyMessage: this.replyMessage,
      selectedMentions: this.selectedMentions,
      selectedPhoto: this.selectedPhoto
    });

    this.replyMessage = null;
    this.cancelPhotoShare();
    this.textMessage = "";
    this.selectedMentions = [];
    this.closeMemberList();
    this.hideEmoji();
    this.$timeout(this.setTextAreaAutoGrow(true));
  }

  parseMilisecToSec(milisec) {
    return Math.round(milisec / 1000);
  }

  setDateTitleInterval() {
    this.stopRefreshData = this.$interval(() => {
      this.showMessagesDateTitle = false;

      if (!this.$rootScope.$$phase) {
        this.$rootScope.$digest();
      }
    }, this.messagesDateTitleRefreshDuration);
  }

  onMessagesScroll() {
    this.showMessagesDateTitle = true;

    let direction;
    const messagesClassSelector = $(this.messagesClass);
    // check scroll direction by saving the last scroll top;
    if (this.lastScroll) {
      direction = this.lastScroll >= messagesClassSelector.scrollTop() ? OLD_MESSAGES : NEW_MESSAGES;
    }
    this.lastScroll = messagesClassSelector.scrollTop();

    //flag unread messages that are in view if have last saved message
    this.flagUnreadMessagesInView();

    // show scroll to bottom button if not in bottom + delta
    if (this.showScrollBottomBtn !== !this.isScrollReachedBottom(this.showScrollButtonDelta)) {
      this.showScrollBottomBtn = !this.isScrollReachedBottom(this.showScrollButtonDelta);
      if (!this.showScrollBottomBtn) {
        this.showNotificationIcon = false;
      }
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }

    if (direction && this.loadMore[direction] && this.socketService.roomCreated && !this.busy) {
      const scrollDelta = (messagesClassSelector[0].scrollHeight / 3) > this.loadMessagesDelta ?
        Math.min(messagesClassSelector[0].scrollHeight / 3, 2000) : this.loadMessagesDelta;
      // check if reached top
      const oldMessageLoadMore = this.isScrollReachedTop(scrollDelta);
      // check if reached bottom
      const newMessageLoadMore = this.isScrollReachedBottom(scrollDelta);

      let lastMessageStartTime;
      let limitNew;
      let limitOld;

      if (direction === OLD_MESSAGES && oldMessageLoadMore) {
        lastMessageStartTime = this.parseMilisecToSec(this.chatMessages[0].dateTime);
        limitNew = 0;
        limitOld = this.limit[direction];
      } else if (direction === NEW_MESSAGES && newMessageLoadMore) {
        lastMessageStartTime = this.parseMilisecToSec(this.chatMessages[this.chatMessages.length - 1].dateTime);
        limitNew = this.limit[direction];
        limitOld = 0;
      } else {
        return;
      }

      this.getTeamMessages(
        {
          start_time: lastMessageStartTime,
          scrollDirection: direction,
          limitNewMessages: limitNew,
          limitOldMessages: limitOld,
        }).then(() => {
        // reset last scroll top after load
        this.lastScroll = null;

        this.scrollRefesh();
        this.busy = false;

        if (!this.$rootScope.$$phase) {
          this.$rootScope.$digest();
        }
      });
    }
  }

  isScrollReachedTop(delta = 0) {
    const messagesClassSelector = $(this.messagesClass);

    if (messagesClassSelector[0]) {
      return messagesClassSelector.scrollTop() <= delta;
    }

    return false;
  }

  isScrollReachedBottom(delta = 0) {
    const messagesClassSelector = $(this.messagesClass);

    if (messagesClassSelector[0]) {
      return messagesClassSelector.scrollTop() + messagesClassSelector.innerHeight() >= messagesClassSelector[0].scrollHeight - delta;
    }

    return false;
  }

  onTextareaChange() {
    this.setTextAreaAutoGrow();
    this.checkMentions();
  }

  setTextAreaAutoGrow(reset = false) {
    const ta = $(this.messageInputClass).get(0);

    if (ta) {
      ta.style.height = 'auto';
      if (reset) {
        ta.style.height = '30px';
        $('.my-team-chat__chat__bottom__send-message').css('border-radius', `20px`);
        return;
      }
      ta.style.height = (ta.scrollHeight) + 'px';
      $('.my-team-chat__chat__bottom__send-message').css('border-radius', `${ta.scrollHeight > 32 ? '8' : '20'}px`);
      const emojiElement = $('.emoji-popover.top')[0];

      if (emojiElement) {
        const numOfLines = Math.floor(ta.scrollHeight / 28);
        const a = -271 - (numOfLines > 1 ? (Math.min(numOfLines, 4) * 7) : 0) + 'px';
        emojiElement.style.setProperty('top', a, 'important');
      }
    }
  }

  checkMentions() {
    const ta = $(this.messageInputClass).get(0);

    if (ta) {
      const lastTypedCharIndex = $(this.messageInputClass).get(0).selectionStart - 1;
      if (this.textMessage.length) { // if text not empty
        // const lastTypedChar = this.textMessage.charAt(lastTypedCharIndex);
        this.lastIndexOfAt = this.textMessage.lastIndexOf(AT_SYMBOL, lastTypedCharIndex);
        if (this.lastIndexOfAt === -1) { // no at symbol
          // console.log('no at symbol');
          this.closeMemberList();
          return;
        }

        if (this.lastIndexOfAt !== 0 && ![' ', '\n'].includes(this.textMessage.charAt(this.lastIndexOfAt - 1))) { // no space before the at sign
          console.log('no space before the at sign');
          return;
        }

        const textFromLastAt = this.textMessage.substring(this.lastIndexOfAt); //all text from last at symbol

        if (textFromLastAt.match(/\s/g) && textFromLastAt.match(/\s/g).length > 1) { // more then one space
          console.log('more then one space');
          return;
        }

        const tagsMatch = textFromLastAt.match(/(\@\S\w*(\s|\.)?\w*|\@)/g);
        console.log('tagsMatch', tagsMatch);
        if (!tagsMatch) { // 'no matches'
          console.log('no matches');
          return;
        }
        this.showMentionList(tagsMatch[0]);
      } else {
        this.closeMemberList();
      }
    }
  }

  showMentionList(mentionText) {
    this.mentionText = mentionText;
    this.closeMemberList();
    const membersList = this.team.members.filter(member => this.team.current_member.member_id !== member.id);

    if (mentionText === '@') { // show all
      this.mentionMemberList = membersList;
    } else {
      this.mentionMemberList = membersList.filter((member) => {
        const re = new RegExp(mentionText.replace('@', ''), 'i');
        return member.name.search(re) !== -1 || member.user_name.search(re) !== -1;
      });
    }
  }

  addMentionToMessage(member) {
    const text = this.textMessage.substr(0, this.lastIndexOfAt) +
      member.displayName + ' ' +
      this.textMessage.substr(this.lastIndexOfAt + this.mentionText.length, this.textMessage.length);
    this.textMessage = text;
  }

  onMemberSelect(member) {
    console.log('onMemberSelect');
    this.addMentionToMessage(member);
    this.messageinputFocus();
    this.selectedMentions.push(member);
    this.$timeout(() => {
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      this.setTextAreaAutoGrow();
      this.closeMemberList();
    });
  }

  closeMemberList(digest) {
    this.mentionMemberList = [];
    if (digest) {
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  getMemberTag(memberId, displayName) {
    return `<span class="my-team-chat__chat-message__system__user-name" style="color:${ChatMessageModel.getChatMemberColor(memberId)}">${displayName}</span>`;
  }

  onChatKeyup(e) {
    const enterKeyCode = 13;
    const backspaceKeyCode = 8;

    if (e.keyCode === enterKeyCode && !this.mentionMemberList.length && this.textMessage.length) {
      if (e.shiftKey) {
        this.textMessage += '\n';

      } else {
        this.sendMessage();
      }
    }
    if([enterKeyCode,backspaceKeyCode].includes(e.keyCode)) {
      this.$timeout(this.onTextareaChange.bind(this));
    }
  }

  hideEmoji() {
    const emojiCloseBtnElement = $('.emoji-popover .close-button');
    if (emojiCloseBtnElement[0]) {
      emojiCloseBtnElement.click();
    }
  }

  visible_in_container(p, e) {
    const pRect = p.getBoundingClientRect();
    const eRect = e.getBoundingClientRect();

    // Check style visiblilty and off-limits
    return !e.style.opacity && e.style.display !== 'none' &&
      e.style.visibility !== 'hidden' &&
      !(eRect.top > pRect.bottom || eRect.bottom < pRect.top ||
        eRect.left > pRect.right || eRect.right < pRect.left);
  }

  flagUnreadMessagesInView() {
    let firstItemInView;
    for (let m of this.chatMessages) {
      if (!firstItemInView || (this.lastSavedMessageTs < m.dateTime && !m.unreadInView)) {
        if (this.visible_in_container($(this.messagesClass)[0], this.getMessageSelectorById(m.id)[0])) {
          m.unreadInView = true;
          if (!firstItemInView) {
            firstItemInView = m;
            if (!this.unreadMessagesMode) {
              break;
            }
          }
        }
      }
    }
    if (firstItemInView) {
      this.firstItemInViewDate = '';
      this.firstItemInViewDate = this.getDateByMessage(firstItemInView);
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  getDateByMessage(message) {
    const dateFormat = 'dd MMM';
    const currentDate = new Date();
    const messageDate = new Date(message.dateTime);
    if (currentDate.getFullYear() === messageDate.getFullYear() &&
      currentDate.getMonth() === messageDate.getMonth()) {
      if (currentDate.getDate() === messageDate.getDate()) {
        return 'Today';
      } else if (currentDate.getDate() - 1 === messageDate.getDate()) {
        return 'Yesterday';
      }
      return this.$filter('date')(messageDate, dateFormat);
    }
    return this.$filter('date')(messageDate, dateFormat);
  }


  setLastVisibleMessage() {
    if (!this.chatMessages.length) {
      return;
    }
    let lastUnreadMessageInView = this.getLastVisibleMessage();
    // if clicked on reply message and reset list, dont save last message cookie if last message before reset is newer then the one we want to save
    if (this.lastMessageBeforeReload && this.lastMessageBeforeReload.dateTime >= lastUnreadMessageInView.dateTime) {
      return;
    }

    if (lastUnreadMessageInView) {
      this.teamsService.setChatCookie(lastUnreadMessageInView.id, lastUnreadMessageInView.dateTime, lastUnreadMessageInView.origDateTime);
    }
  }

  getLastVisibleMessage() {
    if (!this.chatMessages.length) {
      return null;
    }

    if (!this.unreadMessagesMode) {
      return this.chatMessages[this.chatMessages.length - 1];
    } else {
      this.chatMessages.reverse();
      for (let m of this.chatMessages) {
        if (m.unreadInView) {
          return m;
        }
      }
    }
  }

  onChatTabFocus(e, data) {
    if (!data.tabVisible) {
      this.setOffFocusLastMessage();

      return;
    }

    if (!$(`.${this.unreadClass}`).length && data.tabVisible && data.newMessagesFlag && this.offFocusLastMessage) {
      this.goToMessage(this.offFocusLastMessage.id, true);
    }
  }

  setOffFocusLastMessage() {
    this.offFocusLastMessage = this.getLastMessageInList();
  }

  reply(chatMessage) {
    this.messageinputFocus();
    this.replyMessage = chatMessage;
  }

  messageinputFocus() {
    $(this.messageInputClass).focus();
  }

  cancelReply() {
    this.replyMessage = null;
    this.textMessage = '';
    this.$timeout(this.setTextAreaAutoGrow());
  }

  cancelPhotoShare(){
    this.selectedPhoto = null;
  }

  photoShare(event){
    this.Modals.open('gsUploader', event, {
      target: 'chat',
      source: 'profile',
      state: 'photos',
      items_limit: 1,
      member: {
        id: this.member.id
      },
      callback: images => {
        console.log('selectedPhoto', images);
        this.selectedPhoto = images[0];
        console.log('selectedPhoto', this.selectedPhoto);
      }
    });
  }
}
myTeamChatCtrl.$inject = [
  '$timeout',
  '$element',
  '$scope',
  '$rootScope',
  'stateService',
  'socketService',
  'RestManager',
  'notificationAlert',
  '$sce',
  'teamsService',
  '$cookies',
  '$window',
  '$filter',
  '$interval',
  'Modals',
  'chatService',
  'MemberService',
  'RouterService',
];
export default {
  template: template,
  controller: myTeamChatCtrl,
  bindings: {
    team: '<',
  },
};
