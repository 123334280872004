const template = require(`html-loader!./gsImage.html`).default;

class gsImageCtrl {
  constructor() {
    'ngInject';
  }
}

gsImageCtrl.$inject = [

];

export default {
  template: template,
  controller: gsImageCtrl,
  bindings: {
    srcData:'<', // {src: ${url}
    lazy: '@',
  }
};
