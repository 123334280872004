const template = require(`html-loader!./myChallengesMedia.html`).default;

import myChallengesEditorBase from "../myChallengesEditorBase";

class myChallengesMediaController extends myChallengesEditorBase {

  constructor($scope, Toasts, $element, RestManager) {
    'ngInject';

    super({$scope, Toasts, $element, RestManager});

    this.busy = true;
    this.amoutOfMedia = 4;

    this.$scope = $scope;
    this.Toasts = Toasts;
    this.$element = $element;
    this.restManager = RestManager;
  }

  $onInit() {
    let youtube = this.challenge.media.filter((item) => item.type === 'youtube');
    if(!youtube.length) {
      youtube = [{
        "type": "youtube",
        "data": {
          "id": ""
        }
      }];
    }
    let images = this.challenge.media.filter((item) => item.type === 'image');

    this.challenge.media = [...youtube, ...images];

    this.busy = false;
  }

  save() {
    console.log('this.challenge.media 1 ', this.challenge.media);

    const media = this.challenge.media.filter((item) => {
      if(!item.type && !!item.data.id) {
        item.type = 'image';
      }

      return !!item.type && !!item.data.id;
    });

    console.log('this.challenge.media 2 ', media);

    this.restManager.setChallengeMedia({
      challenge_id: this.challenge.id,
      media: media
    });
  }
}
myChallengesMediaController.$inject = [
  '$scope', 'Toasts', '$element', 'RestManager'
];
export default {
  template: template,
  controller: myChallengesMediaController,
  bindings: {
    challenge: '<'
  }
};
