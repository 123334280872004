const template = require(`html-loader!./mobileHeaderEmpty.html`).default;

class mobileHeaderEmptyCtrl {
  constructor(
    $scope,
    $element,
    $mdSidenav,
    Modals,
    eventsHeaderService,
    protectionService
  ) {
    'ngInject';
    this.isScrolled = false;

    this.$scope = $scope;
    this.$element = $element;
    this.$mdSidenav = $mdSidenav;
    this.Modals = Modals;
    this.eventHeaderService = eventsHeaderService;
    this.eventHeader = this.eventHeaderService.getEvent();
    this.protectionService = protectionService;
  }

  onScroll() {
    const pos = document.documentElement.scrollTop || document.body.scrollTop;
    this.isScrolled = true;
    if (pos > 100 ) {
      this.$element.addClass('white');
    } else {
      this.$element.removeClass('white');
    }

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  toggleMenu() {
    this.$mdSidenav('mobileMenu').toggle();
  }

  login(event) {
    this.Modals.open('login', event);
  }

  signUp(event) {
    this.Modals.open('login', event, { signUp: true });
  }
}

mobileHeaderEmptyCtrl.$inject = [
  '$scope',
  '$element',
  '$mdSidenav',
  'Modals',
  'eventsHeaderService',
  'protectionService'
];

export default {
  template: template,
  controller: mobileHeaderEmptyCtrl,
};
