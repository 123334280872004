import {ChallengeGroup} from "../../../../../modules/challenges/enums/challenges.enum";

const template = require(`html-loader!./challengesUpcoming.html`).default;
import {filter, Subject, takeUntil} from "rxjs";
const IS_MOBILE = 768;

class challengesUpcomingCtrl {
  /** @param {ChallengesManager} ChallengesManager **/

  subject = new Subject();
  ChallengeGroup = ChallengeGroup;
  selectedFastFilters = {};
  constructor(
    $timeout,
    $location,
    $window,
    Modals,
    ChallengesManager,
    $scope,
    tagEventService,
    JoinedChallengesPageService,
    MediatorService,
  ) {
    'ngInject';
    this.busy = true;

    this.$timeout = $timeout;
    this.$location = $location;
    this.$window = $window;
    this.Modals = Modals;
    this.ChallengesManager = ChallengesManager;
    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.JoinedChallengesPageService = JoinedChallengesPageService;
    this.mediatorService = MediatorService;

    this.onResize = _.debounce(this.onResize.bind(this), 250);
    this.filterBySelectedTypesBinded = this.filterBySelectedTypes.bind(this);
  }

  async $onInit() {
    this.selectedFastFilters = this.JoinedChallengesPageService.selectedFastFilters;
    // virtual page view
    this.tagEventService.sendPageViewEvent({title: 'Upcoming challenges'});
    // mobile view
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    $(window).on('resize', this.onResize);
    // scroll to top
    this.$window.scrollTo(0, 0);

    this.JoinedChallengesPageService.$fastFilters
    .pipe(
      filter(res => !!res),
      takeUntil(this.subject)
    ).subscribe(()=>{
      this.selectedFastFilters = this.JoinedChallengesPageService.selectedFastFilters;
    });

    this.JoinedChallengesPageService.$upcomingChallenges.pipe(
      takeUntil(this.subject)
    ).subscribe((challenges)=>{
      if(challenges === undefined){return}
      this.items = challenges;
      this.busy = false;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    });
    this.JoinedChallengesPageService.getUpcomingChallenges();
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    $(window).off('resize', this.onResize);
  }

  onResize() {
    this.isMobile = document.body.clientWidth <= IS_MOBILE;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  filterBySelectedTypes (item) {
    // If no types are selected, show all items
    if (!this.isAnyFilterSelected()) {
      return false;
    }

    // Show the item if its type is selected
    return this.selectedFastFilters[item.type];
  };

  onFastFilterChange(selectedFastFilters){
    // this.selectedFastFilters = selectedFastFilters;
  }
  isAnyFilterSelected(){
    return Object.values(this.selectedFastFilters).includes(true)
  }
}
challengesUpcomingCtrl.$inject = [
  '$timeout',
  '$location',
  '$window',
  'Modals',
  'ChallengesManager',
  '$scope',
  'tagEventService',
  'JoinedChallengesPageService',
  'MediatorService',
];
export default {
  template: template,
  controller: challengesUpcomingCtrl,
};
