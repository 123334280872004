/* globals angular */
const template = require(`html-loader!./expoSelection.html`).default;

class expoSelectionToastCtrl {
  constructor($mdToast) {
    'ngInject';

    this.$mdToast = $mdToast;
  }

  close() {
    this.$mdToast.hide();
  }
}
expoSelectionToastCtrl.$inject = [
  '$mdToast'
];
export default {
  template: template,
  controller: expoSelectionToastCtrl,
  bindings: {}
};
