import FollowerModel from './follower.model';

class FollowersResponseModel {
  constructor({base_url, success, items = []}) {
    this.success = success;
    this.base_url = base_url;
    this.items = items.map((item) => new FollowerModel(item));
  }
}

export default FollowersResponseModel;
