class ModalsServiceBridge {
  constructor(
    Modals
  ) {
    this.Modals = Modals;
  }

  getModalsService() {
    return this.Modals;
  }
}

ModalsServiceBridge.$inject = [
  'Modals'
];

export default ModalsServiceBridge;
