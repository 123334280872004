import {Injectable} from '@angular/core';
// @ts-ignore
import LocalStorageModel from "../../gsApp/app/services/saveToClientSide/models/localStorage.model";
import {MemberService} from "./member.service";
import {NgLocalStorageKeysEnum} from "../models/enums";

@Injectable()
export class NgLocalStorageService {

  constructor(
    private memberService:MemberService
  ) { }

  //TODO add expiration time option and refactor all existing usages
  public saveMemberDataByKeyValue(key: NgLocalStorageKeysEnum, value: any):void {
    if(!this.memberService.getCurrentMember()?.id){
      return;
    }
    let localStorageData:any = this.getMemberData();
    if(localStorageData) {
      localStorageData[key] = value;
    } else {
      localStorageData = new LocalStorageModel({
        key,
        value
      });
    }
    this.saveMemberData(localStorageData);
  }

  private saveMemberData(data: any):void {
    localStorage.setItem(this.memberService.getCurrentMember().id, JSON.stringify(data));
  }

  public getMemberData() {
    if(!this.memberService.getCurrentMember()?.id){
      return {};
    }
    let data = localStorage.getItem(this.memberService.getCurrentMember().id);
    return data ? JSON.parse(data) : !!data;
  }

  public getMemberDataByKey(key:NgLocalStorageKeysEnum) {
    if(!this.memberService.getCurrentMember()?.id){
      return;
    }
    let data =  this.getMemberData();
    return data ? data[key] : data;
  }

  public removeMemberDataByKey(key: NgLocalStorageKeysEnum) {
    if(!this.memberService.getCurrentMember()?.id){
      return;
    }
    const data = this.getMemberData();
    delete data[key];
    this.saveMemberData(data);
  }

  public clearMemberData() {
    if(!this.memberService.getCurrentMember()?.id){
      return;
    }
    localStorage.removeItem(this.memberService.getCurrentMember().id);
  }
}
