const template = require(`html-loader!./leaderboardHeader.html`).default;
import resolutionEnum from "../../../api/enums/resolution.enum";
import leagueEnum from "../../../api/enums/league.enum";

class leaderboardHeaderCtrl {

  constructor(RestManager, Modals) {
    'ngInject';
    this.RestManager = RestManager;
    this.Modals = Modals;
    this.leagueEnum = leagueEnum;
  }

  $onInit() {
    this.isMobile = document.body.clientWidth <= resolutionEnum.WIDTH.MOBILE_LEADERBOARD_ITEM;
  }

  help() {
    this.Modals.open('individualLeaderboardInfo');
  }
}

leaderboardHeaderCtrl.$inject = [
  'RestManager', 'Modals'
];
export default {
  template: template,
  controller: leaderboardHeaderCtrl,
  bindings: {
    leagueData: '<',
    leagueConf: '<'
  }
};
