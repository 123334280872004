const template = require(`html-loader!./wixOffer.html`).default;

class wixOfferCtrl {
  constructor($mdDialog) {
    "ngInject";
    this.$mdDialog = $mdDialog;
  }

  onClick() {
    this.close();
  }

  close() {
    this.$mdDialog.hide();
  }
}
wixOfferCtrl.$inject = [
  '$mdDialog'
];
export default {
  template: template,
  controller: wixOfferCtrl
};
