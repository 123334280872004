import {Component, Input, OnInit} from '@angular/core';
import {PaymentService} from "../../payment.service";
import {IStoreItem} from "../../../store/services/store.service.types";

@Component({
  selector: 'app-paypal-button',
  templateUrl: './paypal-button.component.html',
  styleUrls: ['./paypal-button.component.scss']
})
export class PaypalButtonComponent implements OnInit {

  @Input() storeItem?: IStoreItem;
  @Input() selector?: string;
  constructor(
    private paymentService: PaymentService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.paymentService.initPaypal(this.storeItem!, this.selector!);
    }, 1000);
  }

}
