export const shareList = [
  {
    service: 'facebook',
    code: 1065
  },
  {
    service: 'twitter',
    code: 1066
  },
  {
    service: 'pinterest',
    code: 1066
  },
  {
    service: 'tumblr',
    code: 1066
  }
];