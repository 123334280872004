const template = require(`html-loader!./adminExhibitions.html`).default;

class adminExhibitionsCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Manager', route: 'admin/contentManager/exhibitions/manager'},
      {name: 'Selected', route: 'admin/contentManager/exhibitions/selected'},
      {name: 'Page', route: 'admin/contentManager/exhibitions/page'},
      {name: 'virtual', route: 'admin/contentManager/exhibitions/virtual'},
    ];
  }
}

export default {
  template: template,
  controller: adminExhibitionsCtrl,
  bindings: {}
};
