const template = require(`html-loader!./contactPage.html`).default;

class contactPageComponent {
  /** @param {RestManager} RestManager **/

  constructor(
    pageData,
    RestManager,
    $window,
    $scope,
    tagEventService,
    MemberService,
    NgMetaDataService,
  ) {
    'ngInject';
    this.ready = false;

    this.pageData = pageData;
    this.RestManager = RestManager;
    this.$window = $window;
    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.NgMetaDataService = NgMetaDataService;

    this.formData = {};
    this.state = 'form';
  }

  $onInit() {
    this.NgMetaDataService.setTitle('Contact');
    // to top
    this.$window.scrollTo(0, 0);
    // init
    this.initPage();
  }

  /** @return void **/
  initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('contact');

    this.tagEventService.sendPageViewEvent({title: 'Contact page'});
    this.ready = true;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  send(form) {
    // validate
    if (!this.validate(form)) {
      return false;
    }

    if (this.busy) {
      return false;
    }
    this.busy = true;

    this.RestManager.sendContact(this.formData).then(res => {
      this.busy = false;
      if (!res.success) {
        return;
      }
      // done
      this.state = 'thank-you';
    });
  }

  validate(form) {
    // clear form
    form.$submitted = true;
    this.errorMessage = '';
    let noErrors = true;

    // validate name
    form.name.$error.validationError = false;
    if (!this.isValid('name', this.formData.name)) {
      form.name.$error.validationError = true;
      noErrors = false;
    }

    // validate email
    form.email.$error.validationError = false;
    if (!this.isValid('email', this.formData.email)) {
      form.email.$error.validationError = true;
      noErrors = false;
    }

    // message
    form.message.$error.validationError = false;
    if (!this.isValid('message', this.formData.message)) {
      form.message.$error.validationError = true;
      noErrors = false;
    }

    return noErrors;
  }

  isValid(field, value) {
    switch (field) {
      case 'name':
        return value && value.length > 1;
      case 'email':
        return /^[a-zA-Z0-9\-_.+]+@[a-zA-Z0-9\-]+(\.[a-zA-Z]+){1,2}$/.test(value);
      case 'message':
        return value && value.length > 10;
      default:
        return true;
    }
  }
}
contactPageComponent.$inject = [
  'pageData',
  'RestManager',
  '$window',
  '$scope',
  'tagEventService',
  'MemberService',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: contactPageComponent,
};
