<section
  class="carousel-wrapper"
  [ngStyle]="carouselWrapperStyle"
  [ngClass]="{'faded-border': fadeOptions.isFadeOverlayVisible}"
>
  <ul class="carousel-inner" #carousel>
    <li *ngFor="let item of items;" class="carousel-item">
      <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
    </li>
  </ul>
  <button
    *ngIf="items.length > 1"
    (click)="next()"
    [disabled]="currentSlide === items.length - 1"
    class="next-slide-button"
    [ngClass]="{'disable-events': fadeOptions.isFadeOverlayVisible}"
  >
    <i class="icon icon-right-arrow"></i>
  </button>
  <button
    *ngIf="items.length > 1"
    (click)="prev()"
    [disabled]="currentSlide === 0"
    class="prev-slide-button"
    [ngClass]="{'disable-events': fadeOptions.isFadeOverlayVisible}"
  >
    <i class="icon icon-left-arrow"></i>
  </button>

  <div
    *ngIf="items.length > 1"
    [ngClass]="{'disable-events': fadeOptions.isFadeOverlayVisible}"
    class="dots-menu-wrapper">
    <span
      *ngFor="let item of items; let i = index"
      [ngClass]="{'is-active-menu-item': currentSlide === i}"
      class="dots-menu-item"
      (click)="goToSlideByIndex(i)"
    >
    </span>
  </div>
</section>
