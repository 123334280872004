const friendsTemplate = require(`html-loader!./tpl/friends.toast.html`).default;
const copyTemplate = require(`html-loader!./tpl/copy.toast.html`).default;

import toastCtrl from './toasts.ctrl';
import {suspendedImagesConf} from './suspendedImages/suspendedImages.conf';
import {successfulSwapConf} from './successfulSwapToast/successfulSwapToast.conf';
import {trySwapConf} from './trySwap/trySwap.conf';
import {simpleMessageConf} from './simpleMessage/simpleMessage.conf';
import {expoSelectionConf} from './expoSelectionToast/expoSelection.conf';
import {expoSaleConf} from './expoSaleToast/expoSale.conf';

function toastsSettings($document) {

  "ngInject";
  return {
    'default': {
      controller: toastCtrl,
      controllerAs: 'toast',
      autoWrap: false,
      hideDelay: 0,
      position: 'bottom right',
      parent: $document[0],
      bindToController: true
    },
    'friends': {
      template: friendsTemplate,
      controller: toastCtrl,
      controllerAs: 'toast'
    },
    'copy': {
      template: copyTemplate,
      hideDelay: 1500
    },
    'try_swap': trySwapConf,
    'suspended_images': suspendedImagesConf,
    'successful_swap': successfulSwapConf,
    'suspendedImages' : suspendedImagesConf,
    'simpleMessage' : simpleMessageConf,
    'expoSelection' : expoSelectionConf,
    'expoSale' : expoSaleConf
  };
}
toastsSettings.$inject = [
  '$document',
];
export default toastsSettings;
