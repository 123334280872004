import environment from '../../gsApp.environment';

class GooglePhotosManager {
  constructor(api, $interval, $timeout, NgTokenService, $cookies) {
    'ngInject';
    this.api = api;
    this.$interval = $interval;
    this.$timeout = $timeout;
    this.$cookies = $cookies;
    this.NgTokenService = NgTokenService;

    this.isModalClosed =
      this.isModalClosed.bind(this);
  }

  getPhotos(start, limit, query) {
    return this.api['rest']['getPhotosGoogle']({start, limit, query});
  }

  googlePhotosDisconnect() {
    return this.api['rest']['googlePhotosDisconnect']();
  }

  auth() {
    // close if opened before
    if (this.modalWindow) {
      this.modalWindow.close();
    }

    //Modal Settings
    let title = '',
      url = `https://${environment.domains.api}/auth/google_photos_auth?token=${this.NgTokenService.memberToken}`,
      width = 600,
      height = 400,
      top = (screen.height / 2) - (height / 2),
      left = (screen.width / 2) - (width / 2);
    console.log('google window url', url);

    this.modalWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + width + ', height=' + height + ', top=' + top + ', left=' + left);

    //If popup not allowed or not supported
    if (this.modalWindow === null || typeof(this.modalWindow) === 'undefined') {
      return new Promise((resolve, reject) => resolve({success: false}));
    }

    //Set Interval
    this.closeTimer = this.$interval(this.isModalClosed, 1000);

    //Result
    return new Promise((resolve, reject) => {
      this.resolve = resolve;

      //Stop endless loop
      this.$timeout(() => {
        this.$interval.cancel(this.closeTimer);
        this.closeTimer = undefined;
        resolve({success: false});
      }, 4000 * 60);
    });
  }

  isModalClosed() {
    console.log('Waiting for popup closed');
    if (this.modalWindow && this.modalWindow.closed) {
      //stop timer
      this.$interval.cancel(this.closeTimer);
      this.closeTimer = undefined;

      //resolve promise
      this.resolve({success: true});
    }
  }
}

GooglePhotosManager.$inject = [
  'api',
  '$interval',
  '$timeout',
  'NgTokenService',
  '$cookies'
];

export default GooglePhotosManager;
