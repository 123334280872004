const template = require(`html-loader!./fixedFooter.html`).default;

class fixedFototerCtrl {
  constructor(
    $scope,
    $rootScope,
    Modals,
    protectionService,
    MemberService,
  ) {
    'ngInject';

    this.isScrolled = false;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.protectionService = protectionService;
    this.MemberService = MemberService;
    this.onScroll = _.throttle(this.onScroll.bind(this), 250);
  }

  $onInit() {
    $(document).on('scroll', this.onScroll);
  }

  $doCheck() {
    this.member = this.MemberService.getCurrentMember();
    if(this.memberLoggedIn !== this.member?.logged_in){
      this.memberLoggedIn = this.member?.logged_in;
    }
  }

  $onDestroy() {
    $(document).off('scroll', this.onScroll);
  }

  onScroll() {
    const scrollTop = $(window).scrollTop();
    this.isScrolled = scrollTop > 100 && !(scrollTop + $(window).height() >= $(document).height()-100);
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
    return true;
  }

  signUp(event) {
    this.Modals.open('login', event, { signUp: true });
  }
}

fixedFototerCtrl.$inject = [
  '$scope',
  '$rootScope',
  'Modals',
  'protectionService',
  'MemberService',
];

export default {
  template: template,
  controller: fixedFototerCtrl,
};
