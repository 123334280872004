<div
  *ngIf="{ value: degree$ | async } as dgr"
  class="c-challenges-item__exposure__meter__wrapper">
  <app-ng-image
    [image]="'/assets/svg/exposure-meter.svg'"
    [priority]="true"
    [fill]="true"
  >
  </app-ng-image>
  <div class="c-challenges-item__exposure__meter"
  >
    <app-ng-image
      [ngStyle]="{
          'transform': 'rotate('+ (dgr?.value ?? -90) +'deg)',
          '-webkit-transform': 'rotate('+ (dgr?.value ?? -90) +'deg)'
          }"
      [ngClass]="{'width-20': isVoting}"
      class="c-challenges-item__exposure__meter__arrow"
      [image]="isVoting ? '/assets/svg/exposure-meter-dial-vote.svg' : '/assets/svg/exposure-meter-dial.svg'"
      [priority]="true"
      [width]="isVoting ? 20 : 19"
      [height]="49"
    >
    </app-ng-image>

    <div
      *ngIf="showMeterBackground"
      class="c-challenges-item__red-sector-bg"
      [ngStyle]="{'background-image': 'linear-gradient(' + ((dgr?.value ?? -90) + 90) + 'deg, transparent 50%, white 50%),linear-gradient(0deg, white 50%, transparent 50%)'}"
    ></div>

    <div
      *ngIf="showMeterBackground"
      class="c-challenges-item__red-sector-bg-gradient"
    ></div>
  </div>
</div>
