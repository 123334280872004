import {NgChallengeModel} from "../../../../modules/challenges/models/ng-challenge.model";

const template = require(`html-loader!./challengeMobile.html`).default;
import badgeV2Mode from "../../api/enums/badgeV2.enum";
import challengeEnum from '../../api/enums/challenge.enum';
import memberEnum from '../../api/enums/member.enum';

class challengeMobileCtrl {
  constructor(
    Modals,
    $rootScope,
    challengeService
  ) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.challengeService = challengeService;

    this.challengeEnum = challengeEnum;
    this.memberEnum = memberEnum;
    this.Modals = Modals;
    this.badgeV2Mode = badgeV2Mode;
  }

  $onInit() {
    this.inited = (this.challenge instanceof NgChallengeModel);
  }

  preSubmit(event) {
    this.challengeService.openPreSubmit(this.challenge);
  }

  challengeLocked(event) {
    this.Modals.open('challengeLocked', event, {challenge: this.challenge});
  }
}

challengeMobileCtrl.$inject = [
  'Modals',
  'challengeService'
];

export default {
  template: template,
  controller: challengeMobileCtrl,
  bindings: {
    challenge: '<data'
  }
};
