import { Component } from '@angular/core';

@Component({
  selector: 'app-error-page-wrapper',
  templateUrl: './error-page-wrapper.component.html',
  styleUrl: './error-page-wrapper.component.scss'
})
export class ErrorPageWrapperComponent {

}
