import ChatMemberModel from "./chatMember.model";

class ChatImageModel {
  constructor({
    id,
    member_id,
    adult,
    members = []
  } = {}) {
    Object.assign(this, {
      id,
      member_id,
      adult,
    });

    this.members = members.map( member => new ChatMemberModel(member));
  }

  get member() {
    return this.members && this.members[0] ? this.members[0] : null;
  }
}

export default ChatImageModel;
