<div class="info gs-theme-bg-color-white_100">
  <div class="gs-center-flex gs-h-40">
    <div class="gs-theme-text-bold gs-theme-color-gray_80">Bundle Contents</div>
  </div>
  <div class="info_content">
    <div *ngFor="let resource of data.received"
         class="info_content_item gs-center-v-flex">
      <img [src]="'/assets/images/resources/' + resource.type + '.png'"
           alt="{{resource.type}}">
      <div class="info_content_item__name gs-theme-text-bold">{{resource.type.toLowerCase()}}</div>
      <div class="gs-theme-text-regular">x{{resource.amount}}</div>
    </div>
  </div>
</div>

