import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-challenges-new-challenge-template-wrapper',
  templateUrl: './admin-content-manager-challenges-new-challenge-template-wrapper.component.html',
  styleUrl: './admin-content-manager-challenges-new-challenge-template-wrapper.component.scss'
})
export class AdminContentManagerChallengesNewChallengeTemplateWrapperComponent {

}
