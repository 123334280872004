import {DoBootstrap, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {setAngularJSGlobal, UpgradeModule} from '@angular/upgrade/static';
import {HttpClientModule} from "@angular/common/http";
import {
  ModalsServiceBridgeProvider,
  rootScopeServiceProvider,
  autoUpdateProvider,
  BrazeProvider,
  MixpanelProvider,
  StateParamsProvider,
  stateProvider,
  gsModalsProvider,
  voteManagerProvider,
  TagEventProvider,
  photoSwipeProvider,
  ModalsProvider,
  challengeServiceProvider
} from "./ajs-upgraded-providers";
import {AppComponent} from './app.component';
import {MaintenanceComponent} from "./components/maintenance/maintenance.component";
import {LottieModule} from "ngx-lottie";
import {AiTermsComponent} from './components/ai-terms/ai-terms.component';
import {CoreModule} from "./core/core.module";
import {ChallengesModule} from "./modules/challenges/challenges.module";
import {StoreModule} from "./modules/store/store.module";
import {AppRoutingModule} from "./app-routing.module";
import {ng1AboutPageComponent} from "./upgradedComponents/aboutPage.directive";
import {ng1TermsPageComponent} from "./upgradedComponents/termsPage.directive";
import {AboutPageWrapperComponent} from './components/about-page-wrapper/about-page-wrapper.component';
import {TermsPageWrapperComponent} from './components/terms-page-wrapper/terms-page-wrapper.component';
import {ExhibitionsWrapperComponent} from './components/exhibitions-wrapper/exhibitions-wrapper.component';
import {BankrollModule} from "./modules/bankroll/bankroll.module";
import {HeaderWrapperComponent} from './components/header-wrapper/header-wrapper.component';
import {HomePageWrapperComponent} from './components/home-page-wrapper/home-page-wrapper.component';
import {CommonModule} from "@angular/common";
import {mainModule, oldAngular} from "./gsApp/app/gsApp";
import {Router} from "@angular/router";
import { PartnersWrapperComponent } from './components/partners-wrapper/partners-wrapper.component';
import { FooterWrapperComponent } from './components/footer-wrapper/footer-wrapper.component';
import { ChallengesClosedWrapperComponent } from './components/challenges-closed-wrapper/challenges-closed-wrapper.component';
import { ChallengesPageWrapperComponent } from './components/challenges-page-wrapper/challenges-page-wrapper.component';
import { DiscoverWrapperComponent } from './components/discover-wrapper/discover-wrapper.component';
import { DiscoverPhotosWrapperComponent } from './components/discover-photos-wrapper/discover-photos-wrapper.component';
import { DiscoverSearchComponent } from './components/discover-search/discover-search.component';
import { ArticlesWrapperComponent } from './components/articles-wrapper/articles-wrapper.component';
import { ArticleWrapperComponent } from './components/article-wrapper/article-wrapper.component';
import { ArticleEditWrapperComponent } from './components/article-edit-wrapper/article-edit-wrapper.component';
import { ContactWrapperComponent } from './components/contact-wrapper/contact-wrapper.component';
import { PrivacyWrapperComponent } from './components/privacy-wrapper/privacy-wrapper.component';
import { DmcaWrapperComponent } from './components/dmca-wrapper/dmca-wrapper.component';
import { ChallengesJoinedWrapperComponent } from './components/challenges-joined-wrapper/challenges-joined-wrapper.component';
import { ChallengesOpenWrapperComponent } from './components/challenges-open-wrapper/challenges-open-wrapper.component';
import { ChallengesCompletedWrapperComponent } from './components/challenges-completed-wrapper/challenges-completed-wrapper.component';
import { ChallengesUpcomingWrapperComponent } from './components/challenges-upcoming-wrapper/challenges-upcoming-wrapper.component';
import { ChallengesManageWrapperComponent } from './components/challenges-manage-wrapper/challenges-manage-wrapper.component';
import { ChallengesEditorWrapperComponent } from './components/challenges-editor-wrapper/challenges-editor-wrapper.component';
import { ChallengeWrapperComponent } from './components/challenge-wrapper/challenge-wrapper.component';
import { ChallengeWinnersWrapperComponent } from './components/challenge-winners-wrapper/challenge-winners-wrapper.component';
import { ChallengeWinnersAllWrapperComponent } from './components/challenge-winners-all-wrapper/challenge-winners-all-wrapper.component';
import { ChallengeWinnersExhibitPrintWrapperComponent } from './components/challenge-winners-exhibit-print-wrapper/challenge-winners-exhibit-print-wrapper.component';
import { ChallengeWinnersExhibitDigitalWrapperComponent } from './components/challenge-winners-exhibit-digital-wrapper/challenge-winners-exhibit-digital-wrapper.component';
import { ChallengeDetailsWrapperComponent } from './components/challenge-details-wrapper/challenge-details-wrapper.component';
import { ChallengePrizesWrapperComponent } from './components/challenge-prizes-wrapper/challenge-prizes-wrapper.component';
import { ChallengeRulesWrapperComponent } from './components/challenge-rules-wrapper/challenge-rules-wrapper.component';
import { ChallengeRankWrapperComponent } from './components/challenge-rank-wrapper/challenge-rank-wrapper.component';
import { ChallengeRankTopPhotographerWrapperComponent } from './components/challenge-rank-top-photographer-wrapper/challenge-rank-top-photographer-wrapper.component';
import { ChallengeRankTopPhotoWrapperComponent } from './components/challenge-rank-top-photo-wrapper/challenge-rank-top-photo-wrapper.component';
import { ChallengeRankGuruTopPickWrapperComponent } from './components/challenge-rank-guru-top-pick-wrapper/challenge-rank-guru-top-pick-wrapper.component';
import { ProfileWrapperComponent } from './components/profile-wrapper/profile-wrapper.component';
import { ProfilePhotosWrapperComponent } from './components/profile-photos-wrapper/profile-photos-wrapper.component';
import { ProfileLikesWrapperComponent } from './components/profile-likes-wrapper/profile-likes-wrapper.component';
import { ProfileAchievementsWrapperComponent } from './components/profile-achievements-wrapper/profile-achievements-wrapper.component';
import { ProfileAchievementsAchievementsWrapperComponent } from './components/profile-achievements-achievements-wrapper/profile-achievements-achievements-wrapper.component';
import { ProfileAchievementsChallengesWrapperComponent } from './components/profile-achievements-challenges-wrapper/profile-achievements-challenges-wrapper.component';
import { ProfileFollowersWrapperComponent } from './components/profile-followers-wrapper/profile-followers-wrapper.component';
import { ProfileFollowingWrapperComponent } from './components/profile-following-wrapper/profile-following-wrapper.component';
import { TeamsPageWrapperComponent } from './components/teams-page-wrapper/teams-page-wrapper.component';
import { TeamsLockWrapperComponent } from './components/teams-lock-wrapper/teams-lock-wrapper.component';
import { TeamsJoinWrapperComponent } from './components/teams-join-wrapper/teams-join-wrapper.component';
import { TeamsHomeWrapperComponent } from './components/teams-home-wrapper/teams-home-wrapper.component';
import { TeamsHomeLeaderboardWrapperComponent } from './components/teams-home-leaderboard-wrapper/teams-home-leaderboard-wrapper.component';
import { TeamsHomeMatchWrapperComponent } from './components/teams-home-match-wrapper/teams-home-match-wrapper.component';
import { TeamsCreateWrapperComponent } from './components/teams-create-wrapper/teams-create-wrapper.component';
import { TeamsEditWrapperComponent } from './components/teams-edit-wrapper/teams-edit-wrapper.component';
import { TeamsInfoWrapperComponent } from './components/teams-info-wrapper/teams-info-wrapper.component';
import {
  TeamsHomeMyTeamWrapperComponent
} from "./components/teams-home-myteam-wrapper/teams-home-myteam-wrapper.component";
import { LeaguesWrapperComponent } from './components/leagues-wrapper/leagues-wrapper.component';
import {ng1ExhibitionsComponent} from "./upgradedComponents/exhibitions.directive";
import {ng1HeaderComponent} from "./upgradedComponents/header.directive";
import {ng1HomePageComponent} from "./upgradedComponents/homePage.directive";
import {ng1PartnersComponent} from "./upgradedComponents/partners.directive";
import {ng1FooterComponent} from "./upgradedComponents/footer.directive";
import {ng1ChallengesClosedComponent} from "./upgradedComponents/challengesClosed.directive";
import {ng1ChallengesPageComponent} from "./upgradedComponents/challengesPage.directive";
import {ng1DiscoverPhotosComponent} from "./upgradedComponents/discoverPhotos.directive";
import {ng1DiscoverComponent} from "./upgradedComponents/discover.directive";
import {ng1DiscoverSearchComponent} from "./upgradedComponents/discoverSearch.directive";
import {ng1ArticlesComponent} from "./upgradedComponents/articles.directive";
import {ng1ArticleComponent} from "./upgradedComponents/article.directive";
import {ng1ArticleEditComponent} from "./upgradedComponents/articleEdit.directive";
import {ng1DmcaComponent} from "./upgradedComponents/dmca.directive";
import {ng1ContactComponent} from "./upgradedComponents/contact.directive";
import {ng1PrivacyComponent} from "./upgradedComponents/privacy.directive";
import {ng1ChallengesJoinedComponent} from "./upgradedComponents/challengesJoined.directive";
import {ng1ChallengesOpenComponent} from "./upgradedComponents/challengesOpen.directive";
import {ng1ChallengesCompletedComponent} from "./upgradedComponents/challengesCompleted.directive";
import {ng1ChallengesUpcomingComponent} from "./upgradedComponents/challengesUpcoming.directive";
import {ng1ChallengesEditorComponent} from "./upgradedComponents/challengesEditor.directive";
import {ng1ChallengesManageComponent} from "./upgradedComponents/challengesManage.directive";
import {ng1ChallengeRankComponent} from "./upgradedComponents/challengeRank.directive";
import {ng1ChallengeRankTopPhotographerComponent} from "./upgradedComponents/challengeRankTopPhotographer.directive";
import {ng1ChallengeRankTopPhotoComponent} from "./upgradedComponents/challengeRankTopPhoto.directive";
import {ng1ChallengeRankGurusTopPickComponent} from "./upgradedComponents/challengeRankGurusTopPick.directive";
import {ng1ChallengeRulesComponent} from "./upgradedComponents/challengeRules.directive";
import {ng1ChallengeDetailsComponent} from "./upgradedComponents/challengeDetails.directive";
import {ng1ChallengePrizesComponent} from "./upgradedComponents/challengePrizes.directive";
import {ng1ChallengeWinnersComponent} from "./upgradedComponents/challenge-winners.directive";
import {ng1ChallengePageComponent} from "./upgradedComponents/challengePage.directive";
import {ng1ChallengeWinnersAllComponent} from "./upgradedComponents/challengeWinnersAll.directive";
import {ng1ChallengeWinnersExhibitionComponent} from "./upgradedComponents/challengeWinnersExhibition.directive";
import {ng1ProfilePageComponent} from "./upgradedComponents/profile.directive";
import {ng1ProfilePhotosComponent} from "./upgradedComponents/profilePhotos.directive";
import {ng1ProfileAchievementsComponent} from "./upgradedComponents/profileAchivements.directive";
import {ng1ProfileAchievementsChallengesComponent} from "./upgradedComponents/profileAchievementsChallenges.directive";
import {
  ng1ProfileAchievementsAchievementsComponent
} from "./upgradedComponents/profileAchievementsAchievements.directive";
import {ng1ProfileFollowersComponent} from "./upgradedComponents/profileFollowers.directive";
import {ng1TeamsPageComponent} from "./upgradedComponents/teamsPage.directive";
import {ng1TeamsLockComponent} from "./upgradedComponents/teamsLock.directive";
import {ng1TeamsJoinComponent} from "./upgradedComponents/teamsJoin.directive";
import {ng1TeamsHomeComponent} from "./upgradedComponents/teamsHome.directive";
import {ng1TeamsHomeLeaderBoardComponent} from "./upgradedComponents/teamsHomeLeaderBoard";
import {ng1TeamsHomeMatchComponent} from "./upgradedComponents/teamsHomeMatch.directive";
import {ng1TeamsCreateComponent} from "./upgradedComponents/teamsCreate.directive";
import {ng1TeamsInfoComponent} from "./upgradedComponents/teamsInfo.directive";
import {ng1TeamsHomeMyTeamComponent} from "./upgradedComponents/teamsHomeMyTeam.directive";
import {ng1LeaguesComponent} from "./upgradedComponents/leagues.directive";
import { AdminWrapperComponent } from './components/admin-wrapper/admin-wrapper.component';
import { AdminMemberManagerWrapperComponent } from './components/admin-member-manager-wrapper/admin-member-manager-wrapper.component';
import { AdminTeamsManagerWrapperComponent } from './components/admin-teams-manager-wrapper/admin-teams-manager-wrapper.component';
import { AdminContentManagerWrapperComponent } from './components/admin-content-manager-wrapper/admin-content-manager-wrapper.component';
import { AdminContentManagerChallengesWrapperComponent } from './components/admin-content-manager-challenges-wrapper/admin-content-manager-challenges-wrapper.component';
import { AdminContentManagerChallengesScheduleWrapperComponent } from './components/admin-content-manager-challenges-schedule-wrapper/admin-content-manager-challenges-schedule-wrapper.component';
import { AdminContentManagerChallengesTemplatesWrapperComponent } from './components/admin-content-manager-challenges-templates-wrapper/admin-content-manager-challenges-templates-wrapper.component';
import { AdminContentManagerChallengesBulkUploadWrapperComponent } from './components/admin-content-manager-challenges-bulk-upload-wrapper/admin-content-manager-challenges-bulk-upload-wrapper.component';
import { AdminContentManagerChallengesEditChallengeTemplateWrapperComponent } from './components/admin-content-manager-challenges-edit-challenge-tempalate-wrapper/admin-content-manager-challenges-edit-challenge-template-wrapper.component';
import { AdminContentManagerChallengesNewChallengeTemplateWrapperComponent } from './components/admin-content-manager-challenges-new-challenge-tempalate-wrapper/admin-content-manager-challenges-new-challenge-template-wrapper.component';
import { AdminContentManagerChallengesChallengesWinnersWrapperComponent } from './components/admin-content-manager-challenges-challenges-winners-wrapper/admin-content-manager-challenges-challenges-winners-wrapper.component';
import { AdminContentManagerChallengesChallengesCalendarWrapperComponent } from './components/admin-content-manager-challenges-challenges-calendar-wrapper/admin-content-manager-challenges-challenges-calendar-wrapper.component';
import { AdminContentManagerChallengesChallengesAutoSubmitWrapperComponent } from './components/admin-content-manager-challenges-challenges-auto-submit-wrapper/admin-content-manager-challenges-challenges-auto-submit-wrapper.component';
import { AdminContentManagerChallengesNotificationTimesWrapperComponent } from './components/admin-content-manager-challenges-notification-times-wrapper/admin-content-manager-challenges-notification-times-wrapper.component';
import { AdminContentManagerChallengesChallengesRankingLevelsWrapperComponent } from './components/admin-content-manager-challenges-challenges-ranking-levels-wrapper/admin-content-manager-challenges-challenges-ranking-levels-wrapper.component';
import { AdminContentManagerChallengesChallengesSponsorsWrapperComponent } from './components/admin-content-manager-challenges-challenges-sponsors-wrapper/admin-content-manager-challenges-challenges-sponsors-wrapper.component';
import { AdminContentManagerChallengesChallengesSponsorsNewWrapperComponent } from './components/admin-content-manager-challenges-challenges-sponsors-new-wrapper/admin-content-manager-challenges-challenges-sponsors-new-wrapper.component';
import { AdminContentManagerChallengesChallengesSponsorsEditWrapperComponent } from './components/admin-content-manager-challenges-challenges-sponsors-edit-wrapper/admin-content-manager-challenges-challenges-sponsors-edit-wrapper.component';
import { AdminContentManagerChallengesChallengesPlannedTimingsWrapperComponent } from './components/admin-content-manager-challenges-challenges-planned-timings-wrapper/admin-content-manager-challenges-challenges-planned-timings-wrapper.component';
import { AdminContentManagerExhibitionsWrapperComponent } from './components/admin-content-manager-exhibitions-wrapper/admin-content-manager-exhibitions-wrapper.component';
import { AdminContentManagerExhibitionsManagerWrapperComponent } from './components/admin-content-manager-exhibitions-manager-wrapper/admin-content-manager-exhibitions-manager-wrapper.component';
import { AdminContentManagerExhibitionsSelectedWrapperComponent } from './components/admin-content-manager-exhibitions-selected-wrapper/admin-content-manager-exhibitions-selected-wrapper.component';
import { AdminContentManagerExhibitionsPageWrapperComponent } from './components/admin-content-manager-exhibitions-page-wrapper/admin-content-manager-exhibitions-page-wrapper.component';
import { AdminContentManagerExhibitionsVirtualWrapperComponent } from './components/admin-content-manager-exhibitions-virtual-wrapper/admin-content-manager-exhibitions-virtual-wrapper.component';
import { AdminContentManagerExhibitionsVirtualEditWrapperComponent } from './components/admin-content-manager-exhibitions-virtual-edit-wrapper/admin-content-manager-exhibitions-virtual-edit-wrapper.component';
import { AdminContentManagerExhibitionsVirtualNewWrapperComponent } from './components/admin-content-manager-exhibitions-virtual-new-wrapper/admin-content-manager-exhibitions-virtual-new-wrapper.component';
import { AdminContentManagerExhibitionsEditWrapperComponent } from './components/admin-content-manager-exhibitions-edit-wrapper/admin-content-manager-exhibitions-edit-wrapper.component';
import { AdminContentManagerSpotlightWrapperComponent } from './components/admin-content-manager-spotlight-wrapper/admin-content-manager-spotlight-wrapper.component';
import { AdminContentManagerSpotlightEditWrapperComponent } from './components/admin-content-manager-spotlight-edit-wrapper/admin-content-manager-spotlight-edit-wrapper.component';
import { AdminContentManagerPopupsWrapperComponent } from './components/admin-content-manager-popups-wrapper/admin-content-manager-popups-wrapper.component';
import { AdminContentManagerExhibitionWrapperComponent } from './components/admin-content-manager-exhibition-wrapper/admin-content-manager-exhibition-wrapper.component';
import { AdminContentManagerWidgetWrapperComponent } from './components/admin-content-manager-widjet-wrapper/admin-content-manager-widget-wrapper.component';
import { AdminContentManagerDailyRewardWrapperComponent } from './components/admin-content-manager-daily-reward-wrapper/admin-content-manager-daily-reward-wrapper.component';
import {ng1AdminPageComponent} from "./upgradedComponents/admin.directive";
import {ng1AdminMemberManagerComponent} from "./upgradedComponents/adminMemberManager.directive";
import {ng1AdminTeamsManagerComponent} from "./upgradedComponents/adminTeamsManager.directive";
import {ng1AdminContentManagerComponent} from "./upgradedComponents/adminContentManager.directive";
import {ng1AdminContentManagerChallengesComponent} from "./upgradedComponents/adminContentManagerChallenges.directive";
import {
  ng1AdminContentManagerChallengesChallengesScheduleComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesSchedule.directive";
import {
  ng1AdminContentManagerChallengesTemplatesComponent
} from "./upgradedComponents/adminContentManagerChallengesTemplates.directive";
import {
  ng1AdminContentManagerChallengesBulkUploadComponent
} from "./upgradedComponents/adminContentManagerChallengesBulkUpload.directive";
import {
  ng1AdminContentManagerChallengesEditChallengeTemplateComponent
} from "./upgradedComponents/adminContentManagerChallengesEditChallengeTemplate.directive";
import {
  ng1AdminContentManagerChallengesNewChallengeTemplateComponent
} from "./upgradedComponents/adminContentManagerChallengesNewChallengeTemplate.directive";
import {
  ng1AdminContentManagerChallengesChallengesWinnersComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesWinners.directive";
import {
  ng1AdminContentManagerChallengesChallengesCalendarComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesCalendar.directive";
import {
  ng1AdminContentManagerChallengesChallengesAutoSubmitComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesAutoSubmit.directive";
import {
  ng1AdminContentManagerChallengesChallengesNotificationTimesComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesNotificationTimes.directive";
import {
  ng1AdminContentManagerChallengesChallengesRankingLevelsComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesRankingLevels.directive";
import {
  ng1AdminContentManagerChallengesChallengesSponsorsComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesSponsors.directive";
import {
  ng1AdminContentManagerChallengesChallengesSponsorsEditComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesSponsorsEdit.directive";
import {ng1AdminExhibitionsComponent} from "./upgradedComponents/adminExhibition.directive";
import {
  ng1AdminContentManagerExhibitionsManagerComponent
} from "./upgradedComponents/adminExhibitionsManager.directive";
import {
  ng1AdminContentManagerExhibitionsSelectedComponent
} from "./upgradedComponents/adminExhibitionsSelected.directive";
import {ng1AdminContentManagerExhibitionsPageComponent} from "./upgradedComponents/adminExhibitionsPage.directive";
import {
  ng1AdminContentManagerExhibitionsVirtualComponent
} from "./upgradedComponents/adminExhibitionsVirtual.directive";
import {ng1AdminContentManagerExhibitionsEditComponent} from "./upgradedComponents/adminExhibitionsEdit.directive";
import {
  ng1AdminContentManagerExhibitionsVirtualEditComponent
} from "./upgradedComponents/adminExhibitionsVirtualEdit.directive";
import {
  ng1AdminContentManagerExhibitionsVirtualNewComponent
} from "./upgradedComponents/adminExhibitionsVirtualNew.directive";
import {ng1AdminContentManagerSpotlightComponent} from "./upgradedComponents/adminContentManagerSpotlight.directive";
import {
  ng1AdminContentManagerSpotlightEditComponent
} from "./upgradedComponents/adminContentManagerSpotlightEdit.directive";
import {ng1AdminContentManagerPopupsComponent} from "./upgradedComponents/adminContentManagerPopups.directive";
import {ng1AdminContentManagerExhibitionComponent} from "./upgradedComponents/adminContentManagerExhibition.directive";
import {ng1AdminContentManagerWidgetComponent} from "./upgradedComponents/adminContentManagerWidjet.directive";
import { AdminContentManagerDailyRewardsMissionsWrapperComponent } from './components/admin-content-manager-daily-rewards-missions-wrapper/admin-content-manager-daily-rewards-missions-wrapper.component';
import { AdminContentManagerDailyRewardsEditDailyMissionWrapperComponent } from './components/admin-content-manager-daily-rewards-edit-daily-mission-wrapper/admin-content-manager-daily-rewards-edit-daily-mission-wrapper.component';
import { AdminContentManagerDailyRewardsPrizesWrapperComponent } from './components/admin-content-manager-daily-rewards-prizes-wrapper/admin-content-manager-daily-rewards-prizes-wrapper.component';
import { AdminContentManagerDailyRewardsEditDailyPrizeWrapperComponent } from './components/admin-content-manager-daily-rewards-edit-daily-prize-wrapper/admin-content-manager-daily-rewards-edit-daily-prize-wrapper.component';
import { AdminContentRewardedVideoWrapperComponent } from './components/admin-content-rewarded-video-wrapper/admin-content-rewarded-video-wrapper.component';
import { AdminContentLiveopsWrapperComponent } from './components/admin-content-liveops-wrapper/admin-content-liveops-wrapper.component';
import { AdminContentManagerLiveopsSuggestWrapperComponent } from './components/admin-content-manager-liveops-suggest-wrapper/admin-content-manager-liveops-suggest-wrapper.component';
import { AdminContentManagerEventsWrapperComponent } from './components/admin-content-manager-events-wrapper/admin-content-manager-events-wrapper.component';
import { AdminContentManagerEventsLeaderBoardWrapperComponent } from './components/admin-content-manager-events-leader-board-wrapper/admin-content-manager-events-leader-board-wrapper.component';
import { AdminContentManagerEventsEditLbEventWrapperComponent } from './components/admin-content-manager-events-edit-lb-event-wrapper/admin-content-manager-events-edit-lb-event-wrapper.component';
import { AdminContentManagerEventsNewLbEventWrapperComponent } from './components/admin-content-manager-events-new-lb-event-wrapper/admin-content-manager-events-new-lb-event-wrapper.component';
import { AdminContentManagerChestsWrapperComponent } from './components/admin-content-manager-chests-wrapper/admin-content-manager-chests-wrapper.component';
import { AdminContentManagerTeamsWrapperComponent } from './components/admin-content-manager-teams-wrapper/admin-content-manager-teams-wrapper.component';
import { AdminContentManagerTeamsLeaguesWrapperComponent } from './components/admin-content-manager-teams-leagues-wrapper/admin-content-manager-teams-leagues-wrapper.component';
import { AdminContentManagerTeamsSeasonsWrapperComponent } from './components/admin-content-manager-teams-seasons-wrapper/admin-content-manager-teams-seasons-wrapper.component';
import {
  ng1AdminContentManagerDailyRewardMissionsComponent
} from "./upgradedComponents/adminContentManagerDailyRewardsMissions.directive";
import {
  ng1AdminContentManagerDailyRewardEditDailyMissionComponent
} from "./upgradedComponents/adminContentManagerDailyRewardsEditDailyMission.directive";
import {
  ng1AdminContentManagerDailyRewardPrizesComponent
} from "./upgradedComponents/adminContentManagerDailyRewardsPrizes.directive";
import {
  ng1AdminContentManagerDailyRewardEditDailyPrizesComponent
} from "./upgradedComponents/adminContentManagerDailyRewardsEditDailyPrize.directive";
import {
  ng1AdminContentManagerRewardedVideoComponent
} from "./upgradedComponents/adminContentManagerRewardedVideo.directive";
import {ng1AdminContentManagerLiveOpsComponent} from "./upgradedComponents/adminContentManagerLiveOps.directive";
import {
  ng1AdminContentManagerLiveOpsSuggestComponent
} from "./upgradedComponents/adminContentManagerLiveOpsSuggest.directive";
import {ng1AdminContentManagerEventsComponent} from "./upgradedComponents/adminContentManagerEvents.directive";
import {
  ng1AdminContentManagerEventsEditLbComponent
} from "./upgradedComponents/adminContentManagerEventsEditLbEvent.directive";
import {
  ng1AdminContentManagerEventsNewLbComponent
} from "./upgradedComponents/adminContentManagerEventsNewLbEvent.directive";
import {
  ng1AdminContentManagerEventsLeaderBoardComponent
} from "./upgradedComponents/adminContentManagerEventsLeaderBoard.directive";
import {ng1AdminContentManagerChestsComponent} from "./upgradedComponents/adminContentManagerChests.directive";
import {ng1AdminContentManagerTeamsComponent} from "./upgradedComponents/adminContentManagerTeams.directive";
import {
  ng1AdminContentManagerTeamsLeaguesComponent
} from "./upgradedComponents/adminContentManagerTeamsLeagues.directive";
import {
  ng1AdminContentManagerTeamsSessionsComponent
} from "./upgradedComponents/adminContentManagerTeamsSessions.directive";
import { AdminContentManagerTeamsLeaguesActiveWrapperComponent } from './components/admin-content-manager-teams-leagues-active-wrapper/admin-content-manager-teams-leagues-active-wrapper.component';
import { AdminContentManagerTeamsLeaguesNextWrapperComponent } from './components/admin-content-manager-teams-leagues-next-wrapper/admin-content-manager-teams-leagues-next-wrapper.component';
import {
  ng1AdminContentManagerTeamsLeaguesActiveComponent
} from "./upgradedComponents/adminContentManagerTeamsLeaguesActive.directive";
import {
  ng1AdminContentManagerTeamsLeaguesNextComponent
} from "./upgradedComponents/adminContentManagerTeamsLeaguesNext.directive";
import {
    ng1AdminContentManagerChallengesPlannedTimingsComponent
} from "./upgradedComponents/adminContentManagerChallengesChallengesPlannedTimings.directive";
import { DlPageWrapperComponent } from './components/dl-page-wrapper/dl-page-wrapper.component';
import { DlVoteWrapperComponent } from './components/dl-vote-wrapper/dl-vote-wrapper.component';
import { DlProfileUploadWrapperComponent } from './components/dl-profile-upload-wrapper/dl-profile-upload-wrapper.component';
import { DlFillWrapperComponent } from './components/dl-fill-wrapper/dl-fill-wrapper.component';
import { DlSwapWrapperComponent } from './components/dl-swap-wrapper/dl-swap-wrapper.component';
import { DlBoostWrapperComponent } from './components/dl-boost-wrapper/dl-boost-wrapper.component';
import { DlLbWrapperComponent } from './components/dl-lb-wrapper/dl-lb-wrapper.component';
import { DlSuggestedMembersWrapperComponent } from './components/dl-suggedted-members-wrapper/dl-suggested-members-wrapper.component';
import {ng1DlComponent} from "./upgradedComponents/dl.directive";
import { DlExhibitionWrapperComponent } from './components/dl-exhibition-wrapper/dl-exhibition-wrapper.component';
import { EmptyWrapperComponent } from './components/empty-wrapper/empty-wrapper.component';
import { PhotoPageWrapperComponent } from './components/photo-page-wrapper/photo-page-wrapper.component';
import { ErrorPageWrapperComponent } from './components/error-page-wrapper/error-page-wrapper.component';
import { ErrorPage404WrapperComponent } from './components/error-page404-wrapper/error-page404-wrapper.component';
import { ErrorMaintenanceWrapperComponent } from './components/error-maintenance-wrapper/error-maintenance-wrapper.component';
import { AiPageWrapperComponent } from './components/ai-page-wrapper/ai-page-wrapper.component';
import { AiTermsWrapperComponent } from './components/ai-terms-wrapper/ai-terms-wrapper.component';
import { AdsView1WrapperComponent } from './components/ads-view1-wrapper/ads-view1-wrapper.component';
import { AdsView1View2WrapperComponent } from './components/ads-view1-view2-wrapper/ads-view1-view2-wrapper.component';
import { LandingClaimPageWrapperComponent } from './components/landing-claim-page-wrapper/landing-claim-page-wrapper.component';
import { Lp1PageWrapperComponent } from './components/lp1-page-wrapper/lp1-page-wrapper.component';
import { Lp2PageWrapperComponent } from './components/lp2-page-wrapper/lp2-page-wrapper.component';
import { Lp3PageWrapperComponent } from './components/lp3-page-wrapper/lp3-page-wrapper.component';
import { Lp4PageWrapperComponent } from './components/lp4-page-wrapper/lp4-page-wrapper.component';
import { Lp5PageWrapperComponent } from './components/lp5-page-wrapper/lp5-page-wrapper.component';
import { Lp6PageWrapperComponent } from './components/lp6-page-wrapper/lp6-page-wrapper.component';
import { LpMobilePageWrapperComponent } from './components/lp-mobile-page-wrapper/lp-mobile-page-wrapper.component';
import { PhotoContestPageWrapperComponent } from './components/photo-contest-page-wrapper/photo-contest-page-wrapper.component';
import {ng1PhotoComponent} from "./upgradedComponents/photo.directive";
import { BoardingPageWrapperComponent } from './components/boarding-page-wrapper/boarding-page-wrapper.component';
import {ng1BoardingComponent} from "./upgradedComponents/boardingPage.directive";
import { VotePageWrapperComponent } from './components/vote-page-wrapper/vote-page-wrapper.component';
import {ng1VoteComponent} from "./upgradedComponents/vote.directive";
import { SponsorsPageWrapperComponent } from './components/sponsors-page-wrapper/sponsors-page-wrapper.component';
import { SponsorsMaterialsWrapperComponent } from './components/sponsors-materials-wrapper/sponsors-materials-wrapper.component';
import { SponsorsPaymentsWrapperComponent } from './components/sponsors-payments-wrapper/sponsors-payments-wrapper.component';
import {ng1SponsorsPageComponent} from "./upgradedComponents/sponsorsPage.directive";
import { ModerationPageWrapperComponent } from './components/moderation-page-wrapper/moderation-page-wrapper.component';
import { ModerationCopyrightWrapperComponent } from './components/moderation-copyright-wrapper/moderation-copyright-wrapper.component';
import { ModerationOfftopicWrapperComponent } from './components/moderation-offtopic-wrapper/moderation-offtopic-wrapper.component';
import { ModerationSpamWrapperComponent } from './components/moderation-spam-wrapper/moderation-spam-wrapper.component';
import { ModerationAdultWrapperComponent } from './components/moderation-adult-wrapper/moderation-adult-wrapper.component';
import { ModerationOfftopicImagesWrapperComponent } from './components/moderation-offtopic-images-wrapper/moderation-offtopic-images-wrapper.component';
import {ng1ModerationComponent} from "./upgradedComponents/moderationPage.directive";
import {ng1ModerationCopyRightComponent} from "./upgradedComponents/moderationCopyright.directive";
import {ng1ModartionCopyOfftopicComponent} from "./upgradedComponents/moderationOfftopic.directive";
import {ng1ModartionImagesComponent} from "./upgradedComponents/moderationSpam.directive";
import {ng1PhotoSwipeComponent} from "./upgradedComponents/photoSwipe.directive";
import {ng1GsModalsComponent} from "./upgradedComponents/gsModals.directive";
import {ng1MobileHeaderComponent} from "./upgradedComponents/mobileHeader.directive";
import {ng1MobileMenuComponent} from "./upgradedComponents/mobileMenu.directive";
import { MobileMenuWrapperComponent } from './components/mobile-menu-wrapper/mobile-menu-wrapper.component';
import {ng1VirtualExhibitionsComponent} from "./upgradedComponents/virtualExhibitions.directive";
import { VirtualExhibitionsWrapperComponent } from './components/virtual-exhibitions-wrapper/virtual-exhibitions-wrapper.component';
import {ng1ProfileSettingsComponent} from "./upgradedComponents/profileSettings.directive";
import { ProfileSettingsWrapperComponent } from './components/profile-settings-wrapper/profile-settings-wrapper.component';
import {ng1ChallengeAchievementsComponent} from "./upgradedComponents/achievements.directive";
import { ChallengeAchievementsWrapperComponent } from './components/challenge-achievements-wrapper/challenge-achievements-wrapper.component';
import {ng1ActivityComponent} from "./upgradedComponents/activity.directive";
import { ActivityWrapperComponent } from './components/activity-wrapper/activity-wrapper.component';
import {ng1DeactivatedComponent} from "./upgradedComponents/diactivated.directive";
import { DeactivatedWrapperComponent } from './components/deactivated-wrapper/deactivated-wrapper.component';
import {ng1MobileMemberSearchComponent} from "./upgradedComponents/mobileMemberSearch.directive";
import { MobileMemberSearchWrapperComponent } from './components/mobile-member-search-wrapper/mobile-member-search-wrapper.component';
import {ng1MobileNotificationsComponent} from "./upgradedComponents/mobileNotification.directive";
import { MobileNotificationsWrapperComponent } from './components/mobile-notifications-wrapper/mobile-notifications-wrapper.component';
import {ng1ErrorPageComponent} from "./upgradedComponents/errorPage.directive";
import { ExhibitionWidgetWrapperComponent } from './components/exhibition-widget-wrapper/exhibition-widget-wrapper.component';
import {ng1ExhibitionWidget} from "./upgradedComponents/exhibitionWidget.directive";

export function playerFactory(): any {
  // Note we need a separate function as it's required
  // by the AOT compiler.
  //
  // return player;

  // The lottie-web library can be loaded on demand using dynamic import.
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

@NgModule({
  declarations: [
    AppComponent,
    MaintenanceComponent,
    AiTermsComponent,
    ng1AboutPageComponent,
    ng1TermsPageComponent,
    AboutPageWrapperComponent,
    TermsPageWrapperComponent,
    ExhibitionsWrapperComponent,
    HeaderWrapperComponent,
    HomePageWrapperComponent,
    PartnersWrapperComponent,
    FooterWrapperComponent,
    ChallengesClosedWrapperComponent,
    ChallengesPageWrapperComponent,
    DiscoverWrapperComponent,
    DiscoverPhotosWrapperComponent,
    DiscoverSearchComponent,
    ArticlesWrapperComponent,
    ArticleWrapperComponent,
    ArticleEditWrapperComponent,
    ContactWrapperComponent,
    PrivacyWrapperComponent,
    DmcaWrapperComponent,
    ChallengesJoinedWrapperComponent,
    ChallengesOpenWrapperComponent,
    ChallengesCompletedWrapperComponent,
    ChallengesUpcomingWrapperComponent,
    ChallengesManageWrapperComponent,
    ChallengesEditorWrapperComponent,
    ChallengeWrapperComponent,
    ChallengeWinnersWrapperComponent,
    ChallengeWinnersAllWrapperComponent,
    ChallengeWinnersExhibitPrintWrapperComponent,
    ChallengeWinnersExhibitDigitalWrapperComponent,
    ChallengeDetailsWrapperComponent,
    ChallengePrizesWrapperComponent,
    ChallengeRulesWrapperComponent,
    ChallengeRankWrapperComponent,
    ChallengeRankTopPhotographerWrapperComponent,
    ChallengeRankTopPhotoWrapperComponent,
    ChallengeRankGuruTopPickWrapperComponent,
    ProfileWrapperComponent,
    ProfilePhotosWrapperComponent,
    ProfileLikesWrapperComponent,
    ProfileAchievementsWrapperComponent,
    ProfileAchievementsAchievementsWrapperComponent,
    ProfileAchievementsChallengesWrapperComponent,
    ProfileFollowersWrapperComponent,
    ProfileFollowingWrapperComponent,
    TeamsPageWrapperComponent,
    TeamsLockWrapperComponent,
    TeamsJoinWrapperComponent,
    TeamsHomeWrapperComponent,
    TeamsHomeMyTeamWrapperComponent,
    TeamsHomeLeaderboardWrapperComponent,
    TeamsHomeMatchWrapperComponent,
    TeamsCreateWrapperComponent,
    TeamsEditWrapperComponent,
    TeamsInfoWrapperComponent,
    LeaguesWrapperComponent,
    ng1ExhibitionsComponent,
    ng1HeaderComponent,
    ng1GsModalsComponent,
    ng1MobileHeaderComponent,
    ng1HomePageComponent,
    ng1PartnersComponent,
    ng1FooterComponent,
    ng1ChallengesClosedComponent,
    ng1ChallengesPageComponent,
    ng1DiscoverPhotosComponent,
    ng1DiscoverComponent,
    ng1MobileMenuComponent,
    ng1DiscoverSearchComponent,
    ng1ArticlesComponent,
    ng1ArticleComponent,
    ng1ArticleEditComponent,
    ng1DmcaComponent,
    ng1ContactComponent,
    ng1PrivacyComponent,
    ng1ChallengesJoinedComponent,
    ng1ChallengesOpenComponent,
    ng1ChallengesCompletedComponent,
    ng1ChallengesUpcomingComponent,
    ng1ChallengesEditorComponent,
    ng1ChallengesManageComponent,
    ng1ChallengeRankComponent,
    ng1ChallengeRankTopPhotographerComponent,
    ng1ChallengeRankTopPhotoComponent,
    ng1ChallengeRankGurusTopPickComponent,
    ng1ChallengeRulesComponent,
    ng1ChallengeDetailsComponent,
    ng1ChallengePrizesComponent,
    ng1ChallengeWinnersComponent,
    ng1ChallengePageComponent,
    ng1ChallengeWinnersAllComponent,
    ng1ChallengeWinnersExhibitionComponent,
    ng1ProfilePageComponent,
    ng1ProfilePhotosComponent,
    ng1ProfileAchievementsComponent,
    ng1ProfileAchievementsChallengesComponent,
    ng1ProfileAchievementsAchievementsComponent,
    ng1ProfileFollowersComponent,
    ng1TeamsPageComponent,
    ng1TeamsLockComponent,
    ng1TeamsJoinComponent,
    ng1TeamsHomeComponent,
    ng1TeamsHomeLeaderBoardComponent,
    ng1TeamsHomeMatchComponent,
    ng1TeamsCreateComponent,
    ng1TeamsInfoComponent,
    ng1TeamsHomeMyTeamComponent,
    ng1LeaguesComponent,
    ng1VirtualExhibitionsComponent,
    ng1ProfileSettingsComponent,
    ng1ChallengeAchievementsComponent,
    ng1ActivityComponent,
    ng1DeactivatedComponent,
    ng1MobileMemberSearchComponent,
    ng1MobileMenuComponent,
    ng1MobileNotificationsComponent,
    ng1ErrorPageComponent,
    AdminWrapperComponent,
    AdminMemberManagerWrapperComponent,
    AdminTeamsManagerWrapperComponent,
    AdminContentManagerWrapperComponent,
    AdminContentManagerChallengesWrapperComponent,
    AdminContentManagerChallengesScheduleWrapperComponent,
    AdminContentManagerChallengesTemplatesWrapperComponent,
    AdminContentManagerChallengesBulkUploadWrapperComponent,
    AdminContentManagerChallengesEditChallengeTemplateWrapperComponent,
    AdminContentManagerChallengesNewChallengeTemplateWrapperComponent,
    AdminContentManagerChallengesChallengesWinnersWrapperComponent,
    AdminContentManagerChallengesChallengesCalendarWrapperComponent,
    AdminContentManagerChallengesChallengesAutoSubmitWrapperComponent,
    AdminContentManagerChallengesNotificationTimesWrapperComponent,
    AdminContentManagerChallengesChallengesRankingLevelsWrapperComponent,
    AdminContentManagerChallengesChallengesSponsorsWrapperComponent,
    AdminContentManagerChallengesChallengesSponsorsNewWrapperComponent,
    AdminContentManagerChallengesChallengesSponsorsEditWrapperComponent,
    AdminContentManagerChallengesChallengesPlannedTimingsWrapperComponent,
    AdminContentManagerExhibitionsWrapperComponent,
    AdminContentManagerExhibitionsManagerWrapperComponent,
    AdminContentManagerExhibitionsSelectedWrapperComponent,
    AdminContentManagerExhibitionsPageWrapperComponent,
    AdminContentManagerExhibitionsVirtualWrapperComponent,
    AdminContentManagerExhibitionsVirtualEditWrapperComponent,
    AdminContentManagerExhibitionsVirtualNewWrapperComponent,
    AdminContentManagerExhibitionsEditWrapperComponent,
    AdminContentManagerSpotlightWrapperComponent,
    AdminContentManagerSpotlightEditWrapperComponent,
    AdminContentManagerPopupsWrapperComponent,
    AdminContentManagerExhibitionWrapperComponent,
    AdminContentManagerWidgetWrapperComponent,
    AdminContentManagerDailyRewardWrapperComponent,
    ng1AdminPageComponent,
    ng1AdminMemberManagerComponent,
    ng1AdminTeamsManagerComponent,
    ng1AdminContentManagerComponent,
    ng1AdminContentManagerChallengesComponent,
    ng1AdminContentManagerChallengesChallengesScheduleComponent,
    ng1AdminContentManagerChallengesTemplatesComponent,
    ng1AdminContentManagerChallengesBulkUploadComponent,
    ng1AdminContentManagerChallengesEditChallengeTemplateComponent,
    ng1AdminContentManagerChallengesNewChallengeTemplateComponent,
    ng1AdminContentManagerChallengesChallengesWinnersComponent,
    ng1AdminContentManagerChallengesChallengesCalendarComponent,
    ng1AdminContentManagerChallengesChallengesAutoSubmitComponent,
    ng1AdminContentManagerChallengesChallengesNotificationTimesComponent,
    ng1AdminContentManagerChallengesChallengesRankingLevelsComponent,
    ng1AdminContentManagerChallengesChallengesSponsorsComponent,
    ng1AdminContentManagerChallengesChallengesSponsorsEditComponent,
    ng1AdminExhibitionsComponent,
    ng1AdminContentManagerExhibitionsManagerComponent,
    ng1AdminContentManagerExhibitionsSelectedComponent,
    ng1AdminContentManagerExhibitionsPageComponent,
    ng1AdminContentManagerExhibitionsVirtualComponent,
    ng1AdminContentManagerExhibitionsEditComponent,
    ng1AdminContentManagerExhibitionsVirtualEditComponent,
    ng1AdminContentManagerExhibitionsVirtualNewComponent,
    ng1AdminContentManagerSpotlightComponent,
    ng1AdminContentManagerSpotlightEditComponent,
    ng1AdminContentManagerPopupsComponent,
    ng1AdminContentManagerExhibitionComponent,
    ng1AdminContentManagerWidgetComponent,
    AdminContentManagerDailyRewardsMissionsWrapperComponent,
    AdminContentManagerDailyRewardsEditDailyMissionWrapperComponent,
    AdminContentManagerDailyRewardsPrizesWrapperComponent,
    AdminContentManagerDailyRewardsEditDailyPrizeWrapperComponent,
    AdminContentRewardedVideoWrapperComponent,
    AdminContentLiveopsWrapperComponent,
    AdminContentManagerLiveopsSuggestWrapperComponent,
    AdminContentManagerEventsWrapperComponent,
    AdminContentManagerEventsLeaderBoardWrapperComponent,
    AdminContentManagerEventsEditLbEventWrapperComponent,
    AdminContentManagerEventsNewLbEventWrapperComponent,
    AdminContentManagerChestsWrapperComponent,
    AdminContentManagerTeamsWrapperComponent,
    AdminContentManagerTeamsLeaguesWrapperComponent,
    AdminContentManagerTeamsSeasonsWrapperComponent,
    ng1AdminContentManagerDailyRewardMissionsComponent,
    ng1AdminContentManagerDailyRewardEditDailyMissionComponent,
    ng1AdminContentManagerDailyRewardPrizesComponent,
    ng1AdminContentManagerDailyRewardEditDailyPrizesComponent,
    ng1AdminContentManagerRewardedVideoComponent,
    ng1AdminContentManagerLiveOpsComponent,
    ng1AdminContentManagerLiveOpsSuggestComponent,
    ng1AdminContentManagerEventsComponent,
    ng1AdminContentManagerEventsEditLbComponent,
    ng1AdminContentManagerEventsNewLbComponent,
    ng1AdminContentManagerEventsLeaderBoardComponent,
    ng1AdminContentManagerChestsComponent,
    ng1AdminContentManagerTeamsComponent,
    ng1AdminContentManagerTeamsLeaguesComponent,
    ng1AdminContentManagerTeamsSessionsComponent,
    AdminContentManagerTeamsLeaguesActiveWrapperComponent,
    AdminContentManagerTeamsLeaguesNextWrapperComponent,
    ng1AdminContentManagerTeamsLeaguesActiveComponent,
    ng1AdminContentManagerTeamsLeaguesNextComponent,
    ng1AdminContentManagerChallengesPlannedTimingsComponent,
    DlPageWrapperComponent,
    DlVoteWrapperComponent,
    DlProfileUploadWrapperComponent,
    DlFillWrapperComponent,
    DlSwapWrapperComponent,
    DlBoostWrapperComponent,
    DlLbWrapperComponent,
    DlSuggestedMembersWrapperComponent,
    ng1DlComponent,
    DlExhibitionWrapperComponent,
    EmptyWrapperComponent,
    PhotoPageWrapperComponent,
    ErrorPageWrapperComponent,
    ErrorPage404WrapperComponent,
    ErrorMaintenanceWrapperComponent,
    AiPageWrapperComponent,
    AiTermsWrapperComponent,
    AdsView1WrapperComponent,
    AdsView1View2WrapperComponent,
    LandingClaimPageWrapperComponent,
    Lp1PageWrapperComponent,
    Lp2PageWrapperComponent,
    Lp3PageWrapperComponent,
    Lp4PageWrapperComponent,
    Lp5PageWrapperComponent,
    Lp6PageWrapperComponent,
    LpMobilePageWrapperComponent,
    PhotoContestPageWrapperComponent,
    ng1PhotoComponent,
    ng1PhotoSwipeComponent,
    BoardingPageWrapperComponent,
    ng1BoardingComponent,
    VotePageWrapperComponent,
    ng1VoteComponent,
    SponsorsPageWrapperComponent,
    SponsorsMaterialsWrapperComponent,
    SponsorsPaymentsWrapperComponent,
    ng1SponsorsPageComponent,
    ModerationPageWrapperComponent,
    ModerationCopyrightWrapperComponent,
    ModerationOfftopicWrapperComponent,
    ModerationSpamWrapperComponent,
    ModerationAdultWrapperComponent,
    ModerationOfftopicImagesWrapperComponent,
    ng1ModerationComponent,
    ng1ModerationCopyRightComponent,
    ng1ModartionCopyOfftopicComponent,
    ng1ModartionImagesComponent,
    MobileMenuWrapperComponent,
    VirtualExhibitionsWrapperComponent,
    ProfileSettingsWrapperComponent,
    ChallengeAchievementsWrapperComponent,
    ActivityWrapperComponent,
    DeactivatedWrapperComponent,
    MobileMemberSearchWrapperComponent,
    MobileNotificationsWrapperComponent,
    ExhibitionWidgetWrapperComponent,
    ng1ExhibitionWidget,
  ],
  imports: [
    CoreModule,
    StoreModule,
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    UpgradeModule,
    HttpClientModule,
    ChallengesModule,
    LottieModule.forRoot({player: playerFactory}),
    AppRoutingModule,
    BankrollModule
  ],
  providers: [
    stateProvider,
    rootScopeServiceProvider,
    autoUpdateProvider,
    ModalsServiceBridgeProvider,
    BrazeProvider,
    TagEventProvider,
    MixpanelProvider,
    StateParamsProvider,
    gsModalsProvider,
    voteManagerProvider,
    photoSwipeProvider,
    ModalsProvider,
    challengeServiceProvider
  ]
})
export class AppModule implements DoBootstrap {
  constructor(private upgrade: UpgradeModule) {}

  public ngDoBootstrap(app: any): void {
    setAngularJSGlobal(oldAngular);

    mainModule.factory('ng2Injector', () => app.injector)
      .factory('ng2Router', () => app.injector.get(Router));

    this.upgrade.bootstrap(document.body, [mainModule.name], { strictDi: false });
    app.bootstrap(AppComponent);
  }
}
