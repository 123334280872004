import ImageModel from '../../../api/models/image/image.model';
const template = require(`html-loader!./offTopicCard.html`).default;

class offTopicCardCtrl {

  constructor($element, Modals, challengeService) {
    'ngInject';
    this.$element = $element;
    this.Modals = Modals;
    this.challengeService = challengeService;

    this.reasons = [];
  }

  $onChanges() {
    //Prepare data & reasons array
    this.gspopup.data.images = this.gspopup.data.images.map(image => new ImageModel(image));
  }

  swap(event) {

    this.close();

    if (this.challengeService.isSwapsEmpty()) {
      this.challengeService.openStoreSwapsSection();
      return true;
    }

    // Swap
    this.Modals.open('gsUploader', event, {
      target: 'swap',
      challenge: this.challenge
    });
  }
}

offTopicCardCtrl.$inject = [
  '$element', 'Modals', 'challengeService'
];
export default {
  template: template,
  controller: offTopicCardCtrl,
  bindings: {
    challenge: '<',
    gspopup: '<',
    close: '&'
  }
};
