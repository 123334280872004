/* globals angular */
function challengeMenuScrollDirective(
  $document,
  $timeout
) {
  "ngInject";

  return {
    restrict: 'A',
    scope: {
      menuScroll: '@'
    },
    link: function (scope, element, attr) {
      // **********
      // Vars
      // **********
      var topMenu = $('.c-challenge__top-menu');
      var width = window.innerWidth;

      // **********
      // _functions
      // **********
      function onScroll() {
        if (element[0].getBoundingClientRect().top <= 28) {
          topMenu.addClass('active');
        }
        else {
          topMenu.removeClass('active');
        }
      }

      // **********
      // Events
      // **********
      if (width > 767)
        $document.unbind('scroll', onScroll).bind('scroll', onScroll);

      // **********
      // Destroy
      // **********
      scope.$on("$destroy", function () {
        //Unbind scroll
        $document.unbind('scroll', onScroll);
      });
    }
  };
}

challengeMenuScrollDirective.$inject = [
  '$document',
  '$timeout'
];

export default challengeMenuScrollDirective;
