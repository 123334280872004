<div class="confirm-purchase gs-theme-bg-color-white_100">
  <div class="gs-center-flex gs-h-40 gs-theme-bg-color-gray_05 gs-w-100-p">
    <div class="gs-theme-text-bold gs-theme-color-gray_80">Purchase</div>
  </div>
  <div class="confirm-purchase_name gs-theme-text-caption-bold gs-theme-color-gray_80">
    Get {{data.item!.name}}
  </div>
  <app-store-item-icon-amount [typeClass]="data.sectionType!"
                              [item]="data.item!"
                              [itemSize]="100"
  ></app-store-item-icon-amount>
  <div class="btn gs-center-flex gs-theme-text-headline gs-c-p" [mat-dialog-close]="CloseModalType.BUY_WITH_COINS">
    <app-store-item-price [item]="data.item!"></app-store-item-price>
  </div>
</div>
<!--  <div class="gs-center-v-flex mask">-->
<!--    <div style="width: 210px; height: 185px">-->
<!--      <app-store-item-icon-amount [item]="data.item!"-->
<!--                                  [typeClass]="data.sectionType!"-->
<!--                                  [itemSize]="data.itemSize!"></app-store-item-icon-amount>-->
<!--    </div>-->
<!--  </div>-->

<!--  <div class="gs-m-t-27 gs-t-a-c gs-theme-text-headline">Get {{data.item!.name}}</div>-->
<!--  <div class="gs-theme-button gs-theme-gradient-quickshots gs-theme-text-bold gs-theme-color-white_100 gs-center-v-flex"-->
<!--       [mat-dialog-close]="true">-->
<!--    <app-store-item-price [item]="data.item!"></app-store-item-price>-->


