/* globals angular */

class pushService {z
  constructor($timeout) {
    'ngInject';

    this.$timeout = $timeout;
  }

  push({title = 'GuruShots', icon, body, link, timeout = 3000}) {

    if(!window.Notification) {
      return;
    }

    if (window.Notification.permission !== "granted") {
      window.Notification.requestPermission();
      return;
    }

    //Show
    let notification = new window.Notification(title, {icon, body});

    //On click
    if (link) {
      notification.onclick = function () {
        window.open(link);
      };
    }

    //Close timer
    if (timeout !== 0) {
      this.$timeout(() => {
        notification.close();
      }, timeout);
    }
  }
}
pushService.$inject = ['$timeout'];
export default pushService;
