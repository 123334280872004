import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {finalize, interval, take} from "rxjs";
import {map} from "rxjs/operators";

@Component({
  selector: 'app-ng-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss']
})
export class NgCountdownComponent implements OnInit {

  @Input() countdownInSec!:number;
  @Output() complete = new EventEmitter<undefined>();

  countdown!:number;

  constructor() { }

  ngOnInit(): void {
    this.countdown = this.countdownInSec;
    interval(1000).pipe(
      take(this.countdownInSec),
      map((count:number) => (this.countdownInSec-count-1)),
      finalize(() => this.complete.emit())
    ).subscribe(
      countdown => this.countdown = countdown
    );
  }
}
