import challengeEnum from '../../../../../api/enums/challenge.enum';

const challengesScheduleData = Object.freeze({
  PENDING_FILTERED: {
    sort_field: challengeEnum.SORT.LAST_UPDATE_TIME.VALUE,
    order: 'DESC'
  },
  PLANNING: {
    sort_field: challengeEnum.SORT.START_TIME.VALUE,
    order: 'DESC'
  },
  PENDING: {
    sort_field: challengeEnum.SORT.LAST_UPDATE_TIME.VALUE,
    order: 'DESC'
  },
  DRAFT: {
    sort_field: challengeEnum.SORT.LAST_UPDATE_TIME.VALUE,
    order: 'DESC'
  },
  UPCOMING: {
    sort_field: challengeEnum.SORT.START_TIME.VALUE,
    order: 'DESC'
  },
  IN_BID: {
    sort_field: challengeEnum.SORT.START_TIME.VALUE,
    order: 'DESC'
  },
  UNASSIGNED: {
    sort_field: challengeEnum.SORT.START_TIME.VALUE,
    order: 'DESC'
  },
  ASSIGNED: {
    sort_field: challengeEnum.SORT.START_TIME.VALUE,
    order: 'DESC'
  },
  ACTIVE: {
    sort_field: challengeEnum.SORT.START_TIME.VALUE,
    order: 'DESC'
  }
});

export default challengesScheduleData;
