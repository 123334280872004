import _ from "lodash";

class InfinityScrollService {

  constructor(
    $window,
    $rootScope
  ) {
    'ngInject';

    this.$window = $window;
    this.$rootScope = $rootScope;

    this.hundredPercent = 100;
    this.loadBeforePercent = 50;
    this.scrollBinded = _.debounce(this.scroll.bind(this), 100);
  }

  scroll() {
    const {
      scrollTop,
      scrollHeight,
      clientHeight
    } = document.documentElement;
    const documentHeight = scrollTop + clientHeight;
    const loadMoreScrollValue = scrollHeight - (scrollHeight * (this.loadBeforePercent / this.hundredPercent));
    console.log('loadMoreScrollValue', loadMoreScrollValue);
    console.log('documentHeight', documentHeight);
    if (documentHeight >= loadMoreScrollValue) {
      console.log('windowInfinityScroll');
      this.$rootScope.$emit('windowInfinityScroll');
    }
  }

  initWindowScrollEvent() {
    this.offWindowScrollEvent();
    this.$window.addEventListener('scroll', this.scrollBinded);
  }

  offWindowScrollEvent() {
    this.$window.removeEventListener('scroll', this.scrollBinded);
  }
}
InfinityScrollService.$inject = [
  '$window',
  '$rootScope',
];
export default InfinityScrollService;
