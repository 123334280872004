import { Component } from '@angular/core';

@Component({
  selector: 'app-admin-content-manager-chests-wrapper',
  templateUrl: './admin-content-manager-chests-wrapper.component.html',
  styleUrl: './admin-content-manager-chests-wrapper.component.scss'
})
export class AdminContentManagerChestsWrapperComponent {

}
