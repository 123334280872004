import {environment} from "../../../../environments/environment";

import {INgImageModel} from "./models";

import {NgMemberModel} from "./ng-member.model";
import {NgExifDataModel} from "./ng-exif-data.model";
import {NgAchievementModel} from "../../../modules/challenges/models/ng-achievement.model";
import {INgAchievementModel} from "../../../modules/challenges/interfaces/challenges.interface";

export class NgImageModel implements INgImageModel {
  image_url?;
  token?: any;
  id?: any;
  member_id?: any;
  achievements: any[] = [];
  comments: any[] = [];
  labels: any[] = [];
  member = new NgMemberModel({});
  meta_data = new NgExifDataModel({});
  boosting = false;
  turbo = false;

  private _pbLarge?: string;
  private _shareUrl?: string;
  private _noExifData? = false;
  private _alt? = '';

  constructor(props: INgImageModel = {}) {
    Object.assign(this, props);

    props.achievements && (this.achievements = props.achievements.map((achievement: INgAchievementModel): INgAchievementModel => new NgAchievementModel(achievement)));
    props.comments && (this.comments = props.comments);

    props.member && (this.member = new NgMemberModel(props.member));
    (props.image_url || props.id) && (this.image_url = props.image_url || props.id);

    if(props.meta_data) {
      this.meta_data = new NgExifDataModel(props.meta_data);
      /* TODO: we can create isExifData getter/setter */
      this._noExifData = !(
        this.meta_data.make ||
        this.meta_data.model ||
        this.meta_data.lens ||
        this.meta_data.f_number ||
        this.meta_data.exposure_time ||
        this.meta_data.focal_length ||
        this.meta_data.iso ||
        this.meta_data.post_processing
      );
    }

    if(props.labels) {
      this.labels = props.labels;
      this._alt = this.labels.join(', ');
    }

    this._pbLarge = `${this.gePhotoDomain()}/unsafe/0x0/${this.member_id}/3_${this.id}.jpg`;
    this._shareUrl = `https://${window.location.host}/photo/${this.id}`;
  }

  src = (width = 0, height = 0): string => `${this.gePhotoDomain()}/unsafe/${width}x${height}/${this.member_id}/3_${this.id}.jpg`;

  srcToken = (width = 0, height = 0): string => `${this.gePhotoDomain()}/unsafe/${width}x${height}/${this.token}`;

  gePhotoDomain = (): string => 'https://' + environment.domains.photos; /* TODO: let's move it to urlService */

  get pbLarge(): any {
    return this._pbLarge;
  }

  set pbLarge(value: any) {
    this._pbLarge = value;
  }

  get shareUrl(): any {
    return this._shareUrl;
  }

  set shareUrl(value: any) {
    this._shareUrl = value;
  }

  get noExifData(): any {
    return this._noExifData;
  }

  set noExifData(value: any) {
    this._noExifData = value;
  }

  get alt(): any {
    return this._alt;
  }

  set alt(value: any) {
    this._alt = value;
  }
}
