import { Component } from '@angular/core';

@Component({
  selector: 'app-deactivated-wrapper',
  templateUrl: './deactivated-wrapper.component.html',
  styleUrl: './deactivated-wrapper.component.scss'
})
export class DeactivatedWrapperComponent {

}
