const template = require(`html-loader!./unlockStateCard.html`).default;

import mixpanelEventsEnum from "../../mixpanel/mixpanel.enum";

class unlockStateCardCtrl {
  constructor(
    $element,
    Modals,
    mixpanelService,
    challengeService,
  ) {
    'ngInject';

    this.$element = $element;
    this.Modals = Modals;
    this.mixpanelService = mixpanelService;
    this.challengeService = challengeService;

    this.reasons = [];
  }

  unlock(event) {
    if (this.challengeService.isKeysEmpty()) {
      this.challengeService.openStoreKeysSection();
      return;
    }
    this.Modals.open('challengeActionLocked', event, {challenge: this.challenge});
    this.close();
  }

  eventMixPanelAction() {
    this.mixpanelService.track(
      {
        data: {
          ui_category: mixpanelEventsEnum.UI_CATEGORY.MISSED_BOOST,
          ui_name: mixpanelEventsEnum.UI_NAME.INFO_WHAT_IS_BOOST,
          challenge_id: this.challenge.id
        }
      }
    );
  }
}
unlockStateCardCtrl.$inject = [
  '$element', 'Modals','mixpanelService', 'challengeService'
];

export default {
  template: template,
  controller: unlockStateCardCtrl,
  bindings: {
    challenge: '<',
    gspopup: '<',
    close: '&'
  }
};
