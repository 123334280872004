export class EnvironmentStaticFacebookModel {
  facebookAppId: {[key: string]: string} = {
    preprod: '668625103154083',
    prod: '668625103154083',
    beta: '668625103154083',
    stage: '2024451431135713'
  }

    constructor() {}

    get(env: string): string {
        return this.facebookAppId[env];
    }
}
