const template = require(`html-loader!./similarFilter.html`).default;

class similarFilterCtrl {
  /**
   * @param {RestManager} RestManager
   * @param {ModalsService} Modals
   * */
  constructor(
    $element,
    Modals,
    RestManager
  ) {
    'ngInject';

    this.$element = $element;
    this.Modals = Modals;
    this.RestManager = RestManager;
  }

  check(event) {
    this.Modals.open('reportSimilar', event, {
      member_id: this.image.member_id,
      token: this.image.token,
      c_id: this.challenge.id
    });

    this.$element.remove();
  }
}

similarFilterCtrl.$inject = [
  '$element',
  'Modals',
  'RestManager'
];

export default {
  template: template,
  controller: similarFilterCtrl,
  bindings: {
    image: '<',
    challenge: '<'
  }
};
