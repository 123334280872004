const template = require(`html-loader!./startMatch.html`).default;

class startMatchCtrl {

  constructor($mdDialog, $rootScope, stateService, RestManager) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.$rootScope = $rootScope;
    this.stateService = stateService;
    this.RestManager = RestManager;
  }

  $onInit() {
    this.offOnMatchChanged = this.$rootScope.$on('onMatchChanged', this.onMatchChanged.bind(this));
  }

  onMatchChanged(){
    console.log('teamMatchCtrl onMatchChanged catch event');
    this.close();
  }

  $onDestroy() {
    this.offOnMatchChanged();
  }

  close() {
    this.$mdDialog.hide();
  }

  async start(){
    const res = await this.RestManager.joinMatch({team_id: this.data.teamId, challenge_id: this.data.challenge.id});
    if(!res.success){
      return;
    }
    // this.stateService.reload();
    // this.data.callback();
    this.close();
  }
}
startMatchCtrl.$inject = [
  '$mdDialog', '$rootScope', 'stateService', 'RestManager'
];
export default {
  template: template,
  controller: startMatchCtrl,
  bindings: {
    data: '=data',
  },
};
