import gsHeader from './gsHeader/gsHeader';
import gsFooter from './gsFooter/gsFooter';
import gsNotifications from './gsNotifications/gsNotifications';
import gsNotificationsItem from './gsNotifications/gsNotificationsItem/gsNotificationsItem';
import gsNotificationsService from './gsNotifications/gsNotifications.service';
import eventsHeaderService from "../services/eventsHeader.service";
import gsBanner from './gsBanner/gsBanner.component';
import gsChallengeBox from './gsChallengeBox/gsChallengeBox.component';
import memberPath from './memberPath/memberPath';
import gsChallenge from './gsChallenge/gsChallenge';
import challengeMobile from './challengeMobile/challengeMobile';
import socialBar from './socialBar/socialBar';
import badge from './badge/badge';
import spotlight from './spotlight/spotlight';
import spotlights from './spotlights/spotlights';
import searchBox from './searchBox/searchBox';
import followCard from './followCard/followCard';
import gsComments from './gsComments/gsComments';
import gsMembersList from './gsMembersList/gsMembersList';
import adultFilter from './adultFilter/adultFilter';
import similarFilter from './similarFilter/similarFilter';
import offTopicFilter from './offTopicFilter/offTopicFilter';
import followBtn from './followBtn/followBtn';
import likeBtn from './likeBtn/likeBtn';
import mobileHeader from './mobileHeader/mobileHeader';
import mobileHeaderEmpty from './mobileHeaderEmpty/mobileHeaderEmpty';
import facebookFriends from './facebookFriends/facebookFriends';
import downloadApps from './downloadApps/downloadApps';
import fixedFooter from './fixedFooter/fixedFooter';
import gsJustifiedGallery from './gsJustifiedGallery/gsJustifiedGallery';
import avatarFull from './avatarFull/avatarFull';
import avatar from './avatar/avatar';
import leaderBoard from './leaderBoard/leaderBoard';
import leaderBoardMemberItem from './leaderBoard/leaderBoardMemberItem/leaderBoardMemberItem';
import memberName from './memberName/memberName';
import teamBadge from './teamBadge/teamBadge';
import teamName from './teamName/teamName';
import teamJoinButton from './teamJoinButton/teamJoinButton';
import badgeV2 from './badgeV2/badgeV2';
import badgeV2DynamicChallenge from './badgeV2/badgeV2DynamicChallenge/badgeV2DynamicChallenge';
import badgeV2Exhibition from './badgeV2/badgeV2Exhibition/badgeV2Exhibition';
import badgeV2ExhibitionPreview from './badgeV2/badgeV2Exhibition/badgeV2ExhibitionPreview/badgeV2ExhibitionPreview';
import badgeV2ExhibitionDetails from './badgeV2/badgeV2Exhibition/badgeV2ExhibitionDetails/badgeV2ExhibitionDetails';
import badgeV2ExhibitionMobile from './badgeV2/badgeV2Exhibition/badgeV2ExhibitionMobile/badgeV2ExhibitionMobile';
import badgeV2ExhibitionCompleted from './badgeV2/badgeV2Exhibition/badgeV2ExhibitionCompleted/badgeV2ExhibitionCompleted';
import badgeV2Battle from "./badgeV2/badgeV2Battle/badgeV2Battle";
import media from './media/media';
import mediaYoutube from './media/mediaYoutube/mediaYoutube';
import teamsIntro from './teamsIntro/teamsIntro';
import vimeo from './vimeo/vimeo';
import TeamsSuggestions from './_blocks/teamsSuggestions/teamsSuggestions';
import TeamsMembers from './teamsMembers/teamsMembers';
import TeamsCountry from './teamsCountry/teamsCountry';
import TeamsScore from './teamsScore/teamsScore';
import TeamsViewButton from './teamsViewButton/teamsViewButton';
import ElementProtection from './elementProtection/elementProtection';
import gsTable from './gsTable/gsTable';
import topTabMenu from './topTabsMenu/topTabMenu';
import challengeImages from './challengeImages/challengeImages';
import gsChallengeNew from './gsChallengeNew/gsChallengeNew';
import gsChallengeNewCompleted from './gsChallengeNew/gsChallengeNewCompleted/gsChallengeNewCompleted';
import gsChallengeNewAchievementHistory from './gsChallengeNew/gsChallengeNewAchievementHistory/gsChallengeNewAchievementHistory';
import rankingLevelBadge from './rankingLevelBadge/rankingLevelBadge';
import achievements from './achievements/achievements';
import gsIcon from './gsIcon/gsIcon';
import shareButton from './shareButton/shareButton';
import leaderBoardItems from './leaderBoardItems/leaderBoardItems';
import teamItem from './teamItem/teamItem';
import leaderBoardItem from './leaderBoardItems/leaderBoardItem/leaderBoardItem';
import memberItem from './memberItem/memberItem';
import country from './country/country';
import memberStatus from './memberStatus/memberStatus';
import leagueLevel from './leagueLevel/leagueLevel';
import brazeContentCards from './braze/contentCards/brazeContentCards';
import BrazeContentCardsCaptionedImage from './braze/custom/captionedImage/brazeContentCardsCaptionedImage';
import BrazeContentCardsBanner from './braze/custom/banner/brazeContentCardsBanner';
import gsTableEditable from "./gsTableEditable/gsTableEditable";
import gsImage from './gsImage/gsImage';
import helpButton from './helpButton/helpButton';
import gsProgressBar from './gsProgressBar/gsProgressBar';
import gsCloseButton from './gsCloseButton/gsCloseButton';
import achievementsInfo from './achievementsInfo/achievementsInfo';
import achievementInfo from './achievementInfo/achievementInfo';
import stars from './stars/stars';
import MarkAsAdult from "./markAsAdult/markAsAdult";
import achievementBadge from './achievementBadge/achievementBadge';
import blockedUserItem from "./blockedUserItem/blockedUserItem";
import blockUserButtonComponent from "./blockUserButton/blockUserButton.component";
import horizontalScrollbarArrows from './horizontalScrollbarArrows/horizontalScrollbarArrows';
import {downgradeComponent} from "@angular/upgrade/static";
import {MaintenanceComponent} from "../../../components/maintenance/maintenance.component";
import ChallengesJoinedCoverPhoto from "./challengesJoinedCoverPhoto/challengesJoinedCoverPhoto";
import challengesJoinedActionsRanking from "./challengesJoinedActionsRanking/challengesJoinedActionsRanking";
import ChallengesRewards from "./challengesRewards/challengesRewards";
import {GsLoader} from "../../../shared/components/gs-loader/gs-loader";
import {AiTermsComponent} from "../../../components/ai-terms/ai-terms.component";
import {MuteButtonComponent} from "../../../shared/components/mute-button/mute-button.component";
import {NgImageComponent} from "../../../shared/components/ng-image/ng-image.component";
import {
  MiniGameModalComponent
} from "../../../modules/challenges/components/mini-game-modal/mini-game-modal.component";
import {AiFilterComponent} from "../../../modules/challenges/components/ai-filter/ai-filter.component";
import {NgRewardsComponent} from "../../../shared/components/ng-rewards/ng-rewards.component";
import {PrizeItemComponent} from "../../../modules/challenges/components/prize-item/prize-item.component";
import {NgFollowComponent} from "../../../shared/components/ng-follow/ng-follow.component";
import {NgAvatarComponent} from "../../../shared/components/ng-avatar/ng-avatar.component";
import {WinnerListItemComponent} from "../../../shared/components/winner-list-item/winner-list-item.component";
import {NgAdultFilterComponent} from "../../../shared/components/ng-adult-filter/ng-adult-filter.component";
import {NgExposureMeterComponent} from "../../../shared/components/ng-exposure-meter/ng-exposure-meter.component";
import {UsersLimitComponent} from "../../../shared/components/users-limit/users-limit.component";
import {
  PhotographerRankingComponent
} from "../../../modules/challenges/components/photographer-ranking/photographer-ranking.component";
import {
  ActiveChallengeActionButtonComponent
} from "../../../modules/challenges/components/active-challenge-action-button/active-challenge-action-button.component";
import {ResourceIndicatorComponent} from "../../../shared/components/resource-indicator/resource-indicator.component";
import {PlayersAmountComponent} from "../../../shared/components/players-amount/players-amount.component";
import {NgResourcesComponent} from "../../../shared/components/ng-resources/ng-resources.component";
import {ClaimButtonComponent} from "../../../shared/components/claim-button/claim-button.component";
import {LocateButtonComponent} from "../../../shared/components/locate-button/locate-button.component";
import {GlobalBankrollComponent} from "../../../modules/bankroll/components/global-bankroll/global-bankroll.component";
import {
  ChallengesNavigationComponent
} from "../../../modules/challenges/components/challenges-navigation/challenges-navigation.component";
import {SortOrderComponent} from "../../../shared/components/sort-order/sort-order.component";
import {
  ChallengesHeaderComponent
} from "../../../modules/challenges/containers/challenges-header/challenges-header.component";

let moduleComponents = angular
  .module('gsApp.components', [])
  .service('gsNotificationsService', gsNotificationsService)
  .service('eventsHeaderService', eventsHeaderService)
  .component('gsHeader', gsHeader)
  .component('gsFooter', gsFooter)
  .component('gsBanner', gsBanner)
  .component('gsChallengeBox', gsChallengeBox)
  .component('gsNotifications', gsNotifications)
  .component('gsNotificationsItem', gsNotificationsItem)
  .component('memberPath', memberPath)
  .component('gsChallenge', gsChallenge)
  .component('challengeMobile', challengeMobile)
  .component('socialBar', socialBar)
  .component('badge', badge)
  .component('spotlight', spotlight)
  .component('spotlights', spotlights)
  .component('searchBox', searchBox)
  .component('gsComments', gsComments)
  .component('gsMembersList', gsMembersList)
  .component('followCard', followCard)
  .component('adultFilter', adultFilter)
  .component('similarFilter', similarFilter)
  .component('offTopicFilter', offTopicFilter)
  .component('followBtn', followBtn)
  .component('likeBtn', likeBtn)
  .component('mobileHeader', mobileHeader)
  .component('mobileHeaderEmpty', mobileHeaderEmpty)
  .component('downloadApps', downloadApps)
  .component('facebookFriends', facebookFriends)
  .component('fixedFooter', fixedFooter)
  .component('gsJustifiedGallery', gsJustifiedGallery)
  .component('leaderBoard', leaderBoard)
  .component('leaderBoardMemberItem', leaderBoardMemberItem)
  .component('memberName', memberName)
  .component('avatarFull', avatarFull)
  .component('avatar', avatar)
  .component('teamBadge', teamBadge)
  .component('teamName', teamName)
  .component('teamJoinButton', teamJoinButton)
  .component('badgeV2', badgeV2)
  .component('badgeV2Exhibition', badgeV2Exhibition)
  .component('badgeV2ExhibitionPreview', badgeV2ExhibitionPreview)
  .component('badgeV2ExhibitionDetails', badgeV2ExhibitionDetails)
  .component('badgeV2ExhibitionMobile', badgeV2ExhibitionMobile)
  .component('badgeV2ExhibitionCompleted', badgeV2ExhibitionCompleted)
  .component('badgeV2DynamicChallenge', badgeV2DynamicChallenge)
  .component('badgeV2Battle', badgeV2Battle)
  .component('media', media)
  .component('mediaYoutube', mediaYoutube)
  .component('teamsIntro', teamsIntro)
  .component('vimeo', vimeo)
  .component('teamsSuggestions', TeamsSuggestions)
  .component('teamsMembers', TeamsMembers)
  .component('teamsCountry', TeamsCountry)
  .component('teamsScore', TeamsScore)
  .component('teamsViewButton', TeamsViewButton)
  .component('elementProtection', ElementProtection)
  .component('gsTable', gsTable)
  .component('topTabMenu', topTabMenu)
  .component('challengeImages', challengeImages)
  .component('gsChallengeNew', gsChallengeNew)
  .component('gsChallengeNewCompleted', gsChallengeNewCompleted)
  .component('gsChallengeNewAchievementHistory', gsChallengeNewAchievementHistory)
  .component('rankingLevelBadge', rankingLevelBadge)
  .component('achievements', achievements)
  .component('gsIcon', gsIcon)
  .component('shareButton', shareButton)
  .component('leaderBoardItems', leaderBoardItems)
  .component('teamItem', teamItem)
  .component('leaderBoardItem', leaderBoardItem)
  .component('memberItem', memberItem)
  .component('country', country)
  .component('memberStatus', memberStatus)
  .component('leagueLevel', leagueLevel)
  .component('brazeContentCards', brazeContentCards)
  .component('brazeContentCardsCaptionedImage', BrazeContentCardsCaptionedImage)
  .component('brazeContentCardsBanner', BrazeContentCardsBanner)
  .component('gsTableEditable', gsTableEditable)
  .component('blockedUserItem', blockedUserItem)
  .component('blockUserButton', blockUserButtonComponent)
  .component('gsImage', gsImage)
  .component('helpButton', helpButton)
  .component('gsProgressBar', gsProgressBar)
  .component('gsCloseButton', gsCloseButton)
  .component('achievementsInfo', achievementsInfo)
  .component('achievementInfo', achievementInfo)
  .component('achievementBadge', achievementBadge)
  .component('stars', stars)
  .component('markAsAdult', MarkAsAdult)
  .component('horizontalScrollbarArrows', horizontalScrollbarArrows)
  .component('challengesJoinedActionsRanking', challengesJoinedActionsRanking)
  .component('challengesJoinedCoverPhoto', ChallengesJoinedCoverPhoto)
  .component('challengesRewards', ChallengesRewards)
  .directive('maintenance', downgradeComponent({
    component: MaintenanceComponent
  }))
  .directive('appGsLoader', downgradeComponent({
    component: GsLoader
  }))
  .directive('appAiTerms', downgradeComponent({
    component: AiTermsComponent
  }))
  .directive('appMuteButtonComponent', downgradeComponent({
    component: MuteButtonComponent
  }))
  .directive('appNgImageComponent', downgradeComponent({
    component: NgImageComponent
  }))
  .directive('appMiniGameComponent', downgradeComponent({
    component: MiniGameModalComponent
  }))
  .directive('appAiFilterComponent', downgradeComponent({
    component: AiFilterComponent
  }))
  .directive('appPrizeItemComponent', downgradeComponent({
    component: PrizeItemComponent
  }))
  .directive('appNgRewardsComponent', downgradeComponent({
    component: NgRewardsComponent
  }))
  .directive('appNgFollowComponent', downgradeComponent({
    component: NgFollowComponent
  }))
  .directive('appNgAvatarComponent', downgradeComponent({
    component: NgAvatarComponent,
    inputs: ['member', 'clickable', 'size', 'showLeagueBadge'],
  }))
  .directive('appWinnerListItemComponent', downgradeComponent({
    component: WinnerListItemComponent,
  }))
  .directive('appNgAdultFilterComponent', downgradeComponent({
    component: NgAdultFilterComponent,
  }))
  .directive('appNgExposureMeterComponent', downgradeComponent({
    component: NgExposureMeterComponent
  }))
  .directive('appUsersLimitComponent', downgradeComponent({
    component: UsersLimitComponent
  }))
  .directive('appLocateButtonComponent', downgradeComponent({
    component: LocateButtonComponent
  }))
  .directive('appPhotographerRankingComponent', downgradeComponent({
    component: PhotographerRankingComponent,
  }))
  .directive('appActiveChallengeActionButtonComponent', downgradeComponent({
    component: ActiveChallengeActionButtonComponent,
  }))
  .directive('appResourceIndicatorComponent', downgradeComponent({
    component: ResourceIndicatorComponent,
  }))
  .directive('appPlayersAmountComponent', downgradeComponent({
    component: PlayersAmountComponent,
  }))
  .directive('appGlobalBankrollComponent', downgradeComponent({
    component: GlobalBankrollComponent,
  }))
  .directive('appNgResourcesComponent', downgradeComponent({
    component: NgResourcesComponent,
  }))
  .directive('appClaimButtonComponent', downgradeComponent({
    component: ClaimButtonComponent,
  }))
  .directive('appChallengesNavigationComponent', downgradeComponent({
    component: ChallengesNavigationComponent,
  }))
  .directive('appSortOrderComponent', downgradeComponent({
    component: SortOrderComponent,
  }))
  .directive('appChallengesHeaderComponent', downgradeComponent({
    component: ChallengesHeaderComponent,
  }))
;
export default moduleComponents.name;


