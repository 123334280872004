<ng-container *ngIf="src">
  <ng-container *ngIf="fill; else noFill">
    <img
      *ngIf="!isImageError"
      [ngSrc]="src"
      fill
      (error)="onError()"
      (load)="onLoad()"
      [priority]="priority"
      [alt]="alt"
    >
  </ng-container>

  <ng-template #noFill>
    <img
      *ngIf="!isImageError"
      [ngSrc]="src"
      [width]="width"
      [height]="height"
      (error)="onError()"
      (load)="onLoad()"
      [priority]="priority"
      [alt]="alt"
    >
  </ng-template>
</ng-container>
