import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";

const template = require(`html-loader!./moderationOfftopic.html`).default;
import {extractContentFromHtml} from "../../../helpers/string.helper";

class moderationOfftopicCtrl {
  /**
   * @param {stateService} stateService
   * @param {RestManager} RestManager
   * @param {ChallengesManager} ChallengesManager
   * */

  constructor(stateService, $rootScope, $scope, RestManager, ChallengesManager) {
    'ngInject';
    this.title = "Off Topic reports";
    this.subtitle = "Below is a list of challenges. Each challenge will have photos that are marked by other users as Off Topic. Review the photos and confirm if they are Off Topic or not.";
    this.items = [];
    this.challengesList = [];
    this.allLoaded = false;
    this.limit= 100;
    this.start= 0;

    this.stateService = stateService;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.RestManager = RestManager;
    this.ChallengesManager = ChallengesManager;
  }

  $onInit() {
    this.getData();
  }

  /** @return void */
  async getData() {
    this.busy = true;
    // const res = await this.ChallengesManager.getMemberChallenges('open');
    const res = await this.RestManager.getOffTopicReports();
    if (!res.success) {
      return;
    }

    this.items = res.items.map((challenge) =>{
      challenge.member = {submit_state:'offtopic'};
      return new NgChallengeModel(challenge);
    });

    this.challengesList = this.items.map((challenge) =>{
      return {
        url:challenge.url,
        id:challenge.id,
        numberOfReports:challenge.number_of_reports,
        title:challenge.title,
        welcome_message:extractContentFromHtml(challenge.welcome_message, true)
      };
    });


    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
moderationOfftopicCtrl.$inject = [
  'stateService', '$rootScope', '$scope', 'RestManager', 'ChallengesManager'
];
export default {
  template,
  controller: moderationOfftopicCtrl,
};
