import mixpanelEventsEnum from "../../services/mixpanel/mixpanel.enum";

const ChallengesJoinedActionsRankingTemplate = require(`html-loader!./challengesJoinedActionsRanking.html`).default;

class ChallengesJoinedActionsRanking {
  constructor(
    mixpanelService,
    stateService
  ) {
    'ngInject';

    this.mixpanelService = mixpanelService;
    this.stateService = stateService;
  }

  onRankingClick(){
    this.mixpanelService.track(
      {
        data:{
          ui_category: mixpanelEventsEnum.UI_CATEGORY.ACTIVE_CHALLENGE,
          ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
          ui_name: mixpanelEventsEnum.UI_NAME.RANKING,
          challenge_id:this.challenge.id
        }
      }
    );
  }
}

ChallengesJoinedActionsRanking.$inject = [
  'mixpanelService',
  'stateService',
];

export default {
  template: ChallengesJoinedActionsRankingTemplate,
  controller: ChallengesJoinedActionsRanking,
  bindings: {
    challenge: '<'
  }
};
