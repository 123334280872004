const template = require(`html-loader!./badgeV2ExhibitionDetails.html`).default;

class badgeV2ExhibitionDetailsController {
  constructor(

  ) {
    'ngInject';
  }

  $onInit() {
    this.className = `badge-details__${this.name}`;
    this.fullFileName = `icon-${this.name}-circle.svg`;
    this.isPremium = this.name.toLowerCase() === 'premium';
  }
}

badgeV2ExhibitionDetailsController.$inject = [

];

export default {
  template: template,
  controller: badgeV2ExhibitionDetailsController,
  bindings: {
    name: '<',
    textLeft: '<',
    textRight: '<'
  }
};
