import {
  INgImageModel
} from "../../../core/models/fromOldApp/models";
import {NgImageModel} from "../../../core/models/fromOldApp/ng-image.model";
import {NgBoostModel} from "./ng-boost.model";
import {NgRankingModel} from "./ng-ranking.model";
import {NgAchievementModel} from "./ng-achievement.model";
import {NgTeamChestModel} from "../../../core/models/fromOldApp/ng-team-chest.model";
import {NgChallengeExhibitionModel} from "./ng-challenge-exhibition.model";
import {
  INgAchievementModel,
  INgChallengeMemberModel,
  INgChallengeMemberShareModel, INgRewardModel
} from "../interfaces/challenges.interface";

export class NgChallengeMemberModel implements INgChallengeMemberModel {
  boost;
  ranking;
  rewards?:INgRewardModel[];
  exhibition;
  suggested_images;
  share?:INgChallengeMemberShareModel;
  chest;
  achievements;
  is_new: any;

  constructor(props: INgChallengeMemberModel = {}) {
    Object.assign(this, props);

    props.boost && (this.boost = new NgBoostModel(props.boost));
    props.ranking && (this.ranking = new NgRankingModel(props.ranking));
    (props.achievements && props.achievements.length) && (this.achievements = props.achievements.map((achievement: INgAchievementModel): INgAchievementModel => new NgAchievementModel(achievement)));
    props.exhibition && (this.exhibition = new NgChallengeExhibitionModel(props.exhibition));
    props.suggested_images && (this.suggested_images = props.suggested_images.map((suggested_image: INgImageModel): INgImageModel => new NgImageModel(suggested_image)));
    props.chest && (this.chest = new NgTeamChestModel(props.chest));
  }

  /* TODO: why we need init methods to get simply value? */
  isNew = ():any => this.is_new;
  isChest = (): any => this.chest;
  getImages = (): any => this.ranking!.entries;
  getRankingLevel = (): any => this.ranking!.total!.rankingLevel;
}
