import ImageModel from '../image/image.model';
import ArticleItemModel from './articleItem.model';

class ArticleModel {
  constructor({
    id,
    published = false,
    cover_image = {},
    title,
    text = '',
    url,
    date,
    views,
    keywords,
    auto_banners = true,
    items = []
  }) {
    cover_image = new ImageModel(cover_image);
    items = items.map(item => new ArticleItemModel(item));
    Object.assign(this, {
      id,
      published,
      cover_image,
      title,
      text,
      url,
      date,
      views,
      keywords,
      auto_banners,
      items
    });
  }

  get shortTitle() {
    return this.title.length < 76 ? this.title : this.title.substr(0, 76) + '...';
  }

  get shortText() {
    return this.text.length < 214 ? this.text : this.text.substr(0, 214) + '...';
  }

  get shareUrl() {
    return `https://${window.location.host}/article/${this.url}`;
  }

  get shareInfo() {
    return {
      url: `https://${window.location.host}/article/${this.url}`,
      image: this.cover_image,
      title: this.shortTitle,
      description: this.shortText
    };
  }
}

export default ArticleModel;
