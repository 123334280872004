const template = require(`html-loader!./helpButton.html`).default;

class helpButtonCtrl {
  constructor(

  ) {}
}

helpButtonCtrl.$inject = [

];

export default {
  template: template,
  controller: helpButtonCtrl,
  bindings: {}
};
