export const types = [
  {
    title: 'Image',
    id: 'image'
  },
  {
    title: 'Text',
    id: 'html'
  },
  {
    title: 'Youtube ID',
    id: 'youtube'
  },
  {
    title: 'Article',
    id: 'article'
  },
  {
    title: 'CTA button',
    id: 'action'
  },
  {
    title: 'CTA banner',
    id: 'banner'
  }
];

export const banners = [
  {
    title: '5M Votes',
    url: '/assets/images/pages/articles/Banner_Article_B_02.jpg'
  },
  {
    title: '150K Prizes',
    url: '/assets/images/pages/articles/Banner_Article_B_01.jpg'
  },
  {
    title: '30 challenges',
    url: '/assets/images/pages/articles/Banner_Article_B_03.jpg'
  },
  {
    title: '150 countries',
    url: '/assets/images/pages/articles/Banner_Article_B_04.jpg'
  },
  {
    title: 'Improve Orange',
    url: '/assets/images/pages/articles/improve_orange.jpg'
  },
  {
    title: 'Improve White',
    url: '/assets/images/pages/articles/improve_white.jpg'
  },
  {
    title: 'Improve Pink',
    url: '/assets/images/pages/articles/improve_pink.jpg'
  },
  {
    title: 'Improve Votes',
    url: '/assets/images/pages/articles/improve_votes.jpg'
  }
];