import { Component } from '@angular/core';

@Component({
  selector: 'app-landing-claim-page-wrapper',
  templateUrl: './landing-claim-page-wrapper.component.html',
  styleUrl: './landing-claim-page-wrapper.component.scss'
})
export class LandingClaimPageWrapperComponent {

}
