/* globals angular */
const template = require(`html-loader!./gsChallengeBox.component.html`).default;

class gsChallengeBoxCtrl {
  constructor() {}
}

gsChallengeBoxCtrl.$inject = [

];

export default {
  template: template,
  controller: gsChallengeBoxCtrl,
  bindings: {
    data: '<'
  }
};
