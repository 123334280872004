import {millisecondsInSecond, secondsInMinute} from "../helpers/date.helper";

class idleService {

  constructor(
    $timeout,
    SessionService
  ) {
    'ngInject';

    this._isIdle = false;
    this.timerPromise = null;
    this.idleDelayTime = millisecondsInSecond  * secondsInMinute * 3;

    this.isReloadAppIdle = false;
    this.reloadAppTimerPromise = null;
    this.reloadAppDelayTime = millisecondsInSecond  * secondsInMinute * 10;
    // this.reloadAppDelayTime = millisecondsInSecond  * 10;

    this.$timeout = $timeout;
    this.sessionService = SessionService;
    this.addIdleListeners();
  }

  addIdleListeners(){
    const callback = this.memberInteractWithApp.bind(this);
    window.onload = callback;
    window.onmousemove = callback;
    window.onmousedown = callback;  // catches touchscreen presses as well
    window.ontouchstart = callback; // catches touchscreen swipes as well
    window.onclick = callback;      // catches touchpad clicks as well
    window.onkeydown = callback;
    window.addEventListener('scroll', callback, true);
    window.addEventListener("focus", callback);
    document.addEventListener('visibilitychange', ()=>{
      if(document.visibilityState === 'visible'){
        callback();
      } else {
        this.isIdle = true;
      }
    });
  }

  memberInteractWithApp(event) {
    this.$timeout.cancel(this.timerPromise);
    this.isIdle = false;
    this.timerPromise = this.$timeout(()=>{
      this.isIdle = true;
    }, this.idleDelayTime);

    if(this.isReloadAppIdle){
      this.sessionService.onNewSession();
    }
    this.isReloadAppIdle = false;
    this.$timeout.cancel(this.reloadAppTimerPromise);
    this.reloadAppTimerPromise = this.$timeout(()=>{
      this.isReloadAppIdle = true;
    }, this.reloadAppDelayTime);
  }

  set isIdle (value){
    this._isIdle = value;
  }

  get isIdle (){
    return this._isIdle;
  }
}

idleService.$inject = [
  '$timeout',
  'SessionService'
];

export default idleService;
