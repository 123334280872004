export const levels = [
  {index: 0, name: "Not<br>Ranked"},
  {index: 1, name: "Popular"},
  {index: 2, name: "Skilled"},
  {index: 3, name: "Premier"},
  {index: 4, name: "Elite"},
  {index: 5, name: "All Star"}
];
export const levelNames = [
  "Not Ranked",
  "Popular",
  "Skilled",
  "Premier",
  "Elite",
  "All Star"
];
/*TODO move to image enum*/
export const filterReasonIds = {
  adult: 0,
  off_topic: 2,
  copyright: 3,
  winning: 4,
  similar: 5,
  ai: 7,
};
