
// elastic search decoder
export function elasticSearchDecode(query = '') {
  query = parseQuery(query);
  let group = [
    {
      type: 'group',
      value: [],
      root: true
    }
  ];
  const index = query.indexOf(' ((');
  if(index === -1){
    decodeGroup(query, group[0]);
  }else{
    let queryArr = query.split(' ((');
    queryArr.forEach((queryText, index) =>{
      if(index === 0){
        decodeGroup(queryText, group[0]);
      }else{
        decodeGroup(queryText
          ,{
            type: 'group',
            value: [],
            root: false
          },group[0]);
      }
    });
  }
  return group;
}

export function elasticSearchEncode(queryArr = []) {
  let query = '';
  for (const item of queryArr) {
    switch (item.type) {
      case 'group':
        query += '(' + elasticSearchEncode(item.value) + ')';
        break;
      case 'text':
        // query += '(' + item.value.toLowerCase().trim().replace(/\s/g, '') + ')';
        query += '(' + item.value.toLowerCase() + ')';
        break;
      case 'condition':
        query += ' ' + item.value + ' ';
        break;
    }
  }
  return query;
}


function decodeGroup(query, group, parentGroup) {
  query = query.replace(/\(/g, '')
    .replace(/\)/g, '')
    .replace(/AND NOT/g, 'ANDNOT')
    .replace(/OR NOT/g, 'ORNOT');

  let queryArr = query.split(' ');
  const arrLength = queryArr.length-1;
  let groupCondition = null;
  queryArr.forEach((text, index) =>{
    if (/^(ANDNOT|ORNOT|OR|AND)/.exec(text)) {
      text = text.replace('ANDNOT', 'AND NOT')
        .replace('ORNOT', 'OR NOT');

      if(parentGroup && index === arrLength){
        groupCondition = {
          type: 'condition',
          value: text,
        };
      }else {
        group.value.push({
          type: 'condition',
          value: text,
        });
      }
    }else {
      text = text.replace(/__/g, ' ');
      group.value.push({
        type: 'text',
        value: text.trim(),
      });
    }
  });
  if(parentGroup){
    parentGroup.value.push(group);
  }
  if(groupCondition){
    parentGroup.value.push(groupCondition);
  }
}

function parseQuery(query){
  return query.replace(/--/g, '')
    .replace(/ AND NOT /g, '--AND--NOT--')
    .replace(/ OR NOT /g, '--OR--NOT--')
    .replace(/ OR /g, '--OR--')
    .replace(/ AND /g, '--AND--')
    .replace(/\s/g, '__')
    .replace(/--/g, ' ');
}

// export function elasticSearchDecode(queryRest = '', group = []) {
//   let matches;
//
//   // GROUPS
//   if ((matches = /^\(\((.*)\)\)/.exec(queryRest))) {
//     console.log('matches: ', matches);
//     let text = matches.splice(0, 1)[0];
//     queryRest = queryRest.slice(text.length).trim();
//     console.log('group detected: ', text);
//     console.log('queryRest:', queryRest);
//     text = text.slice(1, -1);
//     group.push({
//       type: 'group',
//       value: elasticSearchDecode(text.toString()),
//       root: false
//     });
//     if(group.length === 1) group[0].root = true;
//     return elasticSearchDecode(queryRest, group);
//   }
//
//   // STRINGS
//   if ((matches = /^(\(.*?\))/.exec(queryRest))) {
//     let text = matches.splice(0, 1)[0];
//     queryRest = queryRest.slice(text.length).trim();
//     console.log('string detected: ', text);
//     console.log('queryRest:', queryRest);
//     text = text.slice(1, -1);
//     group.push({
//       type: 'text',
//       value: text,
//     });
//     return elasticSearchDecode(queryRest, group);
//   }
//
//   // AND NOT, OR NOT, OR, AND
//   if ((matches = /^(AND NOT|OR NOT|OR|AND)/.exec(queryRest))) {
//     let text = matches.splice(0, 1)[0];
//     queryRest = queryRest.slice(text.length).trim();
//     console.log('condition detected: ', text);
//     console.log('queryRest:', queryRest);
//     group.push({
//       type: 'condition',
//       value: text,
//     });
//     return elasticSearchDecode(queryRest, group);
//   }
//   // result
//   return group;
// }

