import {DestroyRef, Inject, inject, Injectable} from '@angular/core';
import RequestServiceModel from "../../../core/services/request/requestService.model";
import NgRequestEnum from "../../../core/services/request/request.enum";
import {BehaviorSubject, firstValueFrom, Observable} from "rxjs";
import {
  IGetStore,
  IGetStoreItem,
  IStoreItem,
  ISuggestedStoreItems,
  ISuggestedStoreItemsResponse
} from "./store.service.types";
import RequestService from "../../../core/services/request/requestService";
import {CloseModalType, SectionType, StoreCurrencyType} from "../models/enums";
import {NgModalsService} from "../../angular-material/services/ng-modals.service";
import {ResourceType} from "../../../core/models/enums";
import {PromiseHelper} from "../../../core/helpers/PromiseHelper";
import {ScrollService} from "../../../core/services/scroll.service";
import {BankrollService, IUpdateBankrollItemAmountParams} from "../../bankroll/bankroll.service";
import {
  IStoreCompletePurchaseModalEventData,
  IStorePackageSelectEventData,
  StoreCompletePurchaseUiAction,
  StoreEventOrigin,
  StoreEventsService
} from "./store-events.service";
// @ts-ignore
import mixpanelEventsEnum from "../../../gsApp/app/services/mixpanel/mixpanel.enum";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {OutOfResourceCloseModalType} from "../containers/out-of-resource/out-of-resource.component";
import {IGsResponse} from "../../../core/models/gsResponse.types";
import {InfoService} from "../../../core/services/info.service";

export enum StoreState {
  STORE,
  PAYMENT
}

export interface IStoreStateData {
  state:StoreState,
  item?:IStoreItem
}

@Injectable()
export class StoreService {
  storeState$ = new BehaviorSubject<IStoreStateData>({state: StoreState.STORE});
  materialDialogOpenOutOfResourceRef: any;
  itemSectionType?: SectionType;
  destroyRef = inject(DestroyRef);
  purchaseFromOrigin?: StoreEventOrigin;

  constructor(
    private requestService: RequestService,
    private ngModalsService: NgModalsService,
    private scrollService: ScrollService,
    private bankrollService: BankrollService,
    private storeEventsService: StoreEventsService,
    private infoService: InfoService,
    @Inject('brazeService') private brazeService: any
  ) { }

  getStore(): Promise<IGetStore> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_STORE.NAME,
      // getMockData: true,
      // mockDataState: 1
    });
    return firstValueFrom(this.requestService.request<IGetStore>(conf));
  }

  getStoreItem(storeItemId:number): Promise<IGetStoreItem> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_PACKAGE.NAME,
      body: {
        package_id:storeItemId
      }
    });
    return firstValueFrom(this.requestService.request<IGetStoreItem>(conf));
  }

  payStoreItem(storeItemId:number): Promise<IGsResponse> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.PAY_STORE_ITEM.NAME,
      body:{
        store_item_id: storeItemId
      },
    });
    return firstValueFrom(this.requestService.request<IGsResponse>(conf));
  }

  getSuggestedStoreItems(params: ISuggestedStoreItems): Observable<ISuggestedStoreItemsResponse> {
    const conf:RequestServiceModel = new RequestServiceModel({
      endPoint: NgRequestEnum.END_POINTS.GET_SUGGESTED_STORE_ITEMS.NAME,
      body: params
    });
    return this.requestService.request<ISuggestedStoreItemsResponse>(conf);
  }

  openOutOfResourcesModal(amount_missing:number): void {
    let paramsGetSuggestedStoreItems: ISuggestedStoreItems = {
      type: ResourceType.COINS,
      amount_missing: amount_missing
    };
    this.getSuggestedStoreItems(paramsGetSuggestedStoreItems).pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((getSuggestedStoreItemsResponse: ISuggestedStoreItemsResponse) => {
      this.materialDialogOpenOutOfResourceRef = this.ngModalsService.materialDialogOpenOutOfCoins({
        items: getSuggestedStoreItemsResponse.suggested_store_items,
        resourceType: ResourceType.COINS,
        sectionType: SectionType.BATTLES,
      });
      this.materialDialogOpenOutOfResourceRef.afterClosed().pipe(
        takeUntilDestroyed(this.destroyRef)
      ).subscribe(async (closeModalType: CloseModalType | OutOfResourceCloseModalType): Promise<void> => {
        if (closeModalType === OutOfResourceCloseModalType.MORE_OPTIONS) {
          this.storeEventsService.sendOutOfCoinsExitClientEvent(mixpanelEventsEnum.UI_NAME.MORE_OPTIONS)
          this.ngModalsService.materialDialogOpenStore(ResourceType.COINS);
        } else if (closeModalType === CloseModalType.X_BUTTON){
          this.storeEventsService.sendOutOfCoinsExitClientEvent(mixpanelEventsEnum.UI_NAME.X_BUTTON)
        }
      });
    });
  }

  completePurchase(storeItem: IStoreItem, sectionType: SectionType): void {
    let data:IStoreCompletePurchaseModalEventData = {
      storeItem,
      current_balance: this.bankrollService.getBankrollItemAmount(
        sectionType,
        storeItem.received[0].type),
      origin: this.purchaseFromOrigin || StoreEventOrigin.STORE,
      ui_action: StoreCompletePurchaseUiAction.PLAY
    }
    const modalRef = this.ngModalsService.materialDialogOpenCompletePurchase(storeItem);
    modalRef.afterOpened().pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe(() => {
      data.ui_action = StoreCompletePurchaseUiAction.APPEAR;
      this.storeEventsService.storeCompletePurchaseModalEvent(data);
    });
    modalRef.afterClosed()
      .pipe(
      takeUntilDestroyed(this.destroyRef)
    ).subscribe((resolvedValue?: string) => {
      data.ui_action = resolvedValue === 'redirect' ?
        StoreCompletePurchaseUiAction.PLAY : StoreCompletePurchaseUiAction.BACK_TO_STORE
      this.storeEventsService.storeCompletePurchaseModalEvent(data);
      if(resolvedValue === 'redirect') {
        this.ngModalsService.closeAll();
        // this.stateService.goToState({state: 'gs.challenges.myChallenges.joined'});
        // switch (sectionType) {
        //   case SectionType.CHALLENGES:
        //     this.ngModalsService.closeAll();
        //     this.stateService.goToState({state: 'gs.challenges.myChallenges.joined'});
        //     break;
        //   case SectionType.BATTLES:
        //     this.ngModalsService.closeAll();
        //     this.stateService.goToState({state: 'gs.battles.notJoined'});
        //     break;
        //   default:
        //     // this.stateService.goToState({state: 'gs.challenges.myChallenges.joined'});
        //     break;
        // }
      }
    });
  }

  storeItemSelected(storeItem: IStoreItem, sectionType: SectionType): void {
    if (this.isCoin(storeItem.cost_currency)) {
      this.ngModalsService.materialDialogOpenConfirmPurchase({storeItem, sectionType})
        .afterClosed().subscribe((res:CloseModalType) => {
        if(res === CloseModalType.BUY_WITH_COINS){
          this.buyCoins({storeItem, sectionType});
        }
      });
    } else if (this.isMoney(storeItem.cost_currency)) {
      this.setPurchaseActive(storeItem, StoreEventOrigin.STORE);
    }

    const data:IStorePackageSelectEventData = {
      storeItem,
      current_balance: this.bankrollService.getBankrollItemAmount(
        sectionType,
        storeItem.received[0].type),
      origin:StoreEventOrigin.STORE
    }
    this.storeEventsService.storePackageSelectEvent(data);
  }

  async buyCoins(data: {storeItem: IStoreItem, sectionType: SectionType}): Promise<void> {
    let numOfCoinsAfterPurchase = this.bankrollService.getBankrollItemAmountCoins() - data.storeItem.cost_amount;
    if (numOfCoinsAfterPurchase >= 0) {
      console.log('normal flow numOfCoinsAfterPurchase', numOfCoinsAfterPurchase);
      const res = await this.payStoreItem(data.storeItem.id);
      if(res.success) {
        const resourcesToUpdate:IUpdateBankrollItemAmountParams[] = data.storeItem.received.map((r)=> {
          return {resourceType: r.type, amountToAddOrReduce: r.amount}
        })
        this.bankrollService.updateBankrollItemAmount([
          {resourceType: ResourceType.COINS,
          amountToAddOrReduce: -data.storeItem.cost_amount},
          ...resourcesToUpdate
        ]);
        this.completePurchase(data.storeItem, data.sectionType);
      } else {
        console.log('error, cant buy coins numOfCoinsAfterPurchase',numOfCoinsAfterPurchase);
      }
    } else {
      const coinsStoreEventParams = {outOfResourceId: 'coins_store' + Date.now()};
      this.brazeService.logCustomEvent('coins_store', coinsStoreEventParams);
      await this.brazeService.waitForBrazeMessage(this.goToCoinsSection.bind(this), coinsStoreEventParams.outOfResourceId);
      // let paramsGetSuggestedStoreItems: ISuggestedStoreItems = {
      //   type: ResourceType.COINS,
      //   amount_missing: Math.abs(numOfCoinsAfterPurchase)
      // };
      // this.getSuggestedStoreItems(paramsGetSuggestedStoreItems)
      //   .subscribe((getSuggestedStoreItemsResponse: ISuggestedStoreItemsResponse) => {
      //   console.log('getSuggestedStoreItemsResponse ', getSuggestedStoreItemsResponse);
      //   this.ngModalsService.materialDialogOpenOutOfCoins({
      //     items: getSuggestedStoreItemsResponse.suggested_store_items,
      //     sectionType: data.sectionType,
      //     amountMissing: paramsGetSuggestedStoreItems.amount_missing
      //   }).afterClosed().subscribe(async (option: string): Promise<void> => {
      //     if (option === 'more_options') {
      //       await this.goToDeepLink(ResourceType.COINS);
      //     }
      //   });
      // })
    }
  }

  async goToCoinsSection(): Promise<void> {
    await this.goToDeepLink(ResourceType.COINS);
    this.infoService.showErrorMessage({
      message:'Not enough coins',
      panelClass:'white-snackbar'
    });
  }

  isCoin(currencyType: StoreCurrencyType){
    return currencyType === StoreCurrencyType.COINS;
  }

  isMoney(currencyType:StoreCurrencyType){
    return currencyType === StoreCurrencyType.USD;
  }

  async goToDeepLink(deepLink: SectionType | ResourceType) {
    const e = document.getElementById(deepLink);
    if(e){
      this.scrollService.scrollIntoView(e, 'start');
      //need to wait for scroll to finish
      await PromiseHelper.wait(1000);
      return true
    }
    return false;
  }

  setStoreActive(): void {
    this.storeState$.next({state:StoreState.STORE});
  }

  setPurchaseActive(storeItem:IStoreItem, purchaseFromOrigin: StoreEventOrigin): void {
    this.purchaseFromOrigin = purchaseFromOrigin;
    this.storeState$.next({state:StoreState.PAYMENT, item:storeItem});
  }
}
