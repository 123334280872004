import challengeEnum from '../api/enums/challenge.enum';
import {filter} from "rxjs";

const dlStates =
  {
    // 'gs.dl.vote': {
    //   dlState: 'gs.dl.vote',
    //   redirectState: 'gs.challenge',
    //   type: 'vote'
    // },
    // 'gs.dl.profileUpload': {
    //   dlState: 'gs.dl.profileUpload',
    //   redirectState: 'gs.profile.photos',
    //   type: 'profileUpload'
    // },
    // 'gs.dl.suggestedMembers': {
    //   dlState: 'gs.dl.suggestedMembers',
    //   redirectState: 'gs.teams.home.myteam',
    //   type: 'suggestedMembers'
    // },
    // '/dl/exhibition': {
    //   dlState: 'dl/exhibition',
    //   redirectState: 'challenges/my-challenges/current',
    //   type: 'exhibition'
    // },
    // 'gs.dl.fill': {
    //   dlState: 'gs.dl.fill',
    //   redirectState: 'challenges/my-challenges/current',
    //   type: 'fill'
    // },
    // 'gs.dl.boost': {
    //   dlState: 'gs.dl.boost',
    //   redirectState: 'challenges/my-challenges/current',
    //   type: 'boost'
    // },
    // 'gs.dl.swap': {
    //   dlState: 'gs.dl.swap',
    //   redirectState: 'challenges/my-challenges/current',
    //   type: 'swap'
    // },
    // 'gs.dl.lb': {
    //   dlState: 'gs.dl.lb',
    //   redirectState: 'challenges/my-challenges/current',
    //   type: 'lb'
    // },
  };

class deepLinkService {

  constructor($cookies, $rootScope, gsModals, Modals, ChallengesManager, $timeout, challengeService, MemberService) {
    'ngInject';
    this.$cookies = $cookies;
    this.$rootScope = $rootScope;
    this.gsModals = gsModals;
    this.Modals = Modals;
    this.ChallengesManager = ChallengesManager;
    this.$timeout = $timeout;
    this.challengeService = challengeService;
    MemberService.member$.pipe(
      filter(member => member !== undefined),
    ).subscribe(member => {
      this.member = member;
    });
  }

  getDl() {
    return this.$cookies.getObject('dl');
  }

  reset(){
    this.$cookies.remove('dl');
  }

  getParam(paramName) {
    const dl = this.getDl();
    if (dl && dl.params && dl.params[paramName]) {
      return dl.params[paramName];
    }
    return null;
  }

  check(data) {
    let dl = this.getDl();
    if (!dl || !dl.type) {
      return;
    }

    switch (dl.type) {
      case 'vote':
        if (this.member?.logged_in && data.challenge.status === challengeEnum.STATUSES.ACTIVE.VALUE) {
          this.gsModals.open('vote', {challenge: data.challenge});
        }
        break;
      case 'profileUpload':
        this.Modals.open('gsUploader', null, {
          target: 'profile',
          member: this.member,
        });
        break;
      case 'fill':
        if (this.challengeService.isFillEmpty()) {
          this.challengeService.openStoreFillsSection();
          return;
        }
        const obj = {
          availableFills: this.challengeService.getFillAmount(),
          challengeIds: [data.challenge.id],
        };
        this.Modals.open('autoFill', null, obj);
        break;
      case 'swap':
        // open shop
        if (this.challengeService.isSwapsEmpty()) {
          this.challengeService.openStoreSwapsSection();
        } else {
          // swap
          const params = {
            target: 'swap',
            challenge: data.challenge
          };
          if(data.challenge.isOnePhoto()){
            params.currentImage = data.challenge.member.ranking.entries[0];
          }
          this.Modals.open('gsUploader', null, params);
        }
        break;
      case 'boost':
        if(data.challenge.boost_enable) {
          switch (data.challenge.member.boost.state) {
            case challengeEnum.BOOST.AVAILABLE:
              this.Modals.open('challengeActionActivate', event, {challenge: data.challenge});
              break;
            case challengeEnum.BOOST.LOCKED:
            case challengeEnum.BOOST.MISSED:
              if (this.challengeService.isKeysEmpty()) {
                this.challengeService.openStoreKeysSection();
              }else {
                this.Modals.open('challengeActionLocked', null, {challenge: data.challenge});
              }
              break;
          }
        }
        break;
      case 'exhibition':
        if (dl.params.cId && data.challenge) {
          if (!!data.challenge.member.exhibition &&
              data.challenge.member.exhibition.isMemberPaid() &&
              data.challenge.status === challengeEnum.STATUSES.ACTIVE.VALUE &&
              data.challenge.type === challengeEnum.TYPE.EXHIBITION
          ) {
            this.Modals.open('challengeIntro', null, {
              challenge: data.challenge,
            });
            break;
          }
        }
        break;
      case 'lb':
        this.$rootScope.$emit('openLb');
        break;
      case 'suggestedMembers':
        this.$timeout(()=> {
          $(data.selectorParent).scrollTo($(data.selector), 200);
        },200);
        break;
    }
    this.reset();
  }

}
deepLinkService.$inject = [
  '$cookies', '$rootScope', 'gsModals', 'Modals', 'ChallengesManager', '$timeout', 'challengeService', 'MemberService'
];
export default deepLinkService;
