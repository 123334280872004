import MainCtrl from "../gsApp.ctrl";

class animationService {

  constructor(
    $timeout,
  ) {
    'ngInject';
    this.$timeout = $timeout;
  }

  destroyAll(){
    window.bodymovin.destroy();
  }

  destroyCurrentAnimation(){
    this.animation.destroy();
  }

  startAnim ({animData, path, elementName, destroy=true, loop=false}){
    return new Promise(resolve => {
      this.$timeout(() => {
        let anim = {
          container: document.querySelector(elementName),
          renderer: 'svg',
          loop: loop,
          autoplay: true,
        };
        if(path){
          anim.path = path;
        }
        if(animData){
          anim.animationData = animData;
        }
        this.animation = window.bodymovin.loadAnimation(anim);
        this.animation.addEventListener(
          'complete',
          event => {
            resolve();
            if(destroy){
              this.animation.destroy();
            }
          }
        );
        this.animation.addEventListener(
          'destroy',
          event => {
            resolve();
          }
        );
      });
    });
  }

}
animationService.$inject = [
  '$timeout',
];
export default animationService;
