const template = require(`html-loader!./queryBuilder.html`).default;
const block = require(`html-loader!./queryBuilder.block.html`).default;
import { elasticSearchDecode, elasticSearchEncode } from '../../../helpers/google.helper';
import { conditions } from './queryBuilder.data';
import PhotosManager from '../../../api/managers/PhotosManager';
import * as _ from 'lodash';

class queryBuilderCtrl {
  /** @param {PhotosManager} PhotosManager **/

  constructor($mdDialog, PhotosManager, $scope) {
    'ngInject';
    this.$ctrl = this;
    this.query = '';
    this.queryObj = [];
    this.block = block;
    this.conditions = conditions;

    this.$mdDialog = $mdDialog;
    this.PhotosManager = PhotosManager;
    this.$scope = $scope;
    this.getImages = _.debounce(this.getImages.bind(this), 500);
  }

  $onInit() {
    this.query = this.data.query.length > 4 ? this.data.query : '(())';
    this.parseQuery();
    this.queryObj = elasticSearchDecode(this.query);
    console.log("this.queryObj", this.queryObj)
    this.getImages();
  }

  /** @return void **/
  async getImages() {
    if (this.query.length < 4 || this.query === '(())') return;
    this.lastItemQuery = this.getLastItemQuery();
    let reqArr = [
      this.PhotosManager.globalSuggest({
        query: this.query,
        start: 0,
        limit: 36,
      }),
    ];
    if(this.lastItemQuery){
      reqArr.push(this.PhotosManager.globalSuggest({
        query: this.lastItemQuery,
        start: 0,
        limit: 36,
      }));
    }
    this.suggests = await Promise.all(reqArr);

    console.log(this.suggests);
    const success = this.suggests.every(suggest => suggest.success);
    if (!success) {
      console.log('not success');
      return;
    }
    if(this.suggests[1]){
      this.suggests[1].title = this.lastItemQuery;
    }
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  changed() {
    this.suggests = [];
    this.lastItemQuery = null;
    this.query = elasticSearchEncode(this.queryObj);
    this.getImages();
    console.log('this.queryObj',this.queryObj);
  }

  getLastItemQuery(){
    const group = this.queryObj[0].value;
    if(group.length <= 1){
      return null;
    }
    const lastGroupItem = group[group.length-1];
    if(lastGroupItem.type === "text"){
      return lastGroupItem.value;
    }else{
      return lastGroupItem.value[lastGroupItem.value.length-1].value;
    }
  }

  parseQuery() {
    // this.query = elasticSearchEncode(this.queryObj);
    if(this.query.slice(0,3) === '((('){
      this.query = this.query.replace('(((','((');
      this.query = this.query.replace('))',')');
      console.log(this.query);
    }
  }

  save() {
    if (this.query.length < 5) this.query = '';
    this.data.callback(this.query);
    this.close();
  }

  close() {
    this.$mdDialog.hide();
  }

  addGroup(group) {
    group.value.push({
      type: 'condition',
      value: 'AND',
    });
    group.value.push({
      type: 'group',
      value: [
        {
          type: 'text',
          value: '',
        },
      ],
    });
  }

  removeGroup(group, parentGroup) {
    this.removeItem(parentGroup, _.findIndex(parentGroup.value, group));
    if (!parentGroup.value.length) {
      this.addItem(parentGroup, 0);
    }
  }

  addItem(group, index) {
    if (typeof index === 'undefined') {
      group.value.push({
        type: 'text',
        value: '',
      });
      this.changed();
      return;
    }
    group.value.splice(index + 1, 0, {
      type: 'condition',
      value: 'OR',
    });
    group.value.splice(index + 2, 0, {
      type: 'text',
      value: '',
    });
  }

  removeItem(group, index) {
    if (index !== 0) {
      group.value.splice(index, 1);
      group.value.splice(index - 1, 1);
      this.changed();
    }
  }
}
queryBuilderCtrl.$inject = [
  '$mdDialog', 'PhotosManager', '$scope'
];
export default {
  template: template,
  controller: queryBuilderCtrl,
  bindings: {
    data: '=data',
  },
};
