export const deactivateDeleteAccountConf = {
  controller: () => {},
  controllerAs: 'modal',
  bindToController: true,
  template: '<modal-deactivate-delete-account data="modal.data"></modal-deactivate-delete-account>',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: true,
  fullscreen: false
};
