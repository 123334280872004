import { Injectable } from '@angular/core';
import CookieService from './cookie.service';
import {CookieKeyEnum} from "../enum/cookies.enum";
import {BehaviorSubject} from "rxjs";

@Injectable()
class NgTokenService {
  private _adminToken?: string;
  public memberToken$ = new BehaviorSubject<string | undefined>(undefined);

  constructor(private cookieService: CookieService) {}

  initMemberToken(){
    this.memberToken$.next(this.cookieService.getCookie(CookieKeyEnum.TOKEN));
  }

  get memberToken():string | undefined {
    return this.memberToken$.value;
  }

  set memberToken(token:string) {
    this.cookieService.setCookie(CookieKeyEnum.TOKEN, token);
    this.memberToken$.next(token);
  }

  initAdminToken(){
    this._adminToken =  this.cookieService.getCookie(CookieKeyEnum.ADMIN_TOKEN);
  }

  set adminToken(token:string) {
    this.cookieService.setCookie(CookieKeyEnum.ADMIN_TOKEN, token);
    this._adminToken = token;
  }

  get adminToken():string {
    return this._adminToken!;
  }

  resetAdminToken(){
    this.cookieService.removeCookie(CookieKeyEnum.ADMIN_TOKEN);
    this._adminToken = undefined;
  }

  resetMemberToken(){
    this.cookieService.removeCookie(CookieKeyEnum.TOKEN);
    this.memberToken$.next(undefined);
  }

  resetAllTokens(){
    this.resetMemberToken();
    this.resetAdminToken();
  }
}

export default NgTokenService;
