<div
  (click)="mute()"
  class="gs-theme-color-white_100 mute-unmute-button"
>
  <i
    *ngIf="soundService.muted; else muted"
    class="icon-muted"
  ></i>
</div>

<ng-template #muted>
  <i class="icon-mute"></i>
</ng-template>
