import {EnvironmentDomainsModel} from './environment.domains.model';
import {EnvironmentStaticFacebookModel} from './environment.static.facebook.model';
import {EnvironmentStaticCaptchaModel} from './environment.static.captcha.model';
import {EnvironmentStaticBrazeModel} from './environment.static.braze.model';
import {EnvironmentStaticMixpanelModel} from './environment.static.mixpanel.model';

export class EnvironmentModel {
  env: string = '';
  production =  false;

  facebookAppId: string = new EnvironmentStaticFacebookModel().get('prod');
  captchaKey: string = new EnvironmentStaticCaptchaModel().get('prod');
  mixpanelToken: string = new EnvironmentStaticMixpanelModel().getToken('prod');
  mixpanelHost: string = new EnvironmentStaticMixpanelModel().getHost('prod');
  brazeAppId: EnvironmentStaticBrazeModel = new EnvironmentStaticBrazeModel();

  domains: EnvironmentDomainsModel;

  constructor(config:any) {
    this.domains = new EnvironmentDomainsModel(config.domains);

    if (config.env) {
      this.env = config.env;
    }

    if (config.production) {
      this.production = config.production;
    }
  }
}
