const template = require(`html-loader!./gsJustifiedGallery.html`).default;

import {adultReportType} from "../../pages/moderation/moderationPage.data";

class gsGaleryCtrl {
  constructor(
    $scope,
    $rootScope,
    $timeout,
    ExhibitionsManager,
    Modals,
    RestManager
  ) {
    'ngInject';

    this.imgHeight = 500;
    this.busy = false;
    this.inited = false;

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.ExhibitionsManager = ExhibitionsManager;
    this.Modals = Modals;
    this.RestManager = RestManager;
    this.adultReportType = adultReportType;
  }

  $onInit() {
  }

  $onChanges() {
    this.onChanges();
  }

  async onChanges() {
    if (!this.images || !this.images.length) {
      this.inited = false;
      return;
    }

    await this.$timeout();
    if (!this.inited) {
      this.inited = true;
      $('.gs-justified-gallery__grid')
        .justifiedGallery({
          rowHeight: 300,
          maxRowHeight: 300,
          lastRow: 'nojustify',
          captions: false,
          margins: 5,
          waitThumbnailsLoad: false,
          selector: 'div',
          cssAnimation: false,
          imagesAnimationDuration: 0,
          refreshSensitivity:300
        })
        .off('jg.complete').on('jg.complete', this.onJgComplete.bind(this));
    } else {
      $('.gs-justified-gallery__grid').justifiedGallery('norewind');
    }
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  onJgComplete() {
    console.log('onJgComplete');
    if(this.onComplete){
      this.onComplete();
    }
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  async selectExpo(image, type) {
    if(!this.busy) {
      if (image[type]){
        const confirm = await this.Modals.confirm(
          'Remove this photo from selection?',
          'it will not appear in the exhibition.',
          'Remove',
          'Cancel'
        );
        if (!confirm) return;
      }
      this.sendExhibitionSelection(image, type);
      if(type === 'print'){
        image.digital = 0;
      }else{
        image.print = 0;
      }
      image[type] =  image[type] === 1 ? 0 : 1;

      let resImages = [];
      if(image[type]){
        resImages.push({
          print:image.print,
          digital:image.digital,
          id:image.id,
        });
      }
      this.busy = true;
      const res = await this.ExhibitionsManager.setExhibitionWinners(
        {
          challenge_id: this.challengeId,
          member_id: image.member.id,
          images_data: resImages,
        }
      );
      if (!res.success) {
        console.log("error setExhibitionPhoto", res);
      }
      this.busy = false;
    }
  }

  sendExhibitionSelection(image, type){
    let counter = {print:0,digital:0};
    counter[type] += image[type] ? -1 : 1;
    const type2 = type === "print" ? "digital" : "print";
    if (image[type2]){
      counter[type2] = -1;
    }
    this.$rootScope.$emit('onExhibitionSelection', counter);
  }
}

gsGaleryCtrl.$inject = [
  '$scope',
  '$rootScope',
  '$timeout',
  'ExhibitionsManager',
  'Modals',
  'RestManager'
];

export default {
  template: template,
  controller: gsGaleryCtrl,
  bindings: {
    images: '<',
    challengeId: '<',
    loadMore: '&',
    onComplete: '&',
    type:'@',
    moderationTitle:'<',
  }
};
