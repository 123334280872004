import {Injectable} from '@angular/core';
import {MediatorService} from "./mediator.service";
import {ChallengeGroup} from "../../modules/challenges/enums/challenges.enum";
import {RouterService} from "./router.service";
export interface IState{
  state?:string
  params?:any
  reload?:boolean
}

@Injectable()
export class StateService {

  static DEFAULT_STATE = 'challenges/my-challenges/current';
  constructor(
    private mediatorService: MediatorService,
    private routerService: RouterService
  ) {
    this.mediatorService.gotToState.subscribe(
    (route)=> {
      if (route?.includes('deactivated')) {// hook for deactivated member
        if (route?.includes('contact')) {
          return;
        }
        if (!document.body.classList.contains("restricted")) {
          document.body.classList.add("restricted");
        }
      }
      // @ts-ignore
      if (route) {
        this.goToState(route)
      }
    });
  }

  goToState(route: any, queryParams={}){
    void this.routerService.router.navigate([route], {queryParams});
  }

  goToDefaultState({params}: {params:{}} = {params:{}}){
    this.goToState(StateService.DEFAULT_STATE, params);
  }

  getCurrentState():string {
    return this.routerService.currentUrl;
  }

  //TODO ROUTER - refactor
  isStateByChallengeGroup(challengeGroup:ChallengeGroup){
    let isState = false;
    const currentUrl = this.routerService.currentUrl;
    switch (challengeGroup){
      case ChallengeGroup.OPEN:
        isState =  currentUrl === '/challenges/open'
        break;
      case ChallengeGroup.UPCOMING:
        isState =  currentUrl === '/challenges/upcoming'
        break;
      case ChallengeGroup.CLOSED:
        isState =  currentUrl === '/challenges/closed'
        break;
      case ChallengeGroup.MY_CLOSED:
        isState =  currentUrl === '/challenges/completed'
        break;
    }
    return isState;
  }
}
