import { Component } from '@angular/core';
import {Subject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";

@Component({
  selector: 'app-admin-teams-manager-wrapper',
  templateUrl: './admin-teams-manager-wrapper.component.html',
  styleUrl: './admin-teams-manager-wrapper.component.scss'
})
export class AdminTeamsManagerWrapperComponent {
  isParams = false;
  teamId = '';
  subject = new Subject();

  constructor(
    private route: ActivatedRoute
  ) {
    this.route.params.pipe(
      takeUntilDestroyed()
    ).subscribe(async (params: any) => {
      this.isParams = true;
      this.teamId = params.teamId;
    });
  }
}
