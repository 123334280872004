export abstract class NgDateHelper {
  public static millisecondsInSecond = 1000;
  public static secondsInMinute = 60;
  public static minutesInHour = 60;
  public static hoursInDay = 24;
  public static daysInWeek = 7;

  public static getWeeksByDelta(delta: number) {
    return Math.floor(delta / (NgDateHelper.millisecondsInSecond * NgDateHelper.secondsInMinute * NgDateHelper.minutesInHour * NgDateHelper.hoursInDay * NgDateHelper.daysInWeek));
  }

  /**
   * Convert a timestamp delta to amount of days.
   * @param {number} delta - difference between two dates.
   * @returns {number} - amount of days.
   */
  public static getDaysByDelta(delta: number) {
    return Math.floor(delta / (NgDateHelper.millisecondsInSecond * NgDateHelper.secondsInMinute * NgDateHelper.minutesInHour * NgDateHelper.hoursInDay));
  }

  /**
   * Convert a timestamp delta to amount of hours.
   * @param {number} delta - difference between two dates.
   * @returns {number} - amount of hours. From 0 to 23.
   */
  public static getHoursByDelta(delta: number) {
    return Math.floor((delta % (NgDateHelper.millisecondsInSecond * NgDateHelper.secondsInMinute * NgDateHelper.minutesInHour * NgDateHelper.hoursInDay)) /
      (NgDateHelper.millisecondsInSecond * NgDateHelper.secondsInMinute * NgDateHelper.minutesInHour));
  }

  /**
   * Convert a timestamp delta to amount of minutes.
   * @param {number} delta - difference between two dates.
   * @returns {number} - amount of minutes. From 0 to 59.
   */
  public static getMinutesByDelta(delta: number) {
    return Math.floor((delta % (NgDateHelper.millisecondsInSecond * NgDateHelper.secondsInMinute * NgDateHelper.minutesInHour)) / (NgDateHelper.millisecondsInSecond * NgDateHelper.secondsInMinute));
  }

  /**
   * Convert a timestamp delta to amount of seconds.
   * @param {number} delta - difference between two dates.
   * @returns {number} - amount of seconds. From 0 to 59.
   */
  public static getSecondsByDelta(delta: number) {
    return Math.round((delta % (NgDateHelper.millisecondsInSecond * NgDateHelper.secondsInMinute)) / NgDateHelper.millisecondsInSecond);
  }

  public static parseFromMilisecToSec(milliseconds:number) {
    return milliseconds ? Math.round(milliseconds / this.millisecondsInSecond) : milliseconds;
  }

  public static parseFromSecToMilisec(sec:number) {
    return sec ? (sec * this.millisecondsInSecond) : sec;
  }

  public static addSeconds(date:Date, secondsToAdd:number){
    if(date && secondsToAdd) {
      date.setSeconds(date.getSeconds() + secondsToAdd);
    }
    return date;
  }

  public static getEndTimeDeltaInMilisec(endDateMilliseconds: number): number {
    return endDateMilliseconds - new Date().getTime();
  }

  public static isTimeHasPassed(timeInSeconds: number): boolean {
    return this.parseFromSecToMilisec(timeInSeconds) < (new Date()).getTime();
  }

  public static addLeadZero(value: number): string | number {
    return value < 10 ? `0${value}` : value;
  }
}
