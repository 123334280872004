import { Component } from '@angular/core';

@Component({
  selector: 'app-contact-wrapper',
  templateUrl: './contact-wrapper.component.html',
  styleUrl: './contact-wrapper.component.scss'
})
export class ContactWrapperComponent {

}
