const template = require(`html-loader!./teamSuggestedMembers.html`).default;

import {teamConfirmTypes, teamConfirmTypesData} from "../../../../services/Modals/teamsConfirm/teamsConfirm.data";
import InviteMemberModel from '../../../../api/models/team/inviteMember.model';

class teamSuggestedMembersCtrl {

  constructor($scope, $rootScope, RestManager, teamsService, Modals, Facebook, stateService) {
    'ngInject';
    this.loadMore = true;
    this.busy = false;
    this.start = 0;
    this.limit = 20;
    this.scrollClass = '.team-suggested-members__list';
    this.facebookCbType = 'FACEBOOK_FRIENDS';
    this.countryCbType = 'COUNTRY';
    this.filtersOptions = [];
    this.selectedFilters = {};
    this.suggestedMembers = [];
    this.filtersMode = false;
    this.invitedMembersMode = false;
    this.isInitialized = false;

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.RestManager = RestManager;
    this.teamsService = teamsService;
    this.Modals = Modals;
    this.Facebook = Facebook;
    this.stateService = stateService;
  }

  $onInit() {
    this.init();
  }

  async init(){
    this.selectedFilters = this.teamsService.getMemberSuggestFiltersCookie().filters || {};
    this.lastSelectedFilters = angular.copy(this.selectedFilters);
    this.onScrollFuc = this.onScroll.bind(this);
    this.showSuggestedMembers();
    this.getTeamSuggestedMembersOptions();
    this.getFbIsConnected();
  }

  $onDestroy(){
    $(this.scrollClass).off('scroll', this.onScrollFuc);
  }

  /** @return void **/
  async getFbIsConnected(){
    const res = await this.RestManager.getFbIsConnected();
    this.isFbConnected = res.success && res.is_connected;
  }

  /** @return void **/
  async getTeamSuggestedMembersOptions(){
    const res = await this.RestManager.getTeamSuggestedMembersOptions();
    if(!res.success){
      return;
    }
    this.filtersOptions = res.options;
  }

  /** @return void **/
  async getTeamSuggestedMembers() {
    if(!this.busy) {
      this.busy = true;
      const res = await this.RestManager.getTeamSuggestedMembers({
        option_types: Object.keys(this.selectedFilters).filter((key) => this.selectedFilters[key]),
        // option_types: Object.keys(this.selectedFilters),
        start: this.start,
        limit: this.limit
      });
      this.isInitialized = true;
      if (!res.success) {
        return;
      }
      this.data = res.suggested_members.map((member) => {
        let suggestedMember = {};
        suggestedMember.invited_member = new InviteMemberModel(member);

        return suggestedMember;
      });

      if (this.start === 0) {
        this.suggestedMembers = this.data;
      } else {
        this.suggestedMembers = [...this.suggestedMembers, ...this.data];
      }
      this.loadMore = this.data.length === this.limit;
      this.start += this.limit;
      this.busy = false;

      // console.log('loadMore', this.loadMore);
      // console.log('getTeamSuggestedMembers', this.suggestedMembers);
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  /** @return void **/
  async getTeamInvitedMembers() {
    if(!this.busy) {
      this.busy = true;
      const res = await this.RestManager.getTeamInvitedMembers();
      this.busy = false;
      this.isInitialized = true;
      if (!res.success) {
        return;
      }
      this.suggestedMembers = res.members.map((member) => {
        member.inviting_member = new InviteMemberModel(member.inviting_member);
        member.invited_member = new InviteMemberModel(member.invited_member);
        return member;
      });
      console.log('getTeamInvitedMembers', this.suggestedMembers);
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    }
  }

  showInvitedMembers() {
    this.reset();
    this.invitedMembersMode = true;
    this.getTeamInvitedMembers();
    $(this.scrollClass).off('scroll', this.onScrollFuc);
  }

  async showSuggestedMembers() {
    this.reset();
    this.invitedMembersMode = false;
    await this.getTeamSuggestedMembers();
    $(this.scrollClass).on('scroll', this.onScrollFuc);
  }

  reset() {
    this.suggestedMembers = [];
    this.isInitialized = false;
    this.start = 0;
  }

  onRemoveSuggestedMember(memberId) {
    console.log('memberid', memberId);
    const index = this.suggestedMembers.findIndex(suggestedMember => suggestedMember.invited_member.id === memberId);
    this.suggestedMembers.splice(index, 1);
    if(!this.suggestedMembers.length){
      this.showSuggestedMembers();
      return;
    }
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
    console.log('suggestedMembers.length', this.suggestedMembers.length);
  }

  onScroll(e) {
    const newScrollLeft = $(e.target).scrollLeft();
    const width = $(e.target).innerWidth();
    const scrollWidth = $(e.target).get(0).scrollWidth;
    const offset = 300;
    if (scrollWidth - newScrollLeft - width <= offset) {
      if(this.suggestedMembers.length && this.loadMore){
        console.log('get more items');
        this.getTeamSuggestedMembers();
      }
    }
  }

  isClearFilterDisabled(){
    return !this.isSelectedFilters(this.selectedFilters);
  }

  isApplyFilterDisabled(){
    return !this.isSelectedFilters(this.selectedFilters) && !this.isSelectedFilters(this.lastSelectedFilters);
  }

  isSelectedFilters(filters){
    const values = Object.values(filters);
    return values.length && values.some( value => value);
  }

  clearFilters(){
    this.selectedFilters = {};
    this.applyFilters();
  }

  applyFilters(){
    this.lastSelectedFilters = angular.copy(this.selectedFilters);
    this.teamsService.setMemberSuggestFiltersCookie(this.selectedFilters);
    this.closeFilters();
    this.showSuggestedMembers();
  }

  closeFilters(setOrigFilters = false){
    this.filtersMode = false;
    if(setOrigFilters){
      this.selectedFilters = angular.copy(this.lastSelectedFilters);
    }
  }

  async openFacebookConnect(){
    const isConfirmed = await this.Modals.open('teamsConfirm', null, {teamConfirmType : teamConfirmTypes.FACEBOOK_CONNECT});
    if(isConfirmed){
      this.Facebook.link();
    }
  }

  async openNoCountryModal(){
    const isConfirmed = await this.Modals.open('teamsConfirm', null, {teamConfirmType : teamConfirmTypes.NO_COUNTRY});
    if(isConfirmed){
      this.stateService.goToState('teams/edit', {teamId: this.team.id});
    }
  }
}
teamSuggestedMembersCtrl.$inject = [
  '$scope', '$rootScope', 'RestManager', 'teamsService', 'Modals', 'Facebook', 'stateService'
];

export default {
  template: template,
  controller: teamSuggestedMembersCtrl,
  bindings: {
    team: '<'
  }
};
