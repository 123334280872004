<div class="ng-adult-filter__wrapper">
  <div class="ng-adult-filter__default"
       *ngIf="mode === AdultFilterMode.DEFAULT">
    <i class="icon-adult"></i>
    <div class="ng-adult-filter__title gs-theme-text-regular-small">Adult Content</div>
    <div class="ng-adult-filter__btn gs-theme-text-bold-small gs-center-flex"
         (click)="show()">Show</div>
    <div class="ng-adult-filter__checkbox gs-center-flex gs-g-5 gs-c-p"
         (click)="disableAdult()">
      <div class="ng-adult-filter__checkbox__box"></div>
      <div class="ng-adult-filter__checkbox__title gs-theme-text-regular-small">Always show</div>
    </div>
  </div>

  <div class="ng-adult-filter__small gs-center-v-flex"
       *ngIf="mode === AdultFilterMode.SMALL">
    <i class="icon-adult"></i>
    <div class="ng-adult-filter__title gs-theme-text-regular-small">Adult Content</div>
  </div>
</div>

