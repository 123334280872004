import {filter, Subject, takeUntil} from "rxjs";

const template = require(`html-loader!./achievementsLevel.html`).default;
import mixpanelEventsEnum from "../../../../../services/mixpanel/mixpanel.enum";

class achievementsLevelCtrl {

  constructor(achievementsService, mixpanelService, RouterService) {
    'ngInject';
    this.achievement = null;
    this.subject = new Subject();
    this.mixpanelService = mixpanelService;
    this.achievementsService = achievementsService;
    this.RouterService = RouterService;

    this.RouterService.activatedRoute
      .queryParams.pipe(
      filter(params => params.achievementUniqueKey),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      this.achievement.showAchievementsDetails = this.achievement.unique_key === params.achievementUniqueKey;
    });
  }

  showDetails(){
    if(this.achievement.showAchievementsDetails){
      this.achievement.showAchievementsDetails = false;
    } else {
      this.achievementsService.hideAllAchievementsDetails();
      this.achievement.showAchievementsDetails = true;
      this.eventMixPanelAction();
    }
  }

  eventMixPanelAction() {
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.ACHIEVEMENT_LEVELS,
      data: {
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
        ui_name: mixpanelEventsEnum.UI_NAME.ACHIEVEMENT_EXPAND_BUTTON,
        achievement_name: this.achievement.name
      }
    });
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
achievementsLevelCtrl.$inject = [
  'achievementsService', 'mixpanelService', 'RouterService'
];
export default {
  template: template,
  controller: achievementsLevelCtrl,
  bindings: {
    achievement: '<',
  }
};
