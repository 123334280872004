import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {INgMemberModel} from "../../../core/models/fromOldApp/models";
import {StateService} from "../../../core/services/state.service";
import leagueEnum from "../../../gsApp/app/api/enums/league.enum";
import memberEnum from "../../../gsApp/app/api/enums/member.enum";
import TeamMemberModel from "../../../gsApp/app/api/models/team/teamMember.model";
import {MediatorMessageKey, MediatorService} from "../../../core/services/mediator.service";

@Component({
  selector: 'app-ng-avatar',
  templateUrl: './ng-avatar.component.html',
  styleUrls: ['./ng-avatar.component.scss']
})
export class NgAvatarComponent implements OnChanges {
  @Input() member?: INgMemberModel;
  @Input() clickable = false;
  @Input() size = '';
  @Input() lazy = false;
  @Input() newTab = false;
  @Input() goToAdminMemberManager = false;
  @Input() showLeagueBadge = false;

  isImageError = false;
  avatar = '';
  showBadge = false;
  leagueConf = {
    COLORS: {
      AVATAR: {
        BORDER: ''
      }
    }
  };
  loaded = false;

  constructor(
    private stateService: StateService,
    private mediatorService: MediatorService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['member'].currentValue?.id) {
      this.isImageError = false;
      const newSize = this.size || '50x50';
      this.avatar = this.member?.avatar ? this.member.avatar.replace('500x500', newSize) : memberEnum.AVATAR.PLACEHOLDER;
      this.showBadge = this.showLeagueBadge && !!this.member?.member_league_id;
      if (this.showBadge && this.member?.member_league_id) {
        // @ts-ignore
        this.leagueConf = leagueEnum.ID_LEAGUE[this.member.member_league_id]();
      }
    }
  }

  get isClickEnabled() {
    return this.clickable && this.member?.user_name;
  }

  get isTeamAdmin() {
    // @ts-ignore
    return this.member && TeamMemberModel.prototype.isPrototypeOf(this.member) ? this.member.isAdmin() ?? false : false;
  }

  onImageLoaded(){
    this.loaded = true;
  }

  onImageError(){
    this.loaded = true;
    this.avatar = memberEnum.AVATAR.PLACEHOLDER;
  }

  onClick() {
    const state = this.goToAdminMemberManager ? 'admin/memberManager' : `${this.member?.user_name}/photos`;
    //@TODO router goToStateNewTab not exist
    const stateMethod = this.newTab ? 'goToStateNewTab' : 'goToState';
    const params = this.goToAdminMemberManager ? {cUserName: this.member?.user_name} : {}
    if (this.member?.user_name) {
      // @ts-ignore
      this.stateService[stateMethod](
        state,
        params
      );
      this.mediatorService.broadcast(MediatorMessageKey.CLOSE_MODAL);
    }
  }
}
