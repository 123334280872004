const ChatMemberColorsConst = [
  '#d88477',
  '#f35460',
  '#488054',
  '#63bc7c',
  '#6c7e56',
  '#b86b87',
  '#9fae26',
  '#c4599e',
  '#be965e',
  '#566990',
  '#7d7556',
  '#b4a740',
  '#5e9086',
  '#996d43',
  '#f2b84e',
  '#a44a1d',
  '#94c0f2',
  '#a698c1',
  '#5e68b7',
  '#818398',
  '#da564b',
  '#ff8d62',
  '#af9675',
  '#ef8423',
  '#1daac4',
  '#396dc4',
  '#aebe67',
  '#009688',
  '#33caaa',
  '#69a039',

];

export default ChatMemberColorsConst;
