import {INgImageModel, INgTeamsRankDataModel} from "./models";
import {NgImageModel} from "./ng-image.model";

export class NgTeamsRankDataModel implements INgTeamsRankDataModel {
  rank_data?: {
    entries: any;
    total: any;
  };

  constructor(props: INgTeamsRankDataModel = {}) {
    Object.assign(this, props);

    props.rank_data!.entries && (this.rank_data!.entries = props.rank_data!.entries.map((entry: INgImageModel): INgImageModel => new NgImageModel({id: entry.id, member_id: props.member_id})));
  }
}
