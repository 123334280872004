const template = require(`html-loader!./gsComments.html`).default;

import memberEnum from '../../api/enums/member.enum';

class gsComments {
  $onDestroy() {
    this.newCommentText = null;
  }

  /** @param {CommentsManager} CommentsManager **/
  constructor(
    CommentsManager,
    $timeout,
    Modals,
    gsCommentsService,
    ResponseService,
    $rootScope,
    MemberService,
  ) {
    'ngInject';

    this.showLines = 3;
    this.linesStep = 20;
    this.items = [];
    this.replyFull = {};

    this.CommentsManager = CommentsManager;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.gsCommentsService = gsCommentsService;
    this.ResponseService = ResponseService;
    this.$rootScope = $rootScope;
    this.MemberService = MemberService;

    this.newCommentText = null;
    this.member = this.MemberService.getCurrentMember();
  }

  $onChanges() {
    this.newCommentText = this.gsCommentsService.getDraft();

    if (!this.image) {
      return;
    }

    // Reset form
    this.replyOnComment = undefined;
    this.items = [];
    this.showLines = 3;

    // Add items
    this.items = this.image.comments;
  }

  showPrev() {
    this.showLines += this.linesStep;
  }

  edit(item) {
    item.edit = true;
    item.newText = item.text;
  }

  newComment(item) {
    const oe_id = item ? this.replyOnComment.id : this.image.event_id;
    const text = item ? this.newCommentOnCommentText : this.newCommentText;

    this.newCommentText = '';
    this.newCommentOnCommentText = '';
    this.replyOnComment = undefined;

    if (!text || !text.length) {
      return false;
    }

    const data = {
      el: 'challenges',
      el_id: true,
      oe_id: oe_id,
      re_id: this.image.event_id,
      om_id: this.image.member_id,
      text: text
    };

    this.CommentsManager.commentCreate(data).then(res => {
      if (!res.success) {
        if (res.error_code === 1000) {
          this.Modals.open('login', null, {signUp: true});
        } else if (this.ResponseService.isResponseError(res)) {
          this.ResponseService.responseHandler(res);

          return false;
        }

        return false;
      }

      // Attach member
      res.comment.member = this.member;

      if (!item) {
        this.items.push(res.comment);
        return;
      }

      const index = this.items.indexOf(item);

      if (!this.items[index].replies) {
        this.items[index].replies = [];
      }

      this.items[index].replies.push(res.comment);
    });

    this.gsCommentsService.eraseDraft();
  }

  updateComment(item) {
    item.edit = false;

    const data = {
      id: item.id,
      text: item.newText
    };

    this.CommentsManager.commentUpdate(data).then(res => {
      if (!res.success) {
        return false;
      }

      item.text = item.newText;
    });
  }

  reply(item) {
    this.replyOnComment = item;
    this.replyFull[item.id] = true;
    this.$timeout(() => {
      $('.gs-comments__item-reply--new:not(.closed)')
        .find('textarea')
        .trigger('focus');
    });
  }

  deleteComment(item, reply) {
    let id;
    const index = this.items.indexOf(item);

    if (reply) {
      id = reply.id;
      this.items[index].replies.splice(item.replies.indexOf(reply), 1);
    } else {
      id = item.id;
      this.items.splice(index, 1);
    }

    this.CommentsManager.commentDelete(id).then(res => {
      /*console.log(res);*/
    });
  }

  actionAllowed(action, comment) {
    if (!comment.member) {
      return false;
    }
    switch (action) {
      case 'edit':
        // Admin or owner of comment
        return this.member.is_admin || this.member.id === comment.member.id;
      case 'delete':
        // Admin or owner of comment or owner of image
        return (
          this.member.is_admin ||
          this.member.id === comment.member.id ||
          this.member.id === this.image.member_id
        );
      case 'reply':
        // Anyone if logged in
        return this.member.logged_in;
      default:
        return false;
    }
  }

  isCommentAllowed() {
    return this.member.member_status >= memberEnum.STATUS.ROOKIE.VALUE;
  }
}

gsComments.$inject = [
  'CommentsManager',
  '$timeout',
  'Modals',
  'gsCommentsService',
  'ResponseService',
  '$rootScope',
  'MemberService',
];

export default {
  template: template,
  controller: gsComments,
  bindings: {
    image: '<'
  }
};
