import { Component } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-boarding-page-wrapper',
  templateUrl: './boarding-page-wrapper.component.html',
  styleUrl: './boarding-page-wrapper.component.scss'
})
export class BoardingPageWrapperComponent {
  cId = null;
  slide = null;

  constructor(
    private route: ActivatedRoute
  ) {
    this.route
      .params.pipe(
    ).subscribe(async (params: any) => {
      this.cId = params.cId;
      this.slide = params.slide;
    });
  }
}
