import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, TextOnlySnackBar} from "@angular/material/snack-bar";
import {Injectable} from "@angular/core";

interface ISnackBarOpenProps{
  message: string,
  action?: string,
  config?: MatSnackBarConfig
}

@Injectable()
export class NgSnackBarService {

  constructor(private snackBar: MatSnackBar) { }

  open(data:ISnackBarOpenProps):MatSnackBarRef<TextOnlySnackBar>{
    return this.snackBar.open(data.message, data.action, data.config);
  }
}
