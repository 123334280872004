import { Component } from '@angular/core';

@Component({
  selector: 'app-teams-home-match-wrapper',
  templateUrl: './teams-home-match-wrapper.component.html',
  styleUrl: './teams-home-match-wrapper.component.scss'
})
export class TeamsHomeMatchWrapperComponent {

}
