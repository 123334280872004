import { Component } from '@angular/core';

@Component({
  selector: 'app-exhibitions-wrapper',
  templateUrl: './exhibitions-wrapper.component.html',
  styleUrl: './exhibitions-wrapper.component.scss'
})
export class ExhibitionsWrapperComponent {

}
