import {WinnersLayout} from "../../../../../modules/challenges/enums/challenges.enum";
const template = require(`html-loader!./winners.all.html`).default;
import ImageModel from '../../../api/models/image/image.model';
import challengeEnum from '../../../api/enums/challenge.enum';
import MemberModel from "../../../api/models/member.model";
import {AdultFilterMode} from "../../../../../shared/components/ng-adult-filter/ng-adult-filter.component";
import {NgChallengeModel} from "../../../../../modules/challenges/models/ng-challenge.model";
import {Subject, takeUntil, filter} from "rxjs";

class challengeWinnersAllCtrl {

  constructor(
    $scope,
    $timeout,
    $location,
    $rootScope,
    NgChallengesService,
    tagEventService,
    MemberService,
    RouterService
  ) {
    'ngInject';
    this.busy = true;
    this.subject = new Subject();
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.NgChallengesService = NgChallengesService;
    this.tagEventService = tagEventService;
    this.winnersLayout = WinnersLayout;
    this.AdultFilterMode = AdultFilterMode;
    this.MemberService = MemberService;
    this.RouterService = RouterService;

    this.NgChallengesService.currentChallenge$.pipe(
      filter(challenge => challenge !== undefined),
      takeUntil(this.subject)
    ).subscribe(async (challenge) => {
      this.challenge = challenge;

      // challenge not closed
      if (this.challenge.status !== challengeEnum.STATUSES.CLOSED.VALUE) {
        this.RouterService.go('challenge/details');
      }

      this.c_id = this.challenge.id;
      this.init();
    });

    this.loggedinMember = this.MemberService.getCurrentMember();
  }

  $onInit() {
    // Google vPageView
    this.tagEventService.sendPageViewEvent({title: 'Challenge Winners All'});
  }

  async init() {
    const res = await this.NgChallengesService.getWinnersAchievementsAndPrizes(this.c_id);
    this.busy = false;

    if (!res.success) {
      this.noData = true;
      return;
    }

    res.winners.forEach(winner => {
      winner.items = winner.items.map(item => {
        return {
          ...item,
          activeImageIndex: 0,
          member: new MemberModel(item.member),
          images: item.images.map(image => new ImageModel(image))
        };
      })
    });

    this.data = res.winners;

    this.busy = false;

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  setMainImg(item, index) {
    item.activeImageIndex = index;
  }

  isAdultFilterEnabled(image){
    return !this.loggedinMember?.adult && image.adult;
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
challengeWinnersAllCtrl.$inject = [
  '$scope',
  '$timeout',
  '$location',
  '$rootScope',
  'NgChallengesService',
  'tagEventService',
  'MemberService',
  'RouterService'
];
export default {
  template: template,
  controller: challengeWinnersAllCtrl
};
