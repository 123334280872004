import broadcastCtrl from './broadcast.ctrl';
//Templates
const broadcast_1_tpl = require(`html-loader!./tpl/broadcast.type.1.tpl.html`).default;
const broadcast_2_tpl = require(`html-loader!./tpl/broadcast.type.2.tpl.html`).default;
const broadcast_3_tpl = require(`html-loader!./tpl/broadcast.type.3.tpl.html`).default;
const broadcast_6_tpl = require(`html-loader!./tpl/broadcast.type.6.tpl.html`).default;
const broadcast_7_tpl = require(`html-loader!./tpl/broadcast.type.7.tpl.html`).default;
const broadcast_9_tpl = require(`html-loader!./tpl/broadcast.type.9.tpl.html`).default;
const broadcast_10_tpl = require(`html-loader!./tpl/broadcast.type.10.tpl.html`).default;
const broadcast_11_tpl = require(`html-loader!./tpl/broadcast.type.11.tpl.html`).default;
const broadcast_12_tpl = require(`html-loader!./tpl/broadcast.type.12.tpl.html`).default;
const broadcast_13_tpl = require(`html-loader!./tpl/broadcast.type.13.tpl.html`).default;
const broadcast_14_tpl = require(`html-loader!./tpl/broadcast.type.14.tpl.html`).default;
const broadcast_15_tpl = require(`html-loader!./tpl/broadcast.type.15.tpl.html`).default;
const broadcast_16_tpl = require(`html-loader!./tpl/broadcast.type.16.tpl.html`).default;
const broadcast_17_tpl = require(`html-loader!./tpl/broadcast.type.17.tpl.html`).default;
const broadcast_18_tpl = require(`html-loader!./tpl/broadcast.type.18.tpl.html`).default;
const broadcast_19_tpl = require(`html-loader!./tpl/broadcast.type.19.tpl.html`).default;
const broadcast_20_tpl = require(`html-loader!./tpl/broadcast.type.20.tpl.html`).default;
const broadcast_21_tpl = require(`html-loader!./tpl/broadcast.type.21.tpl.html`).default;
const broadcast_22_tpl = require(`html-loader!./tpl/broadcast.type.22.tpl.html`).default;
const broadcast_23_tpl = require(`html-loader!./tpl/broadcast.type.23.tpl.html`).default;
const broadcast_24_tpl = require(`html-loader!./tpl/broadcast.type.24.tpl.html`).default;
const broadcast_25_tpl = require(`html-loader!./tpl/broadcast.type.25.tpl.html`).default;
const broadcast_26_tpl = require(`html-loader!./tpl/broadcast.type.26.tpl.html`).default;
const broadcast_27_tpl = require(`html-loader!./tpl/broadcast.type.27.tpl.html`).default;
const broadcast_28_tpl = require(`html-loader!./tpl/broadcast.type.28.tpl.html`).default;
const broadcast_29_tpl = require(`html-loader!./tpl/broadcast.type.29.tpl.html`).default;
const broadcast_30_tpl = require(`html-loader!./tpl/broadcast.type.30.tpl.html`).default;


export const broadcastConf = {
  controller: broadcastCtrl,
  controllerAs: 'broadcast',
  template: '[see this.templates]',
  autoWrap: false,
  targetEvent: '[set event from params]',
  clickOutsideToClose: false,
  fullscreen: false,
  templates: {
    1: broadcast_1_tpl,
    2: broadcast_2_tpl,
    3: broadcast_3_tpl,
    6: broadcast_6_tpl,
    7: broadcast_7_tpl,
    9: broadcast_9_tpl,
    10: broadcast_10_tpl,
    11: broadcast_11_tpl,
    12: broadcast_12_tpl,
    13: broadcast_13_tpl,
    14: broadcast_14_tpl,
    15: broadcast_15_tpl,
    16: broadcast_16_tpl,
    17: broadcast_17_tpl,
    18: broadcast_18_tpl,
    19: broadcast_19_tpl,
    20: broadcast_20_tpl,
    21: broadcast_21_tpl,
    22: broadcast_22_tpl,
    23: broadcast_23_tpl,
    24: broadcast_24_tpl,
    25: broadcast_25_tpl,
    26: broadcast_26_tpl,
    27: broadcast_27_tpl,
    28: broadcast_28_tpl,
    29: broadcast_29_tpl,
    30: broadcast_30_tpl,
  }
};
