const template = require(`html-loader!./facebookFriends.html`).default;

class facebookFriendsCtrl {
  /**
   * @param {FacebookManager} FacebookManager
   * @param {Facebook} Facebook
   * **/
  constructor(
    $scope,
    Facebook,
    FacebookManager,
    notificationAlert
  ) {
    'ngInject';

    this.$ctrl = this;
    this.friendsList = [];
    this.facebookConnected = false;
    this.open = false;
    this.ready = false;

    this.$scope = $scope;
    this.Facebook = Facebook;
    this.FacebookManager = FacebookManager;
    this.notificationAlert = notificationAlert;
  }

  $onInit() {
    this.getFriends();
  }

  /** @return void **/
  async getFriends() {
    const res = await this.FacebookManager.facebookFriends();
    this.ready = true;
    if (!res.success) {
      this.facebookConnected = false;
      if (!this.$scope.$$phase) this.$scope.$digest();
      return;
    }
    this.facebookConnected = true;
    this.friendsList = res.friends_list;
    if (!this.$scope.$$phase) this.$scope.$digest();
  }

  facebookFriendsClick(event) {
    if (!this.ready) return;
    // not connected
    if (!this.facebookConnected) {
      this.Facebook.link();
      return;
    }
    this.open = !this.open;
  }

  linkFacebook() {
    this.Facebook.link();
  }

  info() {
    this.notificationAlert
      .open(`Connect with Facebook to see all your Facebook friends who are participating in GuruShots Challenges.
            <a href="https://gurushots.freshdesk.com/solution/articles/13000011621-facebook-friends-"
               target="_blank"
            >Read More</a>`);
  }
}

facebookFriendsCtrl.$inject = [
  '$scope',
  'Facebook',
  'FacebookManager',
  'notificationAlert'
];

export default {
  template: template,
  controller: facebookFriendsCtrl,
};
