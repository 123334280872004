<div class="challenges-header">
  <div class="filters gs-d-f gs-g-8 ">
    <div *ngFor="let filter of fastFilters"
         [ngClass]="{selected: filter.selected}"
         (click)="onFilterClick(filter)"
         class="filter gs-center-flex">
        {{filter.type}}
    </div>
<!--    <div *ngIf="isFilterEmpty"-->
<!--         class="no-filters gs-center-x gs-theme-text-bold gs-theme-color-gray_90 gs-center-v-flex gs-g-8">-->
<!--      <img width="24" height="24" ngSrc="/assets/images/fastFilters/empty-filter.svg" alt="empty filter icon">-->
<!--      <div>No Filter Selected</div>-->
<!--    </div>-->
  </div>
  <app-sort-order
          class="gs-center-v"
          [config]="sortOrderConfig!"
          (onOrderChange)="onActiveSortOrderChange($event)"
          (onSortChange)="onActiveSortOrderChange($event)"
  ></app-sort-order>
</div>

