<div class="gs-d-f payment__wrapper">
  <div class="aside gs-center-v-flex">
    <div class="m-desktop gs-center-v-flex">
      <div class="gs-theme-text-headline gs-theme-color-white_100">
        Buy {{(storeItem  && storeItem.received[0].amount) ? storeItem!.received[0].amount : 1 }} {{storeItem!.received[0].title}}
      </div>
      <div class="price gs-m-t-12 gs-p-8 gs-theme-text-headline gs-theme-color-white_100">
        For <span>{{storeItem!.cost_amount | currency: storeItem!.cost_currency}}</span>
      </div>
      <div class="item-icon gs-m-t-48 gs-center-v-flex">
        <img [src]="storeItem!.icon.svg_url">
      </div>
    </div>
    <div class="m-mobile">
      <div class="gs-d-f gs-a-i-c gs-g-8">
        <div class="back-button__store gs-theme-text-regular-small gs-theme-color-gray_80 gs-c-p"
             (click)="backToStore()"><i class="icon icon-left-arrow gs-v-a-m"></i> <span class="gs-m-l-5 gs-v-a-m">back to store</span></div>
        <div class="store-item-icon">
          <img [src]="storeItem!.icon.svg_url">
        </div>
        <div>
          <div class="gs-theme-text-headline gs-theme-color-gray_80">
            Buy {{storeItem ? storeItem!.received[0].amount : 0 }} {{storeItem!.received[0].title}}
          </div>
          <div class="gs-theme-text-caption-bold gs-theme-color-gray_80">
            For <span>{{storeItem!.cost_amount | currency: storeItem!.cost_currency}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="payment gs-d-f gs-f-d-c gs-p-r">
    <div [ngClass]="{'gs-d-n' : busy}">
      <div class="back-button__store gs-c-p"
           (click)="backToStore()">
        <i class="icon icon-left-arrow gs-v-a-m gs-theme-color-gray_50"></i>
        <span class="gs-theme-text-regular-small gs-theme-color-gray_60 gs-m-l-5 gs-v-a-m">
          Back to store</span>
      </div>
      <div class="header gs-d-f gs-f-d-r gs-a-i-c gs-j-c-s-b gs-m-b-24">
        <div>
          <div class="payment__title gs-theme-color-gray_70">Payment</div>
          <div class="subtitle gs-theme-color-gray_60"
               *ngIf="isUnknownPayment">Please fill out your information </div>
        </div>
        <div [ngClass]="{'gs-d-n' : state === PaymentState.PAYPAL}" class="paypal gs-center-v-flex gs-g-10">
          <div class="gs-theme-text-caption-regular gs-theme-color-gray_60">
            or checkout with paypal
          </div>
          <app-paypal-button [storeItem]="storeItem" selector="gs-paypal-button"></app-paypal-button>
        </div>
      </div>
      <hr>
      <div class="main" [ngSwitch]="state">
        <div *ngSwitchCase="PaymentState.NEW_CARD" class="form">
          <form [formGroup]="pf" id="payment-form"
                (ngSubmit)="onSubmit()">
            <!--        <div>debug {{pfFirstNameC?.errors | json}}</div>-->
            <div class="gs-d-f gs-g-24">
              <div class="form-group gs-m-t-2-0">
                <label for="first-name"
                       class="gs-d-b gs-theme-color-gray_70">First name</label>
                <input [ngClass]="{
            'error m-red-border': pfFirstNameC?.invalid && (pfFirstNameC?.touched || pfFirstNameC?.dirty),
            'input-filled': pfFirstNameC?.valid
            }"
                       formControlName="firstName"
                       required
                       id="first-name"
                       type="text"
                       class="gs-w-100-p"
                       placeholder="For ex. Nick">
                <div class="error m-message-block gs-theme-bg-color-light_red"
                     *ngIf="(pfFirstNameC?.touched || pfFirstNameC?.dirty) && pfFirstNameC?.invalid && pfFirstNameC?.errors?.['required']">Please enter First name!</div>
                <div class="error m-message-block gs-theme-bg-color-light_red"
                     *ngIf="(pfFirstNameC?.touched || pfFirstNameC?.dirty) && pfFirstNameC?.invalid && pfFirstNameC?.errors?.['minlength']">The min. length is {{pfFirstNameValidationLength}}, you typed {{pfFirstNameC?.errors?.['minlength']?.['actualLength']}}</div>
              </div>
              <div class="form-group gs-m-t-2-0">
                <label for="last-name"
                       class="gs-d-b gs-theme-color-gray_70">Last name</label>
                <input [ngClass]="{
            'error m-red-border': pfLastNameC?.invalid && (pfLastNameC?.touched || pfLastNameC?.dirty),
            'input-filled': pfLastNameC?.valid
            }"
                       formControlName="lastName"
                       required
                       id="last-name"
                       type="text"
                       class="gs-w-100-p"
                       placeholder="For ex. Jonson">
                <div class="error m-message-block gs-theme-bg-color-light_red"
                     *ngIf="(pfLastNameC?.touched || pfLastNameC?.dirty) && pfLastNameC?.invalid && pfLastNameC?.errors?.['required']">Please enter Last name!</div>
                <div class="error m-message-block gs-theme-bg-color-light_red"
                     *ngIf="(pfLastNameC?.touched || pfLastNameC?.dirty) && pfLastNameC?.invalid && pfLastNameC?.errors?.['minlength']">The min. length is {{pfFirstNameValidationLength}}, you typed {{pfLastNameC?.errors?.['minlength']?.['actualLength']}}</div>
              </div>
            </div>
            <div class="form-group gs-m-t-2-0">
              <label for="email"
                     class="gs-d-b gs-theme-color-gray_70">Email</label>
              <input [ngClass]="{
            'error m-red-border': pfEmailC?.invalid && (pfEmailC?.touched || pfEmailC?.dirty),
            'input-filled': pfEmailC?.valid
            }"
                     formControlName="email"
                     required
                     id="email"
                     type="email"
                     class="gs-w-100-p"
                     placeholder="account@gurushots.com">
              <div class="error m-message-block gs-theme-bg-color-light_red"
                   *ngIf="(pfEmailC?.touched || pfEmailC?.dirty) && pfEmailC?.invalid && pfEmailC?.errors?.['email']">Email isn't valid!</div>
              <div class="error m-message-block gs-theme-bg-color-light_red"
                   *ngIf="(pfEmailC?.touched || pfEmailC?.dirty) && pfEmailC?.invalid && pfEmailC?.errors?.['required']">Please enter the email address!</div>
            </div>
            <div formGroupName="card">
              <div class="form-group gs-m-t-2-0">
                <label for="card"
                       class="gs-d-b gs-theme-color-gray_70">Card number</label>
                <input [ngClass]="{
            'error m-red-border': pfCardNumberC?.invalid && (pfCardNumberC?.touched || pfCardNumberC?.dirty),
            'input-filled': pfCardNumberC?.valid
            }"
                       formControlName="number"
                       [maxlength]="pfCardNumberMaxValidationLength"
                       data-bluesnap="encryptedCreditCard"
                       id="card"
                       type="text"
                       class="gs-w-100-p"
                       placeholder="1111 1111 1111 1111">
                <div class="error m-message-block gs-theme-bg-color-light_red"
                     *ngIf="(pfCardNumberC?.touched || pfCardNumberC?.dirty) && pfCardNumberC?.invalid && pfCardNumberC?.errors?.['minlength']">The min. length is {{pfCardNumberMinValidationLength}}, you typed {{pfCardNumberC?.errors?.['minlength']?.['actualLength']}}</div>
                <div class="error m-message-block gs-theme-bg-color-light_red"
                     *ngIf="(pfCardNumberC?.touched || pfCardNumberC?.dirty) && pfCardNumberC?.invalid && pfCardNumberC?.errors?.['pattern'] && !pfCardNumberC?.errors?.['minlength']">Numbers only!</div>
                <div class="error m-message-block gs-theme-bg-color-light_red"
                     *ngIf="(pfCardNumberC?.touched || pfCardNumberC?.dirty) && pfCardNumberC?.invalid && pfCardNumberC?.errors?.['required']">Please enter the card number!</div>
              </div>
              <div class="gs-d-f gs-g-24">
                <div class="form-group gs-m-t-2-0">
                  <label for="expire-date"
                         class="gs-d-b gs-theme-color-gray_70">Expire date</label>
                  <input [ngClass]="{
            'error m-red-border': pfCardExpiredC?.invalid && (pfCardExpiredC?.touched || pfCardExpiredC?.dirty),
            'input-filled': pfCardExpiredC?.valid
            }"
                         [maxlength]="5"
                         formControlName="expired"
                         required
                         id="expire-date"
                         type="text"
                         placeholder="MM/DD"
                         class="gs-w-100-p">
                  <div class="error m-message-block gs-theme-bg-color-light_red"
                       *ngIf="(pfCardExpiredC?.touched || pfCardExpiredC?.dirty) && pfCardExpiredC?.invalid && pfCardExpiredC?.errors?.['pattern']">Required format is MM/YY</div>
                  <div class="error m-message-block gs-theme-bg-color-light_red"
                       *ngIf="(pfCardExpiredC?.touched || pfCardExpiredC?.dirty) && pfCardExpiredC?.invalid && pfCardExpiredC?.errors?.['required']">Please enter exp. date!</div>
                </div>
                <div class="form-group gs-m-t-2-0">
                  <label for="cvv"
                         class="gs-d-b gs-theme-color-gray_70">CVV</label>
                  <input [ngClass]="{
            'error m-red-border': pfCardCvvC?.invalid && (pfCardCvvC?.touched || pfCardCvvC?.dirty),
            'input-filled': pfCardCvvC?.valid
            }"
                         [maxlength]="pfCardCvvCValidationLength"
                         formControlName="cvv"
                         required
                         data-bluesnap="encryptedCvv"
                         id="cvv"
                         type="text"
                         class="gs-w-100-p">
                  <div class="error m-message-block gs-theme-bg-color-light_red"
                       *ngIf="(pfCardCvvC?.touched || pfCardCvvC?.dirty) && pfCardCvvC?.invalid && pfCardCvvC?.errors?.['minlength']">The min. length is {{pfCardCvvCValidationLength}}, you typed {{pfCardCvvC?.errors?.['minlength']?.['actualLength']}}</div>
                  <div class="error m-message-block gs-theme-bg-color-light_red"
                       *ngIf="(pfCardCvvC?.touched || pfCardCvvC?.dirty) && pfCardCvvC?.invalid && pfCardCvvC?.errors?.['required']">Please enter CVV!</div>
                  <div class="error m-message-block gs-theme-bg-color-light_red"
                       *ngIf="(pfCardCvvC?.touched || pfCardCvvC?.dirty) && pfCardCvvC?.invalid && pfCardCvvC?.errors?.['pattern'] && !pfCardCvvC?.errors?.['minlength']">Numbers only!</div>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div *ngSwitchCase="PaymentState.SAVED_CARD"
             class="saved-card">
          <app-saved-card [last4numbers]="paymentConfig?.config?.payment_card?.last_4"
                          [cardType]="paymentConfig?.config?.payment_card?.type"></app-saved-card>
        </div>
        <div class="gs-d-f gs-j-c-c gs-w-100-p gs-h-100-p"
             *ngSwitchCase="PaymentState.PAYPAL">
          <div class="main__paypal gs-center-v-flex gs-g-20">
            <div class="gs-theme-text-bold gs-theme-color-gray_60">Continue with Paypal</div>
            <app-paypal-button class="main__paypal__button"
                               [storeItem]="storeItem"
                               selector="gs-paypal-button1"></app-paypal-button>
          </div>
        </div>
      </div>
      <hr>
      <div class="actions" [ngSwitch]="state">
        <div *ngSwitchDefault class="gs-m-t-24">
          <div class="gs-d-f gs-g-24">
            <button *ngIf="state === PaymentState.NEW_CARD && paymentService.isSavedCardAvailable()"
                    (click)="state = PaymentState.SAVED_CARD"
                    class="back-button__saved-card gs-w-100-p gs-theme-text-caps-regular gs-theme-color-blue_solid">Back to saved card</button>
            <button [disabled]="isDisabled()"
                    (click)="onSubmit()"
                    class="continue-purchase gs-w-100-p gs-theme-text-bold gs-theme-bg-color-cool_60 gs-theme-color-white_100">Confirm Purchase</button>
          </div>
          <div *ngIf="error"
               class="system-error gs-t-a-c gs-m-t-8 gs-p-8-0 gs-theme-bg-color-light_red gs-theme-text-regular-small">
            {{error}}
          </div>
        </div>
        <div *ngSwitchCase="PaymentState.PAYPAL"
             class="gs-w-100-p gs-center-flex">
          <div class="use-other-card gs-center-v-flex gs-g-8">
            <div class="gs-theme-text-caption-regular gs-theme-color-gray_60">Or checkout with your credit card</div>
            <div (click)="onUseYourCreditCard()"
                 class="use-other-card__btn gs-theme-gradient-blue
            gs-center-flex gs-theme-text-caps-bold gs-theme-color-white_100 clickable">
              Use your credit card
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="gs-center" *ngIf="busy">
      <mat-spinner></mat-spinner>
    </div>
  </div>

</div>
