const template = require(`html-loader!./finalizingResult.html`).default;

class finalizingResulteCtrl {
  constructor() {}
}

export default {
  template: template,
  controller: finalizingResulteCtrl,
  bindings: {}
};
