// This Angular directive will act as an interface to the "upgraded" AngularJS component
import {Directive, ElementRef, Injector, Input} from "@angular/core";
import {UpgradeComponent} from "@angular/upgrade/static";

@Directive({selector: 'ng1-badge-v2'})
export class Ng1BadgeV2ComponentWrapper extends UpgradeComponent {
  // The names of the input and output properties here must match the names of the
  // `<` and `&` bindings in the AngularJS component that is being wrapped
  @Input() badgeName: string | undefined;
  @Input() mode: string | undefined;
  @Input() previewSizeClass: string | undefined;
  @Input() maxPhotos: number | undefined;
  @Input() exhibitionName: string | undefined;

  constructor(
    elementRef: ElementRef,
    injector: Injector
  ) {
    super('badgeV2', elementRef, injector);
  }
}
