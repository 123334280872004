const template = require(`html-loader!./mediaYoutube.html`).default;

class mediaYoutubeController {
  constructor(

  ) {
    'ngInject';
  }

  $onInit() {
    const loopString = `&playlist=${this.src}&loop=1`;
    this.youtubeLink = `https://www.youtube.com/embed/${this.src}?rel=0&autoplay=${this.autoplay === 'true' ? '1' : '0'}&mute=${this.mute === 'true' ? '1' : '0'}&controls=${this.controls === 'true' ? '1' : '0'}${this.loop === 'true' ? loopString : ''}`;
  }
}

mediaYoutubeController.$inject = [

];

export default {
  template: template,
  controller: mediaYoutubeController,
  bindings: {
    'src': '<',
    'autoplay': '@',
    'mute': '@',
    'controls': '@',
    'loop': '@',
  }
};
