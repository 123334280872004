const template = require(`html-loader!./shareButton.html`).default;

class shareButtonCtrl {
  constructor(
    $scope,
    $rootScope,
    Toasts,
    $element,
    $timeout
  ) {
    'ngInject';

    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.Toasts = Toasts;
    this.$element = $element;
    this.$timeout = $timeout;
  }

  $onInit() {}

  copyDone() {
    // show toast
    this.Toasts.open('copy', {}, this.$element[0]);
  }

  getImage(event) {
    console.log(event);
    if(!this.busy){
      this.busy = true;
      let btn = $(event.currentTarget).find('.gs-round-loader');
      btn.addClass('run');
      let img = new Image();
      img.src = this.screenshotUrl;
      // this.$timeout(()=>{
      //   this.imageLoaded = true;
      //   if (!this.$scope.$$phase) {
      //     this.$scope.$digest();
      //   }
      // }, 2000);
      $(img).on('load', ()=>{
        this.imageLoaded = true;
        if (!this.$scope.$$phase) {
          this.$scope.$digest();
        }
      });

    }
  }
}

shareButtonCtrl.$inject = [
  '$scope',
  '$rootScope',
  'Toasts',
  '$element',
  '$timeout'
];

export default {
  template: template,
  controller: shareButtonCtrl,
  bindings: {
    link: '<',
    screenshotUrl : '<'
  }
};
