class CommentsManager {
  /** @param {api} api */
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  commentDelete(id) {
    return this.api['rest']['commentDelete']({id});
  }

  commentCreate({el, el_id, oe_id, re_id, om_id, text}) {
    return this.api['rest']['commentCreate']({el, el_id, oe_id, re_id, om_id, text});
  }

  commentUpdate({id, text}) {
    return this.api['rest']['commentUpdate']({id, text});
  }
}

CommentsManager.$inject = [
  'api'
];

export default CommentsManager;
