import {MediatorMessageKey} from "../../../../../core/services/mediator.service";

class reportCtrl {
  /**
   * @param {ModalsService} Modals
   * @param {ChallengesManager} ChallengesManager
   * */

  constructor(
    $mdDialog,
    data,
    $timeout,
    Modals,
    ChallengesManager,
    $scope,
    $rootScope,
    MediatorService
  ) {
    'ngInject';
    this.$mdDialog = $mdDialog;
    this.ChallengesManager = ChallengesManager;
    this.$timeout = $timeout;
    this.Modals = Modals;
    this.data = data;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.mediatorService = MediatorService;
  }

  $onInit() {
    this.imageUrl = this.data.image.token ?
      `${this.$rootScope.photoDomain}/unsafe/220x0/${this.data.image.token}`
      :
      `${this.$rootScope.photoDomain}/unsafe/220x0/${this.data.image.member_id}/3_${this.data.image.id}.jpg`;

  }

  hide() {
    this.$mdDialog.hide();
  }

  async save(reason_id, isAiImage = false) {
    const confirm = await this.Modals.confirm(
      'Report photo?',
      `Repeated false reporting is against the rules, if the report is reviewed and found to be malicious you
       may loose your rank in the challenge.`,
      'Report',
      'Cancel'
    );
    if (!confirm) {
      return;
    }

    if (reason_id === 5) {
      this.Modals.open('reportSimilar', null, { member_id: this.data.image.member_id, c_id: this.data.c_id });
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      return;
    }

    let reqData = {
      token: this.data.image.token,
      id: this.data.image.id,
      reason_id: reason_id,
      c_id: this.data.c_id,
    };

    const res = await this.ChallengesManager.reportImage(reqData);
    if (!res.success) {
      this.hide();
      if (res.error_code === 1000) {
        this.Modals.open('login', null, { signUp: true });
      }
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      return false;
    }

    if (isAiImage) {
      this.mediatorService.broadcast(MediatorMessageKey.AI_REPORT, this.data.image.token);
    }

    this.done = true;

    this.$timeout(() => {
      this.hide();
    }, 1000);

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
reportCtrl.$inject = [
  '$mdDialog', 'data', '$timeout', 'Modals', 'ChallengesManager', '$scope', '$rootScope', 'MediatorService'
];
export default reportCtrl;
