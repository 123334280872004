import { Component } from '@angular/core';

@Component({
  selector: 'app-empty-wrapper',
  templateUrl: './empty-wrapper.component.html',
  styleUrl: './empty-wrapper.component.scss'
})
export class EmptyWrapperComponent {

}
