import {delay, filter, Subject, takeUntil} from "rxjs";

const template = require(`html-loader!./teamsInfo.html`).default;
import TeamModel from "../../../api/models/team/team.model";
import teamEnum from '../../../api/enums/team.enum';
import TeamStateModel from "../../../api/models/team/teamState.model";

class teamsInfoCtrl {
  subject = new Subject();
  constructor(
    $scope,
    $rootScope,
    RestManager,
    $timeout,
    stateService,
    $location,
    notificationAlert,
    Modals,
    socketService,
    teamsService,
    gsScrollTopService,
    tagEventService,
    MemberService,
    RouterService
  ) {
    'ngInject';
    this.busy = true;

    this.$scope = $scope;
    this.RestManager = RestManager;
    this.$timeout = $timeout;
    this.stateService = stateService;
    this.$location = $location;
    this.notificationAlert = notificationAlert;
    this.Modals = Modals;
    this.$rootScope = $rootScope;
    this.socketService = socketService;
    this.teamsService = teamsService;
    this.gsScrollTopService = gsScrollTopService;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.RouterService = RouterService;

    this.member = this.MemberService.getCurrentMember();

    this.teamsService.getTeams$.pipe(
      filter(res => !!res),
      takeUntil(this.subject)
    ).subscribe(res => {
      this.state = new TeamStateModel(res.state);
      if (this.state.status === teamEnum.STATE.JOINED && !this.myTeam) {
        this.myTeam = new TeamModel(res.data.teams[0]);
      }
    });

    this.RouterService.activatedRoute
      .queryParams.pipe(
      delay(50),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      await this.init(params);
    });

    this.teamsService.getTeamsConfig$.pipe(
      filter(configData => !!configData),
      takeUntil(this.subject)
    ).subscribe(configData => {
      this.configData = configData;
    });
  }

  $onInit() {
    this.gsScrollTopService.scrollTop();
  }

  async init(params){
    if (this.teamId) {
      this.tagEventService.sendPageViewEvent({title: 'Teams - info'});
      this.joinBy = params.joinBy;
      if (!await this.getTeam(params)) {
        this.stateService.goToDefaultState();
        return;
      }

      this.busy = false;
      this.setDetailItems();
      this.setBackState();

      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
    } else {
      this.stateService.goToDefaultState();
    }
  }

  setBackState(){
    if (this.state.status === teamEnum.STATE.JOINED) {
      if (['teams/leaderboard'].includes(this.stateService.previousState)) {
        this.backState = 'teams/home/leaderboard';
        this.backBtnText = 'Leaderboard'; /* TODO: enum */
      } else {
        this.backState = 'teams/home/my-team';
        this.backBtnText = 'My Team'; /* TODO: enum */
      }

      return;
    }

    this.backState = 'teams/join';
    this.backBtnText = 'View Teams List'; /* TODO: enum */
  }

  async getTeam(params){
    const res = await this.RestManager.getTeam(this.teamId);
    if (!res.success || !res.data || !res.data.teams || !res.data.teams[0] || !res.state) {
      return false;
    }
    this.team = new TeamModel(res.data.teams[0]);
    return true;
  }

  setDetailItems() {
    this.teamDetailTypes = [
      teamEnum.DETAILS.TYPES.SCORE,
      teamEnum.DETAILS.TYPES.WIN,
      teamEnum.DETAILS.TYPES.MEMBERS,
      teamEnum.DETAILS.TYPES.STATUS,
      teamEnum.DETAILS.TYPES.LANGUAGE,
      teamEnum.DETAILS.TYPES.COUNTRY
    ];
  }

  back(){
    this.stateService.goToState(this.backState);
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
teamsInfoCtrl.$inject = [
  '$scope',
  '$rootScope',
  'RestManager',
  '$timeout',
  'stateService',
  '$location',
  'notificationAlert',
  'Modals',
  'socketService',
  'teamsService',
  'gsScrollTopService',
  'tagEventService',
  'MemberService',
  'RouterService'
];

export default {
  template: template,
  controller: teamsInfoCtrl,
  bindings: {
    teamId: '<'
  }
};
