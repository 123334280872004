const template = require(`html-loader!./adminMenu.html`).default;

class adminMenuCtrl {
  constructor(RouterService) {
    'ngInject';
    this.RouterService = RouterService;
  }

  isActiveClassAddedToContent() {
    if (
      this.RouterService.currentUrl.includes('/contentManager/')
    ) {
      return true
    }
    return false;
  }
}

export default {
  template: template,
  controller: adminMenuCtrl
};
