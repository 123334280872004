function config($stateProvider) {
  "ngInject";

  $stateProvider
    .state('gs.error', {
      url: '/error',
      redirectTo: 'gs.error.page404',
      views: {
        'header@gs': {component: 'gsHeader'},
        'page@gs': {template: '<ui-view class="gs-j-c-c"></ui-view>'},
        'footer@gs': {component: 'gsFooter'}
      }
    })
    .state('gs.error.page404', {
      url: '/page_404',
      component: 'page404'
    })
    .state('gs.error.maintenance', {
      url: '/maintenance',
      component: 'maintenance'
    })
  ;
}

config.$inject = ['$stateProvider', '$urlRouterProvider'];
export default config;
