const leagueEnum = Object.freeze({
  TYPE:{
    LEGENDS: {
      NAME: 'LEGENDS',
      COLORS: {
        BG: '#dde7ff',
        BORDER: '#bec0fa',
        NAME: '#3e40a2',
        AVATAR:{
          BORDER:'linear-gradient(180deg, #4C50AB 0%, #A6A9EC 100%)'
        }
      }
    },
    GOLD: {
      NAME: 'GOLD',
      COLORS: {
        BG: '#fff9ef',
        BORDER: '#ffe6c7',
        NAME: '#d4772d',
        LEVEL:{
          BG: '#ffeac9',
          NAME: '#d4772d',
        },
        AVATAR:{
          BORDER:'linear-gradient(180deg, #FFE3B4 0%, #FFF2D8 100%)'
        }
      }
    },
    SILVER: {
      NAME: 'SILVER',
      COLORS: {
        BG: '#e8f4fb',
        BORDER: '#cfddeb',
        NAME: '#49606b',
        LEVEL:{
          BG_IMAGE: 'linear-gradient(65deg, #ccc 21%, #fff 186%)',
          NAME: '#49606b',
        },
        AVATAR:{
          BORDER:'linear-gradient(90deg, #9EBCD5 17.99%, #DBE9FF 99.97%)'
        }
      }
    },
    BRONZE: {
      NAME: 'BRONZE',
      COLORS: {
        BG: '#fff3f0',
        BORDER: '#f9ceba',
        NAME: '#cb561b',
        LEVEL:{
          BG: '#ffd7cc',
          NAME: '#d44e2d',
        },
        AVATAR:{
          BORDER:'linear-gradient(90deg, #C96748 17.99%, #F2BA88 99.97%)'
        }
      }
    },
    EMERALD: {
      NAME: 'EMERALD',
      COLORS: {
        BG: '#e8f7f9',
        BORDER: '#abe0f2',
        NAME: '#2e92a6',
        LEVEL:{
          BG_IMAGE: 'linear-gradient(65deg, #cae6e9 21%, #dafbff 186%)',
          NAME: '#278294',
        },
        AVATAR:{
          BORDER:'linear-gradient(93.87deg, #71BCB8 1.68%, #1B6666 143.61%)'
        }
      }
    },
  },
  STATE: {
    COMING_SOON: 'coming_soon',
    INACTIVE_NOT_IN_LEAGUE: 'inactive_not_in_league',
    INACTIVE_IN_LEAGUE: 'inactive_in_league',
    ACTIVE: 'active',
  },
  SEASON: {
    STATE: {
      PENDING: 'pending',
    },
  },
  TIMER: {
    COLORS: {
      DROP: {
        BG:'#ffe1e1',
        ICON:'#ff6a6a',
        TEXT: '#ea4141'
      },
      PROMOTE: {
        BG:'#d7f6ef',
        ICON:'#00e3ae',
        TEXT: '#009875'
      },
      STAY: {
        BG:'#e8f3f9',
        ICON:'#a6d1eb',
        TEXT: '#3397d2'
      }
    }
  },
  ID_LEAGUE:{
    1:   ()=>{ return leagueEnum.TYPE.BRONZE },
    2:   ()=>{ return leagueEnum.TYPE.BRONZE },
    3:   ()=>{ return leagueEnum.TYPE.BRONZE },
    4:   ()=>{ return leagueEnum.TYPE.SILVER },
    5:   ()=>{ return leagueEnum.TYPE.SILVER },
    6:   ()=>{ return leagueEnum.TYPE.SILVER },
    7:   ()=>{ return leagueEnum.TYPE.GOLD },
    8:   ()=>{ return leagueEnum.TYPE.GOLD },
    9:   ()=>{ return leagueEnum.TYPE.GOLD },
    10:  ()=>{ return leagueEnum.TYPE.EMERALD },
    11:  ()=>{ return leagueEnum.TYPE.EMERALD },
    12:  ()=>{ return leagueEnum.TYPE.EMERALD },
    13:  ()=>{ return leagueEnum.TYPE.LEGENDS },
  }
});

export default leagueEnum;
