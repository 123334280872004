/* globals angular */
const template = require(`html-loader!./simpleMessage.html`).default;

class simpleMessageCtrl {

  constructor($mdToast) {
    'ngInject';

    this.$mdToast = $mdToast;

    this.reasons = [];
  }

  close() {
    this.ChallengesManager.deleteSuspendedImages(this.challenge.id);
    this.$mdToast.hide();
  }
}
simpleMessageCtrl.$inject = [
  '$mdToast'
];
export default {
  template: template,
  controller: simpleMessageCtrl,
  bindings: {
    data: '<'
  }
};
