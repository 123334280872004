import cards from './cards.ctrl';
import trySwapCard from './trySwapCard/trySwapCard';
import suspendedImagesCard from './suspendedImagesCard/suspendedImagesCard';
import successfulSwapCard from './successfulSwapCard/successfulSwapCard';
import unlockStateCard from './unlockStateCard/unlockStateCard';
import offTopicCard from './offTopicCard/offTopicCard';
import similarCard from './similarCard/similarCard';
import customCard from './customCard/customCard';
import swapSuggestCard from './swapSuggestCard/swapSuggestCard';

let moduleCards = angular.module('gsApp.cards', [])
    .component('cards', cards)
    .component('trySwapCard', trySwapCard)
    .component('suspendedImagesCard', suspendedImagesCard)
    .component('successfulSwapCard', successfulSwapCard)
    .component('unlockStateCard', unlockStateCard)
    .component('offTopicCard', offTopicCard)
    .component('similarCard', similarCard)
    .component('customCard', customCard)
    .component('swapSuggestCard', swapSuggestCard)
  ;

export default moduleCards.name;
