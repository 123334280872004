import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-challenge-prizes-wrapper',
  templateUrl: './challenge-prizes-wrapper.component.html',
  styleUrl: './challenge-prizes-wrapper.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ChallengePrizesWrapperComponent {

}
