import teamsPage from './teamsPage/teamsPage';
import teamsJoin from './teamsJoin/teamsJoin';
import teamsCreate from './teamsCreate/teamsCreate';
import teamsLock from './teamsLock/teamsLock';
import teamsInfo from './teamsInfo/teamsInfo';
import teamHome from './teamHome/teamHome';
import myTeam from './myTeam/myTeam';
import teamMembers from './teamMembers/teamMembers';
import myTeamChat from './myTeamChat/myTeamChat';
import teamsMatch from './teamsMatch/teamsMatch';
import matchMemberItem from './teamsMatch/matchMemberItem/matchMemberItem';
import matchHistory from './teamsMatch/matchHistory/matchHistory';
import teamLeaderboard from './teamLeaderboard/teamLeaderboard';
import teamDetails from './teamDetails/teamDetails';
import teamInvites from './teamsJoin/teamInvites/teamInvites';
import teamInvitesItem from './teamsJoin/teamInvites/teamInvitesItem/teamInvitesItem';
import teamSuggestedMembers from './myTeam/teamSuggestedMembers/teamSuggestedMembers';
import teamSuggestedMemberItem from './myTeam/teamSuggestedMembers/teamSuggestedMemberItem/teamSuggestedMemberItem';
import myTeamChatMemberList from './myTeamChat/myTeamChatMemberList/myTeamChatMemberList';
import myTeamChatReplyMessage from './myTeamChat/myTeamChatReplyMessage/myTeamChatReplyMessage';

let moduleTeamsPage = angular.module('gsApp.teamsPage', [])
  .component('teamsPage', teamsPage)
  .component('teamsJoin', teamsJoin)
  .component('teamsCreate', teamsCreate)
  .component('teamsLock', teamsLock)
  .component('teamsInfo', teamsInfo)
  .component('teamHome', teamHome)
  .component('teamMembers', teamMembers)
  .component('myTeamChat', myTeamChat)
  .component('teamsMatch', teamsMatch)
  .component('matchMemberItem', matchMemberItem)
  .component('myTeam', myTeam)
  .component('matchHistory', matchHistory)
  .component('teamLeaderboard', teamLeaderboard)
  .component('teamDetails', teamDetails)
  .component('teamInvites', teamInvites)
  .component('teamInvitesItem', teamInvitesItem)
  .component('teamSuggestedMembers', teamSuggestedMembers)
  .component('teamSuggestedMemberItem', teamSuggestedMemberItem)
  .component('myTeamChatMemberList', myTeamChatMemberList)
  .component('myTeamChatReplyMessage', myTeamChatReplyMessage)
;
export default moduleTeamsPage.name;
