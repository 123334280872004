import photoSwipe from './photoSwipe/photoSwipe';
import photoSwipeLink from './photoSwipeLink/photoSwipeLink';
import photoSwipeService from './photoSwipe.service';
import photoData from './photoData/photoData';

let modulePhotoSwipe = angular.module('gsApp.photoSwipe', [])
    .component('photoSwipe', photoSwipe)
    .component('photoSwipeLink', photoSwipeLink)
    .component('photoData', photoData)
    .service('photoSwipe', photoSwipeService)
;
export default modulePhotoSwipe.name;
