import LoaderEnum from "../../../../../api/enums/loader.enum";

const template = require(`html-loader!./achievementDetails.html`).default;
import achievementEnum from "../../../../../api/enums/achievement.enum";
import mixpanelEventsEnum from "../../../../../services/mixpanel/mixpanel.enum";

class achievementDetailsCtrl {

  constructor($scope, $rootScope, Modals, mixpanelService, achievementsService, RestManager, MemberService) {
    'ngInject';
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.Modals = Modals;
    this.RestManager = RestManager;
    this.mixpanelService = mixpanelService;
    this.achievementsService = achievementsService;
    this.MemberService = MemberService;
    this.achievementEnum = achievementEnum;
    this.LoaderEnum = LoaderEnum;
    this.busy = true;
    this.offOnListReady = this.$rootScope.$on('listReady', this.onListReady.bind(this));
  }

  $onDestroy() {
    this.offOnListReady();
  }

  help(){
    this.Modals.open('achievementsInfo', null , {
      type: achievementEnum.MODAl.TYPE.ACHIEVEMENT_INFO,
      achievement: this.achievement
    });
    this.eventMixPanelAction();
  }

  onListReady(e, data){
    this.busy = false;
    this.isListEmpty = data.isListEmpty;
    if(!this.isListEmpty || this.achievement.is_active){
      if (!this.$scope.$$phase) this.$scope.$digest();
    }
  }

  eventMixPanelAction() {
    this.mixpanelService.track({
      event: mixpanelEventsEnum.EVENT_NAME.ACHIEVEMENT_LEVELS,
      data: {
        ui_action: mixpanelEventsEnum.UI_ACTION.CLICK,
        ui_name: mixpanelEventsEnum.UI_NAME.ACHIEVEMENT_HELP_BUTTON,
        achievement_name: this.achievement.name
      }
    });
  }
}
achievementDetailsCtrl.$inject = [
  '$scope', '$rootScope', 'Modals', 'mixpanelService', 'achievementsService', 'RestManager', 'MemberService'
];
export default {
  template: template,
  controller: achievementDetailsCtrl,
  bindings: {
    achievement: '<',
  }
};
