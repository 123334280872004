import {NgImageModel} from "../../../../core/models/fromOldApp/ng-image.model";
import {delay, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./photo.component.html`).default;

class photoPageComponent {
  subject$ = new Subject();
  constructor(
    pageData,
    ExhibitionsManager,
    RouterService,
    photoSwipe,
    $scope,
    $rootScope,
    $location,
    MemberService,
    PhotosManager,
    NgMetaDataService,
  ) {
    'ngInject';
    this.busy = true;

    this.pageData = pageData;
    this.ExhibitionsManager = ExhibitionsManager;
    this.RouterService = RouterService;
    this.photoSwipe = photoSwipe;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.MemberService = MemberService;
    this.PhotosManager = PhotosManager;
    this.NgMetaDataService = NgMetaDataService;
    this.parameters = $location.search();

    this.RouterService.activatedRoute
      .params.pipe(
      delay(50),
      takeUntil(this.subject$)
    ).subscribe(async () => {
      if (!this.id) {
        this.RouterService.go('challenges/my-challenges/current');
        return;
      }

      await this.initPage();
    });
  }

  async initPage() {
    this.member = this.MemberService.getCurrentMember();
    this.pageData.get('photo');
    const res =  await this.PhotosManager.getImageData(this.id);

    if(!res.success || !res.data || !res.data.id) {
      this.RouterService.go('challenges/my-challenges/current');
      return;
    }
    this.image = new NgImageModel(res.data);

    // redirect if user deleted
    if (this.image.member.isRestricted() && !this.member.is_admin) {
      window.location.href = '/error/page_404';
      return;
    }

    this.NgMetaDataService.updateMetaTags({
      title: this.image.title?.length ? this.image.title : 'image',
      description: this.image.description?.length ? this.image.description : 'description',
      keywords:[ ...this.image.labels, 'photography', 'GuruShots photos', 'photo gallery']
    })
    // Open photo
    this.photoSwipe.open({images:[this.image], closeEnabled:false});

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  $onDestroy() {
    this.subject$.next();
    this.subject$.complete();
  }
}
photoPageComponent.$inject = [
  'pageData',
  'ExhibitionsManager',
  'RouterService',
  'photoSwipe',
  '$scope',
  '$rootScope',
  '$location',
  'MemberService',
  'PhotosManager',
  'NgMetaDataService',
];
export default {
  template: template,
  controller: photoPageComponent,
  bindings: {
    id: '<'
  }
};
