import {Component, Input} from '@angular/core';
import {IStoreItem} from "../../services/store.service.types";
import {StoreCurrencyType} from "../../models/enums";

@Component({
  selector: 'app-store-item-price',
  templateUrl: './store-item-price.component.html',
  styleUrls: ['./store-item-price.component.scss']
})
export class StoreItemPriceComponent {
  @Input() item!: IStoreItem;
  StoreCurrencyType = StoreCurrencyType;
}
