const template = require(`html-loader!./achievements.html`).default;

class achievementsCtrl {
  constructor(

  ) {
    'ngInject';

    this.maxAchievements = 4;
  }
}

achievementsCtrl.$inject = [

];

export default {
  template: template,
  controller: achievementsCtrl,
  bindings: {
    achievements: '<'
  }
};
