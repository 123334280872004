import teamEnum from '../../enums/team.enum';
import ImageModel from '../image/image.model';
import {pareDateFromUnixTime} from "../../../helpers/date.helper";

class TeamsRankData {
  constructor(
    {
      total,
      entries
    },
    member_id
  ) {
    this.total = total;
    this.entries = entries ? entries.map((entry) => {
      return new ImageModel({id: entry.id, member_id: member_id});
    }) : entries;
  }
}

class TeamMemberModel {
  constructor(props) {
    this.id = props.id;
    this.avatar = props.avatar;
    this.member_id = props.member_id;
    this.member_status = props.member_status;
    this.member_status_name = props.member_status_name;
    this.name = props.name;
    this.points = props.points;
    this.match_points = props.match_points || 0;
    this.status = props.status;
    this.team_id = props.team_id;
    this.type = props.type;
    this.user_name = props.user_name;
    this.team_id = props.team_id;
    this.join_by = props.join_by;
    this.rank_data = props.rank_data ? new TeamsRankData(props.rank_data, props.member_id): props.rank_data;
    //for admin
    this.date_created = pareDateFromUnixTime(props.date_created);
    this.left_date = pareDateFromUnixTime(props.left_date);
    this.invitation = props.invitation;
    this.total_votes = props.total_votes;
    this.score = props.score;
  }

  get displayName(){
    return this.name || `@${this.user_name}`;
  }

  isAdmin() {
    return [
      teamEnum.MEMBER.TYPE.ADMIN,
      teamEnum.MEMBER.TYPE.CREATOR
    ].includes(this.type);
  }
}

export default TeamMemberModel;
