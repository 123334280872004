import {Subject, takeUntil, filter} from "rxjs";
const template = require(`html-loader!./winners.exhibition.html`).default;
import ImageModel from '../../../../api/models/image/image.model';
import {NgChallengeModel} from "../../../../../../modules/challenges/models/ng-challenge.model";

class challengeWinnersExhibitionDigit {

  constructor(
    $timeout,
    $document,
    $location,
    $rootScope,
    RankingManager,
    ExhibitionsManager,
    $scope,
    tagEventService,
    MemberService,
    NgChallengesService
  ) {
    'ngInject';
    this.start = 0;
    this.limit = 50;
    this.items = [];
    this.destroyed = false;
    this.allLoaded = false;
    this.loading = false;
    this.imgHeight = 500;
    this.inited = false;
    this.subject = new Subject();
    this.$timeout = $timeout;
    this.$document = $document;
    this.$location = $location;
    this.$rootScope = $rootScope;
    this.RankingManager = RankingManager;
    this.ExhibitionsManager = ExhibitionsManager;
    this.$scope = $scope;
    this.tagEventService = tagEventService;
    this.MemberService = MemberService;
    this.NgChallengesService = NgChallengesService;

    this.onJgComplete = this.onJgComplete.bind(this);

    this.NgChallengesService.currentChallenge$.pipe(
      filter(challenge => challenge !== undefined),
      takeUntil(this.subject)
    ).subscribe(async (challenge) => {
      this.challenge = challenge;
      this.loadMore();
    });

    this.loggedinMember = this.MemberService.getCurrentMember();
  }

  $onInit() {
    // Google vPageView
    this.tagEventService.sendPageViewEvent({title: 'Challenge Exhibition Print'});
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
    this.destroyed = true;
    try {
      $('.top-photo__grid')
        .justifiedGallery('destroy')
        .off('jg.complete', this.onJgComplete);
    } catch (e) {}
  }

  async loadMore(more) {
    if (this.busy || this.allLoaded) {
      return;
    }
    this.busy = true;

    // prepare data
    const data = {
      challenge_id: this.challenge.id,
      sort: "votes",
      start: this.start,
      limit: this.limit,
      filter: [this.type, 'selected_only']
    };
    const res = await this.ExhibitionsManager.getExhibitionWinners(data);

    if (this.destroyed) {
      this.busy = false;
      return;
    }
    if (!res.items || !res.items.length) {
      this.allLoaded = true;
      this.busy = false;
      if (!this.$scope.$$phase) {
        this.$scope.$digest();
      }
      return;
    }

    this.items.push(...this.parseImages(res.items));
    this.start += this.limit;

    this.$timeout(() => {
      if (!this.inited) {
        this.inited = true;
        $('.top-photo__grid')
          .justifiedGallery({
            rowHeight: 300,
            maxRowHeight: 300,
            lastRow: 'nojustify',
            captions: false,
            margins: 5,
            waitThumbnailsLoad: false,
            selector: 'div',
            cssAnimation: false,
            imagesAnimationDuration: 0
          })
          .on('jg.complete', this.onJgComplete);
      } else {
        $('.top-photo__grid').justifiedGallery('norewind');
      }
    });

    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }

  parseImages(items) {
    return items.map((item) => {
      let image = new ImageModel(item.images[0]);
      image.member = item.member;
      image.total = item.total;
      image.badge = item.badge;
      return image;
    });
  }

  onJgComplete() {
    this.busy = false;
    if (!this.$scope.$$phase) {
      this.$scope.$digest();
    }
  }
}
challengeWinnersExhibitionDigit.$inject = [
  '$timeout',
  '$document',
  '$location',
  '$rootScope',
  'RankingManager',
  'ExhibitionsManager',
  '$scope',
  'tagEventService',
  'MemberService',
  'NgChallengesService'
];
export default {
  template: template,
  controller: challengeWinnersExhibitionDigit,
  bindings: {
    type: '@'
  }
};
