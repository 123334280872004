export default function() {
  let previousDevice,
    _addClass,
    _doc_element,
    _find,
    _handleOrientation,
    _hasClass,
    _orientation_event,
    _removeClass,
    _supports_orientation,
    _user_agent;
  previousDevice = window.device;
  window.device = {};
  _doc_element = window.document.documentElement;
  _user_agent = window.navigator.userAgent.toLowerCase();

  device.ios = function() {
    return device.iphone() || device.ipod() || device.ipad();
  };

  device.iphone = function() {
    return _find('iphone');
  };

  device.ipod = function() {
    return _find('ipod');
  };

  device.ipad = function() {
    return _find('ipad');
  };

  device.android = function() {
    return _find('android');
  };

  device.androidPhone = function() {
    return device.android() && _find('mobile');
  };

  device.androidTablet = function() {
    return device.android() && !_find('mobile');
  };

  device.blackberry = function() {
    return _find('blackberry') || _find('bb10') || _find('rim');
  };

  device.blackberryPhone = function() {
    return device.blackberry() && !_find('tablet');
  };

  device.blackberryTablet = function() {
    return device.blackberry() && _find('tablet');
  };

  device.windows = function() {
    return _find('windows');
  };

  device.windowsPhone = function() {
    return device.windows() && _find('phone');
  };

  device.windowsTablet = function() {
    return device.windows() && _find('touch');
  };

  device.fxos = function() {
    return (_find('(mobile;') || _find('(tablet;')) && _find('; rv:');
  };

  device.fxosPhone = function() {
    return device.fxos() && _find('mobile');
  };

  device.fxosTablet = function() {
    return device.fxos() && _find('tablet');
  };

  device.meego = function() {
    return _find('meego');
  };

  device.mobile = function() {
    return (
      device.androidPhone() ||
      device.iphone() ||
      device.ipod() ||
      device.windowsPhone() ||
      device.blackberryPhone() ||
      device.fxosPhone() ||
      device.meego()
    );
  };

  device.tablet = function() {
    return (
      device.ipad() ||
      device.androidTablet() ||
      device.blackberryTablet() ||
      device.windowsTablet() ||
      device.fxosTablet()
    );
  };

  device.portrait = function() {
    return Math.abs(window.orientation) !== 90;
  };

  device.landscape = function() {
    return Math.abs(window.orientation) === 90;
  };

  device.noConflict = function() {
    window.device = previousDevice;
    return this;
  };

  _find = function(needle) {
    return _user_agent.indexOf(needle) !== -1;
  };

  _hasClass = function(class_name) {
    var regex;
    regex = new RegExp(class_name, 'i');
    return _doc_element.className.match(regex);
  };

  _addClass = function(class_name) {
    if (!_hasClass(class_name)) {
      return (_doc_element.className += ' ' + class_name);
    }
  };

  _removeClass = function(class_name) {
    if (_hasClass(class_name)) {
      return (_doc_element.className = _doc_element.className.replace(class_name, ''));
    }
  };

  if (device.ios()) {
    if (device.ipad()) {
      _addClass('ios ipad tablet');
    } else if (device.iphone()) {
      _addClass('ios iphone mobile');
    } else if (device.ipod()) {
      _addClass('ios ipod mobile');
    }
    _addClass('touch');
  } else if (device.android()) {
    if (device.androidTablet()) {
      _addClass('android tablet');
    } else {
      _addClass('android mobile');
    }
    _addClass('touch');
  } else if (device.blackberry()) {
    if (device.blackberryTablet()) {
      _addClass('blackberry tablet');
    } else {
      _addClass('blackberry mobile');
    }
  } else if (device.windows()) {
    if (device.windowsTablet()) {
      _addClass('windows Xtablet');
    } else if (device.windowsPhone()) {
      _addClass('windows Xmobile');
    } else {
      _addClass('desktop');
    }
  } else if (device.fxos()) {
    if (device.fxosTablet()) {
      _addClass('fxos tablet');
    } else {
      _addClass('fxos mobile');
    }
  } else if (device.meego()) {
    _addClass('meego mobile');
  } else {
    _addClass('desktop');
  }

  // _handleOrientation = function () {
  //   if (device.landscape()) {
  //     _removeClass("portrait");
  //     return _addClass("landscape");
  //   } else {
  //     _removeClass("landscape");
  //     return _addClass("portrait");
  //   }
  // };

  // _supports_orientation = "onorientationchange" in window;
  //
  // _orientation_event = _supports_orientation ? "orientationchange" : "resize";
  //
  // if (window.addEventListener) {
  //   window.addEventListener(_orientation_event, _.throttle(_handleOrientation, 200), false);
  // } else if (window.attachEvent) {
  //   window.attachEvent(_orientation_event, _.throttle(_handleOrientation, 200));
  // } else {
  //   window[_orientation_event] = _.throttle(_handleOrientation, 200);
  // }
  //
  // _handleOrientation();
}
