const template = require(`html-loader!./contentManager.html`).default;

class contentManagerCtrl {
  constructor() {
    this.menuItems = [
      {name: 'Challenges', route: 'admin/contentManager/challenges/challengesSchedule/upcoming'},
      {name: 'Exhibitions', route: 'admin/contentManager/exhibitions/manager'},
      {name: 'Spotlight', route: 'admin/contentManager/spotlight'},
      {name: 'Popups', route: 'admin/contentManager/popups'},
      {name: 'Widget', route: 'admin/contentManager/widget'},
      // {name: 'Daily Rewards', route: 'admin.contentManager.dailyRewards'},
      {name: 'Rewarded Video', route: 'admin/contentManager/rewardedVideo'},
      {name: 'Liveops', route: 'admin/contentManager/liveops/suggest'},
      {name: 'Events', route: 'admin/contentManager/events/leaderBoard'},
      {name: 'Chests', route: 'admin/contentManager/chests'},
      {name: 'Teams', route: 'admin/contentManager/teams/leagues/active'}
    ];
  }
}

export default {
  template: template,
  controller: contentManagerCtrl,
  bindings: {}
};
