import ImageModel from '../image/image.model';

class CardCustomDataModel {
  constructor({action = {}, media = {}, title, body, images = []}) {
    if (media.image) {
      media.image = new ImageModel(media.image);
    }
    Object.assign(this, {action, media, title, body, images});
  }
}

export default CardCustomDataModel;
