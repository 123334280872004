import {Component, Input} from '@angular/core';
import {PrizeItem} from "../../interfaces/challenges.interface";
import {PrizesLayout} from "../../enums/challenges.enum";

@Component({
  selector: 'app-prize-item',
  templateUrl: './prize-item.component.html',
  styleUrl: './prize-item.component.scss'
})
export class PrizeItemComponent {
  @Input() prize: PrizeItem = {};
  PrizesLayout = PrizesLayout;
}
