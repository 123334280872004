import environment from '../gsApp.environment';
class socketService {

  constructor($timeout, $rootScope, $cookies) {
    'ngInject';
    this.socket = null;
    this.chatId = null;
    this.socketUrl = "";
    this.socketInitialized = false;
    this.roomCreated = false;

    this.socketEvents = {
      connect: 'connect',
      joinRoom: 'joinRoom',
      disconnect: 'disconnect',
      roomCreated: 'roomCreated',
      msgToClient: 'msgToClient',
      msgToServer: 'msgToServer',
      error: 'error',
    };


    this.$rootScope = $rootScope;
    this.$timeout = $timeout;
    this.$cookies = $cookies;
    this.socketUrl = environment.domains.chats;
  }

  getSocket(){
    if(!this.socketInitialized){
      this.socket = io(`https://${this.socketUrl}/teamsSecure`,
        {
          query: {
            token: this.$cookies.get('gs_t')
          }
        }
      );
      this.socketInitialized = true;
      this.socket.open();
      this.setListeners();
    }
    return this.socket;
  }

  addListener(event, listener){
    if(this.socket) {
      this.removeListener(event, listener);
      this.socket.on(event, listener);
    }
  }

  removeListener(event, listener){
    if(this.socket) {
      this.socket.off(event, listener);
    }
  }

  setListeners(){
    this.socket.on(this.socketEvents.connect, () => {
      this.roomCreated = true;

      if (!this.$rootScope.$$phase) {
        this.$rootScope.$digest();
      }
    });

    this.socket.on(this.socketEvents.error, (err) => {
      console.error('socket error', err);
    });

    this.socket.on(this.socketEvents.disconnect, () => {
      console.error("socket disconnected");
      // console.log('this.socket.disconnected', this.socket.disconnected);
      // if(this.socket.disconnected){
      //   this.closeSocket();
      // }
    });
  }

  closeSocket(){
    if(this.socket){
      this.socket.off();
      this.socket.close();
      this.socketInitialized = false;
      this.roomCreated = false;
    }
  }
}
socketService.$inject = [
  '$timeout', '$rootScope', '$cookies'
];
export default socketService;
