import {Component, Input} from '@angular/core';
import {enterTriggerAnimation} from "../../../core/animations/animations";
// import {animate, state, style, transition, trigger} from "@angular/animations";

export enum LocateButtonTypes {
  TARGET,
  UP,
  DOWN
}

@Component({
  selector: 'app-locate-button',
  templateUrl: './locate-button.component.html',
  styleUrls: ['./locate-button.component.scss'],
  animations: [enterTriggerAnimation]
})

export class LocateButtonComponent {

  @Input() type: LocateButtonTypes = LocateButtonTypes.TARGET;
  Types = LocateButtonTypes;

  constructor() {
  }
}
