import { Component } from '@angular/core';

@Component({
  selector: 'app-challenge-details-wrapper',
  templateUrl: './challenge-details-wrapper.component.html',
  styleUrl: './challenge-details-wrapper.component.scss'
})
export class ChallengeDetailsWrapperComponent {

}
