import ImageModel from '../../../api/models/image/image.model';
const template = require(`html-loader!./similarCard.html`).default;

class similarCardCtrl {
  constructor($element, Modals, challengeService) {
    'ngInject';
    this.$element = $element;
    this.Modals = Modals;
    this.challengeService = challengeService;

    this.reasons = [];
  }

  $onChanges() {
    //Prepare data & reasons array
    this.gspopup.data.images = this.gspopup.data.images.map(image => new ImageModel(image));
  }

  swap(event) {
    this.challengeService.swapAction({
      challenge: this.challenge
    });
    this.close();
  }
}

similarCardCtrl.$inject = [
  '$element', 'Modals', 'challengeService'
];
export default {
  template: template,
  controller: similarCardCtrl,
  bindings: {
    challenge: '<',
    gspopup: '<',
    close: '&'
  }
};
