export class EnvironmentDomainsModel {
    // domain: string;
    photos: string;
    api: string;
    chats: string;
    globalSocket: string;

    constructor(config: any) {
        // this.domain = config.domain;
        this.photos = config.photos;
        this.api = config.api;
        this.chats = config.chats;
        this.globalSocket = config.globalSocket;
    }
}
