const template = require(`html-loader!./appUpdated.html`).default;

class appUpdated {
  refresh() {
    window.location.reload();
  }
}

export default {
  template: template,
  controller: appUpdated,
  bindings: {
    data: '=data'
  }
};
