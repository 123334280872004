import {filter, Subject, takeUntil} from "rxjs";
const template = require(`html-loader!./adminExhibitionsEdit.html`).default;
import ExhibitionModel from '../../../../../api/models/exhibition/exhibition.model';

class adminExhibitionsEdit {
  subject = new Subject();
  constructor(ExhibitionsManager, Toasts, $element, RouterService, Modals, MemberService) {
    'ngInject';
    this.busy = true;
    this.mediaTypes = ['youtube', 'image'];

    this.ExhibitionsManager = ExhibitionsManager;
    this.Toasts = Toasts;
    this.$element = $element;
    this.RouterService = RouterService;
    this.Modals = Modals;
    this.MemberService = MemberService;
    this.member = MemberService.getCurrentMember();

    this.RouterService.activatedRoute
      .queryParams.pipe(
      filter(params => params.id || params.exhibition),
      takeUntil(this.subject)
    ).subscribe(async (params) => {
      // If exhibition Id
      if (params.id) {
        this.ExhibitionsManager.getExhibitionPageItems(params.id).then(res => {
          if (!res.success) {
            this.RouterService.go('admin/contentManager/exhibitions/manager');
            return;
          }

          this.exhibition = res.items[0];
          this.busy = false;
        });
        return;
      }

      // If new
      this.exhibition = new ExhibitionModel({});
      this.busy = false;
    });
  }

  selectImage(event, objName) {
    this.Modals.open('gsUploader', event, {
      target: 'getImages',
      items_limit: 1,
      member: {
        id: this.member.id,
      },
      callback: images => {
        switch (objName) {
          case 'media':
            this.exhibition[objName].image = images[0];
            this.exhibition[objName].id = images[0].id;
            break;
          case 'comment':
            this.exhibition[objName].image = images[0];
            this.exhibition[objName].member.id = images[0].member_id;
            break;
          default:
            this.exhibition[objName].image = images[0];
        }
      },
    });
  }

  save() {
    this.busy = true;

    const data = {
      id: this.exhibition.id,
      c_id: this.exhibition.challenge.id,
      country: this.exhibition.country,
      city: this.exhibition.city,
      country_code: this.exhibition.country_code,
      date: this.exhibition.date,
      name: this.exhibition.name,
      summary_votes: this.exhibition.summary.votes,
      summary_photos: this.exhibition.summary.photos,
      summary_countries: this.exhibition.summary.countries,
      summary_members: this.exhibition.summary.members,
      media_type: this.exhibition.media.type,
      media_id: this.exhibition.media.id,
      comment_text: this.exhibition.comment.text,
      comment_member_id: this.exhibition.comment.member.id,
      comment_image_id: this.exhibition.comment.image.id,
      action_text: this.exhibition.action.text,
      action_url: this.exhibition.action.url,
      sponsor_url: this.exhibition.sponsor.url,
      sponsor_image_id: this.exhibition.sponsor.image.id,
      published: this.exhibition.published,
    };
    this.ExhibitionsManager.setExhibitionPageItem(data).then(res => {
      if (!res.success) {
        this.busy = false;
        const data = {
          message: res.error_code,
          class: 'error',
        };
        this.Toasts.open('simpleMessage', { data }, this.$element);
      }

      // Refresh view
      this.RouterService.go(
        'admin/contentManager/exhibitions/page',
        {
          id: this.exhibition.id || res.id,
          exhibition: null,
        }
        );
    });
  }

  $onDestroy() {
    this.subject.next();
    this.subject.complete();
  }
}
adminExhibitionsEdit.$inject = [
  'ExhibitionsManager', 'Toasts', '$element', 'RouterService', 'Modals', 'MemberService'
];
export default {
  template: template,
  controller: adminExhibitionsEdit,
};
