class gsCommentsService {

  constructor(
    $location
  ) {
    'ngInject';

    this.location = $location;

    this.draftComment = {};
  }

  createDraft(text) {
    this.draftComment[this.location.$$path] = text;
  }

  getDraft() {
    return Object.keys(this.draftComment).includes(this.location.$$path) ? this.draftComment[this.location.$$path] : null;
  }

  eraseDraft() {
    delete this.draftComment[this.location.$$path];
  }
}
gsCommentsService.$inject = [
  '$location',
];
export default gsCommentsService;
