class ShopManager {
  constructor(api) {
    'ngInject';
    this.api = api;
  }

  getPackages(p_shelf) {
    return this.api['shop']['getAllShelves']({p_shelf});
  }

  getPackage(type) {
    return this.api['shop']['getShelve']({type});
  }

  pay(data) {
    return this.api['gsShop']['pay'](data);
  }

  paypalCreate(package_id, param = '') {
    return this.api.gsShop.paypalCreate({package_id, param});
  }

  paypalExecute(package_id, payment_id, payer_id, c_id) {
    return this.api.gsShop.paypalExecute({package_id, payment_id, payer_id, c_id});
  }

  payCreditCard(data) {
    return this.api['gsShop']['payCreditCard'](data);
  }
}

ShopManager.$inject = [
  'api'
];

export default ShopManager;
