//chest types , not used for now
const ChestType = {
  TEAM_LEAGUE_END_OF_SEASON: 'TEAM_LEAGUE_END_OF_SEASON',
  END_OF_CHALLENGE: 'END_OF_CHALLENGE',
};

class ChestModel {
  constructor(props) {
    this.chest_id = props.chest_id;
    this.entity_id = props.entity_id;
    this.points = props.points;
    this.target_points = props.target_points;
    this.can_claim = props.can_claim;
    this.is_claimed = props.is_claimed;
    this.type = props.type;
    this.chest_domain = props.chest_domain;
  }

  get pointsProgress() {
    return this.target_points && this.points ? Math.round(this.points / this.target_points * 100) : 0;
  }
}

export default ChestModel;
