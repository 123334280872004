export const moderatorReason = {
  SPAM:4,
  COPYRIGHT:3,
  OFF_TOPIC:2,
  ADULT_CONTENT:6,
};
export const adultReportType = {
  APPROVED:0,
  PORN:1,
  ADULT:2,
};
export const moderatorAction = {
  REINSTATE:1,
  DISQUALIFY:-1
};
export const adultRes = {
  "items": [
    {
      "member_id": "0888b1e2152328d43f8678a64ebf8ebf",
      "ratio": 0.81,
      "id": "873893bbcb4c2e3837ba9b2b7e4cb95c"
    },
    {
      "member_id": "104faf14bb9af889d59ee75de36ba90f",
      "ratio": 1.33,
      "id": "99965e3bbae3526f18f6a58a80c6f022"
    },
    {
      "member_id": "ec256198c9c333c9a3dda37379850eed",
      "ratio": 0.8,
      "id": "4e6c6bcfc0a041f49022ed4995ef0b42"
    },
    {
      "member_id": "ac7d4894a68f2af59560482cc108c65e",
      "ratio": 0.56,
      "id": "1d6ae99fbf3037f2e5a7f2aea1c914bc"
    },
    {
      "member_id": "88eb2f7235391e000e842950be6abfb3",
      "ratio": 0.65,
      "id": "d935bf102b7f5db4984f285d93dbcce4"
    },
    {
      "member_id": "8732209faa7e910a7dcd81194e00e400",
      "ratio": 0.82,
      "id": "6dc7e9015e7c01d7f79c99e6ed75ff8b"
    },
    {
      "member_id": "b9e0ae0a9b9fabb0c61c6fc0f642261c",
      "ratio": 0.75,
      "id": "78db1220982a189bb3ed03291c14bcce"
    },
    {
      "member_id": "26cd55ec259c0c3ab59e8baf13c78805",
      "ratio": 1.5,
      "id": "487abb56bb974616375a7fd25aafc133"
    },
    {
      "member_id": "21e881f239da3e17c8ca3cf847cb7760",
      "ratio": 0.56,
      "id": "d1c11fdc0e3b904488a1547da325873e"
    },
    {
      "member_id": "5127741d3120a59c2c9c004840e8e008",
      "ratio": 0.6,
      "id": "b33106ba42ee9b0cd5924e0cfb46d818"
    },
    {
      "member_id": "14b4aa7bc141449a06a24ddbee71a450",
      "ratio": 1.5,
      "id": "7b7cb88174179d50ab4a6de59f7b1d11"
    },
    {
      "member_id": "b02cf6553e576d72dffcd0841dc3171d",
      "ratio": 1.1,
      "id": "9dbe73f12026e83856234254fd2d778d"
    },
    {
      "member_id": "ec359fd8da96a6300eb85e178c0b6c33",
      "ratio": 0.8,
      "id": "84f6fcdd1304151d2ff9c3f9ea1ae5b0"
    },
    {
      "member_id": "e653124569ffbd0baa19fa261ddc82d4",
      "ratio": 0.74,
      "id": "c6f36f5436c970a4f9dbc33b7d97fb16"
    },
    {
      "member_id": "25041bf85bd61e85a5968dfce2cbec34",
      "ratio": 0.68,
      "id": "cbab88610d26dba4a86f26fe9e1ad481"
    },
    {
      "member_id": "a407ea291ee0f0607042f6853f4c1904",
      "ratio": 1.5,
      "id": "393750dd907e6a595201b892dad00cf7"
    },
    {
      "member_id": "8868596949778052777901abb56b679c",
      "ratio": 0.66,
      "id": "a245a17ced7b749ddcedd2643bec2d7f"
    },
    {
      "member_id": "a09d47db363694903d3819f42a424205",
      "ratio": 1.34,
      "id": "7bdbbaeeba48c11acea56b538ced855d"
    },
    {
      "member_id": "56d41906ce2d52d2cdf97f169b3a858c",
      "ratio": 0.67,
      "id": "f81f56818bc4b7c2a1596489c8231005"
    },
    {
      "member_id": "7131a8a766ebe26beecfc92394bc6edc",
      "ratio": 0.65,
      "id": "87707ef90f3379cbe8624bb3b2332571"
    }
  ],
  "number_of_reports": "368",
  "success": true
};
