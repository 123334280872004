import {
  AfterContentInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges
} from '@angular/core';
import {UrlService} from "../../../core/services/url.service";

@Component({
  selector: 'app-ng-image',
  templateUrl: './ng-image.component.html',
  styleUrls: ['./ng-image.component.scss']
})
export class NgImageComponent implements OnChanges, AfterContentInit {
  // @HostListener('contextmenu', ['$event'])
  // onRightClick(event:any) {
  //   event.preventDefault();
  // }

  @Input() image?: any;
  @Input() width = 0;
  @Input() height = 0;
  @Input() priority = false;
  @Input() fill = false;
  @Input() alt = 'Image';

  @Output() error = new EventEmitter<void>;
  @Output() load = new EventEmitter<void>;

  src = '';
  isViewInit = false;
  isImageError= false;

  constructor(
    private urlService: UrlService,
    private elementRef: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['image'].currentValue && this.isViewInit) {
      this.isImageError = false;
      this.getSrc();
    }
  }

  getSrc(){
    if (typeof this.image === 'string') {
      this.src = this.image;
      return;
    }
    if (this.image?.token) {
      this.src = this.urlService.getImageTokenUrl(
        this.image.token,
        this.width
      );
    } else if (this.image?.member_id && this.image?.id) {
      this.src = this.urlService.getImageUrl(
        this.image.member_id,
        this.image.id,
        this.width
      );
    }
  }

  ngAfterContentInit(): void {
    this.width = this.fill ?
      Math.max(this.width, this.elementRef.nativeElement?.offsetParent?.offsetWidth ?? 0):
      this.width;

    this.isViewInit = true;
    this.getSrc();
  }

  onError() {
    this.isImageError = true;
    this.error.emit();
  }

  onLoad() {
    this.load.emit();
  }
}
