/* globals angular */
const template = require(`html-loader!./expoSale.html`).default;

class expoSaleToastCtrl {

  constructor($mdToast) {
    'ngInject';

    this.$mdToast = $mdToast;
  }

  close() {
    this.$mdToast.hide();
  }
}

expoSaleToastCtrl.$inject = [
  '$mdToast'
];
export default {
  template: template,
  controller: expoSaleToastCtrl,
  bindings: {}
};
