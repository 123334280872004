import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-progress-bar-shield-generic',
  templateUrl: './progress-bar-shield-generic.component.html',
  styleUrls: ['./progress-bar-shield-generic.component.scss']
})
export class ProgressBarShieldGenericComponent {
  @Input() fillPercentage = 0;
}
