const gsAnimationsFade = ($animateCss) => {
  'ngInject';

  return {
    move: (element) => $animateCss(element, {
        duration: 0.5,
        //stagger: 0.2, // By element animation
        keyframeStyle: 'pulse 0.5s'
      }),
    enter: (element) => $animateCss(element, {
        duration: 0.3,
        //stagger: 0.2,
        keyframeStyle: 'fadeInUp 0.5s'
      }),
    leave: (element) => $animateCss(element, {
        duration: 0.5,
        keyframeStyle: 'bounceOut 0.5s'
      })
  };
};

gsAnimationsFade.$inject = [
  '$animateCss'
];

export default gsAnimationsFade;
