class FacebookManager {
  /** @param {api} api **/
  constructor(
    api
  ) {
    'ngInject';

    this.api = api;
  }

  facebookConnect(fb_token) {
    return this.api['facebook']['facebookConnect']({fb_token});
  }

  facebookLink(fb_token) {
    return this.api['facebook']['facebookLink']({fb_token});
  }

  facebookUnlink() {
    return this.api['facebook']['facebookUnlink']();
  }

  facebookRenewToken(fb_token) {
    return this.api['facebook']['facebookRenewToken']({fb_token});
  }

  facebookFriends() {
    return this.api['facebook']['facebookFriends']();
  }
}

FacebookManager.$inject = [
  'api'
];

export default FacebookManager;
